import { Component, Input } from '@angular/core';
import {
  PrecertificationStatusType,
  PrecertificationStatus,
} from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-precert-status-alert',
  templateUrl: './precert-status-alert.component.html',
  styleUrls: ['./precert-status-alert.component.scss'],
})
export class PrecertStatusAlertComponent {
  @Input() status: PrecertificationStatusType;
  @Input() size: 'small' | 'default' = 'default';
  PrecertificationStatus = PrecertificationStatus;
}
