import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cigna-filter-label-shared',
  templateUrl: './filter-label.component.html',
  styleUrls: ['./filter-label.component.scss'],
})
export class FilterLabelSharedComponent {
  @Input() filterName: string;
  @Input() selections: string[] = [];
  @Input() customLabel = '';
  @Input() shouldHideLabel = false;
  @Input() isOpen = false;

  @Output() labelClick = new EventEmitter();

  defaultLabel = 'All';
  multipleSelectionLabel = 'Custom';

  get selectionText(): string {
    if (this.customLabel) {
      return this.customLabel;
    }

    return this.selections.length > 0
      ? this.selections.length > 1
        ? this.multipleSelectionLabel
        : this.selections[0]
      : this.defaultLabel;
  }
}
