<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="panel">
  <div class="panel-top">
    <div class="cg-flex cg-flex-vertical-center">
      <div>
        <ng-container *ngIf="appContext === 'chcp'; else evernorthLobs">
          <button
            class="toggle-link"
            style="margin-right: 0.5em"
            [class.toggle-link__active]="lob === LOB_MEDICAL"
            (click)="setUserDevMode(LOB_MEDICAL)"
          >
            MED
          </button>
          <button
            class="toggle-link"
            style="margin-right: 0.5em"
            [class.toggle-link__active]="lob === LOB_MEDBH"
            (click)="setUserDevMode(LOB_MEDBH)"
          >
            MEDBH
          </button>
          <button
            class="toggle-link"
            [class.toggle-link__active]="lob === LOB_DENTAL"
            (click)="setUserDevMode(LOB_DENTAL)"
          >
            DEN
          </button>
        </ng-container>
        <ng-template #evernorthLobs
          ><button
            class="toggle-link"
            style="margin-right: 0.5em"
            [class.toggle-link__active]="lob === LOB_BEHAVIORAL"
            (click)="setUserDevMode(LOB_BEHAVIORAL)"
          >
            BEH
          </button>
          <button
            class="toggle-link"
            [class.toggle-link__active]="lob === LOB_MEDBH"
            (click)="setUserDevMode(LOB_MEDBH)"
          >
            MEDBH
          </button></ng-template
        >
      </div>
      <div style="margin-left: auto">
        <button
          cignaButton="link inline no-underline"
          class="cg-margin-left-lg cg-margin-right-md toggle-button"
          [attr.aria-label]="
            collapsed ? 'expand dev toggle' : 'collapse dev toggle'
          "
          (click)="toggleCollapsed()"
        >
          <cigna-icon
            [class.icon-expanded-state]="collapsed === 'n'"
            type="chevron-thin-up"
          ></cigna-icon>
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="collapsed === 'n'">
    <div class="cg-margin-top-lg">
      <div class="cg-margin-bottom-sm" style="font-size: 120%">
        ENTITLEMENTS
      </div>
      <div *ngFor="let entitlement of allEntitlements">
        <label class="cg-checkbox-label cg-margin-bottom-xs"
          ><input
            #entitlementToggle
            (change)="setUserEntitlements(entitlement, $event.target.checked)"
            type="checkbox"
            [checked]="enabledEntitlements.includes(entitlement)"
          />
          <span>{{ entitlement }}</span></label
        >
      </div>
    </div>
    <div class="cg-margin-top-lg">
      <div class="cg-margin-bottom-sm" style="font-size: 120%">PERMISSIONS</div>
      <div *ngFor="let permission of allPermissions">
        <label class="cg-checkbox-label cg-margin-bottom-xs"
          ><input
            #permissionToggle
            (change)="setUserPermissions(permission, $event.target.checked)"
            type="checkbox"
            [checked]="enabledPermissions.includes(permission)"
          />
          <span>{{ permission }}</span></label
        >
      </div>
    </div>
    <div class="cg-margin-top-lg">
      <div class="cg-margin-bottom-sm" style="font-size: 120%">
        IMPERSONATION
      </div>

      <div class="cg-margin-bottom-xs">
        <label class="cg-flex cg-flex-vertical-center cg-margin-bottom-sm"
          ><input
            #impersonatorStatus
            (change)="setUserDevImpersonation('')"
            type="radio"
            value=""
            name="impersonatorStatus"
            [checked]="impersonator === ''"
          />
          <span>OFF</span></label
        >
      </div>
      <div class="cg-margin-bottom-xs">
        <label class="cg-flex cg-flex-vertical-center cg-margin-bottom-sm"
          ><input
            #impersonatorStatus
            (change)="setUserDevImpersonation('CHCP_RES')"
            type="radio"
            value="CHCP_RES"
            name="impersonatorStatus"
            [checked]="impersonator === 'CHCP_RES'"
          />
          <span>On - Restricted</span></label
        >
      </div>
      <div class="cg-margin-bottom-xs">
        <label class="cg-flex cg-flex-vertical-center cg-margin-bottom-sm"
          ><input
            #impersonatorStatus
            (change)="setUserDevImpersonation('CHCP_UNRES')"
            type="radio"
            value="CHCP_UNRES"
            name="impersonatorStatus"
            [checked]="impersonator == 'CHCP_UNRES'"
          />
          <span>On - Unrestricted</span></label
        >
      </div>
    </div>
  </ng-container>
</div>
