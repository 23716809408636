<section
  class="banner-wrapper cg-hide-for-print"
  *ngIf="!dismissed && isValidDate"
>
  <div class="banner-inner">
    <div class="banner-content">
      <ng-container *ngIf="daysLeft < 0; else countdownWarn">
        Your password is expired.
        <a
          data-test="link-lockout"
          [routerLink]="['/preferences/security/password']"
          >Please change it now</a
        >
        to avoid being locked out of the website.
      </ng-container>
      <ng-template #countdownWarn>
        <div class="cg-flex-vertical-center">
          <div>
            <cigna-icon
              (click)="dismissed = true"
              type="close"
              class="dismiss-icon cg-clickable-icon"
            ></cigna-icon>
          </div>
          <div>
            Your password expires in {{ daysLeft }} days.
            <a
              data-test="link-expiring"
              [routerLink]="['/preferences/security/password']"
              >Please change it now</a
            >
            to avoid being locked out of the website on {{ lockoutDate }}
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
