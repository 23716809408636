<div class="flex-container">
  <div style="margin-left: auto">
    <div class="flex-container clickable" (click)="expanded = !expanded">
      <section class="cg-margin-right-sm cg-bold cg-link-color">
        {{ title }}
      </section>
      <div class="toggle-section">
        <cigna-icon
          *ngIf="!expanded"
          class="toggle-icon"
          type="chevron-down"
        ></cigna-icon>
        <cigna-icon
          *ngIf="expanded"
          class="toggle-icon"
          type="chevron-up"
        ></cigna-icon>
      </div>
    </div>
  </div>
</div>

<div *ngIf="expanded">
  <div class="data-wrapper">
    <ng-content></ng-content>
    <button *ngIf="showMoreLink" cignaButton="link" (click)="showMoreClick()">
      ..show more
    </button>
  </div>
  <div *ngIf="clearAll" style="border-top: 1px solid #e4e4e4">
    <button style="" cignaButton="link" (click)="clear.emit('all')">
      Clear All
    </button>
  </div>
</div>
