import {
  Component,
  Inject,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { HoverDropdownDirective } from '@cigna/chcp/shared/ui';

import { NavigationLink, ServiceError } from '@cigna/chcp/shared/util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RouterFacade } from '@cigna/shared/angular/core/router-state-data-access';
import { Subject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'cigna-header-user-util',
  templateUrl: './header-user-util.component.html',
  styleUrls: ['./header-user-util.component.scss'],
})
export class HeaderUserUtilComponent implements OnDestroy {
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() userid = '';
  @Input() profileLinks: NavigationLink[] = [];
  @Input() profileNavError?: ServiceError;
  @Input() isImpersonator = false;
  @Input() hasMessageCenterFunction = false;

  private _onDestroy$ = new Subject<void>();

  @ViewChildren(HoverDropdownDirective)
  dropdownList: QueryList<HoverDropdownDirective>;

  constructor(
    @Inject('inboxUrl') public inboxUrl: string,
    private routerFacade: RouterFacade,
  ) {
    this.routerFacade.url$
      .pipe(
        skip(1),
        filter(() => this.dropdownList && this.dropdownList.length > 0),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() =>
        this.dropdownList.forEach((d) => d.isOpen && d.onFocusOut()),
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
