<div class="profile-header container">
  <div class="row">
    <ng-container *ngIf="assets$ | async as assets; else defaultAssets">
      <div class="col-2">
        <div class="avatar">
          <img
            data-test-id="image-advocate-avatar"
            *ngIf="
              conversationId && assets.data && assets.data.get(conversationId)
            "
            [src]="
              assets.data.get(conversationId)
                ? cdn + assets.data.get(conversationId).avatar
                : ''
            "
            alt=""
          />
          <div
            class="avatar-default"
            *ngIf="assets.data && !assets.data.get(conversationId)"
          ></div>
        </div>
      </div>
      <div class="col-6">
        <div class="name" data-test-id="name-advocate">
          <div class="data" W>
            <span *ngIf="assets.data">{{
              conversationId && assets.data.get(conversationId)
                ? assets.data.get(conversationId).first_name
                : name
            }}</span>
          </div>
        </div>
        <div class="availability">
          <span
            data-test-id="text-advocate-matchedreply"
            *ngIf="!isChatInactive"
            >Replies within 24 hours</span
          >
          <span data-test-id="text-formeradvocate" *ngIf="isChatInactive"
            >Former Care Advocate</span
          >
        </div>
      </div>
    </ng-container>
    <div class="col-4 text-right display-grid" *ngIf="!isChatInactive">
      <button
        class="profile-button"
        data-test-id="button-profile"
        (click)="goToProfile()"
      >
        Profile
      </button>
    </div>
  </div>
  <div
    class="profile-content"
    data-test-id="content-profile"
    *ngIf="!isChatInactive"
  >
    {{ disclaimer }}
  </div>
</div>
<ng-template #defaultAssets>
  <div class="col-2">
    <div class="avatar">
      <div class="avatar-default"></div>
    </div>
  </div>
  <div class="col-6">
    <div class="name">
      <div class="data">
        <span>{{ name }}</span>
      </div>
    </div>
    <div class="availability">
      <span *ngIf="!isChatInactive">Replies within 24 hours</span>
      <span *ngIf="isChatInactive">Former Care Advocate</span>
    </div>
  </div>
</ng-template>
