import { Component, AfterViewInit } from '@angular/core';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';

@Component({
  selector: 'cigna-omni-chat-history',
  templateUrl: './history.component.html',
})
export class HistoryComponent implements AfterViewInit {
  public isLoadingHistory$ = this.conversationsFacade.isLoadingHistory$;

  constructor(
    private chatFacade: OmniChatFacade,
    private conversationsFacade: OmniConversationsFacade,
  ) {}

  ngAfterViewInit() {
    this.chatFacade.historyView();
  }
}
