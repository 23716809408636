import { IconDynamicData } from '../icon-dynamic';
import {
  ConversationDTO,
  ConversationMessageEventDTO,
  Category,
} from '@cigna/vampire-dto';
import {
  AvailabilityTypes,
  CommChannelContent,
  OmniArchiveHistoryModalInterface,
  OmniArchiveModalInterface,
  OmniInfoModalInterface,
  SupportAvailability,
  ChatBubbleContentId,
} from '../models';
import * as utils from './utils';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ScreenSize } from '@cigna/shared/angular/responsive-ui';

export const disclaimer =
  'This conversation provides no guarantee of benefits or payment for services under your plan. If you have a medical emergency or behavioral crisis, call 911 immediately.';
export const dmDisclaimer =
  'This conversation is between you and a member of your nurse advocate team. They are available during standard business hours on weekdays. Outside these hours, they will respond to messages on the next business day. If you have a medical emergency or behavioral crisis, call 911 immediately.';
export const newDMUIDisclaimer =
  'Direct messages will be answered within one business day. If you have a medical emergency or are in crisis, call 911 immediately.';

export const newDMUITerminatedUserMessage = {
  title: 'Live Chat and Direct Message features are not available',
  message:
    "Your account is no longer active so we can't start a new chat. You can still access your chat history.",
};

export const themeButtonIntro = 'Contact a:';

export const themeSOButtonText = 'Personal Guide';

export const themeCHEButtonText = 'Nurse';

export const soInitiateConversationButtonText = 'Contact a Personal Guide';

export const liveConversationDialogHeader = 'CHAT LIVE WITH A PERSONAL GUIDE';

export const asyncConversationDialogHeader = 'SEND A MESSAGE';

export const coachingIntroMessage =
  'Thank you. Check back here in the next business day for a response from your nurse.';

export const asyncScheduleMessage =
  'Thank you. A personal guide will call you at this number within one business day.';

export const supportIntroMessage =
  'Thank you. Check back here in the next business day for a response from your personal guide.';

export const agentJoinMessage = ' has joined the conversation.';

export const agentTransferMessage = ' has transferred your conversation.';

export const agentLeftMessage = ' has left your conversation.';

export const chatHours = 'Chat hours: Mon - Fri, 9 a.m. - 8 p.m. ET.';

export const chatSpanishDisclaimer =
  'Note: The chat is available only in english.';

export const chatBubbleContent: Record<
  ChatBubbleContentId,
  Record<ScreenSize.XSmall | ScreenSize.Medium, IconDynamicData>
> = {
  chatOpen: {
    [ScreenSize.XSmall]: { lineOne: 'Chat', lineTwo: '' },
    [ScreenSize.Medium]: { lineOne: 'Talk', lineTwo: 'with us' },
  },
  evernorthChatOpen: {
    [ScreenSize.XSmall]: { lineOne: 'Chat', lineTwo: '' },
    [ScreenSize.Medium]: { lineOne: 'Chat', lineTwo: 'with us' },
  },
  terminatedUser: {
    [ScreenSize.XSmall]: { lineOne: 'Chat History', lineTwo: '' },
    [ScreenSize.Medium]: { lineOne: 'View', lineTwo: 'Chat History' },
  },
  leaveMessage: {
    [ScreenSize.XSmall]: { lineOne: 'Msg', lineTwo: '' },
    [ScreenSize.Medium]: { lineOne: 'Leave a', lineTwo: 'message' },
  },
  chatClosed: {
    [ScreenSize.XSmall]: { lineOne: 'Closed', lineTwo: '' },
    [ScreenSize.Medium]: { lineOne: 'Chat', lineTwo: 'closed' },
  },
};

export const cigna: { [index: string]: string } = {
  phoneNumber: '(800) 244-6224',
};

export const links: { [index: string]: string } = {
  archive: 'View All Conversations',
  chatButtonSOText: 'Personal Guide',
  chatButtonCHEText: 'Nurse',
};

export const text: { [index: string]: string } = {
  chatIntroText: 'Contact a:',
};

export const infoModalContent: OmniInfoModalInterface = {
  archive: links.archive,
  aria: {
    closed:
      "You can leave us a message and we'll respond within one business day.",
  },
  callHours: '(24 hours a day, 365 days a year)',
  closed: 'Closed now',
  download: 'DOWNLOAD THIS CONVERSATION',
  header: 'Chat Hours',
  open: 'Open now',
  phoneNumber: cigna.phoneNumber,
};

export const archiveModalContent: OmniArchiveModalInterface = {
  lineOne:
    "Archived conversations are saved here. You won't be able to restart an archived conversation.",
  lineTwo: 'Are you sure you want to continue?',
  cancel: 'NO THANKS',
  archive: 'ARCHIVE CONVERSATION',
};

export const archiveHistoryModalContent: OmniArchiveHistoryModalInterface = {
  ...archiveModalContent,
  archive: 'Archive Conversation',
  cancel: 'No Thanks',
};

export const generateCHEAsyncSlaMessages = (customerName: string): string[] => [
  `Hi ${customerName}!`,
  'Send us your question and a <strong>Nurse</strong> will get back to you within one business day',
  `If you need medical advice now, call our <a href="/web/secure/my/providers/telehealth">24/7 nurse line</a>.<br /> <br />
    If you are experiencing a medical emergency, please call 911 immediately.`,
];

export const generateScheduleAsyncSlaMessages = (
  customerName: string,
): string[] => [
  `Hi ${customerName}!`,
  `Our personal guides are not available right now. Please type your message
     below and a personal guide will call you to set up a coaching appointment. <br /> <br />
     Please include your phone number and whether you would prefer a morning, afternoon, or evening appointment.`,
];

export const generateSOLiveSLAMessage =
  (): Partial<ConversationMessageEventDTO> => ({
    dialog: {
      acceptInput: false,
      answerText:
        'Welcome to live chat. Would you like to start a chat with a personal guide?',
      ctaList: {
        displayType: 'button',
        options: [
          {
            iconName: 'icon_thumbs_up',
            label: 'Start Chat',
            value: 'I have a question',
            isDefault: false,
          },
          {
            iconName: 'icon_thumbs_down',
            label: 'No Thanks',
            value: 'no',
            isDefault: false,
          },
        ],
      },
      type: 'cta_list',
    },
  });

export const liveScheduleCoachingUserMessage = `Hi, I'd like to schedule a coaching conversation.`;

export const generateSlaMessages = (
  context: ConversationDTO['context'] = { tags: ['support'] },
  customerName: string,
  type: ConversationDTO['type'] = 'async',
): string[] => {
  if (utils.isAsyncCHEConversation(context, type)) {
    return generateCHEAsyncSlaMessages(customerName);
  }
  if (utils.isAsyncScheduleConversation(context, type)) {
    return generateScheduleAsyncSlaMessages(customerName);
  }
  return [
    `Hi ${customerName}. It's after hours, but you can leave us a message and a personal guide will respond within 1 business day.`,
  ];
};

export const generateIntroMessage = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): string => {
  switch (true) {
    case utils.isClinicalNursingContextTags(context.tags):
      return coachingIntroMessage;
    case utils.isSupportOpsContextTags(context.tags):
      return supportIntroMessage;
    case utils.isAsyncScheduleConversation(context, type):
      return asyncScheduleMessage;
    default:
      return '';
  }
};

export const supportLivePersonalGuide: CommChannelContent = {
  header: 'Chat with a personal guide',
  icon: 'headset',
  body: [
    { text: 'Have a benefits question?', bold: true },
    { text: 'Coverage and claims' },
  ],
  buttonText: 'Chat with a Guide',
  buttonId: 'chatGuide',
};

export const supportAsyncPersonalGuide: CommChannelContent = {
  header: 'Message a personal guide',
  headerByline: `We'll reply back within one business day.`,
  icon: 'headset',
  body: [
    { text: 'Have a benefits question?', bold: true },
    { text: 'Coverage and claims' },
    { text: chatHours, bold: true },
  ],
  buttonText: 'Message a Guide',
  buttonId: 'messageGuide',
};

export const eapLivePersonalAdvocate: CommChannelContent = {
  header: 'Chat with a Personal Advocate',
  icon: 'headset',
  body: [
    { text: 'Have a question?', bold: true },
    { text: 'Live chat is available Monday-Friday, 9am-8pm ET' },
  ],
  buttonText: 'Chat with us',
  buttonId: 'messagePersonalAdvocate',
};

export const enLivePersonalAdvocate = (
  chatCloseHrsMsg?: string[],
): CommChannelContent => ({
  header: 'Chat with a Personal Advocate',
  icon: 'headset',
  body: [
    { text: 'Have a question?', bold: true },
    {
      text: `Live chat is available ${
        chatCloseHrsMsg?.length
          ? chatCloseHrsMsg.join(', ')
          : 'Monday-Friday, 9am-8pm ET'
      }`,
    },
  ],
  buttonText: 'Chat with us',
  buttonId: 'messagePersonalAdvocate',
});

export const eapNonLivePersonalAdvocate = (
  chatCloseHrsMsg?: string[],
): CommChannelContent => ({
  header: 'Live chat is closed',
  body: [
    {
      text: chatCloseHrsMsg?.length
        ? `Chat hours: ${chatCloseHrsMsg.join(', ')}`
        : chatHours,
      bold: true,
    },
    {
      text: `Please return during chat hours. We'll be happy to answer your questions here.`,
    },
  ],
  buttonText: '',
  buttonId: 'none',
});

export const supportLive: CommChannelContent = {
  header: 'Chat with a customer service advocate',
  headerByline: `We can help you with questions about coverages and claims.`,
  buttonText: 'Chat Now',
  buttonId: 'chatCustomerAdvocate',
};

export const supportNonLiveAsync: CommChannelContent = {
  header: 'Live chat is closed',
  body: [
    { text: chatHours, bold: true },
    {
      text: `Please return during chat hours. We'll be happy to answer your questions then.`,
    },
  ],
  buttonText: '',
  buttonId: 'none',
};

export const commChannelSupportContent: {
  [k in AvailabilityTypes]: CommChannelContent;
} = {
  isLivePersonalGuide: supportLivePersonalGuide,
  isAsyncPersonalGuide: supportAsyncPersonalGuide,
  isLive: supportLive,
  nonLiveAsync: supportNonLiveAsync,
};

export const commChannelCheLiveContent: CommChannelContent = {
  header: 'Chat with a nurse',
  icon: 'stethoscope',
  body: [
    { text: 'Have a health question?', bold: true },
    { text: 'Medical guidance and wellness' },
  ],
  buttonText: 'Chat with a Nurse',
  buttonId: 'chatClinician',
};

export const commChannelCheOutOfHoursContent: CommChannelContent = {
  header: 'Chat with a nurse is closed',
  body: [
    { text: 'Need to talk to a nurse?', bold: true },
    {
      text: `for medical guidance you can call 1 (855) 673-3063.`,
    },
  ],
  buttonText: '',
  buttonId: 'none',
};

export const generateCommChannelSOContent = (
  availability: SupportAvailability,
) => commChannelSupportContent[utils.getSupportType(availability)];

export const generateCommChannelCHEContent = (isLiveChatAvailable: boolean) => {
  if (isLiveChatAvailable) {
    return commChannelCheLiveContent;
  }
  return commChannelCheOutOfHoursContent;
};

export const generateCommChannelEAPContent = (isLiveChatAvailable: boolean) => {
  if (isLiveChatAvailable) {
    return eapLivePersonalAdvocate;
  }
  return eapNonLivePersonalAdvocate();
};

export const generateCommChannelENContent = (
  isLiveChatAvailable: boolean,
  chatCloseHrsMsg?: string[],
) => {
  if (isLiveChatAvailable) {
    return enLivePersonalAdvocate(chatCloseHrsMsg);
  }
  return eapNonLivePersonalAdvocate(chatCloseHrsMsg);
};

export const outroMessages = {
  message: 'Thanks for being a<br>Cigna customer.',
  evernorthMessage: 'Thank you for being an<br>Evernorth customer.',
  thankYouMessage: 'Thank you.',
  footer: 'This conversation is now closed.',
  agentTimeoutFooter:
    'Sorry, a personal guide is not available to join your chat. Please try again later.',
};

export const conversationStartContent: { [key in Category]: string } = {
  'medical coverage': 'Coverage',
  'medical claims': 'Claims',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  'medical care': 'Finding Care',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  'medical profile': 'Profiles and ID Cards',
  'medical incentives': 'Incentives and Biometrics',
  'dental coverage': 'Coverage',
  'dental claims': 'Claims',
  'dental care': 'Finding Care',
  'dental profile': 'Profiles and ID Cards',
  'pharmacy coverage': 'Coverage',
  'pharmacy claims': 'Claims',
  'pharmacy care': 'Finding Care',
  'pharmacy profile': 'Profiles and ID Cards',
  'pharmacy home delivery': 'Home Delivery Pharmacy',
  'state or company leave': 'State or Company Leave',
  'family or medical leave': 'Family or Medical Leave',
  'short term disability': 'Short Term Disability Leave',
  'eap counseling': 'Counseling',
  'eap schedule telephone': 'Scheduling Telephone Consultation',
  'legal financial identity theft': 'Legal, Financial, Identity Theft',
  'child senior pet care': 'Child/Senior/Pet Care',
  'career education services': 'Career or Education Services',
  'e health rewards': 'E-Health Rewards',
  navigation: 'Navigation Assistance',
  'documents forms': 'Documents and Forms',
  care: 'Managing Complex Care',
  guidance: 'Medical Guidance',
  wellness: 'Health & Wellness Coaching',
  mental: 'Mental Health & Substance Use',
  other: 'Something Else',
};

export const content = {
  agentLeftMessage,
  agentJoinMessage,
  agentTransferMessage,
  archiveHistoryModalContent,
  archiveModalContent,
  asyncConversationDialogHeader,
  cigna,
  disclaimer,
  dmDisclaimer,
  newDMUIDisclaimer,
  newDMUITerminatedUserMessage,
  generateIntroMessage,
  generateSlaMessages,
  generateSOLiveSLAMessage,
  infoModalContent,
  links,
  liveConversationDialogHeader,
  liveScheduleCoachingUserMessage,
  outroMessages,
  soInitiateConversationButtonText,
  themeButtonIntro,
  themeCHEButtonText,
  themeSOButtonText,
  chatSpanishDisclaimer,
};
