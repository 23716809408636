import {
  Directive,
  ViewContainerRef,
  TemplateRef,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';

import {
  Observable,
  BehaviorSubject,
  of,
  throwError,
  EMPTY,
  Subject,
  isObservable,
} from 'rxjs';
import {
  catchError,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';

import { AsyncData } from '@cigna/shared/angular/core/interfaces-util';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ErrorMessageComponent } from '../error-message/error-message.component';

enum LoadState {
  Waiting,
  Loaded,
  Error,
}

interface LoadEvent {
  state: LoadState;
  value?: unknown;
  error?: Error;
}

/**
 * Directive that hides its parent element until an observable fires, showing
 * a _loading state_ (usually a spinner) until then. If the observable throws an
 * error, displays an _error state_ instead.
 *
 * The value produced by the observable can be assigned to a variable using
 * a `let` expression.
 *
 * ```html
 * <ng-container *cignaWaitToLoad="observable$; let foo">
 *   <p>{{ foo }}</p>
 * </ng-container>
 * ```
 *
 * Custom loading state and error state templates can be assigned via the
 * options `loadingState` and `errorState`. `errorState`'s template can take
 * a `let` parameter, which will be assigned the error value.
 *
 * ```html
 * <ng-container
 *   *cignaWaitToLoad="observable$; let foo; loadingState: loading; errorState: error">
 *  <p>Loaded: {{ foo }}</p>
 * </ng-container>
 *
 * <ng-template #loading>
 *   <p>Loading…</p>
 * </ng-template>
 *
 * <ng-template #error let-e>
 *   <p>Error! {{ e.message }}</p>
 * </ng-template>
 * ```
 *
 * Setting `loadingState` or `errorState` to `null` will hide the default
 * components for those states.
 */
@Directive({
  selector: '[cignaWaitToLoad]',
})
export class WaitToLoadDirective implements OnInit, OnDestroy {
  @Input() cignaWaitToLoadLoadingState?: TemplateRef<unknown> | null;

  @Input() cignaWaitToLoadErrorState?: TemplateRef<unknown> | null;

  @Input() set cignaWaitToLoad(
    input$: Observable<unknown> | AsyncData<unknown>,
  ) {
    if (input$ instanceof AsyncData) {
      if (!isEqual(input$, this._cignaWaitToLoad)) {
        this.source$.next(of(input$));
      }
    } else if (isObservable(input$)) {
      this.source$.next(input$);
    } else {
      this.source$.next(
        throwError(
          new TypeError(
            `*cignaWaitToLoad expected an Observable or AsyncData, got ${input$}`,
          ),
        ),
      );
    }
    this._cignaWaitToLoad = input$;
  }

  private readonly source$ = new BehaviorSubject<Observable<unknown>>(EMPTY);
  private _cignaWaitToLoad: Observable<unknown> | AsyncData<unknown>;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private changeDetection: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.source$
      .pipe(
        switchMap(
          (input$): Observable<LoadEvent> =>
            input$.pipe(
              map((value: unknown) => {
                if (value instanceof AsyncData) {
                  if (value.loaded) {
                    if (value.error !== undefined) {
                      return { error: value.error, state: LoadState.Error };
                    }
                    return { value: value.data, state: LoadState.Loaded };
                  }
                  return { state: LoadState.Waiting };
                }
                return { value, state: LoadState.Loaded };
              }),
              catchError((error) => of({ error, state: LoadState.Error })),
              startWith({ state: LoadState.Waiting }),
            ),
        ),
        tap((event) => this.updateView(event)),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  private updateView(event: LoadEvent): void {
    this.viewContainer.clear();
    const { state, value, error } = event;
    switch (state) {
      case LoadState.Waiting:
        if (isUndefined(this.cignaWaitToLoadLoadingState)) {
          this.viewContainer.createComponent(SpinnerComponent);
        } else if (this.cignaWaitToLoadLoadingState !== null) {
          this.viewContainer.createEmbeddedView(
            this.cignaWaitToLoadLoadingState,
          );
        }
        break;
      case LoadState.Error:
        if (isUndefined(this.cignaWaitToLoadErrorState)) {
          // Error will not be undefined if state is LoadState.Error
          this.viewContainer.createComponent(
            ErrorMessageComponent,
          ).instance.error = error as Error;
        } else if (this.cignaWaitToLoadErrorState !== null) {
          this.viewContainer.createEmbeddedView(
            this.cignaWaitToLoadErrorState,
            {
              // Assign to any 'let' variable defined on the error template
              get $implicit(): unknown {
                return error;
              },
            },
          );
        }
        break;
      case LoadState.Loaded:
        this.viewContainer.createEmbeddedView(this.templateRef, {
          // If the microsyntax contains `let x` without `=`,
          // then the value of `$implicit` will be bound to `x`
          get $implicit(): unknown {
            return value;
          },
        });
    }
    this.changeDetection.markForCheck();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
