<div class="wrap" [class.full-width]="isFullWidth">
  <ng-content></ng-content>
  <button
    cignaButton="tertiary small"
    type="button"
    (click)="toggleVisibility()"
  >
    <mat-icon
      *ngIf="!isPasswordVisible"
      svgIcon="leaf-system-regular-visibility-false"
      aria-hidden="false"
      aria-label="show password"
    ></mat-icon>
    <mat-icon
      *ngIf="isPasswordVisible"
      svgIcon="leaf-system-regular-visibility-true"
      aria-hidden="false"
      aria-label="hide password"
    ></mat-icon>
  </button>
</div>
