import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileWithUrl, FileUploadErrors } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-file-upload-list',
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['./file-upload-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadListComponent {
  @Input() files: FileWithUrl[];
  @Input() isRemoveDisabled: boolean;
  @Input() customConfirmationText: string;

  @Output() removeFile = new EventEmitter<string>();
  @Output() fileNameClick = new EventEmitter<FileWithUrl>();

  @ViewChild('confirmationTemplate', { static: false })
  confirmationTemplate: TemplateRef<any>;

  dialogRef: MatDialogRef<any>;
  FileUploadErrors: { [key: string]: string } = FileUploadErrors;

  constructor(private dialog: MatDialog) {}

  onRemoveFile(fileWithUrl: FileWithUrl): void {
    if (!this.isRemoveDisabled) {
      if (fileWithUrl.error) {
        this.onConfirmRemoveFile(fileWithUrl.file.name);
      } else {
        this.dialogRef = this.dialog.open(this.confirmationTemplate, {
          autoFocus: false,
          disableClose: true,
          width: '600px',
          data: { fileName: fileWithUrl.file.name },
        });
      }
    }
  }

  onFileNameClick(file: FileWithUrl): void {
    this.fileNameClick.emit(file);
  }

  onConfirmRemoveFile(fileName: string): void {
    this.removeFile.emit(fileName);
  }

  trackByFn(_index: number, fileWithUrl: FileWithUrl) {
    return fileWithUrl.file.name;
  }
}
