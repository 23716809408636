import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sendLdAnalyticEvent, trackMetric } from './feature-toggles.actions';
import { LaunchDarklyStreamingService } from '../launchdarkly/streaming';
import { tap } from 'rxjs';

@Injectable()
export class FeatureTogglesEffects {
  constructor(
    private _actions$: Actions,
    private _ldStreamingService: LaunchDarklyStreamingService,
  ) {}

  sendLdAnalyticEvent$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(sendLdAnalyticEvent),
        tap(({ toggles }) =>
          toggles.forEach((toggle) =>
            this._ldStreamingService.variation(toggle),
          ),
        ),
      ),
    { dispatch: false },
  );

  trackMetric$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(trackMetric),
        tap(({ key }) => this._ldStreamingService.track(key)),
      ),
    { dispatch: false },
  );
}
