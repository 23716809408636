<ng-container *ngrxLet="routeData$ as routeData">
  <div class="cg-main-page-container">
    <header role="banner" *ngrxLet="isResourcePage$ as isResourcePage">
      <cigna-password-change-banner
        *ngIf="
          (showPasswordChangeWarning$ | async) && !routeData.disableNavigation
        "
        [lastChangedDate]="lastPasswordChangeDate$ | async"
      ></cigna-password-change-banner>

      <cigna-header
        [firstName]="firstName$ | async"
        [lastName]="lastName$ | async"
        [userid]="userid$ | async"
        [profileLinks]="profileNav$ | async"
        [profileNavError]="profileNavError$ | async"
        [isImpersonator]="impersonator$ | async"
        [isNavigationDisabled]="routeData.disableNavigation"
        [isAuthenticated]="true"
        [logoLink]="
          routeData.disableNavigation && !isResourcePage
            ? ''
            : (logoLink$ | async)
        "
        [isLogoLinkInternal]="!isResourcePage"
        [isResourceResults]="isResourcePage"
        [hasMessageCenterFunction]="messageCenterFunction$ | async"
        (logOut)="shellFacade.logOut($event)"
      ></cigna-header>

      <cigna-primary-nav
        *ngIf="!routeData.disableNavigation"
        class="cg-hide-for-print"
        [links]="siteNav$ | async"
        [isImpersonator]="impersonator$ | async"
        [error]="siteNavError$ | async"
      ></cigna-primary-nav>
      <cigna-alerts
        *ngIf="!routeData.disableNavigation"
        [alert]="alert$ | async"
      ></cigna-alerts>
    </header>

    <main
      role="main"
      [class.cg-padding-top-none]="(routeData$ | async)?.bluebar"
      [class.cg-padding-bottom-none]="(routeData$ | async)?.noBottomPadding"
    >
      <div
        [class.cg-inner-page-wrapper]="(routeData$ | async)?.bluebar !== true"
      >
        <router-outlet></router-outlet>
      </div>
    </main>

    <footer role="contentinfo">
      <cigna-footer-global
        [isLoggedIn]="true"
        [isMedical]="isMedicalUser$ | async"
      ></cigna-footer-global>
    </footer>
  </div>

  <aside aria-label="Live chat">
    <div class="chat-bubble-container" data-test-id="image-chat-bubble">
      <cigna-omni-entry></cigna-omni-entry>
    </div>
  </aside>

  <aside aria-label="Timeout modal" *ngIf="inBrowserPlatform">
    <cigna-timeout-popup></cigna-timeout-popup>
  </aside>

  <aside *ngIf="DEV_MODE">
    <cigna-dev-toggle class="cg-hide-for-print"></cigna-dev-toggle>
  </aside>
</ng-container>
