<button
  [attr.data-omni-qa]="omniQaTag"
  cignaButton="primary"
  (click)="buttonInteraction()"
  *ngIf="copy"
  type="button"
  class="omni-comm-channel-button"
>
  {{ copy }}
</button>
