import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OmniSharedUiModule } from '@cigna/omni/shared-util';
import { OmniInterceptionsStateModule } from '@cigna/omni/interceptions-state-data-access';
import { InterceptionComponent } from './interception/interception.component';
import { InterceptionsDataComponent } from './interceptions-data/interceptions-data.component';
import { InterceptionsListComponent } from './interceptions-list/interceptions-list.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    OmniInterceptionsStateModule,
    OmniSharedUiModule,
  ],
  declarations: [
    InterceptionComponent,
    InterceptionsDataComponent,
    InterceptionsListComponent,
  ],
  exports: [
    InterceptionComponent,
    InterceptionsDataComponent,
    InterceptionsListComponent,
  ],
})
export class OmniInterceptionsModule {}
