import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-icon-triangle',
  templateUrl: './icon-triangle.component.html',
  styleUrls: ['./icon-triangle.component.scss'],
})
export class IconTriangleComponent {
  @Input() size = 'medium';
  @Input() color = 'blue';
  @Input() direction = 'east';
}
