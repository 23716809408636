import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MFA_FEATURE_KEY, Mfa } from './mfa.reducer';

const getMfaState = createFeatureSelector<Mfa>(MFA_FEATURE_KEY);

const getSubmitting = createSelector(getMfaState, (state) => state.submitting);

const getServiceError = createSelector(
  getMfaState,
  (state) => state.serviceError,
);

const getWamTins = createSelector(getMfaState, (state) =>
  state.mfaTinDetails.map((detail) => ({
    tin: detail.tin,
    name: detail.name,
  })),
);

const getWamSelectedTins = createSelector(
  getMfaState,
  (state) => state.wamSelectedTins,
);

const getIsamMobileOnlyMfa = createSelector(getMfaState, (state) => ({
  isMobileOnlyMfa: state.isam.isMobileOnlyMfa,
}));

const getUpdatedEmail = createSelector(
  getMfaState,
  (state) => state.updatedEmail,
);

const getUpdatedMobilePhone = createSelector(
  getMfaState,
  (state) => state.updatedPhone,
);

const getIsamSelectedContact = createSelector(
  getMfaState,
  (state) => state.isam.selectedContact,
);

const getIsamContacts = createSelector(
  getMfaState,
  (state) => state.isam.contacts,
);
const getIsamStateAndHint = createSelector(getMfaState, (state) => ({
  stateId: state.isam.stateId,
  otpHint: state.isam.otpHint,
}));
const getIsamErrorMsg = createSelector(
  getMfaState,
  (state) => state.isam.errorMessage,
);
const getIsamSubmitting = createSelector(
  getMfaState,
  (state) => state.isam.submitting,
);
const getIsamRetryExceeded = createSelector(
  getMfaState,
  (state) => state.isam.retryExceeded,
);

const getMfaTinDetailsByWam = createSelector(
  getMfaState,
  (state) => state.mfaTinDetails,
);

const getMfaTinDetailsByWamUpdated = createSelector(getMfaState, (state) =>
  state.mfaTinDetailsUpdated.length
    ? state.mfaTinDetailsUpdated
    : state.mfaTinDetails.filter((t) => state.wamSelectedTins.includes(t.tin)),
);

const getMfaChanges = createSelector(getMfaState, (state) =>
  state.mfaTinDetailsUpdated.map((updatedMfaTinDetail) => {
    const oldMfaTinDetail = state.mfaTinDetails.find(
      (mfaTinDetail) => mfaTinDetail.tin === updatedMfaTinDetail.tin,
    );
    return {
      tin: updatedMfaTinDetail.tin,
      name: updatedMfaTinDetail.name,
      changes: updatedMfaTinDetail.jobRoles.filter(
        (jobRole, i) =>
          oldMfaTinDetail &&
          jobRole.mfaTypeName !== oldMfaTinDetail.jobRoles[i].mfaTypeName,
      ),
      noChanges: updatedMfaTinDetail.jobRoles.filter(
        (jobRole, i) =>
          oldMfaTinDetail &&
          jobRole.mfaTypeName === oldMfaTinDetail.jobRoles[i].mfaTypeName,
      ),
      // changes: oldMfaTinDetail
      //   ? updatedMfaTinDetail.jobRoles.filter(
      //       (jobRole, i) =>
      //         jobRole.mfaTypeName !== oldMfaTinDetail.jobRoles[i].mfaTypeName,
      //     )
      //   : [],
      // noChanges: oldMfaTinDetail
      //   ? updatedMfaTinDetail.jobRoles.filter(
      //       (jobRole, i) =>
      //         jobRole.mfaTypeName === oldMfaTinDetail.jobRoles[i].mfaTypeName,
      //     )
      //   : [],
    };
  }),
);

export const mfaQuery = {
  getSubmitting,
  getServiceError,
  getWamTins,
  getWamSelectedTins,
  getUpdatedEmail,
  getUpdatedMobilePhone,
  getIsamContacts,
  getIsamSelectedContact,
  getIsamStateAndHint,
  getIsamErrorMsg,
  getIsamRetryExceeded,
  getIsamSubmitting,
  getMfaTinDetailsByWam,
  getMfaTinDetailsByWamUpdated,
  getMfaChanges,
  getIsamMobileOnlyMfa,
};
