import { createReducer, on } from '@ngrx/store';
import { updateFeatureToggles } from './feature-toggles.actions';
import { ToggleValue } from './interfaces';

export const FEATURETOGGLES_FEATURE_KEY = 'root-feature-toggles';

export interface FeatureTogglesState {
  hasBeenUpdated: boolean;
  toggles: Record<string, ToggleValue>;
}

export interface FeatureTogglesPartialState {
  readonly [FEATURETOGGLES_FEATURE_KEY]: FeatureTogglesState;
}

export const initialState: FeatureTogglesState = {
  hasBeenUpdated: false,
  toggles: {},
};

export const featureTogglesReducer = createReducer(
  initialState,
  on(updateFeatureToggles, (state, { toggles, toggleType, namespace }) => ({
    ...state,
    hasBeenUpdated: true,
    toggles: {
      ...state.toggles,
      ...Object.keys(toggles).reduce(
        (acc: Record<string, ToggleValue>, curr) => {
          const key = namespace
            ? `${namespace}.${curr}.${toggleType}`
            : `${curr}.${toggleType}`;
          acc[key] = toggles[curr];
          return acc;
        },
        {},
      ),
    },
  })),
);
