import { NgModule } from '@angular/core';

import { SATELLITE, satelliteFactory } from './satellite';
import { DATA_STORE, dataStoreFactory } from './data-store';
import { adobeDataLayerFactory, ADOBE_DATA_LAYER } from './adobe-data-layer';

@NgModule({
  providers: [
    { provide: SATELLITE, useFactory: satelliteFactory },
    {
      provide: DATA_STORE,
      useFactory: dataStoreFactory,
    },
    {
      provide: ADOBE_DATA_LAYER,
      useFactory: adobeDataLayerFactory,
    },
  ],
})
export class AnalyticsDataAccessModule {}
