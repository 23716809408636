import { Store, select } from '@ngrx/store';

import { ShellState } from './shell.reducer';
import {
  siteNavLoad,
  headerNavLoad,
  reset,
  logout,
  secureAlertLoad,
  publicAlertLoad,
  loginStepup,
} from './shell.actions';
import { shellQuery } from './shell.selectors';
import { Injectable } from '@angular/core';
import { LogoutVariations } from '@cigna/chcp/shared/util';

@Injectable({
  providedIn: 'root',
})
export class ShellFacade {
  profileNav$ = this.store$.pipe(select(shellQuery.getProfileNavLinks));
  profileNavError$ = this.store$.pipe(select(shellQuery.getProfileNavError));
  siteNav$ = this.store$.pipe(select(shellQuery.getSiteNav));
  siteNavError$ = this.store$.pipe(select(shellQuery.getSiteNavError));
  alertSecure$ = this.store$.pipe(select(shellQuery.getSecureAlert));
  alertPublic$ = this.store$.pipe(select(shellQuery.getPublicAlert));

  constructor(private store$: Store<ShellState>) {}

  loadNav(): void {
    this.store$.dispatch(siteNavLoad());
    this.store$.dispatch(headerNavLoad());
  }

  loadSecureAlerts(): void {
    this.store$.dispatch(secureAlertLoad());
  }

  loadPublicAlerts(): void {
    this.store$.dispatch(publicAlertLoad());
  }

  logOut(data: { variation: LogoutVariations; linkName: string }): void {
    this.store$.dispatch(logout(data));
  }

  loginStepUp() {
    this.store$.dispatch(loginStepup());
  }

  reset(): void {
    this.store$.dispatch(reset());
  }
}
