import { Inject, Injectable } from '@angular/core';
import { ChcpAnalyticsService, authStatus } from '../chcp-analytics.service';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LinkType } from '@cigna/shared/angular/analytics-data-access';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MouseTrackEvent } from '@cigna/shared/angular/analytics-ui';

import { AppContext, RouteData, ServiceError } from '@cigna/chcp/shared/util';
import { EventExtraData } from '../chcp.rules';
import { DigitalDataObject } from './shell-track-handlers.model';
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';

@Injectable({ providedIn: 'root' })
export class ShellTrackHandlers {
  constructor(
    private _analytics: ChcpAnalyticsService,
    @Inject(APP_CONTEXT) private appContext: AppContext,
  ) {}

  updateStore(data: DigitalDataObject) {
    this._analytics.updateStore(data);
  }

  trackLogoutSuccess(data: { variation: string; linkName: string }) {
    this._analytics.trackEvent(
      'trackOther',
      {
        linkName: data.linkName,
        prop13: 'ac-Login002-US1012734-Login Logout',
        eVar8: authStatus.loggedOut,
        events: 'event2',
        prop20: data.linkName === 'Logout' ? 'nav-sub-utility' : null,
      },
      LinkType.OTHER,
    );
    this._analytics.clearUserStore();
    localStorage.setItem('adobeLoginSuccess', 'n');
  }

  trackLoginSuccess() {
    const loginSuccessHistory =
      localStorage.getItem('adobeLoginSuccess') || 'n';

    if (loginSuccessHistory === 'n') {
      this._analytics.trackEvent(
        'trackOther',
        {
          linkName: 'Login Success',
          prop13: 'ac-Login002-US1012734-Login Success',
          eVar8: authStatus.loggedIn,
          events: 'event1,event137',
        },
        LinkType.OTHER,
      );
      localStorage.setItem('adobeLoginSuccess', 'y');
    }
  }

  trackPageLoad(data: RouteData, _url: string | null) {
    if (data.analytics) {
      const appName =
        this.appContext === 'evernorth'
          ? 'EVRN Provider'
          : this.appContext.toUpperCase();
      this._analytics.trackPage('chcpPageLoadTrack', {
        channel: data.analytics.section,
        pageName: data.analytics.name,
        hier2: `${appName}|${data.analytics.section}|${data.analytics.name}`,
        eVar2: `${appName} - ${
          data.analytics.section
        } ${data.analytics.name.replace('|', ' ')}`,
        prop13: data.analytics.prop13,
        eVar51: data.analytics.eVar51,
        eVar119: data.analytics.eVar119,
        eVar173: data.analytics.eVar173,
        eVar8:
          data.analytics.loggedIn !== undefined && !data.analytics.loggedIn
            ? 'not logged in'
            : 'logged in',
        events: data.analytics.events,
      });
    }
  }

  /* use this only in extreme cases where page is not loaded from router, but we want to track as equivalent of a page view */
  trackPageLoadOnInit(
    event: TrackEvent,
    url: string,
    section: string,
    name: string,
    prop13: string,
    loggedIn: boolean = true,
    extra?: EventExtraData,
  ) {
    const appName =
      this.appContext === 'evernorth'
        ? 'EVRN Provider'
        : this.appContext.toUpperCase();
    this._analytics.trackPage('chcpPageLoadTrack', {
      prop13,
      ...extra,
      channel: section,
      pageName: `${name}`,
      hier2: `${appName}|${section}|${name}`,
      eVar2: `${appName} - ${section} ${name.replace('|', ' ')}`,
      eVar8: loggedIn ? 'logged in' : 'not logged in',
    });
  }

  // [cignaTrackClicks] and [cignaTrackInit] directives expect first param as the event
  // when calling this method outside a directive, such as from an effect pass {} for the event param
  trackEventOther(
    event: any,
    linkName: string,
    prop13: string,
    extra?: EventExtraData,
  ) {
    this._analytics.trackEvent(
      'trackOther',
      { linkName, prop13, ...extra },
      LinkType.OTHER,
    );
  }

  // todo: make eVar12 a required param, or try to auto generate it, eVar12 = linkName:[URL]
  trackEventExit(
    event: MouseTrackEvent,
    linkName: string,
    prop13: string,
    extra?: EventExtraData,
  ) {
    const target = event.mouseEvent.currentTarget as HTMLLinkElement;
    const eVar12 = `${linkName}:${target.href}`;
    const extraEvents = extra && extra.events;

    this._analytics.trackEvent(
      'trackOther',
      {
        ...extra,
        linkName,
        prop13,
        eVar12,
        prop12: 'D=v12',
        events: extraEvents ? `${extraEvents},event9` : 'event9',
      },
      LinkType.EXIT,
    );
  }

  trackEventDownload(
    event: MouseTrackEvent,
    linkName: string,
    prop13: string,
    extra?: EventExtraData,
  ) {
    const target = event.mouseEvent.currentTarget as HTMLLinkElement;
    const prop7 = `${linkName}:${target.href}`;
    const extraEvents = extra && extra.events;
    this._analytics.trackEvent(
      'trackOther',
      {
        ...extra,
        linkName,
        prop13,
        prop7,
        events: extraEvents ? `${extraEvents},event8` : 'event8',
      },
      LinkType.DOWNLOAD,
    );
  }

  // [cignaTrackInit] directive expect first param as the event
  // when calling this method outside the directive, such as from an effect pass {} for the event param
  trackServiceError(
    event: any,
    serviceName: string,
    error: ServiceError,
    customerMessage: string,
    additionalEvents?: string,
  ) {
    this._analytics.trackEvent(
      'trackOther',
      {
        prop13: 'ac-G001-Global Error',
        linkName: customerMessage,
        prop37: `Service Err^${serviceName}^${error.status.toString()}^${error.errMsg.replace(
          /[,;]/g,
          '',
        )}^${customerMessage}`,
        events: `event291${additionalEvents ? `,${additionalEvents}` : ''}`,
      },
      LinkType.OTHER,
    );
  }
}
