import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  featureTogglesReducer,
  FEATURETOGGLES_FEATURE_KEY,
  initialState as featureTogglesInitialState,
} from './+state/feature-toggles.reducer';
import { FeatureTogglesEffects } from './+state/feature-toggles.effects';
import {
  FeatureToggleLoaderConfig,
  FeatureToggleLoaderDep,
  LOADER,
} from './config';
import { FeatureEnabledPipe } from './feature-enabled.pipe';
import { FeatureValuePipe } from './feature-value.pipe';
import { FeatureToggleLoader } from './feature-toggle-loader';
import { FeatureToggleDirective } from './feature-toggle.directive';
import {
  useLaunchdarkly,
  UnwrappedDeps,
  SettingsAccesssor,
} from './launchdarkly/streaming';

const DECLARATIONS = [
  FeatureToggleDirective,
  FeatureEnabledPipe,
  FeatureValuePipe,
];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURETOGGLES_FEATURE_KEY, featureTogglesReducer, {
      initialState: featureTogglesInitialState,
    }),
    EffectsModule,
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class FeaturesModule {
  static withLoader<T extends FeatureToggleLoaderDep<unknown>>(
    loader: FeatureToggleLoaderConfig<T>,
  ): ModuleWithProviders<FeaturesModule> {
    return {
      ngModule: FeaturesModule,
      providers: [{ provide: LOADER, useValue: loader }, FeatureToggleLoader],
    };
  }

  static withLaunchDarkly<TDeps extends unknown[]>(
    settings: (...dep: UnwrappedDeps<TDeps>) => SettingsAccesssor,
    ...deps: TDeps
  ): ModuleWithProviders<FeaturesModule> {
    return {
      ngModule: FeaturesModule,
      providers: [
        ...useLaunchdarkly(settings, ...deps),
        ...(EffectsModule.forFeature([FeatureTogglesEffects]).providers || []),
      ],
    };
  }
}
