<div
  #chatHeader
  class="omni-chat-header-wrapper change-align"
  [ngClass]="[
    headerBackgroundClass$ | async,
    'enableChatShellDrag' ? 'is-movable' : ''
  ]"
  [class.dcm-chat-icons]="isDcmChat"
>
  <div *ngrxLet="currentComponent$ as currentComponent">
    <div
      class="back-cover"
      *ngIf="
        (isHistory && !(currentComponent == 'history' && isDcmChat)) ||
        currentComponent === 'activeDMsView' ||
        (currentComponent === 'dialog' &&
          canEnableNewDMUI &&
          (canGoBack$ | async) &&
          (conversation$ | async)?.type === 'async')
      "
    >
      <button
        [disabled]="isBackButtonDisabled$ | async"
        (click)="backToDialog()"
        data-qa-omni="headerBackButton"
        data-test-id="button-header-back"
        aria-label="back"
      >
        <cigna-icon type="chevron-thin-left" class="back"></cigna-icon>
      </button>
    </div>
    <ng-container *ngIf="!isDcmChat || (showOptionsMenu$ | async)">
      <div
        *ngIf="
          currentComponent != 'history' && currentComponent != 'activeDMsView'
        "
        id="menu_icon"
        class="icon-more"
        data-test-id="icon-header-menu"
        [ngClass]="{ disabledClass: shouldCloseSplashScreen }"
        tabindex="0"
        aria-label="menu"
        aria-haspopup="menu"
        role="button"
        (click)="isMenuOpen = !isMenuOpen"
        (keyup.enter)="isMenuOpen = !isMenuOpen"
        (focus)="isMenuOpen = false"
      >
        <cigna-icon type="icon-more" class="more-on"></cigna-icon>
      </div>
      <nav
        aria-label="menu dropdown"
        [ngClass]="isMenuOpen ? 'open' : 'closed'"
        class="menu nav-submenu"
        data-test-id="menu-dropdown"
        role="menu"
      >
        <ul class="parent-ul">
          <li class="menuItem" data-test-id="list-menuitem1">
            <button
              tabindex="0"
              (click)="toggleMute()"
              *ngIf="isUnmuted$ | async"
            >
              <cigna-icon
                type="mute"
                (click)="toggleMute()"
                class="volume-onoff"
                *ngIf="isUnmuted$ | async"
              ></cigna-icon
              ><span>Mute Sounds</span>
            </button>
            <button
              tabindex="0"
              (click)="toggleMute()"
              *ngIf="(isUnmuted$ | async) === false"
            >
              <cigna-icon
                type="sound"
                (click)="toggleMute()"
                class="volume-onoff"
                *ngIf="(isUnmuted$ | async) === false"
              ></cigna-icon
              ><span>Unmute Sounds</span>
            </button>
          </li>
          <hr class="divider" />
          <li class="menuItem" data-test-id="list-menuitem2">
            <button tabindex="0" (click)="printEmitter.emit()">
              <cigna-icon type="file" class="volume-onoff"></cigna-icon
              ><span>Chat Transcript</span>
            </button>
          </li>
        </ul>
      </nav>
    </ng-container>
  </div>

  <div class="header-title-container">
    <h2
      #headerTitle
      tabindex="-1"
      [ngClass]="{ 'header-title': true, 'dcm-header-title': isDcmChat }"
      data-qa-omni="headerTitle"
      data-test-id="header-title-chat"
      (focus)="isMenuOpen = false"
    >
      {{ title$ | async }}
    </h2>
  </div>
  <div *ngrxLet="shouldCloseOrExit$ as shouldCloseOrExit">
    <button
      (click)="closeOrEndChat(!shouldCloseOrExit)"
      data-qa-omni="closeOrEndChat"
      data-test-id="button-close"
      aria-label="close"
      (focus)="isMenuOpen = false"
    >
      <ng-container *ngIf="toggleButton$ | async as button">
        <cigna-icon type="close-thin" class="closeIcon"></cigna-icon>
      </ng-container>
    </button>
  </div>
</div>
