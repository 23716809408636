import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phone: string, separator?: string): string {
    const digitArray = phone.trim().replace(/\D/g, '').slice(-10).split('');

    // if we don't have valid phone number just return original string
    if (digitArray.length !== 10) {
      return phone;
    }

    return [
      digitArray.slice(0, 3).join(''),
      digitArray.slice(3, 6).join(''),
      digitArray.slice(6, 10).join(''),
    ].join(separator ? separator : '.');
  }
}
