<div
  *ngFor="let repeater of repeatTimes; let hi = index"
  [style.margin-right]="hi < repeatTimes.length - 1 ? lineGapH : null"
  class="sk-group {{ repeater }}"
>
  <div
    *ngFor="let line of lines; let vi = index"
    [style.margin-top]="vi > 0 ? lineGapV : null"
    [style.width]="line"
  >
    <span class="sk-line">&nbsp;</span>
  </div>
</div>
