import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

import { ChcpSharedUiModule } from '@cigna/chcp/shared/ui';
import { AnalyticsUiModule } from '@cigna/shared/angular/analytics-ui';
import { ChcpSharedEnvironmentUtilModule } from '@cigna/chcp/shared/environment-util';

import { UiInputModule } from '@cigna/shared/angular/input-ui';
import { UiButtonModule } from '@cigna/shared/angular/button-ui';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { UiProgressModule } from '@cigna/shared/angular/progress-ui';
import { UiCollapsibleModule } from '@cigna/shared/angular/collapsible-ui';
import { UiModalModule } from '@cigna/shared/angular/modal-ui';

import { DialogSiteHelpPublicComponent } from './dialog-site-help-public/dialog-site-help-public.component';
import { DialogSiteHelpSecureComponent } from './dialog-site-help-secure/dialog-site-help-secure.component';
import { DialogLegalDisclaimerComponent } from './dialog-legal-disclaimer/dialog-legal-disclaimer.component';
import { DialogSiteTourComponent } from './dialog-site-tour/dialog-site-tour.component';
import { DialogPrivacyComponent } from './dialog-privacy/dialog-privacy.component';
import { ResourcesSearchFormComponent } from './resources-search-form/resources-search-form.component';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { HeaderUserUtilComponent } from './header-user-util/header-user-util.component';
import { PrimaryNavComponent } from './primary-nav/primary-nav.component';
import { NavLinksComponent } from './nav-links/nav-links.component';
import { UnavailableComponent } from './unavailable/unavailable.component';
import { HeaderComponent } from './header/header.component';
import { FooterGlobalComponent } from './footer-global/footer-global.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { NavItemComponent } from './nav-item/nav-item.component';
import { AlertsComponent } from './alerts/alerts.component';
import { DevToggleComponent } from './dev-toggle/dev-toggle.component';
import { PasswordChangeBannerComponent } from './password-change-banner/password-change-banner.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DialogProviderRelationsComponent } from './dialog-provider-relations/dialog-provider-relations.component';
import { DialogSecureAlertComponent } from './dialog-secure-alert/dialog-secure-alert.component';
import { DisabledPageComponent } from './disabled-page/disabled-page.component';
import { ChcpSharedLeafIconsUiModule } from '@cigna/chcp/shared/leaf-icons-ui';
import { CignaLeafBadgeComponent } from '@cigna/shared/angular/leaf-ui';
import { AlertsV2Component } from './alerts-v2/alerts-v2.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ChcpSharedUiModule,
    RouterModule,
    UiInputModule,
    UiButtonModule,
    UiIconModule,
    UiProgressModule,
    UiModalModule,
    UiCollapsibleModule,
    AnalyticsUiModule,
    ChcpSharedEnvironmentUtilModule,
    ChcpSharedLeafIconsUiModule,
    CignaLeafBadgeComponent,
  ],
  declarations: [
    DialogSiteHelpPublicComponent,
    DialogSiteHelpSecureComponent,
    DialogLegalDisclaimerComponent,
    DialogSiteTourComponent,
    DialogPrivacyComponent,
    DialogProviderRelationsComponent,
    ResourcesSearchFormComponent,
    HeaderLogoComponent,
    HeaderUserUtilComponent,
    PrimaryNavComponent,
    NavLinksComponent,
    UnavailableComponent,
    HeaderComponent,
    FooterGlobalComponent,
    BreadcrumbsComponent,
    NavItemComponent,
    AlertsComponent,
    AlertsV2Component,
    DevToggleComponent,
    PasswordChangeBannerComponent,
    NotFoundComponent,
    DialogSecureAlertComponent,
    DisabledPageComponent,
  ],
  exports: [
    ResourcesSearchFormComponent,
    HeaderLogoComponent,
    HeaderUserUtilComponent,
    PrimaryNavComponent,
    NavLinksComponent,
    NavItemComponent,
    UnavailableComponent,
    HeaderComponent,
    FooterGlobalComponent,
    BreadcrumbsComponent,
    AlertsComponent,
    AlertsV2Component,
    DevToggleComponent,
    PasswordChangeBannerComponent,
    NotFoundComponent,
    DialogProviderRelationsComponent,
    DialogSecureAlertComponent,
    DialogLegalDisclaimerComponent,
    DialogPrivacyComponent,
    DialogSiteHelpPublicComponent,
    DialogSiteHelpSecureComponent,
    DialogSiteTourComponent,
  ],
})
export class ChcpShellUiModule {}
