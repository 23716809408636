import { Component } from '@angular/core';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniCommChannelFacade } from '../+state/comm-channel.facade';

@Component({
  selector: 'cigna-omni-my-conversations',
  templateUrl: './my-conversations-card.component.html',
  styleUrls: ['./my-conversations-card.component.scss'],
})
export class MyConversationsCardComponent {
  public hasNoHistory$ = this.commChannelFacade.hasNoHistory$;
  public summaryListItem$ =
    this.commChannelFacade.recentActiveOrUnreadConversationSummaryListItem$;

  constructor(
    private chatFacade: OmniChatFacade,
    private commChannelFacade: OmniCommChannelFacade,
  ) {}

  public openHistoryPage() {
    this.chatFacade.openHistory();
  }
}
