import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { UiButtonModule } from '@cigna/shared/angular/button-ui';

import { CollapsibleBlockComponent } from './collapsible-block/collapsible-block.component';
import { CollapsibleGroupComponent } from './collapsible-group/collapsible-group.component';
import { CollapsibleItemComponent } from './collapsible-item/collapsible-item.component';
import { CollapsibleComponent } from './collapsible/collapsible.component';

const declarations = [
  CollapsibleBlockComponent,
  CollapsibleGroupComponent,
  CollapsibleItemComponent,
  CollapsibleComponent,
];

@NgModule({
  declarations,
  imports: [CommonModule, UiIconModule, UiButtonModule],
  exports: declarations,
})
export class UiCollapsibleModule {}
