import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as DcmAssetsActions from './dcm.actions';
import { DcmAssetsState } from './dcm.reducer';
import * as DcmAssetsSelectors from './dcm.selector';
import { DcmAssetsService } from './dcm.service';

@Injectable()
export class DcmAssetsFacade {
  constructor(
    private _store$: Store<DcmAssetsState>,
    private service: DcmAssetsService,
  ) {}

  DcmAssets$ = this._store$.pipe(select(DcmAssetsSelectors.getDcmAssets));

  loadAssets(fileName: string) {
    this._store$.dispatch(DcmAssetsActions.getDcmAssets({ fileName }));
  }
}
