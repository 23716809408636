import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { initiateConversation } from './chat-link.actions';
import { withLatestFrom, concatMap } from 'rxjs/operators';
import {
  CreateConversation,
  OmniConversationsFacade,
  EndConversation,
} from '@cigna/omni/conversations-state-data-access';
import { OmniLiveAvailabilityFacade } from '@cigna/omni/live-availability-state-data-access';
import { Action } from '@ngrx/store';

@Injectable()
export class ChatLinkEffects {
  startConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateConversation),
      withLatestFrom(
        this.liveAvailabilityFacade.liveChatAvailable$,
        this.conversationsFacade.conversation$,
      ),
      concatMap(
        ([{ product, category }, isLiveAvailable, currentConversation]) => {
          const actions: Action[] = [
            new CreateConversation({
              type: isLiveAvailable ? 'live' : 'async',
              context: {
                tags: ['support'],
                meta: { ROUTING_PRODUCT: product, ROUTING_REASON: category },
              },
            }),
          ];

          if (
            !!currentConversation &&
            currentConversation.type === 'bot' &&
            currentConversation.state !== 'closed'
          ) {
            actions.unshift(new EndConversation(currentConversation._id));
          }

          return actions;
        },
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private liveAvailabilityFacade: OmniLiveAvailabilityFacade,
    private conversationsFacade: OmniConversationsFacade,
  ) {}
}
