/* eslint-disable deprecation/deprecation */
import { Injectable, Inject } from '@angular/core';
import {
  ADOBE_DATA_LAYER,
  AdobeDataLayer,
  DataLayerError,
  DataLayerPageAction,
  DataLayerPageLoad,
} from './adobe-data-layer';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(
    @Inject(ADOBE_DATA_LAYER) private adobeDataLayer: AdobeDataLayer,
  ) {}

  trackPageAction(data: DataLayerPageAction) {
    this.updateAdobeDataLayer(data);
  }

  trackPageLoad(data: DataLayerPageLoad) {
    this.updateAdobeDataLayer(data);
  }

  trackError(data: DataLayerError) {
    this.updateAdobeDataLayer(data);
  }

  /**
   * @deprecated
   *
   * avoid calling 'updateAdobeDataLayer'' directly, instead use strongly typed event methods such as 'trackPageAction' and 'trackPageLoad'
   * in the future change this to be a private method
   */
  updateAdobeDataLayer(data?: object) {
    if (!data) {
      return;
    }
    if (!this.adobeDataLayer) {
      // eslint-disable-next-line no-console
      console.warn(
        'Adobe Analytics Data Layer not present, cannot update with',
        {
          data,
        },
      );
      return;
    }
    this.adobeDataLayer.push(data);
  }
}
