import { InjectionToken } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppMeasurement } from '@cigna/shared/angular/core/interfaces-util';

export const APP_MEASUREMENT = new InjectionToken<AppMeasurement>('s');

// eslint-disable-next-line no-var
export declare var s: AppMeasurement;

export function appMeasurementFactory(): AppMeasurement {
  if (typeof s !== 'undefined') {
    return s;
  }

  // eslint-disable-next-line no-console
  console.warn(
    's not detected, analytics events will be added to queue and loaded later if library appears',
  );
}
