import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';

import '@esi/leaf-web/lib/wc/components/inline-alert/inline-alert';
import type { LeafInlineAlert } from '@esi/leaf-web/lib/wc';

@Component({
  selector: 'cigna-leaf-inline-alert',
  template: `
    <leaf-inline-alert
      [fullWidth]="fullWidth"
      [iconTitle]="iconTitle"
      [variant]="variant"
      [status]="status"
    >
      <ng-content></ng-content>
    </leaf-inline-alert>
  `,
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CignaLeafInlineAlertComponent {
  @Input() fullWidth: LeafInlineAlert['fullWidth'];
  @Input() iconTitle: LeafInlineAlert['iconTitle'];
  @Input() variant: LeafInlineAlert['variant'] = 'subtle';
  @Input() status: LeafInlineAlert['status'];
}
