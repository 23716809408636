import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalDirective } from './portal.directive';
import { PortalExitComponent } from './portal-exit.component';

const DECLARATIONS = [PortalDirective, PortalExitComponent];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class UiPortalsModule {}
