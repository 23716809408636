import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { logError, checkValidResponse } from '@cigna/chcp/shared/util';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  public SERVICE_CONSUMER_CODE = '1000';
  public flagApi: string;

  constructor(
    private http: HttpClient,
    @Inject('secureApi') secureApi: string,
  ) {
    this.flagApi = `${secureApi}/flag/v1/toggle`;
  }

  getOpsToggles() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.SERVICE_CONSUMER_CODE,
    );

    return this.http
      .get(`${this.flagApi}`, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) => res.features),
        catchError(logError),
      );
  }
}
