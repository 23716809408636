import { Component, Input, OnInit } from '@angular/core';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { TranslateService } from '@ngx-translate/core';
import { content } from '@cigna/omni/shared-util';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Component({
  selector: 'cigna-omni-messaging-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class OmniMessagingDisclaimerComponent implements OnInit {
  @Input() public shouldEnablePreChatWelcome: boolean;
  @Input() public isHistory: boolean;
  @Input() public disclaimer: string;
  @Input() public isHistoryLinkEnable: boolean;
  @Input() public isUpdatingConversation: boolean;
  @Input() public isTerminatedUser: boolean;
  @Input() public canEnableNewDMUI: boolean;
  @Input() public conversationType: string;
  @Input() currentLanguage: string;
  public isReleaseFlagEnabled$ = this.features.featuresEnabled([
    'ftr-mycigna-omni-web-chat-01-29-2025-release',
  ]);
  public historyLabel = 'Looking for older conversations?';
  public historyButtonText: string;
  public chatSpanishDisclaimer = content.chatSpanishDisclaimer;

  constructor(
    private chatFacade: OmniChatFacade,
    private conversationsFacade: OmniConversationsFacade,
    private _translate: TranslateService,
    private features: FeatureTogglesFacade,
  ) {}

  ngOnInit() {
    this.historyButtonText = this.canEnableNewDMUI
      ? 'Go to conversation history'
      : 'View Chat History';
  }

  public openHistory() {
    this.chatFacade.openHistory();
    this.conversationsFacade.getConversationHistory();
    this.conversationsFacade.setHeaderFocus(true);
  }

  get isSpanishActive() {
    return this._translate.currentLang === 'es-us';
  }
}
