import { Injectable } from '@angular/core';
import { InterceptionDTO } from '@cigna/vampire-dto';
import { select, Store } from '@ngrx/store';
import { interceptionsActions } from './interceptions.actions';
import { InterceptionsState } from './interceptions.reducer';
import { interceptionsQuery } from './interceptions.selectors';

@Injectable()
export class OmniInterceptionsFacade {
  public allInterceptions$ = this.store$.pipe(
    select(interceptionsQuery.selectAllInterceptions),
  );
  public displayMax$ = this.store$.pipe(
    select(interceptionsQuery.selectInterceptionsDisplayMax),
  );
  public interceptionsToPresent = this.store$.pipe(
    select(interceptionsQuery.selectInterceptionsToPresent),
  );
  public shouldShowInterceptions = this.store$.pipe(
    select(interceptionsQuery.shouldShowInterceptions),
  );

  constructor(private store$: Store<InterceptionsState>) {}

  public addMany(interceptions: InterceptionDTO[]): void {
    this.store$.dispatch(
      new interceptionsActions.InterceptionsAddMany({ interceptions }),
    );
  }

  public addOne(interception: InterceptionDTO): void {
    this.store$.dispatch(
      new interceptionsActions.InterceptionsAddOne({ interception }),
    );
  }

  public dismiss(id: string): void {
    this.store$.dispatch(new interceptionsActions.InterceptionsDismiss({ id }));
  }

  public engage(interception: InterceptionDTO): void {
    this.store$.dispatch(
      new interceptionsActions.InterceptionsEngage({ interception }),
    );
  }

  public snooze(id: string): void {
    this.store$.dispatch(new interceptionsActions.InterceptionsSnooze({ id }));
  }

  public viewed(id: string): void {
    this.store$.dispatch(new interceptionsActions.InterceptionsViewed({ id }));
  }
}
