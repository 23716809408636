import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  RIGHTNOW_FEATURE_KEY,
  initialState as rightNowInitialState,
  rightNowReducer,
} from './+state/right-now.reducer';
import { RightNowEffects } from './+state/right-now.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(RIGHTNOW_FEATURE_KEY, rightNowReducer, {
      initialState: rightNowInitialState,
    }),
    EffectsModule.forFeature([RightNowEffects]),
  ],
})
export class OmniRightNowDataAccessModule {}
