import { Injectable, Optional } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  ConversationDiff,
  ConversationsActionTypes,
  CreateConversationSuccess,
  EndConversation,
  OmniConversationsFacade,
  TransferConversationFailure,
  SetSelectedConversationId,
  LoadConversationsSuccess,
} from '@cigna/omni/conversations-state-data-access';
import { ConversationSummaryDTO, ConversationEvent } from '@cigna/vampire-dto';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, combineLatest, EMPTY } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  Back,
  chatActions,
  ChatActionTypes,
  OpenConversation,
  OpenDMHistory,
  ToggleChatState,
  SetChatByClientList,
  SetSelectedClientDetail,
} from './chat.actions';
import { OmniChatFacade } from './chat.facade';
import { Router, ActivatedRoute } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  FeatureTogglesFacade,
  FeatureToggleType,
} from '@cigna/shared/angular/features-feature';

@Injectable()
export class ChatEffects {
  closeConversationOnBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Back>(ChatActionTypes.Back),
        withLatestFrom(
          this.features.featuresEnabled(['enableNewPreChatWelcome']),
        ),
        filter(([, enableNewPreChatWelcome]) => !enableNewPreChatWelcome),
        withLatestFrom(this.conversationsFacade.conversation$),
        map(([, conv]) => conv),
        tap((conv: ConversationSummaryDTO) => {
          if (
            conv?.type !== 'async' &&
            !['closed', 'transfer'].includes(conv.state)
          ) {
            return this.conversationsFacade.endConversation(conv._id);
          }
          this.conversationsFacade.setConversationId('');
        }),
      ),
    { dispatch: false },
  );

  conversationDiff$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ConversationDiff>(ConversationsActionTypes.ConversationDiff),
      withLatestFrom(
        this.chatFacade.isShellMinimized$,
        this.conversationsFacade.conversation$,
      ),
      filter(
        ([
          {
            payload: { _id },
          },
          minimized,
          conversation,
        ]) => !!conversation && conversation._id === _id && minimized,
      ),
      map(
        ([
          {
            payload: { newEvents },
          },
        ]) =>
          newEvents.find((event) =>
            ['message', 'participant', 'state'].includes(event.type),
          ),
      ),
      filter((event): event is ConversationEvent => !!event),
      map((event) => new chatActions.AutoMaximize(event.type)),
    ),
  );

  createNewConversationSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateConversationSuccess>(
        ConversationsActionTypes.CreateConversationSuccess,
      ),
      withLatestFrom(this.chatFacade.isShellOpen$),
      concatMap(([_, open]) => {
        const actions: Action[] = [new chatActions.OpenDialog()];
        if (!open) {
          actions.push(new chatActions.AutoMaximize('state'));
        }
        return actions;
      }),
    ),
  );

  toggleChatState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ToggleChatState>(ChatActionTypes.ToggleChatState),
      withLatestFrom(
        this.chatFacade.isShellMinimized$,
        this.chatFacade.shouldClose$,
      ),
      map(([, minimized, shouldClose]) => {
        if (shouldClose) {
          return new chatActions.HeaderClose();
        }

        if (minimized) {
          return new chatActions.HeaderMaximize();
        }

        return new chatActions.HeaderMinimize();
      }),
    ),
  );

  transferConversationFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<TransferConversationFailure>(
        ConversationsActionTypes.TransferConversationFailure,
      ),
      withLatestFrom(this.conversationsFacade.conversation$),
      filter(
        ([, conversation]) => !!conversation && conversation.type === 'bot',
      ),
      mergeMap(([, conversation]) => {
        const actions: Action[] = [new chatActions.GoHome()];
        if (conversation && conversation.state !== 'closed') {
          actions.push(new EndConversation(conversation._id));
        }
        return actions;
      }),
    ),
  );

  openConversationOnQueryParam$ = createEffect(() =>
    combineLatest([
      this.route
        ? this.route.queryParamMap.pipe(
            map((query) => query.get('conversationId') as string),
          )
        : EMPTY,
      this.conversationsFacade.conversationsEntities$.pipe(
        filter((conversations) => !!Object.keys(conversations).length),
      ),
    ]).pipe(
      filter(([conversationId]) => !!conversationId),
      tap(() =>
        this.router?.navigate([], {
          /**
           * Merging while setting our param to null allows us to remove only that
           * param without affecting other query params.
           */
          queryParams: { conversationId: null },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        }),
      ),
      filter(([conversationId, entities]) => conversationId in entities),
      map(
        ([conversationId]) => new chatActions.OpenConversation(conversationId),
      ),
    ),
  );

  openActiveLiveConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadConversationsSuccess>(
        ConversationsActionTypes.LoadConversationsSuccess,
      ),
      withLatestFrom(this.conversationsFacade.activeLiveConversation$),
      map(([, activeLive]) => activeLive),
      filter(
        (activeLive): activeLive is ConversationSummaryDTO => !!activeLive,
      ),
      map(({ _id }) => new chatActions.OpenConversation(_id)),
    ),
  );

  openConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OpenConversation>(ChatActionTypes.OpenConversation),
      concatMap(({ convId }) => [
        new SetSelectedConversationId(convId),
        new chatActions.OpenDialog(),
        new chatActions.AutoMaximize('state'),
      ]),
    ),
  );

  openDMHistory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OpenDMHistory>(ChatActionTypes.OpenDMHistory),
        withLatestFrom(
          this.chatFacade.isShellClosed$,
          this.chatFacade.isShellMinimized$,
        ),
        tap(([, isShellClosed, isShellMinimized]) => {
          if (isShellClosed) {
            this.chatFacade.showDMHistory();
            this.conversationsFacade.getConversationHistory();
          }
          if (isShellMinimized) {
            this.chatFacade.toggleChatState();
          }
        }),
      ),
    { dispatch: false },
  );

  setChatByClientList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SetChatByClientList>(ChatActionTypes.SetChatByClientList),
        withLatestFrom(
          this.chatFacade.getChatByClientList$,
          this.chatFacade.getSelectedClientDetail$,
        ),
        map(([, generalChatByClient, selectedClientDetail]) =>
          selectedClientDetail?.cs1id
            ? generalChatByClient?.[selectedClientDetail.cs1id]
            : false,
        ),
        tap((enableChat: boolean) => {
          this.features.updateFeatureToggles(
            { canDisplayRestrictedBubble: enableChat },
            FeatureToggleType.OPS,
          );
        }),
      ),
    { dispatch: false },
  );

  setSelectedClientDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SetSelectedClientDetail>(
          ChatActionTypes.SetSelectedClientDetail,
        ),
        withLatestFrom(
          this.chatFacade.getChatByClientList$,
          this.chatFacade.getSelectedClientDetail$,
        ),
        tap(([, generalChatByClient, selectedClientDetail]) => {
          this.conversationsFacade.updateConversationMetadata({
            meta: {
              clientId: selectedClientDetail?.clientId,
              chatCode: selectedClientDetail?.chatCode,
            },
            dataType: 'cfe',
          });
        }),
        filter(
          ([, generalChatByClient, selectedClientDetail]) =>
            generalChatByClient !== undefined,
        ),
        // eslint-disable-next-line sonarjs/no-identical-functions
        map(([, generalChatByClient, selectedClientDetail]) =>
          selectedClientDetail?.cs1id
            ? generalChatByClient?.[selectedClientDetail.cs1id]
            : false,
        ),
        // eslint-disable-next-line sonarjs/no-identical-functions
        tap((enableChat: boolean) => {
          this.features.updateFeatureToggles(
            { canDisplayRestrictedBubble: enableChat },
            FeatureToggleType.OPS,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    public actions$: Actions,
    public chatFacade: OmniChatFacade,
    public conversationsFacade: OmniConversationsFacade,
    private features: FeatureTogglesFacade,
    @Optional() private route?: ActivatedRoute,
    @Optional() private router?: Router,
  ) {}
}
