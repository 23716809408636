<nav class="inner-wrapper">
  <cigna-skeleton
    *ngIf="links.length === 0 && !error; else nav"
    repeat="5"
    layout="10"
    gap="0 .4"
    class="nav-skeleton sk-left"
  >
  </cigna-skeleton>

  <ng-template #nav>
    <ng-container *ngFor="let link of links || []">
      <div *ngIf="!link.children?.length; else dropdown" class="nav-item">
        <div class="nav-link-style cg-link-color">
          <cigna-nav-item
            [navItem]="link"
            [dataTestAttribute]="'primary-nav-' + link.identifier"
            [isTopLevel]="true"
            [isImpersonator]="isImpersonator"
          ></cigna-nav-item>
        </div>
      </div>

      <ng-template #dropdown>
        <div
          class="nav-item nav-dropdown"
          cignaHoverDropdown
          [attr.data-test]="'primary-nav-dropdown-' + link.identifier"
        >
          <div class="nav-link-style cg-link-color">
            <ng-container *ngIf="link.path; else tpl">
              <cigna-nav-item
                [navItem]="link"
                [dataTestAttribute]="'primary-nav-' + link.identifier"
                [isTopLevel]="true"
                [isImpersonator]="isImpersonator"
                [hasChildren]="true"
              ></cigna-nav-item>
            </ng-container>
            <ng-template #tpl> {{ link.title }} </ng-template>
          </div>

          <cigna-nav-links
            *ngIf="link.children"
            #dropdownMenu
            class="nav-dropdown__menu"
            [links]="link.children"
            [isImpersonator]="isImpersonator"
          >
          </cigna-nav-links>
        </div>
      </ng-template>
    </ng-container>
  </ng-template>
</nav>
