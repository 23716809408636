import { Component, Input, Inject, ViewChild, ElementRef } from '@angular/core';
import { ShellTrackHandlers } from '@cigna/chcp/shared/analytics-util';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
} from '@cigna/chcp/shared/environment-util';

@Component({
  selector: 'cigna-resources-search-form',
  templateUrl: './resources-search-form.component.html',
  styleUrls: ['./resources-search-form.component.scss'],
})
export class ResourcesSearchFormComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() resourcesPage = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() secure = false;

  @ViewChild('searchInput', { read: ElementRef, static: false })
  searchInput: ElementRef;

  constructor(
    private analytics: ShellTrackHandlers,
    @Inject(ENVIRONMENT_CONFIG) private envConfig: EnvironmentConfig,
  ) {}

  getSearchTerm(): string {
    return (this.searchInput && this.searchInput.nativeElement.value) || '';
  }

  submitSearch() {
    const searchTerm = this.getSearchTerm();

    if (searchTerm) {
      this.analytics.trackEventOther({}, 'Search', 'ac-Search001', {
        eVar27: searchTerm,
        eVar58: searchTerm,
        prop27: 'D=v27',
        prop58: 'D=v58',
      });

      return true; // submit the form
    }
    return false;
  }

  get formAction(): string {
    const resultsPagePath = `/app/resources/results/${
      this.secure ? 'secure' : 'public'
    }`;

    if (this.resourcesPage) {
      const cdnEnvironment = this.envConfig.api.gateways.cdn;
      let hostname = 'https://cignaforhcp.cigna.com';
      if (cdnEnvironment.includes('d.static')) {
        hostname = 'https://d-hcpportal-0019.cigna.com';
      }
      if (cdnEnvironment.includes('a.static')) {
        hostname = 'https://a-hcpportal-0036.cigna.com';
      }
      if (cdnEnvironment.includes('v.static')) {
        hostname = 'https://v-provportal.cigna.com';
      }
      if (cdnEnvironment.includes('chk.static')) {
        hostname = 'https://chk-cignaforhcp.cigna.com';
      }

      return `${hostname}${resultsPagePath}`;
    }
    return resultsPagePath;
  }
}
