<cigna-dcm-advocate-unavailable
  *ngIf="!(careAdvocateData$ | async)?.agentId; else advocateMatched"
  [careAdvocateNotAssignedMessage]="(careAdvocateData$ | async)?.notAssigned"
></cigna-dcm-advocate-unavailable>
<ng-template #advocateMatched>
  <ng-container
    *ngIf="
      (conversationId$ | async) &&
        (conversationCount$ | async)! <= 1 &&
        !shouldShowMessageDataComp &&
        (events$ | async)?.length === 0;
      else messagingData
    "
  >
    <cigna-dcm-advocate-matched
      [conversationId]="conversationId$ | async"
      [agentName]="agentName$ | async"
      (openDialog)="openMessagingData()"
    ></cigna-dcm-advocate-matched>
  </ng-container>
</ng-template>
<ng-template #messagingData>
  <cigna-omni-messaging-data></cigna-omni-messaging-data>
</ng-template>
