import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import {
  FeatureTogglesFacade,
  FeatureToggleType,
} from '@cigna/shared/angular/features-feature';
import { filter, take } from 'rxjs/operators';

/**
 * ChatResolver provides a way for the application to request omnibus operation which provides more details
 *
 * **Route Configuration:**
 * ```ts
 *  {
 *    resolve: {
 *      chat: ChatResolver,
 *    }
 *  }
 * ```
 */
@Injectable({ providedIn: 'root' })
export class ChatResolver {
  constructor(private featureFacade: FeatureTogglesFacade) {}

  resolve(): Observable<boolean> {
    this.featureFacade
      .featuresEnabled(['chat'])
      .pipe(
        filter((featureFlag) => !!featureFlag),
        take(1),
      )
      .subscribe(() => {
        this.featureFacade.updateFeatureToggles(
          {
            canCallOmnibusApi: true,
          },
          FeatureToggleType.PERMISSION,
        );
      });
    return of(true);
  }
}
