<div
  class="omni-chat-header-wrapper"
  [ngClass]="headerBackgroundClass$ | async"
>
  <div>
    <button
      *ngIf="canGoBack$ | async"
      [disabled]="isBackButtonDisabled$ | async"
      (click)="back()"
      data-qa-omni="headerBackButton"
    >
      <cigna-icon-dynamic
        [colors]="iconColors"
        [icon]="backIcon"
        [data]="backIconData"
        class="back-button"
      ></cigna-icon-dynamic>
      <span>{{ backButtonContent$ | async }}</span>
    </button>
  </div>

  <div>
    <span class="header-title" data-qa-omni="headerTitle">{{
      title$ | async
    }}</span>
  </div>

  <div *ngIf="(title$ | async) === 'Chat'">
    <cigna-icon
      type="sound-on"
      (click)="toggleMute()"
      class="volume"
      *ngIf="isUnmuted$ | async"
    >
    </cigna-icon>
    <cigna-icon
      type="sound-off"
      (click)="toggleMute()"
      class="volume"
      *ngIf="(isUnmuted$ | async) === false"
    >
    </cigna-icon>
  </div>

  <div *ngrxLet="toggleButton$ as button">
    <button
      (click)="toggleChatState(button.copy)"
      data-qa-omni="headerToggleChatState"
    >
      <ng-container>
        <span>{{ button.copy }}</span>
        <cigna-icon-dynamic
          [colors]="iconColors"
          [icon]="button.icon"
          [data]="button.iconData"
          class="close-minimize"
        ></cigna-icon-dynamic>
      </ng-container>
    </button>
  </div>
</div>
