<section>
  <header class="collapsible-item__header">
    <button
      style="padding: 0"
      cignaButton="link"
      class="collapsible-item__header-link"
      type="button"
      [class.active]="expanded"
      (click)="toggle()"
      data-test="cg-collapsible-item-header-text"
    >
      <div class="collapsible-item__header-icon">
        <cigna-icon-triangle
          size="medium"
          color="blue"
          [direction]="expanded ? 'south' : 'east'"
        ></cigna-icon-triangle>
      </div>
      <div>{{ headerText }}</div>
    </button>
  </header>
  <div *ngIf="expanded" class="collapsible-item__content">
    <ng-content></ng-content>
  </div>
</section>
