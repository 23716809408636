import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cigna-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent {
  @Input() size: 'default' | 'compact' = 'default';
  @Input()
  get expanded(): boolean {
    return this._isExpanded;
  }

  set expanded(expanded: boolean) {
    if (this._isExpanded !== expanded) {
      this._isExpanded = expanded;
    }
  }

  private _isExpanded = false;

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disabled = false;

  @Input() headerText: string;
  @Input() icon: string;
  @Input() hasBorder = true;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  toggle(): void {
    if (!this.disabled) {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.opened.emit();
      } else {
        this.closed.emit();
      }
    }
  }

  open(): void {
    this.opened.emit();
    this._isExpanded = true;
  }
}
