// eslint-disable-next-line @nx/enforce-module-boundaries
import { ServiceError, Tin } from '@cigna/chcp/shared/util';
import { createAction, props } from '@ngrx/store';
import {
  ContactMethod,
  MfaSkipType,
  MfaTinDetailsByWam,
  SendCodeRequest,
  StepupDataMultiple,
  StepupDataSingle,
  VerifyCodeRequest,
} from '../mfa/mfa.model';

export const loadUserProfile = createAction('[MFA] Load User Profile');

export const skipEmailVerification = createAction(
  '[MFA] Skip Email Verification',
  props<{ count: number; skipType: MfaSkipType }>(),
);

export const sendContactVerificationCode = createAction(
  '[MFA] Send Contact Verification Code',
  props<{
    payload: SendCodeRequest;
    isResend: boolean;
  }>(),
);
export const sendContactVerificationCodeSuccess = createAction(
  '[MFA] Send Contact Verification Code Success',
  props<{ navigate: boolean }>(),
);
export const sendContactVerificationCodeError = createAction(
  '[MFA] Send Contact Verification Code Error',
  props<{ error: ServiceError }>(),
);

export const verifyContactCode = createAction(
  '[MFA] Verify Contact Code',
  props<{ payload: VerifyCodeRequest }>(),
);
export const verifyContactCodeSuccess = createAction(
  '[MFA] Verify Contact Code Success',
);
export const verifyContactCodeError = createAction(
  '[MFA] Verify Contact Code Error',
  props<{ error: ServiceError }>(),
);

export const exitContactVerification = createAction(
  '[MFA] Exit Contact Verification',
);

export const resetWamState = createAction('[MFA] Reset Wam Settings');
export const getMfaTinDetailsByWam = createAction('[MFA] Get MFA Details');

export const getMfaTinDetailsByWamSuccess = createAction(
  '[MFA] Get MFA Details Success',
  props<{ mfaTinDetails: MfaTinDetailsByWam[] }>(),
);

export const getMfaTinDetailsByWamError = createAction(
  '[MFA] Get MFA Details Error',
  props<{ error: ServiceError }>(),
);

export const putMfaTinDetails = createAction('[MFA] Put MFA Details');

export const putMfaTinDetailsSuccess = createAction(
  '[MFA] Put MFA Details Success',
);

export const putMfaTinDetailsError = createAction(
  '[MFA] Put MFA Details Error',
  props<{ error: ServiceError }>(),
);

export const storeMfaWamUpdate = createAction(
  '[MFA] Store MFA WAM Update',
  props<{ mfaTinDetails: MfaTinDetailsByWam[] }>(),
);

export const storeWamSelectedTins = createAction(
  '[MFA] Store Wam Selected Tins',
  props<{ wamSelectedTins: string[] }>(),
);

export const getAllWamTins = createAction('[MFA] Get All Tins');

export const getAllWamTinsSuccess = createAction(
  '[MFA] Get All Tins Success',
  props<{ wamTins: Tin[] }>(),
);

export const getAllWamTinsError = createAction(
  '[MFA] Get All Tins Error',
  props<{ error: ServiceError }>(),
);

export const isamStepup = createAction(
  '[MFA] Isam Stepup',
  props<{ isMobileOnlyMfa: boolean }>(),
);
export const isamStepupSuccessPasthrough = createAction(
  '[MFA] Isam Stepup Success Passthrough',
  props<{ location: string }>(),
);
export const isamStepupSuccessSingleContact = createAction(
  '[MFA] Isam Stepup Success Single Contact',
  props<{ data: StepupDataSingle }>(),
);
export const isamStepupSuccessMultipleContact = createAction(
  '[MFA] Isam Stepup Success Multiple Contact',
  props<{ isMobileOnlyMfa: boolean; data: StepupDataMultiple }>(),
);
export const isamStepup204 = createAction('[MFA] Isam Stepup 204 Response');
export const isamStepupError = createAction(
  '[MFA] Isam Stepup Error',
  props<{ errorMessage: string }>(),
);

export const isamSendCode = createAction(
  '[MFA] Isam Send Code',
  props<{ selectedContact: ContactMethod }>(),
);
export const isamSendCodeSuccess = createAction(
  '[MFA] Isam Send Code Success',
  props<{ otpHint: string; stateId: string }>(),
);
export const isamSendCodeError = createAction('[MFA] Isam Send Code Error');

export const isamResendCode = createAction('[MFA] Isam Resend Code');
export const isamResendCodeSuccess = createAction(
  '[MFA] Isam Resend Code Success',
  props<{ otpHint: string; stateId: string }>(),
);
export const isamResendCodeError = createAction('[MFA] Isam Resend Code Error');

export const isamVerifyCode = createAction(
  '[MFA] Isam Verify Code',
  props<{ code: string; remember: boolean }>(),
);
export const isamVerifyCodeSuccess = createAction(
  '[MFA] Isam Verify Code Success',
  props<{ location: string }>(),
);
export const isamVerifyCodeServiceError = createAction(
  '[MFA] Isam Verify Code Service Error',
  props<{ errorMessage: string }>(),
);
export const isamVerifyCodeInputError = createAction(
  '[MFA] Isam Verify Code Input Error',
  props<{ stateId: string; errorMessage: string }>(),
);
export const isamVerifyCodeRetryLimitError = createAction(
  '[MFA] Isam Verify Code Retry Limit Error',
  props<{ stateId: string; errorMessage: string }>(),
);
