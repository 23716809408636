import { Injectable } from '@angular/core';
import { chatQuery } from '@cigna/omni/chat-state-data-access';
import { select, Store } from '@ngrx/store';
import { publishReplay, refCount } from 'rxjs/operators';
import { messagingQuery } from './messaging.selectors';

@Injectable()
export class MessagingFacade {
  public inputEnabled$ = this.store$.pipe(
    select(messagingQuery.selectInputEnabled),
    publishReplay(1),
    refCount(),
  );
  public conversationType$ = this.store$.pipe(
    select(messagingQuery.selectConversationType),
  );
  public disclaimer$ = this.store$.pipe(
    select(messagingQuery.selectDisclaimer),
  );
  public events$ = this.store$.pipe(select(messagingQuery.selectEvents));
  public outro$ = this.store$.pipe(select(messagingQuery.selectOutro));
  public minimized$ = this.store$.pipe(select(chatQuery.isChatMinimized));
  public shouldEnabledBackToPrevious$ = this.store$.pipe(
    select(messagingQuery.selectEventsForBackToPrevious),
  );
  public replaceBackToPreviousWithEndChat$ = this.store$.pipe(
    select(messagingQuery.replaceBackToPreviousWithEndChat),
  );

  public constructor(private store$: Store<any>) {}
}
