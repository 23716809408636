<div class="dropdown-menu" cignaHoverDropdown>
  <button
    class="menu-button"
    cignaButton="link"
    style="padding-right: 0 !important; padding-left: 0 !important"
    [attr.data-test]="dataTest ? dataTest : 'dropdown-menu-button'"
    [attr.aria-label]="shouldHideMenuLabel ? menuLabel : undefined"
  >
    <ng-container *ngIf="menuLabel && !shouldHideMenuLabel">
      {{ menuLabel }}</ng-container
    >

    <ng-container *ngIf="iconName">
      <mat-icon [svgIcon]="iconName" class="cg-icon-md"></mat-icon>
    </ng-container>

    <cigna-icon-triangle
      size="medium"
      color="blue"
      direction="south"
      style="height: 5px"
    ></cigna-icon-triangle>
  </button>

  <div
    class="dropdown-content"
    data-test="dropdown-content"
    [style.width.px]="dropdownWidth"
    #dropdownMenu
  >
    <ng-content></ng-content>
  </div>
</div>
