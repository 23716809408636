<div class="cg-dialog">
  <h2 class="cg-dialog__header">
    {{ data.title
    }}<button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      (click)="dialogRef.close()"
    >
      Close
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h2>

  <mat-dialog-content *ngIf="data.id">
    <iframe
      *ngIf="data.player === 'limelight'"
      class="limelightIframe"
      [src]="sanitizer.bypassSecurityTrustResourceUrl(getLimelightUrl())"
      [style.height.px]="data.height + 20"
      allowfullscreen
    ></iframe>
    <iframe
      *ngIf="data.player === 'youtube'"
      [style.height.px]="data.height"
      [style.width.%]="100"
      [src]="sanitizer.bypassSecurityTrustResourceUrl(getYoutubeUrl())"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </mat-dialog-content>
</div>
