<section data-test="attachments-section" class="cg-margin-top-xl">
  <div class="cg-margin-top-xl cg-margin-bottom-xs">
    <strong>{{ 'shared.attachmentsList.title' | translate }}</strong>
  </div>
  <div
    *ngIf="(attachments$ | async)?.length; else noAttachmnets"
    class="attachments"
  >
    <div *ngFor="let attachment of attachments$ | async; let i = index">
      <button
        cignaButton="link small"
        class="cg-flush-button-link"
        [attr.data-test]="'attachment-name-' + i"
        (click)="onAttachmentClick(attachment)"
      >
        {{ attachment.name }}
      </button>
      <span
        *ngIf="attachment?.error"
        class="error"
        [attr.data-test]="'error-' + i"
      >
        <cigna-icon type="exclamation-triangle" class="error-icon"></cigna-icon>
        {{ FileUploadErrors[attachment.error] }}</span
      >
    </div>
  </div>
  <ng-template #noAttachmnets>
    <div>
      {{ 'shared.attachmentsList.noAttachments' | translate }}
    </div>
  </ng-template>
</section>
