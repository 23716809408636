<div class="accordion-container">
  <span class="title" [innerHTML]="props.title"></span>
  <ng-container *ngFor="let rowDataItem of props.rowData">
    <cigna-accordion [showArrow]="true">
      <section class="header">
        <span [innerHTML]="rowDataItem[primaryKeyIndex] | linkText"></span>
      </section>
      <section class="content">
        <ul>
          <ng-container *ngFor="let item of rowDataItem; let j = index">
            <li>
              <strong>
                <span [innerHTML]="props.columnHeaders[j][0] | linkText"></span
                >:
              </strong>
              <span [innerHTML]="item | linkText"></span>
            </li>
          </ng-container>
        </ul>
      </section>
    </cigna-accordion>
  </ng-container>
</div>
