<div class="cg-main-page-container" *ngrxLet="routeData$ as routeData">
  <header role="banner">
    <cigna-header
      [logoLink]="logoLink$ | async"
      [isLogoLinkInternal]="(isResourcePage$ | async) === false"
      [isAuthenticated]="false"
      [isNavigationDisabled]="routeData.disableNavigation"
    ></cigna-header>
  </header>

  <main role="main">
    <router-outlet></router-outlet>
  </main>

  <footer role="contentinfo">
    <cigna-footer-global
      *ngIf="!routeData.disableNavigation"
      [hasOneTrustEnabled]="false"
    ></cigna-footer-global>
  </footer>
</div>
