import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';

import { checkValidResponse, logError } from '@cigna/chcp/shared/util';

@Injectable({
  providedIn: 'root',
})
export class RecentSearchService {
  public dashboardApi: string;

  public CONSUMER_CODE = '1000';

  constructor(
    private http: HttpClient,
    @Inject('secureApi') secureApi: string,
  ) {
    this.dashboardApi = `${secureApi}/dashboard/v1`;
  }

  getRecentPatients() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );
    return this.http
      .get(`${this.dashboardApi}/recentBenefits`, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) => res.recentBenefits),
        catchError(logError),
      );
  }

  getRecentClaims() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );
    return this.http
      .get(`${this.dashboardApi}/recentClaims`, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) => res.recentClaims),
        catchError(logError),
      );
  }
}
