import {
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { WindowService } from '@cigna/shared/angular/core/window-util';

/**
 * Applies a class to CSS hover dropdowns when links inside of the dropdown are
 * TAB-focused, to make them visible. This makes dropdowns accessible using the
 * keyboard.
 *
 * child should have the template name `#dropdownMenu`.
 */
@Directive({
  selector: '[cignaHoverDropdown]',
  exportAs: 'cignaHoverDropdown',
})
export class HoverDropdownDirective implements OnDestroy {
  private _destroy$ = new Subject<void>();
  private _focus$ = new Subject<boolean>();

  constructor(private _renderer: Renderer2, private _window: WindowService) {
    this._focus$
      .pipe(debounceTime(10), distinctUntilChanged(), takeUntil(this._destroy$))
      .subscribe((isFocusable) => {
        if (isFocusable) {
          this.isOpen = true;
          setTimeout(() => this.alignToAvoidRightOverflow(), 0);

          return;
        }

        this.isOpen = false;
        this.isFocusable = false;
      });
  }

  @ContentChild('dropdownMenu', { read: ElementRef, static: true })
  menu?: ElementRef;

  @HostBinding('class.nav-dropdown--open')
  isOpen = false;

  isFocusable = false;

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('focusin')
  onFocusIn() {
    this._focus$.next(true);
  }

  @HostListener('focusout')
  onFocusOut() {
    this._focus$.next(false);
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.isOpen = true;
    setTimeout(() => this.alignToAvoidRightOverflow(), 0);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isOpen = false;
  }

  /**
   * Dropdown menus may overflow the right side of the browser window, causing
   * the window to scroll horizontally. This function detects this and moves the
   * dropdown to the left.
   */
  alignToAvoidRightOverflow() {
    const el = this.menu && this.menu.nativeElement;
    if (!el || !el.parentElement) {
      return;
    }

    const { left } = el.parentElement.getBoundingClientRect();
    const { width } = el.getBoundingClientRect();

    const offset = Math.min(this._window.innerWidth - left - width - 30, 0);
    if (offset) {
      this._renderer.setStyle(el, 'left', `${offset}px`);
    }
  }

  allowFocus() {
    this.isFocusable = true;
  }

  closeMenu() {
    this.isOpen = false;
    this.isFocusable = false;
  }

  openMenu() {
    setTimeout(() => this.alignToAvoidRightOverflow(), 0);
    this.isOpen = true;
    this.isFocusable = true;
  }
}
