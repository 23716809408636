import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from '@cigna/shared/angular/logging-util';
import { ContentGateway } from '@cigna/shared/angular/gateways-util';
import {
  CONTENT_BUNDLE_CONFIG,
  ContentBundleConfig,
  GLOBAL_CONTENT_MANIFEST,
  GlobalContentManifest,
  ContentBundle,
  Manifest,
} from './content-bundle.config';

@Injectable({ providedIn: 'root' })
export class ContentBundleService {
  constructor(
    private _http: HttpClient,
    private _gw: ContentGateway,
    @Inject(CONTENT_BUNDLE_CONFIG) private _config: ContentBundleConfig,
    @Inject(GLOBAL_CONTENT_MANIFEST)
    private _globalManifest: GlobalContentManifest,
    private _logger: LoggingService,
  ) {}

  getManifestFile(): Observable<Manifest> {
    return this._globalManifest
      ? of(this._globalManifest)
      : this._http.get<Manifest>(
          this._gw.contentUrl({ filename: this._config.manifestFilename }),
        );
  }

  getContentBundle(filename: string): Observable<ContentBundle> {
    return this._http
      .get<ContentBundle>(this._gw.contentUrl({ filename }))
      .pipe(
        catchError((e) => {
          this._logger.error(e, `failed to request ${filename} bundle`);

          return this._http.get<ContentBundle>(
            this._gw.contentUrl({
              filename: `${filename}?_ts=${Date.now().toString()}`,
            }),
          );
        }),
      );
  }
}
