import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';

import { format, isValid } from 'date-fns';
import { Injectable } from '@angular/core';

const isNumber = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);

@Injectable()
export class CignaDateAdapterModule extends NativeDateAdapter {
  // for reference: https://github.com/angular/material2/blob/master/src/lib/core/datetime/native-date-adapter.ts

  parse(value: any): Date | null {
    if (typeof value === 'number') {
      return new Date(value);
    }

    if (value.length === 8 && isNumber(value)) {
      return new Date(
        Date.parse(
          `${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(
            4,
            8,
          )}`,
        ),
      );
    }

    const parsedDate =
      typeof value === 'string'
        ? Date.parse(value.replace(/-/g, '/').replace(/T.+/, ''))
        : Date.parse(value);

    return value && isValid(parsedDate) ? new Date(parsedDate) : null;
  }
  format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
      return format(date, 'MM/dd/yyyy');
    }

    return date.toDateString();
  }
}

export const CIGNA_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'long' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
