import { LogEventLevel } from '@cigna/shared/angular/logging-util';
import { InjectionToken } from '@angular/core';

export const TEALEAF_CONFIG = new InjectionToken<TealeafConfig>(
  'TealeafConfig',
  {
    providedIn: 'root',
    factory: () => ({
      logLevel: LogEventLevel.Error,
      logDescription: 'Javascript Error',
    }),
  },
);

export interface TealeafConfig {
  logLevel: LogEventLevel;
  logDescription: string;
}
