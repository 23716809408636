import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { WindowService } from './window.service';
import { BrowserWindowService } from './browser-window.service';

export function windowFactory(platformId: object): WindowService {
  if (isPlatformBrowser(platformId) || isPlatformServer(platformId)) {
    // server also returns browser implementation because of https://www.npmjs.com/package/domino
    return new BrowserWindowService();
  }

  throw new Error(`no WindowService defined for platform "${platformId}"`);
}
