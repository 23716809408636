<header *ngIf="showAction" class="cg-collapsible-group__header">
  <button
    cignaButton="link"
    type="button"
    class="cg-collapsible-group__action"
    (click)="toggleAll()"
  >
    {{ expanded ? collapseLabel : expandLabel }} {{ groupLabel }}
  </button>
</header>
<ng-content></ng-content>
