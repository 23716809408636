import { Injectable } from '@angular/core';
import { UrlFactory } from './url-factory';

@Injectable({ providedIn: 'root' })
export class GeolocationGateway {
  constructor(private _urlFactory: UrlFactory) {}

  geolocationUrl(params: GeolocationParams): string {
    return this._urlFactory.createUrl(
      'directoryGeolocation',
      'v1/geolocation',
      params,
    );
  }

  geolocationTypeaheadUrl(params: {
    query: string;
    consumerCode: string;
    limit: number;
  }): string {
    return this._urlFactory.createUrl(
      'directory',
      'v1/geolocation/typeahead',
      params,
    );
  }

  typeaheadUrl(params: TypeaheadParams): string {
    return this._urlFactory.createUrl(
      'directoryTypeahead',
      'v1/typeahead',
      params,
    );
  }
}

export interface GeolocationParams {
  consumerCode: string;
  zipCode?: string;
  latitude?: string;
  longitude?: string;
  address?: string;
  city?: string;
  stateCode?: string;
}

export interface TypeaheadParams {
  consumerCode: string;
  searchCategoryCode?: string;
  query?: string;
  latitude?: string;
  longitude?: string;
  medicalProductCode?: string;
  medicalEcnCode?: string;
  behavioralEcnCode?: string;
  behavioralCsnIndicator?: string;
  medicalNetworkCode?: string;
  medicalMpoCode?: string;
  medicalNpoCode?: string;
  dentalProductCode?: string;
  dentalNetworkCode?: string;
  pharmacyProductCode?: string;
  pharmacyNetworkCode?: string;
  medicalUserConfigTypeCode?: string;
  suppressionCodes?: string;
  behavioralNetworkCode?: string;
  behavioralProductCode?: string;
  eapProductCode?: string;
  eapNetworkCode?: string;
  eapEcnCode?: string;
}
