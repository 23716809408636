import { InjectionToken } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import {
  CIGNA_SVGS_NAMESPACE,
  CIGNA_SVGS_FILENAME,
} from './generated/cigna-svgs';

export const ICON_ASSETS_BASE_URL = new InjectionToken<string>(
  'ICON_ASSETS_BASE_URL',
);

export function iconLoader(
  sanitizer: DomSanitizer,
  registry: MatIconRegistry,
  baseUrl: string,
) {
  return () => {
    let url = baseUrl || '/';
    if (!url.endsWith('/')) {
      url += '/';
    }
    url += CIGNA_SVGS_FILENAME;
    registry.addSvgIconSetInNamespace(
      CIGNA_SVGS_NAMESPACE,
      sanitizer.bypassSecurityTrustResourceUrl(url),
    );
  };
}
