<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 10 17"
  [style.transform]="direction"
  data-omni-qa="omni-icon-chevron"
>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g
      transform="translate(-1044.000000, -274.000000)"
      [attr.stroke]="colors.color"
      stroke-width="2"
    >
      <polyline
        id="Path-2"
        points="1045.02875 275 1052.29733 282.268584 1045 289.565913"
      ></polyline>
    </g>
  </g>
</svg>
