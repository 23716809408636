import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniGateway } from '@cigna/omni/shared-util';
import {
  ConversationDTO,
  ConversationMessageEventDTO,
  ConversationTransferResultDTO,
  ConversationActivityEventDTO,
  ConversationClient,
  ConversationSummaryDTO,
} from '@cigna/vampire-dto';
import { Observable } from 'rxjs';
import { Transfer } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ConversationsDataAccessService {
  constructor(private http: HttpClient, private gateway: OmniGateway) {}

  public endLiveConversation(
    conversationId: string,
  ): Observable<ConversationDTO> {
    const url = this.gateway.endLiveConversationUrl({ conversationId });
    return this.http.post<ConversationDTO>(url, {});
  }
  public endBotConversation(
    conversationId: string,
  ): Observable<ConversationDTO> {
    const url = this.gateway.endBotConversationUrl({ conversationId });
    return this.http.post<ConversationDTO>(url, {});
  }

  public terminateConversation(
    conversationId: string,
    userAction: 'logout' | 'inactive',
  ): Observable<void> {
    const url = this.gateway.terminateConversationUrl({
      conversationId,
      userAction,
    });
    return this.http.post<void>(url, {});
  }

  public terminateLambdaConversation(
    conversationId: string,
    ssoId: string,
    userAction: 'logout' | 'inactive',
    type: 'user' | 'bot',
  ): Observable<void> {
    const url = this.gateway.terminateLambdaConversationUrl(userAction, type);
    return this.http.post<void>(url, { ssoId, convId: conversationId });
  }

  public getBotConversation(conversationId: string) {
    const url = this.gateway.getBotConversationUrl({ conversationId });
    return this.http.get<ConversationDTO>(url);
  }
  public getLiveConversation(
    conversationId: string,
    enableNewDMUI?: boolean,
    convType?: string,
  ) {
    const url =
      convType === 'async'
        ? this.gateway.getAwsLiveConversationUrl({ conversationId }) +
          this.appendAsyncQuery(enableNewDMUI)
        : this.gateway.getLiveConversationUrl({ conversationId });
    return this.http.get<ConversationDTO>(url);
  }

  public loadConversations() {
    const url = this.gateway.conversationsUrl();
    return this.http.get<ConversationDTO[]>(url);
  }

  public botMessageViewed(conversationId: string, messageId: string) {
    const url = this.gateway.botMessageViewedUrl({ conversationId, messageId });
    return this.http.post<ConversationDTO['events']>(url, {});
  }
  public liveMessageViewed(
    conversationId: string,
    messageId: string,
    enableNewDMUI?: boolean,
    convType?: string,
  ) {
    const url =
      convType === 'async'
        ? this.gateway.awsLiveMessageViewedUrl({
            conversationId,
            messageId,
          }) + this.appendAsyncQuery(enableNewDMUI)
        : this.gateway.liveMessageViewedUrl({
            conversationId,
            messageId,
          });
    return this.http.post<ConversationDTO['events']>(url, {});
  }

  public activeConversation() {
    const url = this.gateway.activeConversation();
    return this.http.get<Transfer>(url);
  }

  public activeAsyncConversation() {
    const url = this.gateway.activeAsyncConversation();
    return this.http.get<ConversationDTO[]>(url);
  }

  public conversationHistory() {
    const url = this.gateway.conversationHistory();
    return this.http.get<ConversationSummaryDTO[]>(url);
  }

  public directMessageConversationHistory() {
    const url = this.gateway.directMessageConversationHistory();
    return this.http.get<ConversationSummaryDTO[]>(url);
  }

  public postActivity(
    conversationId: string,
    activityType: ConversationActivityEventDTO['activityType'],
  ) {
    const body = {
      activityType,
    };
    const url = this.gateway.postActivity({ conversationId });
    return this.http.post<ConversationActivityEventDTO>(url, body);
  }

  public postConversation(
    type: ConversationDTO['type'],
    context: ConversationDTO['context'],
    initial?: string,
  ): Observable<ConversationDTO> {
    const body = {
      type,
      context,
      client: {
        type: 'browser' as ConversationClient['type'],
        fcmToken: '',
      },
    };
    const url = this.gateway.conversationsUrl();
    return this.http.post<ConversationDTO>(
      url,
      initial ? { ...body, initial } : body,
    );
  }

  public postLiveConversation(
    type: ConversationDTO['type'],
    context: ConversationDTO['context'],
  ): Observable<ConversationDTO> {
    const body = {
      type,
      context,
      client: {
        type: 'browser' as ConversationClient['type'],
        fcmToken: '',
      },
    };
    const url = this.gateway.postLiveConversationUrl();
    return this.http.post<ConversationDTO>(url, body);
  }

  public postCta(
    conversationId: string,
    sourceMessageId: string,
    value: string,
    _id: string,
  ): Observable<ConversationMessageEventDTO> {
    const url = this.gateway.postCtaUrl({ conversationId });
    return this.http.post<ConversationMessageEventDTO>(url, {
      value,
      sourceMessageId,
      params: {},
      _id,
    });
  }

  public postMessage(
    conversationId: string,
    message: string,
    enableNewDMUI?: boolean,
    convType?: string,
  ): Observable<ConversationMessageEventDTO> {
    const url =
      convType === 'async'
        ? this.gateway.awsPostMessageUrl({ conversationId }) +
          this.appendAsyncQuery(enableNewDMUI)
        : this.gateway.postMessageUrl({ conversationId });
    return this.http.post<ConversationMessageEventDTO>(url, { message });
  }

  public transferConversation(
    conversationId: string,
    type: ConversationDTO['type'],
    initial: string = '',
  ): Observable<ConversationTransferResultDTO> {
    const url = this.gateway.transferConversationUrl({ conversationId });
    return this.http.post<ConversationTransferResultDTO>(url, {
      type,
      initial,
      client: {
        type: 'browser' as ConversationClient['type'],
      },
    });
  }

  appendAsyncQuery(enableNewDMUI: boolean | undefined) {
    return enableNewDMUI ? '?chatType=async' : '';
  }
}
