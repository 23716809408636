import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-omni-messaging-after-hours-chat',
  templateUrl: './after-hours-chat.component.html',
  styleUrls: ['./after-hours-chat.component.scss'],
})
export class OmniMessagingAfterHoursChatComponent {
  @Input()
  public chatCloseHrsMsg: string[];

  constructor() {}
}
