import { createReducer, on, Action } from '@ngrx/store';

import {
  AuthError,
  OidcResult,
  TokenBody,
} from '@cigna/shared/angular/auth/oidc-util';
import * as AuthActions from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  isLoading: boolean;
  data?: OidcResult;
  previousAccessToken?: TokenBody;
  error?: AuthError;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialState: AuthState = {
  isLoading: false,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.noopLoadAuth, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AuthActions.loadAuth, (state) => ({
    isLoading: true,
    previousAccessToken: state.data?.accessTokenBody,
  })),
  on(AuthActions.loadAuthSuccess, (state, { auth }) => ({
    isLoading: false,
    data: auth,
  })),
  on(AuthActions.loadAuthError, (state, { error }) => ({
    error,
    isLoading: false,
  })),
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
