import { Pipe, PipeTransform, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIG, EnvironmentConfig, APP_CONTEXT } from './config';
import { Observable, of } from 'rxjs';

// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import { map } from 'rxjs/operators';

interface ResourceLinkOptions {
  secure?: boolean;
  type?: 'page' | 'file';
  targetApp?: 'chcp' | 'evernorth' | 'both'; // 'both' will use appContext to send to appropriate resource library
}

@Pipe({
  name: 'resourceLink',
})
export class ResourceLinkPipe implements PipeTransform {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private _config: EnvironmentConfig,
    private authFacade: AuthFacade,
    @Inject(APP_CONTEXT) private appContext: string,
  ) {}

  // eslint-disable-next-line sonarjs/cognitive-complexity
  transform(
    path: string,
    {
      secure = true,
      type = 'page',
      targetApp = 'chcp', // default all resource links to chcp resource library
    }: ResourceLinkOptions = {},
  ): Observable<string> {
    const isPage = type === 'page' && !path.endsWith('.pdf');
    const appPath =
      (this.appContext === 'evernorth' && targetApp !== 'chcp') ||
      (this.appContext === 'chcp' && targetApp === 'evernorth')
        ? 'evernorth/provider'
        : 'chcp';

    if (
      targetApp === 'chcp' ||
      (this.appContext === 'chcp' && targetApp === 'both')
    ) {
      // replace cdn domain in case we are in evernorth context
      const cdnLocation = this._config.api.gateways.cdn.replace(
        'static.evernorth',
        'static.cigna',
      );

      if (secure && this.appContext === 'chcp') {
        return this.authFacade.isDentalUser$.pipe(
          map((isDental) => {
            const subfolder = isPage ? (isDental ? '/dental' : '/medical') : '';
            return `${cdnLocation}assets/${appPath}${
              isPage ? subfolder : '/secure'
            }${path}${isPage ? '.html' : ''}`;
          }),
        );
      }
      const linkPath = `${cdnLocation}assets/${appPath}${path}${
        isPage ? '.html' : ''
      }`;
      return of(linkPath);
    }

    // evernorth resource links
    const linkPath = `${this._config.api.gateways.cdn.replace(
      'static.cigna',
      'static.evernorth',
    )}assets/${appPath}${secure && isPage ? '/behavioral' : ''}${path}${
      isPage ? '.html' : ''
    }`;
    return of(linkPath);
  }
}
