import { Injectable } from '@angular/core';
import { UrlFactory } from './url-factory';

@Injectable({ providedIn: 'root' })
export class DcmAssetsGateway {
  constructor(private _urlFactory: UrlFactory) {}

  DcmAssetsUrl(fileName: string): string {
    return this._urlFactory.createAssetsUrl(`secure/dcm/navigator/${fileName}`);
  }
}
