import {
  headerNavLoadSuccess,
  siteNavLoadSuccess,
  secureAlertLoadSuccess,
  publicAlertLoadSuccess,
  siteNavLoadError,
  headerNavLoadError,
} from './shell.actions';

import { NavigationItem, ServiceError } from '@cigna/chcp/shared/util';

import { AlertItem } from '../alerts/alerts.model';
import { on, createReducer, Action } from '@ngrx/store';

export const SHELL_FEATURE_KEY = 'shell';

export interface ShellState {
  headerNav: NavigationItem[];
  headerNavError?: ServiceError;
  siteNav: NavigationItem[];
  siteNavError?: ServiceError;
  secureAlerts: AlertItem[];
  publicAlerts: AlertItem[];
}
export const initialState: ShellState = {
  headerNav: [],
  headerNavError: undefined,
  siteNav: [],
  siteNavError: undefined,
  secureAlerts: [],
  publicAlerts: [],
};

export interface ShellPartialState {
  readonly [SHELL_FEATURE_KEY]: ShellState;
}

export const shellReducer = createReducer(
  initialState,
  on(headerNavLoadSuccess, (state, { navItems }) => ({
    ...state,
    headerNav: navItems,
  })),
  on(headerNavLoadError, (state, { error }) => ({
    ...state,
    headerNavError: error,
  })),
  on(siteNavLoadSuccess, (state, { navItems }) => ({
    ...state,
    siteNav: navItems,
  })),
  on(siteNavLoadError, (state, { error }) => ({
    ...state,
    siteNavError: error,
  })),
  on(secureAlertLoadSuccess, (state, { alerts }) => ({
    ...state,
    secureAlerts: alerts,
  })),
  on(publicAlertLoadSuccess, (state, { alerts }) => ({
    ...state,
    publicAlerts: alerts,
  })),
);

export function reducer(state: ShellState | undefined, action: Action) {
  return shellReducer(state, action);
}
