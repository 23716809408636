<ul *ngIf="displayType === 'unordered'">
  <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
</ul>
<ol *ngIf="displayType === 'ordered'">
  <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
</ol>
<ng-template #listTemplate>
  <ng-container *ngFor="let item of props">
    <li [innerHTML]="item | linkText"></li>
  </ng-container>
</ng-template>
