import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from '../custom-router-state-serializer';

export const getRouterState = createFeatureSelector<{
  state: RouterStateUrl;
  navigationId: number;
}>('router');

export const getUrl = createSelector(getRouterState, (routerState) =>
  routerState ? routerState.state.url : null,
);

export const getQueryParams = createSelector(getRouterState, (routerState) =>
  routerState ? routerState.state.queryParams : null,
);

export const getRouteParams = createSelector(getRouterState, (routerState) =>
  routerState ? routerState.state.params : null,
);

export const getData = createSelector(getRouterState, (routerState) =>
  routerState ? routerState.state.data : null,
);
