import get from 'lodash/get';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  Locale,
  ManifestAccessorFn,
} from '@cigna/shared/angular/resource-content-data-access';
import { ContentBundleManifest } from './content-bundle.interfaces';
import { of } from 'rxjs';
import { LoggingService } from '@cigna/shared/angular/logging-util';

export const manifestAccessorFactory =
  (logger: LoggingService): ManifestAccessorFn =>
  (bundleName: string, manifest: ContentBundleManifest, lang: Locale) => {
    const filePaths = [`public.${lang.toLowerCase()}.${bundleName}`];

    const fileNames = filePaths.map((p) => get(manifest, p)).filter((_) => _);
    if (fileNames.length === 0) {
      logger.warn(
        `could not find base requested bundle in manifest at: ${filePaths[0]}`,
      );
    }

    return of(fileNames);
  };
