<section>
  <div class="cg-margin-bottom-lg cg-margin-top-xs">
    <ng-container *ngIf="allTins.length < maxSelectedTins">
      <button
        cignaButton="link"
        style="padding: 0"
        data-test="select-tin-group-all"
        (click)="checkAll()"
      >
        Select all
      </button>
      <span class="cg-pipe-style">|</span>
    </ng-container>
    <button
      cignaButton="link"
      style="padding: 0"
      data-test="select-tin-group-none"
      (click)="uncheckAll()"
    >
      Deselect all
    </button>
  </div>
  <div class="cg-multicolumn-list cg-multi-column-list-lg-{{ columns }}">
    <div *ngFor="let tin of allTins" class="cg-multicolumn-list__item">
      <label class="cg-checkbox-label cg-margin-bottom-sm">
        <div>
          <input
            #tinInput
            type="checkbox"
            [checked]="activeTin(tin.tin)"
            (change)="selectionsChanged()"
            [value]="tin.tin"
            [attr.data-test]="'chk-tin-' + tin.tin"
          />
        </div>
        <div>
          {{ tin.tin
          }}<ng-container *ngIf="tin.name !== '' && tin.name !== ' '">
            | ({{ tin.name }})</ng-container
          >
          <div *ngIf="tin.lob" class="cg-margin-top-sm">
            {{
              tin.lob === LOB_MEDBH
                ? 'Medical/Behavioral'
                : tin.lob === LOB_MEDICAL
                ? 'Medical'
                : tin.lob === LOB_BEHAVIORAL
                ? 'Behavioral'
                : ''
            }}
          </div>
          <div *ngIf="showDetailLink" class="cg-margin-top-sm">
            <button
              type="button"
              cignaButton="link"
              style="padding: 0"
              (click)="detailLinkClick.emit(tin.tin)"
            >
              {{ detailLinkText }}
            </button>
          </div>
        </div>
      </label>
    </div>
  </div>
</section>
