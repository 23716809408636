import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-omni-messaging-date-change',
  templateUrl: './date-change.component.html',
  styleUrls: ['./date-change.component.scss'],
})
export class OmniMessagingDateChangeComponent {
  @Input() date: Date;
}
