import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  ContentChild,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Tab, TabSwitchService } from '../tab-group/tab-switch.service';
import { TabLabelDirective } from '../shared/customization/tab-label.directive';

@Component({
  selector: 'cigna-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements Tab {
  private _isActive = false;

  constructor(
    private _cd: ChangeDetectorRef,
    private _switcher: TabSwitchService,
  ) {}

  /**
   * Tab color can be set to one of the predetermined colors in the tab-button.scss file
   */
  @Input() color = '';

  /**
   * Tab width in css format
   */
  @Input() width = '';

  /**
   * Tab label (can be customized via `*cignaTabLabel`).
   */
  @Input() label = '';

  /**
   * Tab Id can be set for accessibility purposes.
   */
  @Input() id?: string;

  /**
   * Tab languageAttr can be set for accessibility purposes for screen readers
   */
  @Input() languageAttr? = '';
  /**
   * Activates or deactivates the tab.
   */
  @Input()
  get isActive() {
    return this._isActive;
  }
  set isActive(isActive: boolean) {
    this.switch(isActive);
  }
  @Output() click: EventEmitter<MouseEvent> = new EventEmitter();

  /**
   * Emits when tab is activated.
   */
  @Output() activated = new Subject<TabComponent>();

  /**
   * Emits when tab is deactivated.
   */
  @Output() deactivated = new Subject<TabComponent>();

  /** @internal @customization */
  @ContentChild(TabLabelDirective, { static: true })
  set customLabelRef(customLabelRef: TabLabelDirective) {
    if (customLabelRef) {
      this.labelRef = customLabelRef;
    }
  }

  /** @internal */
  @ViewChild(TabLabelDirective, { static: true }) labelRef: TabLabelDirective;

  /** @internal */
  @ViewChild('contentTemplate', { static: true })
  contentTemplate: TemplateRef<any>;

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  private switch(isActive: boolean) {
    if (this._isActive === isActive) {
      return;
    }

    this._isActive = isActive;
    this._cd.markForCheck();

    if (isActive) {
      this._switcher.switchToTab(this);

      this.activated.next(this);
    } else {
      this._switcher.ensureActiveTab();

      this.deactivated.next(this);
    }
  }

  onClick(event: MouseEvent) {
    this.click.emit(event);
  }

  returnColorClass() {
    if (this.color !== '') {
      return `${this.color} color`;
    }
    return '';
  }
}
