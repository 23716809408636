import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthConfig, authConfig } from './config';
import { AUTH_FEATURE_KEY, reducer } from './+state/auth.reducer';
import { AuthFacade } from './+state/auth.facade';
import { AuthEffects } from './+state/auth.effects';
import { AuthInterceptor } from './auth-interceptor';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AUTH_FEATURE_KEY, reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    AuthFacade,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AuthDataAccessModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AuthDataAccessModule,
  ) {
    if (parentModule) {
      throw new Error(
        "AuthDataAccessModule is already loaded. It should only be imported in your application's main module.",
      );
    }
  }

  static withConfig(
    config: AuthConfig,
  ): ModuleWithProviders<AuthDataAccessModule> {
    return {
      ngModule: AuthDataAccessModule,
      providers: [authConfig(config)],
    };
  }
}
