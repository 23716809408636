import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { MessagingFacade } from '@cigna/omni/messaging-feature';
import { OmnibusInitializationFacade } from '@cigna/omni/omnibus-initialization-data-access';
import { Subscription, combineLatest, map, take, withLatestFrom } from 'rxjs';
import { ChatBubbleFacade } from '@cigna/omni/chat-bubble-feature';
import { ConversationDTOAgentID } from '@cigna/omni/shared-util';

@Component({
  selector: 'cigna-omni-chat-dcm-dialog',
  templateUrl: './dcm-dialog.component.html',
})
export class DcmDialogComponent implements AfterViewInit, OnDestroy {
  shouldShowMessageDataComp = false;
  careAdvocateData$ = this.conversationsFacade.careAdvocateData$.pipe(
    take(2),
    withLatestFrom(this.conversationsFacade.allConversations$),
    map(([careAdvocateData, allConversations]) => {
      if (careAdvocateData) {
        if (!careAdvocateData.agentId) {
          this.omnibusFacade.omnibusInitializeRetry();
        } else {
          const currConversations = allConversations.filter(
            (c: ConversationDTOAgentID) =>
              c.state !== 'closed' &&
              careAdvocateData.agentId &&
              careAdvocateData.agentId === c.agentData?.id,
          );
          if (currConversations.length < 1 && careAdvocateData.agentId) {
            this.conversationsFacade.createLiveConversation('async', {
              tags: ['msk'],
            });
            this.conversationsFacade.setExistingConversationId(
              careAdvocateData.agentId,
            );
            this.conversationsFacade.setConversationId(
              careAdvocateData.agentId,
            );
          }
          this.conversationsFacade.openConversation(true);
        }
      }
      return careAdvocateData;
    }),
  );
  conversationId$ = this.conversationsFacade.conversationId$;
  conversationCount$ = this.conversationsFacade.conversationCount$;
  events$ = this.msgFacade.events$;
  currentComponent$ = this.chatFacade.currentComponent$;
  agentName$ = this.conversationsFacade.agentName$;
  setShowOptionsMenuSubscription: Subscription;

  constructor(
    private chatFacade: OmniChatFacade,
    public conversationsFacade: OmniConversationsFacade,
    public msgFacade: MessagingFacade,
    private omnibusFacade: OmnibusInitializationFacade,
    private chatBubbleFacade: ChatBubbleFacade,
  ) {}
  ngAfterViewInit() {
    this.chatFacade.dialogView();
    this.setShowOptionsMenuSubscription = combineLatest([
      this.careAdvocateData$,
      this.conversationId$,
      this.conversationCount$,
      this.events$,
      this.currentComponent$,
    ])
      .pipe(
        map(
          ([
            careAdvocateData,
            conversationId,
            conversationCount,
            events,
            currentComponent,
          ]) =>
            !(
              !careAdvocateData?.agentId ||
              (conversationId &&
                conversationCount === 1 &&
                events.length === 0 &&
                currentComponent === 'dialog')
            ),
        ),
      )
      .subscribe((showOptionsMenu: boolean) => {
        this.chatFacade.setShowOptionsMenu(showOptionsMenu);
      });
  }
  openMessagingData() {
    this.shouldShowMessageDataComp = true;
  }
  ngOnDestroy() {
    this.setShowOptionsMenuSubscription.unsubscribe();
  }
}
