import { Pipe, PipeTransform, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { OMNI_CONFIG, OmniConfig } from '@cigna/omni/shared-util';
import { Observable, filter, take } from 'rxjs';

@Pipe({
  name: 'cdnPrefix',
  pure: false,
})
export class CdnPrefixPipe implements PipeTransform {
  _config: OmniConfig;
  constructor(@Inject(OMNI_CONFIG) private _config$: Observable<OmniConfig>) {
    this._config$
      .pipe(
        filter((conf: OmniConfig) => !!conf),
        take(1),
      )
      .subscribe((conf: OmniConfig) => (this._config = conf));
  }

  transform(path: string): string {
    return Location.joinWithSlash(
      this._config?.omni?.sonicNotification || '',
      path,
    );
  }
}
