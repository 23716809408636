import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cigna-dialog-site-tour',
  templateUrl: './dialog-site-tour.component.html',
  styleUrls: ['./dialog-site-tour.component.scss'],
})
export class DialogSiteTourComponent {
  constructor(public dialogRef: MatDialogRef<DialogSiteTourComponent>) {}
}
