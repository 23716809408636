import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUploadErrors, Attachment } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss'],
})
export class AttachmentsListComponent {
  @Input() attachments$: Observable<Attachment[]>;

  @Output() attachmentClick = new EventEmitter<Attachment>();

  FileUploadErrors: { [key: string]: string } = FileUploadErrors;

  onAttachmentClick(attachment: Attachment): void {
    this.attachmentClick.emit(attachment);
  }
}
