import { ActionReducerMap } from '@ngrx/store';
import { ChatActionsUnion, ChatActionTypes } from './chat.actions';
import {
  ChatState,
  Components,
  ChatStates,
  AnimationStates,
  HistoryFilterState,
} from './chat.interfaces';

export const CHAT_FEATURE_KEY = 'omni-chat-feature';

export const initialState: ChatState = {
  history: [],
  chatState: 'closed',
  mute: true,
  direction: 'right',
  generalChatByClient: undefined,
  isInternalUser: undefined,
  selectedClientDetail: undefined,
  showOptionsMenu: false,
  historyFilter: '',
};

export function history(
  state = initialState.history,
  action: ChatActionsUnion,
): Components[] {
  switch (action.type) {
    case ChatActionTypes.Back:
      return state.length > 1 ? state.slice(0, state.length - 1) : [];

    case ChatActionTypes.GoHome:
    case ChatActionTypes.HeaderClose:
      return [];

    case ChatActionTypes.OpenDialog:
      return state.length && state[state.length - 1] === 'dialog'
        ? state
        : [...state, 'dialog'];

    case ChatActionTypes.SplashView:
      return [...state, 'splashView'];

    case ChatActionTypes.ActiveDMsView:
      return [...state, 'activeDMsView'];

    case ChatActionTypes.OpenHistory:
      return [...state, 'history'];

    case ChatActionTypes.DMHistoryView:
      return ['splashView', 'activeDMsView', 'history'];

    default:
      return state;
  }
}

export function chatState(
  state = initialState.chatState,
  action: ChatActionsUnion,
): ChatStates {
  switch (action.type) {
    case ChatActionTypes.AutoMaximize:
    case ChatActionTypes.ChatBubbleOpen:
    case ChatActionTypes.OpenDialog:
    case ChatActionTypes.SplashView:
    case ChatActionTypes.DMHistoryView:
    case ChatActionTypes.HeaderMaximize:
      return 'open';

    case ChatActionTypes.HeaderClose:
      return 'closed';

    case ChatActionTypes.HeaderMinimize:
      return 'minimized';

    default:
      return state;
  }
}

export function muteState(state = true, action: ChatActionsUnion): boolean {
  return action.type === ChatActionTypes.ToggleMuteState ? !state : state;
}

export function direction(
  state = initialState.direction,
  action: ChatActionsUnion,
): AnimationStates {
  switch (action.type) {
    case ChatActionTypes.GoHome:
    case ChatActionTypes.Back:
      return state === 'left' ? 'left2' : 'left';

    case ChatActionTypes.OpenDialog:
    case ChatActionTypes.OpenHistory:
      return state === 'right' ? 'right2' : 'right';

    case ChatActionTypes.DMHistoryView:
      return 'right2';

    default:
      return state;
  }
}

export function historyFilter(
  state = initialState.historyFilter,
  action: ChatActionsUnion,
): HistoryFilterState {
  switch (action.type) {
    case ChatActionTypes.Back:
      return '';

    case ChatActionTypes.DMHistoryView:
      return 'async';

    default:
      return state;
  }
}

export function generalChatByClient(
  state = initialState.generalChatByClient,
  action: ChatActionsUnion,
) {
  return action.type === ChatActionTypes.SetChatByClientList
    ? action.generalChatByClient
    : state;
}

export function isInternalUser(
  state = initialState.isInternalUser,
  action: ChatActionsUnion,
): boolean | undefined {
  return action.type === ChatActionTypes.SetCfeUserType
    ? action.isInternalUser
    : state;
}

export function selectedClientDetail(
  state = initialState.selectedClientDetail,
  action: ChatActionsUnion,
) {
  return action.type === ChatActionTypes.SetSelectedClientDetail
    ? action.selectedClientDetail
    : state;
}

export function showOptionsMenu(
  state = initialState.showOptionsMenu,
  action: ChatActionsUnion,
) {
  return action.type === ChatActionTypes.SetShowOptionsMenu
    ? action.showOptionsMenu
    : state;
}

export const chatReducers: ActionReducerMap<ChatState> = {
  history,
  direction,
  mute: muteState,
  chatState,
  historyFilter,
  generalChatByClient,
  isInternalUser,
  selectedClientDetail,
  showOptionsMenu,
};
