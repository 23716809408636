/* eslint-disable sonarjs/max-switch-cases */
import { UserActions, UserActionTypes } from './user.actions';

import { UserProfile } from '../user-profile/user-profile.model';
import {
  ProviderTin,
  TinFunctions,
  TinMapObject,
} from '../user-tins/tins.model';
import { FlaggedPatient, FlaggedClaim } from '../user-flags/flags.model';
import {
  removeFlaggedClaims,
  removeFlaggedPatients,
  addDateToFlaggedPatient,
  addDateToFlaggedClaim,
} from '../user-flags/flags.util';

import { FlagUpdateType } from '../user-flags/flags.constant';
import { mapEntitlementToTokenFormat } from '@cigna/chcp/auth/util';

export const USER_FEATURE_KEY = 'user';

export const parseProviderTins = (
  tinsFromResponse: Map<string, TinMapObject>,
): ProviderTin[] => {
  const tins: ProviderTin[] = [];
  const tinMap: Map<string, TinMapObject> = new Map(
    Object.entries(tinsFromResponse),
  );
  tinMap.forEach((value: TinMapObject, key: string) => {
    tins.push({
      tinId: key,
      tinName: value.name,
      tinLob: value.lob,
      contracted: value.contracted === 'Y' ? true : false,
      hasGovrnm: value.hasGovrnm,
      hasComrcl: value.hasComrcl,
      sanctioned: value.sanctioned,
      generatedTinId: value.generatedTinId,
      entitlements: value.entitlements,
      entitlementsJWTFormatted: value.entitlements,
    });
  });
  return tins;
};

export interface UserState {
  profile: UserProfile | null;
  tins: ProviderTin[];
  tinFunctions: TinFunctions | null;
  tinStatus: string;
  tinFunctionsStatus: string;
  securityQuestions: string[];
  flaggedPatients: FlaggedPatient[];
  flaggedClaims: FlaggedClaim[];
  recentPatients: FlaggedPatient[];
  recentClaims: FlaggedClaim[];
  isPatientsLoading: boolean;
  isClaimsLoading: boolean;
  hideEmailPopup: boolean;
  updatePrefLobSuccess: boolean;
}

export const initialState: UserState = {
  profile: null,
  tins: [],
  tinFunctions: null,
  tinStatus: 'default',
  tinFunctionsStatus: 'default',
  securityQuestions: [],
  flaggedPatients: [],
  flaggedClaims: [],
  recentPatients: [],
  recentClaims: [],
  isPatientsLoading: true,
  isClaimsLoading: true,
  hideEmailPopup: false,
  updatePrefLobSuccess: false,
};

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: UserState;
}

export function userReducer(
  state = initialState,
  action: UserActions,
): UserState {
  switch (action.type) {
    case UserActionTypes.GetUserProfileSuccess: {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    }
    case UserActionTypes.GetLoginUserProfile: {
      return {
        ...state,
        tinStatus: 'loading',
      };
    }
    case UserActionTypes.GetLoginUserProfileSuccess: {
      return {
        ...state,
        profile: { ...action.payload, phoneExtn: action.payload.phoneExt },
        tins: parseProviderTins(action.payload.tins),
        tinStatus: 'success',
      };
    }
    case UserActionTypes.GetLoginUserProfileError: {
      return {
        ...state,
        tinStatus: 'error',
      };
    }
    case UserActionTypes.UpdateUserProfile: {
      return {
        ...state,
        updatePrefLobSuccess: false,
      };
    }
    case UserActionTypes.UpdateSkipCountSuccess:
    case UserActionTypes.UpdateUserProfileSuccess: {
      return {
        ...state,
        updatePrefLobSuccess: true,
        profile: {
          ...(state.profile as UserProfile),
          ...action.payload,
        },
      };
    }
    // todo: remove action once we migrate to new user profile api
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case UserActionTypes.LoadTins: {
      return {
        ...state,
        tinStatus: 'loading',
      };
    }
    case UserActionTypes.LoadTinsSuccess: {
      return {
        ...state,
        tins: action.payload.tins.map((tin) => ({
          ...tin,
          entitlementsJWTFormatted:
            tin.entitlements?.map((entitlement) =>
              mapEntitlementToTokenFormat(entitlement),
            ) || [],
        })),
        tinStatus: 'success',
      };
    }
    // todo: remove action once we migrate to new user profile api
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case UserActionTypes.LoadTinsError: {
      return {
        ...state,
        tinStatus: 'error',
      };
    }
    case UserActionTypes.LoadTinsCached: {
      return {
        ...state,
        tinStatus: 'success',
      };
    }
    case UserActionTypes.LoadTinFunctions: {
      return {
        ...state,
        tinFunctionsStatus: 'loading',
        tinFunctions: null,
      };
    }
    case UserActionTypes.LoadTinFunctionsSuccess: {
      return {
        ...state,
        tinFunctions: action.payload.tins,
        tinFunctionsStatus: 'success',
      };
    }
    case UserActionTypes.LoadTinFunctionsError: {
      return {
        ...state,
        tinFunctionsStatus: 'error',
      };
    }
    case UserActionTypes.GetSecurityQuestionsSuccess: {
      return {
        ...state,
        securityQuestions: action.payload,
      };
    }
    case UserActionTypes.GetUserRecentPatients:
    case UserActionTypes.GetUserFlaggedPatients: {
      return {
        ...state,
        isPatientsLoading: true,
      };
    }
    case UserActionTypes.GetUserFlaggedPatientsSuccess: {
      return {
        ...state,
        flaggedPatients: action.payload,
        isPatientsLoading: false,
      };
    }
    case UserActionTypes.GetUserRecentPatientsError:
    case UserActionTypes.GetUserFlaggedPatientsError: {
      return {
        ...state,
        isPatientsLoading: false,
      };
    }
    case UserActionTypes.UpdateUserFlaggedPatients: {
      return {
        ...state,
        // optimistically update state
        flaggedPatients:
          action.payload.type === FlagUpdateType.add
            ? action.payload.data
                .map(addDateToFlaggedPatient)
                .concat(state.flaggedPatients)
            : removeFlaggedPatients(action.payload.data, state.flaggedPatients),
      };
    }
    case UserActionTypes.UpdateUserFlaggedPatientsError: {
      return {
        ...state,
        // revert optimistic update if service fails
        flaggedPatients:
          action.payload.type === FlagUpdateType.remove
            ? state.flaggedPatients.concat(action.payload.data)
            : removeFlaggedPatients(action.payload.data, state.flaggedPatients),
      };
    }
    case UserActionTypes.GetUserRecentClaims:
    case UserActionTypes.GetUserFlaggedClaims: {
      return {
        ...state,
        isClaimsLoading: true,
      };
    }
    case UserActionTypes.GetUserFlaggedClaimsSuccess: {
      return {
        ...state,
        flaggedClaims: action.payload,
        isClaimsLoading: false,
      };
    }
    case UserActionTypes.GetUserRecentClaimsError:
    case UserActionTypes.GetUserFlaggedClaimsError: {
      return {
        ...state,
        isClaimsLoading: false,
      };
    }
    case UserActionTypes.UpdateUserFlaggedClaims: {
      return {
        ...state,
        // optimistically update state
        flaggedClaims:
          action.payload.type === FlagUpdateType.add
            ? action.payload.data
                .map(addDateToFlaggedClaim)
                .concat(state.flaggedClaims)
            : removeFlaggedClaims(action.payload.data, state.flaggedClaims),
      };
    }
    case UserActionTypes.UpdateUserFlaggedClaimsError: {
      return {
        ...state,
        // revert optimistic update if service fails
        flaggedClaims:
          action.payload.type === FlagUpdateType.remove
            ? state.flaggedClaims.concat(action.payload.data)
            : removeFlaggedClaims(action.payload.data, state.flaggedClaims),
      };
    }
    case UserActionTypes.GetUserRecentPatientsSuccess: {
      return {
        ...state,
        recentPatients: action.payload,
        isPatientsLoading: false,
      };
    }
    case UserActionTypes.GetUserRecentClaimsSuccess: {
      return {
        ...state,
        recentClaims: action.payload,
        isClaimsLoading: false,
      };
    }
    case UserActionTypes.UpdateSkipCountError:
    case UserActionTypes.HideEmailPopup: {
      return {
        ...state,
        hideEmailPopup: true,
      };
    }
    default:
      return state;
  }
}
