import {
  HostBinding,
  Input,
  HostListener,
  Optional,
  Component,
  ChangeDetectionStrategy,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[cignaTabButton], button[cignaTabButton]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tab-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabButtonComponent {
  constructor(@Optional() private _tab?: TabComponent) {}
  @Input() isMultiLineTab = false;
  @HostBinding('class.tab-active')
  @HostBinding('attr.aria-selected')
  @Input()
  get isActive() {
    return this._tab && this._tab.isActive;
  }

  @HostListener('click')
  activate() {
    if (this._tab) {
      this._tab.activate();
    }
  }
}
