// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';
import { OmnibusResultTypes } from './omnibus-initialization.interfaces';

export enum OmnibusInitializationActionTypes {
  OmnibusInitializeFailure = '[Omni - OmnibusInitialization] Initialize Fail',
  OmnibusInitializeNoop = '[Omni - OmnibusInitialization] Initialize Noop',
  OmnibusInitializeRetry = '[Omni - OmnibusInitialization] Initialize',
  OmnibusInitializeSuccess = '[Omni - OmnibusInitialization] Initialize Success',
}

export class OmnibusInitializeFailure implements Action {
  public readonly type =
    OmnibusInitializationActionTypes.OmnibusInitializeFailure;
  constructor(public error: any) {}
}

export class OmnibusInitializeNoop implements Action {
  public readonly type = OmnibusInitializationActionTypes.OmnibusInitializeNoop;
}

export class OmnibusInitializeRetry implements Action {
  public readonly type =
    OmnibusInitializationActionTypes.OmnibusInitializeRetry;
}

export class OmnibusInitializeSuccess implements Action {
  public readonly type =
    OmnibusInitializationActionTypes.OmnibusInitializeSuccess;

  constructor(public payload: OmnibusResultTypes[]) {}
}

export type OmnibusInitializationActionsUnion =
  | OmnibusInitializeFailure
  | OmnibusInitializeNoop
  | OmnibusInitializeRetry
  | OmnibusInitializeSuccess;

export const omnibusInitializeActions = {
  OmnibusInitializeFailure,
  OmnibusInitializeNoop,
  OmnibusInitializeRetry,
  OmnibusInitializeSuccess,
};
