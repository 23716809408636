import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OmniDcmStateModule } from '@cigna/omni/dcm-state-data-access';
import { OmniSharedUiModule } from '@cigna/omni/shared-util';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { DcmAdvocateMatchedComponent } from './components/dcm-advocate-matched/dcm-advocate-matched.component';
import { DcmAdvocateUnavailableComponent } from './components/dcm-advocate-unavailable/dcm-advocate-unavailable.component';
import { DcmProfileHeaderComponent } from './components/dcm-profile-header/dcm-profile-header.component';
import { DcmSummaryHeaderComponent } from './components/dcm-summary-header/dcm-summary-header.component';

@NgModule({
  exports: [
    DcmSummaryHeaderComponent,
    DcmAdvocateUnavailableComponent,
    DcmProfileHeaderComponent,
    DcmAdvocateMatchedComponent,
  ],
  imports: [CommonModule, OmniSharedUiModule, OmniDcmStateModule, UiIconModule],
  declarations: [
    DcmSummaryHeaderComponent,
    DcmAdvocateUnavailableComponent,
    DcmProfileHeaderComponent,
    DcmAdvocateMatchedComponent,
  ],
})
export class OmniDcmModule {}
