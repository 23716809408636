import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FeatureTogglesFacade } from './+state/feature-toggles.facade';

@Directive({
  selector: '[cignaFeatureToggle]',
})
export class FeatureToggleDirective implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  private toggle$ = new BehaviorSubject<string[] | null>(null);
  private viewRef: EmbeddedViewRef<unknown> | null;

  constructor(
    private featureToggleFacade: FeatureTogglesFacade,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input()
  set cignaFeatureToggle(featureToggle: string | string[]) {
    if (typeof featureToggle === 'string') {
      this.toggle$.next([featureToggle]);
    } else {
      this.toggle$.next(featureToggle);
    }
  }

  ngOnInit() {
    this.toggle$
      .pipe(
        switchMap((toggle) =>
          toggle?.length
            ? this.featureToggleFacade.featuresEnabled(toggle)
            : of(false),
        ),
        takeUntil(this.onDestroy$),
      )
      .subscribe((enabled) => this.updateView(enabled));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private updateView(enabled: boolean) {
    if (enabled) {
      if (!this.viewRef) {
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else if (this.viewRef) {
      this.viewContainer.clear();
      this.viewRef = null;
    }
  }
}
