import { Component, Input } from '@angular/core';
import { IconDynamicColors } from '../../interfaces/colors';
import { IconDynamicData } from '../../interfaces/data';

@Component({
  selector: 'cigna-icon-dynamic-heart-in-hand',
  templateUrl: './heart-in-hand-icon.component.html',
})
export class IconDynamicHeartInHandComponent {
  @Input() public colors: IconDynamicColors;

  @Input()
  public set data(payload: IconDynamicData) {
    if (!payload) {
      return;
    }

    if (payload.showNotificationBadge) {
      this.showNotificationBadge = !!payload.showNotificationBadge;
    }

    if (payload.size) {
      this.size = payload.size;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public showNotificationBadge = false;
  public size = '36px';
}
