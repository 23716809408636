import { Component, Input } from '@angular/core';
import { IconDynamicColors } from '../../interfaces/colors';
import { IconDynamicData } from '../../interfaces/data';

@Component({
  selector: 'cigna-icon-dynamic-chat-bubble',
  templateUrl: './chat-bubble-icon.component.html',
})
export class IconDynamicChatBubbleComponent {
  @Input()
  public data: IconDynamicData;
  @Input()
  public set colors(payload: IconDynamicColors) {
    if (!payload) {
      return;
    }

    if (payload.color) {
      this.backgroundColor = payload.color;
    }

    if (payload.textColor) {
      this.textColor = payload.textColor;
    }

    if (payload.strokeColor) {
      this.strokeColor = payload.strokeColor;
    }

    if (payload.dropShadow) {
      this.dropShadow = payload.dropShadow;
    }
  }

  public backgroundColor = '#118503';
  public textColor = '#FFFFFF';
  public strokeColor = '';
  public dropShadow = '';
}
