<button
  tabindex="0"
  cignaButton="secondary"
  class="conv-message-list-item-cta ctaItem"
  type="button"
  (click)="handleClick()"
  data-omni-qa="conv-list-item-cta"
>
  <span>{{ props.label }}</span>
</button>
