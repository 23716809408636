<div class="closeSplashScreen">
  <div class="cover">
    <div tabindex="-1" #textRef class="focusText">
      <span>{{ screenText }}</span>
    </div>
    <div class="btnContainer">
      <button cignaButton="primary" class="btnEndChat" (click)="endChat()">
        End Chat
      </button>
      <button cignaButton="secondary" class="btnKeepChat" (click)="keepChat()">
        Keep Chatting
      </button>
    </div>
  </div>
</div>
