import { MetaData, ServiceLineInfo } from './claims.model';

import {
  Attachment,
  ServiceLineDetails,
  ReconsiderationType,
} from '@cigna/chcp/shared/util';

export interface UserBase {
  id: string;
  firstName: string;
  lastName: string;
}

export enum AdjustmentStatus {
  DRAFT = 'DRAFT',
  SCANNER_PENDING = 'SCANNER_PENDING',
  SCANNER_FAILED = 'SCANNER_FAILED',
  UNAVAILABLE = 'UNAVAILABLE',
  RECEIVED = 'RECEIVED',
  IN_PROCESS = 'IN_PROCESS',
  ADJUSTED = 'ADJUSTED',
  UPHELD_SUCCESS = 'UPHELD_SUCCESS',
  UPHELD_INSUFFICIENT_INFO = 'UPHELD_INSUFFICIENT_INFO',
  PENDED = 'PENDED',
  CLOSED = 'CLOSED',
  CLOSED_REROUTED_RECON = 'CLOSED_REROUTED_RECON',
  CLOSED_REROUTED_APPEAL = 'CLOSED_REROUTED_APPEAL',
  REVIEW_REQUIRED = 'REVIEW_REQUIRED',
}

export const activeAdjustmentStatuses: AdjustmentStatusType[] = [
  AdjustmentStatus.DRAFT,
  AdjustmentStatus.UNAVAILABLE,
  AdjustmentStatus.IN_PROCESS,
  AdjustmentStatus.RECEIVED,
  AdjustmentStatus.PENDED,
  AdjustmentStatus.SCANNER_PENDING,
];

export enum AdjustmentDisplayStatus {
  // internal statuses
  DRAFT = 'Draft',
  SCANNER_PENDING = 'Pending Virus Scan',
  SCANNER_FAILED = 'Request Failed - Virus Found',
  UNAVAILABLE = 'Status Unavailable - Check Back Later',
  // OneView statuses
  RECEIVED = 'Received',
  ADJUSTED = 'Adjusted',
  IN_PROCESS = 'In Process',
  UPHELD_SUCCESS = 'Claim Processing Upheld - Claim Processed Correctly',
  UPHELD_INSUFFICIENT_INFO = 'Claim Processing Upheld - Insufficient Information Provided',
  PENDED = 'Pended',
  PROCESSING_ERROR = 'Request failed – Help desk notified – Please resubmit in 48 hours',
  CLOSED = 'Closed',
  CLOSED_REROUTED_RECON = 'Closed. Re-routed to Reconsiderations',
  CLOSED_REROUTED_APPEAL = 'Closed. Re-routed to Appeals',
  REVIEW_REQUIRED = 'Additional review required. Resolution response to follow',
}

export interface ClaimsAdjustmentsResponse {
  metadata: MetaData;
  adjustmentsHistory: Adjustment[];
}

export type AdjustmentStatusType = keyof typeof AdjustmentStatus;

export type AppealPathType = 'Participant' | 'Provider';

export interface Adjustment {
  id: string;
  claimReferenceNumber?: string;
  requestType?: string;
  oneViewRequestType?: string;
  extRefNum: string;
  status: AdjustmentStatusType;
  decisionNotes?: string;
  providerId: string;
  providerName: string;
  stateOfService: string;
  typeOfNetwork: string;
  notesRequired?: boolean;
  attachmentsRequired?: boolean;
  processStateUrl: string;
  reconsiderationType?: ReconsiderationType | '';
  attachments: string[];
  attachmentsData: Attachment[];
  choiceOne: number | null;
  choiceTwo: number | null;
  choiceThree: number | null;
  confirmationQuestion?: string;
  confirmationAnswer?: string;
  createDate?: string;
  createdBy?: UserBase;
  updateDate?: string;
  updatedBy?: UserBase;
  submittedDate?: string;
  submittedBy?: UserBase;
  nonTimelyChoice: number | null;
  nonTimelyNotesRequired?: boolean;
  nonTimelyAttachmentsRequired?: boolean;
  expirationTime: number | null;
  claimDos: string;
  groupNumber: string;
  memberDob: string;
  memberId: string;
  memberFirstName: string;
  memberLastName: string;
  sourceSystemCode: string;
  householdSuffix: string;
  requesterName: string;
  requesterSSOId: string;
  contactInformation: ContactInfoAdjustment;
  precertId: string;
  notes: string;
  reviewType: ReviewType;
  tinNumber?: string;
  serviceLines?: ServiceLineDetails[];
  procedureLines?: ServiceLineInfo[];
  typeOfInitiator?: AppealPathType;
  placeOfService?: string;
  appealsReason?: string;
  providerRenderingState?: string;
  coverageType: string;
  decisionDate?: string;
}

export interface MemberGroupSummary {
  idCardExtension: string;
  groupNumber: string;
  ami: string;
}

export interface MemberGroupRequest {
  memberFirstName: string;
  relationshipCode: string;
  memberLastName: string;
  memberDateOfBirth: string;
  memberId: string;
  coverageType: string;
  eligibilityAsOfDate: string;
  memberIdentifier: string;
}

interface ContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}
export interface ContactInfoAdjustment extends ContactInfo {
  phone: string;
}

export interface ContactInfoFormData extends ContactInfo {
  phone: PhoneData;
}

export interface PhoneData {
  phone1: string;
  phone2: string;
  phone3: string;
}

export type ReviewType = 'Pre-Service' | 'Post-Service';
