import { Component, Input } from '@angular/core';
import { IconDynamicColors } from '../../interfaces/colors';
import { IconDynamicData, IconDirection } from '../../interfaces/data';

@Component({
  selector: 'cigna-icon-dynamic-chevron',
  templateUrl: './chevron-icon.component.html',
})
export class IconDynamicChevronComponent {
  private directionMap = new Map<IconDirection, string>([
    ['top', 'rotate(270deg)'],
    ['right', ''],
    ['bottom', 'rotate(90deg)'],
    ['left', 'rotate(180deg)'],
  ]);

  @Input() public colors: IconDynamicColors;
  @Input()
  public set data(payload: IconDynamicData) {
    if (!payload) {
      return;
    }

    if (payload.size && payload.size === 'sm') {
      this.height = '14px';
      this.width = '9px';
    }

    if (payload.direction) {
      this.direction = this.directionMap.get(payload.direction);
    }
  }

  public height = '17px';
  public direction = this.directionMap.get('right');
  public width = '10px';
}
