import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PollingConfigError, PollingConfigLoaded } from './polling.actions';
import { PollingState } from './polling.reducer';

@Injectable({
  providedIn: 'root',
})
export class OmniPollingFacade {
  constructor(private store$: Store<PollingState>) {}

  public pollingConfigLoaded(config: PollingState) {
    this.store$.dispatch(new PollingConfigLoaded(config));
  }

  public pollingConfigError(error: any) {
    this.store$.dispatch(new PollingConfigError(error));
  }
}
