import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cigna-dialog-site-help-public',
  templateUrl: './dialog-site-help-public.component.html',
  styleUrls: ['./dialog-site-help-public.component.scss'],
})
export class DialogSiteHelpPublicComponent {
  constructor(public dialogRef: MatDialogRef<DialogSiteHelpPublicComponent>) {}
}
