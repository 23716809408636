import { Store, select } from '@ngrx/store';
import { RouterStateUrl } from '../custom-router-state-serializer';
import {
  getUrl,
  getQueryParams,
  getRouteParams,
  getData,
} from './router-state.selectors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RouterFacade {
  url$ = this.store$.pipe(select(getUrl));
  routeParams$ = this.store$.pipe(select(getRouteParams));
  queryParams$ = this.store$.pipe(select(getQueryParams));
  data$ = this.store$.pipe(select(getData));
  constructor(private store$: Store<RouterStateUrl>) {}
}
