import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export type Locale = 'en-us' | 'es-us' | 'zh-cn';
export const EN_US = 'en-us';
export const ES_US = 'es-us';
export const DEFAULT_LANG: Locale = EN_US;

export const CONTENT_BUNDLE_CONFIG = new InjectionToken<ContentBundleConfig>(
  'CONTENT_BUNDLE_CONFIG',
);

export type Manifest = object;
export type GlobalContentManifest = Manifest | null;
// eslint-disable-next-line no-var
export declare var contentManifest: Manifest;

export type ManifestAccessorFn = (
  name: string,
  manifest: Manifest,
  lang: Locale,
) => Observable<string[]>;

export const CONTENT_BUNDLE_MANIFEST_ACCESSOR =
  new InjectionToken<ManifestAccessorFn>('CONTENT_BUNDLE_MANIFEST_ACCESSOR');

export const GLOBAL_CONTENT_MANIFEST =
  new InjectionToken<GlobalContentManifest>('GLOBAL_CONTENT_MANIFEST', {
    providedIn: 'root',
    factory: globalContentManifestFactory,
  });

export function globalContentManifestFactory(): GlobalContentManifest {
  if (typeof contentManifest !== 'undefined') {
    return contentManifest;
  }

  // eslint-disable-next-line no-console
  console.warn(
    'global contentManifest object not defined, content bundle service will fetch from cdn when requested',
  );

  return null;
}

export interface ContentBundleConfig {
  manifestFilename: string;
  resolvePageContentTimeoutSeconds?: number;
}

export interface ContentBundle {
  [key: string]: ContentBundle | string;
}
