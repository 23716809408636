import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cigna-leave-chcp-dialog',
  templateUrl: './leave-chcp-dialog.component.html',
  styleUrls: ['./leave-chcp-dialog.component.scss'],
})
export class LeaveChcpDialogComponent {
  constructor(
    public leaveChcpDialogRef: MatDialogRef<LeaveChcpDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      content: string;
      link: string;
    },
  ) {}
}
