import { Inject, Injectable } from '@angular/core';
import { TEALEAF, TealeafCaptureLibrary } from './tlt';
import { TEALEAF_CONFIG, TealeafConfig } from './tealeaf.config';

@Injectable({
  providedIn: 'root',
})
export class TealeafService {
  constructor(
    @Inject(TEALEAF) private _tealeaf: TealeafCaptureLibrary,
    @Inject(TEALEAF_CONFIG) private _config: TealeafConfig,
  ) {}

  logError(error: object) {
    this._tealeaf.logCustomEvent(this._config.logDescription, error);
  }

  logCustomError(eventName: string, message: string, data?: any) {
    this._tealeaf.logCustomEvent(eventName, { message, data });
  }

  logMessageInfo(eventName: string, description: string, message: string) {
    this._tealeaf.logCustomEvent(eventName, { description, message });
  }

  logInfo(eventName: string, description: string, data?: any) {
    this._tealeaf.logCustomEvent(eventName, { description, data });
  }
}
