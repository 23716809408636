<div
  class="conv-message-list-item"
  [ngClass]="containerClasses"
  data-omni-qa="conv-list-item"
>
  <div
    *ngIf="(props.firstInSet || props.single) && !shouldEnablePreChatWelcome"
    class="conv-message-list-item-message-timestamp"
    [ngClass]="{ customerMessage: customer }"
    [attr.data-omni-dialog-message-timestamp-role]="props.role"
    data-omni-qa="conv-list-item-timestamp"
  >
    {{ agent ? props.participantName + ' | ' : ''
    }}{{ props.created | date : 'h:mm a' }}
  </div>
  <section [class]="shouldEnablePreChatWelcome ? 'expandList' : ''">
    <div class="conv-message-list-item-customer-spacer" *ngIf="customer"></div>
    <cigna-icon-dynamic
      *ngIf="showAvatar && !shouldEnablePreChatWelcome"
      [icon]="iconType"
      [data]="iconData"
      [colors]="iconColors"
    ></cigna-icon-dynamic>
    <cigna-omni-messaging-avatar
      *ngIf="shouldEnablePreChatWelcome && (agent || bot) && !isDcmChat"
      [agentLogoColor]="props.logoColor"
      [participantName]="props.participantName"
      [hasRole]="props.role"
      [isEvernorth]="isEvernorth"
    ></cigna-omni-messaging-avatar>

    <div [ngClass]="messageClasses" *ngIf="props">
      <div
        *ngIf="(agent || bot) && shouldEnablePreChatWelcome"
        class="conv-message-list-item-message-name"
        data-omni-qa="conv-list-item-name"
      >
        {{
          agent ? props.participantName : isEvernorth ? 'Evernorth' : 'Cigna'
        }}
      </div>

      <div
        #item
        [tabindex]="props.message.includes('delivery prescriptions') ? 0 : -1"
        [attr.data-omni-dialog-message-role]="props.role"
        *ngIf="templateType !== 'table_data'"
      >
        <div
          *ngIf="templateType !== 'content_link'; else contentLinkMessage"
          [ngClass]="{
            answer: shouldEnablePreChatWelcome && customer,
            question: shouldEnablePreChatWelcome && (bot || agent),
            'dcm-chat': isDcmChat
          }"
        >
          <!-- <span
          cignaHrefToRouterLink
          [innerHTML]="props.message | linkText: linkText"
        ></span> -->
          <cigna-omni-messaging-link-text
            [linkText]="linkText"
            [message]="props.message"
          ></cigna-omni-messaging-link-text>
          <div *ngIf="templateType === 'data_list'">
            <cigna-omni-messaging-data-list
              [props]="dataList"
              [displayType]="displayType"
            ></cigna-omni-messaging-data-list>
          </div>
          <a
            *ngIf="props.answerUri"
            [routerLink]="props.answerUri"
            (click)="minibotLinkClick.emit(props.answerUri)"
            >click here</a
          >
        </div>
        <ng-template #contentLinkMessage>
          <div
            *ngIf="
              contentLink?.type === 'file' && isDcmChat;
              else messagingContentLink
            "
          >
            <div
              [ngClass]="{
                answer: shouldEnablePreChatWelcome && customer,
                question: shouldEnablePreChatWelcome && (bot || agent),
                'dcm-chat': isDcmChat
              }"
            >
              <cigna-omni-messaging-file-item
                [contentLink]="contentLink"
                (isError)="toggleOpenFileError($event)"
              ></cigna-omni-messaging-file-item>
            </div>
            <div *ngIf="shouldShowOpenFileError" class="open-file-error">
              <cigna-icon type="red-alert"></cigna-icon>
              We couldn't open the file. Please try again later.
            </div>
          </div>
          <ng-template #messagingContentLink>
            <cigna-omni-messaging-content-link
              [props]="contentLink"
            ></cigna-omni-messaging-content-link>
          </ng-template>
        </ng-template>
      </div>

      <div
        *ngIf="
          (props.firstInSet || props.single || isDcmChat) &&
          shouldEnablePreChatWelcome
        "
        class="conv-message-list-item-message-timestamp"
        [ngClass]="{
          'customerMessage aligntoright': customer,
          botOrAgentMessage: !customer,
          'dcm-chat-time': isDcmChat
        }"
        [attr.data-omni-dialog-message-timestamp-role]="props.role"
        data-omni-qa="conv-list-item-timestamp"
      >
        <div
          *ngIf="
            (props.deliveredStatus === 'delivered' ||
              props.deliveredStatus === 'read') &&
            isDcmChat
          "
          class="conv-message-list-item-delivered-status"
        >
          Delivered •
        </div>
        {{ props.created | date : 'h:mm a' }}
      </div>

      <div *ngIf="templateType === 'table_data'">
        <cigna-omni-messaging-data-table
          [props]="tableData"
          [displayType]="displayType"
        ></cigna-omni-messaging-data-table>
      </div>

      <ul *ngIf="ctaList && templateType === 'link' && props.mostRecent">
        <li *ngFor="let cta of ctaList">
          <button
            type="button"
            [disabled]="disabled"
            (click)="handleCtaEvent(cta)"
          >
            {{ cta.label }}
          </button>
        </li>
      </ul>
      <div
        *ngIf="ctaList && displayType === 'button' && props.mostRecent"
        [ngClass]="
          shouldEnablePreChatWelcome
            ? 'conv-message-list-item-cta-list changeToRight'
            : 'conv-message-list-item-cta-list'
        "
      >
        <ul>
          <li *ngFor="let cta of ctaList">
            <cigna-omni-messaging-cta
              (ctaEvent)="handleCtaEvent(cta)"
              [ctaDisabled]="disabled"
              [mostRecent]="props.mostRecent"
              [props]="cta"
              [shouldEnablePreChatWelcome]="shouldEnablePreChatWelcome"
            ></cigna-omni-messaging-cta>
          </li>
        </ul>
        <ng-container
          *ngIf="shouldEnablePreChatWelcome && shouldEnabledBackToPrevious"
        >
          <button
            cignaButton="link no-underline"
            (click)="backToPrevious.emit()"
            class="backBtn"
          >
            <cigna-icon
              class="backToPrevious"
              data-test-id="icon-back"
              type="left-arrow"
            ></cigna-icon
            >{{
              canReplaceBackToPreviousWithEndChat ? 'End Chat' : 'Back to Start'
            }}
          </button>
        </ng-container>
      </div>
      <p
        *ngIf="ctaFooter"
        class="conv-message-list-item-footer"
        data-omni-qa="conv-message-list-item-footer"
      >
        {{ ctaFooter }}
      </p>
    </div>
    <div
      class="conv-message-list-item-non-customer-spacer"
      *ngIf="!customer"
    ></div>
  </section>
</div>
