import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OmniConversationSummaryCardModule } from '@cigna/omni/conversation-summary-card-feature';
import { OmniConversationSummaryListComponent } from './component/omni-conversation-summary-list.component';
import { ConvSummaryListFacade } from './+state/omni-conversation-summary-list.facade';
import { OmniConversationsStateModule } from '@cigna/omni/conversations-state-data-access';

@NgModule({
  imports: [
    CommonModule,
    OmniConversationSummaryCardModule,
    OmniConversationsStateModule,
  ],
  declarations: [OmniConversationSummaryListComponent],
  exports: [OmniConversationSummaryListComponent],
  providers: [ConvSummaryListFacade],
})
export class OmniConversationSummaryListModule {}
