<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 36 36"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-80.000000, -336.000000)">
      <g transform="translate(80.000000, 336.000000)">
        <!-- Bounding circle -->
        <circle
          *ngIf="!isDisabled && showBorder"
          [attr.stroke]="colors?.color"
          stroke-width="1.25"
          fill-rule="nonzero"
          cx="18"
          cy="18"
          r="17.375"
        ></circle>

        <!-- Stethoscope -->
        <g [attr.fill]="colors?.color" data-omni-qa="omni-icon-stethoscope">
          <path
            d="M26.332,20.1346 C25.357,20.1346 24.568,20.9246 24.568,21.8986 C24.568,22.6236 25.007,23.2456 25.633,23.5166 L25.633,24.3456 C25.633,26.7176 23.703,28.6486 21.331,28.6486 C18.959,28.6486 17.028,26.7176 17.028,24.3456 L17.028,20.3966 C19.511,19.4206 22.765,15.7166 22.765,12.0546 C22.765,10.5666 22.578,9.7066 21.542,8.5846 C21.451,7.6966 20.709,6.9996 19.796,6.9996 C18.822,6.9996 18.032,7.7896 18.032,8.7636 C18.032,9.7376 18.822,10.5276 19.796,10.5276 C20.206,10.5276 20.579,10.3816 20.879,10.1466 C21.21,10.6616 21.265,11.1486 21.265,12.0546 C21.265,15.4406 17.486,19.1526 15.882,19.1526 C14.535,19.1526 10.5,15.4626 10.5,12.0546 C10.5,11.2946 10.61,10.7146 10.818,10.2446 C11.094,10.4216 11.419,10.5276 11.769,10.5276 C12.744,10.5276 13.533,9.7376 13.533,8.7636 C13.533,7.7896 12.744,6.9996 11.769,6.9996 C10.82,6.9996 10.052,7.7526 10.014,8.6926 C9.115,9.7466 9,11.0886 9,12.0546 C9,15.9086 13.074,20.2176 15.528,20.6136 L15.528,24.3456 C15.528,27.5456 18.132,30.1486 21.331,30.1486 C24.53,30.1486 27.133,27.5456 27.133,24.3456 L27.133,23.4616 C27.702,23.1686 28.096,22.5826 28.096,21.8986 C28.096,20.9246 27.306,20.1346 26.332,20.1346"
          ></path>
        </g>
        <!-- Crossed out line -->
        <g *ngIf="isDisabled" data-omni-qa="omni-icon-stethoscope-line">
          <path
            d="M33.0037839,0.996216075 L0.99609445,33.0039055"
            stroke="#FFFFFF"
            stroke-width="4.25"
            stroke-linecap="round"
            fill-rule="nonzero"
          ></path>
          <path
            d="M32.3850655,0.377497642 C32.7267742,0.0357888866 33.2807936,0.0357888866 33.6225024,0.377497642 C33.9642111,0.719206397 33.9642111,1.27322575 33.6225024,1.61493451 L1.61481288,33.622624 C1.27310413,33.9643327 0.719084772,33.9643327 0.377376017,33.622624 C0.0356672613,33.2809152 0.0356672613,32.7268959 0.377376017,32.3851871 L32.3850655,0.377497642 Z"
            [attr.fill]="colors?.color"
            fill-rule="nonzero"
          ></path>
        </g>

        <!-- Badge notifcation -->
        <circle
          *ngIf="showNotificationBadge"
          data-omni-qa="omni-icon-stethoscope-badge"
          [attr.stroke]="colors?.badgeStrokeColor"
          [attr.fill]="colors?.badgeFillColor"
          stroke-width="1.5"
          fill-rule="nonzero"
          cx="31.5"
          cy="6.5"
          r="4.5"
        ></circle>
      </g>
    </g>
  </g>
</svg>
