import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShellState, SHELL_FEATURE_KEY } from './shell.reducer';

// Lookup the 'Shell' feature state managed by NgRx
const getShellState = createFeatureSelector<ShellState>(SHELL_FEATURE_KEY);

const getProfileNav = createSelector(getShellState, (state) =>
  state.headerNav.find((n) => n.identifier === 'drop-down-id'),
);
const getProfileNavError = createSelector(
  getShellState,
  (state) => state.headerNavError,
);

const getProfileNavLinks = createSelector(getProfileNav, (navItem) =>
  navItem && navItem.children ? navItem.children : [],
);

const getSiteNav = createSelector(getShellState, (state) => state.siteNav);
const getSiteNavError = createSelector(
  getShellState,
  (state) => state.siteNavError,
);

/* note:
  - currently we wil only show one alert
  - but building in some future proofing in reducer and service to handle multiple alerts in future should it come to pass
*/
const getSecureAlert = createSelector(
  getShellState,
  (state) => state.secureAlerts[0],
);

const getPublicAlert = createSelector(
  getShellState,
  (state) => state.publicAlerts[0],
);

export const shellQuery = {
  getProfileNavLinks,
  getProfileNavError,
  getSiteNav,
  getSiteNavError,
  getSecureAlert,
  getPublicAlert,
};
