import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-promo-messaging-section',
  templateUrl: './promo-messaging-section.component.html',
  styleUrl: './promo-messaging-section.component.scss',
})
export class PromoMessagingSectionComponent {
  @Input() title: string;
  @Input() iconName: string;
  @Input() hasAccentColor = false;
}
