import { Inject, Injectable, InjectionToken } from '@angular/core';
import { createClient, ContentfulClientApi } from 'contentful';

export interface ContentfulSdkConfig {
  space: string;
  accessToken: string;
  environment?: string;
  // setting `previewApi: true` will return draft content
  // you need to also use the appropriate "Content Preview API - access token" from contentful settings
  previewApi?: boolean;
}

export const CONTENTFUL_CONFIG = new InjectionToken<ContentfulSdkConfig>(
  'ContentfulSdkConfig',
);

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  public client: ContentfulClientApi<undefined>;

  constructor(
    @Inject(CONTENTFUL_CONFIG) private contentfulSDK: ContentfulSdkConfig,
  ) {
    this.client = createClient({
      space: this.contentfulSDK.space,
      accessToken: this.contentfulSDK.accessToken,
      environment: this.contentfulSDK.environment || 'master',
      host: this.contentfulSDK.previewApi
        ? 'preview.contentful.com'
        : 'cdn.contentful.com',
    });
  }
}
