import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'cigna-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() userId: string;
  @Input() isEOC: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() forgotId: EventEmitter<any> = new EventEmitter();
  @Output() forgotPass: EventEmitter<any> = new EventEmitter();

  loginForm: FormGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  submitted = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  submitting = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: [this.userId, [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  /**
   * handler for submit
   */
  submit() {
    if (this.loginForm.valid) {
      this.submitting = true;
      return true;
    }

    return false;
  }

  /**
   * go to home page
   */
  back() {
    this.cancel.emit(null);
  }

  forgotUserId() {
    this.forgotId.emit();
  }

  forgotPassword() {
    this.forgotPass.emit();
  }
  get username(): FormControl {
    return this.loginForm.get('username') as FormControl;
  }
  get password(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }
}
