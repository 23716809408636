import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OmniSharedUiModule } from '@cigna/omni/shared-util';
import { OmniChatStateModule } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsStateModule } from '@cigna/omni/conversations-state-data-access';
import { OmniMessagingCtaComponent } from './cta/cta.component';
import { OmniMessagingDataComponent } from './data/data.component';
import { OmniMessagingContentLinkComponent } from './content-link/content-link.component';
import { OmniMessagingDataListComponent } from './data-list/data-list.component';
import { OmniMessagingDataTableComponent } from './data-table/data-table.component';
import { OmniMessagingLinkTextComponent } from './link-text/link-text.component';
import { OmniMessagingDateChangeComponent } from './date-change/date-change.component';
import { MessagingFacade } from './facade/messaging.facade';
import { OmniMessagingInputComponent } from './input/input.component';
import { OmniMessagingItemComponent } from './item/item.component';
import { OmniMessagingListComponent } from './list/list.component';
import { OmniMessagingOutroComponent } from './outro/outro.component';
import { OmniMessagingSystemEventComponent } from './system-event/system-event.component';
import { OmniMessagingSystemGeneratedMessageComponent } from './system-generated-message/system-generated-message.component';
import { OmniMessagingWaitTimeComponent } from './wait-time/wait-time.component';
import { UiAccordionModule } from '@cigna/shared/angular/accordion-ui';
import { UiButtonModule } from '@cigna/shared/angular/button-ui';
import { CdnPrefixPipe } from './pipes/cdn-prefix.pipe';
import { HtmlDecodePipe } from './pipes/html-decode.pipe';
import { LinkTextPipe } from './pipes/link-text.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HrefToRouterLinkDirective } from './directives/href-to-routerlink.directive';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { OmniMessagingSplashCloseScreenComponent } from './chat-splash-close-screen/chat-splash-close-screen.component';
import { OmniMessagingDisclaimerComponent } from './disclaimer/disclaimer.component';
import { AvatarComponent } from './avatar/avatar.component';
import { OmniMessagingAfterHoursChatComponent } from './after-hours-chat/after-hours-chat.component';
import { OmniMessagingTerminatedUserMessageComponent } from './terminated-user-message/terminated-user-message.component';
import { OmniDcmModule } from '@cigna/omni/dcm-feature';
import { FeaturesModule } from '@cigna/shared/angular/features-feature';
import { OmniMessagingFileItemComponent } from './file-item/file-item.component';
import { OmniMessagingOutageScreenComponent } from './chat-outage-screen/chat-outage-screen.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    OmniChatStateModule,
    LetDirective,
    OmniConversationsStateModule,
    ReactiveFormsModule,
    UiAccordionModule,
    UiButtonModule,
    OmniSharedUiModule,
    UiIconModule,
    OmniDcmModule,
    FeaturesModule,
  ],
  exports: [OmniMessagingDataComponent, OmniMessagingDisclaimerComponent],
  declarations: [
    OmniMessagingCtaComponent,
    OmniMessagingDataComponent,
    OmniMessagingContentLinkComponent,
    OmniMessagingDataListComponent,
    OmniMessagingDataTableComponent,
    OmniMessagingLinkTextComponent,
    OmniMessagingDateChangeComponent,
    OmniMessagingInputComponent,
    OmniMessagingItemComponent,
    OmniMessagingListComponent,
    OmniMessagingOutroComponent,
    OmniMessagingSystemEventComponent,
    OmniMessagingSystemGeneratedMessageComponent,
    OmniMessagingWaitTimeComponent,
    OmniMessagingSplashCloseScreenComponent,
    OmniMessagingDisclaimerComponent,
    AvatarComponent,
    OmniMessagingAfterHoursChatComponent,
    OmniMessagingTerminatedUserMessageComponent,
    HtmlDecodePipe,
    LinkTextPipe,
    SafeHtmlPipe,
    CdnPrefixPipe,
    HrefToRouterLinkDirective,
    OmniMessagingFileItemComponent,
    OmniMessagingOutageScreenComponent,
  ],
  providers: [MessagingFacade, CdnPrefixPipe],
})
export class OmniMessagingModule {}
