import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniCommChannelFacade } from '@cigna/omni/comm-channel-feature';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniAnalyticsService } from '@cigna/omni/shared-util';
import { OutroObj } from '../interfaces/outro.interface';

@Component({
  selector: 'cigna-omni-messaging-outro',
  templateUrl: './outro.component.html',
  styleUrls: ['./outro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingOutroComponent {
  @Input() outro: OutroObj;
  @Input() isHistory: boolean;
  @Input() shouldEnablePreChatWelcome: boolean;
  @Input() canReplaceBackToPreviousWithEndChat: boolean;
  @Input() agentLeftMessageCopy: string;
  @Input() public isEapUser: boolean;
  isDCMChat$ = this.featureTogglesFacade.featuresEnabled(['isDcmChat']);
  isEvernorthEnabled$ = this.featureTogglesFacade.featuresEnabled([
    'isEvernorthEnabled',
  ]);
  canUpdateAnalyticsData$ = this.featureTogglesFacade.featuresEnabled([
    'enableChatAnalyticsDataLayer',
  ]);
  @Input() public isEvernorth: boolean;

  constructor(
    private featureTogglesFacade: FeatureTogglesFacade,
    private commChannelFacade: OmniCommChannelFacade,
    private chatFacade: OmniChatFacade,
    private conversationFacade: OmniConversationsFacade,
    private analytics: OmniAnalyticsService,
  ) {}

  public backTostart() {
    if (this.canReplaceBackToPreviousWithEndChat) {
      this.chatFacade.closeDialog();
      this.conversationFacade.setConversationId('');
    } else {
      const paramTags = this.isEvernorth
        ? ['advocate', 'evn']
        : this.isEapUser
        ? ['advocate', 'member']
        : ['default'];
      this.conversationFacade.setConversationId('');
      this.conversationFacade.backToStart();
      this.commChannelFacade.interact(
        {
          buttonText: 'Chat with a Guide',
          buttonId: 'chatGuide',
        },
        { tags: paramTags },
        false,
      );
    }
  }

  downloadTranscript(canUpdateAnalyticsData: boolean) {
    const printSection = document.querySelector('#printSection');
    if (!printSection) {
      return;
    }

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      return;
    }

    const clonedSection = printSection.cloneNode(true) as HTMLElement;
    const elementsToRemove = clonedSection.querySelectorAll(
      '.footer-buttons-group, .footer-download, .footer-backtostart',
    );
    elementsToRemove.forEach((el) => el.remove());

    const outroSection = document.createElement('div');
    outroSection.className = 'conv-message-list-outro';

    outroSection.innerHTML = `
      <div style="display: flex; flex-direction: column; align-items: center; text-align: center;">
        <img alt="Cigna" class="cigna-footer-tree" src="https://a-mycigna-sit.cigna.com/web/assets/images/cignahlthcr_logo_color.svg" style="height: 64px; width: 64px;">
        <div class="message" style="color: #6e6e6e; font-size: 13px; letter-spacing: 0.5px; line-height: 20px; max-width: 180px; font-weight: 400; margin-top: 8px; margin-bottom: 16px;">
          Thanks for being a<br>Cigna customer.
        </div>
      </div>
    `;

    clonedSection.appendChild(outroSection);

    /** Explicitly copies only the style and link elements, avoiding potential issues with other head content. */
    const styles = Array.from(document.head.getElementsByTagName('style'))
      .map((style) => style.outerHTML)
      .join('\n');

    const links = Array.from(document.head.getElementsByTagName('link'))
      .filter((link) => link.rel === 'stylesheet')
      .map((link) => link.outerHTML)
      .join('\n');

    printWindow.document.write(`
    <html>
    <head>
    <title>Contact Us | myCigna</title>
    ${styles}
    ${links}
    </head>
    <body>
    ${clonedSection.innerHTML}
    </body>
    </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    // adding setTimeout to support print in Safari
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);

    if (canUpdateAnalyticsData) {
      // todo: update to new use data layer format standard
      this.analytics.dataLayer.updateAdobeDataLayer({
        event: 'pageAction',
        actionData: {
          actionName: 'downloadTranscript',
          hitType: 'action',
          widgetName: 'chat-download-transcript',
          customLinkText: 'Download Transcript',
          requirementId: 'ac24072',
          userStory: 'ODCTC-593',
        },
      });
    } else {
      this.analytics.trackEvent('trackDownloadTranscriptClick', {
        linkLabel: 'Download Transcript',
      });
    }
  }
}
