import { Type } from '@angular/core';
import { IconDynamicChatBubbleComponent } from './chat-bubble-icon/chat-bubble-icon.component';
import { IconDynamicChevronComponent } from './chevron-icon/chevron-icon.component';
import { IconDynamicCloseComponent } from './close-icon/close-icon.component';
import { IconDynamicDirectionArrowComponent } from './direction-arrow-icon/direction-arrow-icon.component';
import { IconDynamicHeadsetComponent } from './headset-icon/headset-icon.component';
import { IconDynamicHeartInHandComponent } from './heart-in-hand-icon/heart-in-hand-icon.component';
import { IconDynamicMessageChatComponent } from './message-chat-icon/message-chat-icon.component';
import { IconDynamicStethoscopeComponent } from './stethoscope-icon/stethoscope-icon.component';
import { IconCollapsibleChatBubbleComponent } from './chat-collapsible-icon/chat-collapsible-icon.component';

export * from './chat-bubble-icon/chat-bubble-icon.component';
export * from './chevron-icon/chevron-icon.component';
export * from './close-icon/close-icon.component';
export * from './direction-arrow-icon/direction-arrow-icon.component';
export * from './headset-icon/headset-icon.component';
export * from './heart-in-hand-icon/heart-in-hand-icon.component';
export * from './message-chat-icon/message-chat-icon.component';
export * from './stethoscope-icon/stethoscope-icon.component';
export * from './chat-collapsible-icon/chat-collapsible-icon.component';

export {
  IconDynamicChatBubbleComponent,
  IconDynamicChevronComponent,
  IconDynamicCloseComponent,
  IconDynamicDirectionArrowComponent,
  IconDynamicHeadsetComponent,
  IconDynamicHeartInHandComponent,
  IconDynamicMessageChatComponent,
  IconDynamicStethoscopeComponent,
  IconCollapsibleChatBubbleComponent,
};

export type IconsDynamic =
  | IconDynamicChatBubbleComponent
  | IconDynamicChevronComponent
  | IconDynamicCloseComponent
  | IconDynamicDirectionArrowComponent
  | IconDynamicHeadsetComponent
  | IconDynamicHeartInHandComponent
  | IconDynamicMessageChatComponent
  | IconDynamicStethoscopeComponent
  | IconCollapsibleChatBubbleComponent;

export type IconName =
  | 'chatBubble'
  | 'chevron'
  | 'close'
  | 'directionArrow'
  | 'headset'
  | 'heartInHand'
  | 'messageChat'
  | 'stethoscope'
  | 'collapsibleBubble';

export type IconDynamicMap = { [k in IconName]: Type<IconsDynamic> };

export const iconDynamicMap: IconDynamicMap = {
  chatBubble: IconDynamicChatBubbleComponent,
  chevron: IconDynamicChevronComponent,
  close: IconDynamicCloseComponent,
  directionArrow: IconDynamicDirectionArrowComponent,
  headset: IconDynamicHeadsetComponent,
  heartInHand: IconDynamicHeartInHandComponent,
  messageChat: IconDynamicMessageChatComponent,
  stethoscope: IconDynamicStethoscopeComponent,
  collapsibleBubble: IconCollapsibleChatBubbleComponent,
};
