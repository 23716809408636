import {
  LiveAvailabilityAction,
  LiveAvailabilityActionTypes,
} from './live-availability.actions';

export const LIVE_AVAILABILITY_FEATURE_KEY = 'omni-live-availability';

export interface LiveAvailabilityState {
  inHoliday: boolean;
  inOperatingHours: boolean;
  loaded: boolean;
  pauseLiveCheck?: boolean;
  chatCloseHrsMsg?: string[];
  hasChatOutage?: boolean;
  chatOutageMsg?: string;
}

export const initialState: LiveAvailabilityState = {
  inHoliday: false,
  inOperatingHours: false,
  loaded: false,
  pauseLiveCheck: false,
  chatCloseHrsMsg: [],
  hasChatOutage: false,
  chatOutageMsg: '',
};

export function liveAvailabilityReducer(
  state: LiveAvailabilityState = initialState,
  action: LiveAvailabilityAction,
): LiveAvailabilityState {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case LiveAvailabilityActionTypes.GetLiveAvailabilitySuccess: {
      const chatCloseHrsMsg = action.liveAvailability.chatCloseHrsMsg;
      return {
        ...state,
        inHoliday: action.liveAvailability.inHoliday,
        inOperatingHours: action.liveAvailability.inOperatingHours,
        loaded: true,
        chatCloseHrsMsg: Array.isArray(chatCloseHrsMsg)
          ? chatCloseHrsMsg
          : chatCloseHrsMsg
          ? [chatCloseHrsMsg]
          : undefined,
      };
    }
    case LiveAvailabilityActionTypes.ResumeLiveAvailabilityCheck:
      return {
        ...state,
        pauseLiveCheck: false,
      };
    case LiveAvailabilityActionTypes.PauseLiveAvailabilityCheck:
      return {
        ...state,
        pauseLiveCheck: true,
      };
    case LiveAvailabilityActionTypes.LiveAvailabilityLoaded:
      return {
        ...state,
        loaded: action.isLoaded,
      };
    case LiveAvailabilityActionTypes.SetChatOutage: {
      return { ...state, hasChatOutage: action.hasChatOutage };
    }
    case LiveAvailabilityActionTypes.SetChatOutageMsg: {
      return { ...state, chatOutageMsg: action.chatOutageMsg };
    }
    default:
      return state;
  }
}
