import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConversationMessageDialogContentLinkExtn } from '@cigna/omni/shared-util';
import { WindowService } from '@cigna/shared/angular/core/window-util';

@Component({
  selector: 'cigna-omni-messaging-content-link',
  templateUrl: './content-link.component.html',
  styleUrls: ['./content-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingContentLinkComponent {
  @Input() public props: ConversationMessageDialogContentLinkExtn;
  @Input() public shouldEnablePreChatWelcome: boolean;

  constructor(public window: WindowService) {}

  public handleClick() {
    this.window.open(this.props.url, this.props.target);
  }
}
