import {
  MessageEventItemTypes,
  MessageEventListItem,
  WaitEventListItem,
} from '../interfaces';
import { ConversationDTO, ConversationWaitEventDTO } from '@cigna/vampire-dto';
import isEqual from 'lodash/isEqual';
import {
  iSplice,
  isSupportOpsContextTags,
  isClinicalNursingContextTags,
} from '@cigna/omni/shared-util';

export interface ConversationDTOStatus extends ConversationDTO {
  status?: string;
  waitMessage?: string;
  waitTimeinSeconds?: string;
}

export interface ConversationWaitEventBlob {
  waitEvent: ConversationWaitEventDTO[];
  waitMessageConfig: {
    waitMessage: string;
    waitTimeinSeconds: number;
  };
}

export interface ConversationDTOAgentID extends ConversationDTO {
  agentData?: ConversationAgentData;
}

interface ConversationAgentData {
  firstName?: string;
  id?: string;
}

export const asyncWaitMessageBase = (
  created: Date,
): Pick<WaitEventListItem, '_id' | 'created' | 'type'> => ({
  created,
  _id: 'noop',
  type: 'wait',
});

export const asyncWaitMessageSupport = (created: Date): WaitEventListItem => ({
  ...asyncWaitMessageBase(created),
  lineOne: 'Your message has been sent.',
  lineTwo:
    'Please come back to myCigna.com in the next business day to see our response.',
});

export const asyncWaitMessageChe = (created: Date): WaitEventListItem => ({
  ...asyncWaitMessageBase(created),
  lineOne: 'A clinician will reply within a few minutes.',
});

export function isMessageEvent(
  event: MessageEventItemTypes,
): event is MessageEventListItem {
  return event.type === 'message';
}

export function addAsyncWaitMessage(
  events: MessageEventItemTypes[],
  tags: string[],
  created: Date,
): MessageEventItemTypes[] {
  const isSupport = isSupportOpsContextTags(tags);
  const isChe = isClinicalNursingContextTags(tags);

  if (!(isSupport || isChe)) {
    return events;
  }

  // could use Lodash findLastIndex here instead, this is a smaller version of that, it works faster
  let lastBotMessageIndex = events.length - 1;
  while (lastBotMessageIndex > -1) {
    if ((events[lastBotMessageIndex] as MessageEventListItem).role === 'bot') {
      break;
    }

    lastBotMessageIndex -= 1;
  }

  let waitMsg: WaitEventListItem | null = null;
  // use the customer's first message date when the bot messages are not loaded
  const firstMsg = events[lastBotMessageIndex + 2]
    ? events[lastBotMessageIndex + 2]
    : events.find(({ type }) => type === 'message');
  const eventDate = firstMsg ? firstMsg.created : created;

  switch (true) {
    case isSupport:
      waitMsg = asyncWaitMessageSupport(eventDate);
      break;

    case isChe:
      waitMsg = asyncWaitMessageChe(eventDate);
      break;
  }

  if (!waitMsg) {
    return events;
  }

  return iSplice<MessageEventItemTypes>(
    lastBotMessageIndex + 2,
    0,
    waitMsg,
  )(events);
}

export const cacheComputedEvents = (
  fn: (
    conversation: ConversationDTO,
    personalGuideEligible: boolean,
    isEvernorthEnabled: boolean,
    enableNewPreChatWelcome: boolean,
  ) => MessageEventItemTypes[],
) => {
  const raw = new Map();
  const cache = new Map();

  return (
    x: ConversationDTO,
    y: boolean,
    z: boolean,
    n: boolean,
  ): MessageEventItemTypes[] => {
    if (isEqual(raw.get(x._id), x)) {
      return cache.get(x._id);
    }
    raw.set(x._id, x);
    const computed = fn(x, y, z, n);
    cache.set(x._id, computed);
    return computed;
  };
};
