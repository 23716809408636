import {
  Component,
  ViewChild,
  OnDestroy,
  Input,
  AfterViewInit,
  TemplateRef,
  Output,
  EventEmitter,
  Inject,
  ContentChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  ScrollStrategy,
  BlockScrollStrategy,
  ViewportRuler,
} from '@angular/cdk/overlay';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  PortalService,
  PortalHandle,
  PortalTarget,
} from '@cigna/shared/angular/portals-ui';
import { ModalExitFactory } from '../modal-exit-factory';
import { ModalContentDirective } from './modal-content.directive';

const MAX = Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1;

export interface CignaModalOptions {
  autoOpen?: true;
  open$?: Observable<any>;
  class?: string;
  backdrop?: 'static';
  backdropClass?: string;
  static?: true;
}

@Component({
  selector: 'cigna-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [ModalExitFactory],
})
export class ModalComponent<TData = unknown>
  implements OnDestroy, AfterViewInit
{
  private _onDestroy$ = new Subject<void>();
  private _modalHandle?: PortalHandle;
  private _scrollStrategy: ScrollStrategy;
  private _userData?: TData;
  public options$ = new BehaviorSubject<CignaModalOptions>({});

  readonly labelledBy = `label${Math.floor(Math.random() * MAX)}`;
  readonly describedBy = `desc${Math.floor(Math.random() * MAX)}`;

  constructor(
    private _portalService: PortalService,
    private _exitFactory: ModalExitFactory,
    ruler: ViewportRuler,
    @Inject(DOCUMENT) _document: Document,
  ) {
    this._scrollStrategy = new BlockScrollStrategy(ruler, _document);
  }

  /**
   * Set the modal options.
   * - `autoOpen`: If `true`, modal will open immediately.
   * - `open$`: An observable that, when it emits a value, will cause the modal to open.
   * - `class`: Optional CSS class[es] to be added to a wrapper for the modal content.
   * - `backdrop`: If `'static'`, the modal will not be closed when the backdrop is clicked.
   * - `backdropClass`: Optional CSS class[es] to be added to the modal backdrop element.
   * - `static`: If `true`, there will be no close button for the modal and the modal will not be closed
   *   when the backdrop is clicked.
   */
  @Input()
  set options(providedOptions: CignaModalOptions) {
    this.options$.next(providedOptions);
  }
  get options() {
    return this.options$.getValue();
  }

  /**
   * Emits when the modal is opened.
   */
  @Output()
  opened = new EventEmitter<ModalComponent>();
  /**
   * Emits when the modal is closed.
   */
  @Output()
  closed = new EventEmitter<Event | undefined>();
  /**
   * Emits when the modal close is triggered (right before it closes).
   */
  @Output()
  startedClose = new EventEmitter<ModalComponent>();

  @Output()
  dismissed = new EventEmitter<Event>();

  get isOpened() {
    return !!this._modalHandle;
  }

  /** @internal */
  @ViewChild('modalTemplate', { static: true })
  modalTemplate: TemplateRef<any>;

  /** @internal */
  @ContentChild(ModalContentDirective, { static: false })
  modalContent?: ModalContentDirective;

  ngAfterViewInit(): void {
    if (this.options.autoOpen) {
      this.open();
    }

    if (this.options.open$) {
      this.options.open$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
        this.open();
      });
    }
  }

  /** @internal */
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.close();
  }

  open({ userData }: { userData?: TData } = {}) {
    if (this.modalTemplate && !this.isOpened) {
      this._scrollStrategy.enable();
      this._exitFactory.ensureModalExitExists();

      this._userData = userData;

      this._modalHandle = this._portalService.attach(
        PortalTarget.Modal,
        this.modalTemplate,
      );

      // allow angular to render the modal
      setTimeout(() => this.opened.next(this), 0);
    }
  }

  close(event?: Event) {
    this.startedClose.next(this);

    if (this._modalHandle) {
      this._scrollStrategy.disable();

      this._modalHandle.detach();
      delete this._modalHandle;

      this.closed.next(event);
    }
  }

  dismiss() {
    this.dismissed.emit();
  }

  getUserData(): TData | undefined {
    return this._userData;
  }

  /** @internal */
  backdropClick(event: Event) {
    if (this.options.static || this.options.backdrop === 'static') {
      return;
    }
    this.close(event);
  }
}
