/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, timer } from 'rxjs';
import {
  delayWhen,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { authActions } from '@cigna/shared/angular/auth-data-access';

import {
  GetLiveAvailability,
  GetLiveAvailabilitySuccess,
  LiveAvailabilityAction,
  LiveAvailabilityActionTypes,
  PauseLiveAvailabilityCheck,
  ResumeLiveAvailabilityCheck,
} from './live-availability.actions';
import { getUTCOffset } from '../utils';
import {
  LiveAvailabilityDataAccessService,
  LiveAvailability,
} from '@cigna/omni/data-access';
import { liveAvailabilityQuery } from './live-availability.selectors';
import { OmniLiveAvailabilityFacade } from './live-availability.facade';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

export interface Outage {
  activeOutage: boolean;
  message: string;
}

export interface UpdatedLiveAvailability extends LiveAvailability {
  outage?: Outage;
}

@Injectable()
export class LiveAvailabilityEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private dataAccessService: LiveAvailabilityDataAccessService,
    private featureToggleFacade: FeatureTogglesFacade,
    private liveAvailabilityFacade: OmniLiveAvailabilityFacade,
  ) {}

  getLiveAvailability$: Observable<LiveAvailabilityAction> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetLiveAvailability>(
        LiveAvailabilityActionTypes.GetLiveAvailability,
      ),
      switchMap(() =>
        this.dataAccessService
          .getLiveAvailability(
            getUTCOffset(new Date().getTimezoneOffset()),
            '12h',
          )
          .pipe(
            map((liveAvailability: UpdatedLiveAvailability) => {
              if (liveAvailability.outage) {
                const isActiveOutage = liveAvailability.outage.activeOutage;
                const message = liveAvailability.outage.message;
                this.liveAvailabilityFacade.setChatOutage(isActiveOutage);
                this.liveAvailabilityFacade.setChatOutageMsg(message);
              } else {
                this.liveAvailabilityFacade.setChatOutage(false);
                this.liveAvailabilityFacade.setChatOutageMsg('');
              }
              return new GetLiveAvailabilitySuccess(liveAvailability);
            }),
          ),
      ),
    ),
  );

  getLiveAvailabilitySuccess$: Observable<LiveAvailabilityAction> =
    createEffect(() =>
      this.actions$.pipe(
        ofType<GetLiveAvailabilitySuccess>(
          LiveAvailabilityActionTypes.GetLiveAvailabilitySuccess,
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled(['isNotImpersonator']),
        ),
        filter(
          ([liveAvailabilitySuccess, isNotImpersonator]) => isNotImpersonator,
        ),
        delayWhen(([{ liveAvailability }, isNotImpersonator]) => {
          const validUntil = new Date(liveAvailability.validUntil);

          if (validUntil < new Date()) {
            return timer(60000);
          }
          return timer(validUntil);
        }),
        map(() => new GetLiveAvailability()),
      ),
    );

  resumeLiveAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loadAuthSuccess),
      withLatestFrom(
        this.store$.pipe(select(liveAvailabilityQuery.isLiveCheckPaused)),
      ),
      filter(([_authSuccess, isPollPaused]) => !!isPollPaused),
      switchMap(([_authSuccess, _isPollPaused]) => [
        new GetLiveAvailability(),
        new ResumeLiveAvailabilityCheck(),
      ]),
    ),
  );

  stopPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.unauthorizedError),
      map(() => new PauseLiveAvailabilityCheck()),
    ),
  );
}
