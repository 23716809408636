import {
  Component,
  ViewChild,
  Input,
  Inject,
  TemplateRef,
  Renderer2,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  HostBinding,
  EventEmitter,
  Output,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subject } from 'rxjs';

@Component({
  selector: 'cigna-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnDestroy, AfterViewInit {
  public isOpen = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public keepOpen = false;
  private readonly _destroy$ = new Subject<void>();
  private listenerFns: Array<() => void> = [];

  DEPRECATED_KEY_CODE_MAP: { [key: number]: string } = {
    9: 'Tab',
    13: 'Enter',
    27: 'Escape',
    32: 'Space',
  };

  MAX = Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1;

  @Input()
  popoverId = `popover-${Math.floor(Math.random() * this.MAX)}`;
  @ViewChild('bsPopoverRef', { static: false }) bsPopoverRef: PopoverDirective;
  @ViewChild('popoverTrigger', { static: false, read: ElementRef })
  popoverTrigger: ElementRef;
  @ViewChild('popoverContent', { read: ElementRef, static: false })
  popoverContent: ElementRef<HTMLElement>;
  @ViewChild('closeButton', { read: ElementRef, static: false })
  closeButton: ElementRef<HTMLElement>;

  @Input() content: TemplateRef<any>;
  @Input() contentContext: object | null = null;
  @Input() placement: Placement = 'bottom';
  @Input() underlineStyle: 'tooltip' | 'link' | 'no-underline' = 'tooltip';
  @Input() containerClass? = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() openOnHover? = true;
  @Input() close = 'Close';
  @Input() shouldShowCloseIcon? = false;
  @Input() shouldPreventClickDefault = true;
  @Input() hasAdaptivePosition = true;

  /**
   * areaLabelText can be set for accessibility purposes for screen readers
   */
  @Input() areaLabelText?: string;
  /**
   * role can be set for accessibility purposes
   */
  @Input() role?: string;

  @Output() shown = new EventEmitter<void>();

  get popoverRef() {
    return this.bsPopoverRef;
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    const hostElement: HTMLElement = this.popoverTrigger.nativeElement;
    if (!hostElement) {
      return;
    }
    this.listenerFns = [
      this.renderer.listen(hostElement, 'click', this.click.bind(this)),
      this.renderer.listen(hostElement, 'touchstart', this.click.bind(this)),
      this.renderer.listen(hostElement, 'keydown', this.onKeydown.bind(this)),
      this.renderer.listen(
        this._document,
        'keydown.escape',
        this.hide.bind(this),
      ),
    ];

    if (this.openOnHover) {
      this.listenerFns.push(
        this.renderer.listen(hostElement, 'mouseenter', this.show.bind(this)),
        this.renderer.listen(
          hostElement,
          'mouseleave',
          this.endHover.bind(this),
        ),
      );
    }
  }

  endHover() {
    if (!this.keepOpen) {
      this.isOpen = false;

      if (this.popoverRef) {
        this.popoverRef.hide();
      }
    }
  }

  click(event: Event) {
    if (this.shouldPreventClickDefault) {
      event.preventDefault();
    }
    this.hideOtherPopovers();
    this.isOpen = true;

    this.keepOpen = !this.keepOpen;
  }

  hideOtherPopovers() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const keepOpencheck = this.keepOpen;
    this._document.body.click();
    this.keepOpen = keepOpencheck;
  }

  triggerEscapeKey() {
    if (!this.keepOpen) {
      const escapeEvent = new KeyboardEvent('keydown', {
        key: 'Escape',
        code: 'Escape',
        charCode: 27,
        keyCode: 27,
        which: 27,
        bubbles: true,
      });
      document.dispatchEvent(escapeEvent);
      this.keepOpen = false;
    }
  }

  onKeydown(event: KeyboardEvent) {
    const keyCode = this.getKeyCode(event);
    if (keyCode === 'Enter' || keyCode === 'Space') {
      this.click(event);
      setTimeout(this.setInitialFocus.bind(this));
      this.triggerEscapeKey();
    }
  }

  ngOnDestroy() {
    this.listenerFns.forEach((cleanupMethod) => cleanupMethod());
    this._destroy$.next();
    this._destroy$.complete();
  }

  getKeyCode(event: KeyboardEvent): string {
    if (event.key && event.key !== ' ') {
      return event.key;
    }
    return this.DEPRECATED_KEY_CODE_MAP[event.which];
  }

  setInitialFocus() {
    if (this.closeButton) {
      this.closeButton.nativeElement.focus();
    }
  }

  focusFirstElement(event: KeyboardEvent) {
    if (this.popoverContent) {
      const focusable = this.popoverContent.nativeElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );
      if (focusable.length) {
        (focusable[0] as HTMLElement).focus();
      } else {
        this.setInitialFocus();
      }
      event.preventDefault();
    }
  }

  restoreFocus() {
    const hostElement: HTMLElement = this.popoverTrigger.nativeElement;
    if (hostElement) {
      hostElement.focus();
    }
  }

  public show() {
    this.hideOtherPopovers();
    this.shown.emit();
    this.isOpen = true;
    if (this.popoverRef) {
      this.popoverRef.show();
    }
  }

  public hide() {
    this.keepOpen = false;
    this.isOpen = false;
    if (this.popoverRef) {
      this.popoverRef.hide();
    }
  }
}

type Placement = 'top' | 'bottom' | 'left' | 'right' | 'auto';
