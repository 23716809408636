<div class="cg-media cg-margin-bottom-md" *ngIf="isEOC === 'EOC'">
  <div class="cg-media__img">
    <cigna-icon
      class="cg-icon-md cg-color-success"
      type="checkmark-circle-outline"
    ></cigna-icon>
  </div>
  <div
    class="cg-media__bd cg-color-success"
    data-test="lbl-successful-registration-msg"
  >
    Good news! You've registered successfully
  </div>
</div>

<p *ngIf="isEOC === 'ID'" data-test="lbl-confirmed-identity-msg">
  We've confirmed your identity. Just enter your password and you can log in
  now.
</p>
<p *ngIf="isEOC === 'PWD'" data-test="lbl-successful-password-reset-msg">
  Good news! Your password is now in effect. You may log in.
</p>
<p *ngIf="isEOC === 'EOC'">
  You may now log in at the end of this page. You will only have access to your
  actionable reports for your speciality group.
</p>

<form
  class="cg-margin-top-lg"
  ngNoForm
  (submit)="submit()"
  id="reg-login-form"
  action="/pkmslogin.form"
  data-test="login-form"
  method="POST"
>
  <fieldset>
    <div class="cg-field-container">
      <label
        class="cg-field-container__label"
        for="userId"
        data-test="lbl-userid"
        >User ID</label
      >
      <div class="cg-input-container">
        <input
          name="username"
          id="userId"
          data-test="txt-userid"
          [formControl]="username"
          cignaInput
          type="text"
          maxlength="32"
        />
      </div>
      <div
        class="cg-error-msg"
        *ngIf="
          (username.invalid && username.touched) ||
          (username.invalid && submitted)
        "
      >
        <cigna-icon type="exclamation-circle"></cigna-icon>
        <span data-test="err-userid">Please enter your User ID</span>
      </div>
    </div>
    <div class="cg-field-container">
      <label
        class="cg-field-container__label"
        for="password"
        data-test="lbl-password"
        >Password</label
      >
      <div class="cg-input-container">
        <cigna-password-input>
          <input
            name="password"
            id="password"
            data-test="txt-password"
            [formControl]="password"
            cignaInput
            type="password"
            maxlength="64"
            style="width: 375px"
        /></cigna-password-input>
      </div>
      <div
        class="cg-error-msg"
        *ngIf="
          (password.invalid && password.touched) ||
          (password.invalid && submitted)
        "
      >
        <cigna-icon type="exclamation-circle"></cigna-icon>
        <span data-test="err-password">Please enter a valid password</span>
      </div>
    </div>
  </fieldset>
  <input type="hidden" name="login-form-type" value="pwd" />

  <div class="cg-button-group cg-margin-top-lg">
    <button
      type="submit"
      [disabled]="submitting"
      cignaButton="primary"
      data-test="btn-login"
    >
      Log In
    </button>
    <button cignaButton="link" (click)="back()" data-test="btn-cancel">
      Cancel
    </button>
    <div
      *ngIf="submitting"
      style="display: inline-block"
      class="cg-margin-left-md"
    >
      <div class="cg-media">
        <div class="cg-media__img"><div class="cg-spinner"></div></div>
        <div class="cg-media__bd cg-margin-top-xs cg-margin-left-sm"></div>
      </div>
    </div>
  </div>
</form>

<div class="cg-margin-top-lg" data-test="lbl-forgot-identity-links">
  Forgot
  <button
    (click)="forgotUserId()"
    style="padding: 0"
    cignaButton="link"
    data-test="lnk-forgot-userid"
  >
    your user ID
  </button>
  or
  <button
    (click)="forgotPassword()"
    style="padding: 0"
    cignaButton="link"
    data-test="lnk-forgot-password"
  >
    your password?
  </button>
</div>
