<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 36 36"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g
      transform="translate(-16.000000, -171.000000)"
      [attr.fill]="colors.color"
      data-omni-qa="omni-icon-bot"
    >
      <g transform="translate(16.000000, 171.000000)">
        <polygon
          id="Fill-1"
          points="15.353 15.7037 22.478 15.7037 22.478 14.2037 15.353 14.2037"
        ></polygon>
        <polygon
          id="Fill-2"
          points="15.353 19.5157 22.478 19.5157 22.478 18.0157 15.353 18.0157"
        ></polygon>
        <path
          d="M18.6729,25.2627 C17.5509,25.2627 16.4629,25.0437 15.4399,24.6117 C15.2589,24.5357 15.0559,24.5337 14.8729,24.6047 L10.7249,26.2377 L11.8599,22.2007 C11.9179,21.9887 11.8819,21.7617 11.7589,21.5787 C10.8329,20.2037 10.3439,18.5967 10.3439,16.9337 C10.3439,12.3417 14.0799,8.6057 18.6729,8.6057 C23.2649,8.6057 27.0009,12.3417 27.0009,16.9337 C27.0009,21.5267 23.2649,25.2627 18.6729,25.2627 M18.6729,7.1057 C13.2529,7.1057 8.8439,11.5147 8.8439,16.9337 C8.8439,18.7787 9.3549,20.5647 10.3229,22.1217 L8.8719,27.2857 C8.7949,27.5607 8.8799,27.8547 9.0909,28.0457 C9.2319,28.1727 9.4109,28.2397 9.5939,28.2397 C9.6869,28.2397 9.7789,28.2217 9.8679,28.1867 L15.1449,26.1097 C16.2689,26.5427 17.4539,26.7627 18.6729,26.7627 C24.0919,26.7627 28.5009,22.3537 28.5009,16.9337 C28.5009,11.5147 24.0919,7.1057 18.6729,7.1057"
          id="Fill-3"
        ></path>
        <path
          d="M18.000482,34.5540233 C8.87251305,34.5540233 1.4459767,27.1274869 1.4459767,18.000482 C1.4459767,8.87251305 8.87251305,1.4459767 18.000482,1.4459767 C27.1284509,1.4459767 34.5540233,8.87251305 34.5540233,18.000482 C34.5540233,27.1274869 27.1284509,34.5540233 18.000482,34.5540233 M18.000482,0 C8.0752979,0 0,8.0752979 0,18.000482 C0,27.9256661 8.0752979,36 18.000482,36 C27.9256661,36 36,27.9256661 36,18.000482 C36,8.0752979 27.9256661,0 18.000482,0"
          id="Fill-5"
        ></path>
      </g>
    </g>
  </g>
</svg>
