import {
  OmniConfigColorsInterface,
  OmniInitiateCHEConversationOptions,
  OmniInitiateSOConversationOptions,
} from '../models';

export const omniColors: OmniConfigColorsInterface = {
  blue: '#0170BC',
  digitalGreen: '#118503',
  digitalRed: '#cc0000',
  gray40: '#939496',
  gray60: '#505050',
  white: '#FFFFFF',
  audiBlack: '#000000',
  gray80: '#c8c8c8',
};

export const omniBreakPoints = {
  xs: 376,
};

export const initialCHEConversationOptions: OmniInitiateCHEConversationOptions =
  {
    context: {
      tags: ['clinical', 'hil'],
    },
  };

export const initialSOConversationOptions: OmniInitiateSOConversationOptions = {
  context: {
    tags: ['support'],
  },
};

export const omniConfig = {
  omniColors,
  initialCHEConversationOptions,
  initialSOConversationOptions,
};
