import { InjectionToken } from '@angular/core';

export interface CoreWindowConfig {
  isWebview: boolean;
  isEmbedded: boolean;
}

export const CORE_WINDOW_CONFIG = new InjectionToken<CoreWindowConfig>(
  'CORE_WINDOW_CONFIG',
  {
    providedIn: 'root',
    factory: () => ({ isWebview: false, isEmbedded: false }),
  },
);
