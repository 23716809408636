/* eslint-disable no-console */
import { Injectable, Inject } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { WindowService } from '@cigna/shared/angular/core/window-util';

import mergeWith from 'lodash/mergeWith';
import noop from 'lodash/noop';
import set from 'lodash/set';
import uuid from 'uuid';

import { SATELLITE, satelliteFactory } from './satellite';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Satellite } from '@cigna/shared/angular/core/interfaces-util';
import { DATA_STORE, DataStore } from './data-store';
import { LinkType } from './feature-analytics.service';
import { throwError } from 'rxjs';
import { filter, take, catchError, timeout } from 'rxjs/operators';
import { DataLayerService } from './data-layer.service';

interface QueuedTrack {
  type: 'track' | 'trackPage' | 'trackEvent' | 'voc-NBA';
}

interface Track extends QueuedTrack {
  rule: string;
  path?: string;
  data?: object;
  mergeCustomizer?: any;
}

interface TrackPage extends QueuedTrack {
  data: object;
}

interface TrackEvent extends QueuedTrack {
  data: object;
  linkType?: LinkType;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  trackingQueue: Array<Track | TrackPage | TrackEvent> = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  libraryTimeout = false;

  timeoutWarning =
    '_satellite library load timed out, event will not be tracked';
  queueWarning = '_satellite not present, adding to queue';

  /**
   * Including evar150 into the general analytics service, since analytics team has determined that this is a more reliable page indicator.
   */
  generalAnalyticsServiceEvar150: string;

  constructor(
    @Inject(DATA_STORE) private dataStore: DataStore,
    @Inject(SATELLITE) protected satellite: Satellite,
    public dataLayer: DataLayerService,
    private _window: WindowService,
  ) {
    if (!this.satellite) {
      this._window.analyticsLoaded$
        .pipe(
          filter(() => typeof (window as any)._satellite !== 'undefined'),
          take(1),
          timeout(5000),
          catchError((error) => throwError(error)),
        )
        .subscribe(
          () => {
            this.satellite = satelliteFactory();
            this.trackingQueue.forEach((e) => {
              switch (e.type) {
                case 'track':
                  this.track(
                    (e as Track).rule,
                    (e as Track).data,
                    (e as Track).path,
                    (e as Track).mergeCustomizer,
                  );
                  break;
                case 'trackEvent':
                  this.trackEvent(
                    (e as TrackEvent).data,
                    (e as TrackEvent).linkType,
                  );
                  break;
                case 'trackPage':
                  this.trackPage((e as TrackPage).data);
                  break;
              }
            });
            this.trackingQueue = [];
          },
          () => {
            console.warn(
              'waited 5s but _satellite was not loaded, following events were not tracked',
              this.trackingQueue,
            );

            this.libraryTimeout = true;
            this.trackingQueue = [];
          },
        );
    }
  }

  /**
   * @param {string} [rule] The rule name to pass into {@see Satellite.track}
   *
   * @param {object} [data] The data to merge into the datastore before tracking event.
   *
   * @param {string} [path] An optioal base property path to set {@param data} at.
   *                        {@see https://lodash.com/docs/latest#set} for syntax
   *
   * @param {function} [mergeCustomizer] An optional customizer function that will be passed to mergeWith.
   */
  track(
    rule: string,
    data?: object,
    path?: string,
    mergeCustomizer: (obj: any, src: any) => any = noop,
  ): void {
    if (this.libraryTimeout) {
      console.warn(this.timeoutWarning, {
        rule,
        data,
        path,
      });
      return;
    }
    if (!this.satellite) {
      console.warn(this.queueWarning, {
        rule,
        data,
        path,
      });
      this.trackingQueue.push({
        data,
        rule,
        path,
        mergeCustomizer,
        type: 'track',
      });
      return;
    }

    if (data) {
      this.updateStore(data, path, mergeCustomizer);
    }

    this.satellite.track(rule);
  }

  /**
   *
   * @param {object} data the data that will be sent into the _satellite.track method.
   *                      this should contain all the tracking variables
   *                      can be accessed in the Launch rule on the event.detail object
   * @param {string} linkType 'd', 'e', or 'o' for the respective inidcator on what type
   *                           of tracking it should be
   */
  trackEvent(data: object, linkType?: LinkType): void {
    if (this.libraryTimeout) {
      console.warn(this.timeoutWarning, data);
      return;
    }
    if (!this.satellite) {
      console.warn(this.queueWarning, data);
      this.trackingQueue.push({
        data,
        linkType,
        type: 'trackEvent',
      });
      return;
    }
    switch (linkType) {
      case LinkType.EXIT:
        this.satellite.track('trackExitEvent', data);
        break;
      case LinkType.DOWNLOAD:
        this.satellite.track('trackDownloadEvent', data);
        break;
      default:
        this.satellite.track('trackOtherEvent', data);
        break;
    }
  }

  /**
   *
   * @param {object} data the data that will be sent into the _satellite.track method.
   *                      this should contain all the tracking variables
   *                      can be accessed in the Launch rule on the event.detail object
   */
  trackPage(data: object): void {
    if (this.libraryTimeout) {
      console.warn(this.timeoutWarning, data);
      return;
    }
    if (!this.satellite) {
      console.warn(this.queueWarning, data);
      this.trackingQueue.push({
        data,
        type: 'trackPage',
      });
      return;
    }

    this.satellite.track('pageView', data);
  }

  trackNbaVoc(data: object): void {
    if (this.libraryTimeout) {
      console.warn(this.timeoutWarning, data);
      return;
    }
    if (!this.satellite) {
      console.warn(this.queueWarning, data);
      this.trackingQueue.push({
        data,
        type: 'voc-NBA',
      });
      return;
    }

    this.satellite.track('voc-NBA', data);
  }

  /**
   * @param {object} [data] The data to merge into the datastore
   *
   * @param {string} [path] An optioal base property path to set {@param data} at.
   *                        {@see https://lodash.com/docs/latest#set} for syntax
   *
   * @param {function} [mergeCustomizer] An optional customizer function that will be passed to mergeWith.
   */
  updateStore(
    data?: object,
    path?: string,
    mergeCustomizer: (obj: any, src: any) => any = noop,
  ) {
    if (!data) {
      return;
    }
    if (!this.dataStore) {
      console.warn('Analytics DataStore not present, cannot update with', {
        data,
        path,
      });
      return;
    }

    mergeWith(
      this.dataStore,
      path ? set({}, path, data) : data,
      mergeCustomizer,
    );
  }

  setPageIdentifier() {
    this.generalAnalyticsServiceEvar150 = uuid.v4();
  }
}
