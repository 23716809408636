import { getUrl } from '@cigna/shared/angular/core/router-state-data-access';
import { featuresEnabled } from '@cigna/shared/angular/features-feature';
import { chatQuery } from '@cigna/omni/chat-state-data-access';
import { conversationsQuery } from '@cigna/omni/conversations-state-data-access';
import { liveAvailabilityQuery } from '@cigna/omni/live-availability-state-data-access';
import { rightNowQuery } from '@cigna/omni/right-now-data-access';
import {
  chatBubbleContent,
  getChatBubbleId,
  IconDynamicColors,
  IconDynamicData,
  omniConfig,
} from '@cigna/omni/shared-util';
import { ScreenSize } from '@cigna/shared/angular/responsive-ui';
import { createSelector } from '@ngrx/store';

export const getChatBubbleData = createSelector(
  liveAvailabilityQuery.getLiveChatAvailable,
  featuresEnabled(['asyncSupportChat']),
  conversationsQuery.selectNotificationCount,
  conversationsQuery.selectAllConversations,
  featuresEnabled(['useRightNowChat']),
  featuresEnabled(['isEvernorthEnabled']),
  featuresEnabled(['isTerminatedUser']),
  (
    liveChatAvailable,
    asyncEnabled,
    notificationCount,
    allConversations,
    useRightNowChat,
    isEvernorthEnabled,
    isTerminatedUser,
  ): Record<ScreenSize.XSmall | ScreenSize.Medium, IconDynamicData> => {
    const chatBubbleContentId = getChatBubbleId(
      liveChatAvailable,
      useRightNowChat ? false : asyncEnabled,
      isEvernorthEnabled,
      isTerminatedUser,
    );
    const badgeData = {
      showNotificationBadge: useRightNowChat ? false : !!notificationCount,
      badgeCount: notificationCount,
    };
    const hasNewMessages =
      allConversations.length > 0
        ? allConversations.find((conv) => conv.state === 'opened')?.newMessages
        : false;
    return {
      [ScreenSize.XSmall]: {
        ...chatBubbleContent[chatBubbleContentId][ScreenSize.XSmall],
        ...badgeData,
        hasNewMessages,
      },
      [ScreenSize.Medium]: {
        ...chatBubbleContent[chatBubbleContentId][ScreenSize.Medium],
        ...badgeData,
        hasNewMessages,
      },
    };
  },
);

export const getChatBubbleContentId = createSelector(
  liveAvailabilityQuery.getLiveChatAvailable,
  featuresEnabled(['asyncSupportChat']),
  featuresEnabled(['isEvernorthEnabled']),
  featuresEnabled(['isTerminatedUser']),
  getChatBubbleId,
);

export const getIconColors = createSelector(
  liveAvailabilityQuery.getLiveChatAvailable,
  featuresEnabled(['asyncSupportChat']),
  featuresEnabled(['useRightNowChat']),
  featuresEnabled(['isEvernorthEnabled']),
  featuresEnabled(['enableNewPreChatWelcome']),
  featuresEnabled(['isTerminatedUser']),
  conversationsQuery.getCareAdvocateData,
  featuresEnabled(['isDcmChat']),
  (
    liveChatAvailable,
    asyncEnabled,
    useRightNowChat,
    isEvernorthEnabled,
    canEnableNewPreChatWelcome,
    isTerminatedUser,
    careAdvocateData,
    isDcmChat,
  ): IconDynamicColors => {
    if (
      (!liveChatAvailable && canEnableNewPreChatWelcome) ||
      isTerminatedUser ||
      (careAdvocateData?.notAssigned && isDcmChat)
    ) {
      return {
        color: omniConfig.omniColors.gray80,
      };
    }
    if (!liveChatAvailable && (!asyncEnabled || useRightNowChat)) {
      return {
        color: omniConfig.omniColors.white,
        textColor: omniConfig.omniColors.gray60,
        strokeColor: omniConfig.omniColors.gray40,
      };
    }
    if (isEvernorthEnabled) {
      return {
        color: omniConfig.omniColors.audiBlack,
        textColor: omniConfig.omniColors.white,
        strokeColor: omniConfig.omniColors.white,
        dropShadow: 'drop-shadow(0 0 4px white)',
      };
    }
    return {
      // default values for chat bub color
      color: omniConfig.omniColors.digitalGreen,
      textColor: omniConfig.omniColors.white,
    };
  },
);

/**
 * Add a page name that would match part of a uri
 * To exclude the bubble from showing up
 *
 * example:
 * ['directory']
 */
export const exclusionList: string[] = [
  'secure/consumer/elevate/assessment/start',
];
export const inclusionList: string[] = [];
export const dcmExclusionList: string[] = [
  'assess',
  'error/unavailable',
  'welcome',
];

export const isOnExcludedPage = (currentUrl: string) =>
  createSelector(getUrl, (url) =>
    exclusionList.some((fragment) => (url || currentUrl).includes(fragment)),
  );

export const isOnInclusionPage = (currentUrl: string) =>
  createSelector(getUrl, (url) =>
    inclusionList.some((fragment) => (url || currentUrl).includes(fragment)),
  );

export const isOnExcludedDcmPage = (currentUrl: string) =>
  createSelector(getUrl, (url) =>
    dcmExclusionList.some((fragment) => (url || currentUrl).includes(fragment)),
  );

export const showChatBubble = (currentUrl: string) =>
  createSelector(
    isOnExcludedPage(currentUrl),
    isOnInclusionPage(currentUrl),
    liveAvailabilityQuery.getLiveAvailabilityLoaded,
    chatQuery.isChatClosed,
    featuresEnabled(['generalChat', 'isNotImpersonator']),
    featuresEnabled(['useRightNowChat']),
    rightNowQuery.isWidgetInitialized,
    featuresEnabled(['enableNewPreChatWelcome']),
    featuresEnabled(['hasRestrictedChat']),
    featuresEnabled([
      'enableRestrictedChatInPortal',
      'canDisplayRestrictedBubble',
    ]),
    isOnExcludedDcmPage(currentUrl),
    featuresEnabled(['isDcmChat']),
    conversationsQuery.selectConversation,
    (
      onExcludedPage,
      onInclusionPage,
      liveAvailabilityLoaded,
      isChatClosed,
      isGrubbleEnabled,
      useRightNowChat,
      isRightNowWidgetInitialized,
      enableNewPreChatWelcome,
      hasRestrictedChat,
      displayRestrictedChat,
      onExcludedDcmPage,
      isDcmChat,
      selectConversation,
    ) =>
      !onExcludedPage &&
      !(onExcludedDcmPage && isDcmChat) &&
      (!hasRestrictedChat ||
        onInclusionPage ||
        displayRestrictedChat ||
        selectConversation?.type === 'live') &&
      liveAvailabilityLoaded &&
      (isChatClosed || enableNewPreChatWelcome) &&
      isGrubbleEnabled &&
      (!useRightNowChat || isRightNowWidgetInitialized),
  );
