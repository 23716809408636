import { Component } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cigna-dialog-provider-relations',
  templateUrl: './dialog-provider-relations.component.html',
  styleUrls: ['./dialog-provider-relations.component.scss'],
})
export class DialogProviderRelationsComponent {
  CALLERS_LIST = [
    {
      name: 'Callers from continental US',
      number: '800.453.4187',
    },
    {
      name: 'Callers from Puerto Rico',
      number: '800.981.5888',
    },
    {
      name: 'Callers from US Virgin Islands',
      number: '800.789.7171',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogProviderRelationsComponent>,
    public dialog: MatDialog,
  ) {}
}
