import { Component, Inject, Input } from '@angular/core';
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';
import { AppContext, BreadCrumb } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() breadCrumbs: BreadCrumb[];

  constructor(@Inject(APP_CONTEXT) public appContext: AppContext) {}
}
