import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CtaListItemData } from '../interfaces/cta-list-item.interface';

@Component({
  selector: 'cigna-omni-messaging-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
})
export class OmniMessagingCtaComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() public mostRecent: boolean;
  @Input() public props: CtaListItemData;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() public ctaDisabled: boolean;
  @Output() public ctaEvent = new EventEmitter<string>();
  @Input() public shouldEnablePreChatWelcome: boolean;

  public handleClick() {
    this.ctaEvent.emit(this.props.value);
  }
}
