// eslint-disable-next-line @nx/enforce-module-boundaries
import { conversationsQuery } from '@cigna/omni/conversations-state-data-access';
import { liveAvailabilityQuery } from '@cigna/omni/live-availability-state-data-access';
import {
  generateCommChannelCHEContent,
  generateCommChannelEAPContent,
  generateCommChannelSOContent,
  generateCommChannelENContent,
  isAsyncCHEConversation,
  isBehavioralContextTags,
  isClinicalNursingContextTags,
  isDMContextTags,
  isMedicalContextTags,
} from '@cigna/omni/shared-util';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  ChatState,
  ChatStateButton,
  HeaderBackgroundClass,
  HeaderTitle,
} from './chat.interfaces';
import { ConversationDTO } from '@cigna/vampire-dto';
import { CHAT_FEATURE_KEY } from './chat.reducers';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// eslint-disable-next-line @nx/enforce-module-boundaries
import { featuresEnabled } from '@cigna/shared/angular/features-feature';

export const getChat = createFeatureSelector<ChatState>(CHAT_FEATURE_KEY);

export const getCurrentComponent = createSelector(
  getChat,
  featuresEnabled(['isDcmChat']),
  ({ history }, isDcmChat) =>
    history.length
      ? history[history.length - 1]
      : isDcmChat
      ? 'history'
      : 'home',
);

export const getMuteState = createSelector(getChat, ({ mute }) => mute);

export const getHistoryState = createSelector(
  getChat,
  ({ history }) => history,
);

export const getChatState = createSelector(
  getChat,
  ({ chatState }) => chatState,
);

export const isChatClosed = createSelector(
  getChat,
  ({ chatState }) => chatState === 'closed',
);

export const isChatOpen = createSelector(
  getChat,
  ({ chatState }) => chatState === 'open',
);

export const isChatMinimized = createSelector(
  getChat,
  ({ chatState }) => chatState === 'minimized',
);

export const getDirection = createSelector(
  getChat,
  ({ direction }) => direction,
);

export const getHistoryFilter = createSelector(
  getChat,
  ({ historyFilter }) => historyFilter,
);

export const getCanGoBack = createSelector(
  getChat,
  isChatMinimized,
  ({ history }, isMinimized) =>
    !!history.length && history.length > 1 && !isMinimized,
);

export const isBackButtonDisabled = createSelector(
  conversationsQuery.selectConversation,
  conversationsQuery.selectConversationEvents,
  (conversation, events) =>
    !!conversation &&
    ((conversation.type === 'bot' && !events.length) ||
      conversation.state === 'transfer'),
);

export const getBackButtonContent = createSelector(
  conversationsQuery.selectConversation,
  (conv) => {
    if (conv && conv.state !== 'closed' && conv.type !== 'async') {
      return 'End Chat';
    }
    return 'Back';
  },
);

export const getHeaderBackgroundClass = createSelector(
  getCurrentComponent,
  (component): HeaderBackgroundClass =>
    component === 'home' ? '' : 'white-header',
);

const chatTitle = 'Chat';
const chatWithUsTitle = 'Chat With Us';
const liveChatTitle = 'Live Chat';
const closedChatTitle = 'Closed Chat';
const dmTitle = 'Direct Message';
const dmsTitle = 'Direct Messages';
const closedDMTitle = 'Closed Direct Message';
const closedMessageTitle = 'Closed Message';
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getHeaderTitleByConversationType(
  type: ConversationDTO['type'],
  state: ConversationDTO['state'],
  liveAvailability: boolean,
  context: ConversationDTO['context'],
  enableNewChatExp: boolean,
  isTerminatedUser: boolean,
  isDcmChat: boolean,
  enableNewDMUI: boolean,
) {
  const messageTitle = isDcmChat ? chatTitle : 'Message';
  switch (type) {
    case 'live':
      return state !== 'closed'
        ? enableNewChatExp && !isTerminatedUser
          ? enableNewDMUI
            ? liveChatTitle
            : chatWithUsTitle
          : chatTitle
        : closedChatTitle;
    case 'async':
      if (state !== 'closed') {
        return isBehavioralContextTags(context.tags) ||
          isMedicalContextTags(context.tags)
          ? dmTitle
          : messageTitle;
      }
      return isDcmChat
        ? chatTitle
        : isBehavioralContextTags(context.tags) ||
          isMedicalContextTags(context.tags)
        ? enableNewDMUI
          ? closedMessageTitle
          : closedDMTitle
        : closedMessageTitle;
    case 'bot':
      return liveAvailability && !isClinicalNursingContextTags(context.tags)
        ? enableNewChatExp && !isTerminatedUser
          ? enableNewDMUI
            ? liveChatTitle
            : chatWithUsTitle
          : chatTitle
        : 'Message';
    default:
      return enableNewChatExp
        ? enableNewDMUI
          ? liveChatTitle
          : chatWithUsTitle
        : '';
  }
}

export const getHeaderTitle = createSelector(
  getCurrentComponent,
  conversationsQuery.selectConversation,
  liveAvailabilityQuery.getLiveChatAvailable,
  featuresEnabled(['enableNewPreChatWelcome']),
  featuresEnabled(['isTerminatedUser']),
  featuresEnabled(['isDcmChat']),
  featuresEnabled(['enableNewDMUI']),
  (
    component,
    conversation,
    liveAvailability,
    enableNewPreChatWelcome,
    isTerminatedUser,
    isDcmChat,
    enableNewDMUI,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): HeaderTitle => {
    const listHeader = isDcmChat
      ? 'Conversations'
      : enableNewDMUI
      ? 'Conversation History'
      : 'Chat History';
    switch (component) {
      case 'dialog':
        if (!conversation) {
          return enableNewPreChatWelcome
            ? isTerminatedUser || isDcmChat
              ? chatTitle
              : chatWithUsTitle
            : '';
        }

        return getHeaderTitleByConversationType(
          conversation.type,
          conversation.state,
          liveAvailability,
          conversation.context,
          enableNewPreChatWelcome,
          isTerminatedUser,
          isDcmChat,
          enableNewDMUI,
        );

      case 'splashView':
        return chatWithUsTitle;

      case 'activeDMsView':
        return dmsTitle;

      case 'history':
        return enableNewPreChatWelcome ? listHeader : 'My Conversations';
      default:
        return '';
    }
  },
);

export const shouldClose = createSelector(
  getCurrentComponent,
  conversationsQuery.selectConversation,
  isChatMinimized,
  (component, conversation, minimized) =>
    minimized
      ? false
      : !(
          component === 'dialog' &&
          conversation &&
          conversation.state !== 'closed' &&
          (conversation.type === 'live' ||
            conversation.type === 'bot' ||
            isDMContextTags(conversation.context.tags) ||
            isAsyncCHEConversation(conversation.context, conversation.type))
        ),
);

export const toggleChatStateButton: MemoizedSelector<object, ChatStateButton> =
  createSelector(isChatOpen, shouldClose, (isOpen, close): ChatStateButton => {
    if (close) {
      return {
        copy: 'Close',
        icon: 'close',
        iconData: {},
      };
    }

    return isOpen
      ? {
          copy: 'Minimize',
          icon: 'directionArrow',
          iconData: {
            direction: 'bottom',
          },
        }
      : {
          copy: 'Open',
          icon: 'directionArrow',
          iconData: {
            direction: 'top',
          },
        };
  });

export const getCommChannelContent = createSelector(
  featuresEnabled(['asyncSupportChat']),
  featuresEnabled(['liveClinicianChat']),
  liveAvailabilityQuery.getLiveChatAvailable,
  liveAvailabilityQuery.getChatCloseHrsMsg,
  featuresEnabled(['personalGuideChat']),
  featuresEnabled(['advocateChat', 'useRightNowChat'], 'OR'),
  featuresEnabled(['isEvernorthEnabled']),
  (
    isAsyncSupportEnabled,
    isLiveClinicianChatEnabled,
    isLiveChatAvailable,
    chatCloseHrsMsg,
    isPersonalGuide,
    isUseRightNowChat,
    isEvernorthEnabled,
  ) => ({
    support: {
      ...generateCommChannelSOContent({
        isAsyncSupportEnabled,
        isLiveChatAvailable,
        isPersonalGuide,
      }),
    },
    che: isLiveClinicianChatEnabled
      ? {
          ...generateCommChannelCHEContent(isLiveChatAvailable),
        }
      : null,
    eap:
      isUseRightNowChat && !isEvernorthEnabled
        ? {
            ...generateCommChannelEAPContent(isLiveChatAvailable),
          }
        : isUseRightNowChat && isEvernorthEnabled
        ? {
            ...generateCommChannelENContent(
              isLiveChatAvailable,
              chatCloseHrsMsg,
            ),
          }
        : null,
  }),
);

export const isHistory = createSelector(
  getHistoryState,
  (historyState): boolean => historyState.includes('history'),
);

export const getChatByClientList = createSelector(
  getChat,
  ({ generalChatByClient }) => generalChatByClient,
);

export const getIsInternalUser = createSelector(
  getChat,
  ({ isInternalUser }) => isInternalUser,
);

export const getSelectedClientDetail = createSelector(
  getChat,
  ({ selectedClientDetail }) => selectedClientDetail,
);

export const getShowOptionsMenu = createSelector(
  getChat,
  ({ showOptionsMenu }) => showOptionsMenu,
);

export const chatQuery = {
  getBackButtonContent,
  getCanGoBack,
  getChatState,
  getChat,
  getCommChannelContent,
  getCurrentComponent,
  getDirection,
  getHistoryFilter,
  getHeaderBackgroundClass,
  getHeaderTitle,
  isBackButtonDisabled,
  isChatClosed,
  isChatOpen,
  isChatMinimized,
  shouldClose,
  toggleChatStateButton,
  getMuteState,
  isHistory,
  getChatByClientList,
  getIsInternalUser,
  getSelectedClientDetail,
  getShowOptionsMenu,
};
