import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberPipe } from './phone-number/phone-number.pipe';
import { TranslateFallbackPipe } from './translation-pipe/translation-check.pipe';
import { DotDotDotPipe } from './dotdotdot-pipe/dotdotdot.pipe';
import { UpDownDirective } from './up-down/up-down.directive';
import { RightLeftDirective } from './right-left/right-left.directive';
import { StickyDirective } from './sticky/sticky.directive';
import { TemplatePipe } from './template-pipe/template.pipe';
import { TranslateOrPipe } from './translateOr-pipe/translateOr.pipe';
import { CommaSeparatedListPipe } from './comma-seperated-list/comma-separated-list.pipe';
import { Translate2Pipe } from './translate2/translate2.pipe';
import { SecureDocsDirective } from './secure-docs/secure-docs.directive';
import { ArrayIncludesPipe } from './array-includes/array-includes.pipe';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { AriaHiddenLinksDirective } from './aria-hidden-links/aria-hidden-links.directive';

const DECLARATIONS = [
  DotDotDotPipe,
  PhoneNumberPipe,
  CommaSeparatedListPipe,
  RightLeftDirective,
  StickyDirective,
  TemplatePipe,
  TranslateFallbackPipe,
  UpDownDirective,
  TranslateOrPipe,
  Translate2Pipe,
  SecureDocsDirective,
  ArrayIncludesPipe,
  AutofocusDirective,
  AriaHiddenLinksDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class UiUtilsModule {}
