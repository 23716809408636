import { Injectable, NgZone, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WindowService } from '@cigna/shared/angular/core/window-util';
import { RESPONSIVE_CONFIG, ResponsiveConfig } from './responsive.config';

export enum ScreenSize {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  XLarge = 'xl',
}

interface MQRule {
  media: string;
  size: ScreenSize;
}

@Injectable({ providedIn: 'root' })
export class MediaQueryService {
  private _screenSize$ = new BehaviorSubject(ScreenSize.XLarge);
  private _rules: MQRule[];

  constructor(
    private _ngZone: NgZone,
    private _window: WindowService,
    @Inject(RESPONSIVE_CONFIG) config: ResponsiveConfig,
  ) {
    /**
     * order of the array entries does matter!
     * it is used for matching up or down through the screen sizes
     */
    this._rules = [
      { media: `(max-width: ${config.sm - 1}px)`, size: ScreenSize.XSmall },
      {
        media: `(min-width: ${config.sm}px) and (max-width: ${
          config.md - 1
        }px)`,
        size: ScreenSize.Small,
      },
      {
        media: `(min-width: ${config.md}px) and (max-width: ${
          config.lg - 1
        }px)`,
        size: ScreenSize.Medium,
      },
      {
        media: `(min-width: ${config.lg}px) and (max-width: ${
          config.xl - 1
        }px)`,
        size: ScreenSize.Large,
      },
      { media: `(min-width: ${config.xl}px)`, size: ScreenSize.XLarge },
    ];

    this.registerMatchers();
  }

  get match$(): Observable<ScreenSize> {
    return this._screenSize$.asObservable();
  }

  get match(): ScreenSize {
    return this._screenSize$.value;
  }

  isLargerOrEqual(screenSize: ScreenSize): boolean {
    return this.indexOf(screenSize) <= this.indexOf(this._screenSize$.value);
  }

  isSmallerOrEqual(screenSize: ScreenSize): boolean {
    return this.indexOf(screenSize) >= this.indexOf(this._screenSize$.value);
  }

  isEqual(screenSize: ScreenSize) {
    return screenSize === this._screenSize$.value;
  }

  private registerMatchers() {
    if (!this._window.matchMedia) {
      return;
    }

    this._rules.forEach((mq) => {
      const initialMql = this._window.matchMedia(mq.media);

      this.emitScreenSize(initialMql, mq.size);

      initialMql.addListener((mql) =>
        this._ngZone.run(() => this.emitScreenSize(mql, mq.size)),
      );
    });
  }

  private emitScreenSize(
    mql: MediaQueryListEvent | MediaQueryList,
    screenSize: ScreenSize,
  ) {
    if (!mql.matches) {
      return;
    }

    this._screenSize$.next(screenSize);
  }

  private indexOf(screenSize: ScreenSize): number {
    return this._rules.findIndex((mq) => mq.size === screenSize);
  }
}
