import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OMNI_CONFIG } from '@cigna/omni/shared-util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OMNI_RIGHT_NOW_CONFIG } from '@cigna/omni/right-now-data-access';
import { CONTENTFUL_CONFIG } from '@cigna/shared/angular/contentful-util';

import { CookieService } from 'ngx-cookie-service';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { routerReducer } from '@ngrx/router-store';
import { TranslateModule } from '@ngx-translate/core';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { of } from 'rxjs';
import { AnalyticsDataAccessModule } from '@cigna/shared/angular/analytics-data-access';
import { CoreWindowModule } from '@cigna/shared/angular/core/window-util';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import {
  LogEventLevel,
  LoggingService,
} from '@cigna/shared/angular/logging-util';
import { CoreRouterStateModule } from '@cigna/shared/angular/core/router-state-data-access';
import {
  TEALEAF_CONFIG,
  SharedTealeafUtilModule,
} from '@cigna/shared/angular/tealeaf-util';

import { SHARED_GATEWAY_CONFIG } from '@cigna/shared/angular/gateways-util';
import { GATEWAY_CONFIG as PDL_GATEWAY_CONFIG } from '@cigna/pdl-viewer/shared/gateway-util';

import {
  contentBundleProviders,
  ResourceContentDataAccessModule,
} from '@cigna/shared/angular/resource-content-data-access';

import { ChcpAnalyticsUtilModule } from '@cigna/chcp/shared/analytics-util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChcpShellFeatureModule } from '@cigna/chcp/shell/feature';
import { ChcpShellUiModule } from '@cigna/chcp/shell/ui';
import { ChcpAuthDataAccessModule } from '@cigna/chcp/auth/data-access';
import { ChcpMfaDataAccessModule } from '@cigna/chcp/mfa/data-access';
import { salesDemoProviders } from '@cigna/chcp/shell/data-access';

import { ChcpSharedGraphqlDataAccessModule } from '@cigna/chcp/shared/graphql-data-access';

import {
  ENVIRONMENT_CONFIG,
  CDN_BASE_URL,
  APP_CONTEXT,
} from '@cigna/chcp/shared/environment-util';
/**
 * test in libs/chcp/shared/features-util/src/lib/chcp-shared-features-util.module.spec.ts
 * references environment file here, which causes circular dependency
 * TODO move this test to app
 */
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  ChcpSharedFeaturesUtilModule,
  permissionToggles,
} from '@cigna/chcp/shared/features-util';
import { manifestAccessorFactory } from '@cigna/chcp/shared/content-bundle-util';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';

export function cdnBaseUrlFactory(baseHref: string) {
  return environment.api.gateways.cdn === ''
    ? baseHref
    : `${environment.api.gateways.cdn}spa/chcp/`;
}

import { resetReducer } from './+state/reset.reducer';

export const metaReducers: Array<MetaReducer<any>> = [resetReducer];

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    ChcpShellFeatureModule.withConfig(environment.sessionTimeoutPopup),
    ChcpShellUiModule,
    ChcpAuthDataAccessModule.withConfig(environment.oidc, [
      environment.oidc.authorizeUri,
      environment.api.gateways.publicApi,
      environment.api.gateways.gsgPublicApi,
      environment.api.gateways.resourceContent,
      environment.api.gateways.cdn,
    ]),
    ChcpSharedGraphqlDataAccessModule.withConfig(
      environment.api.gateways.graphql,
    ),
    AnalyticsDataAccessModule,
    ChcpAnalyticsUtilModule,
    ChcpMfaDataAccessModule,
    CoreWindowModule.forRoot(),
    UiIconModule.forRoot({
      factory: cdnBaseUrlFactory,
      deps: [APP_BASE_HREF],
    }),
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    CoreRouterStateModule,
    StoreDevtoolsModule.instrument({ connectInZone: true }),
    TranslateModule.forRoot(),
    EffectsModule.forRoot([]),
    BrowserAnimationsModule,
    ChcpSharedFeaturesUtilModule,
    ResourceContentDataAccessModule.withConfig({
      factory: manifestAccessorFactory,
      deps: [LoggingService],
    }),
    SharedTealeafUtilModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/app/',
    },
    {
      provide: APP_CONTEXT,
      useValue: 'chcp',
    },
    {
      provide: ENVIRONMENT_CONFIG,
      useValue: environment,
    },
    {
      provide: 'consumerCode',
      useValue: '1000',
    },
    {
      provide: 'secureApi',
      useValue: environment.api.gateways.secureApi,
    },
    {
      provide: 'publicApi',
      useValue: environment.api.gateways.publicApi,
    },
    {
      provide: 'domainRedirectUrl',
      useValue: environment.redirect.crossOrigin.domain,
    },
    {
      provide: CONTENTFUL_CONFIG,
      useValue: {
        space: environment.contentful.space,
        accessToken: environment.contentful.accessToken,
        previewApi: environment.contentful.previewApi,
        environment: environment.contentful.environment,
      },
    },
    {
      provide: OMNI_CONFIG,
      useValue: of({ omni: environment.omni, api: environment.api }),
    },
    {
      provide: OMNI_RIGHT_NOW_CONFIG,
      useValue: {},
    },
    {
      provide: 'inboxUrl',
      useValue: environment.redirect.inbox,
    },
    {
      provide: 'permissionToggleList',
      useValue: permissionToggles,
    },
    {
      provide: CDN_BASE_URL,
      useFactory: cdnBaseUrlFactory,
      deps: [APP_BASE_HREF],
    },
    {
      provide: TEALEAF_CONFIG,
      useValue: {
        logLevel: LogEventLevel.Error,
        logDescription: 'CHCP Javascript Error',
      },
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    ...contentBundleProviders(environment.contentBundle),
    {
      provide: SHARED_GATEWAY_CONFIG,
      useValue: {
        cdn: environment.api.gateways.cdn,
        resourceContent: environment.api.gateways.resourceContent,
      },
    },
    {
      provide: PDL_GATEWAY_CONFIG,
      useValue: {
        drugsearch: environment.api.gateways.secureApi,
        gsg: environment.api.gateways.gsgSecureApi,
        drugSearchPublic: environment.api.gateways.gsgSecureApi,
      },
    },
    ...salesDemoProviders(environment.api.salesDemo),
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
