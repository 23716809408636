import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch } from '@ngrx/router-store/data-persistence';
import {
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  mergeMap,
  filter,
  groupBy,
  catchError,
  concatMap,
  distinctUntilChanged,
} from 'rxjs/operators';
import { combineLatest, from, forkJoin, of } from 'rxjs';

import { ContentBundlePartialState } from './content-bundle.reducer';
import { ContentBundleService } from './content-bundle.service';
import {
  loadContentBundle,
  contentBundleLoaded,
  contentBundleLoadError,
  changeLanguage,
  languageChanged,
  loadManifest,
  manifestLoaded,
  manifestLoadError,
  requestBundles,
} from './content-bundle.actions';
import { ContentBundleFacade } from './content-bundle.facade';
import {
  CONTENT_BUNDLE_MANIFEST_ACCESSOR,
  ManifestAccessorFn,
} from './content-bundle.config';

@Injectable()
export class ContentBundleEffects {
  loadContentBundle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContentBundle),
      groupBy(({ name }) => name),
      mergeMap((bundleGroup$) =>
        bundleGroup$.pipe(
          distinctUntilChanged(
            (a, b) => a.filename === b.filename && a.lang === b.lang,
          ),
          concatMap(
            ({ name, filename, lang }: ReturnType<typeof loadContentBundle>) =>
              this._service.getContentBundle(filename).pipe(
                map((translations) =>
                  contentBundleLoaded({ name, filename, lang, translations }),
                ),
                catchError((error) =>
                  of(contentBundleLoadError({ name, filename, lang, error })),
                ),
              ),
          ),
        ),
      ),
    ),
  );

  loadManifest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadManifest),
      withLatestFrom(this._store$),
      fetch<[ContentBundlePartialState], ReturnType<typeof loadManifest>>({
        run: () =>
          this._service
            .getManifestFile()
            .pipe(map((manifest) => manifestLoaded({ manifest }))),
        onError: (_, error) => manifestLoadError({ error }),
      }),
    ),
  );

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLanguage),
      withLatestFrom(this._facade.getLoaded(), this._facade.getLoading()),
      switchMap(([{ lang }, loaded, loading]) =>
        forkJoin([
          loading.length
            ? this._facade.loadBundle(loading, lang)
            : of(undefined),
          loaded.length ? this._facade.loadBundle(loaded, lang) : of(undefined),
        ]).pipe(
          take(1),
          map(() => languageChanged({ lang })),
        ),
      ),
    ),
  );

  useLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(languageChanged),
        tap(({ lang }) => {
          this._ngxTranslateService.use(lang);
        }),
      ),
    { dispatch: false },
  );

  updateTranslation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(contentBundleLoaded),
        tap(({ lang, translations }) => {
          if (translations) {
            const updated = mergeDeep(
              this._ngxTranslateService.translations[lang] || {},
              translations,
            );
            this._ngxTranslateService.setTranslation(lang, updated);
          }
        }),
      ),
    { dispatch: false },
  );

  requests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestBundles),
      mergeMap(({ lang, bundles }) =>
        combineLatest([
          this._facade.getLoaded(lang),
          this._facade.getLoading(lang),
          this._facade.manifest$,
          lang ? of(lang) : this._facade.lang$,
        ]).pipe(
          take(1),
          mergeMap(([loaded, loading, manifest, locale]) =>
            from(bundles).pipe(
              filter(
                (name) => !loading.includes(name) && !loaded.includes(name),
              ),
              mergeMap((name) =>
                this._manifestAccessor(name, manifest, locale).pipe(
                  withLatestFrom(this._facade.getRequestedFiles(locale, name)),
                  switchMap(([filenames, requestedFiles]) => {
                    // prevent `ContentBundleFacade.loadBundle()` from hanging when bundle
                    // is never loaded because manifest accessor does not return files for it
                    if (filenames.length === 0) {
                      return [contentBundleLoaded({ name, lang: locale })];
                    }
                    return filenames
                      .filter((f) => !requestedFiles.includes(f))
                      .map((filename) =>
                        loadContentBundle({ name, filename, lang: locale }),
                      );
                  }),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private _service: ContentBundleService,
    private _facade: ContentBundleFacade,
    private actions$: Actions,
    private _ngxTranslateService: TranslateService,
    @Inject(CONTENT_BUNDLE_MANIFEST_ACCESSOR)
    private _manifestAccessor: ManifestAccessorFn,
    private _store$: Store<ContentBundlePartialState>,
  ) {}

  ngrxOnInitEffects(): Action {
    return loadManifest();
  }
}

function isObject(item: unknown): item is Bundle {
  return !!item && typeof item === 'object' && !Array.isArray(item);
}

interface Bundle {
  [key: string]: Bundle | string;
}

function mergeDeep(target: Bundle, source: Bundle): Bundle {
  const output = { ...target };
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key] as Bundle, source[key] as Bundle);
        }
      } else if (output[`${key}.json`] && !source[`${key}.json`]) {
        Object.assign(output, {
          [key]: source[key],
          [`${key}.json`]: undefined,
        });
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}
