import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  ImplicitFlowConfig,
  implicitFlowConfig,
  ImplicitFlowService,
} from '@cigna/shared/angular/auth/oidc-util/implicit';
import {
  AuthDataAccessModule,
  AuthConfig,
  authConfig,
} from '@cigna/shared/angular/auth-data-access';

import { ChcpAuthenticationService } from './auth/authentication.service';

@NgModule({
  imports: [CommonModule, AuthDataAccessModule],
})
export class ChcpAuthDataAccessModule {
  static withConfig(
    config: ChcpAuthConfig,
    publicUris: string[],
  ): ModuleWithProviders<ChcpAuthDataAccessModule> {
    return {
      ngModule: ChcpAuthDataAccessModule,
      providers: [
        implicitFlowConfig(config),
        authConfig({
          authenticator: ImplicitFlowService,
          errorHandler: ChcpAuthenticationService,
          secureUris: config.secureUris,
          publicUris: [...(config.publicUris || []), ...publicUris],
          noRefreshUris: config.noRefreshUris,
          loadBehavior: 'onDemand',
        }),
      ],
    };
  }
}

export type ChcpAuthConfig = Pick<
  AuthConfig,
  'secureUris' | 'publicUris' | 'noRefreshUris'
> &
  ImplicitFlowConfig;
