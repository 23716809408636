import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';

import {
  AppContext,
  checkValidResponse,
  logError,
} from '@cigna/chcp/shared/util';
import { FlagUpdateType, FlaggedClaim, FlaggedPatient } from '../..';
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';

@Injectable({
  providedIn: 'root',
})
export class UserFlagService {
  public flaggedApi: string;
  public patientLambda: string;

  public CONSUMER_CODE = '1000';

  constructor(
    private http: HttpClient,
    @Inject('secureApi') secureApi: string,
    @Inject(APP_CONTEXT) public appContext: AppContext,
  ) {
    this.flaggedApi = `${secureApi}/flag/v1`;
    this.patientLambda = `${secureApi}/patients`;
  }

  getFlaggedPatients(isDentalUser: boolean, hasDashboardLambda = true) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );
    return hasDashboardLambda
      ? this.http
          .get(
            `${this.patientLambda}${
              isDentalUser
                ? '/dent'
                : this.appContext === 'evernorth'
                ? '/epp'
                : '/med'
            }/dashboard/v1/flag/benefits`,
            {
              params,
            },
          )
          .pipe(
            map(checkValidResponse),
            map((res) => res.flaggedPatients),
            catchError(logError),
          )
      : this.http
          .get(`${this.flaggedApi}/benefits`, {
            params,
          })
          .pipe(
            map(checkValidResponse),
            map((res) => res.flaggedPatients),
            catchError(logError),
          );
  }

  getFlaggedClaims() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );
    return this.http
      .get(`${this.flaggedApi}/claims`, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) =>
          res.flaggedClaims.filter(
            (claim: any) => claim.claimSummary.claimReferenceNumber,
          ),
        ),

        catchError(logError),
      );
  }

  updateFlaggedPatients(
    payload: {
      type: FlagUpdateType;
      data: FlaggedPatient[];
    },
    isDentalUser: boolean,
    hasDashboardLambda = true,
  ) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    const requestBody = {
      memberSummary: payload.data,
    };

    return hasDashboardLambda
      ? this.http
          .put(
            `${this.patientLambda}${
              isDentalUser
                ? '/dent'
                : this.appContext === 'evernorth'
                ? '/epp'
                : '/med'
            }/dashboard/v1/flag/benefit/${payload.type}`,
            requestBody,
            {
              params,
            },
          )
          .pipe(map(checkValidResponse), catchError(logError))
      : this.http
          .put(`${this.flaggedApi}/benefit/${payload.type}`, requestBody, {
            params,
          })
          .pipe(map(checkValidResponse), catchError(logError));
  }

  updateFlaggedClaims(payload: { type: FlagUpdateType; data: FlaggedClaim[] }) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    const requestBody = {
      flagClaim: payload.data.map((fc) => ({
        tinNumbers: fc.tinNumbers,
        dateFlagged: fc.dateFlagged,
        member: {
          memberId: fc.member.memberId ? fc.member.memberId : '',
          memberFirstName: fc.member.memberFirstName,
          memberLastName: fc.member.memberLastName,
        },
        claimSummary: {
          claimReferenceNumber: fc.claimSummary.claimReferenceNumber,
          providerGeneratedAccountNumber: fc.claimSummary
            .providerGeneratedAccountNumber
            ? fc.claimSummary.providerGeneratedAccountNumber
            : '--',
          claimStatus: fc.claimSummary.claimStatus,
          medicare: fc.claimSummary.medicare,
          dateOfService: fc.claimSummary.dateOfService,
          dateProcessed: fc.claimSummary.dateProcessed,
          chargeAmount: fc.claimSummary.chargeAmount,
          claimCompositeKey: fc.claimSummary.claimCompositeKey,
          compositeClaimId: fc.claimSummary.compositeClaimId,
        },
      })),
    };

    return this.http
      .put(`${this.flaggedApi}/claim/${payload.type}`, requestBody, {
        params,
      })
      .pipe(map(checkValidResponse), catchError(logError));
  }
}
