import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniGateway } from '@cigna/omni/shared-util';
import { Observable } from 'rxjs';
import { LiveAvailability } from '../models';

export type TimeFormat = '12h' | '24h';

@Injectable({
  providedIn: 'root',
})
export class LiveAvailabilityDataAccessService {
  constructor(private http: HttpClient, private gateway: OmniGateway) {}

  public getLiveAvailability(
    offset: string,
    format: TimeFormat,
  ): Observable<LiveAvailability> {
    const url = this.gateway.liveAvailabilityUrl({ offset, format });
    return this.http.get<LiveAvailability>(url);
  }
}
