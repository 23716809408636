import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  USER_OPTIONS_FEATURE_KEY,
  UserOptionsState,
} from './user-options.reducer';

const getUserOptionsState = createFeatureSelector<UserOptionsState>(
  USER_OPTIONS_FEATURE_KEY,
);

const getJobRoles = createSelector(
  getUserOptionsState,
  (state) => state.jobRoles,
);
const getSecurityQuestions = createSelector(
  getUserOptionsState,
  (state) => state.securityQuestions,
);

export const userOptionsQuery = {
  getJobRoles,
  getSecurityQuestions,
};
