import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  ChatEffects,
  CHAT_FEATURE_KEY,
  chatReducers,
  ChatAnalyticsEffects,
} from './+state';

@NgModule({
  imports: [
    StoreModule.forFeature(CHAT_FEATURE_KEY, chatReducers),
    EffectsModule.forFeature([ChatEffects, ChatAnalyticsEffects]),
  ],
})
export class OmniChatStateModule {}
