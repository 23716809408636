import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConversationMessageDialogLinkText, LinkTextItem } from '../interfaces';

interface MessageObj {
  type: string;
  text?: string;
  linkInfo?: LinkTextItem;
}

@Component({
  selector: 'cigna-omni-messaging-link-text',
  templateUrl: './link-text.component.html',
  styleUrls: ['./link-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingLinkTextComponent implements OnInit {
  @Input() public linkText: ConversationMessageDialogLinkText;
  @Input() public message: string;

  public LINK_MARKER = '%';
  public messageList: string[] = [];
  public messageObjList: MessageObj[] = [];

  public iconMapper: { [key: string]: string } = {
    file: 'file',
    video: 'play-btn',
    web_url: 'internet-of-things',
    url: 'internet-of-things',
  };

  constructor(private router: Router) {}

  ngOnInit() {
    this.generateMessageList(this.message);
    this.messageObjList = this.generateMessageObjList();
  }

  generateMessageList(message: string) {
    let combinedLinkMarkers = '';
    if (this.linkText) {
      combinedLinkMarkers = Object.keys(this.linkText)
        .map((link) => `${this.LINK_MARKER}${link}${this.LINK_MARKER}`)
        .join('|');
      this.messageList = message.split(
        new RegExp(`(${combinedLinkMarkers})`, 'g'),
      );
    } else {
      this.messageList = [message];
    }
  }

  generateMessageObjList() {
    return this.messageList.map((message) => {
      if (
        message.startsWith(this.LINK_MARKER) &&
        message.endsWith(this.LINK_MARKER)
      ) {
        const linkInfo = this.linkText[message.slice(1, -1)];
        return {
          type: 'link',
          linkInfo: {
            ...linkInfo,
            url: linkInfo.url.replace('§', '&sect'),
          },
        };
      }
      return { type: 'text', text: message };
    });
  }

  openLink(url: string) {
    this.router.navigateByUrl(url);
  }
}
