/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { WindowService } from '@cigna/shared/angular/core/window-util';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import {
  ConversationsDataAccessService,
  ConversationMetaExtens,
} from '@cigna/omni/data-access';
import { OmniLiveAvailabilityFacade } from '@cigna/omni/live-availability-state-data-access';
import {
  ConversationDTOStatus,
  ConversationEventWithState,
  isBotCHEConversation,
  ConversationMetaRoute,
  isClinicalNursingContextMetaRoute,
  isConversation,
  isConversationEndInitiationEvent,
  isConversationTransferInitiationEvent,
  LogoutService,
} from '@cigna/omni/shared-util';
import { ConversationDTOMetadata } from './conversation.interface';
import {
  ConversationActivityEventDTO,
  ConversationDTO,
  ConversationMessageEventDTO,
  ConversationStateEventDTO,
  ConversationSummaryDTO,
  ConversationEvent,
  ConversationTransferResultDTO,
} from '@cigna/vampire-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { combineLatest, concat, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  distinct,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  throttle,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import {
  BackToPrevious,
  ConversationDiff,
  ConversationsActionTypes,
  CreateConversation,
  CreateConversationFailure,
  CreateConversationSuccess,
  CreateLiveConversation,
  EndConversation,
  EndConversationFailure,
  EndConversationSuccess,
  GetActiveConversation,
  GetActiveConversationFailure,
  GetActiveConversationSuccess,
  GetActiveAsyncConversation,
  UpdateActiveAsyncConvCount,
  UpdateClosedAsyncConvCount,
  GetConversation,
  GetConversationFailure,
  GetConversationHistory,
  GetConversationSuccess,
  GetTransferredConversation,
  GetTransferredConversationFailure,
  GetTransferredConversationSuccess,
  LoadConversationsFailure,
  LoadConversationsSuccess,
  MessageViewed,
  MessageViewedFailure,
  MessageViewedSuccess,
  OnKeyUp,
  OpenConversation,
  PostActivity,
  PostActivityFailure,
  PostActivitySuccess,
  PostCta,
  PostCtaFailure,
  PostCtaSuccess,
  PostMessage,
  PostMessageFailure,
  PostMessageSuccess,
  SetSelectedConversationId,
  TerminateConversation,
  TransferConversation,
  TransferConversationFailure,
  TransferConversationSuccess,
} from './conversations.actions';
import { OmniConversationsFacade } from './conversations.facade';
import { AuthFacade } from '@cigna/shared/angular/auth-data-access';

export function updateConversation(
  conversation: ConversationDTO,
): ConversationDTO {
  const transferManualIndex = conversation.events.findIndex(
    (event) =>
      (event as ConversationEventWithState).state === 'transfer-manual',
  );
  const lastMessageIndex = conversation.events
    .map(
      (event, index) => event.type === 'message' && index < transferManualIndex,
    )
    .lastIndexOf(true);
  return {
    ...conversation,
    events: conversation.events.map((event, index) =>
      index === lastMessageIndex
        ? {
            ...event,
            message:
              'Please submit your question so that your personal advocate may better assist you',
          }
        : event,
    ),
  };
}

const checkIfChatOutageStarted = (events: OutageEvent[]) => {
  if (events.length <= 1) {
    return false;
  }
  const last = events[events.length - 1];
  const secondLast = events[events.length - 2];
  return (
    last.type === 'error' &&
    secondLast.type === 'state' &&
    secondLast.reason === 'UPSTREAM_OUTAGE'
  );
};

interface OutageEvent extends ConversationEvent {
  reason: string;
  message: string;
}

interface CommonMetadata {
  metadata?: {
    outcome: {
      status: number;
      additionalDetails: Array<{
        message: string;
        severity: string;
      }>;
    };
  };
}

interface OutageResultConversationTransfer
  extends ConversationTransferResultDTO,
    CommonMetadata {}

interface OutageCreateConversationResult
  extends ConversationDTO,
    CommonMetadata {}

@Injectable()
export class ConversationsEffects {
  createNewConversation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateConversation>(ConversationsActionTypes.CreateConversation),
      withLatestFrom(
        this.conversationsFacade.conversationMetadata$,
        this.liveAvailbilityFacade.liveChatAvailable$,
        this.conversationsFacade.conversationId$,
      ),
      filter(
        ([_payload, _metadata, isLiveChatAvailable, conversationID]) =>
          isLiveChatAvailable && !conversationID,
      ),
      exhaustMap(
        ([
          {
            payload: { type, message, context },
          },
          conversationMeta,
        ]) =>
          this.dataAccessService
            .postConversation(
              type,
              {
                ...context,
                tags: conversationMeta?.currentType
                  ? [conversationMeta.currentType]
                  : context.tags,
                meta: conversationMeta,
              },
              message,
            )
            .pipe(
              tap((conversation: OutageCreateConversationResult) => {
                if (conversation.metadata?.outcome.status === 501) {
                  const outageMsg =
                    conversation.metadata.outcome.additionalDetails[0].message;
                  this.liveAvailbilityFacade.setChatOutageMsg(outageMsg);
                  this.liveAvailbilityFacade.setChatOutage(true);
                  return of(new TransferConversationFailure(conversation));
                }
                const hasOutageStarted = checkIfChatOutageStarted(
                  conversation.events as OutageEvent[],
                );
                this.liveAvailbilityFacade.setChatOutage(hasOutageStarted);
                const chatOutageMsg = hasOutageStarted
                  ? (conversation.events as OutageEvent[]).find(
                      (event: OutageEvent) => event.type === 'error',
                    )?.message ?? ''
                  : '';
                this.liveAvailbilityFacade.setChatOutageMsg(chatOutageMsg);
                return this.conversationsFacade.setExistingConversationId(
                  conversation._id,
                );
              }),
              map(
                (conversation: OutageCreateConversationResult) =>
                  new CreateConversationSuccess(conversation),
              ),
              catchError((error) => of(new TransferConversationFailure(error))),
            ),
      ),
    ),
  );

  createNewLiveConversation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateLiveConversation>(
        ConversationsActionTypes.CreateLiveConversation,
      ),
      withLatestFrom(
        this.conversationsFacade.conversationMetadata$,
        this.liveAvailbilityFacade.liveChatAvailable$,
        this.conversationsFacade.conversationId$,
      ),
      filter(
        ([_payload, _metadata, isLiveChatAvailable, conversationID]) =>
          isLiveChatAvailable && !conversationID,
      ),
      exhaustMap(
        ([
          {
            payload: { type, context },
          },
          conversationMeta,
        ]) =>
          this.dataAccessService
            .postLiveConversation(type, {
              ...context,
              tags: conversationMeta?.currentType
                ? [conversationMeta.currentType]
                : context.tags,
              meta: conversationMeta,
            })
            .pipe(
              // eslint-disable-next-line sonarjs/no-identical-functions
              tap((conversation: ConversationDTOMetadata) => {
                this.conversationsFacade.setExistingConversationId(
                  conversation._id,
                );
                const meta = conversation.metadata?.outcome;
                if (meta && meta.status >= 400) {
                  new CreateConversationFailure({
                    message: meta.message,
                    status: meta.status,
                  });
                }
              }),
              map(
                (conversation) => new CreateConversationSuccess(conversation),
              ),
              catchError((error) => of(new CreateConversationFailure(error))),
            ),
      ),
    ),
  );

  setConversationId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SetSelectedConversationId>(
        ConversationsActionTypes.SetSelectedConversationId,
      ),
      withLatestFrom(this.conversationsFacade.conversationEntities$),
      mergeMap(([{ conversationId, convType }, entities]) => {
        const actions: Action[] = [
          new GetConversation(conversationId, convType),
          new PostActivity('listening'),
        ];
        const selectedConversation = entities[conversationId];

        if (selectedConversation && selectedConversation.transferIds) {
          const transferIds = selectedConversation.transferIds.filter(
            (transferId) => !entities[transferId],
          );
          actions.push(
            ...transferIds.map(
              (transferId) => new GetTransferredConversation(transferId),
            ),
          );
        }
        return actions;
      }),
    ),
  );

  backToPreviousClick$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<BackToPrevious>(ConversationsActionTypes.BackToPrevious),
      withLatestFrom(this.conversationsFacade.isAiBot$),
      switchMap(([{ payload }, isAiBot]) =>
        this.dataAccessService.endBotConversation(payload.convId).pipe(
          switchMap((conversation) =>
            isAiBot
              ? [
                  new SetSelectedConversationId(''),
                  new EndConversationSuccess(conversation),
                  new CreateConversation({
                    type: 'bot',
                    context: payload.context,
                  }),
                ]
              : [
                  new SetSelectedConversationId(''),
                  new CreateConversation({
                    type: 'bot',
                    context: payload.context,
                  }),
                ],
          ),
        ),
      ),
    ),
  );

  getTransferredConversation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTransferredConversation>(
        ConversationsActionTypes.GetTransferredConversation,
      ),
      mergeMap(({ payload }) =>
        this.dataAccessService.getBotConversation(payload).pipe(
          mergeMap((conversation) =>
            of(new GetTransferredConversationSuccess(conversation)),
          ),
          catchError((error) =>
            of(new GetTransferredConversationFailure(error)),
          ),
        ),
      ),
    ),
  );

  endConversation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<EndConversation>(ConversationsActionTypes.EndConversation),
      withLatestFrom(
        this.conversationsFacade.conversationsEntities$,
        this.featuresFacade.featuresEnabled(['isDcmChat']),
      ),
      filter(
        ([{ payload }, allConversations, isDcmChat]) =>
          !!(
            allConversations[payload] &&
            allConversations[payload]?.state !== 'closed'
          ) &&
          !isDcmChat && // UI will never end a chat session for dcm
          allConversations[payload]?.type !== 'async',
      ),
      concatMap(([{ payload }, allConversations]) => {
        const isLiveConvo =
          allConversations[payload] &&
          allConversations[payload]?.type !== 'bot';
        return (
          isLiveConvo
            ? this.dataAccessService.endLiveConversation(payload)
            : this.dataAccessService.endBotConversation(payload)
        ).pipe(
          tap(() => {
            this.conversationsFacade.setExistingConversationId('');
          }),
          map(
            (conversation) =>
              new EndConversationSuccess({
                ...conversation,
                _id:
                  (conversation as ConversationDTOStatus).convId ||
                  conversation._id,
              }),
          ),
          catchError((error) => of(new EndConversationFailure(error))),
        );
      }),
    ),
  );

  endOfConversationSurvey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetConversationSuccess>(
          ConversationsActionTypes.GetConversationSuccess,
        ),
        withLatestFrom(
          this.conversationsFacade.isHistory$,
          this.featuresFacade.featuresEnabled(['enableChatSurvey']),
        ),
        filter(
          ([
            {
              payload: { context, events, state, type },
            },
            isHistory,
            enableChatSurvey,
          ]) =>
            enableChatSurvey &&
            !isHistory &&
            state === 'closed' &&
            type !== 'async' && // (exclude DMs)
            !!(context.meta as ConversationMetaExtens).AGENT_IDENTIFIER &&
            !!context.meta?.ENGAGEMENT_ID &&
            !!(events as ConversationStateEventDTO[]).find(
              (event) =>
                event.state === 'closed' &&
                (event.reason === 'AGENT_CLOSED' ||
                  event.reason === 'USER_CLOSED'),
            ),
        ),
        tap(
          ([
            {
              payload: { context, events, state, type },
            },
            isHistory,
            enableChatSurvey,
          ]) => {
            const agentId = (context.meta as ConversationMetaExtens)
              .AGENT_IDENTIFIER;
            const [sessionId, taskId] = context.meta?.ENGAGEMENT_ID?.split(
              '|',
            ) as string[];
            const closedEvent = (events as ConversationStateEventDTO[]).find(
              (event) => event.state === 'closed',
            ) as ConversationStateEventDTO;
            window.dispatchEvent(
              new CustomEvent('chat.end', {
                detail: {
                  agentId,
                  closeReason: closedEvent.reason,
                  sessionId,
                  subType: isClinicalNursingContextMetaRoute(
                    (context.meta as ConversationMetaRoute).routeOptions,
                  )
                    ? 'hil'
                    : type,
                  taskId,
                  type,
                },
              }),
            );
          },
        ),
      ),
    { dispatch: false },
  );

  terminateConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TerminateConversation>(
          ConversationsActionTypes.TerminateConversation,
        ),
        withLatestFrom(
          this.conversationsFacade.conversation$,
          this.featuresFacade.featuresEnabled(['enableChatAws']),
        ),
        switchMap(([{ userAction, logoutUrl, ssoId }, convo, enableChatAws]) =>
          (convo?._id
            ? enableChatAws
              ? this.dataAccessService.terminateLambdaConversation(
                  convo._id,
                  ssoId || '',
                  userAction,
                  convo.adapter === 'bot' ? 'bot' : 'user',
                )
              : this.dataAccessService.terminateConversation(
                  convo._id,
                  userAction,
                )
            : of()
          ).pipe(
            timeout(3000),
            finalize(() => {
              if (logoutUrl) {
                this.logoutService.logout();
                this.windowService.location.href = logoutUrl;
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  getConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetConversation>(ConversationsActionTypes.GetConversation),
      withLatestFrom(
        this.conversationsFacade.conversationsEntities$,
        this.featuresFacade.featuresEnabled(['enableNewDMUI']),
      ),
      filter(
        ([{ conversationId, convType }, allConversations, enableNewDMUI]) =>
          !!conversationId,
      ),
      switchMap(
        ([{ conversationId, convType }, allConversations, enableNewDMUI]) => {
          const isLiveConvo =
            allConversations[conversationId] &&
            allConversations[conversationId]?.type !== 'bot';
          const _convType = convType
            ? convType
            : allConversations[conversationId]?.type;
          return (
            isLiveConvo
              ? this.dataAccessService.getLiveConversation(
                  conversationId,
                  enableNewDMUI,
                  _convType,
                )
              : this.dataAccessService.getBotConversation(conversationId)
          ).pipe(
            mergeMap((conversation: OutageCreateConversationResult) => {
              const hasOutageStarted = checkIfChatOutageStarted(
                conversation.events as OutageEvent[],
              );
              this.liveAvailbilityFacade.setChatOutage(hasOutageStarted);
              const chatOutageMsg = hasOutageStarted
                ? (conversation.events as OutageEvent[]).find(
                    (event: OutageEvent) => event.type === 'error',
                  )?.message ?? ''
                : '';
              this.liveAvailbilityFacade.setChatOutageMsg(chatOutageMsg);
              const ret: Action[] = [new GetConversationSuccess(conversation)];

              const oldConv = allConversations[conversationId];
              if (isConversation(oldConv)) {
                const newEvents = conversation.events.filter(
                  (e) => e.created > oldConv.updated,
                );
                if (newEvents.length) {
                  ret.push(
                    new ConversationDiff({
                      newEvents,
                      _id: conversationId,
                    }),
                  );
                }
              }
              return ret;
            }),
            catchError((error) => of(new GetConversationFailure(error))),
          );
        },
      ),
    ),
  );

  getActiveConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetActiveConversation>(
        ConversationsActionTypes.GetActiveConversation,
      ),
      withLatestFrom(
        this.featuresFacade.featuresEnabled(['enableChatAws']),
        this.featuresFacade.featuresEnabled(['isDcmChat']),
        this.featuresFacade.featuresEnabled(['enableNewDMUI']),
      ),
      filter(
        ([_data, enableChatAws, isDcmChat, enableNewDMUI]) =>
          enableChatAws && !isDcmChat,
      ),
      switchMap(([_data, enableChatAws, isDcmChat, enableNewDMUI]) =>
        this.dataAccessService.activeConversation().pipe(
          map((conversation) => {
            if (
              !Object.prototype.hasOwnProperty.call(
                conversation,
                'nextConversation',
              ) &&
              enableNewDMUI
            ) {
              return new GetActiveAsyncConversation([]);
            }
            return new GetActiveConversationSuccess(conversation);
          }),
          catchError((error) => of(new GetActiveConversationFailure(error))),
        ),
      ),
    ),
  );

  getActiveAsyncConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetActiveAsyncConversation>(
        ConversationsActionTypes.GetActiveAsyncConversation,
      ),
      switchMap(({ vampireHistory }) =>
        this.dataAccessService.activeAsyncConversation().pipe(
          mergeMap((conversations) => [
            new UpdateActiveAsyncConvCount(
              conversations.length,
              conversations.length === 0 && !!vampireHistory, // if active async count is 0 and first time GetActiveAsyncConversation is called
            ),
            new LoadConversationsSuccess([
              ...(vampireHistory ? vampireHistory : []),
              ...conversations,
            ]),
          ]),
          catchError((error) => of(new LoadConversationsFailure(error))),
        ),
      ),
    ),
  );

  getClosedAsyncConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateActiveAsyncConvCount>(
        ConversationsActionTypes.UpdateActiveAsyncConvCount,
      ),
      filter(
        ({ getClosedAsyncConvCount }) => getClosedAsyncConvCount as boolean,
      ),
      switchMap(() =>
        this.dataAccessService.directMessageConversationHistory().pipe(
          map(
            (conversations) =>
              new UpdateClosedAsyncConvCount(conversations.length),
          ),
          catchError((error) => of(new LoadConversationsFailure(error))),
        ),
      ),
    ),
  );

  getActiveConversationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetActiveConversationSuccess>(
        ConversationsActionTypes.GetActiveConversationSuccess,
      ),
      filter(({ response }) => !!response.nextConversation),
      switchMap(({ response }) => [
        new OpenConversation(true),
        new TransferConversationSuccess(response),
      ]),
    ),
  );

  getConversationTransferEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetConversationSuccess>(
        ConversationsActionTypes.GetConversationSuccess,
      ),
      withLatestFrom(
        this.liveAvailbilityFacade.liveChatAvailable$,
        this.featuresFacade.featuresEnabled(['liveClinicianChat']),
        this.featuresFacade.featuresEnabled(['asyncSupportChat']),
      ),
      filter(
        ([
          {
            payload: { events, context, type },
          },
          liveAvailable,
          liveCHEEnabled,
          asyncEnabled,
        ]) =>
          ((liveAvailable &&
            (!isBotCHEConversation(context, type) || liveCHEEnabled)) ||
            asyncEnabled) &&
          !!events.some(
            (e) =>
              isConversationTransferInitiationEvent(e) ||
              isConversationEndInitiationEvent(e),
          ),
      ),
      distinct(
        ([
          {
            payload: { events },
          },
        ]) =>
          events.filter(
            (e) =>
              isConversationTransferInitiationEvent(e) ||
              isConversationEndInitiationEvent(e),
          )[0]._id,
      ),
      map(
        ([
          {
            payload: { events, initial, context, type, _id },
          },
          liveAvailable,
          liveCHEEnabled,
          asyncEnabled,
        ]) => ({
          transferId: events.find((e) =>
            isConversationTransferInitiationEvent(e),
          )?._id,
          endId: events.find((e) => isConversationEndInitiationEvent(e))?._id,
          _id,
          liveAvailable,
          liveCHEEnabled,
          asyncEnabled,
          initial,
          context,
          type,
        }),
      ),
      map(
        ({
          initial,
          context,
          type,
          liveAvailable,
          liveCHEEnabled,
          _id,
          transferId,
        }) => {
          if (transferId) {
            return new TransferConversation({
              initial,
              conversationId: _id,
              type:
                liveAvailable &&
                (!isBotCHEConversation(context, type) || liveCHEEnabled)
                  ? 'live'
                  : 'async',
            });
          }
          return new EndConversation(_id);
        },
      ),
    ),
  );

  loadConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationsActionTypes.LoadConversations),
      switchMap(() =>
        this.dataAccessService.loadConversations().pipe(
          map((conversations) => new LoadConversationsSuccess(conversations)),
          catchError((error) => of(new LoadConversationsFailure(error))),
        ),
      ),
    ),
  );

  loadDMDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationsActionTypes.LoadConversationsSuccess),
      withLatestFrom(this.conversationsFacade.unreadOpenDmConversations$),
      switchMap(([_, dms]) => dms),
      withLatestFrom(this.featuresFacade.featuresEnabled(['enableNewDMUI'])),
      concatMap(([{ _id, type }, enableNewDMUI]) =>
        this.dataAccessService
          .getLiveConversation(_id, enableNewDMUI, type)
          .pipe(
            map((conversation) => new GetConversationSuccess(conversation)),
            catchError((error) => of(new GetConversationFailure(error))),
          ),
      ),
    ),
  );

  messageViewed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<MessageViewed>(ConversationsActionTypes.MessageViewed),
      filter(({ payload }) => !!payload && payload !== 'noop'),
      distinctUntilKeyChanged('payload'),
      withLatestFrom(
        this.conversationsFacade.conversationId$,
        this.conversationsFacade.conversationEntities$,
        this.featuresFacade.featuresEnabled(['enableNewDMUI']),
      ),
      filter(
        ([_payload, conversationId, _conversations, enableNewDMUI]) =>
          !!conversationId,
      ),
      switchMap(
        ([{ payload }, conversationId, conversations, enableNewDMUI]) => {
          const isLiveId =
            conversations[conversationId as string] &&
            conversations[conversationId as string]?.type !== 'bot';
          const convType = conversations[conversationId as string]?.type;
          return (
            isLiveId
              ? this.dataAccessService.liveMessageViewed(
                  conversationId as string,
                  payload,
                  enableNewDMUI,
                  convType,
                )
              : this.dataAccessService.botMessageViewed(
                  conversationId as string,
                  payload,
                )
          ).pipe(
            map((newMessages) => {
              const conversation =
                newMessages as unknown as ConversationDTOMetadata;
              const meta = conversation.metadata?.outcome;
              if (meta && meta.status >= 400) {
                return new CreateConversationFailure(meta);
              }
              return newMessages.length > 0
                ? new GetConversation(conversationId as string)
                : new MessageViewedSuccess({
                    conversationId: conversationId as string,
                    eventId: payload,
                  });
            }),
            catchError((error) => of(new MessageViewedFailure(error))),
          );
        },
      ),
    ),
  );

  onKeyUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OnKeyUp | PostMessageSuccess>(
        ConversationsActionTypes.OnKeyUp,
        ConversationsActionTypes.PostMessageSuccess,
      ),
      withLatestFrom(this.conversationsFacade.activityDebounceTime$),
      filter(([, activityDebounceTime]) => activityDebounceTime > 0),
      switchMap(([{ type }, activityDebounceTime]) => {
        switch (type) {
          case ConversationsActionTypes.PostMessageSuccess:
            return of('reset');
          case ConversationsActionTypes.OnKeyUp:
            return concat(
              of('responding'),
              of('listening').pipe(delay(activityDebounceTime)),
            );
        }
      }),
      distinctUntilChanged(),
      filter(
        (type): type is ConversationActivityEventDTO['activityType'] =>
          type !== 'reset',
      ),
      map((type) => new PostActivity(type)),
    ),
  );

  postActivity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PostActivity>(ConversationsActionTypes.PostActivity),
      withLatestFrom(this.conversationsFacade.conversation$),
      filter(
        (c): c is [PostActivity, ConversationSummaryDTO] =>
          !!c[1] && c[1].type === 'live' && c[1].state !== 'closed',
      ),
      switchMap(([{ activityType }, conversation]) =>
        this.dataAccessService
          .postActivity(conversation._id, activityType)
          .pipe(
            map((res) => new PostActivitySuccess(res)),
            catchError((e) => of(new PostActivityFailure(e))),
          ),
      ),
    ),
  );

  postCta$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PostCta>(ConversationsActionTypes.PostCta),
      withLatestFrom(this.conversationsFacade.conversationId$),
      throttle(() =>
        this.actions$.pipe(
          ofType<PostCtaSuccess | PostCtaFailure>(
            ConversationsActionTypes.PostCtaSuccess,
            ConversationsActionTypes.PostCtaFailure,
          ),
        ),
      ),
      filter((c): c is [PostCta, string] => !!c[1]),
      concatMap(
        ([
          {
            payload: { sourceMessageId, value, _id },
          },
          conversationId,
        ]) =>
          this.dataAccessService
            .postCta(conversationId, sourceMessageId, value, _id)
            .pipe(
              map(
                (messageEvent) =>
                  new PostCtaSuccess({ conversationId, messageEvent }),
              ),
              catchError((error) => of(new PostCtaFailure(error))),
            ),
      ),
    ),
  );

  postCtaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationsActionTypes.PostCtaSuccess),
      withLatestFrom(
        this.featuresFacade.featuresEnabled(['enableChatAws']),
        this.conversationsFacade.conversationEntities$,
      ),
      filter(
        ([{ payload }, feature, conversations]: [
          {
            payload: {
              conversationId: string;
              messageEvent: ConversationMessageEventDTO;
            };
          },
          boolean,
          Dictionary<ConversationDTO | ConversationSummaryDTO>,
        ]) =>
          !!(
            payload.conversationId &&
            (!conversations[payload.conversationId] ||
              conversations[payload.conversationId]?.state.toLowerCase() !==
                'closed') &&
            feature
          ),
      ),
      map(([res]) => new GetConversation(res.payload.conversationId)),
    ),
  );

  conversationHistory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetConversationHistory>(
        ConversationsActionTypes.GetConversationHistory,
      ),
      withLatestFrom(
        this.featuresFacade.featuresEnabled(['enableChatAws']),
        this.featuresFacade.featuresEnabled(['enableNewDMUI']),
      ),
      filter(
        ([_data, enableChatAws, enableNewDMUI]) =>
          enableChatAws || enableNewDMUI,
      ),
      tap(() => {
        this.conversationsFacade.loadHistoryInitiated();
      }),
      switchMap(([_data, enableChatAws, enableNewDMUI]) =>
        forkJoin({
          conversationHistory: this.dataAccessService.conversationHistory(),
          directMessageConversationHistory:
            !enableChatAws && enableNewDMUI
              ? this.dataAccessService.directMessageConversationHistory()
              : of([]),
        }).pipe(
          map(({ conversationHistory, directMessageConversationHistory }) => {
            // New DM UI for vampire users - Concatenate both Vampire Conversation history (without 'async' type conv) and Direct Message Conversation History
            const payload = [
              ...(!enableChatAws && enableNewDMUI
                ? conversationHistory.filter(
                    (conversation) => conversation.type === 'live',
                  )
                : conversationHistory),
              ...directMessageConversationHistory,
            ];
            return new LoadConversationsSuccess(payload);
          }),
          catchError((error) => of(new LoadConversationsFailure(error))),
        ),
      ),
    ),
  );

  postNewMessage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PostMessage>(ConversationsActionTypes.PostMessage),
      withLatestFrom(
        this.conversationsFacade.conversationId$ as Observable<string>,
        this.conversationsFacade.conversationEntities$,
        this.featuresFacade.featuresEnabled(['enableNewDMUI']),
      ),
      filter(
        ([{ payload }, conversationId, allConversations, enableNewDMUI]) =>
          !!payload && !!conversationId,
      ),
      tap(() => {
        this.refreshToken();
      }),
      concatMap(
        ([{ payload }, conversationId, allConversations, enableNewDMUI]) => {
          const convType = allConversations[conversationId as string]?.type;
          return this.dataAccessService
            .postMessage(conversationId, payload, enableNewDMUI, convType)
            .pipe(
              map((messageEvent) => {
                const conversation =
                  messageEvent as unknown as ConversationDTOMetadata;
                const meta = conversation.metadata?.outcome;
                if (meta && meta.status >= 400) {
                  return new CreateConversationFailure(messageEvent);
                }
                return new PostMessageSuccess({ conversationId, messageEvent });
              }),
              catchError((error) => of(new PostMessageFailure(error))),
            );
        },
      ),
    ),
  );

  agentSentMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConversationDiff>(ConversationsActionTypes.ConversationDiff),
        filter(
          ({ payload: { newEvents } }) =>
            !!newEvents.filter(
              (e: ConversationMessageEventDTO) => e.type === 'message',
            ).length,
        ),
        withLatestFrom(this.conversationsFacade.conversationsEntities$),
        map(([{ payload }, convs]) => convs[payload._id]),
        filter(
          (conv: ConversationDTO) =>
            isConversation(conv) &&
            conv.type !== 'bot' &&
            conv.lastMessageBy?.role !== 'customer',
        ),
        tap(() => {
          window.dispatchEvent(new Event('agentinteraction'));
        }),
      ),
    { dispatch: false },
  );

  // @Effect()
  // initiateTransferCall$: Observable<Action> = this.actions$.pipe(
  //   ofType<PostMessage>(ConversationsActionTypes.PostMessage),
  //   withLatestFrom(
  //     this.conversationsFacade.conversationId$,
  //     this.conversationsFacade.allConversations$,
  //   ),
  //   filter(
  //     (
  //       c,
  //     ): c is [
  //       PostMessage,
  //       string,
  //       Array<ConversationDTO | ConversationSummaryDTO>,
  //     ] =>
  //       !!c[0].payload &&
  //       !!c[1] &&
  //       !(c[2].find(
  //         (convo) => convo._id === c[1],
  //       ) as ConversationDTO)?.events.some(
  //         (event) => (event as ConversationEventWithState).state === 'transfer',
  //       ),
  //   ),
  //   map(
  //     ([{ payload }, conversationId]) =>
  //       new TransferConversation({
  //         conversationId,
  //         type: 'live',
  //         initial: payload,
  //       }),
  //   ),
  // );

  transferConversation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<TransferConversation>(
        ConversationsActionTypes.TransferConversation,
      ),
      switchMap(({ payload: { conversationId, type, initial } }) =>
        this.dataAccessService
          .transferConversation(conversationId, type, initial)
          .pipe(
            map((result: OutageResultConversationTransfer) => {
              if (result.metadata?.outcome.status === 501) {
                const message =
                  result.metadata.outcome.additionalDetails[0].message;
                this.liveAvailbilityFacade.setChatOutageMsg(message);
                this.liveAvailbilityFacade.setChatOutage(true);
                return new TransferConversationFailure(result);
              }
              return new TransferConversationSuccess(result);
            }),
            catchError((error) => {
              if (error.metadata?.outcome.status === 501) {
                const message =
                  error.metadata?.outcome.additionalDetails[0].message;
                this.liveAvailbilityFacade.setChatOutageMsg(message);
                this.liveAvailbilityFacade.setChatOutage(true);
              }
              return of(new TransferConversationFailure(error));
            }),
          ),
      ),
    ),
  );

  constructor(
    private windowService: WindowService,
    public actions$: Actions,
    public conversationsFacade: OmniConversationsFacade,
    public dataAccessService: ConversationsDataAccessService,
    public liveAvailbilityFacade: OmniLiveAvailabilityFacade,
    public featuresFacade: FeatureTogglesFacade,
    private authFacade: AuthFacade,
    private logoutService: LogoutService,
  ) {}

  refreshToken() {
    combineLatest(
      this.featuresFacade.featuresEnabled(['isDcmChat']),
      this.conversationsFacade.conversation$,
    )
      .pipe(take(1))
      .subscribe(([isDcmChat, conversation]) => {
        if (!isDcmChat && conversation?.type !== 'async') {
          combineLatest(
            this.authFacade.authData$,
            this.conversationsFacade.tokenRefreshThresholdTime$,
          )
            .pipe(take(1))
            .subscribe(([authData, tokenRefreshThresholdTime]) => {
              const threshold = tokenRefreshThresholdTime * 60;
              const remainingTime =
                authData.accessTokenBody.exp - new Date().getTime() / 1000;
              if (remainingTime < threshold) {
                this.authFacade.authenticate();
              }
            });
        }
      });
  }
}
