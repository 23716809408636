import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
import { FeaturesModule } from '@cigna/shared/angular/features-feature';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { UiButtonModule } from '@cigna/shared/angular/button-ui';
import { UiPopoverModule } from '@cigna/shared/angular/popover-ui';
import { ChatLinkComponent } from './chat-link-feature/chat-link.component';
import { EffectsModule } from '@ngrx/effects';
import { ChatLinkEffects } from './+state/chat-link.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ChatLinkEffects]),
    FeaturesModule,
    UiButtonModule,
    UiIconModule,
    UiPopoverModule,
  ],
  declarations: [ChatLinkComponent],
  exports: [ChatLinkComponent],
})
export class OmniChatLinkModule {}
