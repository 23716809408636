import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map, retry } from 'rxjs/operators';

import { checkValidResponse, logError } from '@cigna/chcp/shared/util';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public CONSUMER_CODE = '1000';

  public navApi: string;

  constructor(
    private http: HttpClient,
    @Inject('secureApi') secureApi: string,
  ) {
    this.navApi = `${secureApi}/navigation/v1`;
  }

  getAppNav() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    return this.http
      .get(`${this.navApi}/app`, {
        params,
      })
      .pipe(
        retry(1),
        map(checkValidResponse),
        map((res) => res.app),
        catchError(logError),
      );
  }

  getHeaderNav() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    return this.http
      .get(`${this.navApi}/header`, {
        params,
      })
      .pipe(
        retry(1),
        map(checkValidResponse),
        map((res) => res.header),
        catchError(logError),
      );
  }

  logOut() {
    return this.http.get('/pkmslogout').pipe(catchError(logError));
  }

  loginStepUp() {
    return this.http
      .get('/route/secure/loginstepup')
      .pipe(catchError(logError));
  }
}
