import { NgZone } from '@angular/core';
import {
  ConversationDTO,
  ConversationSummaryDTO,
  ConversationParticipantDTO,
  ConversationStateEventDTO,
  ConversationEvent,
  ConversationState,
  ConversationMeta,
  ConversationContext,
} from '@cigna/vampire-dto';
import { Observable, Observer, of, range, throwError, timer, zip } from 'rxjs';
import { catchError, mergeMap, retryWhen, timeout } from 'rxjs/operators';
import {
  AvailabilityTypes,
  ClinicalNursingContextTags,
  ClinicalNursingContextMetaRoute,
  ScheduleCoachingContextTags,
  SOContextTags,
  SupportAvailability,
  TransferredConversation,
  ChatBubbleContentId,
  LegacyCoachingContextTags,
  BehavioralContextTags,
  MedicalContextTags,
  SOOffhoursContextTags,
} from '../models';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

export interface ConversationDTOStatus extends ConversationDTO {
  status?: string;
  convId?: string;
}
export interface ConversationDTOAgentID extends ConversationSummaryDTO {
  agentData?: ConversationAgentData;
}

export interface ConversationAgentData {
  firstName?: string;
  id?: string;
}

export interface ConversationMetaRoute extends ConversationMeta {
  routeOptions: string[];
}

export interface ConversationContextMeta extends ConversationContext {
  meta: ConversationMetaRoute;
}

export interface ConversationDTOMetaRoute extends ConversationDTO {
  context: ConversationContextMeta;
}

export function observeOnZone(zone: NgZone) {
  return <T>(observable$: Observable<T>) =>
    new Observable((observer: Observer<T>) =>
      observable$.subscribe(
        (value) => zone.run(() => observer.next(value)),
        (error) => zone.run(() => observer.error(error)),
        () => zone.run(() => observer.complete()),
      ),
    );
}

type ConversationExtendState = ConversationState & 'bot-end';

export interface DataIntegrityCheck {
  fallback?: any;
  fallbackAction?: any;
  errorPassthrough?: boolean;
  retry?: boolean;
  opts?: {
    attempts?: number;
    strategy?: 'linear' | 'exponential';
    timeout?: number;
  };
}

export const dataIntegrityCheck =
  ({
    // eslint-disable-next-line no-console
    fallback = console.warn,
    fallbackAction = null,
    errorPassthrough = true,
    retry = false,
    opts = {
      attempts: 9,
      strategy: 'exponential',
      timeout: 15000,
    },
  }: DataIntegrityCheck) =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (stream$: any): Observable<any> =>
    stream$.pipe(
      retryWhen((errors$) =>
        zip(
          errors$.pipe(timeout(opts.timeout || 15000)),
          range(1, (opts.attempts || 9) + 1),
        ).pipe(
          mergeMap(([error, i]) => {
            if (!retry || (opts.attempts || 9) < i) {
              return throwError(error);
            }

            switch (opts.strategy) {
              case 'linear':
                return timer(i * 1000);

              case 'exponential':
              default:
                return timer(Math.pow(Math.E, i));
            }
          }),
        ),
      ),
      catchError((err) => {
        if (fallbackAction) {
          return of(
            errorPassthrough ? new fallbackAction(err) : new fallbackAction(),
          );
        }

        if (typeof fallback === 'function') {
          return of(errorPassthrough ? fallback(err) : fallback());
        }

        return of(fallback);
      }),
    );

export const isConversation = (
  conversation:
    | ConversationDTO
    | ConversationSummaryDTO
    | ConversationDTOStatus
    | undefined,
): conversation is ConversationDTOStatus =>
  // eslint-disable-next-line no-prototype-builtins
  !!conversation && conversation.hasOwnProperty('events');

export const isTransferredConversation = (
  conversation: ConversationDTO | ConversationSummaryDTO,
): conversation is TransferredConversation =>
  isConversation(conversation) &&
  !!conversation.transferIds &&
  !!conversation.transferIds.length;

export const isConversationStateEvent = (
  event: ConversationEvent,
): event is ConversationStateEventDTO => event.type === 'state';

export const isConversationTransferInitiationEvent = (
  event: ConversationEvent,
): boolean => isConversationStateEvent(event) && event.state === 'transfer';

export const isConversationEndInitiationEvent = (
  event: ConversationEvent,
): boolean =>
  isConversationStateEvent(event) &&
  (event.state as ConversationExtendState) === 'bot-end';

export const isConversationTransferManualInitiationEvent = (
  event: ConversationEvent,
): boolean =>
  isConversationStateEvent(event) &&
  (event.state as string) === 'transfer-manual';

/**
 *  Context Only Conversation Identifies
 */
export const genericContextMatcher =
  <T extends ConversationDTO['context']['tags']>(pattern: T) =>
  (test: string[]) =>
    isArrayEqual(pattern, test);

export const genericContextMetaMatcher =
  <T extends ConversationDTOMetaRoute['context']['meta']['routeOptions']>(
    pattern: T,
  ) =>
  (test: string[]) =>
    isArrayEqual(pattern, test);

function isArrayEqual(pattern: string[], test: string[]) {
  return (
    (Array.isArray(test) &&
      test.length &&
      isEqual(sortBy(pattern), sortBy(test))) ||
    false
  );
}

export const isClinicalNursingContextTags =
  genericContextMatcher<ClinicalNursingContextTags>(['clinical', 'hil']);

export const isClinicalNursingContextMetaRoute =
  genericContextMetaMatcher<ClinicalNursingContextMetaRoute>(['clinical']);

export const isBehavioralContextTags =
  genericContextMatcher<BehavioralContextTags>(['behavioral']);

export const isMedicalContextTags = genericContextMatcher<MedicalContextTags>([
  'medical',
]);

export const isDMContextTags = (arg: string[]) =>
  isMedicalContextTags(arg) || isBehavioralContextTags(arg);

export const isScheduleCoachingContextTags =
  genericContextMatcher<ScheduleCoachingContextTags>([
    'scheduling',
    'coaching',
  ]);

export const isLegacyCoachingContextTags =
  genericContextMatcher<LegacyCoachingContextTags>(['coaching']);

export const isSupportOpsContextTags = genericContextMatcher<SOContextTags>([
  'support',
]);
export const isSupportOffhoursContextTags =
  genericContextMatcher<SOOffhoursContextTags>(['offhours', 'support']);

/**
 *  Context + Channel Type Conversation Identifies
 */
export const isLiveSOConversation = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): boolean =>
  type === 'live' &&
  (isSupportOpsContextTags(context.tags) ||
    isSupportOffhoursContextTags(context.tags));

export const isLiveCHEConversation = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): boolean => type === 'live' && isClinicalNursingContextTags(context.tags);

export const isLiveScheduleConversation = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): boolean => type === 'live' && isScheduleCoachingContextTags(context.tags);

export const isAsyncSOConversation = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): boolean => type === 'async' && isSupportOpsContextTags(context.tags);

export const isAsyncCHEConversation = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): boolean => type === 'async' && isClinicalNursingContextTags(context.tags);

export const isAsyncScheduleConversation = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): boolean => type === 'async' && isScheduleCoachingContextTags(context.tags);

export const isBotConversation = (type: ConversationDTO['type']): boolean =>
  type === 'bot';

export const isBotCHEConversation = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): boolean =>
  isBotConversation(type) && isClinicalNursingContextTags(context.tags);

export const isDmConversation = (
  context: ConversationDTO['context'],
  type: ConversationDTO['type'],
): boolean => type === 'async' && isDMContextTags(context.tags);

export const isLiveInitialMessage = (
  conversation: ConversationDTO | ConversationSummaryDTO | null,
): boolean =>
  !!(
    conversation &&
    conversation.type === 'live' &&
    (conversation.state === 'opening' ||
      (conversation.state === 'opened' &&
        (conversation as ConversationDTOStatus).status === 'AWAITING_AGENT') ||
      conversation.state === 'creating')
  );

export const isCtaVisible = (c: any): boolean =>
  c && c.messages && c.messages.length
    ? !!c.messages.find((m: any) => m.meta && m.meta.type === 'cta_list')
    : false;

export const isActiveConversation = (
  state: ConversationDTO['state'],
): boolean => !['creating', 'created', 'closed'].includes(state);

export const isLiveActiveConversation = (
  state: ConversationDTO['state'],
  type: ConversationDTO['type'],
): boolean => type === 'live' && isActiveConversation(state);

export const isAgentTimedOutConversation = (
  events: ConversationDTO['events'],
): boolean =>
  (events || []).some(
    (event: ConversationEvent) =>
      // eslint-disable-next-line no-prototype-builtins
      event.hasOwnProperty('reason') &&
      (event as ConversationStateEventDTO).reason === 'AGENT_TIMEOUT',
  );

export const getChatBubbleId = (
  liveChatAvailable: boolean,
  asyncEnabled: boolean,
  isEvernorthEnabled: boolean,
  isTerminatedUser: boolean,
): ChatBubbleContentId =>
  isTerminatedUser
    ? 'terminatedUser'
    : liveChatAvailable
    ? isEvernorthEnabled
      ? 'evernorthChatOpen'
      : 'chatOpen'
    : asyncEnabled
    ? 'leaveMessage'
    : 'chatClosed';

export const getConversationType = (
  context: ConversationDTO['context'],
): string => {
  if (isDMContextTags(context.tags)) {
    return 'DM';
  }

  if (isSupportOpsContextTags(context.tags)) {
    return 'Support';
  }

  if (isClinicalNursingContextTags(context.tags)) {
    return 'Clinical';
  }

  if (
    isScheduleCoachingContextTags(context.tags) ||
    isLegacyCoachingContextTags(context.tags)
  ) {
    return 'Coaching';
  }
  return '';
};

export const getSupportType: (
  availability: SupportAvailability,
) => AvailabilityTypes = ({
  isLiveChatAvailable,
  isAsyncSupportEnabled,
  isPersonalGuide,
}) => {
  switch (true) {
    case isLiveChatAvailable && isPersonalGuide:
      return 'isLivePersonalGuide';

    case isAsyncSupportEnabled && isPersonalGuide:
      return 'isAsyncPersonalGuide';

    case isLiveChatAvailable && !isPersonalGuide:
      return 'isLive';

    case !isLiveChatAvailable && !isAsyncSupportEnabled:
    default:
      return 'nonLiveAsync';
  }
};

export const getParticipant = (
  participantId: string,
  participants: ConversationParticipantDTO[],
) => participants.find((participant) => participant._id === participantId);

export const getParticipantFirstName = (
  participantId: string,
  participants: ConversationParticipantDTO[],
  defaultString = '',
): string => {
  const participant = getParticipant(participantId, participants);
  if (!participant) {
    return defaultString;
  }

  // if userName is 'One Guide Personal Guide' we return the whole name.
  // this is the agent name for all async support conversations.
  return participant.name === 'One Guide Personal Guide'
    ? participant.name
    : participant.name?.split(' ')[0];
};

export const throwEventTypeError = (type: any): void => {
  throw new Error(`[ dispatch ] :: Event Type Unknown: ${type}`);
};

/**
 * Immutable Splice: mimic Array.prototype.splice
 *
 * Does not:
 * - mutate the Array
 * - return deleted elements
 *
 * @export
 * @template T
 * @param {number} start
 * @param {number} deleteCount
 * @param {...T[]} items
 * @returns {(arr: T[]) => T[]}
 */
export function iSplice<T>(
  start: number,
  deleteCount: number,
  ...items: T[]
): (arr: T[]) => T[] {
  return (arr) => [
    ...arr.slice(0, start),
    ...items,
    ...arr.slice(start + deleteCount),
  ];
}

export const utils = {
  dataIntegrityCheck,
  genericContextMatcher,
  getParticipantFirstName,
  isActiveConversation,
  isAgentTimedOutConversation,
  isAsyncCHEConversation,
  isAsyncScheduleConversation,
  isAsyncSOConversation,
  isBehavioralContextTags,
  isBotConversation,
  isClinicalNursingContextTags,
  isClinicalNursingContextMetaRoute,
  isConversation,
  isCtaVisible,
  isDMContextTags,
  isLiveActiveConversation,
  isLiveCHEConversation,
  isLiveInitialMessage,
  isLiveScheduleConversation,
  isLiveSOConversation,
  isMedicalContextTags,
  iSplice,
  isScheduleCoachingContextTags,
  isSupportOpsContextTags,
  throwEventTypeError,
  getConversationType,
  isConversationTransferInitiationEvent,
  isConversationEndInitiationEvent,
};
