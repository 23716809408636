import { chatQuery } from '@cigna/omni/chat-state-data-access';
import { conversationsQuery } from '@cigna/omni/conversations-state-data-access';
import { isClinicalNursingContextTags } from '@cigna/omni/shared-util';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PollingState, POLLING_FEATURE_KEY } from './polling.reducer';

export const getPollingState =
  createFeatureSelector<PollingState>(POLLING_FEATURE_KEY);

export const isRealTimeAndActive = createSelector(
  conversationsQuery.selectConversation,
  (conversation): boolean =>
    !!conversation &&
    conversation.state !== 'closed' &&
    (conversation.type === 'bot' ||
      conversation.type === 'live' ||
      (conversation.type === 'async' &&
        isClinicalNursingContextTags(conversation.context.tags))),
);

export const isVisibleAndActive = createSelector(
  conversationsQuery.selectConversation,
  chatQuery.getChatState,
  chatQuery.getCurrentComponent,
  (conversation, chatState, currentComponent): boolean =>
    !!conversation &&
    conversation.state !== 'closed' &&
    chatState === 'open' &&
    currentComponent === 'dialog',
);

export const isPollPaused = createSelector(
  getPollingState,
  (state) => state.pauseEvents,
);

export const getPollingRegime = createSelector(
  getPollingState,
  isRealTimeAndActive,
  isVisibleAndActive,
  conversationsQuery.getOpenLiveConvExists,
  (
    pollingConfig,
    isRealTime,
    isVisible,
    openLive,
  ): { attempts: number | null; rate: number; keepalive: boolean } => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      limitPolling,
      pollingAttempts,
      rate: { fast, slow },
    } = pollingConfig;

    return {
      attempts:
        isVisible || isRealTime || !limitPolling ? null : pollingAttempts,
      rate: isVisible && isRealTime ? fast : slow,
      keepalive: openLive,
    };
  },
);

export const fromPolling = {
  getPollingRegime,
  isRealTimeAndActive,
  isVisibleAndActive,
  isPollPaused,
};
