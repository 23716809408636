import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiIconModule } from '@cigna/shared/angular/icon-ui';

import { AlertComponent } from './alert.component';
import { UiButtonModule } from '@cigna/shared/angular/button-ui';

@NgModule({
  imports: [CommonModule, UiIconModule, UiButtonModule],
  declarations: [AlertComponent],
  exports: [AlertComponent],
})
export class UiAlertModule {}
