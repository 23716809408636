import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './custom-router-state-serializer';

@NgModule({
  imports: [CommonModule, StoreRouterConnectingModule.forRoot()],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
})
export class CoreRouterStateModule {}
