import {
  Pipe,
  PipeTransform,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { FeatureTogglesFacade } from './+state/feature-toggles.facade';
import { QueryOperator } from './+state/feature-toggles.selectors';

@Pipe({
  name: 'cignaFeatureEnabled',
  pure: false,
})
export class FeatureEnabledPipe implements PipeTransform, OnDestroy {
  private _sub?: Subscription;
  private _current: {
    toggles?: string | string[];
    operator?: QueryOperator;
    value: boolean;
  } = { value: false };

  constructor(
    private _facade: FeatureTogglesFacade,
    private _ref: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this.clearSub();
  }

  transform(toggles: string | string[], operator?: QueryOperator): boolean {
    if (
      this._current.toggles === toggles &&
      this._current.operator === operator
    ) {
      return this._current.value;
    }

    this._current = { toggles, operator, value: false };

    this.clearSub();
    this._sub = this._facade
      .featuresEnabled(Array.isArray(toggles) ? toggles : [toggles], operator)
      .subscribe((v) => {
        this._current.value = v;
        this._ref.markForCheck();
      });

    return this._current.value;
  }

  private clearSub() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }
}
