import {
  Directive,
  HostListener,
  AfterViewInit,
  ElementRef,
  Input,
} from '@angular/core';

@Directive({
  selector: '[cignaRightLeft]',
})
export class RightLeftDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  @Input() itemSelector?: string;

  tabs: HTMLElement[] = [];

  getArrayOfTabs(el: ElementRef): HTMLElement[] {
    const nativeElement: HTMLElement = el.nativeElement;

    return this.itemSelector
      ? Array.from(nativeElement.querySelectorAll(this.itemSelector))
      : (Array.from(nativeElement.children) as HTMLElement[]);
  }

  ngAfterViewInit(): void {
    this.tabs = this.getArrayOfTabs(this.elementRef);
    this.resetTabIndex();
  }

  focusOnFirstTab() {
    const tab = this.tabs[0];

    if (tab) {
      tab.focus();
      tab.setAttribute('tabIndex', '0');
    }
  }

  focusOnLastTab() {
    const tab = this.tabs[this.tabs.length - 1];

    if (tab) {
      tab.focus();
      tab.setAttribute('tabIndex', '0');
    }
  }

  detachFromTabOrder() {
    this.tabs.forEach((tab) => {
      tab.setAttribute('tabIndex', '-1');
    });
  }

  resetTabIndex() {
    this.detachFromTabOrder();
    this.tabs[0]?.setAttribute('tabIndex', '0');
  }

  // Focus on the next tab, wrapping to the first tab
  switchToNextTab(currentIndex: number) {
    const nextIndex = currentIndex + 1;
    this.detachFromTabOrder();
    if (this.tabs[nextIndex]) {
      this.tabs[nextIndex].focus();
      this.tabs[nextIndex].setAttribute('tabIndex', '0');
    } else {
      this.focusOnFirstTab();
    }
  }

  // Focus on the previous tab, wrapping to the last tab
  switchToPreviousTab(currentIndex: number) {
    const nextIndex = currentIndex - 1;
    this.detachFromTabOrder();
    if (this.tabs[nextIndex]) {
      this.tabs[nextIndex].focus();
      this.tabs[nextIndex].setAttribute('tabIndex', '0');
    } else {
      this.focusOnLastTab();
    }
  }

  // Handle keydown on tabs
  keydownEventListener(event: KeyboardEvent) {
    const currentIndex = this.tabs.findIndex((tab) => tab === event.target);

    if (currentIndex === -1) {
      return;
    }

    const key: string = event.key;
    switch (key) {
      case 'ArrowLeft':
      case 'Left':
        event.preventDefault();
        this.switchToPreviousTab(currentIndex);
        break;
      case 'ArrowRight':
      case 'Right':
        event.preventDefault();
        this.switchToNextTab(currentIndex);
        break;
    }
  }

  @HostListener('keydown', ['$event']) onkeydown(event: KeyboardEvent) {
    this.keydownEventListener(event);
  }
}
