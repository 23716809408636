import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OmnibusInitializationEffects } from './+state/omnibus-initialization.effects';
import { OmnibusInitializationFacade } from './+state/omnibus-initialization.facade';
import { OmniLiveAvailabilityStateModule } from '@cigna/omni/live-availability-state-data-access';
import { OmniConversationsStateModule } from '@cigna/omni/conversations-state-data-access';

@NgModule({
  imports: [
    StoreModule,
    EffectsModule.forFeature([OmnibusInitializationEffects]),
    OmniLiveAvailabilityStateModule,
    OmniConversationsStateModule,
  ],
  providers: [OmnibusInitializationEffects, OmnibusInitializationFacade],
})
export class OmnibusInitializationModule {}
