import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  tap,
  filter,
  withLatestFrom,
  combineLatest,
} from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TerminateConversation } from '@cigna/omni/conversations-state-data-access';

import {
  siteNavLoad,
  siteNavLoadSuccess,
  siteNavLoadError,
  headerNavLoad,
  headerNavLoadSuccess,
  headerNavLoadError,
  secureAlertLoad,
  secureAlertLoadSuccess,
  publicAlertLoad,
  publicAlertLoadSuccess,
  logout,
  loginStepup,
  loginStepupSuccess,
  loginStepupError,
} from './shell.actions';

import { NavigationService } from '../navigation/navigation.service';
import { AlertsService } from '../alerts/alerts.service';

import { Router, NavigationEnd } from '@angular/router';
import { RouterFacade } from '@cigna/shared/angular/core/router-state-data-access';
import { ShellTrackHandlers } from '@cigna/chcp/shared/analytics-util';

import { AppContext, NavigationItem, RouteData } from '@cigna/chcp/shared/util';
import { MatDialog } from '@angular/material/dialog';
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import { WindowService } from '@cigna/shared/angular/core/window-util';
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';
import { CookieService } from 'ngx-cookie-service';
import { addDays } from 'date-fns';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Injectable()
export class ShellEffects {
  getAppNav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(siteNavLoad),
      switchMap(() =>
        this.navigationService.getAppNav().pipe(
          map((data: NavigationItem[]) =>
            siteNavLoadSuccess({ navItems: data }),
          ),
          catchError((err) => of(siteNavLoadError({ error: err }))),
        ),
      ),
    ),
  );

  getHeaderNav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerNavLoad),
      switchMap(() =>
        this.navigationService.getHeaderNav().pipe(
          map((data: NavigationItem[]) =>
            headerNavLoadSuccess({
              navItems: data,
            }),
          ),
          catchError((err) => of(headerNavLoadError({ error: err }))),
        ),
      ),
    ),
  );

  getAlertsSecure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(secureAlertLoad),
      combineLatest(this.authFacade.lob$),
      switchMap(([, lob]) =>
        this.alertsService.getSecureAlertsAws(lob).pipe(
          map((data: any) => secureAlertLoadSuccess({ alerts: data })),
          catchError(() => of(secureAlertLoadSuccess({ alerts: [] }))),
        ),
      ),
    ),
  );

  getAlertsPublic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publicAlertLoad),
      switchMap(() =>
        this.alertsService.getPublicAlerts().pipe(
          map((data: any) => publicAlertLoadSuccess({ alerts: data })),
          catchError(() => of(publicAlertLoadSuccess({ alerts: [] }))),
        ),
      ),
    ),
  );

  routeChange$ = createEffect(
    () =>
      this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        withLatestFrom(
          this.routerFacade.data$ as Observable<RouteData>,
          this.routerFacade.url$,
        ),
        map(([, data, url]) => {
          const titlePrefix = this.appContext.toUpperCase();
          const title =
            data && data.title ? `${titlePrefix} - ${data.title}` : '';

          if (data && data.analytics && data.analytics.async !== true) {
            this._analytics.trackPageLoad(data, url);
          }
          if (title && title !== `${titlePrefix} - Login`) {
            this.titleService.setTitle(title);
          } else {
            // use a more SEO friendly title for login page
            this.titleService.setTitle(
              this.appContext === 'evernorth'
                ? 'Evernorth for Health Care Professionals'
                : 'Cigna for Health Care Professionals',
            );
          }
        }),
      ),
    { dispatch: false },
  );

  processLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Auth] Load Auth Success'),
        withLatestFrom(
          this.authFacade.userid$,
          this.authFacade.useridEncrypted$,
          this.authFacade.lob$,
          this.routerFacade.data$ as Observable<RouteData>,
        ),
        tap(([, userid, useridEncrypted, lob, routeData]) => {
          this._analytics.updateStore({
            user: {
              profile: {
                ssoId: useridEncrypted,
                ssoIdPlainText: userid,
                lobType: lob,
              },
            },
          });
          if (routeData.routeCode !== 'resources') {
            this._analytics.trackLoginSuccess();
            this.cookieService.set('is-chcp-user', 'true', {
              secure: true,
              domain: 'cigna.com',
              expires: addDays(new Date(), 365),
            }); // requested by cigna.com team
          }
        }),
      ),
    { dispatch: false },
  );

  loginStepup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginStepup),
      switchMap(() =>
        this.navigationService.loginStepUp().pipe(
          map((data: any) => loginStepupSuccess({ location: data.location })),
          catchError(() => of(loginStepupError())),
        ),
      ),
    ),
  );

  loginStepupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginStepupSuccess),
        tap(({ location }) => {
          if (location.includes('/app')) {
            // baseHref is /app so we need to trim off /app from location or it will go to /app/app/dasbhoard
            this.router.navigateByUrl(location.replace('/app', ''), {
              replaceUrl: true,
            });
          } else {
            this.windowService.location.href = location;
          }
        }),

        catchError((): any => {
          // todo: figure out what to do for error, should we redirect to login page maybe?
        }),
      ),
    { dispatch: false },
  );

  processTerminateConvo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      withLatestFrom(this.authFacade.userid$),
      map(
        ([{ variation }, ssoId]) =>
          new TerminateConversation(variation, '', ssoId || ''),
      ),
    ),
  );

  processLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        map(({ variation, linkName }) => {
          this._analytics.trackLogoutSuccess({ variation, linkName });
          this.windowService.location.href = '/pkmslogout';
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private navigationService: NavigationService,
    private alertsService: AlertsService,
    private routerFacade: RouterFacade,
    private _analytics: ShellTrackHandlers,
    private titleService: Title,
    private dialogRef: MatDialog,
    private authFacade: AuthFacade,
    private windowService: WindowService,
    private cookieService: CookieService,
    private featureToggleFacade: FeatureTogglesFacade,
    @Inject(APP_CONTEXT) private appContext: AppContext,
  ) {}
}
