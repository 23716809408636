import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlDecode',
})
export class HtmlDecodePipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  }
}
