<div class="cg-dialog">
  <h2 class="cg-dialog__header">
    {{ data.title
    }}<button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      (click)="dialogRef.close()"
    >
      Close
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h2>

  <mat-dialog-content>
    <p>{{ data.errorMessage }}</p>
  </mat-dialog-content>
</div>
