import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OidcResult, AuthError } from '@cigna/shared/angular/auth/oidc-util';
import { AUTH_FEATURE_KEY, AuthState, AuthPartialState } from './auth.reducer';

export const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const isAuthLoading = createSelector(
  getAuthState,
  (state: AuthState) => state.isLoading,
);

export const getAuthData = createSelector(
  getAuthState,
  (state: AuthState): OidcResult | undefined => state.data,
);

export const getAuthError = createSelector(
  getAuthState,
  (state: AuthState): AuthError | undefined => state.error,
);
