<cigna-omni-comm-channel-header
  omniQaTag="omni-comm-channel-my-conversations-header"
>
  My Conversations
</cigna-omni-comm-channel-header>
<cigna-omni-comm-channel-content
  *ngIf="(summaryListItem$ | async) || (hasNoHistory$ | async)"
>
  <cigna-omni-conversation-summary-card
    class="my-conversations-card"
    [body]="item.body"
    [chevronColor]="item.chevronColor"
    [conversationId]="item.conversationId"
    [date]="item.date"
    [icon]="item.icon"
    [iconColors]="item.iconColors"
    [iconData]="item.iconData"
    [isClosed]="item.isClosed"
    [hasNewMessages]="item.newMessages"
    [subject]="item.subject"
    [context]="item.context"
    *ngIf="summaryListItem$ | async as item; else noConversations"
    data-omni-qa="omni-comm-channel-my-conversations-summary-card"
  ></cigna-omni-conversation-summary-card>

  <ng-template #noConversations>
    <ng-container *ngIf="hasNoHistory$ | async">
      <p
        data-omni-qa="omni-comm-channel-my-conversations-no-conversations-line-1"
      >
        You currently have no previous conversations.
      </p>
      <p
        data-omni-qa="omni-comm-channel-my-conversations-no-conversations-line-2"
      >
        Start a conversation today.
      </p>
    </ng-container>
  </ng-template>
</cigna-omni-comm-channel-content>
<cigna-omni-comm-channel-content-link
  (interaction)="openHistoryPage()"
  [disabled]="hasNoHistory$ | async"
  copy="View All"
  omniQaTag="omni-comm-channel-my-conversations-content-link"
></cigna-omni-comm-channel-content-link>
