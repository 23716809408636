import { Injectable } from '@angular/core';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { ChatPreferencesDataAccessService } from '@cigna/omni/data-access';
import {
  GetLiveAvailabilitySuccess,
  LiveAvailabilityActionTypes,
} from '@cigna/omni/live-availability-state-data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { RightNowService } from '../right-now.service';
import {
  fromRightNowActions,
  GetChatPreferences,
  GetChatPreferencesFailure,
  GetChatPreferencesSuccess,
  InitializeWidget,
  InitializeWidgetFailure,
  InitializeWidgetSuccess,
  LoadClientScript,
  LoadClientScriptFailure,
  LoadClientScriptSuccess,
  RightNowAction,
  RightNowActionTypes,
  StartChat,
  StartChatFailure,
  StartChatSuccess,
} from './right-now.actions';

@Injectable()
export class RightNowEffects {
  fetchRightNowPrereqs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetLiveAvailabilitySuccess>(
        LiveAvailabilityActionTypes.GetLiveAvailabilitySuccess,
      ),
      take(1),
      withLatestFrom(
        this.featureTogglesFacade.featuresEnabled(['useRightNowChat']),
      ),
      filter(([_, useRightNowChat]) => useRightNowChat),
      // TODO: remove the references of GetChatPreferences and LoadClientScript
      map(() => new InitializeWidgetSuccess()),
    ),
  );

  getChatPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetChatPreferences>(RightNowActionTypes.GetChatPreferences),
      switchMap(() =>
        this.preferencesService.getChatPreferences().pipe(
          map((preferences) => new GetChatPreferencesSuccess(preferences)),
          catchError((error) => of(new GetChatPreferencesFailure(error))),
        ),
      ),
    ),
  );

  loadClientScript$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadClientScript>(RightNowActionTypes.LoadClientScript),
      switchMap(() =>
        this.rightNow.loadClientScript().pipe(
          map(() => new LoadClientScriptSuccess()),
          catchError((error) => of(new LoadClientScriptFailure(error))),
        ),
      ),
    ),
  );

  prereqsComplete$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(
        ofType<LoadClientScriptSuccess>(
          RightNowActionTypes.LoadClientScriptSuccess,
        ),
      ),
      this.actions$.pipe(
        ofType<GetChatPreferencesSuccess>(
          RightNowActionTypes.GetChatPreferencesSuccess,
        ),
      ),
    ]).pipe(
      take(1),
      map(([_, { preferences }]) => new InitializeWidget(preferences)),
    ),
  );

  initializeWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitializeWidget>(RightNowActionTypes.InitializeWidget),
      switchMap(({ preferences }) =>
        this.rightNow.initializeWidget(preferences).pipe(
          map(() => new InitializeWidgetSuccess()),
          catchError((error) => of(new InitializeWidgetFailure(error))),
        ),
      ),
    ),
  );

  startChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType<StartChat>(RightNowActionTypes.StartChat),
      map(({ provider }) => {
        try {
          this.rightNow.startChat(provider);
          return new StartChatSuccess();
        } catch (e) {
          return new StartChatFailure(e);
        }
      }),
    ),
  );

  logErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RightNowAction & { error: any }>(
          RightNowActionTypes.GetChatPreferencesFailure,
          RightNowActionTypes.LoadClientScriptFailure,
          RightNowActionTypes.InitializeWidgetFailure,
          RightNowActionTypes.StartChatFailure,
        ),
        tap(({ type, error }) => console.log(type, error)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private featureTogglesFacade: FeatureTogglesFacade,
    private rightNow: RightNowService,
    private preferencesService: ChatPreferencesDataAccessService,
  ) {}
}
