<ng-container *ngIf="navItem && navItem.path">
  <a
    *ngIf="!navItem.openExternal; else externalLink"
    [routerLink]="navItem.path"
    [class.top-link]="isTopLevel"
    [class.child-link]="!isTopLevel"
    [class.cg-disabled-link]="isImpersonator && !navItem.allowImpersonator"
    [class.cg-non-clickable]="isImpersonator && !navItem.allowImpersonator"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: false }"
    [attr.data-test]="
      dataTestAttribute ? dataTestAttribute : navItem.identifier
    "
    [cignaTrackClicks]="[
      'trackEventOther',
      navItem.title,
      isTopLevel ? 'ac-NV003-Primary Nav' : 'ac-NV004-Secondary Nav',
      { prop20: isTopLevel ? 'nav-top-menu' : 'nav-sub-menu' }
    ]"
  >
    {{ navItem.title }}
    <cigna-icon
      *ngIf="hasChildren"
      type="chevron-down-medium"
      class="nav-dropdown-icon"
    ></cigna-icon>
    <cigna-leaf-badge
      *ngIf="navItem?.newTitle"
      [status]="'notification'"
      [text]="'NEW'"
      [variant]="'strong'"
      class="new-keyword-icon"
    ></cigna-leaf-badge>
  </a>

  <ng-template #externalLink>
    <ng-container
      *ngIf="navItem.path.includes('resourceLibrary'); else externalDefault"
    >
      <a
        [class.top-link]="isTopLevel"
        [class.child-link]="!isTopLevel"
        [href]="
          navItem.path
            | resourceLink : { type: 'page', targetApp: navItem.targetApp }
            | async
        "
        target="resourcesWindow"
        [attr.data-test]="
          dataTestAttribute ? dataTestAttribute : navItem.identifier
        "
        [cignaTrackClicks]="[
          'trackEventOther',
          navItem.title,
          isTopLevel ? 'ac-NV003-Primary Nav' : 'ac-NV004-Secondary Nav',
          { prop20: isTopLevel ? 'nav-top-menu' : 'nav-sub-menu' }
        ]"
      >
        {{ navItem.title }}
        <cigna-icon
          *ngIf="hasChildren"
          type="chevron-down-medium"
          class="nav-dropdown-icon"
        ></cigna-icon>
      </a>
    </ng-container>
    <ng-template #externalDefault>
      <a
        [class.top-link]="isTopLevel"
        [class.child-link]="!isTopLevel"
        [class.cg-disabled-link]="isImpersonator && !navItem.allowImpersonator"
        [class.cg-non-clickable]="isImpersonator && !navItem.allowImpersonator"
        [href]="navItem.path"
        target="_blank"
        rel="noopener"
        [attr.data-test]="
          dataTestAttribute ? dataTestAttribute : navItem.identifier
        "
        [cignaTrackClicks]="[
          'trackEventOther',
          navItem.title,
          isTopLevel ? 'ac-NV003-Primary Nav' : 'ac-NV004-Secondary Nav',
          { prop20: isTopLevel ? 'nav-top-menu' : 'nav-sub-menu' }
        ]"
      >
        {{ navItem.title }}
      </a>
    </ng-template>
  </ng-template>
</ng-container>
