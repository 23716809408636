import {
  ConversationContextTags,
  ConversationDTO,
  ConversationWaitEventDTO,
} from '@cigna/vampire-dto';
import flow from 'lodash/fp/flow';
import curry from 'lodash/fp/curry';
import { WaitEventListItem } from '../interfaces';
import { ConversationDTOStatus, ConversationWaitEventBlob } from './utils';

const defaultWaitMessage =
  'We are currently experiencing higher than normal chat volume. We will join your chat as soon as possible.';

export const extractRelevantWaitEvent = (
  conversation: ConversationDTOStatus,
) => {
  if (
    !conversation ||
    !(
      conversation.state === 'opening' ||
      (conversation.state === 'opened' &&
        conversation.status === 'AWAITING_AGENT')
    )
  ) {
    return { waitEvent: [], waitMessageConfig: {} };
  }

  const events = (conversation.events || []).filter(
    (event): event is ConversationWaitEventDTO => event.type === 'wait',
  );

  return events.length
    ? {
        waitEvent: [events[events.length - 1]],
        waitMessageConfig: {
          waitMessage: conversation.waitMessage || defaultWaitMessage,
          waitTimeinSeconds: conversation.waitTimeinSeconds
            ? parseInt(conversation.waitTimeinSeconds as string, 10)
            : 0,
        },
      }
    : { waitEvent: [], waitMessageConfig: {} };
};

export const transformWaitEvents = (
  personalGuideEligible: boolean,
  // this is due to WaitEvents being sent for async Cisco conversations
  isLive: boolean,
  isEvernorthEnabled: boolean,
  enableNewPreChatWelcome: boolean,
  events: ConversationWaitEventBlob,
): WaitEventListItem[] =>
  isLive
    ? events.waitEvent.map(
        (event: ConversationWaitEventDTO): WaitEventListItem => {
          const minutesRemaining = Math.floor(event.expected / 60);
          return {
            personalGuideEligible,
            _id: event._id,
            lineOne:
              isEvernorthEnabled && !enableNewPreChatWelcome
                ? 'Thank you for waiting. We will be with you shortly.'
                : events.waitMessageConfig.waitMessage,
            lineTwo: '',
            footer:
              isEvernorthEnabled && !enableNewPreChatWelcome
                ? 'You can also reach us by phone at (888) 736-7009'
                : 'Thank you for waiting.',
            datetime: `PT${minutesRemaining || '1'}M`,
            created: event.created,
            type: event.type,
            waitTimeinSeconds: events.waitMessageConfig.waitTimeinSeconds,
          };
        },
      )
    : [];

export const waitEventTransformFlow = (
  conversation: ConversationDTO,
  personalGuideEligible: boolean,
  isEvernorthEnabled: boolean,
  enableNewPreChatWelcome: boolean,
): WaitEventListItem[] =>
  flow(
    extractRelevantWaitEvent,
    curry(transformWaitEvents)(
      personalGuideEligible,
      conversation.type === 'live',
      isEvernorthEnabled,
      enableNewPreChatWelcome,
    ),
  )(conversation);
