<a
  #popoverRef="bs-popover"
  #popoverTrigger
  cignaButton="link inline {{
    underlineStyle !== 'link' ? underlineStyle : ''
  }}"
  [placement]="placement"
  [adaptivePosition]="hasAdaptivePosition"
  container="body"
  containerClass="cigna-popover-layout {{ containerClass }}"
  [popover]="popover"
  [outsideClick]="true"
  [isOpen]="isOpen"
  (onHidden)="hide()"
  tabindex="0"
  [attr.role]="!role ? 'button' : null"
  [attr.aria-label]="areaLabelText"
  data-test-id="popover-container"
>
  <ng-content></ng-content>
</a>

<ng-template #popover>
  <div
    data-test-id="popover-content"
    #popoverContent
    [id]="popoverId"
    (keydown.escape)="hide(); restoreFocus()"
    aria-live="polite"
    role="status"
  >
    <ng-container
      (click)="$event.preventDefault()"
      [ngTemplateOutlet]="content"
      [ngTemplateOutletContext]="contentContext"
    >
      <span
        data-test-id="popover-content-close"
        class="popover-content-close"
        *ngIf="shouldShowCloseIcon"
      >
        <a
          class="cml-btn-close"
          data-test-id="btnClose"
          (click)="hide()"
          (keydown.tab)="focusFirstElement($event)"
          (keydown.enter)="hide(); restoreFocus()"
          (keydown.space)="hide(); $event.preventDefault(); restoreFocus()"
        >
          x
        </a>
      </span>
    </ng-container>
    <!-- removed  close label link part of this requirement https://jira.express-scripts.com/browse/ACCTA-59 (for Keyboard triggering actions) -->
    <!-- <div
      *ngIf="keepOpen && !shouldShowCloseIcon"
      class="text-right shadowheight"
    >
      <button
        #closeButton
        cignaButton="link inline"
        (click)="hide()"
        (keydown.tab)="focusFirstElement($event)"
        (keydown.enter)="hide(); restoreFocus()"
        (keydown.space)="hide(); $event.preventDefault(); restoreFocus()"
        data-test-id="close-popup"
        class="OneLinkTx"
      ></button>
    </div> -->
  </div>
</ng-template>
