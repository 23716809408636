import { Component, Input } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { DialogSiteHelpPublicComponent } from '../dialog-site-help-public/dialog-site-help-public.component';
import { DialogSiteHelpSecureComponent } from '../dialog-site-help-secure/dialog-site-help-secure.component';
import { DialogSiteTourComponent } from '../dialog-site-tour/dialog-site-tour.component';

@Component({
  selector: 'cigna-footer-global',
  templateUrl: './footer-global.component.html',
  styleUrls: ['./footer-global.component.scss'],
})
export class FooterGlobalComponent {
  @Input() isLoggedIn = false;
  @Input() isMedical = true;
  @Input() hasOneTrustEnabled = false;

  currentYear: string = new Date().getFullYear().toString();
  constructor(public dialog: MatDialog) {}

  openSiteHelpModal() {
    if (this.isLoggedIn) {
      this.dialog.open(DialogSiteHelpSecureComponent, {
        autoFocus: false,
        width: '800px',
      });
    } else {
      this.dialog.open(DialogSiteHelpPublicComponent, {
        autoFocus: false,
        width: '800px',
      });
    }
  }

  openSiteTourModal() {
    this.dialog.open(DialogSiteTourComponent, {
      autoFocus: false,
      width: '700px',
    });
  }
}
