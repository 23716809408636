import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { WindowService } from '@cigna/shared/angular/core/window-util';
import {
  IconDynamicCloseComponent,
  IconDynamicHeartInHandComponent,
  omniBreakPoints,
  omniColors,
} from '@cigna/omni/shared-util';
import { Subscription } from 'rxjs';
import {
  ConversationDTO,
  ConversationMessageEventDTO,
} from '@cigna/vampire-dto';

@Component({
  selector: 'cigna-omni-fancy-invitation',
  templateUrl: './fancy-invitation.component.html',
  styleUrls: ['./fancy-invitation.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition(':enter', [
        animate(
          800, // enter animation should be longer than chat-bubble-container leave animation
          keyframes([
            // eslint-disable-next-line sonarjs/no-duplicate-string
            style({ transform: 'scale(0,0)', offset: 0, opacity: 0 }),
            style({ transform: 'scale(0,0)', offset: 0.63, opacity: 0 }),
            style({ transform: 'scale(0.5,0.5)', offset: 0.81, opacity: 0.5 }),
            style({ transform: 'scale(1,1)', offset: 1, opacity: 1 }),
          ]),
        ),
      ]),
      transition(':leave', [
        animate(
          300,
          keyframes([
            style({ transform: 'scale(1,1)', offset: 0, opacity: 1 }),
            style({ transform: 'scale(0.5,0.5)', offset: 0.5, opacity: 0.5 }),
            style({ transform: 'scale(0,0)', offset: 1, opacity: 0 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class FancyInvitationComponent implements OnInit, OnChanges, OnDestroy {
  @Output()
  public dismiss: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public openConv: EventEmitter<{ id: string; convType: string }> =
    new EventEmitter<{ id: string; convType: string }>();
  @Output()
  public viewed: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  public conv: ConversationDTO;
  @Input() public canEnableNewDMUI: boolean;

  public agentFirstName: string;
  public message: string;

  public resizeSub: Subscription;
  public firstMessageOnConv: string;

  public closeIcon = IconDynamicCloseComponent;
  public avatarIcon = IconDynamicHeartInHandComponent;
  public iconColors = {
    color: omniColors.blue,
  };
  public iconData = {
    showBorder: true,
    showNotificationBadge: false,
    size: '53px',
  };

  constructor(private _ws: WindowService) {}

  public dismissHandler() {
    this.dismiss.emit(this.conv._id);
  }

  public openConvHandler() {
    this.openConv.emit({ id: this.conv._id, convType: this.conv.type });
  }

  ngOnInit() {
    this.viewed.emit();
    this.resizeSub = this._ws.resize$.subscribe(() => {
      this.setInitialMessage();
    });
  }

  ngOnDestroy() {
    this.resizeSub.unsubscribe();
  }

  ngOnChanges(_changes: ComponentChanges<this>) {
    this.agentFirstName =
      this.conv.participants.find(
        (participant) => participant.role === 'agent' && participant.active,
      )?.name ?? 'Care Representative';

    this.firstMessageOnConv =
      (
        this.conv.events.find(
          (event) => event.type === 'message',
        ) as ConversationMessageEventDTO
      )?.message ?? '';

    this.setInitialMessage();
  }

  setInitialMessage() {
    const _message = this.canEnableNewDMUI
      ? this.conv.title || ''
      : this.firstMessageOnConv;
    const charLimit = this._ws.innerWidth <= omniBreakPoints.xs ? 69 : 228;
    const charArr = _message.split('');
    let cutoffIndex = 0;

    if (charArr.length < charLimit) {
      this.message = _message;
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = charLimit; i > 0; i--) {
        if (charArr[i] === ' ') {
          cutoffIndex = i;
          break;
        }
      }
      this.message =
        cutoffIndex > 0 ? `${charArr.slice(0, cutoffIndex).join('')}...` : ``;
    }
  }
}
