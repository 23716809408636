import { conversationsQuery } from '@cigna/omni/conversations-state-data-access';
import { createSelector } from '@ngrx/store';
import {
  sortByClosedStateAndDates,
  generateAgentSummaryListItem,
} from './omni-conversation-summary-list.transforms';

export const getSortedSummaryCardItems = createSelector(
  conversationsQuery.selectAllConversations,
  (conversations) =>
    conversations
      .filter((conversation) => conversation.type !== 'bot')
      .sort((conv1, conv2) => sortByClosedStateAndDates(conv1, conv2))
      .map(generateAgentSummaryListItem),
);
