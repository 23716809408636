import { Injectable } from '@angular/core';
import { UrlFactory, type Params } from './url-factory';

@Injectable({ providedIn: 'root' })
export class OmniGateway {
  constructor(private _urlFactory: UrlFactory) {}

  chatPreferencesUrl(): string {
    return this._urlFactory.createLiveUrl('v1/chat/preferences');
  }

  conversationsUrl(): string {
    return this._urlFactory.createBotUrl('v1/conversations');
  }

  endLiveConversationUrl({
    conversationId,
  }: {
    conversationId: string;
  }): string {
    return this._urlFactory.createLiveUrl(
      `v1/conversations/${conversationId}/end`,
    );
  }
  endBotConversationUrl({
    conversationId,
  }: {
    conversationId: string;
  }): string {
    return this._urlFactory.createBotUrl(
      `v1/conversations/${conversationId}/end`,
    );
  }

  terminateConversationUrl({
    conversationId,
    userAction,
  }: {
    conversationId: string;
    userAction: 'logout' | 'inactive';
  }): string {
    return this._urlFactory.createPublicUrl(
      `v1/conversations/${conversationId}/terminate/${userAction}`,
    );
  }

  terminateLambdaConversationUrl(
    userAction: 'logout' | 'inactive',
    type: 'user' | 'bot',
  ): string {
    return this._urlFactory.createPublicUrl(`/terminate/${type}/${userAction}`);
  }

  getBotConversationUrl({
    conversationId,
  }: {
    conversationId: string;
  }): string {
    return this._urlFactory.createBotUrl(`v1/conversations/${conversationId}`);
  }
  getLiveConversationUrl({
    conversationId,
  }: {
    conversationId: string;
  }): string {
    return this._urlFactory.createLiveUrl(`v1/conversations/${conversationId}`);
  }

  getAwsLiveConversationUrl({
    conversationId,
  }: {
    conversationId: string;
  }): string {
    return this._urlFactory.createAwsLiveUrl(
      `v1/conversations/${conversationId}`,
    );
  }

  postLiveConversationUrl(): string {
    return this._urlFactory.createLiveUrl(`v1/conversations`);
  }

  interceptionsUrl({ id }: { id: string }): string {
    return this._urlFactory.createIdentityUrl(`v1/interceptions/${id}`);
  }

  liveAvailabilityUrl(params: { offset: string; format: string }): string {
    return this._urlFactory.createIdentityUrl('v1/availability/live', params);
  }

  botMessageViewedUrl({
    conversationId,
    messageId,
  }: {
    conversationId: string;
    messageId: string;
  }): string {
    return this._urlFactory.createBotUrl(
      `v1/conversations/${conversationId}/viewed/${messageId}`,
    );
  }
  liveMessageViewedUrl({
    conversationId,
    messageId,
  }: {
    conversationId: string;
    messageId: string;
  }): string {
    return this._urlFactory.createLiveUrl(
      `v1/conversations/${conversationId}/viewed/${messageId}`,
    );
  }

  awsLiveMessageViewedUrl({
    conversationId,
    messageId,
  }: {
    conversationId: string;
    messageId: string;
  }): string {
    return this._urlFactory.createAwsLiveUrl(
      `v1/conversations/${conversationId}/viewed/${messageId}`,
    );
  }

  omnibusUrl(): string {
    return this._urlFactory.createIdentityUrl('v1/omnibus');
  }

  activeConversation(): string {
    return this._urlFactory.createLiveUrl('v1/conversations/active');
  }

  activeAsyncConversation(): string {
    return this._urlFactory.createAwsIdentityUrl(
      'v1/omnibus/async/conversations/active',
    );
  }

  conversationHistory(): string {
    return this._urlFactory.createLiveUrl('v1/conversations/history');
  }

  directMessageConversationHistory(): string {
    return this._urlFactory.createAwsLiveUrl(
      '/v1/conversations/history/async/closed',
    );
  }

  postActivity({ conversationId }: { conversationId: string }): string {
    return this._urlFactory.createLiveUrl(
      `v1/conversations/${conversationId}/activities`,
    );
  }

  postCtaUrl({ conversationId }: { conversationId: string }): string {
    return this._urlFactory.createBotUrl(
      `v1/conversations/${conversationId}/cta`,
    );
  }

  postMessageUrl({ conversationId }: { conversationId: string }): string {
    return this._urlFactory.createLiveUrl(
      `v1/conversations/${conversationId}/messages`,
    );
  }

  awsPostMessageUrl({ conversationId }: { conversationId: string }): string {
    return this._urlFactory.createAwsLiveUrl(
      `v1/conversations/${conversationId}/messages`,
    );
  }

  transferConversationUrl({
    conversationId,
  }: {
    conversationId: string;
  }): string {
    return this._urlFactory.createLiveUrl(
      `v1/conversations/${conversationId}/transfer`,
    );
  }

  userEventsUrl(params: Params<unknown>) {
    return this._urlFactory.createLiveUrl('v1/userevents', params);
  }

  awsUserEventsUrl() {
    return this._urlFactory.createAwsLiveUrl('v1/userevents');
  }

  tooltipViewUrl(): string {
    return this._urlFactory.createIdentityUrl('v1/view/tooltip');
  }
}
