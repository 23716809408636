import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
} from '@cigna/chcp/shared/environment-util';

@Injectable()
export class DisabledPageGuard {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private envConfig: EnvironmentConfig,
    private router: Router,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const disabledRoutes = this.envConfig.disabledRoutes;

    if (disabledRoutes.includes(state.url)) {
      this.router.navigate(['/disabled']);
      return false;
    }

    return true;
  }
}
