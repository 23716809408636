import { SafeUrl } from '@angular/platform-browser';
import { LOB } from '@cigna/chcp/auth/util';

export type AppContext = 'chcp' | 'evernorth';
export interface BreadCrumb {
  name: string;
  url: string;
  params?: string;
  queryParams?: { [key: string]: string };
  preserveQueryParams?: boolean;
}

export interface Tin {
  tin: string;
  name: string;
  lob?: LOB;
}

export interface TinLob {
  tin: string;
  lob: LOB;
}

export interface UpdateTinLob {
  tinNumber: string;
  lob: LOB;
}

export type ApiLobType = 'med' | 'dent';

export type Domain = 'APPEAL' | 'ADJUSTMENT' | 'PENDED';

export enum ReconsiderationType {
  APPEAL = 'APPEAL',
  ADJUSTMENT = 'ADJUSTMENT',
  CORRECTEDCLAIM = 'CORRECTED_CLAIM',
  PAYMENTSTATUS = 'PAYMENT_STATUS',
  REJECTION = 'REJECTION',
  PAYMENT_RETURNED = 'PAYMENT_RETURNED',
  CODINGS_EDITS = 'CODINGS_EDITS',
}

export enum PrecertificationStatus {
  APPROVE = 'Approved',
  DENY = 'Denied',
  PARTIAL_APPROVE = 'Partially Approved',
  PEND = 'Pending',
  VOID = 'Void',
  REQUESTED = 'Requested',
  NEGOTIATED = 'Negotiated',
  NO_DECISION = 'No Decision',
  MIXED = 'Mixed',
  MANUAL = 'Manual',
  INCOMPLETE = 'Incomplete',
  PARTIAL_DENY = 'Partially Denied',
}

export type PrecertificationStatusType = keyof typeof PrecertificationStatus;

export enum FileUploadErrors {
  MAX_SIZE = 'Upload failed as the file surpassed the 64MB maximum.  Delete this file and divide the file so that each file is under the 64 MB maximum size.',
  BAD_EXTENSION = 'Upload failed due to incorrect file type. Delete this file, re-save your file to one of the accepted file types and upload again.',
  BAD_NAME = 'The file name does not meet requirements. File names must be at least 5 characters and cannot exceed 128 characters or contain any special characters except hyphen (-), at (@), period (.), exclamation (!), underscore (_) and ampersand (&).',
  SERVER_ERROR = 'Server error',
  FAILED_SCANNER = 'Virus Found',
  UNDER_SIZED = 'This file does not meet requirements. File must not be equal to 0 KB. Please reattach a file greater than 0 KB and under 64 MB maximum size.',
  INVALID_FILE = "Upload failed: We couldn't upload your file. It looks like the file may be corrupted or password protected. Please remove any password protection and try uploading a valid file in a compatible format.",
}

export type FileUploadError = keyof typeof FileUploadErrors;

export enum FileUploadMethod {
  browse = 'Browse',
  drag = 'Drag',
}

export interface FileWithUrl {
  file: File;
  url: SafeUrl;
  pages?: number;
  signedUrl?: string;
  error?: FileUploadError;
  isLoading?: boolean;
  uploadMethod?: FileUploadMethod;
  loaded?: number;
}

export interface FilterOption {
  value: string;
  label: string;
  isSelected: boolean;
}

export type FilterGroupedOptions = Record<string, FilterOption[]>;

export type DateRangeOptionType =
  | 'all'
  | '-24h'
  | '-7d'
  | '-30d'
  | '-60d'
  | '-6m'
  | 'editable';

export interface DateRangeOption {
  value: DateRangeOptionType;
  description: string;
}

export interface DateForm {
  range: DateRangeOptionType;
  option?: string;
  min?: Date;
  max?: Date;
}

export interface DateFilter extends DateForm {
  options?: FilterOption[];
}

export enum AttachmentDisplayStatus {
  'NEW' = 'Pending Virus Scan',
  'VALID' = 'Received',
  'INVALID' = 'Request Failed - Virus Found',
  'UNAVAILABLE' = 'Request Failed - Help Desk Notified - Please Resubmit in 48 Hours',
}

export type AttachmentStatusType = keyof typeof AttachmentDisplayStatus;

export interface AttachmentMetaData {
  id: string;
  name: string;
  size: number;
  domain: string;
  domainId: string; // adjustment id
  createdDate: Date;
  updateDate: Date;
  createdBy: string;
  updatedBy: string;
  signedUrl?: string;
  scanSignature: string;
  scanStatus: AttachmentStatusType;
  type: string;
  pages?: number;
}

export interface SignedUrlMetaData {
  attachmentId: string;
  filename: string;
  signedUrl: string;
}

export interface AttachmentToUpload extends SignedUrlMetaData {
  file: File;
  loaded?: number;
}

export interface Attachment extends AttachmentMetaData {
  file?: File;
  error?: FileUploadError;
  isLoading: boolean;
  loaded?: number;
}

export interface ServiceLineDetails {
  orderNumber: string;
  orderCode: string;
  orderCodeDescription: string;
  orderStatus: PrecertificationStatusType;
  statusReasonCode?: string;
  requestDate?: string;
  decisionId: string;
  decisionStartDate?: string;
  decisionEndDate?: string;
  bedType?: string;
  requestUnits?: string;
  decisionUnits?: string;
  accessType?: string;
  determiningParty?: 'Cigna' | 'Evicore';
  decisionSetting?: string;
  // Below is for DG precerts
  stayLevel?: 'Behavioral/MHSR';
}

export type LogoutVariations = 'logout' | 'inactive';

export interface SupportingInformation {
  claimReferenceNumber: string;
  id: string;
  attachments: string[];
  attachmentsData?: AttachmentMetaData[];
  sourceSystemCode: string; // TODO: add better type
  claimEngineClaimID: string;
  status?: keyof typeof SupportingInformationDisplayStatus;
  submittedBy?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  submittedDate?: string;
  extRefNum?: string;
}

export enum SupportingInformationDisplayStatus {
  SCANNER_PENDING = 'Pending Virus Scan',
  SCANNER_FAILED = 'Request Failed - Virus Found',
  RECEIVED = 'Received',
  UNAVAILABLE = 'Status Unavailable - Check back later',
  IN_PROCESS = 'In Process',
  NOT_FOUND = 'Upload failed - Please resubmit',
  FAILED = 'Upload failed - Please resubmit',
  PROCESSED = 'Processed',
  UPLOADING = 'Uploading',
}

export const claimSystemCodes: { [name: string]: ClaimSystemCode } = {
  Facets: 'GWFC',
  FacetsNew: 'FCTS',
  PowerMHS: 'PMHS',
  CignaBehaivoralHealth: 'CBH',
  ProClaim: 'PCLM',
  DentaCom: 'DNTC',
  Webster: 'WDEN',
  DiamondInternational: 'DMND',
  PayerSolution: 'PYSL',
  QCare: 'QCRE',
  GlobalBenefits: 'PRIC',
  Behavioral: 'B',
  QNext: 'QNXT',
  GovernmentBusinessSegmentFacets: 'GBSF',
};
export type ClaimSystemCode =
  | 'FCTS' // Facets new naming
  | 'GWFC' // Great West / Facets // to be replaced with 'FCTS' for now only making change for claims
  | 'PMHS' // Power MHS
  | 'CBH' // Cigna Behavioral Health
  | 'PCLM' // ProClaim
  | 'DNTC' // Dentacom
  | 'WDEN' // Webster Dental
  | 'DMND' // Diamond International
  | 'QCRE' // Q Care (medical/behavioral)
  | 'PRIC' // Global Benefits
  | 'PYSL' // Payer Solutions
  | 'B' // Behavioral
  | 'QNXT' // Q Next
  | 'GBSF'; // Government Business Segment and Facets

export type ClaimStatusInboundType =
  | 'Approved'
  | 'Paid'
  | 'Adjusted'
  | 'Denied'
  | 'In process'
  | 'In-Process'
  | 'Processed'
  | 'Rejected'
  | 'Duplicate'
  | 'Pending'
  | 'Pended'
  | 'Pre-Treatment Request'
  | 'PRE-TREATMENT REQUEST ESTIMATE'
  | 'PRE-TREATMENT REQUEST DENIED'
  | 'PRE-TREATMENT REQUEST PENDED'
  | 'Pre treatment request'
  | 'Pre treatment request estimate'
  | 'Pre treatment request denied'
  | 'Pre treatment request pended';

// this mapping is needed because several inbound statuses from different claim engines may be mapped to the same status (like pending vs. pended)
export const claimStatusInboundMap: Record<string, keyof typeof ClaimStatus> = {
  APPROVED: 'APPROVED',
  PAID: 'PAID',
  ADJUSTED: 'ADJUSTED',
  DENIED: 'DENIED',
  'IN PROCESS': 'IN_PROCESS',
  PROCESSED: 'PROCESSED',
  REJECTED: 'REJECTED',
  DUPLICATE: 'DUPLICATE',
  PENDING: 'PENDED',
  PENDED: 'PENDED',
  'PRE TREATMENT REQUEST': 'PTRESTIMATE',
  'PRE TREATMENT REQUEST ESTIMATE': 'PTRESTIMATE',
  'PRE TREATMENT REQUEST DENIED': 'PTRESTIMATEDENIED',
  'PRE TREATMENT REQUEST PENDED': 'PTRESTIMATEPENDING',
};

export function mapInboundClaimStatus(
  inboundStatus: ClaimStatusInboundType | string,
): keyof typeof ClaimStatus | string {
  return (
    claimStatusInboundMap[inboundStatus.toUpperCase().replace('-', ' ')] ||
    inboundStatus
  );
}

// maps to value shown on UI
export enum ClaimStatus {
  APPROVED = 'Approved',
  PAID = 'Paid',
  ADJUSTED = 'Adjusted',
  DENIED = 'Denied',
  IN_PROCESS = 'In process',
  PROCESSED = 'Processed',
  REJECTED = 'Rejected',
  DUPLICATE = 'Duplicate',
  PENDED = 'Pended',
  PTRESTIMATE = 'Pre-treatment request',
  PTRESTIMATEDENIED = 'Pre-treatment request denied',
  PTRESTIMATEPENDING = 'Pre-treatment request pending',
}

export interface ClaimStatusCodeDetails {
  statusCode: string;
  statusCodeDesc: string;
}

export interface ClaimSummary {
  claimCompositeKey: string;
  claimReferenceNumber: string;
  providerGeneratedAccountNumber: string;
  claimType: string;
  claimStatus: ClaimStatusInboundType;
  dateOfService: string;
  dateReceived: string;
  dateProcessed: string;
  chargeAmount: string;
  amountPaid: string;
  memberResponsibility: string;
  servicingProvider: string;
  claimStatusCode?: string;
  claimStatusCodeDetails?: ClaimStatusCodeDetails[];
  sourceSystemCode?: string;
}

export function attachmentsToFiles(attachments: Attachment[]): FileWithUrl[] {
  // dirty hack. file-upload component is common and accept files,
  // but if attachments are already on S3 we'll have only metadata
  return (attachments || []).map(
    (v) =>
      ({
        ...v,
        file: v.file || { name: v.name, size: v.size },
      } as unknown as FileWithUrl),
  );
}

export function metaToAttachment(meta: AttachmentMetaData): Attachment {
  const attachment: Attachment = { ...meta, isLoading: false };
  return meta.scanStatus === 'INVALID'
    ? { ...attachment, error: 'FAILED_SCANNER' }
    : attachment;
}

export interface NavigationLink {
  title: string;
  path: string;
  openExternal: boolean;
  allowImpersonator: boolean;
  newTitle?: boolean;
  identifier: string;
  hubkey: string | null;
  targetApp?: AppContext;
}

export interface NavigationItem extends NavigationLink {
  children?: NavigationLink[];
}

export interface MessageAttachments {
  id: string;
  domain: string;
  expiresOn: number;
  name: string;
  key: string;
}

export interface Message {
  id: string;
  subject: string;
  desc: string;
  created: string;
  isRead: boolean;
  type: MessageType;
  flagged: boolean;
  body: string;
  buttonLink: string;
  buttonLabel: string;
  categories: string[];
  hasAttachments?: boolean;
  attachmentsMetadata?: MessageAttachments[];
}

// todo: combine with MessageTab into single type?
export type MessageType =
  | 'inbox'
  | 'claims'
  | 'precert'
  | 'flagged'
  | 'archive';
export type MessageTab = 'inbox' | 'claims' | 'precert' | 'flagged' | 'archive';
export type SortOrderType = 'asc' | 'desc';

export interface MessageFilters {
  read: FilterOption[];
  flag: FilterOption[];
  date: DateFilter;
  category: string;
  subCategory: string;
  claimStatus: string;
}

export interface DateFilterUpdateType {
  filter: keyof MessageFilters;
  date: DateFilter;
}

export interface StandardFilterUpdateType {
  filter: keyof MessageFilters;
  value: FilterOption[] | string;
}

export interface MessageUpdateOptions {
  isRead: boolean;
  type: MessageType;
  flagged: boolean;
  categories: string[];
}
export interface MessageUpdate extends Partial<MessageUpdateOptions> {
  messageIds: string[];
}

export interface CountInfo {
  flagged: number;
  total: number;
  unread: number;
}

export interface MessagesResponse {
  count: CountInfo;
  messages: Message[];
  offset?: string;
}

export interface PresignedUrlData {
  key: string;
  signedUrl: string;
}

export enum PatientType {
  Commercial = 'Commercial',
  MA = 'Medicare Advantage',
}
