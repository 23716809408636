import { Component, Input, isDevMode } from '@angular/core';

@Component({
  selector: 'cigna-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() error: Error;

  get production(): boolean {
    return !isDevMode();
  }
}
