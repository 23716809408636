import { Injectable } from '@angular/core';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AnalyticsService,
  LinkType,
} from '@cigna/shared/angular/analytics-data-access';
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import {
  ChcpRules,
  TinData,
  EventExtraData,
  EventPrivateData,
} from './chcp.rules';
import { DigitalDataObject } from './analytics/shell-track-handlers.model';
import { WindowService } from '@cigna/shared/angular/core/window-util';

// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum authStatus {
  loggedIn = 'logged in',
  loggedOut = 'logged out',
  notLoggedIn = 'not logged in',
}

@Injectable({
  providedIn: 'root',
})
export class ChcpAnalyticsService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  enabled = true;

  constructor(
    private authFacade: AuthFacade,
    private _analytics: AnalyticsService,
    private _window: WindowService,
  ) {
    if (this.authFacade.isImpersonator$) {
      this.authFacade.isImpersonator$.subscribe((impersonator: boolean) => {
        this.enabled = !impersonator;
      });
    }
  }

  public updateStore(data: DigitalDataObject) {
    if (!this.enabled) {
      return;
    }
    this._analytics.updateStore(data);
  }

  public clearUserStore() {
    if (!this.enabled) {
      return;
    }
    this._analytics.updateStore({
      user: {
        profile: {
          ssoId: null,
          lobType: null,
        },
      },
    });
  }

  public trackEvent<R extends keyof ChcpRules>(
    _ruleName: R,
    data: ChcpRules[R]['data'],
    linkType: LinkType = LinkType.OTHER,
  ) {
    if (!this.enabled) {
      return;
    }
    this._analytics.trackEvent(this.enhanceEventData(data as any), linkType);
  }

  public trackPage<R extends keyof ChcpRules>(
    _ruleName: R,
    data: ChcpRules[R]['data'],
  ) {
    if (!this.enabled) {
      return;
    }
    this._analytics.trackPage(this.enhancePageData(data));
    this._analytics.updateStore({
      page: {
        pageInfo: {
          channel: data && data.channel,
          hier2: data && data.hier2,
          eVar8: data && data.eVar8,
          eVar2: data && data.eVar2,
        },
      },
    });
  }

  private enhancePageData(data: any): object {
    return {
      ...data,
      prop67: 'page view',
      ...this.includeUserData(),
      ...this.includeProducts(data),
    };
  }

  /*
    get the channel and hier2 of the current page (stored in digitalData object)
    append link category (optional) and link name to hier2
    getVar('name')  'name' refers to a 'Data Element' in adobe launch configuration
*/
  private enhanceEventData(data: EventExtraData & EventPrivateData): object {
    const { pseudoTin, ...extraData } = data;
    const pageChannel =
      (this._window._satellite && this._window._satellite.getVar('channel')) ||
      'BUG: no channel set for this page, add to routing data object';

    const pageHier2 =
      (this._window._satellite && this._window._satellite.getVar('hier2')) ||
      'BUG: no hier2 set for this page, add to routing data object';

    const pageName =
      (this._window._satellite && this._window._satellite.getVar('pageName')) ||
      'BUG: no hier2 set for this page, add to routing data object';

    const linkLocation = extraData.prop20 ? `${extraData.prop20}|` : '';
    const serviceError = extraData.prop37 ? 'Error:' : '';
    const eventType = extraData.eventType || 'action';

    const linkName =
      extraData.linkName || 'BUG: missing/empty link name from tracking call';

    const loggedIn = extraData.eVar8
      ? extraData.eVar8 // in case of logout success we pass eVar8 explicity, so this will let us use that
      : (this._window._satellite &&
          this._window._satellite.getVar('loggedIn')) ||
        authStatus.notLoggedIn;

    return {
      ...extraData,
      channel: pageChannel,
      hier2: `${pageHier2}|${linkLocation}${serviceError}${linkName}`,
      eVar8: loggedIn,
      prop67: eventType,
      eVar2: pageName,
      prop2: pageName,
      ...this.includeProducts(extraData, pseudoTin),
      ...(loggedIn === authStatus.loggedIn && this.includeUserData()),
    };
  }

  private formatPseudoTin(tinData: TinData) {
    return tinData.tins.map(
      (tin) =>
        `;pseudoTin;;;;eVar122=${tinData.type.toLowerCase()}^selected^${tin}`,
    );
  }

  private includeUserData() {
    const ssoId =
      (this._window._satellite && this._window._satellite.getVar('ssoId')) ||
      null;
    const lobType =
      (this._window._satellite && this._window._satellite.getVar('lobType')) ||
      null;

    return {
      eVar84: ssoId,
      eVar55: lobType,
    };
  }

  private includeProducts(data: EventExtraData, pseudoTin?: TinData) {
    const lobType =
      (this._window._satellite && this._window._satellite.getVar('lobType')) ||
      null;
    const updatedProducts = lobType
      ? `;businessSegments;;;;eVar29=lobType:${lobType}${
          pseudoTin?.tins?.length ? `,${this.formatPseudoTin(pseudoTin)}` : ''
        }${data.products ? `,${data.products}` : ''}`
      : null;

    return {
      products: updatedProducts,
    };
  }
}
