import { InjectionToken, Provider } from '@angular/core';

export interface Auth0FlowConfig {
  domain: string;
  clientId: string;
  connection: string;
  audience: string;
  reviewAppProxyTokenEndpoint?: string;
}

export const AUTH0_FLOW_CONFIG = new InjectionToken<Auth0FlowConfig>(
  'AUTH0_FLOW_CONFIG',
);

export function auth0FlowConfig(config: Auth0FlowConfig): Provider {
  return {
    provide: AUTH0_FLOW_CONFIG,
    useValue: config,
  };
}
