import { NgModule, APP_INITIALIZER } from '@angular/core';

import {
  FeaturesModule,
  FeatureTogglesFacade,
  FeatureToggleType,
} from '@cigna/shared/angular/features-feature';
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import {
  APP_CONTEXT,
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
  Feature,
} from '@cigna/chcp/shared/environment-util';

import zipObject from 'lodash/zipObject';

import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { AppContext } from '@cigna/chcp/shared/util';

export const configFeaturesFactory =
  (config: EnvironmentConfig, features: FeatureTogglesFacade) => () => {
    features.updateFeatureToggles(config.features, FeatureToggleType.RELEASE);
  };

/*
 https://storybook-nx.apps.gp-1-nonprod.openshift.cignacloud.com/?path=/story/docs-explanation--feature-toggles#permission-toggles
 - this is not a list of all feature toggles, only select ones that are also set at user level through jwt to enable user based feature rollout
 - per docs above any feature toggle included here will be disabled by default unless it explicity comes in user jwt, as permission toggle flag supersedes environment level flag

*/
export const permissionToggles: Feature[] = [
  'chat',
  'pilotMHKUser',
  'medicareclaim',
  'upxview',
  'upxsubmit',
  'pilotBenEks',
];

export const permissionFeaturesFactory =
  (authFacade: AuthFacade, features: FeatureTogglesFacade) => () => {
    features.updateFeatureToggles(
      zipObject(permissionToggles, Array(permissionToggles.length).fill(false)),
      FeatureToggleType.PERMISSION,
    );
    authFacade.permissionToggles$.subscribe((permToggles) => {
      // jwt may come back with no permissionToggles
      if (permToggles) {
        features.updateFeatureToggles(
          zipObject(permToggles, Array(permToggles.length).fill(true)),
          FeatureToggleType.PERMISSION,
        );
      }
    });
  };

@NgModule({
  imports: [
    FeaturesModule.withLaunchDarkly(
      ldSettings,
      ENVIRONMENT_CONFIG,
      AuthFacade,
      APP_CONTEXT,
    ),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: permissionFeaturesFactory,
      deps: [AuthFacade, FeatureTogglesFacade],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: configFeaturesFactory,
      deps: [ENVIRONMENT_CONFIG, FeatureTogglesFacade],
    },
  ],
})
export class ChcpSharedFeaturesUtilModule {}

export function ldSettings(
  config: EnvironmentConfig,
  authFacade: AuthFacade,
  appContext: AppContext,
) {
  return authFacade.userid$.pipe(
    startWith(undefined),
    withLatestFrom(authFacade.lob$, authFacade.permissionToggles$),
    map(([ssoid, userLob, permToggles]) => ({
      clientId: config.launchDarklyClientId,
      userKey: ssoid,
      application: appContext,
      lob: userLob,
      permissionToggles: permToggles?.join(' '),
    })),
  );
}
