import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Satellite } from '@cigna/shared/angular/core/interfaces-util';

export type ResizeObserverCtor = new (
  callback: (entries: ResizeObserverEntry[], observer: ResizeObserver) => void,
) => ResizeObserverLike;

export interface ResizeObserverLike {
  observe: (node: HTMLElement) => void;
  disconnect: () => void;
}

interface Navigator {
  cookiesEnabled: boolean;
  language: string;
  userAgent: string;
}

@Injectable()
export abstract class WindowService {
  abstract get resize$(): Observable<Event>;
  abstract get analyticsLoaded$(): Observable<Event>;

  abstract get innerHeight(): number;
  abstract get innerWidth(): number;

  abstract get location(): Location;

  abstract get localStorage(): Storage;

  abstract get pageXOffset(): number;
  abstract get pageYOffset(): number;

  abstract get sessionStorage(): Storage;

  abstract get screen(): Screen;

  abstract get userAgent(): string;

  abstract get ResizeObserver(): ResizeObserverCtor | undefined;

  abstract get _satellite(): Satellite;

  abstract get navigator(): Navigator;

  abstract getComputedStyle(
    elt: Element,
    pseudoElt?: string | null,
  ): CSSStyleDeclaration;

  abstract scroll(options: ScrollOptions): void;
  abstract scroll(x?: number, y?: number): void;

  abstract scrollTo(options: ScrollToOptions): void;
  abstract scrollTo(x?: number, y?: number): void;

  abstract setTimeout(fn: () => void, delay: number): number;
  abstract clearTimeout(timeoutId: number): void;

  abstract matchMedia(mediaQuery: string): MediaQueryList;
  abstract open(
    url?: string,
    target?: string,
    features?: string,
  ): // eslint-disable-next-line @typescript-eslint/ban-types
  Window | null;

  abstract requestAnimationFrame(callback: FrameRequestCallback): number;
  abstract cancelAnimationFrame(handle: number): void;

  abstract createObjectURL(blob: any): any;
  abstract addEventListener(
    type: string,
    listener?: EventListenerOrEventListenerObject | null,
    options?: boolean | AddEventListenerOptions,
  ): void;
  abstract removeEventListener(
    type: string,
    listener?: EventListenerOrEventListenerObject | null,
    options?: boolean | EventListenerOptions,
  ): void;
  abstract print(): void;
  abstract close(): void;

  // eslint-disable-next-line @typescript-eslint/ban-types
  abstract parent: Window;
}
