import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OmniSharedUiModule } from '@cigna/omni/shared-util';
import { OmniConversationSummaryCardComponent } from './component/omni-conversation-summary-card.component';
import { OmniSummaryCardDatetimePipe } from './omni-summary-card-datetime.pipe';
import { OmniDcmModule } from '@cigna/omni/dcm-feature';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { CdnPrefixPipe } from './pipes/cdn-prefix.pipe';

@NgModule({
  imports: [CommonModule, OmniSharedUiModule, OmniDcmModule, UiIconModule],
  declarations: [
    OmniConversationSummaryCardComponent,
    OmniSummaryCardDatetimePipe,
    CdnPrefixPipe,
  ],
  exports: [OmniConversationSummaryCardComponent],
})
export class OmniConversationSummaryCardModule {}
