import { Injectable } from '@angular/core';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  FeatureTogglesFacade,
  FeatureToggleType,
  updateFeatureToggles,
} from '@cigna/shared/angular/features-feature';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmnibusDataAccessService } from '@cigna/omni/data-access';
import {
  getUTCOffset,
  OmniLiveAvailabilityFacade,
} from '@cigna/omni/live-availability-state-data-access';
import { OmniPollingFacade } from '@cigna/omni/polling-state-data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, pipe, UnaryFunction } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  OmnibusInitializationActionTypes,
  OmnibusInitializeFailure,
  OmnibusInitializeSuccess,
} from './omnibus-initialization.actions';
import { OmnibusResultProperties } from './omnibus-initialization.interfaces';

@Injectable()
export class OmnibusInitializationEffects {
  omnibusInitializeNoop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OmnibusInitializationActionTypes.OmnibusInitializeNoop),
        switchMap(() => this.service.postOmnibus([])),
      ),
    { dispatch: false },
  );

  omnibusInitialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFeatureToggles),
      this.omniBusFilter(),
      take(1),
      switchMap(() => this.postOmniBus()),
    ),
  );

  OmnibusInitializeRetry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OmnibusInitializationActionTypes.OmnibusInitializeRetry),
      this.omniBusFilter(),
      switchMap(() => this.postOmniBus()),
    ),
  );

  omnibusInitializeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OmnibusInitializeSuccess>(
          OmnibusInitializationActionTypes.OmnibusInitializeSuccess,
        ),
        map((res) => {
          const properties = res.payload.find(
            // eslint-disable-next-line sonarjs/no-duplicate-string
            (data) => data.type === 'v2/properties',
          );
          const generalChat = (properties as OmnibusResultProperties)?.success
            ?.features?.generalChat;
          return generalChat
            ? res
            : { ...res, payload: properties ? [properties] : [] };
        }),
        withLatestFrom(
          this.featureTogglesFacade.featuresEnabled(['enableChatAws']),
          this.featureTogglesFacade.featuresEnabled(['isDcmChat']),
          this.featureTogglesFacade.featuresEnabled(['isNotImpersonator']),
          this.featureTogglesFacade.featuresEnabled(['enableNewDMUI']),
        ),
        // eslint-disable-next-line sonarjs/cognitive-complexity
        tap(
          ([
            { payload },
            enableChatAws,
            isDcmChat,
            isNotImpersonator,
            enableNewDMUI,
            // eslint-disable-next-line sonarjs/cognitive-complexity
          ]) => {
            // eslint-disable-next-line sonarjs/cognitive-complexity
            payload.forEach((result) => {
              switch (result.type) {
                case 'v2/properties':
                  if (result.success) {
                    const features = result.success.features;
                    const featuresFlags: Record<string, boolean> = {};

                    Object.keys(features).forEach((k) => {
                      const value = features[k];
                      if (typeof value === 'boolean') {
                        featuresFlags[k] = value;
                      }
                    });

                    this.featureTogglesFacade.updateFeatureToggles(
                      featuresFlags,
                      FeatureToggleType.OPS,
                    );

                    if (isNotImpersonator) {
                      if (result.success.termedUser) {
                        this.featureTogglesFacade.updateFeatureToggles(
                          {
                            isTerminatedUser: true,
                          },
                          FeatureToggleType.PERMISSION,
                        );
                        this.conversationsFacade.setTerminatedUserMessage(
                          result.success.termedUser,
                        );
                      }

                      if (
                        typeof result.success.features.generalChatByClient ===
                        'object'
                      ) {
                        this.chatFacade.setChatByClientList(
                          result.success.features.generalChatByClient,
                        );
                      }

                      if (
                        typeof result.success.features.activityDebounce ===
                        'number'
                      ) {
                        this.conversationsFacade.setActivityDebounceTime(
                          result.success.features.activityDebounce,
                        );
                      }
                      if (
                        typeof result.success.features.activeChatProvider ===
                        'string'
                      ) {
                        this.conversationsFacade.setActiveChatProvider(
                          result.success.features.activeChatProvider,
                        );
                      }
                      if (isDcmChat && result.success.careAdvocateData) {
                        this.conversationsFacade.setCareAdvocateData(
                          result.success.careAdvocateData,
                        );
                        this.conversationsFacade.getConversationHistory();
                      }
                      this.pollingFacade.pollingConfigLoaded(
                        result.success.pollingWeb,
                      );
                    }
                    this.liveAvailabilityFacade.setLiveAvailabilityLoaded(true);
                  } else {
                    this.pollingFacade.pollingConfigError(result.failure);
                  }
                  break;

                case 'v1/availability/live':
                  if (result.success) {
                    this.liveAvailabilityFacade.setLiveAvailability(
                      result.success,
                    );
                  }
                  break;

                case 'v1/conversations':
                  if (isNotImpersonator) {
                    // eslint-disable-next-line no-unused-expressions
                    !enableChatAws
                      ? result.success
                        ? enableNewDMUI
                          ? this.conversationsFacade.getActiveAsyncConversation(
                              result.success,
                            )
                          : this.conversationsFacade.loadConversationsSuccess(
                              result.success,
                            )
                        : this.conversationsFacade.loadConversationsFailure(
                            result.failure,
                          )
                      : this.conversationsFacade.getActiveConversation();
                  }
                  break;

                // case 'v1/interceptions':
                //   if (result.success) {
                //     this.interceptionsFacade.addMany(result.success);
                //   }
                //   break;

                default:
                  break;
              }
            });
          },
        ),
      ),
    { dispatch: false },
  );

  omniBusFilter(): UnaryFunction<
    Observable<unknown>,
    Observable<[boolean, boolean, boolean, boolean | undefined, boolean]>
  > {
    return pipe(
      switchMap(() =>
        this.featureTogglesFacade.featuresEnabled(['isUserAuthorized']),
      ),
      withLatestFrom(
        this.featureTogglesFacade.featuresEnabled(['hasRestrictedChat']),
        this.featureTogglesFacade.featuresEnabled([
          'enableRestrictedChatInPortal',
          'canCallOmnibusApi', // to make omnibus call based on page
        ]),
        this.chatFacade.getIsInternalUser$,
        this.featureTogglesFacade.featuresEnabled(
          ['ftr-mycigna-dashboard-team-web-contactus', 'isNotImpersonator'],
          'OR',
        ),
      ),
      filter(
        ([
          isUserAuthorized,
          restrictedChat,
          enableChatInPortal,
          isInternalUser,
          isContactUsOrNotImpersonator,
        ]) =>
          isUserAuthorized &&
          isContactUsOrNotImpersonator &&
          (!restrictedChat || enableChatInPortal) &&
          !isInternalUser,
      ),
    );
  }

  postOmniBus(): Observable<
    OmnibusInitializeSuccess | OmnibusInitializeFailure
  > {
    return this.service
      .postOmnibus([
        {
          seq: 0,
          type: 'v2/properties',
          payload: null,
        },
        {
          seq: 1,
          type: 'v1/conversations',
          payload: { types: ['async', 'live'] },
        },
        {
          seq: 2,
          type: 'v1/availability/live',
          payload: {
            offset: getUTCOffset(new Date().getTimezoneOffset()),
            format: '12h',
          },
        },
        // {
        //   seq: 3,
        //   type: 'v1/interceptions',
        //   payload: null,
        // },
      ])
      .pipe(
        map((omnibusResults) => {
          const outage =
            omnibusResults.find((item) => item.success?.outage)?.success
              .outage || {};
          const { activeOutage = false, message = '' } = outage;
          this.liveAvailabilityFacade.setChatOutage(activeOutage);
          this.liveAvailabilityFacade.setChatOutageMsg(message);
          return new OmnibusInitializeSuccess(omnibusResults);
        }),
        catchError((error) => of(new OmnibusInitializeFailure(error))),
      );
  }

  constructor(
    private actions$: Actions,
    private service: OmnibusDataAccessService,
    private conversationsFacade: OmniConversationsFacade,
    private liveAvailabilityFacade: OmniLiveAvailabilityFacade,
    private pollingFacade: OmniPollingFacade,
    private featureTogglesFacade: FeatureTogglesFacade,
    private chatFacade: OmniChatFacade,
  ) {}
}
