import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  ViewChild,
  ElementRef,
  Inject,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ModalComponent } from '../modal/modal.component';
import {
  ModalLayoutTitleDirective,
  ModalLayoutSubTitleDirective,
  ModalLayoutButtonsDirective,
} from './modal-layout-extensions';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { CignaIcon } from '@cigna/shared/angular/icon-ui';

@Component({
  selector: 'cigna-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.scss'],
})
export class ModalLayoutComponent implements AfterViewInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _previousFocusedElement: (Element & { focus?(): void }) | null;

  /**
   * Set the title for the modal.
   */
  @Input() title: string;
  /**
   * Add OneLinkTx class for translation for myCigna hardcoded content.
   */
  @Input() isMyCignaTranslate: false;
  /**
   * Set the subtitle for the modal.
   */
  @Input() subTitle: string;
  /**
   * Determine if there is a **primary** button in the modal footer. If a non-empty string
   * is provided, the button will display and this will be the text of the button.
   */
  @Input() ok: string;
  /**
   * Determine if there is a **secondary** button in the modal footer. If a non-empty string
   * is provided, the button will display and this will be the text of the button.
   */
  @Input() alt: string;
  /**
   * Determine if there is a close button (X) for the modal and what text appears next to the
   * X. If this value is `null`, do not show the close button. If this value is a non-empty
   * string, show a close button with the given text.
   */
  @Input() close?: string | null;
  /**
   * Determine if there is a dismiss button (X) for the modal and what text appears next to the
   * X. If this value is `null`, do not show the dismiss button. If undefined don't show the the text and (X).
   * If this value is a non-empty string, show a dismiss button with the given text.
   */
  @Input() dismiss?: string | null;
  /**
   * Determine if there is a back button for the modal
   */
  @Input() hasBackButton?: boolean | null;
  /**
   * Set the image for the modal
   */
  @Input() imagePath?: string | null;
  /**
   * Set the alt text for the image for the modal
   */
  @Input() imageAltText = 'Logo image for modal';
  /**
   * Set the translation class to header for the modal
   */
  @Input() titleTranslateClass = '';
  /**
   * Set the translation class to ok button for the modal
   */
  @Input() okTranslateClass = '';
  /**
   * Emits when the primary button is clicked
   */
  @Output() okClicked = new EventEmitter();
  /**
   * Emits when the secondary button is clicked.
   */
  @Output() altClicked = new EventEmitter<void>();
  /**
   * Emits when the close button is clicked.
   */
  @Output() closeClicked = new EventEmitter();
  /**
   * Emits when the dismiss button is clicked.
   */
  @Output() dismissClicked = new EventEmitter();
  /**
   * Emits when the back button is clicked.
   */
  @Output() backClicked = new EventEmitter();

  /** @internal */
  @ContentChild(ModalLayoutButtonsDirective, { static: false })
  _buttonsTemplate: ModalLayoutButtonsDirective;

  /** @internal */
  @ContentChild(ModalLayoutTitleDirective, { static: false })
  titleTemplate: ModalLayoutTitleDirective;

  /** @internal */
  @ContentChild(ModalLayoutSubTitleDirective, { static: false })
  subTitleTemplate: ModalLayoutSubTitleDirective;

  /** @internal */
  @ViewChild('modalContent', { read: ElementRef, static: true })
  modalContent: ElementRef<HTMLElement>;

  CignaIcon = CignaIcon;

  get buttonsTemplate() {
    return this._buttonsTemplate && this._buttonsTemplate.templateRef;
  }

  get hasTitle() {
    return !!(
      this.title ||
      this.titleTemplate ||
      this.subTitle ||
      this.subTitleTemplate
    );
  }

  get hasControls() {
    return !!(this.ok || this.alt || this._buttonsTemplate);
  }

  constructor(
    public modal: ModalComponent,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    modal.opened
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.focusModalContent());

    modal.closed.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      if (this._previousFocusedElement && this._previousFocusedElement.focus) {
        this._previousFocusedElement.focus();
      }
    });
  }

  /** @internal */
  ngAfterViewInit() {
    // modal layout rendering may be delayed (async)
    this.focusModalContent();
  }

  /** @internal */
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  okClick(event?: Event) {
    this.okClicked.next(event);
    this.modal.close(event);
  }

  altClick() {
    this.altClicked.next();
  }

  closeClick(event?: Event) {
    this.modal.close(event);
    this.closeClicked.next(event);
  }

  backClick(event?: Event) {
    this.backClicked.next(event);
  }

  dismissClick(event?: Event) {
    this.modal.close(event);
    this.modal.dismiss();
  }

  private focusModalContent() {
    if (
      this.modal.isOpened &&
      this.modalContent &&
      this.modalContent.nativeElement !== this._document.activeElement
    ) {
      this._previousFocusedElement = this._document.activeElement;

      this.modalContent.nativeElement.focus();
    }
  }
}
