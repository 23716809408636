import { Injectable } from '@angular/core';
import { authActions } from '@cigna/shared/angular/auth-data-access';
import {
  GetConversation,
  OmniConversationsFacade,
  conversationsQuery,
} from '@cigna/omni/conversations-state-data-access';
import { PollingDataAccessService, UserEvent } from '@cigna/omni/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, EMPTY, identity, timer } from 'rxjs';
import {
  catchError,
  distinct,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ChangeRegime,
  GetEvents,
  PauseEvents,
  PollingActionTypes,
  PollingConfigLoaded,
  ResumeEvents,
  UserEventReceived,
} from './polling.actions';
import { fromPolling } from './polling.selectors';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Injectable()
export class PollingEffects {
  changeRegime$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(
        ofType<PollingConfigLoaded>(PollingActionTypes.PollingConfigLoaded),
        filter(({ payload }) => payload.enabled),
      ),
      this.featureTogglesFacade
        .featuresEnabled(['useRightNowChat'])
        .pipe(filter((useRightNowChat) => !useRightNowChat)),
    ]).pipe(
      switchMap(() => this.store$.pipe(select(fromPolling.getPollingRegime))),
      distinctUntilChanged(),
      map((regime) => new ChangeRegime(regime)),
    ),
  );

  poll$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeRegime>(PollingActionTypes.ChangeRegime),
      switchMap(({ payload: { attempts, rate, keepalive } }) =>
        timer(0, rate).pipe(
          mapTo(keepalive),
          attempts ? take(attempts) : map(identity),
        ),
      ),
      map((keepalive) => new GetEvents(keepalive)),
    ),
  );

  userEventReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserEventReceived>(PollingActionTypes.UserEventReceived),
      map(({ userEvent }) => {
        switch (userEvent.type) {
          case 'conversation':
            return new GetConversation(
              userEvent.conversationChange.conversationId,
            );
          case 'virtual_assistant':
            return {
              type: '[Omni - Deprecated] Virtual Assistant Event Received',
            };
        }
      }),
    ),
  );

  resumePolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loadAuthSuccess),
      withLatestFrom(
        this.store$.pipe(select(fromPolling.isPollPaused)),
        this.store$.pipe(select(fromPolling.getPollingRegime)),
      ),
      filter(([_authSuccess, isPollPaused]) => !!isPollPaused),
      switchMap(([_authSuccess, _isPollPaused, regime]) => [
        new ResumeEvents(),
        new ChangeRegime(regime),
      ]),
    ),
  );

  stopPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.unauthorizedError),
      withLatestFrom(
        this.store$.pipe(select(conversationsQuery.getOpenLiveConvExists)),
      ),
      filter(([_, isLiveChat]) => !isLiveChat),
      map(() => new PauseEvents()),
    ),
  );

  getEvents$ = createEffect(
    () =>
      (dataAccessService = this.dataAccessService) =>
        this.actions$.pipe(
          ofType<GetEvents>(PollingActionTypes.GetEvents),
          withLatestFrom(
            this.conversationsFacade.conversationId$,
            this.conversationsFacade.conversationEntities$,
            this.store$.pipe(select(fromPolling.isPollPaused)),
          ),
          filter(
            ([{ keepalive }, conversationId, conversations, isPollPaused]) =>
              !isPollPaused,
          ),
          switchMap(
            ([{ keepalive }, conversationId, conversations, isPollPaused]) => {
              const convType = conversations[conversationId as string]?.type;
              return dataAccessService
                .getEvents(keepalive, convType)
                .pipe(catchError(() => EMPTY));
            },
          ),
          mergeMap((events: UserEvent[]) => events),
          distinct((event) => {
            switch (event.type) {
              case 'conversation':
                return event.conversationChange.conversationEventId;
              // case 'interception':
              //   return event.interception._id;
              case 'virtual_assistant':
                return event.virtualAssistantEvent.intentId;
              default:
                return true;
            }
          }),
          map((event) => new UserEventReceived(event)),
        ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private dataAccessService: PollingDataAccessService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private conversationsFacade: OmniConversationsFacade,
  ) {}
}
