import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { stringify } from 'qs';
import { SHARED_GATEWAY_CONFIG, GatewayConfig } from '../gateway.config';

@Injectable({ providedIn: 'root' })
export class UrlFactory {
  constructor(@Inject(SHARED_GATEWAY_CONFIG) private _config: GatewayConfig) {}

  createUrl<TParams>(
    gateway: keyof GatewayConfig,
    path: string,
    params?: Params<TParams>,
  ): string {
    return this.join(gateway, path, toQueryString(params));
  }

  createUrlWithTimestamp<TParams>(
    gateway: keyof GatewayConfig,
    path: string,
    params?: Params<TParams>,
  ): string {
    const ts = Date.now().toString();
    const queryString = toQueryString(params, false);

    return this.join(
      gateway,
      path,
      `?${[ts, queryString].filter(Boolean).join('&')}`,
    );
  }

  private join(gateway: keyof GatewayConfig, path: string, query: string) {
    const pathWithParams = Location.stripTrailingSlash(path) + query;
    return Location.joinWithSlash(this._config[gateway], pathWithParams);
  }
}

export type ParamType = string | string[] | number | boolean | undefined;

export type Params<TParams> = { [P in keyof TParams]: ParamType };

export function toQueryString<TParams>(
  params?: Params<TParams>,
  addQueryPrefix: boolean = true,
): string {
  if (!params) {
    return '';
  }

  return stringify(params, {
    addQueryPrefix,
    indices: false,
    skipNulls: true,
  });
}
