import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreWindowModule } from '@cigna/shared/angular/core/window-util';
import { OmniSharedUiModule } from '@cigna/omni/shared-util';
import { OmniChatStateModule } from '@cigna/omni/chat-state-data-access';
import { OmniLiveAvailabilityStateModule } from '@cigna/omni/live-availability-state-data-access';
import { EffectsModule } from '@ngrx/effects';
import { ChatBubbleContainerComponent } from './chat-bubble-container/chat-bubble-container.component';
import { ChatBubbleDataComponent } from './chat-bubble-data/chat-bubble-data.component';
import {
  ChatBubbleEffects,
  ChatBubbleFacade,
  ChatBubbleAnalyticsEffects,
} from './chat-bubble-state';
import { UiResponsiveModule } from '@cigna/shared/angular/responsive-ui';

@NgModule({
  imports: [
    CoreWindowModule,
    CommonModule,
    EffectsModule.forFeature([ChatBubbleEffects, ChatBubbleAnalyticsEffects]),
    OmniLiveAvailabilityStateModule,
    OmniChatStateModule,
    UiResponsiveModule,
    OmniSharedUiModule,
  ],
  declarations: [ChatBubbleContainerComponent, ChatBubbleDataComponent],
  exports: [ChatBubbleContainerComponent, ChatBubbleDataComponent],
  providers: [ChatBubbleFacade],
})
export class OmniChatBubbleModule {}
