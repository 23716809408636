import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import has from 'lodash/has';

import { ContentBundleFacade } from './+state/content-bundle.facade';
import { LoggingService } from '@cigna/shared/angular/logging-util';

/**
 * ContentResolver provides a way for the application to request resources bundles from CDN
 * in two ways:
 *
 * 1. Preloading that blocks navigation to the route until all bundles are loaded
 *
 * **Route Configuration:**
 * ```ts
 *  {
 *    resolve: {
 *      translate: ContentResolver,
 *    },
 *    data: {
 *      preloadContent: [ContentBundleName.A, ContentBundleName.B]
 *    }
 *  }
 * ```
 * 2. Lazy-loading that requests bundles but does not prevent the navigation to the route
 *
 * **Route Configuration:**
 * ```ts
 *  {
 *    resolve: {
 *      translate: ContentResolver,
 *    },
 *    data: {
 *      lazyLoadContent: [ContentBundleName.A, ContentBundleName.B]
 *    }
 *  }
 * ```
 */
@Injectable({ providedIn: 'root' })
export class ContentResolver {
  constructor(
    private _contentFacade: ContentBundleFacade,
    private _logger: LoggingService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string[]> {
    if (
      !has(route, 'data.preloadContent') &&
      !has(route, 'data.lazyLoadContent')
    ) {
      this._logger.warn(
        'Route was defined with ContentResolver, but data.preloadContent or data.lazyLoadContent is undefined.',
      );
      return EMPTY;
    }

    if (route.data.lazyLoadContent) {
      this._contentFacade.loadBundle(route.data.lazyLoadContent);
    }

    if (route.data.preloadContent) {
      return this._contentFacade.loadBundle(route.data.preloadContent);
    }

    return EMPTY;
  }
}
