import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CignaIcon } from '@cigna/shared/angular/icon-ui';

@Component({
  selector: 'cigna-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterViewInit {
  /**
   * Use the _activate_ parameter to force the accordion to be expanded or collapsed. Valid
   * values are `false` (default) for **collapsed** and `true` for **expanded**. Useful for
   * when the accordion can be expanded or collapsed without clicking the header.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() activate = false;

  /**
   * Use the _showArrow_ parameter to control if the caret icon is displayed. Valid values are
   * `true` (default) for **shown** and `false` for **hidden**.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() showArrow = true;

  /**
   * Use the _showRightArrow_ parameter to show the caret icon on the right side. Valid values are
   * `false` (default) for **hidden** and `true` for **shown**.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() showRightArrow = false;

  /**
   * Use the _showTopBorder_ parameter to control if a border line is shown above the header.
   * Valid values are `true` (default) for **shown** and `false` for **hidden**.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() showTopBorder = true;

  /**
   * Use the _isFocused_ boolean parameter to control whether the accordion header should be
   * focused on for accessibility purposes.
   */
  @Input() isFocused = false;

  /**
   * The _changeIsActivated_ output emits `true` when the accordion was expanded
   * (activated) and `false` when the accordion was collapsed.
   *
   * - **NOTE**: This _changeIsActivated_ event can be used to set the value that is passed to
   *   _activate_.
   */
  @Input() isToggleEnabled = true;

  /**
   * Use the _isPanelBlueBackgrd_ boolean parameter to control whether the accordion panel background
   * should be blue
   */
  @Input() isPanelBlueBackgrd? = false;

  /**
   * Use the shouldShowIconRightSide boolean parameter to display an activated and deactivated icon on the right side.
   */
  @Input() shouldShowIconRightSide = false;

  /**
   * Use the activatedIcon typed CignaIcon to customize icon used when accordion is activated.
   */
  @Input() activatedIcon: CignaIcon = CignaIcon.CaretDown;

  /**
   * Use the deactivateIcon typed CignaIcon to customize icon used when accordion is deactivated.
   */
  @Input() deactivatedIcon: CignaIcon = CignaIcon.CaretRight;

  @Output() changeIsActivated = new EventEmitter<boolean>();

  @ViewChild('button') button: ElementRef;

  ngAfterViewInit() {
    if (this.isFocused) {
      this.button.nativeElement.focus();
    }
  }

  toggleOpen() {
    if (!this.isToggleEnabled) {
      this.changeIsActivated.emit(this.activate);
    } else {
      this.activate = !this.activate;
      this.changeIsActivated.emit(this.activate);
    }
  }
}
