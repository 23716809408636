<div
  [@chatBubbleFlyInOut]
  *ngIf="showButton && !shouldShowNewBubble"
  class="conv-chat-bubble-wrapper"
>
  <div *cignaResponsive="let screen">
    <button
      (click)="onClick()"
      attr.aria-label="{{ chatBubbleFullText }}"
      class="conv-chat-bubble-button"
    >
      <cigna-icon-dynamic
        [colors]="iconColors"
        [data]="
          screen.isSmallerOrEqual('xs')
            ? chatBubbleData['xs']
            : chatBubbleData['md']
        "
        [icon]="icon"
      ></cigna-icon-dynamic>
    </button>
  </div>

  <div class="live-chat-utility" id="livechat-container">
    <span id="livechat-link">Live Chat</span>
  </div>
</div>

<div
  [@flyInOut]
  *ngIf="showButton && shouldShowNewBubble"
  class="conv-chat-bubble-wrapper"
>
  <div *cignaResponsive="let screen">
    <button
      (click)="onOpenClose()"
      (mouseenter)="
        isTerminatedUser || isLiveChatAvailable ? changeIconOnHover(true) : ''
      "
      (mouseleave)="
        isTerminatedUser || isLiveChatAvailable ? changeIconOnHover(false) : ''
      "
      attr.aria-label="{{ buttonAriaLable }}"
      class="conv-chat-bubble-button-expander"
      (focus)="
        isTerminatedUser || isLiveChatAvailable ? changeIconOnHover(true) : ''
      "
      (blur)="
        isTerminatedUser || isLiveChatAvailable ? changeIconOnHover(false) : ''
      "
    >
      <cigna-icon-collapsible-chat-bubble
        [colors]="iconColors"
        [data]="
          screen.isSmallerOrEqual('xs')
            ? chatBubbleData['xs']
            : chatBubbleData['md']
        "
        [canShellOpenOrMinimized]="isShellOpenOrMinimized"
        [shouldExpand]="isBubbleExpand"
        [hasShellMinimized]="isShellMinimized"
        [hasNewMessages]="hasNewMessages"
        [isLiveChatAvailability]="isLiveChatAvailable"
        [isTerminatedUser]="isTerminatedUser"
        [isDcmChat]="isDcmChat"
        [hasChatOutage]="hasChatOutage"
      >
      </cigna-icon-collapsible-chat-bubble>
    </button>
  </div>

  <div class="live-chat-utility" id="livechat-container">
    <span id="livechat-link">Live Chat</span>
  </div>
</div>
