import { Component, Input } from '@angular/core';
import { IconDynamicColors } from '../../interfaces/colors';
import { IconDynamicData, IconDirection } from '../../interfaces/data';

@Component({
  selector: 'cigna-icon-dynamic-direction-arrow',
  templateUrl: './direction-arrow-icon.component.html',
})
export class IconDynamicDirectionArrowComponent {
  private directionMap = new Map<IconDirection, string>([
    ['top', ''],
    ['right', 'rotate(90deg)'],
    ['bottom', 'rotate(180deg)'],
    ['left', 'rotate(270deg)'],
  ]);

  @Input() public colors: IconDynamicColors;
  @Input()
  public set data(payload: IconDynamicData) {
    if (!payload) {
      return;
    }

    if (payload.direction) {
      this.direction = this.directionMap.get(payload.direction);
    }
  }

  public direction = this.directionMap.get('top');
}
