<ng-template #modalTemplate>
  <div
    class="cigna-modal-backdrop"
    [ngClass]="options.backdropClass"
    (click)="backdropClick($event)"
  ></div>

  <div class="cigna-modal-container">
    <div
      class="cigna-modal-content"
      [ngClass]="options.class"
      role="dialog"
      [attr.aria-labelledby]="labelledBy"
      [attr.aria-describedby]="describedBy"
      cdkTrapFocus
    >
      <ng-container *ngIf="!modalContent; else modalContent?.templateRef">
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</ng-template>
