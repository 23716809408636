import {
  PreloadingStrategy,
  Route,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Entitlement, EntitlementLob, LOB } from '@cigna/chcp/auth/util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Feature } from '@cigna/chcp/shared/environment-util';

export interface RouteData {
  title?: string;
  breadcrumb?: string;
  analytics?: {
    name: string;
    section: string;
    prop13: string;
    eVar51?: string;
    eVar119?: string;
    eVar173?: string;
    loggedIn?: boolean;
    events?: string;
    /** if async is true analytics data will not be sent automatically on route change, you will need to fire manually from component */
    async?: boolean;
  };
  guards?: {
    entitlements?: Array<Entitlement | EntitlementLob>;
    lob?: LOB[];
    features?: Feature[];
  };
  routeCode?: string;
  bluebar?: boolean;
  disableNavigation?: boolean;
  preload?: boolean;
  lazyLoadContent?: string[];
  preloadContent?: string[];
  loadChatData?: boolean;
  noBottomPadding?: boolean;
}

export interface ChcpRoute extends Route {
  data?: RouteData;
  children?: ChcpRoute[];
}

export declare type ChcpRoutes = ChcpRoute[];

export function deepestActivatedRoute(router: Router): ActivatedRoute | null {
  let route = router.routerState.root.firstChild;

  if (route) {
    while (route.firstChild) {
      route = route.firstChild;
    }
  }

  return route;
}

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: ChcpRoute, load: any): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }
}
