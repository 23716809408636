<p class="title" data-test="lbl-title" *ngIf="title">{{ title }}</p>
<ng-container *ngIf="amount">
  <div style="display: flex" *ngIf="amount > met; else deductibleMet">
    <round-progress
      [current]="met"
      [stroke]="3"
      [radius]="15"
      [max]="amount"
      [color]="'#00a9e0'"
      [attr.title]="title"
    ></round-progress>
    <data
      data-test="lbl-amount"
      *ngIf="isValidNumber(remaining)"
      [ngClass]="{ 'bold-heading': fontType === 'heading' }"
      class="currency currency--{{ size }} cg-margin-left-sm"
      [value]="remaining"
    >
      <span class="pr-1" *ngIf="showNegativeIndicator">-</span>
      <span class="currency__dollar-sign currency--superscript">$</span>
      <span class="currency__dollars">{{ dollars | number }}</span>
      <span class="currency__cents currency--superscript">
        <span
          [ngClass]="{
            'sr-only':
              size === CurrencySize.large ||
              size === CurrencySize.extraLarge ||
              size === CurrencySize.medium
          }"
          >.</span
        >{{ cents | number : '2.' }}</span
      >
    </data>
  </div>

  <ng-template #deductibleMet>
    <div class="amount-met">
      <cigna-icon
        class="cg-icon-lg"
        [type]="showWarning ? 'exclamation-circle' : 'in-network-check'"
        [ngClass]="{ 'cg-color-warn': showWarning }"
      ></cigna-icon>
      <span [ngClass]="{ 'cg-color-success': !showWarning }"
        >{{ benefit }} Met</span
      >
    </div>
  </ng-template>
</ng-container>

<p class="footer">
  Total:
  <strong data-test="lbl-total">{{
    amount ? (amount | currency) : '--'
  }}</strong>
</p>
