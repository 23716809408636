import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CONTENT_BUNDLE_FEATURE_KEY,
  ContentBundleState,
} from './content-bundle.reducer';
import { Locale } from './content-bundle.config';

const getContentBundleState = createFeatureSelector<ContentBundleState>(
  CONTENT_BUNDLE_FEATURE_KEY,
);

const getCurrentLanguage = createSelector(
  getContentBundleState,
  (state: ContentBundleState) => state && state.currentLang,
);

const getManifest = createSelector(
  getContentBundleState,
  (state: ContentBundleState) => state && state.manifest,
);

const getLoaded = createSelector(
  getContentBundleState,
  (state: ContentBundleState, props: { lang: Locale }) =>
    state &&
    state.bundles[props.lang].filter((b) => !b.loading).map((b) => b.name),
);

const getLoading = createSelector(
  getContentBundleState,
  (state: ContentBundleState, props: { lang: Locale }) =>
    state &&
    state.bundles[props.lang].filter((b) => b.loading).map((b) => b.name),
);

const getCurrLoaded = createSelector(
  getContentBundleState,
  getCurrentLanguage,
  (state: ContentBundleState, lang: Locale) =>
    state && state.bundles[lang].filter((b) => !b.loading).map((b) => b.name),
);

const getCurrLoading = createSelector(
  getContentBundleState,
  getCurrentLanguage,
  (state: ContentBundleState, lang: Locale) =>
    state && state.bundles[lang].filter((b) => b.loading).map((b) => b.name),
);

const getRequestedFiles = createSelector(
  getContentBundleState,
  (state: ContentBundleState, props: { locale: Locale; name: string }) =>
    state.bundles[props.locale]
      .filter((b) => b.name === props.name)
      .map((b) => b.filename)
      .filter((f): f is NonNullable<typeof f> => !!f),
);

export const contentBundleQuery = {
  getLoaded,
  getLoading,
  getCurrentLanguage,
  getManifest,
  getCurrLoaded,
  getCurrLoading,
  getRequestedFiles,
};
