// eslint-disable-next-line max-classes-per-file
import { InterceptionDTO } from '@cigna/vampire-dto';
import { Action } from '@ngrx/store';

export enum InterceptionsActionTypes {
  AddMany = '[Interceptions] Add Many',
  AddOne = '[Interceptions] Add One',
  Dismiss = '[Interceptions] Dismiss',
  DismissSuccess = '[Interceptions] Dismiss Success',
  DismissFailure = '[Interceptions] Dismiss Failure',
  Engage = '[Interceptions] Engage',
  EngageClear = '[Interceptions] Engage Clear',
  EngageSuccess = '[Interceptions] Engage Success',
  EngageFailure = '[Interceptions] Engage Failure',
  Failure = '[Interceptions] Failure',
  Snooze = '[Interceptions] Snooze',
  SnoozeSuccess = '[Interceptions] Snooze Success',
  Viewed = '[Interceptions] Viewed',
  ViewedSuccess = '[Interceptions] Viewed Success',
}

export class InterceptionsAddMany implements Action {
  readonly type = InterceptionsActionTypes.AddMany;
  constructor(public payload: { interceptions: InterceptionDTO[] }) {}
}

export class InterceptionsAddOne implements Action {
  readonly type = InterceptionsActionTypes.AddOne;
  constructor(public payload: { interception: InterceptionDTO }) {}
}

export class InterceptionsDismiss implements Action {
  readonly type = InterceptionsActionTypes.Dismiss;
  constructor(public payload: { id: string }) {}
}

export class InterceptionsDismissSuccess implements Action {
  readonly type = InterceptionsActionTypes.DismissSuccess;
  constructor(public payload: { id: string }) {}
}

export class InterceptionsDismissFailure implements Action {
  readonly type = InterceptionsActionTypes.DismissFailure;
  constructor(public error: any) {}
}

export class InterceptionsEngage implements Action {
  readonly type = InterceptionsActionTypes.Engage;
  constructor(public payload: { interception: InterceptionDTO }) {}
}

export class InterceptionsEngageClear implements Action {
  readonly type = InterceptionsActionTypes.EngageClear;
}

export class InterceptionsEngageSuccess implements Action {
  readonly type = InterceptionsActionTypes.EngageSuccess;
  constructor(public payload: { id: string }) {}
}

export class InterceptionsEngageFailure implements Action {
  readonly type = InterceptionsActionTypes.EngageFailure;
  constructor(public error: any) {}
}

export class InterceptionsFailure implements Action {
  readonly type = InterceptionsActionTypes.Failure;
  constructor(public error: any) {}
}

export class InterceptionsSnooze implements Action {
  readonly type = InterceptionsActionTypes.Snooze;
  constructor(public payload: { id: string }) {}
}

export class InterceptionsSnoozeSuccess implements Action {
  readonly type = InterceptionsActionTypes.SnoozeSuccess;
  constructor(public payload: { id: string }) {}
}

export class InterceptionsViewed implements Action {
  readonly type = InterceptionsActionTypes.Viewed;
  constructor(public payload: { id: string }) {}
}

export class InterceptionsViewedSuccess implements Action {
  readonly type = InterceptionsActionTypes.ViewedSuccess;
  constructor(public payload: { id: string }) {}
}

export type InterceptionsActionsUnion =
  | InterceptionsAddMany
  | InterceptionsAddOne
  | InterceptionsDismiss
  | InterceptionsDismissFailure
  | InterceptionsDismissSuccess
  | InterceptionsEngage
  | InterceptionsEngageClear
  | InterceptionsEngageFailure
  | InterceptionsEngageSuccess
  | InterceptionsFailure
  | InterceptionsSnooze
  | InterceptionsSnoozeSuccess
  | InterceptionsViewed
  | InterceptionsViewedSuccess;

export const interceptionsActions = {
  InterceptionsAddMany,
  InterceptionsAddOne,
  InterceptionsDismiss,
  InterceptionsDismissFailure,
  InterceptionsDismissSuccess,
  InterceptionsEngage,
  InterceptionsEngageClear,
  InterceptionsEngageFailure,
  InterceptionsEngageSuccess,
  InterceptionsFailure,
  InterceptionsSnooze,
  InterceptionsSnoozeSuccess,
  InterceptionsViewed,
  InterceptionsViewedSuccess,
};
