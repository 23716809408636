import { Component, Input } from '@angular/core';
import { differenceInDays, addDays, format, isDate } from 'date-fns';

@Component({
  selector: 'cigna-password-change-banner',
  templateUrl: './password-change-banner.component.html',
  styleUrls: ['./password-change-banner.component.scss'],
})
export class PasswordChangeBannerComponent {
  @Input() lastChangedDate = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() dismissed = false;

  get isValidDate() {
    return (
      this.lastChangedDate !== '' && isDate(new Date(this.lastChangedDate))
    );
  }

  get daysLeft() {
    return this.isValidDate
      ? differenceInDays(
          addDays(new Date(this.lastChangedDate), 120),
          new Date(),
        )
      : 0;
  }

  get lockoutDate() {
    return this.isValidDate
      ? format(addDays(new Date(this.lastChangedDate), 120), 'MM/dd/yyyy')
      : '';
  }
}
