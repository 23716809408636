import { createReducer, on, Action } from '@ngrx/store';
import {
  loadContentBundle,
  contentBundleLoaded,
  languageChanged,
  manifestLoaded,
  contentBundleLoadError,
  resetContentBundle,
} from './content-bundle.actions';
import { Locale, DEFAULT_LANG, Manifest } from './content-bundle.config';

export const CONTENT_BUNDLE_FEATURE_KEY = 'content-bundle';

interface Bundle {
  name: string;
  filename?: string;
  loading: boolean;
}

export interface ContentBundleState {
  currentLang: Locale;
  manifest?: Manifest;
  bundles: { [key in Locale]: Bundle[] };
}

export interface ContentBundlePartialState {
  readonly [CONTENT_BUNDLE_FEATURE_KEY]: ContentBundleState;
}

export const initialState: ContentBundleState = {
  currentLang: DEFAULT_LANG,
  bundles: {
    'en-us': [],
    'es-us': [],
    'zh-cn': [],
  },
};

export const contentBundleReducer = createReducer(
  initialState,
  on(loadContentBundle, (state, { name, filename, lang }) => {
    // if file has been requested already, skip it
    // loadContentBundle effect ignores it anyway, and loading=true puts content resolver into deadlock
    if (state.bundles[lang].some((b) => b.filename === filename)) {
      return state;
    }
    return {
      ...state,
      bundles: {
        ...state.bundles,
        [lang]: [...state.bundles[lang], { name, filename, loading: true }],
      },
    };
  }),
  on(resetContentBundle, (state) => ({
    ...state,
    bundles: {
      'en-us': [],
      'es-us': [],
      'zh-cn': [],
    },
  })),
  on(
    contentBundleLoaded,
    contentBundleLoadError,
    (state, { name, filename, lang }) => ({
      ...state,
      bundles: {
        ...state.bundles,
        [lang]: [
          ...state.bundles[lang].filter(
            (b) => b.name !== name || b.filename !== filename,
          ),
          { name, filename, loading: false },
        ],
      },
    }),
  ),
  on(languageChanged, (state, { lang }) => ({ ...state, currentLang: lang })),
  on(manifestLoaded, (state, { manifest }) => ({ ...state, manifest })),
);

export function reducer(state: ContentBundleState | undefined, action: Action) {
  return contentBundleReducer(state, action);
}
