import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LobPref } from '@cigna/chcp/auth/util';

interface LobPrefOption {
  value: LobPref;
  name: string;
}

@Component({
  selector: 'cigna-lob-preference-delete-dialog',
  templateUrl: './lob-preference-dialog.component.html',
  styleUrls: ['./lob-preference-dialog.component.scss'],
})
export class LobPreferenceDialogComponent {
  lobPref: LobPref;
  lobPrefOptions: LobPrefOption[] = [
    { value: 'BOTH', name: 'Medical/Behavioral' },
    { value: 'CHCP', name: 'Medical' },
    { value: 'EBH', name: 'Behavioral' },
  ];

  constructor(
    public dialogRef: MatDialogRef<
      LobPreferenceDialogComponent,
      { lobPref: LobPref } | void
    >,
  ) {}
}
