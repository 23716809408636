import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import warningFilled from '@esi/leaf-icons/lib/icons/System/Filled/Warning.svg';
import account from '@esi/leaf-icons/lib/icons/System/Regular/Account.svg';
import download from '@esi/leaf-icons/lib/icons/System/Regular/Download.svg';
import edit from '@esi/leaf-icons/lib/icons/System/Regular/Edit.svg';
import refresh from '@esi/leaf-icons/lib/icons/System/Regular/Refresh.svg';
import visibilityFalse from '@esi/leaf-icons/lib/icons/System/Regular/Visibility False.svg';
import visibilityTrue from '@esi/leaf-icons/lib/icons/System/Regular/Visibility True.svg';
import warning from '@esi/leaf-icons/lib/icons/System/Regular/Warning.svg';
import flagFilled from '@phosphor-icons/core/assets/fill/flag-fill.svg';
import flagRegular from '@phosphor-icons/core/assets/regular/flag.svg';
import favoriteEmpty from '@esi/leaf-icons/lib/icons/System/Regular/Favorite/Empty.svg';
import favoriteFull from '@esi/leaf-icons/lib/icons/System/Filled/Favorite/Full.svg';
import downloadSimple from '@phosphor-icons/core/assets/regular/download-simple.svg';
import error from '@esi/leaf-icons/lib/icons/System/Regular/Error.svg';
import errorFilled from '@esi/leaf-icons/lib/icons/System/Filled/Error.svg';
import success from '@esi/leaf-icons/lib/icons/System/Regular/Success.svg';
import info from '@esi/leaf-icons/lib/icons/System/Regular/Info.svg';
import infoFilled from '@esi/leaf-icons/lib/icons/System/Filled/Info.svg';
import externalLink from '@esi/leaf-icons/lib/icons/System/Regular/External Link.svg';
import help from '@esi/leaf-icons/lib/icons/System/Regular/Help.svg';
import search from '@esi/leaf-icons/lib/icons/System/Regular/Search.svg';
import print from '@esi/leaf-icons/lib/icons/System/Regular/Print.svg';
import calendar from '@esi/leaf-icons/lib/icons/System/Regular/Calendar.svg';
import trash from '@esi/leaf-icons/lib/icons/System/Regular/Trash.svg';

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
})
export class ChcpSharedLeafIconsUiModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-visibility-true',
      sanitizer.bypassSecurityTrustHtml(visibilityTrue as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-visibility-false',
      sanitizer.bypassSecurityTrustHtml(visibilityFalse as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-warning',
      sanitizer.bypassSecurityTrustHtml(warning as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-filled-warning',
      sanitizer.bypassSecurityTrustHtml(warningFilled as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-account',
      sanitizer.bypassSecurityTrustHtml(account as string),
    );
    iconRegistry.addSvgIconLiteral(
      'phosphor-regular-flag',
      sanitizer.bypassSecurityTrustHtml(flagRegular as string),
    );
    iconRegistry.addSvgIconLiteral(
      'phosphor-fill-flag',
      sanitizer.bypassSecurityTrustHtml(flagFilled as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-error',
      sanitizer.bypassSecurityTrustHtml(error as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-filled-error',
      sanitizer.bypassSecurityTrustHtml(errorFilled as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-success',
      sanitizer.bypassSecurityTrustHtml(success as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-info',
      sanitizer.bypassSecurityTrustHtml(info as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-filled-info',
      sanitizer.bypassSecurityTrustHtml(infoFilled as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-external-link',
      sanitizer.bypassSecurityTrustHtml(externalLink as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-help',
      sanitizer.bypassSecurityTrustHtml(help as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-search',
      sanitizer.bypassSecurityTrustHtml(search as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-print',
      sanitizer.bypassSecurityTrustHtml(print as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-calendar',
      sanitizer.bypassSecurityTrustHtml(calendar as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-download',
      sanitizer.bypassSecurityTrustHtml(download as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-refresh',
      sanitizer.bypassSecurityTrustHtml(refresh as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-edit',
      sanitizer.bypassSecurityTrustHtml(edit as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-favorite-empty',
      sanitizer.bypassSecurityTrustHtml(favoriteEmpty as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-favorite-full',
      sanitizer.bypassSecurityTrustHtml(favoriteFull as string),
    );
    iconRegistry.addSvgIconLiteral(
      'phosphor-regular-download-simple',
      sanitizer.bypassSecurityTrustHtml(downloadSimple as string),
    );
    iconRegistry.addSvgIconLiteral(
      'leaf-system-regular-trash',
      sanitizer.bypassSecurityTrustHtml(trash as string),
    );
  }
}
