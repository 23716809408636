// TODO: resolve CHCP disables by bot
/* eslint-disable sonarjs/no-identical-functions */
import { Action, createReducer, on } from '@ngrx/store';
import {
  getAllWamTins,
  getAllWamTinsError,
  getAllWamTinsSuccess,
  getMfaTinDetailsByWam,
  getMfaTinDetailsByWamError,
  getMfaTinDetailsByWamSuccess,
  isamResendCode,
  isamResendCodeSuccess,
  isamSendCode,
  isamSendCodeSuccess,
  isamStepup,
  isamStepupError,
  isamStepupSuccessMultipleContact,
  isamStepupSuccessSingleContact,
  isamVerifyCode,
  isamVerifyCodeInputError,
  isamVerifyCodeRetryLimitError,
  isamVerifyCodeServiceError,
  loadUserProfile,
  putMfaTinDetails,
  putMfaTinDetailsError,
  putMfaTinDetailsSuccess,
  resetWamState,
  sendContactVerificationCode,
  sendContactVerificationCodeError,
  sendContactVerificationCodeSuccess,
  storeMfaWamUpdate,
  storeWamSelectedTins,
  verifyContactCode,
  verifyContactCodeError,
  verifyContactCodeSuccess,
} from './mfa.actions';

export const MFA_FEATURE_KEY = 'mfa';

import { ServiceError, Tin } from '@cigna/chcp/shared/util';
import { ContactMethod, MfaTinDetailsByWam } from '../mfa/mfa.model';

export interface Mfa {
  submitting: boolean;
  serviceError: ServiceError | null;
  updatedEmail: string;
  updatedPhone: string;
  isam: {
    stateId: string;
    otpHint: string;
    contacts: ContactMethod[];
    selectedContact: ContactMethod | null;
    errorMessage: string;
    submitting: boolean;
    retryExceeded: boolean;
    isMobileOnlyMfa: boolean;
  };
  wamTins: Tin[];
  mfaTinDetails: MfaTinDetailsByWam[];
  wamSelectedTins: string[];
  mfaTinDetailsUpdated: MfaTinDetailsByWam[];
}

export interface MfaPartialState {
  readonly [MFA_FEATURE_KEY]: Mfa;
}

export const initialState: Mfa = {
  submitting: false,
  serviceError: null,
  updatedEmail: '',
  updatedPhone: '',
  isam: {
    stateId: '',
    otpHint: '',
    contacts: [],
    selectedContact: null,
    errorMessage: '',
    submitting: false,
    retryExceeded: false,
    isMobileOnlyMfa: false,
  },
  wamTins: [],
  mfaTinDetails: [],
  wamSelectedTins: [],
  mfaTinDetailsUpdated: [],
};

export const mfaReducer = createReducer(
  initialState,
  on(isamStepup, (state) => ({
    ...state,
    isam: {
      ...state.isam,
      errorMessage: '',
      submitting: true,
      isMobileOnlyMfa: false,
    },
  })),
  on(isamStepupSuccessSingleContact, (state, { data }) => ({
    ...state,
    isam: {
      ...state.isam,
      ...data,
      selectedContact: data.contacts[0],
      errorMessage: '',
      submitting: false,
    },
  })),
  on(isamStepupSuccessMultipleContact, (state, { isMobileOnlyMfa, data }) => ({
    ...state,
    isam: {
      ...state.isam,
      stateId: data.stateId,
      contacts: data.contacts,
      errorMessage: '',
      submitting: false,
      isMobileOnlyMfa,
    },
  })),
  on(isamStepupError, (state, { errorMessage }) => ({
    ...state,
    isam: {
      ...state.isam,
      errorMessage,
      submitting: false,
    },
  })),
  on(isamSendCode, (state, { selectedContact }) => ({
    ...state,
    isam: {
      ...state.isam,
      selectedContact,
      errorMessage: '',
      submitting: true,
    },
  })),
  on(isamVerifyCode, isamResendCode, (state) => ({
    ...state,
    isam: {
      ...state.isam,
      errorMessage: '',
      submitting: true,
    },
  })),
  on(
    isamResendCodeSuccess,
    isamSendCodeSuccess,
    (state, { otpHint, stateId }) => ({
      ...state,
      isam: {
        ...state.isam,
        otpHint,
        stateId,
        submitting: false,
        retryExceeded: false,
      },
    }),
  ),
  on(isamVerifyCodeInputError, (state, { stateId, errorMessage }) => ({
    ...state,
    isam: {
      ...state.isam,
      stateId,
      errorMessage,
      submitting: false,
    },
  })),
  on(isamVerifyCodeRetryLimitError, (state) => ({
    ...state,
    isam: {
      ...state.isam,
      submitting: false,
    },
  })),
  on(isamVerifyCodeServiceError, (state, { errorMessage }) => ({
    ...state,
    isam: {
      ...state.isam,
      errorMessage,
      submitting: false,
    },
  })),
  on(sendContactVerificationCode, (state, { payload }) => ({
    ...state,
    submitting: true,
    serviceError: null,
    updatedEmail: payload.email || '',
    updatedPhone: payload.mobileNumber || '',
  })),
  on(sendContactVerificationCodeSuccess, (state) => ({
    ...state,
    submitting: false,
  })),
  on(sendContactVerificationCodeError, (state, { error }) => ({
    ...state,
    submitting: false,
    serviceError: error,
  })),
  on(verifyContactCode, (state) => ({
    ...state,
    submitting: true,
    serviceError: null,
  })),
  on(verifyContactCodeSuccess, (state) => ({
    ...state,
    submitting: false,
  })),
  on(verifyContactCodeError, (state, { error }) => ({
    ...state,
    submitting: false,
    serviceError: error,
  })),
  on(getMfaTinDetailsByWam, (state) => ({
    ...state,
    submitting: true,
    serviceError: null,
  })),
  on(getMfaTinDetailsByWamSuccess, (state, { mfaTinDetails }) => ({
    ...state,
    mfaTinDetails,
    submitting: false,
  })),
  on(getMfaTinDetailsByWamError, (state, { error }) => ({
    ...state,
    submitting: false,
    serviceError: error,
  })),
  on(putMfaTinDetails, (state, _) => ({
    ...state,
    submitting: true,
    serviceError: null,
  })),
  on(putMfaTinDetailsSuccess, (state) => ({
    ...state,
    submitting: false,
  })),
  on(putMfaTinDetailsError, (state, { error }) => ({
    ...state,
    submitting: false,
    serviceError: error,
  })),
  on(getAllWamTins, (state) => ({
    ...state,
    submitting: true,
    serviceError: null,
  })),
  on(getAllWamTinsSuccess, (state, { wamTins }) => ({
    ...state,
    mfaTinDetails: wamTins as MfaTinDetailsByWam[],
    submitting: false,
  })),
  on(getAllWamTinsError, (state, { error }) => ({
    ...state,
    submitting: false,
    serviceError: error,
  })),
  on(storeWamSelectedTins, (state, { wamSelectedTins }) => ({
    ...state,
    wamSelectedTins,
  })),
  on(storeMfaWamUpdate, (state, { mfaTinDetails }) => ({
    ...state,
    mfaTinDetailsUpdated: mfaTinDetails,
  })),
  on(resetWamState, (state) => ({
    ...state,
    wamTins: [],
    mfaTinDetails: [],
    wamSelectedTins: [],
    mfaTinDetailsUpdated: [],
  })),
  on(loadUserProfile, (state) => ({
    ...state,
    serviceError: null,
  })),
);

export function reducer(state: Mfa | undefined, action: Action) {
  return mfaReducer(state, action);
}
