import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavigationLink, ServiceError } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() userid = '';
  @Input() profileLinks: NavigationLink[] = [];
  @Input() profileNavError?: ServiceError;

  @Input() isImpersonator = false;
  @Input() isAuthenticated = false;
  @Input() isNavigationDisabled = false;
  @Input() isResourceLibraryApp = false;
  @Input() isResourceResults = false;
  @Input() isLogoLinkInternal = true;
  @Input() logoLink = '';
  @Input() hasMessageCenterFunction = false;

  @Output() logOut = new EventEmitter();
}
