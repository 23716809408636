import { Component, Input, OnChanges, Type, ViewChild } from '@angular/core';
import { IconDynamicDirective } from '../icon.directive';
import { IconDynamicColors } from '../interfaces/colors';
import { IconDynamicData } from '../interfaces/data';
import { IconsDynamic } from '../icons';

@Component({
  selector: 'cigna-icon-dynamic',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconDynamicComponent implements OnChanges {
  @Input() public icon: Type<IconsDynamic>;
  @Input() public colors: IconDynamicColors;
  @Input() public data: IconDynamicData;
  @ViewChild(IconDynamicDirective, { static: true })
  public iconDirective: IconDynamicDirective;

  public ngOnChanges() {
    this.iconDirective.viewContainerRef.clear();
    const componentRef = this.iconDirective.viewContainerRef.createComponent(
      this.icon,
    );

    componentRef.instance.colors = this.colors;
    componentRef.instance.data = this.data;
  }
}
