import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { OmniAnalyticsService } from '@cigna/omni/shared-util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import {
  InterceptionsActionTypes,
  InterceptionsDismissFailure,
  InterceptionsEngageFailure,
  InterceptionsFailure,
} from './interceptions.actions';

@Injectable()
export class InterceptionsAnalyticsEffects {
  interceptionsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<
          | InterceptionsEngageFailure
          | InterceptionsDismissFailure
          | InterceptionsFailure
        >(
          InterceptionsActionTypes.EngageFailure,
          InterceptionsActionTypes.DismissFailure,
          InterceptionsActionTypes.Failure,
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(([action]) => {
          this.analytics.track('chatError', {
            serviceName: 'Interceptions Service',
            error: action.error,
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private analytics: OmniAnalyticsService,
    private featureToggleFacade: FeatureTogglesFacade,
    public actions$: Actions,
  ) {}
}
