<div class="afterHours">
  <div class="closed">
    <span>Live chat is closed.</span>
  </div>
  <div class="hours">
    Chat Hours:
    <ng-container *ngIf="!!chatCloseHrsMsg">
      <ng-container *ngFor="let msg of chatCloseHrsMsg">
        <p [innerHTML]="msg"></p>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!chatCloseHrsMsg">
      <span>Mon - Fri, 9am - 8pm ET</span>
    </ng-container>
  </div>
  <div class="return">Please return during chat hours.</div>
  <div class="answer">We'll be happy to answer your questions then.</div>
</div>
