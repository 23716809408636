import { Component, Inject } from '@angular/core';
import {
  ALL_ENTITLEMENTS,
  LOB_BEHAVIORAL,
  LOB_DENTAL,
  LOB_MEDBH,
  LOB_MEDICAL,
} from '@cigna/chcp/auth/util';
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';
import { AppContext } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-dev-toggle',
  templateUrl: './dev-toggle.component.html',
  styleUrls: ['./dev-toggle.component.scss'],
})
export class DevToggleComponent {
  lob: string;

  allPermissions: string[] = [];
  allEntitlements: string[] = [];

  impersonator = localStorage.getItem('chcpDevUserImpersonation') || '';
  enabledPermissions: string[] = [];
  enabledEntitlements: string[] = [];

  collapsed = localStorage.getItem('chcpDevToggleCollapsed') || 'n';
  LOB_MEDICAL = LOB_MEDICAL;
  LOB_DENTAL = LOB_DENTAL;
  LOB_MEDBH = LOB_MEDBH;
  LOB_BEHAVIORAL = LOB_BEHAVIORAL;

  constructor(
    @Inject('permissionToggleList') permissionToggleList: string[],
    @Inject(APP_CONTEXT) public appContext: AppContext,
  ) {
    const lobLocal = localStorage.getItem('chcpDevUserType');

    if (
      this.appContext === 'chcp' &&
      lobLocal &&
      [LOB_MEDBH, LOB_MEDICAL, LOB_DENTAL].includes(lobLocal)
    ) {
      this.lob = lobLocal;
    } else if (
      this.appContext === 'evernorth' &&
      lobLocal &&
      [LOB_MEDBH, LOB_BEHAVIORAL].includes(lobLocal)
    ) {
      this.lob = lobLocal;
    } else {
      this.lob = LOB_MEDBH;
    }

    this.allPermissions = permissionToggleList;
    this.enabledPermissions =
      localStorage.getItem('chcpDevUserPermissions')?.split(',') ||
      permissionToggleList;

    this.allEntitlements = ALL_ENTITLEMENTS;

    this.enabledEntitlements =
      localStorage.getItem(`chcpDevUserEntitlements-${this.lob}`)?.split(',') ||
      this.allEntitlements;
  }

  toggleCollapsed() {
    this.collapsed = this.collapsed === 'y' ? 'n' : 'y';
    localStorage.setItem('chcpDevToggleCollapsed', this.collapsed);
  }

  setUserDevMode(lob: string) {
    localStorage.setItem('chcpDevUserType', lob);
    this.lob = lob;
    window.location.reload();
  }

  setUserDevImpersonation(mode: string) {
    localStorage.setItem('chcpDevUserImpersonation', mode);
    this.impersonator = mode;
    window.location.reload();
  }

  setUserPermissions(permission: string, enabled: boolean) {
    const newPermissions = enabled
      ? this.enabledPermissions.concat(permission)
      : this.enabledPermissions.filter((p) => p !== permission);
    localStorage.setItem('chcpDevUserPermissions', newPermissions.join(','));
    window.location.reload();
  }

  setUserEntitlements(entitlement: string, enabled: boolean) {
    const newPermissions = enabled
      ? this.enabledEntitlements.concat(entitlement)
      : this.enabledEntitlements.filter((p) => p !== entitlement);
    localStorage.setItem(
      `chcpDevUserEntitlements-${this.lob}`,
      newPermissions.join(','),
    );
    window.location.reload();
  }
}
