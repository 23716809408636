import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { LOB_BEHAVIORAL, LOB_MEDBH, LOB_MEDICAL } from '@cigna/chcp/auth/util';

import { Tin } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-select-tins-group',
  templateUrl: './select-tins-group.component.html',
  styleUrls: ['./select-tins-group.component.scss'],
})
export class SelectTinsGroupComponent {
  @Input() allTins: Tin[];
  @Input() currentTins: string[] = [];
  @Input() columns = 4;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() showDetailLink = false;
  @Input() detailLinkText = '';
  @Input() maxSelectedTins = 100000;

  @Output() selectionsChange = new EventEmitter();
  @Output() detailLinkClick = new EventEmitter();

  @ViewChildren('tinInput', { read: ElementRef })
  private tinInputs: QueryList<ElementRef>;

  LOB_MEDBH = LOB_MEDBH;
  LOB_MEDICAL = LOB_MEDICAL;
  LOB_BEHAVIORAL = LOB_BEHAVIORAL;

  activeTin(tin: string): boolean {
    return this.currentTins.includes(tin);
  }

  selectionsChanged() {
    const selections = this.tinInputs
      .filter((cb) => cb.nativeElement.checked)
      .map((cb) => cb.nativeElement.value)
      .map((val) => this.allTins.find((tin) => tin.tin === val))
      .map((tin) => ({
        tin: tin?.tin,
        lob: tin?.lob,
      }));
    this.selectionsChange.emit(selections);
  }

  checkAll() {
    this.tinInputs.map((cb) => {
      cb.nativeElement.checked = true;
    });
    const allValues = this.allTins.map((tin) => ({
      tin: tin.tin,
      lob: tin.lob,
    }));
    this.selectionsChange.emit(allValues);
  }

  uncheckAll() {
    this.tinInputs.map((cb) => {
      cb.nativeElement.checked = false;
    });
    this.selectionsChange.emit([]);
  }
}
