<cigna-icon-dynamic
  *ngIf="icon"
  [icon]="icon"
  [colors]="iconColors"
  [data]="iconData"
  class="omni-comm-channel-content-with-icon"
  [attr.data-omni-qa]="omniQaTag"
></cigna-icon-dynamic>
<div class="omni-comm-channel-content-copy">
  <ng-content></ng-content>
</div>
