import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { AccordionComponent } from './accordion/accordion.component';

@NgModule({
  imports: [CommonModule, UiIconModule],
  declarations: [AccordionComponent],
  exports: [AccordionComponent],
})
export class UiAccordionModule {}
