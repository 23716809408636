import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutSecureComponent } from './layout-secure/layout-secure.component';
import { LayoutPublicComponent } from './layout-public/layout-public.component';
import { LayoutPageNotFoundComponent } from './layout-page-not-found/layout-page-not-found.component';

import {
  ChcpShellUiModule,
  UnavailableComponent,
  NotFoundComponent,
  DisabledPageComponent,
} from '@cigna/chcp/shell/ui';
import { ChcpSharedUserProfileDataAccessModule } from '@cigna/chcp/shared/user-profile-data-access';
import { ChcpSharedUserOptionsDataAccessModule } from '@cigna/chcp/shared/user-options-data-access';
import { ChcpSharedEnvironmentUtilModule } from '@cigna/chcp/shared/environment-util';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LetDirective } from '@ngrx/component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OmniChatLinkModule } from '@cigna/omni/chat-link-feature';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OmniEntryModule } from '@cigna/omni/entry-feature';

import {
  ShellEffects,
  SHELL_FEATURE_KEY,
  reducer,
  ShellFacade,
} from '@cigna/chcp/shell/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  DisabledPageGuard,
  FeatureToggleGuard,
} from '@cigna/chcp/shared/features-util';
import { ChcpRoutes, CustomPreloadingStrategy } from '@cigna/chcp/shared/util';
import { EntitlementGuard } from '@cigna/chcp/auth/data-access';
import {
  Entitlement,
  LOB_DENTAL,
  LOB_MEDBH,
  LOB_MEDICAL,
} from '@cigna/chcp/auth/util';
import { LayoutWrapperComponent } from './layout-wrapper/layout-wrapper.component';
import {
  SharedTimeoutPopupFeatureModule,
  TimeoutPopupConfig,
  timeoutPopupProviders,
} from '@cigna/shared/angular/timeout-popup-feature';

import {
  ContentBundleName,
  manifestAccessorFactory,
} from '@cigna/chcp/shared/content-bundle-util';

import {
  ContentResolver,
  ResourceContentDataAccessModule,
} from '@cigna/shared/angular/resource-content-data-access';
import { LoggingService } from '@cigna/shared/angular/logging-util';
import { ChatResolver } from './chat-resolver';

export const routes: ChcpRoutes = [
  {
    path: '',
    component: LayoutWrapperComponent,
    children: [
      {
        path: '',
        component: LayoutPublicComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'login',
          },
          {
            path: 'login',
            loadChildren: () =>
              import('@cigna/chcp/login/shell-feature').then(
                (m) => m.ChcpLoginShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preloadContent: [ContentBundleName.LOGIN],
            },
          },
          {
            path: 'registration',
            loadChildren: () =>
              import('@cigna/chcp/registration-feature').then(
                (m) => m.ChcpRegistrationModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preloadContent: [ContentBundleName.REGISTRATION],
            },
          },
          {
            path: 'identity',
            loadChildren: () =>
              import('@cigna/chcp/forgot-identity-feature').then(
                (m) => m.ChcpForgotIdentityModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preloadContent: [ContentBundleName.FORGOT_IDENTITY],
            },
          },
          {
            path: 'mfa',
            loadChildren: () =>
              import('@cigna/chcp/mfa/shell-feature').then(
                (m) => m.ChcpMfaShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preloadContent: [ContentBundleName.MFA],
            },
          },
          {
            path: 'agreement',
            loadChildren: () =>
              import('@cigna/chcp/agreement/shell-feature').then(
                (m) => m.ChcpAgreementShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preloadContent: [ContentBundleName.USER_AGREEMENT],
            },
          },
          {
            path: 'resources/results/public',
            // eslint-disable-next-line sonarjs/no-identical-functions
            loadChildren: () =>
              import('@cigna/chcp/resources/search-feature').then(
                (m) => m.ChcpResourcesSearchFeatureModule,
              ),
            data: {
              routeCode: 'resources',
            },
          },
        ],
      },
      {
        path: '',
        component: LayoutSecureComponent,
        canActivateChild: [DisabledPageGuard],
        children: [
          {
            path: 'dashboard',
            loadChildren: () =>
              import('@cigna/chcp/dashboard/shell-feature').then(
                (m) => m.ChcpDashboardShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preload: true,
              preloadContent: [
                ContentBundleName.DASHBOARD,
                ContentBundleName.SHARED,
              ],
            },
          },
          {
            path: 'preferences',
            loadChildren: () =>
              import('@cigna/chcp/preferences/shell-feature').then(
                (m) => m.ChcpPreferencesShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preloadContent: [
                ContentBundleName.SETTINGS,
                ContentBundleName.SHARED,
              ],
            },
          },
          {
            path: 'claim',
            loadChildren: () =>
              import('@cigna/chcp/claims/shell-feature').then(
                (m) => m.ChcpClaimsShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
              chat: ChatResolver,
            },
            data: {
              preload: true,
              guards: {
                entitlements: [Entitlement.ClaimsSearch],
              },
              preloadContent: [
                ContentBundleName.CLAIMS,
                ContentBundleName.APPEAL,
                ContentBundleName.SHARED,
              ],
            },
            canActivate: [EntitlementGuard],
          },
          {
            path: 'patient',
            loadChildren: () =>
              import('@cigna/chcp/patient/shell-feature').then(
                (m) => m.ChcpPatientShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preload: true,
              guards: {
                entitlements: [
                  Entitlement.PatientSearch,
                  Entitlement.MedicareView,
                  Entitlement.MedicareSubmit,
                ],
              },
              preloadContent: [
                ContentBundleName.PATIENT,
                ContentBundleName.APPEAL,
                ContentBundleName.SHARED,
                ContentBundleName.DRUGLISTPDF,
                ContentBundleName.DRUGLISTPDL,
              ],
            },
            canActivate: [EntitlementGuard],
          },
          {
            path: 'report',
            loadChildren: () =>
              import('@cigna/chcp/reports/shell-feature').then(
                (m) => m.ChcpReportsShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preloadContent: [ContentBundleName.REPORTS],
            },
          },
          {
            path: 'working-with-cigna',
            redirectTo: 'my-practice',
          },
          {
            path: 'my-practice',
            loadChildren: () =>
              import('@cigna/chcp/working-with-cigna/shell-feature').then(
                (m) => m.ChcpWorkingWithCignaShellFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              preloadContent: [ContentBundleName.WORKING_WITH_CIGNA],
            },
          },
          {
            path: 'resources/results/secure',
            // eslint-disable-next-line sonarjs/no-identical-functions
            loadChildren: () =>
              import('@cigna/chcp/resources/search-feature').then(
                (m) => m.ChcpResourcesSearchFeatureModule,
              ),
            data: {
              disableNavigation: true,
              routeCode: 'resources',
            },
          },
          {
            path: 'message-center',
            loadChildren: () =>
              import('@cigna/chcp/message-center-feature').then(
                (m) => m.ChcpMessageCenterModule,
              ),
            data: {
              guards: {
                entitlements: [
                  {
                    entitlement: Entitlement.ClaimsSearch,
                    lobs: [LOB_MEDBH, LOB_MEDICAL, LOB_DENTAL],
                  },
                  {
                    entitlement: Entitlement.Precertification,
                    lobs: [LOB_MEDBH, LOB_MEDICAL, LOB_DENTAL],
                  },
                ],
              },
            },
            canActivate: [EntitlementGuard, FeatureToggleGuard],
          },
          {
            path: 'precertification',
            loadChildren: () =>
              import(
                '@cigna/chcp/precertification/precert-three-sixty-feature'
              ).then(
                (m) => m.ChcpPrecertificationPrecertThreeSixtyFeatureModule,
              ),
            resolve: {
              translation: ContentResolver,
            },
            data: {
              guards: {
                entitlements: [
                  {
                    entitlement: Entitlement.ClaimsSearch,
                    lobs: [LOB_MEDBH, LOB_MEDICAL],
                  },
                  {
                    entitlement: Entitlement.PatientSearch,
                    lobs: [LOB_MEDBH, LOB_MEDICAL],
                  },
                  {
                    entitlement: Entitlement.Precertification,
                    lobs: [LOB_MEDBH, LOB_MEDICAL],
                  },
                ],
              },
              preloadContent: [
                ContentBundleName.PRECERTIFICATION,
                ContentBundleName.PATIENT,
                ContentBundleName.APPEAL,
                ContentBundleName.SHARED,
              ],
            },
            canActivate: [EntitlementGuard],
          },
          {
            path: 'unavailable',
            component: UnavailableComponent,
            data: {
              disableNavigation: true,
            },
          },
          {
            path: 'disabled',
            component: DisabledPageComponent,
          },
        ],
      },

      {
        path: '**',
        pathMatch: 'full',
        component: LayoutPageNotFoundComponent,
        data: {
          title: '404 Page Not Found',
          analytics: {
            name: 'Page Not Found',
            section: 'Page Not Found',
            prop13: 'pv-New Page',
          },
        },
        children: [
          {
            path: '',
            component: NotFoundComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    LetDirective,
    ChcpShellUiModule,
    OmniEntryModule,
    OmniChatLinkModule,
    ChcpSharedUserProfileDataAccessModule,
    ChcpSharedUserOptionsDataAccessModule,
    ChcpSharedEnvironmentUtilModule,
    StoreModule.forFeature(SHELL_FEATURE_KEY, reducer),
    EffectsModule.forFeature([ShellEffects]),
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy,
    }),
    ResourceContentDataAccessModule.withConfig({
      factory: manifestAccessorFactory,
      deps: [LoggingService],
    }),
    SharedTimeoutPopupFeatureModule,
  ],
  declarations: [
    LayoutSecureComponent,
    LayoutPublicComponent,
    LayoutPageNotFoundComponent,
    LayoutWrapperComponent,
  ],
  providers: [
    CustomPreloadingStrategy,
    EntitlementGuard,
    FeatureToggleGuard,
    DisabledPageGuard,
  ],
})
export class ChcpShellFeatureModule {
  static withConfig(
    timeoutPopupConfig: TimeoutPopupConfig,
  ): ModuleWithProviders<ChcpShellFeatureModule> {
    return {
      ngModule: ChcpShellFeatureModule,
      providers: timeoutPopupProviders(
        timeoutPopupConfig,
        (shell) => () =>
          shell.logOut({ variation: 'inactive', linkName: 'Logout Timeout' }),
        ShellFacade,
      ),
    };
  }
}
