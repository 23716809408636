import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
} from '@angular/core';

import { InputComponent } from '@cigna/shared/angular/input-ui';

@Component({
  selector: 'cigna-password-input',
  templateUrl: './password-input.component.html',
  styleUrl: './password-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputComponent {
  @Input() isVisibleByDefault = false;
  @Input() isFullWidth = false;

  @ContentChild(InputComponent, { static: true })
  passwordInput: InputComponent;
  isPasswordVisible: boolean;

  toggleVisibility() {
    this.passwordInput.element.type = this.isPasswordVisible
      ? 'password'
      : 'text';

    this.passwordInput.element.focus();
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
