import { Injectable } from '@angular/core';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import {
  ChatBubbleOpen,
  OmniChatFacade,
} from '@cigna/omni/chat-state-data-access';
import { OmniCommChannelFacade } from '@cigna/omni/comm-channel-feature';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniLiveAvailabilityFacade } from '@cigna/omni/live-availability-state-data-access';
import { ConversationDTOAgentID } from '@cigna/omni/messaging-feature';
import { StartChat } from '@cigna/omni/right-now-data-access';
import { DcmCareAdvocateData } from '@cigna/omni/shared-util';
import { ConversationDTO, ConversationSummaryDTO } from '@cigna/vampire-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
  BubbleClick,
  ChatBubbleActionTypes,
  LaunchChat,
  LaunchSplashView,
} from './chat-bubble.actions';
import { ChatBubbleFacade } from './chat-bubble.facade';

@Injectable()
export class ChatBubbleEffects {
  chatBubbleClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BubbleClick>(ChatBubbleActionTypes.BubbleClick),
      withLatestFrom(
        this.featureTogglesFacade.featuresEnabled(['useRightNowChat']),
        this.liveAvailabilityFacade.liveChatAvailable$,
        this.featureTogglesFacade.featuresEnabled(['rightNowChatFederated']),
        this.conversationsFacade.activeChatProvider$,
        this.featureTogglesFacade.featuresEnabled(['eapGreenBubble']),
      ),
      filter(
        ([_, useRightNowChat, liveChatAvailable, eapGreenBubble]) =>
          // restrict bubble click for users without live chat and with right now chat enabled and eap green bubble indicator turned off
          liveChatAvailable || !useRightNowChat || eapGreenBubble,
      ),
      map(
        ([
          _,
          useRightNowChat,
          _liveChatAvailable,
          rightNowChatFederated,
          activeChatProvider,
          eapGreenBubble,
        ]) =>
          useRightNowChat && !eapGreenBubble
            ? new StartChat(rightNowChatFederated ? activeChatProvider : '')
            : new ChatBubbleOpen(),
      ),
    ),
  );

  launchChat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LaunchChat>(ChatBubbleActionTypes.LaunchChat),
        withLatestFrom(
          this.featureTogglesFacade.featuresEnabled([
            'enableNewPreChatWelcome',
          ]),
          this.featureTogglesFacade.featuresEnabled(['useRightNowChat']),
          this.featureTogglesFacade.featuresEnabled(['liveClinicianChat']),
          this.featureTogglesFacade.featuresEnabled(['liveSupportChat']),
          this.featureTogglesFacade.featuresEnabled(['personalGuideChat']),
          this.featureTogglesFacade.featuresEnabled([
            'hasRestrictedChat',
            'enableRestrictedChatInPortal',
          ]),
          this.liveAvailabilityFacade.liveChatAvailable$,
          this.featureTogglesFacade.featuresEnabled(['evmChat']),
          this.featureTogglesFacade.featuresEnabled(['isTerminatedUser']),
          this.featureTogglesFacade.featuresEnabled(['isDcmChat']),
          this.conversationsFacade.allConversations$,
          this.conversationsFacade.careAdvocateData$,
        ),
        tap(
          ([
            { text },
            shouldEnablePreChatWelcome,
            isUseRightNowChat,
            isLiveClinicianChat,
            isLiveSupportChat,
            isPersonalGuideChat,
            isConsumerChatEnabled,
            isLiveChatAvailable,
            isEvernorth,
            isTerminatedUser,
            isDcmChat,
            allConversations,
            careAdvocateData,
          ]) => {
            if (isDcmChat) {
              this.onDcmChatLaunch(allConversations, careAdvocateData);
            }
            if (shouldEnablePreChatWelcome && !isDcmChat) {
              if (
                (!isUseRightNowChat &&
                  !isLiveClinicianChat &&
                  !isLiveSupportChat &&
                  !isPersonalGuideChat &&
                  !isConsumerChatEnabled &&
                  !isEvernorth) ||
                !isLiveChatAvailable ||
                isTerminatedUser
              ) {
                this.omniChatFacade.openDialog();
                this.commChannelFacade.nonLiveChatClicked({
                  buttonText: 'Chat Closed',
                  buttonId: 'chatGuide',
                });
              } else {
                let paramTags = isUseRightNowChat
                  ? ['advocate', 'member']
                  : ['default'];
                paramTags = isEvernorth ? ['advocate', 'evn'] : paramTags;
                this.commChannelFacade.interact(
                  {
                    buttonText: 'Talk with Us',
                    buttonId: 'chatGuide',
                  },
                  { tags: paramTags },
                );
              }
            } else {
              this.chatBubbleFacade.chatBubbleClick(text);
            }
          },
        ),
      ),
    { dispatch: false },
  );

  launchSplashView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LaunchSplashView>(ChatBubbleActionTypes.LaunchSplashView),
        withLatestFrom(
          this.featureTogglesFacade.featuresEnabled([
            'enableNewPreChatWelcome',
          ]),
          this.featureTogglesFacade.featuresEnabled(['isTerminatedUser']),
        ),
        tap(([{ text }, shouldEnablePreChatWelcome, isTerminatedUser]) => {
          if (shouldEnablePreChatWelcome) {
            if (isTerminatedUser) {
              this.omniChatFacade.openDialog();
              this.commChannelFacade.nonLiveChatClicked({
                buttonText: 'Chat Closed',
                buttonId: 'chatGuide',
              });
            } else {
              this.omniChatFacade.splashView();
              this.commChannelFacade.launchSplashView({
                buttonText: 'Talk with Us',
                buttonId: 'chatGuide',
              });
            }
          } else {
            this.chatBubbleFacade.chatBubbleClick(text);
          }
        }),
      ),
    { dispatch: false },
  );

  onDcmChatLaunch(
    allConversations: Array<
      ConversationDTO | ConversationSummaryDTO | ConversationDTOAgentID
    >,
    careAdvocateData?: DcmCareAdvocateData,
  ) {
    const currConversations = allConversations.filter(
      (c: ConversationDTOAgentID) =>
        c.state !== 'closed' &&
        careAdvocateData?.agentId &&
        careAdvocateData.agentId === c.agentData?.id,
    );
    const convDTOAgentId = currConversations[0] as ConversationDTOAgentID;
    if (currConversations.length < 1) {
      if (careAdvocateData?.agentId) {
        this.conversationsFacade.createLiveConversation('async', {
          tags: ['msk'],
        });
        this.setConverationId(convDTOAgentId, undefined);
      } else {
        this.omniChatFacade.openDialog();
      }
    }
    if (allConversations.length === 1 && currConversations.length === 1) {
      if (convDTOAgentId && convDTOAgentId.agentData) {
        this.conversationsFacade.setAgentName(
          convDTOAgentId.agentData.firstName ?? '',
        );
      }
      this.setConverationId(convDTOAgentId, currConversations[0]._id);
      this.omniChatFacade.openDialog();
    } else if (allConversations.length > 1) {
      this.omniChatFacade.openHistory();
    }
  }
  // created this method to reduce onDcmChatLaunch Cognitive Complexity
  setConverationId(
    convDTOAgentId: ConversationDTOAgentID,
    currConversationsId: string | undefined,
  ) {
    if (convDTOAgentId?.agentData?.id) {
      this.conversationsFacade.setExistingConversationId(
        convDTOAgentId.agentData.id,
      );
      this.conversationsFacade.setConversationId(convDTOAgentId.agentData.id);
    } else if (currConversationsId) {
      this.conversationsFacade.setExistingConversationId(currConversationsId);
    }
  }

  constructor(
    private actions$: Actions,
    private featureTogglesFacade: FeatureTogglesFacade,
    private liveAvailabilityFacade: OmniLiveAvailabilityFacade,
    private conversationsFacade: OmniConversationsFacade,
    private omniChatFacade: OmniChatFacade,
    private commChannelFacade: OmniCommChannelFacade,
    private chatBubbleFacade: ChatBubbleFacade,
  ) {}
}
