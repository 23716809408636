import { AbstractControl, ValidatorFn } from '@angular/forms';

export const customValidator =
  (fn: any, validationErr: any): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    fn(control.value) ? null : { [validationErr]: { value: control.value } };

export const trimmedValidator = (value: string): boolean =>
  typeof value === 'string' && value.trim().length > 0;

export function playAudio(source: string) {
  const audio = new Audio();
  audio.src = source;
  audio.load();
  audio.play();
}
