export const MB = 1048576;
export const UPLOAD_TOTAL_FILES_COUNT_LIMIT = 10;
export const UPLOAD_ONE_FILE_SIZE_LIMIT: number = 64 * MB;
export const UPLOAD_ALLOWED_EXTENSIONS: string[] = [
  '.png',
  '.bmp',
  '.gif',
  '.jpg',
  '.jpeg',
  '.tif',
  '.tiff',
  '.pdf',
];

export const COOKIE_EXCEPTION_LIST: RegExp[] = [
  RegExp('FEDERATED_LOGIN'),
  RegExp('oo_click'),
  RegExp('oo_inv_*'),
  RegExp('user_csid'),
  RegExp('user_cuid'),
  RegExp('ac.uuid'),
  RegExp('browserFingerprint'),
  RegExp('user_dn'),
  RegExp('___tk*'),
  RegExp('TLTUID'),
  RegExp('TLTSID'),
  RegExp('CHCP_DEEPLINK'),
  RegExp('tr.uuid'),
  RegExp('PD_STATEFUL_*'),
  RegExp('AMWEBJCT*'),
  RegExp('DCX*'),
];

export const DEEPLINK_BLACKLIST: string[] = [
  '/app',
  '/app/',
  '/app/login',
  '/app/common.js',
  '/app/assets/images/isam-keepalive.gif',
  '/app/auth_callback.html',
];
