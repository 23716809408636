import { Injectable } from '@angular/core';
import {
  ChatButtonId,
  OmniAnalyticsService,
  DcmCareAdvocateData,
} from '@cigna/omni/shared-util';
import { ConversationDTO, ConversationSummaryDTO } from '@cigna/vampire-dto';
import { select, Store } from '@ngrx/store';
import { publishReplay, refCount } from 'rxjs/operators';
import { conversationsActions } from './conversations.actions';
import { conversationsQuery } from './conversations.selectors';

@Injectable()
export class OmniConversationsFacade {
  public activeChatProvider$ = this.store$.pipe(
    select(conversationsQuery.getActiveChatProvider),
  );
  public careAdvocateData$ = this.store$.pipe(
    select(conversationsQuery.getCareAdvocateData),
  );
  public activityDebounceTime$ = this.store$.pipe(
    select(conversationsQuery.getActivityDebounceTime),
  );

  public conversationsEntities$ = this.store$.pipe(
    select(conversationsQuery.selectConversations),
  );

  public allConversations$ = this.store$.pipe(
    select(conversationsQuery.selectAllConversations),
  );

  public activeLiveConversation$ = this.store$.pipe(
    select(conversationsQuery.selectActiveLiveConversation),
  );

  public conversationCount$ = this.store$.pipe(
    select(conversationsQuery.selectConversationCount),
  );
  public dmConversationCount$ = this.store$.pipe(
    select(conversationsQuery.selectDMConversationCount),
  );
  public openDMConversations$ = this.store$.pipe(
    select(conversationsQuery.selectOpenDMConversations),
  );
  public unreadDMConversationCount$ = this.store$.pipe(
    select(conversationsQuery.selectUnreadOpenDMConversationCount),
  );
  public activeAsyncConvCount$ = this.store$.pipe(
    select(conversationsQuery.selectActiveAsyncConvCount),
  );
  public closedAsyncConvCount$ = this.store$.pipe(
    select(conversationsQuery.selectClosedAsyncConvCount),
  );
  public conversation$ = this.store$.pipe(
    select(conversationsQuery.selectConversation),
  );
  public conversationEntities$ = this.store$.pipe(
    select(conversationsQuery.selectConversations),
  );
  public conversationId$ = this.store$.pipe(
    select(conversationsQuery.selectSelectedConversationId),
  );
  public agentName$ = this.store$.pipe(
    select(conversationsQuery.selectSelectedAgentName),
  );
  public isMyConversationsFeatured$ = this.store$.pipe(
    select(conversationsQuery.selectIsMyConversationsFeatured),
    publishReplay(1),
    refCount(),
  );
  public notificationCount$ = this.store$.pipe(
    select(conversationsQuery.selectNotificationCount),
  );
  public recentActiveOrUnreadConversation$ = this.store$.pipe(
    select(conversationsQuery.selectRecentActiveOrUnreadConversation),
  );

  public unreadOpenDmConversations$ = this.store$.pipe(
    select(conversationsQuery.selectUnreadOpenDMConversations),
  );

  public expanderBubble$ = this.store$.pipe(
    select(conversationsQuery.getExpander),
  );

  public closeOrExitFlag$ = this.store$.pipe(
    select(conversationsQuery.getCloseOrExitFlag),
  );

  public closeSplashScreen$ = this.store$.pipe(
    select(conversationsQuery.getCloseSplashScreen),
  );

  public canAutoOpenConversation$ = this.store$.pipe(
    select(conversationsQuery.autoOpenConversation),
  );

  public isUpdatingConversation$ = this.store$.pipe(
    select(conversationsQuery.getIsUpdatingConversation),
  );

  public isAiBot$ = this.store$.pipe(select(conversationsQuery.getIsAiBot));

  public existingConversationId$ = this.store$.pipe(
    select(conversationsQuery.getExistingConversationId),
  );

  public shouldFocusOnHeader$ = this.store$.pipe(
    select(conversationsQuery.getShouldFocusOnHeader),
  );

  public conversationMetadata$ = this.store$.pipe(
    select(conversationsQuery.getConversationMetadata),
  );

  public isLoadingHistory$ = this.store$.pipe(
    select(conversationsQuery.getIsLoadingHistory),
  );

  public terminatedUserMessage$ = this.store$.pipe(
    select(conversationsQuery.getTerminatedUserMessage),
  );
  public conversationsError$ = this.store$.pipe(
    select(conversationsQuery.getConversationError),
  );
  public tokenRefreshThresholdTime$ = this.store$.pipe(
    select(conversationsQuery.getTokenRefreshThresholdTime),
  );

  public isHistory$ = this.store$.pipe(select(conversationsQuery.isHistory));

  public getOpenLiveConvExists$ = this.store$.pipe(
    select(conversationsQuery.getOpenLiveConvExists),
  );

  constructor(
    private store$: Store<any>,
    private analytics: OmniAnalyticsService,
  ) {}

  public createConversation(
    type: ConversationDTO['type'],
    context: ConversationDTO['context'],
    message?: string,
  ): void {
    this.store$.dispatch(
      new conversationsActions.CreateConversation({ type, context, message }),
    );
  }

  public createLiveConversation(
    type: ConversationDTO['type'],
    context: ConversationDTO['context'],
  ): void {
    this.store$.dispatch(
      new conversationsActions.CreateLiveConversation({ type, context }),
    );
  }

  public chatBubbleClick(
    buttonId: ChatButtonId,
    buttonText: string,
    closed = false,
  ) {
    this.store$.dispatch(
      new conversationsActions.ChatBubbleClick(buttonId, buttonText, closed),
    );
  }

  public chatWithHumanClick() {
    this.store$.dispatch(new conversationsActions.ChatWithHumanClick());
  }

  public agentJoinChat() {
    this.store$.dispatch(new conversationsActions.AgentJoinChat());
  }

  public endConversation(conversationId: string): void {
    this.setConversationId('');
    this.store$.dispatch(
      new conversationsActions.EndConversation(conversationId),
    );
  }

  public backToPrevious(
    convId: string,
    context: ConversationDTO['context'],
  ): void {
    this.store$.dispatch(
      new conversationsActions.BackToPrevious({ convId, context }),
    );
  }

  public backToStart(): void {
    this.store$.dispatch(new conversationsActions.BackToStart());
  }

  public openConversation(canAutoOpen: boolean): void {
    this.store$.dispatch(
      new conversationsActions.OpenConversation(canAutoOpen),
    );
  }

  public terminateConversation(
    userAction: 'logout' | 'inactive',
    logoutUrl: string,
    ssoId: string,
  ): void {
    this.store$.dispatch(
      new conversationsActions.TerminateConversation(
        userAction,
        logoutUrl,
        ssoId,
      ),
    );
  }

  public loadConversations(): void {
    this.store$.dispatch(new conversationsActions.LoadConversations());
  }

  public loadHistoryInitiated(): void {
    this.store$.dispatch(new conversationsActions.LoadHistoryInitiated());
  }

  public getConversationHistory(): void {
    this.store$.dispatch(new conversationsActions.GetConversationHistory());
  }

  public loadConversationsSuccess(
    conversations: ConversationDTO[] | ConversationSummaryDTO[],
  ): void {
    this.store$.dispatch(
      new conversationsActions.LoadConversationsSuccess(conversations),
    );
  }

  public loadConversationsFailure(error: any): void {
    this.store$.dispatch(
      new conversationsActions.LoadConversationsFailure(error),
    );
  }

  public messageViewed(messageId: string): void {
    this.store$.dispatch(new conversationsActions.MessageViewed(messageId));
  }

  public onKeyUp() {
    this.store$.dispatch(new conversationsActions.OnKeyUp());
  }

  public postCta(
    sourceMessageId: string,
    value: string,
    topicId: string,
    label: string,
    _id: string,
    primaryTopicName?: string,
  ) {
    this.analytics.track('chatTopicClick', {
      topicId,
      topicLabel: label,
      primaryTopicName,
    });
    this.store$.dispatch(
      new conversationsActions.PostCta({ sourceMessageId, value, _id }),
    );
  }

  public postMessage(message: string, isFirstDMResponse: boolean) {
    if (isFirstDMResponse) {
      this.analytics.dataLayer.updateAdobeDataLayer({
        event: 'pageAction',
        actionData: {
          actionName: 'Track initial direct message submissions',
          hitType: 'action',
          widgetName: 'dm-verbatim-text-sent',
          customLinkText: 'Initial DM Verbatim Text Sent',
          requirementId: 'acCHT011',
          userStory: 'ODCTC-2491',
        },
      });
    }
    this.store$.dispatch(new conversationsActions.PostMessage(message));
  }

  public setConversationId(conversationId: string, convType?: string): void {
    this.store$.dispatch(
      new conversationsActions.SetSelectedConversationId(
        conversationId,
        convType,
      ),
    );
  }

  public setAgentName(agentName: string): void {
    this.store$.dispatch(
      new conversationsActions.SetSelectedAgentName(agentName),
    );
  }

  public setActivityDebounceTime(debounce: number): void {
    this.store$.dispatch(
      new conversationsActions.SetActivityDebounceTime(debounce),
    );
  }

  public setActiveChatProvider(provider: string): void {
    this.store$.dispatch(
      new conversationsActions.SetActiveChatProvider(provider),
    );
  }

  public setCareAdvocateData(advocate: DcmCareAdvocateData): void {
    this.store$.dispatch(
      new conversationsActions.SetCareAdvocateData(advocate),
    );
  }

  public getActiveConversation(): void {
    this.store$.dispatch(new conversationsActions.GetActiveConversation());
  }

  public getActiveAsyncConversation(
    vampireHistory?: ConversationDTO[] | ConversationSummaryDTO[],
  ): void {
    this.store$.dispatch(
      new conversationsActions.GetActiveAsyncConversation(vampireHistory),
    );
  }

  public transferConversation(
    conversationId: string,
    type: ConversationDTO['type'],
    initial: string,
  ): void {
    this.store$.dispatch(
      new conversationsActions.TransferConversation({
        conversationId,
        type,
        initial,
      }),
    );
  }

  public setExpanderBubble(isHide: boolean): void {
    this.store$.dispatch(new conversationsActions.SetExpanderBubble(isHide));
  }

  public setCloseOrExitFlag(isClosed: boolean): void {
    this.store$.dispatch(new conversationsActions.SetCloseOrExitFlag(isClosed));
  }

  public setCloseSplashScreen(closeSplashScreen: boolean): void {
    this.store$.dispatch(
      new conversationsActions.SetCloseSplashScreen(closeSplashScreen),
    );
  }

  public setExistingConversationId(existingConversationId: string): void {
    this.store$.dispatch(
      new conversationsActions.SetExistingConversationId(
        existingConversationId,
      ),
    );
  }

  public setHeaderFocus(shouldFocus: boolean) {
    this.store$.dispatch(new conversationsActions.SetHeaderFocus(shouldFocus));
  }

  public updateConversationMetadata(data: any) {
    this.store$.dispatch(
      new conversationsActions.UpdateConversationMetadata(data),
    );
  }

  public setTerminatedUserMessage(data: Record<string, string>) {
    this.store$.dispatch(
      new conversationsActions.SetTerminatedUserMessage(data),
    );
  }
}
