import {
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from '@ngrx/store';

import {
  AuthPartialState,
  authQuery as authTokenQuery,
} from '@cigna/shared/angular/auth-data-access';

import { AccessToken } from '../auth/access-token.model';
import {
  Entitlement,
  EntitlementLob,
  JWT_NS,
  LOB,
  LOB_BEHAVIORAL,
  LOB_DENTAL,
  LOB_MEDBH,
  LOB_MEDICAL,
} from '@cigna/chcp/auth/util';
import { IdToken } from '../auth/id-token.model';

const selectFromIdentityNS = <
  TPayload extends IdToken[typeof JWT_NS],
  TProp extends keyof TPayload,
>(
  prop: TProp,
): MemoizedSelector<
  AuthPartialState,
  TPayload[TProp] | undefined | null,
  DefaultProjectorFn<TPayload[TProp] | undefined | null>
> =>
  createSelector(authTokenQuery.getAuthData, (authData) =>
    authData != null
      ? ((authData.idTokenBody as IdToken)[JWT_NS] as TPayload)[prop]
      : authData,
  );

const selectFromAccess = createSelector(
  authTokenQuery.getAuthData,
  (authData) =>
    authData !== undefined
      ? (authData.accessTokenBody as AccessToken)
      : authData,
);

const getUserLob = createSelector(selectFromAccess, (accessToken) =>
  accessToken ? accessToken['cigna.lob'] : undefined,
);

const getAppName = createSelector(selectFromAccess, (accessToken) =>
  accessToken ? accessToken['cigna.appName'] : undefined,
);

const getUserLobId = createSelector(getUserLob, (lob) => {
  switch (lob) {
    case LOB_MEDBH: {
      return 1;
    }
    case LOB_DENTAL: {
      return 2;
    }
    default: {
      return 1;
    }
  }
});

const isDentalUser = createSelector(
  getUserLob,
  (lob) => lob && lob === LOB_DENTAL,
);

const isMedicalUser = createSelector(
  getUserLob,
  (lob) => lob && [LOB_MEDBH, LOB_MEDICAL].includes(lob),
);

const isBehUser = createSelector(
  getUserLob,
  (lob) => (lob && lob === LOB_BEHAVIORAL) || false,
);

const getLobHexApiPrefix = createSelector(
  getAppName,
  isDentalUser,
  (appName, isDental) =>
    appName === 'epp-web' ? '' : isDental ? 'dent' : 'med',
);

const getUserSub = createSelector(
  authTokenQuery.getAuthData,
  (authData) => (authData?.accessTokenBody as AccessToken)?.sub || null,
);

const getUserId = createSelector(selectFromAccess, (accessToken) =>
  accessToken ? accessToken['cigna.cn'] : undefined,
);

const getUserIdEncrypted = createSelector(selectFromAccess, (accessToken) =>
  accessToken ? accessToken['cigna.encryptedCn'] : undefined,
);

const authLevel = createSelector(selectFromAccess, (accessToken) =>
  accessToken ? accessToken['cigna.authLevel'] : undefined,
);

const operatorRole = createSelector(selectFromAccess, (accessToken) =>
  accessToken ? accessToken['cigna.operatorRole'] : undefined,
);

const operatorId = createSelector(selectFromAccess, (accessToken) =>
  accessToken ? accessToken['cigna.operatorId'] : undefined,
);

const isImpersonator = createSelector(operatorRole, (role) => !!role);

const isRestrictedImpersonator = createSelector(
  operatorRole,
  (role) => ['CHCP_RES', 'EPP_RES'].includes(role || '') || false,
);
const isUnRestrictedImpersonator = createSelector(
  operatorRole,
  (role) =>
    ['CHCP_UNRES', 'EPP_UNRES', 'CHCP_RES_OPS', 'EPP_RES_OPS'].includes(
      role || '',
    ) || false,
);
const isChatAllowedImpersonator = createSelector(
  operatorRole,
  (role) => ['CHCP_RES_OPS', 'EPP_RES_OPS'].includes(role || '') || false,
);

const isSalesDemoUser = createSelector(
  getUserId,
  (userid) => (userid && userid.startsWith('sales')) || false,
);

const selectUserAuthEntitlements = createSelector(
  selectFromAccess,
  (accessToken) =>
    accessToken
      ? accessToken['cigna.entitlements']?.split(' ').sort()
      : undefined,
);

const getUserAuthEntitlements = createSelector(
  selectUserAuthEntitlements,
  (entitlements) =>
    entitlements?.map((e) => {
      const ent = e.split('_');
      return ent[ent.length - 1];
    }), // interim code to remove lob prefix from entitlements if it comes in token
);

const getPermissionToggles = createSelector(selectFromAccess, (accessToken) =>
  accessToken ? accessToken['cigna.permissionToggles']?.split(' ') : undefined,
);

const hasAllEntitlements = (props: {
  entitlements: Array<Entitlement | EntitlementLob>;
}) =>
  createSelector(
    getUserAuthEntitlements,
    getUserLob,
    (userEntitlements, userLob) =>
      userEntitlements
        ? props.entitlements.every((v) => {
            // eslint-disable-next-line no-prototype-builtins
            if (v.hasOwnProperty('entitlement')) {
              return (
                userEntitlements.includes((v as EntitlementLob).entitlement) &&
                (v as EntitlementLob).lobs.includes(userLob as LOB)
              );
            }
            return userEntitlements.includes(v as Entitlement);
          })
        : false,
  );

const hasOneOfEntitlements = (props: {
  entitlements: Array<Entitlement | EntitlementLob>;
}) =>
  createSelector(
    getUserAuthEntitlements,
    getUserLob,
    (userEntitlements, userLob) =>
      userEntitlements
        ? // eslint-disable-next-line sonarjs/no-identical-functions
          props.entitlements.some((v) => {
            // eslint-disable-next-line no-prototype-builtins
            if (v.hasOwnProperty('entitlement')) {
              return (
                userEntitlements.includes((v as EntitlementLob).entitlement) &&
                (v as EntitlementLob).lobs.includes(userLob as LOB)
              );
            }
            return userEntitlements.includes(v as Entitlement);
          })
        : false,
  );

const isUserAuthenticated = createSelector(
  getUserLob,
  (lob) => lob !== undefined && lob !== null,
);

const isAuthLoading = authTokenQuery.isAuthLoading;

const getUserFirstName = selectFromIdentityNS('firstName');
const getUserLastName = selectFromIdentityNS('lastName');

const getUserFullName = createSelector(
  getUserFirstName,
  getUserLastName,
  (first, last) => `${first} ${last}`,
);

export const authQuery = {
  getUserLob,
  getUserLobId,
  getUserSub,
  getUserId,
  getUserIdEncrypted,
  isAuthLoading,
  isDentalUser,
  isMedicalUser,
  isBehUser,
  getUserAuthEntitlements,
  hasAllEntitlements,
  hasOneOfEntitlements,
  isUserAuthenticated,
  authLevel,
  operatorId,
  operatorRole,
  isImpersonator,
  isRestrictedImpersonator,
  isUnRestrictedImpersonator,
  isChatAllowedImpersonator,
  isSalesDemoUser,
  getPermissionToggles,
  getUserFullName,
  getLobHexApiPrefix,
};
