import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const TIMEOUT_POPUP_CONFIG = new InjectionToken<TimeoutPopupConfig>(
  'TIMEOUT_POPUP_CONFIG',
);
export const TIMEOUT_POPUP_EVENTS = new InjectionToken<
  TimeoutPopupEvents | OnLogout
>('TIMEOUT_POPUP_EVENTS');

export interface TimeoutPopupConfig {
  timeoutDuration: number;
  logoutDuration: number;
  keepAliveUrl?: string;
  keepAliveInterval?: number;
}

export interface TimeoutPopupEvents {
  onLogout: OnLogout;
  onKeepAlive?: () => Observable<unknown>;
}

export type OnLogout = (reason: LogoutReason) => void;
export type LogoutReason = 'logout' | 'inactive';
