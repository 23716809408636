import { Component, Input } from '@angular/core';
import { IconDynamicColors } from '../../interfaces/colors';
import { IconDynamicData } from '../../interfaces/data';

@Component({
  selector: 'cigna-icon-collapsible-chat-bubble',
  templateUrl: './chat-collapsible.component.html',
  styleUrls: ['./chat-collapsible.component.css'],
})
export class IconCollapsibleChatBubbleComponent {
  @Input() public hasShellMinimized: boolean;
  @Input() public shouldExpand: boolean;
  @Input()
  public canShellOpenOrMinimized: boolean;
  @Input()
  public data: IconDynamicData;
  @Input() public isLiveChatAvailability: boolean;
  @Input() public isTerminatedUser: boolean;
  @Input() public hasNewMessages: boolean;
  @Input() public isDcmChat: boolean;
  @Input() public hasChatOutage: boolean;
  @Input()
  public set colors(payload: IconDynamicColors) {
    if (payload.color) {
      this.backgroundColor = payload.color;
    }

    if (payload.textColor) {
      this.textColor = payload.textColor;
    }

    if (payload.strokeColor) {
      this.strokeColor = payload.strokeColor;
    }

    if (payload.dropShadow) {
      this.dropShadow = payload.dropShadow;
    }
  }

  public backgroundColor = '#118503';
  public textColor = '#FFFFFF';
  public strokeColor = '';
  public dropShadow = '';
}
