import { Injectable, NgZone } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export enum LogEventLevel {
  Info,
  Warn,
  Error,
  GlobalError,
}

export interface LogEvent {
  level: LogEventLevel;
  error?: Error;
  args: any[];
}

@Injectable({ providedIn: 'root' })
export class LoggingService {
  private _events$: ReplaySubject<LogEvent>;

  get events$() {
    return this._events$.asObservable();
  }

  constructor(ngZone: NgZone) {
    // store last 10 log entries for trace purpose
    // logs events could be quite frequent, we don't want zone to trigger
    ngZone.runOutsideAngular(() => {
      this._events$ = new ReplaySubject<LogEvent>(10);
    });
  }

  info(...args: any[]) {
    this._events$.next({
      args,
      level: LogEventLevel.Info,
    });

    console.log(...args);
  }

  warn(...args: any[]) {
    this._events$.next({
      args,
      level: LogEventLevel.Warn,
    });

    // eslint-disable-next-line no-console
    console.warn(...args);
  }

  error(error: Error, ...args: any[]) {
    this._events$.next({
      error,
      args,
      level: LogEventLevel.Error,
    });

    console.error(error, ...args);
  }

  globalError(error: Error, ...args: any[]) {
    this._events$.next({
      error,
      args,
      level: LogEventLevel.GlobalError,
    });

    console.error(error, ...args);
  }
}
