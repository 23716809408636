import { createAction, props } from '@ngrx/store';

import { AlertItem } from '../alerts/alerts.model';
import {
  ServiceError,
  NavigationItem,
  LogoutVariations,
} from '@cigna/chcp/shared/util';

export const headerNavLoad = createAction('[Shell] Header Nav Load');
export const headerNavLoadSuccess = createAction(
  '[Shell] Header Nav Load Success',
  props<{ navItems: NavigationItem[] }>(),
);
export const headerNavLoadError = createAction(
  '[Shell] Header Nav Load Error',
  props<{ error: ServiceError }>(),
);
export const siteNavLoad = createAction('[Shell] Site Nav Load');
export const siteNavLoadSuccess = createAction(
  '[Shell] Site Nav Load Success',
  props<{ navItems: NavigationItem[] }>(),
);
export const siteNavLoadError = createAction(
  '[Shell] Site Nav Load Error',
  props<{ error: ServiceError }>(),
);
export const secureAlertLoad = createAction('[Shell] Secure Alerts Load');
export const secureAlertLoadSuccess = createAction(
  '[Shell] Secure Alerts Load Success',
  props<{ alerts: AlertItem[] }>(),
);
export const publicAlertLoad = createAction('[Shell] Public Alerts Load');
export const publicAlertLoadSuccess = createAction(
  '[Shell] Public Alerts Load Success',
  props<{ alerts: AlertItem[] }>(),
);

export const loginStepup = createAction('[Shell] Login Stepup');
export const loginStepupSuccess = createAction(
  '[Shell] Login Stepup Sucess',
  props<{ location: string }>(),
);
export const loginStepupError = createAction('[Shell] Login Stepup Error');

export const logout = createAction(
  '[Shell] LogOut',
  props<{ variation: LogoutVariations; linkName: string }>(),
);
export const reset = createAction('[Shell] Reset All Stores to Initial State');
