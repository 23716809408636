import isArray from 'lodash/isArray';
import noop from 'lodash/noop';
import { sanitizeData } from '@cigna/shared/angular/analytics-util';

import { AnalyticsService } from './analytics.service';

export interface Rule {
  data?: {
    [inputKey: string]: any;
  };
  track?: {
    [outputKey: string]: any;
  };
}

export interface RuleSet {
  [ruleName: string]: Rule;
}

/**
 * Exclude index signatures from type
 */
type RemoveIndex<T> = {
  [K in keyof T as string extends K
    ? never
    : number extends K
    ? never
    : symbol extends K
    ? never
    : K]: T[K];
};

/**
 * Exclude index signatures and require that all keys have `Rule` values
 */
type ValidRules<T> = Record<keyof RemoveIndex<T>, Rule>;

export type AnalyticsTransforms<Rules extends ValidRules<Rules>> = {
  [ruleName in keyof ValidRules<Rules>]: (
    d: Rules[ruleName]['data'],
  ) => Rules[ruleName]['track'];
};

interface AnalyticsTransformOptions {
  replaceArrays?: boolean;
}

export type AnalyticsTransformOptionsSet<Rules extends ValidRules<Rules>> = {
  [ruleName in keyof ValidRules<Rules>]?: AnalyticsTransformOptions;
};

export enum LinkType {
  DOWNLOAD = 'd',
  EXIT = 'e',
  OTHER = 'o',
}

export abstract class FeatureAnalyticsService<Rules extends ValidRules<Rules>> {
  protected basePath?: string;

  protected abstract get transforms(): AnalyticsTransforms<Rules>;
  protected transformOptions: AnalyticsTransformOptionsSet<Rules> = {};

  private static replaceArraysCustomizer(currentValue: any, newValue: any) {
    if (isArray(currentValue)) {
      return newValue;
    }
  }

  constructor(protected analytics: AnalyticsService) {}

  get dataLayer() {
    return this.analytics.dataLayer;
  }

  public track<R extends keyof ValidRules<Rules>>(
    ruleName: R,
    data: Rules[R]['data'],
    dontSanitize: boolean = false,
  ) {
    this.analytics.track(
      ruleName.toString(),
      this.transformData(ruleName, data, dontSanitize),
      this.basePath,
      this.getCustomizer(ruleName),
    );
  }

  public trackEvent<R extends keyof ValidRules<Rules>>(
    ruleName: R,
    data: Rules[R]['data'],
    linkType: LinkType = LinkType.OTHER,
    dontSanitize: boolean = false,
  ) {
    this.analytics.trackEvent(
      this.transformData(ruleName, data, dontSanitize) as object,
      linkType,
    );
  }

  public trackPage<R extends keyof ValidRules<Rules>>(
    ruleName: R,
    data: Rules[R]['data'],
    dontSanitize: boolean = false,
  ) {
    this.analytics.trackPage(
      this.transformData(ruleName, data, dontSanitize) as object,
    );
  }

  public updateStore<R extends keyof ValidRules<Rules>>(
    ruleName: R,
    data: Rules[R]['data'],
    dontSanitize: boolean = false,
  ): void {
    this.analytics.updateStore(
      this.transformData(ruleName, data, dontSanitize),
      this.basePath,
      this.getCustomizer(ruleName),
    );
  }

  private getTransform<R extends keyof ValidRules<Rules>>(
    ruleName: R,
  ): ((d: Rules[R]['data']) => Rules[R]['track']) | undefined {
    return this.transforms[ruleName];
  }

  private getCustomizer<R extends keyof ValidRules<Rules>>(
    ruleName: R,
  ): (obj: any, src: any) => any {
    const options: AnalyticsTransformOptions | undefined =
      this.transformOptions[ruleName];
    if (options && options.replaceArrays) {
      return FeatureAnalyticsService.replaceArraysCustomizer;
    }

    return noop;
  }

  private transformData<R extends keyof ValidRules<Rules>>(
    rule: R,
    data: Rules[R]['data'],
    dontSanitize: boolean,
  ): Rules[R]['track'] {
    if (!data) {
      return undefined;
    }

    const ruleTransform = this.getTransform(rule);

    if (!ruleTransform) {
      // eslint-disable-next-line no-console
      console.warn('update store called but no rule defined', {
        rule,
        data,
      });
      return;
    }

    if (dontSanitize) {
      return ruleTransform(data);
    }

    return sanitizeData(ruleTransform(data));
  }
}
