import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LoggingService } from '@cigna/shared/angular/logging-util';
import {
  Observable,
  TimeoutError,
  throwError,
  firstValueFrom,
  asapScheduler,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  take,
  tap,
  timeout,
  filter,
  map,
} from 'rxjs/operators';
import {
  FeatureToggleType,
  updateFeatureToggles,
  sendLdAnalyticEvent,
  trackMetric,
} from './feature-toggles.actions';
import { FeatureTogglesPartialState } from './feature-toggles.reducer';
import {
  hasBeenUpdated,
  allEnabledFeatures,
  featuresEnabled,
  QueryOperator,
  featureValue,
  getFlag,
} from './feature-toggles.selectors';
import { ToggleValue } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class FeatureTogglesFacade {
  private pendingTasks = 0;

  public allEnabledFeatureToggles$ = this.store$.pipe(
    this.notPending(),
    select(allEnabledFeatures),
  );

  public initialUpdate$ = this.store$.pipe(
    select(hasBeenUpdated),
    filter((x) => x),
    take(1),
    map(() => {}),
  );

  constructor(
    private store$: Store<FeatureTogglesPartialState>,
    private _logger: LoggingService,
  ) {}

  getSingleFlag(flag: string) {
    this.sendLdAnalyticEvent([flag]);
    return this.store$.pipe(this.notPending(), select(getFlag(flag)));
  }

  public updateFeatureToggles(
    toggles: Record<string, ToggleValue>,
    toggleType: FeatureToggleType,
    namespace?: string,
  ) {
    this.store$.dispatch(
      updateFeatureToggles({ toggles, toggleType, namespace }),
    );
  }

  public loadFeatureToggles(
    loader: () => Observable<Record<string, ToggleValue>>,
    type: FeatureToggleType,
    namespace?: string,
  ) {
    this.pendingTasks += 1;
    const timeoutDuration = 5000;

    return firstValueFrom(
      loader().pipe(
        timeout(timeoutDuration), // in case something hangs
        take(1),
        tap((r) => {
          this.pendingTasks -= 1;
          this.updateFeatureToggles(r, type, namespace);
        }),
        catchError((e: Error) => {
          this.pendingTasks -= 1;
          this.updateFeatureToggles({}, type); // force ngrx to emit new value to get the updated pending state
          if (e instanceof TimeoutError) {
            this._logger.error(
              e,
              `${timeoutDuration} ms timeout was reached before feature toggles were loaded`,
            );
          } else {
            this._logger.error(
              e,
              'unexpected error occurred while loading feature toggles',
            );
          }

          return throwError(e);
        }),
      ),
    );
  }

  public featuresEnabled(toggles: string[], operator: QueryOperator = 'AND') {
    this.sendLdAnalyticEvent(toggles);
    return this.store$.pipe(
      this.notPending(),
      select(featuresEnabled(toggles, operator)),
      distinctUntilChanged(),
    );
  }

  public featureValue<T extends ToggleValue = ToggleValue>(
    toggle: string,
  ): Observable<T> {
    this.sendLdAnalyticEvent([toggle]);
    return this.store$.pipe(
      this.notPending(),
      select(featureValue(toggle)),
      distinctUntilChanged(),
    ) as Observable<T>;
  }

  public trackMetric(key: string) {
    this.store$.dispatch(trackMetric({ key }));
  }

  private notPending<T>() {
    return filter<T>(() => this.pendingTasks === 0);
  }

  private sendLdAnalyticEvent(toggles: string[]) {
    if (!toggles.some((toggle) => toggle.startsWith('ftr-'))) {
      return;
    }

    asapScheduler.schedule(() =>
      this.store$.dispatch(sendLdAnalyticEvent({ toggles })),
    );
  }
}
