import { Injector, Injectable } from '@angular/core';

import { FeatureTogglesFacade } from './+state/feature-toggles.facade';
import { LOADER } from './config';

@Injectable()
export class FeatureToggleLoader {
  constructor(
    private injector: Injector,
    private facade: FeatureTogglesFacade,
  ) {}

  canActivate() {
    const loader = this.injector.get(LOADER, null);

    if (loader) {
      const load$ = loader.load.apply(
        null,
        (loader.deps || []).map((d) => this.injector.get(d)),
      );

      this.facade.loadFeatureToggles(
        () => load$,
        loader.type,
        loader.namespace,
      );
    }

    return true;
  }
}
