/* eslint-disable no-useless-escape */
import {
  ConversationMessageDialogContentLinkExtn,
  getParticipant,
  getParticipantFirstName,
  isBehavioralContextTags,
  isClinicalNursingContextTags,
  isMedicalContextTags,
} from '@cigna/omni/shared-util';
import {
  ConversationDTO,
  ConversationEventType,
  ConversationMessageDialog,
  ConversationParticipantDTO,
} from '@cigna/vampire-dto';
import flow from 'lodash/fp/flow';
import dayjs from 'dayjs';
import {
  AvatarType,
  ConversationMessageEventDTOExtn,
  CtaLinkList,
  DeliveredStatusType,
  LinkText,
  MessageEventListItem,
  MetaData,
} from '../interfaces';

export const isMessageSet = (
  prev: MessageEventListItem,
  cur: MessageEventListItem,
): boolean =>
  prev.role === cur.role &&
  dayjs(cur.created).diff(dayjs(prev.created), 'seconds') < 60;

export const generateAvatar = (
  contextTags: string[],
  role: ConversationParticipantDTO['role'],
): AvatarType => {
  if (role === 'bot') {
    return 'messageChat';
  }
  if (
    isMedicalContextTags(contextTags) ||
    isBehavioralContextTags(contextTags)
  ) {
    return 'heartInHand';
  }
  return isClinicalNursingContextTags(contextTags) ? 'stethoscope' : 'headset';
};

export const extractMetaData = (
  messageEvent: Partial<ConversationMessageEventDTOExtn>,
  state: ConversationDTO['state'],
  // eslint-disable-next-line sonarjs/cognitive-complexity
): MetaData => {
  if (!messageEvent || !messageEvent.dialog || !messageEvent.dialog.type) {
    return null;
  }

  const type =
    messageEvent && messageEvent.dialog ? messageEvent.dialog.type : null;

  switch (type) {
    case 'cta_list':
      return {
        type,
        data: (messageEvent.dialog.ctaList?.options || []).map((message) => ({
          ...message,
          sourceMessageId: messageEvent._id,
        })),
        displayType: messageEvent.dialog.ctaList?.displayType || 'button',
        disabled: state === 'closed',
        footer: messageEvent.dialog?.footerText,
      };

    case 'cta_link_list':
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return {
        type,
        data: {
          ctaList: (messageEvent.dialog.ctaList?.options || []).map(
            (message) => ({
              ...message,
              sourceMessageId: messageEvent._id,
            }),
          ),
          linkText: messageEvent.dialog.linkText,
        },
        displayType: messageEvent.dialog.ctaList?.displayType || 'button',
        disabled: state === 'closed',
        footer: messageEvent.dialog?.footerText,
      } as CtaLinkList;

    case 'data_list':
      return {
        type,
        data: messageEvent.dialog.dataList?.list || [],
        displayType: messageEvent.dialog.dataList?.type || 'unordered',
        footer: messageEvent.dialog?.footerText,
      };

    case 'link_text':
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return {
        type,
        data: messageEvent.dialog.linkText,
        footer: messageEvent.dialog?.footerText,
      } as LinkText;

    case 'table_data':
      return {
        type,
        data: {
          title: messageEvent.dialog.tableData?.title || '',
          primary: messageEvent.dialog.tableData?.primary || '',
          columnHeaders: messageEvent.dialog.tableData?.columnHeaders || [],
          rowData: messageEvent.dialog.tableData?.rowData || [],
        },
        displayType: messageEvent.dialog.tableData?.type || 'regular',
        footer: messageEvent.dialog?.footerText,
      };

    case 'content_link':
      return {
        type,
        data: messageEvent.dialog
          .contentLink as ConversationMessageDialogContentLinkExtn,
        displayType: messageEvent.dialog.contentLink?.type || 'web_url',
        footer: messageEvent.dialog?.footerText,
      };

    default:
      return null;
  }
};

export const extractMessage = (
  messageEvent: ConversationMessageEventDTOExtn,
): string =>
  messageEvent?.dialog?.answerText ||
  messageEvent?.message ||
  // This exists because for now we don't have other other agreed upon error states
  'Something went wrong when loading this message... please refresh the page';

export const extractMessageEvents = (
  conversation: ConversationDTO,
): MessageEventListItem[] => {
  const deliveredStatuses = extractDeliveredStatus(conversation);
  return conversation.events
    .filter(
      (event): event is ConversationMessageEventDTOExtn =>
        event.type === 'message',
    )
    .reduce(
      (
        data: MessageEventListItem[],
        messageEvent: ConversationMessageEventDTOExtn,
      ) => {
        const foundParticipant = getParticipant(
          messageEvent.participantId,
          (conversation && conversation.participants) || [],
        );

        const participantIndex = conversation.participants.findIndex(
          (x) => x._id === messageEvent.participantId,
        );
        const agentLogoColor = participantIndex % 2 === 0 ? 'red' : 'green';

        const role: ConversationParticipantDTO['role'] = foundParticipant
          ? foundParticipant.role
          : 'bot';
        const deliveredStatus =
          messageEvent.externalMessageId &&
          deliveredStatuses[messageEvent.externalMessageId]
            ? deliveredStatuses[messageEvent.externalMessageId]
            : undefined;
        const returnData = {
          role,
          participantName: getParticipantFirstName(
            messageEvent.participantId,
            conversation?.participants || [],
          ),
          _id: messageEvent._id,
          avatar: generateAvatar(conversation.context.tags, role),
          created: messageEvent.created,
          message: messageEvent.dialog
            ? extractMessage(messageEvent)
            : messageEvent.message,
          meta: extractMetaData(messageEvent, conversation.state),
          type: messageEvent.type,
          logoColor: agentLogoColor,
          newMessage: messageEvent.newMessage,
          deliveredStatus,
        };
        if (messageEvent.dialog?.footerText && messageEvent.dialog.ctaList) {
          interface ConversationMessageDialogWithUrl
            extends ConversationMessageDialog {
            answerUri: string;
          }
          data.push(
            {
              ...returnData,
              message: messageEvent.dialog.footerText.replace(
                'click here',
                ' ',
              ),
              answerUri: (
                messageEvent.dialog as ConversationMessageDialogWithUrl
              )?.answerUri,
              meta: undefined,
            },
            {
              ...returnData,
              meta: extractMetaData(
                {
                  ...messageEvent,
                  dialog: { ...messageEvent.dialog, footerText: undefined },
                },
                conversation.state,
              ),
            },
          );
        } else {
          data.push(returnData);
        }
        return data;
      },
      [],
    );
};

export const extractDeliveredStatus = (
  conversation: ConversationDTO,
): { [key: string]: DeliveredStatusType } => {
  const returnData: { [key: string]: DeliveredStatusType } = {};
  conversation.events
    .filter(
      (event): event is ConversationMessageEventDTOExtn =>
        event.type === ('delivered_status' as unknown as ConversationEventType),
    )
    .forEach((event) => {
      if (event.deliveredStatus && event.externalMessageId) {
        returnData[event.externalMessageId] = event.deliveredStatus;
      }
    });

  return returnData;
};

export const labelSingle = (
  messageEvents: MessageEventListItem[] = [],
): MessageEventListItem[] =>
  messageEvents.reduce<MessageEventListItem[]>((ret, cur, i) => {
    cur.single = true;

    if (ret.length) {
      const prev = ret[i - 1];
      if (isMessageSet(prev, cur)) {
        prev.single = false;
        cur.single = false;
      }
    }

    ret.push(cur);
    return ret;
  }, []);

export const labelFirst = (
  messageEvents: MessageEventListItem[] = [],
): MessageEventListItem[] =>
  messageEvents.reduce<MessageEventListItem[]>((ret, cur, i) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let firstInSet = !cur.single;

    if (ret.length) {
      const prev = ret[i - 1];
      if (prev.firstInSet || (!prev.firstInSet && isMessageSet(prev, cur))) {
        firstInSet = false;
      }
    }

    ret.push({
      ...cur,
      firstInSet,
    });

    return ret;
  }, []);

export const labelLast = (
  messageEvents: MessageEventListItem[] = [],
): MessageEventListItem[] =>
  messageEvents.reduce<MessageEventListItem[]>((ret, cur, i) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let lastInSet = !(cur.single || cur.firstInSet);

    if (ret.length) {
      const prev = ret[i - 1];
      if (prev.firstInSet) {
        lastInSet = true;
      }

      if (prev.lastInSet && isMessageSet(prev, cur)) {
        lastInSet = true;
        prev.lastInSet = false;
      }
    }

    ret.push({
      ...cur,
      lastInSet,
    });

    return ret;
  }, []);

export const labelMostRecent = (
  conv: MessageEventListItem[],
): MessageEventListItem[] => {
  const len = (conv && conv.length) || [].length;
  if (len) {
    conv[len - 1].mostRecent = true;
  }
  return conv;
};

export const wrapLinksWithAnchorsV2 = (
  messages: MessageEventListItem[] = [],
): MessageEventListItem[] =>
  messages.map((message) => {
    // https://stackoverflow.com/a/8234912
    const linkRe = new RegExp(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
    );
    const emailRe = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\\.[a-zA-Z]{2,}$",
    );
    const protocolRe = new RegExp('(http|https)://', 'i');
    const isCustomer = message.role === 'customer';

    return {
      ...message,
      message: message.message
        .split(' ')
        .map((el: string): string => {
          // This helps support issues where AS is sending HTML
          // And there is no space between a perceived link and an </li> tag
          const liSuffixIndex: number = el.search(/<\/li>/i);
          let plainTextToTransform = el;
          let suffix = '';

          if (liSuffixIndex >= 0) {
            plainTextToTransform = el.slice(0, liSuffixIndex);
            suffix = el.slice(liSuffixIndex);
          }
          // This helps with relative links to my.cigna.com
          // We are supporting a relative link if the plain text specifically includes 'my.cigna.com'
          // If other relative links need to be considered, the technique will need to be adjusted
          const relativeStart: number =
            plainTextToTransform.search(/my.cigna.com/i);
          if (relativeStart >= 0) {
            return `<a href="${plainTextToTransform.slice(
              relativeStart + 12,
            )}">${plainTextToTransform}</a>${suffix}`;
          }

          // Boot if it doesn't pass the link test
          if (!linkRe.test(el) || isCustomer) {
            return el;
          }

          const start: number = plainTextToTransform.search(
            /https?:\/\/(www.)?|www./i,
          );
          if (start === -1) {
            const isValidEmail = emailRe.test(el) && el.split('@').length === 2;
            if (isValidEmail) {
              return `<a href="mailto:${el}">${el}</a>`;
            }
            return el;
          }
          const prefix: string = plainTextToTransform.slice(0, start);
          const protocol: string =
            plainTextToTransform.search(/https:/i) >= 0 ? 'https' : 'http';
          const url: string = plainTextToTransform.slice(start);
          const urlWithoutProtocol: string = url.replace(protocolRe, '');

          return `${prefix}<a href="${protocol}://${urlWithoutProtocol}" target="_blank">${url}</a>${suffix}`;
        })
        .join(' '),
    };
  });

export const wrapLinksWithAnchorsV1 = (
  messages: MessageEventListItem[] = [],
): MessageEventListItem[] =>
  messages.map((message) => {
    // https://stackoverflow.com/a/8234912
    const linkRe = new RegExp(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
    );
    const emailRe = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    const protocolRe = new RegExp('(http|https)://', 'i');
    const isCustomer = message.role === 'customer';

    return {
      ...message,
      message: message.message
        .split(' ')
        .map((el: string): string => {
          // This helps support issues where AS is sending HTML
          // And there is no space between a perceived link and an </li> tag
          const liSuffixIndex: number = el.search(/<\/li>/i);
          let plainTextToTransform = el;
          let suffix = '';

          if (liSuffixIndex >= 0) {
            plainTextToTransform = el.slice(0, liSuffixIndex);
            suffix = el.slice(liSuffixIndex);
          }
          // This helps with relative links to my.cigna.com
          // We are supporting a relative link if the plain text specifically includes 'my.cigna.com'
          // If other relative links need to be considered, the technique will need to be adjusted
          const relativeStart: number =
            plainTextToTransform.search(/my.cigna.com/i);
          if (relativeStart >= 0) {
            return `<a href="${plainTextToTransform.slice(
              relativeStart + 12,
            )}">${plainTextToTransform}</a>${suffix}`;
          }

          // Boot if it doesn't pass the link test
          if (!linkRe.test(el) || isCustomer) {
            return el;
          }

          const start: number = plainTextToTransform.search(
            /https?:\/\/(www.)?|www./i,
          );
          if (start === -1 && emailRe.test(el)) {
            return `<a href="mailto:${el}">${el}</a>`;
          }
          const prefix: string = plainTextToTransform.slice(0, start);
          const protocol: string =
            plainTextToTransform.search(/https:/i) >= 0 ? 'https' : 'http';
          const url: string = plainTextToTransform.slice(start);
          const urlWithoutProtocol: string = url.replace(protocolRe, '');

          return `${prefix}<a href="${protocol}://${urlWithoutProtocol}" target="_blank">${url}</a>${suffix}`;
        })
        .join(' '),
    };
  });

export const messageEventTransformFlowV1 = (
  conversation: ConversationDTO,
): MessageEventListItem[] =>
  flow(
    extractMessageEvents,
    labelSingle,
    labelFirst,
    labelLast,
    labelMostRecent,
    wrapLinksWithAnchorsV1,
  )(conversation);

export const messageEventTransformFlowV2 = (
  conversation: ConversationDTO,
): MessageEventListItem[] =>
  flow(
    extractMessageEvents,
    labelSingle,
    labelFirst,
    labelLast,
    labelMostRecent,
    wrapLinksWithAnchorsV2,
  )(conversation);
