import { Component, Input } from '@angular/core';

import { NavigationItem } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss'],
})
export class NavLinksComponent {
  @Input() links: NavigationItem[] = [];
  @Input() isImpersonator = false;
}
