import { Component } from '@angular/core';
import {
  IconDynamicColors,
  IconDynamicData,
  IconDynamicStethoscopeComponent,
  CommChannelContent,
  omniConfig,
} from '@cigna/omni/shared-util';
import { OmniCommChannelFacade } from '../+state/comm-channel.facade';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cigna-omni-comm-channel-che',
  templateUrl: './che.component.html',
  styleUrls: ['./che.component.scss'],
})
export class CommChannelCheComponent {
  public content$ = this.commChannelFacade.cheContent$.pipe(
    map((content) => {
      if (content?.icon) {
        return { ...content, icon: IconDynamicStethoscopeComponent };
      }
      return content;
    }),
  );
  public iconColors: IconDynamicColors = {
    color: omniConfig.omniColors.gray60,
  };
  public iconData: IconDynamicData = {
    size: '46px',
  };

  constructor(private commChannelFacade: OmniCommChannelFacade) {}

  public interact(content: CommChannelContent) {
    this.commChannelFacade.interact(content, { tags: ['clinical', 'hil'] });
  }
}
