<cigna-modal #modal [options]="{ backdrop: 'static', class: 'cigna-modal-sm' }">
  <cigna-modal-layout
    *ngIf="!service.isLoggedOut; else scheduledLogout"
    title="Stay logged in?"
    ok="Stay Logged In"
    alt="Log Out"
    class="OneLinkTx"
    [close]="null"
    (altClicked)="service.logout('logout')"
    (okClicked)="stayLoggedIn()"
  >
    You've been inactive for {{ service.config.timeoutDuration }} minutes and
    will be logged out in
    <b>{{ service.timeUntilLogout | date : 'm:ss' : '+0000' }}</b> minutes

    <div class="extra-controls-padding"></div>
  </cigna-modal-layout>

  <ng-template #scheduledLogout>
    <cigna-modal-layout title="Logged Out" class="OneLinkTx" [close]="null">
      Due to inactivity, you have been automatically logged out.
      <div class="extra-controls-padding"></div>
    </cigna-modal-layout>
  </ng-template>
</cigna-modal>
