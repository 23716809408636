<div class="alerts-wrapper cg-hide-for-print">
  <div class="alerts-inner">
    <div>
      <mat-icon
        *ngIf="alerts.length > 0"
        svgIcon="leaf-system-filled-warning"
        class="alert-icon cg-color-warn"
      ></mat-icon>
    </div>
    <div class="alert-text" *ngIf="alerts">
      <div
        *ngFor="let alert of alerts"
        data-test="alert-body"
        (click)="alertClick($event)"
        [innerHtml]="alert.body"
        class="alert-body"
      ></div>
    </div>
  </div>
</div>
