import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cigna-dialog-site-help-secure',
  templateUrl: './dialog-site-help-secure.component.html',
  styleUrls: ['./dialog-site-help-secure.component.scss'],
})
export class DialogSiteHelpSecureComponent {
  constructor(public dialogRef: MatDialogRef<DialogSiteHelpSecureComponent>) {}
}
