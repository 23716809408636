import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CONTENT_BUNDLE_FEATURE_KEY,
  reducer,
} from './+state/content-bundle.reducer';
import { ContentBundleEffects } from './+state/content-bundle.effects';
import { ContentBundleFacade } from './+state/content-bundle.facade';
import {
  ContentBundleConfig,
  CONTENT_BUNDLE_CONFIG,
  CONTENT_BUNDLE_MANIFEST_ACCESSOR,
  ManifestAccessorFn,
} from './+state/content-bundle.config';

@NgModule({
  imports: [
    StoreModule.forFeature(CONTENT_BUNDLE_FEATURE_KEY, reducer),
    EffectsModule.forFeature([ContentBundleEffects]),
  ],
  providers: [ContentBundleFacade],
})
export class ResourceContentDataAccessModule {
  static withConfig({
    factory,
    deps,
  }: {
    factory: (...args: unknown[]) => ManifestAccessorFn;
    deps?: unknown[];
  }): ModuleWithProviders<ResourceContentDataAccessModule> {
    return {
      ngModule: ResourceContentDataAccessModule,
      providers: [
        {
          provide: CONTENT_BUNDLE_MANIFEST_ACCESSOR,
          useFactory: factory,
          deps,
        },
      ],
    };
  }
}

export function contentBundleProviders(
  config: ContentBundleConfig,
): Provider[] {
  return [{ provide: CONTENT_BUNDLE_CONFIG, useValue: config }];
}
