export enum ContentBundleName {
  APPEAL = 'appeal',
  CLAIMS = 'claims',
  DASHBOARD = 'dashboard',
  DEMOGRAPHIC = 'demographic',
  DRUGLISTPDF = 'druglistpdf',
  DRUGLISTPDL = 'druglistpdl',
  EFT = 'eft',
  FEE_SCHEDULE = 'fee-schedule',
  FORGOT_IDENTITY = 'forgot-identity',
  LOGIN = 'login',
  MANAGE_ACCESS = 'manage-access',
  MFA = 'mfa',
  PATIENT = 'patient',
  PROVIDER_AGREEMENT = 'provider-agreement',
  REGISTRATION = 'registration',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  SHARED = 'shared',
  USER_AGREEMENT = 'user-agreement',
  WORKING_WITH_CIGNA = 'working-with-cigna',
  PRECERTIFICATION = 'precertification',
}

export interface ContentBundleManifest {
  public?: {
    [lang: string]: { [bundle in ContentBundleName]: string };
  };
}
