import { ModuleWithProviders, NgModule, ProviderToken } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrackClicksDirective } from './dom/track-clicks/track-clicks.directive';
import { TrackImpressionDirective } from './dom/track-impression/track-impression.directive';
import { TrackImpressionProductDirective } from './dom/track-impression/track-impression-product.directive';
import { TrackValuePipe } from './dom/track-value/track-value.pipe';
import { TrackInitDirective } from './lifecycle/track-init/track-init.directive';
import { TRACK_HANDLERS, TrackHandlers } from './track-handlers';
import { TrackUtil } from './track-util';

const DECLARATIONS = [
  TrackClicksDirective,
  TrackImpressionDirective,
  TrackImpressionProductDirective,
  TrackValuePipe,
  TrackInitDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  providers: [TrackUtil],
})
export class AnalyticsUiModule {
  /**
   * Creates a module for the analytics DOM framework with a track handlers provider
   *
   * This should be used to provide track handlers for top-level features.
   *
   * **Example** - Track handlers for `DashboardFeatureModule`:
   * ```
   * @NgModule({
   *   imports: [AnalyticsUiModule.withHandlers([DashboardTrackHandlers])],
   * })
   * export class DashboardFeatureModule {}
   * ```
   *
   * Also, there may be components that are specified in route definitions and require tracking.
   * For those routed entry components, track handlers must be provided.
   *
   * **Example** - Track handlers for `LayoutComponent`:
   * ```
   * @NgModule({
   *   imports: [
   *     AnalyticsUiModule.withHandlers([LayoutTrackHandlers]),
   *     RouterModule.forChild([
   *       {
   *         path: 'login',
   *         component: LayoutComponent,
   *         loadChildren: LOAD_CHILDREN,
   *       },
   *     ]),
   *   ],
   * })
   * export class AppModule {}
   * ```
   */
  static withHandlers(
    handlers: Array<ProviderToken<TrackHandlers<unknown>>>,
  ): ModuleWithProviders<AnalyticsUiModule> {
    return {
      ngModule: AnalyticsUiModule,
      providers: handlers.map((handlersClass) => ({
        provide: TRACK_HANDLERS,
        multi: true,
        useExisting: handlersClass,
      })),
    };
  }
}
