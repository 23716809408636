import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-omni-messaging-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() public participantName: string;
  @Input() public hasRole: string;
  @Input() public agentLogoColor: string;
  @Input() public isEvernorth: boolean;

  constructor() {}
}
