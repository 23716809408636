import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthFacade } from '../+state/auth.facade';
import { map, filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class EntitlementGuard {
  constructor(public authFacade: AuthFacade, private router: Router) {}

  authLoaded$ = this.authFacade.isUserAuthenticated$.pipe(
    tap((isAuth) => {
      // if we deeplink from login to a route with guard auth isnt called yet so we need to trigger it
      if (!isAuth) {
        this.authFacade.authenticate();
      }
    }),
    filter((auth) => auth),
    take(1),
  );

  canActivate(route: ActivatedRouteSnapshot) {
    return this.authLoaded$.pipe(
      switchMap(() =>
        this.authFacade
          .hasOneOfEntitlements$(route.data.guards.entitlements)
          .pipe(
            map((res: boolean) => {
              if (!res) {
                return this.router.parseUrl('/dashboard');
              }
              return true;
            }),
          ),
      ),
    );
  }
}
