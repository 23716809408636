import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WaitToLoadDirective } from './wait-to-load/wait-to-load.directive';
import { SpinnerComponent } from './spinner/spinner.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { SkeletonComponent } from './skeleton/skeleton.component';

const DECLARATIONS = [
  WaitToLoadDirective,
  SpinnerComponent,
  ErrorMessageComponent,
  SkeletonComponent,
];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class UiProgressModule {}
