import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrl: './dropdown-menu.component.scss',
})
export class DropdownMenuComponent {
  @Input({ required: true }) menuLabel: string;
  @Input() iconName?: string;
  @Input() shouldHideMenuLabel = false;
  @Input() dropdownWidth = 260;
  @Input() dataTest?: string;
}
