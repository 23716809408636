import { Component, Type } from '@angular/core';
import {
  IconDynamicChevronComponent,
  IconDynamicColors,
  IconDynamicData,
  IconsDynamic,
  iconDynamicMap,
  omniConfig,
} from '@cigna/omni/shared-util';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { map } from 'rxjs/operators';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';

@Component({
  selector: 'cigna-omni-shell-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class OmniShellHeaderComponent {
  public backButtonContent$ = this.facade.backButtonContent$;
  public canGoBack$ = this.facade.canGoBack$;
  public isBackButtonDisabled$ = this.facade.isBackButtonDisabled$;
  public isShellMinimized$ = this.facade.isShellMinimized$;
  public title$ = this.facade.headerTitle$;
  public headerBackgroundClass$ = this.facade.headerBackgroundClass$;
  public isUnmuted$ = this.facade.isUnmuted$;
  public backIcon: Type<IconsDynamic> = IconDynamicChevronComponent;
  public backIconData: IconDynamicData = {
    size: 'sm',
    direction: 'left',
  };
  public iconColors: IconDynamicColors = {
    color: omniConfig.omniColors.blue,
  };

  public toggleButton$ = this.facade.toggleChatStateButton$.pipe(
    map((button) => ({
      copy: button.copy,
      icon: iconDynamicMap[button.icon],
      iconData: button.iconData,
    })),
  );

  public toggleMute() {
    this.facade.toggleMuteState();
  }

  constructor(
    public facade: OmniChatFacade,
    public conversationsFacade: OmniConversationsFacade,
  ) {}

  public back(): void {
    this.facade.back();
  }

  public toggleChatState(copy: string): void {
    if (copy.toLowerCase() === 'close') {
      this.conversationsFacade.setConversationId('');
    }
    this.facade.toggleChatState();
  }
}
