import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureToggleType } from './feature-toggles.actions';
import {
  FeatureTogglesState,
  FEATURETOGGLES_FEATURE_KEY,
} from './feature-toggles.reducer';
import { ToggleValue } from './interfaces';

const getState = createFeatureSelector<FeatureTogglesState>(
  FEATURETOGGLES_FEATURE_KEY,
);

export const hasBeenUpdated = createSelector(
  getState,
  (state) => state.hasBeenUpdated,
);

export const allEnabledFeatures = createSelector(getState, (state) =>
  Object.keys(state.toggles).reduce<{ [key: string]: true }>(
    (toggleStateAcc, currentToggle) => {
      const featureName = currentToggle.split('.')[0];

      if (!toggleStateAcc[featureName] && getToggleState(featureName, state)) {
        toggleStateAcc[featureName] = true;
      }

      return toggleStateAcc;
    },
    {},
  ),
);

export const featuresEnabled = (
  toggles: string[],
  operator: QueryOperator = 'AND',
) =>
  createSelector(getState, (featureTogglesState) =>
    toggles[operator === 'AND' ? 'every' : 'some']((toggle) =>
      getToggleState(toggle, featureTogglesState),
    ),
  );

export const featureValue = (toggle: string) =>
  createSelector(getState, (featureTogglesState) =>
    getToggleState(toggle, featureTogglesState),
  );

export const getFlag = (toggle: string) =>
  createSelector(
    getState,
    (featureTogglesState) => featureTogglesState.toggles[toggle],
  );

function getToggleState(
  toggle: string,
  featureTogglesState: FeatureTogglesState | undefined,
): ToggleValue {
  if (!featureTogglesState) {
    return false;
  }

  const { toggles } = featureTogglesState;
  const toggleStates = [
    toggles[`${toggle}.${FeatureToggleType.OPS}`],
    toggles[`${toggle}.${FeatureToggleType.PERMISSION}`],
    toggles[`${toggle}.${FeatureToggleType.RELEASE}`],
  ].filter((toggleState) => typeof toggleState !== 'undefined');

  return toggleStates.length > 0
    ? toggleStates.reduce((acc, toggleState) => acc && toggleState)
    : false;
}

export type QueryOperator = 'OR' | 'AND';
