import { NgModule, ModuleWithProviders, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { windowFactory } from './window.factory';
import { WindowService } from './window.service';
import { CoreWindowConfig, CORE_WINDOW_CONFIG } from './config';

@NgModule({
  imports: [CommonModule],
})
export class CoreWindowModule {
  static forRoot(
    config: CoreWindowConfig = { isWebview: false, isEmbedded: false },
  ): ModuleWithProviders<CoreWindowModule> {
    return {
      ngModule: CoreWindowModule,
      providers: [
        {
          provide: CORE_WINDOW_CONFIG,
          useValue: config,
        },
        {
          provide: WindowService,
          useFactory: windowFactory,
          deps: [PLATFORM_ID],
        },
      ],
    };
  }
}
