import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map, retry } from 'rxjs/operators';

import { checkValidResponse, logError } from '@cigna/chcp/shared/util';

@Injectable({
  providedIn: 'root',
})
export class UserTinsService {
  public profileApi: string;
  public delgationApi: string;

  public CONSUMER_CODE = '1000';

  constructor(
    private http: HttpClient,
    @Inject('secureApi') secureApi: string,
  ) {
    this.profileApi = `${secureApi}/profile/v2`;
  }

  getTins() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    return this.http
      .get(`${this.profileApi}/tin-details`, {
        params,
      })
      .pipe(
        retry(1),
        map(checkValidResponse),
        map((res) => res.tinDetails.tins),
        catchError(logError),
      );
  }

  getTinFunctions(entitlements: string[], functions: string[]) {
    let params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );
    if (functions?.length) {
      params = params.append('functions', functions.toString());
    }
    if (entitlements?.length) {
      params = params.append('entitlements', entitlements.toString());
    }

    return this.http
      .get(`${this.profileApi}/tins/functions`, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) => res?.user?.tinAccess),
        catchError(logError),
      );
  }
}
