import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';

@Component({
  selector: 'cigna-omni-splash-close-screen',
  templateUrl: './chat-splash-close-screen.component.html',
  styleUrls: ['./chat-splash-close-screen.component.scss'],
})
export class OmniMessagingSplashCloseScreenComponent implements AfterViewInit {
  @Input() public screenText: string;
  @Input() public conversationId: string;
  @ViewChild('textRef', { static: true })
  public textRef: ElementRef;

  constructor(
    private omniChatFacade: OmniChatFacade,
    private cnvFacade: OmniConversationsFacade,
  ) {}

  public endChat() {
    this.omniChatFacade.closeDialog();
    this.cnvFacade.endConversation(this.conversationId);
  }

  public keepChat() {
    this.cnvFacade.setCloseSplashScreen(false);
  }

  ngAfterViewInit() {
    this.textRef.nativeElement.focus();
  }
}
