import { InjectionToken } from '@angular/core';
import zipObject from 'lodash/zipObject';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ContentBundleConfig } from '@cigna/shared/angular/resource-content-data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppContext } from '@cigna/chcp/shared/util';

export const APP_CONTEXT = new InjectionToken<AppContext>('appContext');

export const ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentConfig>(
  'environmentConfig',
);

import { ContentfulSdkConfig } from '@cigna/shared/angular/contentful-util';

export interface EnvironmentConfig {
  production: boolean;
  oidc: {
    clientId: string;
    redirectUri: string;
    metadataUri: string;
    authorizeUri: string;
    secureUris: string[];
    noRefreshUris: string[];
    publicUris: string[];
  };
  redirect: {
    crossOrigin: {
      domain: string;
    };
    mhk?: string;
    hsConnect?: string;
    inbox?: string;
    upx?: {
      submit?: string;
      modify?: string;
    };
  };
  omni?: {
    sonicNotification: string;
    identityUrl?: string;
    botUrl?: string;
    liveUrl?: string;
    url?: string;
    publicUrl?: string;
  };
  api: {
    gateways: {
      cdn: string;
      resourceContent: string;
      secureApi: string;
      publicApi: string;
      gsgPublicApi: string;
      gsgSecureApi: string;
      graphql: string;
    };
    proxy: {
      headers: {
        [header: string]: string;
      };
    };
    searchIndex: string;
    salesDemo: boolean;
  };
  contentBundle: ContentBundleConfig;
  sessionTimeoutPopup: {
    timeoutDuration: number;
    logoutDuration: number;
    keepAliveUrl: string;
  };
  features: FeatureToggles;
  launchDarklyClientId: string;
  contentful: ContentfulSdkConfig;
  disabledRoutes: string[];
}

export const contentfulMaster: ContentfulSdkConfig = {
  space: 'dy4ji17036nn',
  accessToken: 'xbkx9SXqhwXeyokgOfxGprSnJCuPQiF6oNdPybt0eDM',
  previewApi: false,
};

export const contentfulPreview: ContentfulSdkConfig = {
  space: 'dy4ji17036nn',
  accessToken: 'EIGJz3HD8fCrHQkb_cq0QihsOmZbjdk31MF5HivWSLg',
  previewApi: true,
};

export const environmentDefaults = {
  production: true,
  sessionTimeoutPopup: {
    timeoutDuration: 25,
    logoutDuration: 5,
    keepAliveUrl: '/app/assets/images/isam-keepalive.gif',
  },
  contentBundle: {
    manifestFilename: `content/chcp/manifest.json?t=${Date.now().toString()}`,
    resolvePageContentTimeoutSeconds: 1,
  },
  contentful: contentfulMaster,
};

export const environmentDefaultsEvernorth = {
  ...environmentDefaults,
  contentBundle: {
    manifestFilename: `content/evernorth-provider/manifest.json?t=${Date.now().toString()}`,
    resolvePageContentTimeoutSeconds: 1,
  },
};

export const oidcDefault = {
  clientId: 'chcp_spa_client',
  redirectUri: '/app/auth_callback.html',
  metadataUri: '/mga/sps/oauth/oauth20/metadata/chcp_spa_def',
  authorizeUri: '/mga/sps/oauth/oauth20/authorize',
  publicUris: [],
  noRefreshUris: [],
};

export const oidcEvernorthDefault = {
  clientId: 'evernorth_provider_spa_client',
  redirectUri: '/app/auth_callback.html',
  metadataUri: '/mga/sps/oauth/oauth20/metadata/evernorth_provider_spa_def',
  authorizeUri: '/mga/sps/oauth/oauth20/authorize',
  publicUris: [],
  noRefreshUris: [],
};

export const CDN_BASE_URL = new InjectionToken<string>('CDN_BASE_URL');

export const featureFlags = [
  'nextRelease', // dont remove me :)
  // omni flag start
  'hasRestrictedChat',
  'enableRestrictedChatInPortal',
  'enableNewPreChatWelcome',
  'enableChatAws',
  'enableChatAnalyticsDataLayer',
  'hideDisclaimer',
  // omni flags end
  'patientEnhPower',
  'globalBenEnh',
  'claim360GA',
  'enableBenefitFlexibility',
  'hasCBHAmIInNetwork',
  'medicareclaim',
  'enablePCM',
  'pilotMHKUser',
  'disableCocePretrigger',
  'cocePathwellPCLM',
];

export type Feature = (typeof featureFlags)[number];

export type FeatureToggles = Record<Feature, boolean>;

export const featureFlagDefaults: FeatureToggles = zipObject(
  featureFlags,
  Array(featureFlags.length).fill(true),
);
