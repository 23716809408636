import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniGateway } from '@cigna/omni/shared-util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OmnibusDataAccessService {
  constructor(private http: HttpClient, private gateway: OmniGateway) {}

  public postOmnibus(commands: any[]): Observable<any[]> {
    const url = this.gateway.omnibusUrl();
    return this.http.post<any[]>(url, commands);
  }
}
