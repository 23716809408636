<div class="cg-hide-for-print">
  <div class="upper-footer">
    <div class="footer-inner-wrapper">
      <div class="container-fluid cg-margin-bottom-md cg-margin-top-md">
        <div class="row">
          <div class="col-sm-3">
            <h3 class="cg-h4 cg-margin-bottom-none">Site tour</h3>
            <div class="cg-margin-bottom-sm">(4 minute video)</div>
            <div class="cg-margin-bottom-md">
              <div>
                <button
                  cignaButton="link"
                  style="padding: 0"
                  (click)="openSiteTourModal()"
                  data-test="footer-sitetour-link"
                  [cignaTrackClicks]="[
                    'trackEventOther',
                    'Quick website overview',
                    'ac-NV008-Footer Links',
                    {
                      prop20: 'footer-main-links'
                    }
                  ]"
                >
                  Quick website overview
                </button>
              </div>
            </div>
            <h3 class="cg-h4 cg-margin-bottom-none cg-margin-top-lg">
              Education and training tutorials
            </h3>
            <div class="cg-margin-bottom-sm">
              (videos approx. 5 minutes each)
            </div>
            <div class="cg-margin-bottom-md">
              <div
                class="cg-margin-bottom-xs"
                *ngIf="!isLoggedIn || (isLoggedIn && isMedical)"
              >
                <a
                  target="resourcesWindow"
                  [href]="
                    '/resourceLibrary/eCourses/eCoursesMedicalListing'
                      | resourceLink : { secure: isLoggedIn }
                      | async
                  "
                  [cignaTrackClicks]="[
                    'trackEventOther',
                    'Medical',
                    'ac-NV012-Education Links',
                    { prop20: 'footer-education-links' }
                  ]"
                  >Medical</a
                >
              </div>
              <div class="cg-margin-bottom-xs">
                <a
                  target="resourcesWindow"
                  [cignaTrackClicks]="[
                    'trackEventOther',
                    'Dental',
                    'ac-NV012-Education Links',
                    { prop20: 'footer-education-links' }
                  ]"
                  [href]="
                    '/resourceLibrary/eCourses/eCoursesDentalListing'
                      | resourceLink : { secure: isLoggedIn }
                      | async
                  "
                  >Dental</a
                >
              </div>
              <div
                class="cg-margin-bottom-xs"
                *ngIf="!isLoggedIn || (isLoggedIn && isMedical)"
              >
                <a
                  target="resourcesWindow"
                  [href]="
                    '/resourceLibrary/eCourses/eCourseBehavioralListing'
                      | resourceLink : { secure: isLoggedIn }
                      | async
                  "
                  [cignaTrackClicks]="[
                    'trackEventOther',
                    'Behavioral',
                    'ac-NV012-Education Links',
                    { prop20: 'footer-education-links' }
                  ]"
                  >Behavioral</a
                >
              </div>
              <div></div>
            </div>
          </div>
          <div class="col-sm-3">
            <h3 class="cg-h4 cg-margin-bottom-md">Other Cigna websites</h3>
            <div class="cg-margin-bottom-md">
              <div>Medicare Providers</div>
              <div>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://medicareproviders.cigna.com"
                  [cignaTrackClicks]="[
                    'trackEventOther',
                    'https://medicareproviders.cigna.com',
                    'ac-NV008-Footer Links'
                  ]"
                  >https://medicareproviders.cigna.com<mat-icon
                    svgIcon="leaf-system-regular-external-link"
                    class="cg-mat-icon-link-right"
                  ></mat-icon>
                </a>
              </div>
            </div>
            <div class="cg-margin-bottom-md">
              <div>Cigna Global Health Benefits</div>
              <div>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://provider.cignaenvoy.com"
                  [cignaTrackClicks]="[
                    'trackEventOther',
                    'https://provider.cignaenvoy.com',
                    'ac-NV008-Footer Links'
                  ]"
                  >https://provider.cignaenvoy.com<mat-icon
                    svgIcon="leaf-system-regular-external-link"
                    class="cg-mat-icon-link-right"
                  ></mat-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div>
              <h3 class="cg-h4 cg-margin-bottom-md">Follow us</h3>
              <div class="social-links">
                <a
                  href="http://www.twitter.com/cigna"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-twitter"
                  aria-label="twitter"
                  [cignaTrackClicks]="[
                    'trackEventExit',
                    'Twitter.com',
                    'ac-NV009-Social Media',
                    {
                      prop20: 'footer-social-profile-links'
                    }
                  ]"
                >
                  <cigna-icon type="twitter2" class="social-icon"></cigna-icon>
                </a>

                <a
                  href="http://www.linkedin.com/companies/cigna"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-linkedin"
                  aria-label="linkedin"
                  [cignaTrackClicks]="[
                    'trackEventExit',
                    'Linkedin.com',
                    'ac-NV009-Social Media',
                    {
                      prop20: 'footer-social-profile-links'
                    }
                  ]"
                >
                  <cigna-icon type="linkedin2" class="social-icon"></cigna-icon>
                </a>
                <a
                  href="http://www.facebook.com/cigna"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-facebook"
                  aria-label="facebook"
                  [cignaTrackClicks]="[
                    'trackEventExit',
                    'Facebook.com',
                    'ac-NV009-Social Media',
                    {
                      prop20: 'footer-social-profile-links'
                    }
                  ]"
                >
                  <cigna-icon type="facebook2" class="social-icon"></cigna-icon>
                </a>
                <a
                  href="http://www.youtube.com/cigna"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="social-youtube"
                  aria-label="youtube"
                  [cignaTrackClicks]="[
                    'trackEventExit',
                    'YouTube.com',
                    'ac-NV009-Social Media',
                    {
                      prop20: 'footer-social-profile-links'
                    }
                  ]"
                >
                  <cigna-icon type="youtube2" class="social-icon"></cigna-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="flex-row">
              <a
                href="http://www.cigna.com/health-careprofessionals/health-care-reform"
                class="cg-margin-right-xl"
                style="margin-left: auto"
                target="_blank"
                rel="noopener noreferrer"
                [cignaTrackClicks]="[
                  'trackEventOther',
                  'Informed on Reform',
                  'ac-NV011-Icons',
                  { prop20: 'footer-icon-links' }
                ]"
              >
                <img
                  [src]="'assets/images/footer/informed.png' | cdnPrefix"
                  alt="informed on reform"
                  title="informed on reform"
                  width="79"
                  height="73"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.caqh.org/CORE"
                class="cg-margin-right-xl"
                target="_blank"
                rel="noopener noreferrer"
                [cignaTrackClicks]="[
                  'trackEventExit',
                  'Core',
                  'ac-NV011-Icons',
                  { prop20: 'footer-icon-links' }
                ]"
              >
                <img
                  [src]="'assets/images/footer/core1.png' | cdnPrefix"
                  alt="CAQH-CORE"
                  title="CAQH-CORE"
                  width="53"
                  height="73"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lower-footer">
    <div class="footer-inner-wrapper">
      <div class="cg-margin-bottom-lg">
        &copy; {{ currentYear }} Cigna. All rights reserved.
      </div>
      <div>
        <button
          style="padding: 0"
          cignaButton="link"
          class="lower-footer__link"
          (click)="openSiteHelpModal()"
          data-test="footer-help-link"
          [cignaTrackClicks]="[
            'trackEventOther',
            'Need help?',
            'ac-NV010-Legal Links',
            { prop20: 'footer-legal-links' }
          ]"
        >
          Need help?
        </button>
        <a
          class="lower-footer__link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cigna.com/legal/compliance/terms-of-use"
          >Terms of Use</a
        >
        <a
          class="lower-footer__link"
          target="resourcesWindow"
          [href]="
            '/resourceLibrary/provider/disclaimers/siteSpecificTerms'
              | resourceLink : { secure: false }
              | async
          "
          >Site Specific Terms</a
        >
        <a
          class="lower-footer__link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cigna.com/static/www-cigna-com/docs/website-privacy-notice.pdf"
          data-test="footer-privacy-link"
          [cignaTrackClicks]="[
            'trackEventOther',
            'Privacy',
            'ac-NV010-Legal Links',
            { prop20: 'footer-legal-links' }
          ]"
          >Privacy Notice</a
        >
        <a
          class="lower-footer__link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cigna.com/static/www-cigna-com/docs/web-nondiscrimination-notice.pdf"
          [cignaTrackClicks]="[
            'trackEventDownload',
            'Nondiscrimination notice',
            'ac-NV010-Legal Links',
            { prop20: 'footer-legal-links' }
          ]"
          >Nondiscrimination notice</a
        >
        <button
          *ngIf="hasOneTrustEnabled && !isLoggedIn"
          cignaButton="link"
          style="padding: 0"
          class="ot-sdk-show-settings lower-footer__link"
        >
          Cookie Settings
        </button>
      </div>
      <div class="footer-disclaimer">
        <mat-icon
          svgIcon="leaf-system-regular-external-link"
          class="cg-mat-icon-link-left"
        ></mat-icon>
        Selecting these links will take you away from CignaforHCP.com. Cigna
        does not control the linked sites' content or links.
        <a
          class="lower-footer__link"
          href="http://www.cigna.com/legal-disclaimer"
          target="_blank"
          rel="noopener noreferrer"
          >Details</a
        >
      </div>
    </div>
  </div>
</div>
