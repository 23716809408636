import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable, race, timer } from 'rxjs';
import { filter, take, switchMap } from 'rxjs/operators';
import difference from 'lodash/difference';

import { ContentBundlePartialState } from './content-bundle.reducer';
import { contentBundleQuery } from './content-bundle.selectors';
import {
  changeLanguage,
  requestBundles,
  resetContentBundle,
} from './content-bundle.actions';
import {
  Locale,
  DEFAULT_LANG,
  Manifest,
  CONTENT_BUNDLE_CONFIG,
  ContentBundleConfig,
} from './content-bundle.config';

@Injectable({ providedIn: 'root' })
export class ContentBundleFacade {
  manifest$ = this._store$
    .select(contentBundleQuery.getManifest)
    .pipe(filter<Manifest>(Boolean));

  lang$ = this._store$
    .select(contentBundleQuery.getCurrentLanguage)
    .pipe(filter<Locale>(Boolean));

  public getLoaded(lang?: Locale): Observable<string[]> {
    return this._store$
      .select(
        lang ? contentBundleQuery.getLoaded : contentBundleQuery.getCurrLoaded,
        { lang },
      )
      .pipe(filter<string[]>(Boolean));
  }

  public getLoading(lang?: Locale): Observable<string[]> {
    return this._store$
      .select(
        lang
          ? contentBundleQuery.getLoading
          : contentBundleQuery.getCurrLoading,
        { lang },
      )
      .pipe(filter<string[]>(Boolean));
  }

  public getRequestedFiles(locale: Locale, name: string): Observable<string[]> {
    return this._store$.select(contentBundleQuery.getRequestedFiles, {
      locale,
      name,
    });
  }

  public changeLang(lang: Locale): void {
    this._store$.dispatch(changeLanguage({ lang }));
  }

  public resetContentBundle(): void {
    this._store$.dispatch(resetContentBundle());
  }

  public loadBundle(
    bundle: string | string[],
    lang?: Locale,
  ): Observable<string[]> {
    const bundles = Array.isArray(bundle) ? bundle : [bundle];

    if (lang !== DEFAULT_LANG) {
      this._store$.dispatch(
        requestBundles({
          bundles,
          lang: DEFAULT_LANG,
        }),
      );
    }

    this._store$.dispatch(
      requestBundles({
        lang,
        bundles,
      }),
    );
    return race(
      this.getLoaded(lang).pipe(
        filter((loaded) => difference(bundles, loaded).length === 0),
      ),
      timer((this._config.resolvePageContentTimeoutSeconds || 5) * 1000).pipe(
        switchMap(() => this.getLoaded(lang)),
      ),
    ).pipe(take(1));
  }

  constructor(
    private _store$: Store<ContentBundlePartialState>,
    private _ngxTranslateService: TranslateService,
    @Inject(CONTENT_BUNDLE_CONFIG) private _config: ContentBundleConfig,
  ) {
    this._ngxTranslateService.setDefaultLang(DEFAULT_LANG);
    this._ngxTranslateService.use(DEFAULT_LANG);
  }
}
