import { format } from 'date-fns';
import { ReconsiderationType } from '@cigna/chcp/shared/util';
import { Adjustment, MemberGroupRequest } from './reconsideration.model';
import { ClaimDetails } from './claims.model';

export function isPreserviceAppeal(v: Adjustment): boolean {
  return (
    v.reconsiderationType === ReconsiderationType.APPEAL &&
    v.reviewType === 'Pre-Service'
  );
}

export function isDentalPreserviceAppeal(v: Adjustment): boolean {
  return isPreserviceAppeal(v) && v.coverageType === 'DENT';
}

export function getGroupMemberRequest(
  claimDetailObject: ClaimDetails,
  lob: string,
): MemberGroupRequest {
  const dateFormat = 'yyyy-MM-dd';
  const { memberObject, claimInfoObject } = claimDetailObject;
  const dateOfService = claimInfoObject?.claimDateOfService?.split('-')[0];
  return {
    relationshipCode: memberObject.relationShipCode || '',
    memberId: memberObject.memberId || claimInfoObject.ami || '',
    memberFirstName: memberObject.memberFirstName || '',
    memberLastName: memberObject.memberLastName || '',
    memberIdentifier: memberObject.memberIdentifier || '',
    memberDateOfBirth: format(
      new Date(memberObject?.memberDateOfBirth),
      dateFormat,
    ),
    coverageType: lob?.toUpperCase() === 'DEN' ? 'DENT' : 'MED',
    eligibilityAsOfDate: format(
      dateOfService && !!Date.parse(dateOfService)
        ? new Date(dateOfService)
        : new Date(),
      dateFormat,
    ),
  };
}
