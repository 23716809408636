<svg
  width="12px"
  height="12px"
  viewBox="0 0 12 12"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g
    id="SYMBOLS"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="Icon-/-Nav-/-Close-/-Blue"
      transform="translate(-40.000000, -1.000000)"
      [attr.fill]="colors.color"
      fill-rule="nonzero"
    >
      <g id="Group-2" transform="translate(40.000000, 1.000000)">
        <path
          d="M5.44444444,-0.477777778 C5.44444444,-0.876650097 5.76779435,-1.2 6.16666667,-1.2 C6.56553899,-1.2 6.88888889,-0.876650097 6.88888889,-0.477777778 L6.88888889,12.8111111 C6.88888889,13.2099834 6.56553899,13.5333333 6.16666667,13.5333333 C5.76779435,13.5333333 5.44444444,13.2099834 5.44444444,12.8111111 L5.44444444,-0.477777778 Z"
          id="Combined-Shape"
          transform="translate(6.166667, 6.166667) rotate(-315.000000) translate(-6.166667, -6.166667) "
        ></path>
        <path
          d="M5.44444444,-0.477777778 C5.44444444,-0.876650097 5.76779435,-1.2 6.16666667,-1.2 C6.56553899,-1.2 6.88888889,-0.876650097 6.88888889,-0.477777778 L6.88888889,12.8111111 C6.88888889,13.2099834 6.56553899,13.5333333 6.16666667,13.5333333 C5.76779435,13.5333333 5.44444444,13.2099834 5.44444444,12.8111111 L5.44444444,-0.477777778 Z"
          id="Combined-Shape"
          transform="translate(6.166667, 6.166667) scale(-1, 1) rotate(-315.000000) translate(-6.166667, -6.166667) "
        ></path>
      </g>
    </g>
  </g>
</svg>
