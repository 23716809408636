import { InjectionToken } from '@angular/core';

export const SHARED_GATEWAY_CONFIG = new InjectionToken<GatewayConfig>(
  'SHARED_GATEWAY_CONFIG',
);

export interface GatewayConfig {
  cdn: string;
  resourceContent: string;
  directory: string;
  directoryTypeahead: string;
  gsg: string;
  directoryGeolocation: string;
  launchDarkly: string;
  eirAnalytics: string;
  elevateSurvey: string;
  communicationPreferencesUrl: string;
}

export enum ApiVersion {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3',
}
