<cigna-omni-my-conversations
  *ngIf="isMyConversationsFeatured$ | async"
  data-omni-qa="omni-comm-channel-my-conversations-featured"
></cigna-omni-my-conversations>
<cigna-omni-comm-channel-support
  *cignaFeatureToggle="'liveSupportChat'"
></cigna-omni-comm-channel-support>
<cigna-omni-comm-channel-che
  *cignaFeatureToggle="'liveClinicianChat'"
></cigna-omni-comm-channel-che>
<cigna-omni-comm-channel-eap
  *cignaFeatureToggle="'advocateChat'"
></cigna-omni-comm-channel-eap>
<cigna-omni-my-conversations
  *ngIf="(isMyConversationsFeatured$ | async) === false"
  data-omni-qa="omni-comm-channel-my-conversations"
></cigna-omni-my-conversations>
