<div class="cg-dialog">
  <h2 class="cg-dialog__header">
    Site Tour<button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      (click)="dialogRef.close()"
    >
      Close
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h2>

  <iframe
    class="videoIframe"
    src="https://players.brightcove.net/6415943385001/default_default/index.html?videoId=6366269430112"
    allowfullscreen
  ></iframe>
</div>
