import { Type } from '@angular/core';
import {
  isClinicalNursingContextTags,
  isLegacyCoachingContextTags,
  isScheduleCoachingContextTags,
  isSupportOpsContextTags,
  omniColors,
  isBehavioralContextTags,
  isMedicalContextTags,
  IconDynamicHeadsetComponent,
  IconDynamicHeartInHandComponent,
  IconDynamicStethoscopeComponent,
} from '@cigna/omni/shared-util';
import { ConversationSummaryDTO } from '@cigna/vampire-dto';

export function sortByClosedStateAndDates(
  conv1: ConversationSummaryDTO,
  conv2: ConversationSummaryDTO,
) {
  const date1 = new Date(conv1.updated);
  const date2 = new Date(conv2.updated);
  // new messages are sorted to the top
  if (conv1.state !== 'closed' && conv2.state === 'closed') {
    return -1;
  }
  if (conv1.state === 'closed' && conv2.state !== 'closed') {
    return 1;
  }
  // within new and unread groups we sort by date
  return date2.getTime() - date1.getTime();
}

export function generateIconType(
  context: ConversationSummaryDTO['context'],
): Type<
  | IconDynamicHeadsetComponent
  | IconDynamicHeartInHandComponent
  | IconDynamicStethoscopeComponent
> {
  if (
    isClinicalNursingContextTags(context.tags) ||
    isLegacyCoachingContextTags(context.tags)
  ) {
    return IconDynamicStethoscopeComponent;
  }
  if (
    isScheduleCoachingContextTags(context.tags) ||
    isSupportOpsContextTags(context.tags)
  ) {
    return IconDynamicHeadsetComponent;
  }

  if (
    isBehavioralContextTags(context.tags) ||
    isMedicalContextTags(context.tags)
  ) {
    return IconDynamicHeartInHandComponent;
  }
  return IconDynamicHeadsetComponent;
}

export function generateSummaryListItemSubject(
  state: ConversationSummaryDTO['state'],
  newMessages: boolean,
  type: ConversationSummaryDTO['type'],
): string {
  if (state === 'closed') {
    return 'Closed';
  }
  if (newMessages) {
    return 'New Message';
  }
  if (type === 'live') {
    return 'Active Chat';
  }
  // async and catch all
  return 'No Reply Yet';
}

function generateSummaryListItemType(
  state: ConversationSummaryDTO['state'],
  newMessages: boolean,
  type: ConversationSummaryDTO['type'],
): string {
  if (type === 'live') {
    return 'Live Chat';
  } else if (type === 'async') {
    if (state === 'opened' && newMessages) {
      return 'New Message';
    }
    return 'Direct Message';
  }
  return '';
}

function generateSummaryListItemTypeIcon(
  type: ConversationSummaryDTO['type'],
): string {
  if (type === 'live') {
    return 'live-chat';
  } else if (type === 'async') {
    return 'direct-message';
  }
  return '';
}

export function generateSummaryListItem(c: ConversationSummaryDTO) {
  return {
    body: c.title,
    chevronColor: { color: omniColors.blue },
    icon: generateIconType(c.context),
    conversationId: c._id,
    date: c.updated as any, // this is not typed well for consumers, it is actually a string, but the model has Date as the type
    isClosed: c.state === 'closed',
    iconColors: {
      badgeFillColor:
        c.state === 'closed' ? omniColors.digitalRed : omniColors.digitalGreen,
      badgeStrokeColor: omniColors.white,
      color: c.state === 'closed' ? omniColors.gray40 : omniColors.blue,
    },
    iconData: {
      showBorder: true,
      showNotificationBadge: c.newMessages,
    },
    newMessages: c.newMessages,
    state: c.state,
    subject: generateSummaryListItemSubject(c.state, c.newMessages, c.type),
    context: c.context,
  };
}

export function generateAgentSummaryListItem(c: ConversationDTOAgentID) {
  return {
    body: c.title,
    chevronColor: { color: omniColors.blue },
    icon: generateIconType(c.context),
    conversationId: c._id,
    date: c.updated.toString(), // this is not typed well for consumers, it is actually a string, but the model has Date as the type
    isClosed: c.state === 'closed',
    iconColors: {
      badgeFillColor:
        c.state === 'closed' ? omniColors.digitalRed : omniColors.digitalGreen,
      badgeStrokeColor: omniColors.white,
      color: c.state === 'closed' ? omniColors.gray40 : omniColors.blue,
    },
    iconData: {
      showBorder: true,
      showNotificationBadge: c.newMessages,
    },
    newMessages: c.newMessages,
    state: c.state,
    subject: generateSummaryListItemSubject(c.state, c.newMessages, c.type),
    context: c.context,
    agentData: c.agentData,
    type: c.type,
    typeLabel: generateSummaryListItemType(c.state, c.newMessages, c.type),
    labelIcon: generateSummaryListItemTypeIcon(c.type),
    lastMessageBy: c.lastMessageBy?.name || '',
  };
}

export interface ConversationDTOAgentID extends ConversationSummaryDTO {
  agentData?: ConversationAgentData;
}

export interface ConversationAgentData {
  firstName?: string;
  id?: string;
}
