<div class="content-rail">
  <article class="page">
    <h1 class="cg-h1">404 Page Not Found</h1>
    <div class="cg-margin-bottom-lg">
      <p>The following page doesn't exist.</p>
      <div class="url-highlight">{{ url }}</div>
    </div>

    <ng-container *ngIf="loggedIn$ | async; else loginPageLink">
      <a routerLink="/dashboard">Return to dashboard</a>
    </ng-container>
    <ng-template #loginPageLink>
      <a routerLink="/login">Return to login page</a>
    </ng-template>
  </article>
</div>
