import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY, UserState } from './user.reducer';
import { ProviderTin, TinSummary } from '../user-tins/tins.model';
import { FlaggedClaim } from '../user-flags/flags.model';
import { differenceInDays, parse, format, isDate } from 'date-fns';
import {
  Entitlement,
  LOB,
  LOB_BEHAVIORAL,
  LOB_MEDBH,
  LOB_MEDICAL,
} from '@cigna/chcp/auth/util';
import { AppContext } from '@cigna/chcp/shared/util';
import uniq from 'lodash/uniq';

const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

const getUserProfile = createSelector(getUserState, (state) => state.profile);
const getFirstName = createSelector(getUserProfile, (profile) =>
  profile ? profile.firstName : '',
);
const getLastName = createSelector(getUserProfile, (profile) =>
  profile ? profile.lastName : '',
);
const getUserFullName = createSelector(getUserProfile, (profile) =>
  profile ? `${profile.firstName} ${profile.lastName}` : '',
);
const getUserEmail = createSelector(getUserProfile, (profile) =>
  profile ? profile.email : '',
);
const getUserPhone = createSelector(getUserProfile, (profile) =>
  profile ? profile.phone : '',
);
const getUserMobilePhone = createSelector(getUserProfile, (profile) =>
  profile ? profile.mobilePhone : '',
);
const getContactUpdated = createSelector(
  getUserProfile,
  (profile) => profile?.contactUpdated || '',
);
const isEmailVerified = createSelector(
  getUserProfile,
  (profile) => profile?.emailVerified === 'Y' || false,
);
const isMobileVerified = createSelector(
  getUserProfile,
  (profile) => profile?.mobileVerified === 'Y' || false,
);
const getContactCntr = createSelector(
  getUserProfile,
  (profile) => profile?.contactCntr || 0,
);
const hasUndefinedLobPref = createSelector(
  getUserProfile,
  (profile) => profile?.lobPref === 'TBD',
);
const isSkipExceeded = createSelector(
  getContactCntr,
  (count: number) => count >= 3 || false,
);
const getEmailVerifySkipCount = createSelector(
  getUserProfile,
  (profile) => profile?.emailVerifySkipCount || 0,
);
const isEmailVerifiedSkipExceeded = createSelector(
  getEmailVerifySkipCount,
  (count: number) => count >= 3 || false,
);
const getTinsWithDetails = createSelector(getUserState, (state) => state.tins);
const getTinFunctionsDetails = createSelector(
  getUserState,
  (state) => state.tinFunctions,
);

const getPreferredLob = createSelector(getUserProfile, (profile) =>
  profile ? profile.lobPref : '',
);

const getTins = createSelector(getTinsWithDetails, (tins: ProviderTin[]) =>
  tins.map(
    (tin): TinSummary => ({
      tinName: tin.tinName,
      tinId: tin.tinId,
      contracted: tin.contracted,
      generatedTinId: tin.generatedTinId,
      tinLob: tin.tinLob,
    }),
  ),
);

const getTinLob = (tin: string) =>
  createSelector(
    getTinsWithDetails,
    (tins) => (tins.find((t) => t.tinId === tin) as ProviderTin)?.tinLob,
  );

const getTinsWithFilters = (props: {
  entitlements: Entitlement[];
  onlyContracted: boolean;
  lob: LOB | '';
  context: AppContext | '';
}) =>
  createSelector(getTinsWithDetails, (tins) =>
    tins
      .filter(
        (tin) =>
          props.entitlements.length === 0 ||
          props.entitlements.some((e) =>
            tin.entitlementsJWTFormatted?.includes(e),
          ),
      )
      .filter((tin) => (props.onlyContracted ? tin.contracted : true))
      .filter((tin) => {
        if (!props.lob) {
          return true;
        }

        const isEvernorth = props.context === 'evernorth';
        const hasUserValidLob = props.lob === LOB_MEDBH;
        const isMedbhOrBeh =
          tin.tinLob === LOB_MEDBH || tin.tinLob === LOB_BEHAVIORAL;
        const isMedbhOrMed =
          tin.tinLob === LOB_MEDBH || tin.tinLob === LOB_MEDICAL;

        return (
          (hasUserValidLob && isEvernorth && isMedbhOrBeh) ||
          (hasUserValidLob && !isEvernorth && isMedbhOrMed) ||
          props.lob === tin.tinLob
        );
      })
      .sort((a, b) => a.tinId.localeCompare(b.tinId)),
  );

const getLobsFromTins = createSelector(getTinsWithDetails, (tins) =>
  uniq(tins.map((tin: ProviderTin) => tin.tinLob)),
);

const getTinStatus = createSelector(getUserState, (state) => state.tinStatus);
const getTinFunctionsStatus = createSelector(
  getUserState,
  (state) => state.tinFunctionsStatus,
);

const getSecurityQuestions = createSelector(
  getUserState,
  (state) => state.securityQuestions,
);

const getFlaggedPatients = createSelector(getUserState, (state) =>
  state.flaggedPatients && state.flaggedPatients.length > 0
    ? state.flaggedPatients
        .slice()
        .sort(
          (a, b) =>
            new Date(b.dateFlagged).getTime() -
            new Date(a.dateFlagged).getTime(),
        )
    : [],
);
const getFlaggedPatientKeys = createSelector(
  getFlaggedPatients,
  (flaggedPatients) =>
    flaggedPatients
      ? flaggedPatients
          .map((patient) =>
            patient.coverage ? `${patient.coverage.compositeKey}` : '',
          )
          .filter((k) => k !== '')
      : [],
);

const getFlaggedClaims = createSelector(getUserState, (state) =>
  state.flaggedClaims && state.flaggedClaims.length > 0
    ? state.flaggedClaims
        .slice()
        .sort(
          (a, b) =>
            new Date(b.dateFlagged).getTime() -
            new Date(a.dateFlagged).getTime(),
        )
    : [],
);

const getFlaggedClaimsKeys = createSelector(getFlaggedClaims, (flaggedClaims) =>
  flaggedClaims
    ? flaggedClaims
        .map(
          (claim: FlaggedClaim) =>
            claim.claimSummary?.claimReferenceNumber || '',
        )
        .filter((k) => k !== '')
    : [],
);

const isFlaggedClaim = (flagkey: string) =>
  createSelector(getFlaggedClaims, (claims) =>
    claims.some((claim) => claim.claimSummary.claimReferenceNumber === flagkey),
  );
const isFlaggedPatient = (flagkey: string) =>
  createSelector(getFlaggedPatients, (patients) =>
    patients.some((patient) => patient.coverage.compositeKey === flagkey),
  );
const getRecentPatients = createSelector(
  getUserState,
  (state) => state.recentPatients,
);
const getRecentClaims = createSelector(
  getUserState,
  (state) => state.recentClaims,
);

const patientsLoadingState = createSelector(
  getUserState,
  (state) => state.isPatientsLoading,
);

const claimsLoadingState = createSelector(
  getUserState,
  (state) => state.isClaimsLoading,
);

const getPasswordLastChangedDate = createSelector(getUserProfile, (profile) =>
  profile &&
  profile.secPwdLastChangedDate &&
  isDate(new Date(profile.secPwdLastChangedDate))
    ? format(
        parse(profile.secPwdLastChangedDate, 'yyyy-MM-dd', new Date()),
        'yyyy-MM-dd',
      )
    : null,
);

const shouldShowExpiredPasswordWarning = createSelector(
  getPasswordLastChangedDate,
  (changeDate) =>
    changeDate !== null &&
    differenceInDays(new Date(), new Date(changeDate)) >= 105,
);

const getHideEmailPopup = createSelector(
  getUserState,
  (state) => state.hideEmailPopup,
);

const getClaimsPopupSupressed = createSelector(
  getUserProfile,
  (state) => state?.isClaims360PopupSuppressed,
);

const getWamOnboardingStatus = createSelector(
  getUserProfile,
  (state) => state?.shouldShowWamOnboardingStatus,
);

const getUpdatePrefLobSuccess = createSelector(
  getUserState,
  (state) => state.updatePrefLobSuccess,
);

const getDualTinStatus = createSelector(getUserState, (state) =>
  state.tins.some((tin) => tin.tinLob === 'MEDBH'),
);

export const userQuery = {
  getUserProfile,
  getFirstName,
  getLastName,
  getUserFullName,
  getUserEmail,
  getUserPhone,
  getUserMobilePhone,
  getTins,
  getTinLob,
  getPreferredLob,
  getTinsWithFilters,
  getTinsWithDetails,
  getTinStatus,
  getTinFunctionsDetails,
  getTinFunctionsStatus,
  getSecurityQuestions,
  getFlaggedPatients,
  getFlaggedPatientKeys,
  getFlaggedClaims,
  getFlaggedClaimsKeys,
  isFlaggedClaim,
  isFlaggedPatient,
  getRecentPatients,
  getRecentClaims,
  patientsLoadingState,
  claimsLoadingState,
  shouldShowExpiredPasswordWarning,
  getPasswordLastChangedDate,
  getContactUpdated,
  isEmailVerified,
  getContactCntr,
  isSkipExceeded,
  getHideEmailPopup,
  getEmailVerifySkipCount,
  isEmailVerifiedSkipExceeded,
  isMobileVerified,
  hasUndefinedLobPref,
  getClaimsPopupSupressed,
  getWamOnboardingStatus,
  getLobsFromTins,
  getUpdatePrefLobSuccess,
  getDualTinStatus,
};
