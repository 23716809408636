import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AuthErrorHandler,
  AuthFacade,
} from '@cigna/shared/angular/auth-data-access';
import { AuthError } from '@cigna/shared/angular/auth/oidc-util';

import { AccessToken } from './access-token.model';
import { IdToken } from './id-token.model';
import { JWT_NS } from '@cigna/chcp/auth/util';

@Injectable({
  providedIn: 'root',
})
export class ChcpAuthenticationService implements AuthErrorHandler {
  /**
   * From AccessToken
   */
  entitlements$ = this.selectFromAccessNS('cigna.entitlements');
  lob$ = this.selectFromAccessNS('cigna.lob');

  /**
   * From IdToken
   */
  // can we use createSelector instead to grab this stuff since its in ngrx?
  profile$ = this.selectFromId((id) => id && id[JWT_NS]);
  firstName$ = this.selectFromIdNS('firstName');
  lastName$ = this.selectFromIdNS('lastName');

  constructor(
    private _authFacade: AuthFacade,
    @Optional() private _router?: Router,
  ) {}

  onError(error: AuthError) {
    if (error.isLoginRequired()) {
      this.redirectToLogin();
      return;
    }

    if (error.isFrameTimeout()) {
      // for some reason content bundle service is triggering frame timeout on login, so we want to ignore this
      if (this._router?.url.startsWith('/login')) {
        return;
      }

      this._router?.navigateByUrl('/login?status=timeout');
      return;
    }

    this.showMaintenance();
  }

  private redirectToLogin(): void {
    this._router?.navigate(['/login']);
  }

  private showMaintenance(): void {
    // Redirect to generic unavailable page
    this._router?.navigate(
      ['/unavailable'],
      // Don't update URL so refreshing the browser retries the existing page
      { skipLocationChange: true },
    );
  }

  private selectFromAccessNS<TProp extends keyof AccessToken>(
    prop: TProp,
  ): Observable<AccessToken[TProp] | undefined> {
    return this.selectFromAccess((id) => id && id[prop]);
  }

  private selectFromIdNS<TProp extends keyof IdToken[typeof JWT_NS]>(
    prop: TProp,
  ): Observable<IdToken[typeof JWT_NS][TProp] | undefined> {
    return this.selectFromId((id) => id && id[JWT_NS] && id[JWT_NS][prop]);
  }

  private selectFromAccess<U>(
    projector: (state: AccessToken) => U,
  ): Observable<U> {
    return this._authFacade.authData$.pipe(
      map((x) => projector(x.accessTokenBody as AccessToken)),
    );
  }

  private selectFromId<U>(projector: (state: IdToken) => U): Observable<U> {
    return this._authFacade.authData$.pipe(
      map((x) => projector(x.idTokenBody as unknown as IdToken)),
    );
  }
}
