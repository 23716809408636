import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';

/**
 * @deprecated
 *
 * Please use @cigna/shared/angular/accordion-ui instead.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cigna-collapsible-item',
  templateUrl: './collapsible-item.component.html',
  styleUrls: ['./collapsible-item.component.scss'],
})
export class CollapsibleItemComponent {
  @Input()
  get expanded(): boolean {
    return this._isExpanded;
  }

  set expanded(expanded: boolean) {
    if (this._isExpanded !== expanded) {
      this._isExpanded = expanded;
    }
  }
  private _isExpanded = false;
  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disabled = false;

  @Input() headerText = '';

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  toggle(): void {
    if (!this.disabled) {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.opened.emit(this.headerText);
      } else {
        this.closed.emit(this.headerText);
      }
    }
  }
}
