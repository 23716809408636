import {
  UserOptionsActions,
  UserOptionsActionTypes,
} from './user-options.actions';

import { JobRole, SecurityQuestion } from '../user-options/user-options.model';

export const USER_OPTIONS_FEATURE_KEY = 'userOptions';

export interface UserOptionsState {
  jobRoles: JobRole[];
  securityQuestions: {
    set1: SecurityQuestion[];
    set2: SecurityQuestion[];
  };
}

export const initialState: UserOptionsState = {
  jobRoles: [],
  securityQuestions: {
    set1: [],
    set2: [],
  },
};

export interface UserOptionsPartialState {
  readonly [USER_OPTIONS_FEATURE_KEY]: UserOptionsState;
}

export function userOptionsReducer(
  state = initialState,
  action: UserOptionsActions,
): UserOptionsState {
  // TODO: resolve CHCP disables by bot
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case UserOptionsActionTypes.GetLookUpDataSuccess: {
      return {
        ...state,
        jobRoles: action.payload.jobRoles,
        securityQuestions: action.payload.securityQuestions,
      };
    }
    default:
      return state;
  }
}
