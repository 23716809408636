<div class="cg-dialog">
  <h2 class="cg-dialog__header">
    Site Help<button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      (click)="dialogRef.close()"
    >
      Close
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h2>

  <mat-dialog-content>
    <h4 class="cg-margin-bottom-sm">Before you register</h4>

    <cigna-collapsible-item
      headerText="Who can register for the Cigna for Health Care Professionals website"
    >
      <p>
        Medical, dental and behavioral practitioners, as well as any
        representatives they choose, can register. If your organization does not
        have a TIN, the user can click on the &quot;I don't have a TIN&quot;
        link and continue with registration.
      </p>
      <p>
        Non-contracted providers can register, as long they have submitted a
        claim to Cigna.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="How do I register for the CignaforHCP.com website?"
    >
      <p>A new user may register for the website in one of two ways.</p>
      <ol>
        <li>
          Through the website access manager, who will add or delegate the user
          and assign the appropriate level of access immediately.
        </li>
        <li>
          On their own through CignaforHCP.com. This requires vetting and
          confirmation by the website access manager and may take longer to
          complete.
        </li>
      </ol>
      <p>
        Each TIN with registered users will have a designated person(s) known as
        the website access manager. The website access manager for the TIN the
        user registers under will be notified and either approve the requested
        level of access or deny it and assign the appropriate level of access.
      </p>
      <p>
        If you are the first person in your practice or facility to attempt to
        register, you can obtain a user ID and create a password by clicking
        "Register Now" on the Log In page. Your office will be called by Cigna
        to help establish a website access manager.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="I'm not contracted with Cigna. Can I still register?"
    >
      <p>
        Non-contracted providers can register, as long they have submitted a
        claim to Cigna. You’ll be able to access all of the information, except:
      </p>
      <ul>
        <li>View Default Fee Schedule Change(s)</li>
        <li>Update Demographic Information</li>
        <li>Request a Copy of the Participating Practitioner Agreement</li>
      </ul>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="Do I need a separate user ID and password to access combined medical, dental, and behavioral information?"
    >
      <p>
        You may have the same user ID and password for medical and behavioral. A
        separate user ID and password are required for dental.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="Can I register for more than one tax identification number (TIN)?"
    >
      <p>
        Individuals can register for multiple TINs (up to 50 TINs can be added
        for one user ID).  After registering, you can also request access to a
        TIN(s) under Settings & Preferences > > Online Access > Request TIN
        access. This will direct your request to a website access manager for
        that TIN.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item headerText="What is a website access manager?">
      <p>
        Each group registered on the Cigna for Health Care Professionals website
        (CignaforHCP.com) is required to assign at least one, and up to 15,
        user(s) as the website access manager(s). The website access manager at
        each practice manages how providers within the group register for and
        obtain the appropriate level of access to the website.
      </p>
      <p>Website access managers have three key responsibilities:</p>
      <ol>
        <li>
          Obtain access and approve new users in your group for CignaforHCP.com
        </li>
        <li>
          Assign or modify the website functions to which users in your group
          have access
        </li>
        <li>Remove access for users in your group who no longer need it.</li>
      </ol>

      <p>
        Visit our
        <a
          target="resourcesWindow"
          [href]="
            '/resourceLibrary/websiteAccessManager/websiteAccessManagers'
              | resourceLink : { secure: false, targetApp: 'both' }
              | async
          "
          >website access manager resource page</a
        >.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="How soon will I receive my user ID and password AND When can I start using my user ID and password"
    >
      <p>
        Once the website access managers approve your access, you will have
        immediate access to the roles they delegate for you.
      </p>
      <p>
        If you register for the website (or don't have a website access
        manager), your office will receive a call from Cigna to verify that you
        should have access. Once verified, you will then have immediate access.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="I represent a billing company for multiple practitioners. How do I register?"
    >
      <p>
        Contact the practitioner's website access manager you represent and ask
        them to delegate access to you. If you have more than one temporary user
        ID (each giving you access to different TINS), you can log in with a
        single user ID and password and the temporary user ID and password under
        Settings & Preferences > Online Access > Add access rights from
        temporary ID/Password.
      </p>
      <p>
        If you have multiple user IDs, you can call Cigna at 800.981.9114 to
        have them merged into one user ID/password.
      </p>
    </cigna-collapsible-item>

    <h4 class="cg-margin-bottom-sm cg-margin-top-xl">After you register</h4>

    <cigna-collapsible-item
      headerText="I am newly registered for CignaforHCP.com. Why can’t I access the information I need (for example, patient and claim details)?"
    >
      <p>
        The website access manager at your organization has given you access to
        certain functionality on CignaforHCP.com. Please contact your website
        access manager to request any additional access you need.
      </p>

      <p>
        If a website access manager has not yet been established for the
        Taxpayer Identification Number (TIN) under which you registered, Cigna
        will contact your office within approximately 10 business days to help
        establish one.
      </p>

      <p>
        If you do not know who your website access manager is, please call
        800.853.2713.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="Who do I contact if I need access to additional information on CignaforHCP.com?"
    >
      <p>
        You should contact the website access manager at your organization to
        request the additional access you need.
      </p>

      <p>
        If a website access manager has not yet been established for the
        Taxpayer Identification Number (TIN) for which you registered, Cigna
        will contact your office within approximately 10 business days to help
        establish one.
      </p>

      <p>
        If you do not know who your website access manager is, please call
        800.853.2713.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="What if I don’t know who my website access manager is?"
    >
      <p>
        Log in to CignaforHCP.com. Click the drop-down menu under your name and
        select Settings and Preferences > Online access > View TIN access.
        Select your TIN; the name of the website access manager will be shown at
        the bottom of the screen.
      </p>

      <p>Please call 800.853.2713 for assistance.</p>
    </cigna-collapsible-item>

    <cigna-collapsible-item headerText="When is the website available?">
      <p>Hours of Availability:</p>
      <p>
        The Cigna for Health Care Professionals website and EDI real-time
        transactions are generally available 24 hours a day, 365 days a year.
      </p>

      <p>
        Online precertification transactions are available Monday through
        Saturday from 5:00 a.m. to 3:00 a.m. ET and Sunday from 5:00 a.m. to
        9:00 p.m. ET.
      </p>

      <p>
        To allow for maintenance, the website and EDI real-time transactions may
        be unavailable on Sundays from 12:01 a.m. until 10:00 a.m. ET.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item headerText="How many TINs can a user access?">
      <p>A single user can access up to 50 TINs.</p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="How many users can be aligned to a TIN?"
    >
      <p>
        There is no limit to the number of users that can be aligned to a TIN,
        however, for security purposes, the website access manager should keep
        the list of users current. There can be up to 15 website access managers
        per TIN.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="How do I remove a website access manager?"
    >
      <p>
        In order to modify or remove a website access manager, any other website
        access manager for their TIN may remove their "Delegate access and view
        delegation history report" access found on Working With Cigna > Manage
        User Access > Modify Access.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item headerText="When does my password expire?">
      <p>
        For your security, passwords expire every 120 days. You can change your
        password at any time by accessing Settings and Preferences > Security &
        Login > Site Password.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="If my password expires, how do I reset it?"
    >
      <p>
        You can reset your password online by correctly answering the security
        questions you set up during registration. Just click the “Forgot your
        password” link directly under the log in and password fields on the log
        in page.
      </p>
      <p>
        If you are unable to reset your password online and you are a website
        access manager, call us at 800.981.9114 to have your password reset. You
        may also contact your website access manager and request that your
        password be reset.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item headerText="What is a 'cookie?'">
      <p>
        A cookie is a small file on your computer that saves internet settings.
        Cookies can keep you signed in, remember your site preferences, and give
        you locally relevant content. Because cookies are saved to your
        computer, the settings they save will only apply to the specific
        computer and browser that you used to set those preferences. Therefore,
        cookies are especially helpful if you typically use the same computer
        and browser.
      </p>
      <p>
        Cookies should be enabled in your internet browser in order for you to
        experience the full convenience of the Health Care Professional portal.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item headerText="What does 'clear my cache' mean?">
      <p>
        When you use web browsers, your history, temporary internet files, and
        cookies are stored on your computer.  While these stored files can help
        you access information faster, they also take up disk space.  If your
        disk is full, certain web-based applications may not open properly or
        run well.  It is a best practice to remove temporary files on a regular
        basis.  This is often referred to as "clearing your cache."
      </p>
      <p>
        Generally, you can clear your cache by using your browser's settings and
        looking for the browser's history, temporary files, and "cookies" – and
        then delete them.  Each browser will have help information specific to
        how to do this for that browser.  Please look for your browser's "Help"
        section.
      </p>
    </cigna-collapsible-item>

    <h4 class="cg-margin-top-xl cg-margin-bottom-sm">
      About your security and privacy
    </h4>
    <cigna-collapsible-item headerText="What is Cigna's privacy policy?">
      <p>
        To review Cigna's privacy policy, click on the Privacy Information link
        at the bottom of any page.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item headerText="What is two-step authentication?">
      <p>
        Two-step authentication is an extra layer of security at login to help
        prevent use of the website by unauthorized users, and to further protect
        the privacy of your patients with Cigna-administered coverage. When you
        log in to CignaforHCP.com with your username and password, a security
        code will be sent to the email address or mobile phone number listed for
        you in Settings & Preferences on CignaforHCP.com. Once you enter the
        security code in the Two-Step Authentication pop-up window that appears
        on the login screen, you will gain access to the website.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="How can I confirm that my correct email address and mobile phone are on file for two-step authentication?"
    >
      <p>
        You can view the email address and mobile phone number on file for you
        by logging in to CignaforHCP.com, clicking the drop-down menu under your
        name, and selecting Settings & Preferences. If any information you see
        is not correct, you can update it here.
      </p>

      <p>
        To help ensure we send security codes to your correct email address or
        mobile phone number, it’s important for you to verify that:
      </p>

      <ul>
        <li>Your email address is correct.</li>
        <li>Your mobile phone number is correct (if one is listed).</li>
        <li>You have access to the email box listed.</li>
      </ul>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="Is two-step authentication necessary each time I log in to CignaforHCP.com?"
    >
      <p>
        It depends. If you select the option “Remember this device” when logging
        in, two-step authentication will not be required for subsequent logins.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="How does my device know when two-step authentication is not needed?"
    >
      <p>
        When you select “Remember this device,” a cookie is installed in your
        web browser. The cookie identifies the device as authorized when you log
        in again.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText='Why would I be asked for a security code if I previously selected "Remember this device?"'
    >
      <p>This may occur if you:</p>

      <ul>
        <li>
          Deleted the cookies or the browsing history (the device will no longer
          be identified as an authorized device).
        </li>
        <li>Log in using a different device.</li>
        <li>Log in using a different browser.</li>
        <li>Log in after 90 days of inactivity.</li>
      </ul>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="If I use a federated login or a single sign-on for CignaforHCP.com, do I need to use two-step authentication?"
    >
      <p>
        No, you will not use two-step authentication. Likewise, you will not use
        two-step authentication for websites that allow single sign-on from
        CignaforHCP.com (e.g., OneHealthPort, NaviNet®).
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="If the email address and/or mobile phone you have on file is incorrect and I can't log in to CignaforHCP.com, how do I change it?"
    >
      <p>Please call 800.853.2713 for assistance.</p>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="What should I do if I don't receive a security code via email or mobile phone?"
    >
      <p>You should:</p>

      <ul>
        <li>
          Check your verified email address or verified mobile phone number in
          Settings & Preferences to be sure the correct email address is on
          file. (Log in to CignaforHCP.com, click the drop-down menu under your
          name, and select Settings & Preferences.)
        </li>
        <li>Check the junk folder of your email box.</li>
        <li>
          Verify with your administrator that your email is working correctly
          and that the email is not being blocked. The email should come from
          DoNotReply&#64;Providernotification.cigna.com.
        </li>
        <li>Check to see if you replied Stop to a text code of 32882.</li>
        <li>
          Try restarting your mobile phone to get a fresh connection to your
          network.
        </li>
        <li>
          Check with your mobile phone provider to be sure your text messaging
          (SMS) is working correctly.
        </li>
      </ul>
    </cigna-collapsible-item>

    <cigna-collapsible-item
      headerText="How can I tell if my online session with Cigna is encrypted?"
    >
      <p>
        Microsoft Edge, Google Chrome, Mozilla Firefox, and Apple Safari all use
        a padlock icon by the browser field to represent an encrypted session.
        An open lock indicates that your session is not encrypted. Although an
        open lock might appear when the page loads between two encrypted pages,
        the session is still encrypted if the closed lock is present when the
        page has finished loading.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="What steps should I take to ensure the security of my information?"
    >
      <p>
        You share responsibility for maintaining the security of your personal
        and provider information when using online services. Never share your
        password with anyone. When you finish with this website, always use the
        log out button to leave the service and close down the browser.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item headerText="What if someone learns my password?">
      <p>
        If you suspect that someone knows your password, you can change it by
        clicking Settings & Preferences > Security & Login > Change Password.
        Each user is required to have their own individual password.
      </p>
      <p>
        If you have trouble changing your password, you can request help from
        your website access manager.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="How can I change my password on the Cigna for Health Care Professionals website?"
      ><p>
        Log-in to CignaforHCP.com and click on Settings & Preferences > Security
        and Login, then Change Password. Each user is required to have their own
        individual password.
      </p>
      <p>
        If you use a third-party website, such as OneHealthPort or NaviNet, to
        access CignaforHCP.com, you will not have access to Settings &
        Preferences on the Cigna website.  You will need to change your password
        on the third-party website.
      </p>
      <p>
        If you have trouble changing your password, you can request help from
        your website access manager.
      </p>
    </cigna-collapsible-item>

    <h4 class="cg-margin-bottom-sm cg-margin-top-xl">About your browser</h4>
    <cigna-collapsible-item headerText="What kind of browser do I need?">
      <p>
        The Cigna for Health Care Professionals website works well with a
        variety of browsers, including Microsoft Edge, Mozilla FireFox, Google
        Chrome and Apple Safari. Using the most up-to-date browser version will
        minimize the likelihood that your session with Cigna could be
        interrupted. If you are having trouble registering or using the site,
        and you have checked your browser, please contact your internet service
        provider or your employer's technical support group.
      </p>
    </cigna-collapsible-item>
    <h4 class="cg-margin-bottom-sm cg-margin-top-xl">
      If you still need assistance with this website, please call 1.800.981.9114
    </h4>
  </mat-dialog-content>
</div>
