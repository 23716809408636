import {
  ClaimsSearchTypes,
  ClaimsSearchCriteria,
  DateOfServiceOptions,
  ClaimsReferSearchCriteria,
  ClaimDetails,
  UsefulLink,
  HelpDropdown,
} from './claims.model';

import { BreadCrumb } from '@cigna/chcp/shared/util';
import { subDays, format } from 'date-fns';
import { formatCurrency } from '@angular/common';

export const dtFormat = 'MM/dd/yyyy';

export const claimSearchTypes: ClaimsSearchTypes[] = [
  {
    value: 'id_dob',
    id: 'idDOB',
    name: 'access',
    description: 'Patient ID/Date of Birth',
  },
  {
    value: 'id_name',
    id: 'idName',
    name: 'access',
    description: 'Patient ID/Name',
  },
  {
    value: 'dob_name',
    id: 'dobName',
    name: 'access',
    description: 'Date of Birth/Name',
  },
  {
    value: 'id_dob_name',
    id: 'idDobName',
    name: 'access',
    description: 'Patient ID/Date of Birth/Name',
  },
];

export const medicalClaimSearchTypes: ClaimsSearchTypes[] = [
  ...claimSearchTypes,
  {
    value: 'patient_account_number',
    id: 'patientAccountNumber',
    name: 'access',
    description: 'Provider Generated Patient Account Number',
  },
];

export const dateOfServiceOptions: DateOfServiceOptions[] = [
  {
    value: '-7d',
    description: 'The last week',
  },
  {
    value: '-14d',
    description: 'The last 2 weeks',
  },
  {
    value: '-1m',
    description: 'The last month',
  },
  {
    value: '-2m',
    description: 'The last 2 months',
  },
  {
    value: '-3m',
    description: 'The last 3 months',
  },
  {
    value: '-6m',
    description: 'The last 6 months',
  },
  {
    value: 'editable',
    description: 'Select editable date range',
  },
];

export const claimsSearchCriteriaDefault: ClaimsSearchCriteria = {
  memberId: '',
  dateOfServiceOption: '-7d',
  access: 'id_dob',
  dob: null,
  asOfDate: subDays(new Date(), 7),
  toDate: new Date(),
  lastName: '',
  firstName: '',
  providerSelAccNum: '',
  generatedTinId: '',
  tinName: '',
};

export const claimsReferSearchCriteriaDefault: ClaimsReferSearchCriteria = {
  claimReferNo: '',
  providerSelRefNum: '',
};

// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SUBMIT_STAGE {
  default = 'default',
  submitting = 'submitting',
  error = 'error',
  success = 'success',
}

const usefulLinks: UsefulLink[] = [
  {
    name: 'View Policies and Procedures',
    path: '/resourceLibrary/clinicalReimbursementPayment/clinReimAndPayPolsListing',
    targetApp: 'chcp',
  },
];

const helpDropdownNumbersChcp: HelpDropdown[] = [
  {
    name: 'General Assistance:',
    contactNumber: '1.800.88.CIGNA <br/> (1.800.882.4462)',
  },
  {
    name: 'Technical Web Site Assistance:',
    contactNumber: '1.800.261.6232',
  },
  {
    name: 'Site Navigation and <br/> Registration Assistance:',
    contactNumber: '1.800.853.2713',
  },
];
const helpDropdownNumbersMedicareChcp: HelpDropdown[] = [
  {
    name: 'General Assistance and Provider Services:',
    contactNumber: '800.627.7534 – Arizona<br/>800.230.6138 – All other states',
  },
  {
    name: 'Technical Web Site Assistance:',
    contactNumber: '1.800.261.6232',
  },
  {
    name: 'Site Navigation and <br/> Registration Assistance:',
    contactNumber: '1.800.853.2713',
  },
];
const helpDropdownNumbersEvernorth: HelpDropdown[] = [
  {
    name: 'Provider Services and General Assistance:',
    contactNumber: '800.926.2273',
  },
  {
    name: 'Site Navigation and Technical Assistance:',
    contactNumber: '888.736.7499',
  },
  {
    name: 'Claims:',
    contactNumber:
      'Evernorth Behavioral Health <br/>PO Box 188022<br/>Chattanooga, TN 37422 ',
  },
];

export const claimsDetailsMenuChcp = {
  usefulLinks: [...usefulLinks],
  helpContact: [...helpDropdownNumbersChcp],
};
export const claimsDetailsMenuMedicareChcp = {
  usefulLinks: [...usefulLinks],
  helpContact: [...helpDropdownNumbersMedicareChcp],
};
export const claimsDetailsMenuEvernorth = {
  usefulLinks: [...usefulLinks],
  helpContact: [...helpDropdownNumbersEvernorth],
};

export const CLAIMS_SEARCH: BreadCrumb = {
  name: 'Claim Search',
  url: '/claim/search',
};

export const CLAIMS_RESULT: BreadCrumb = {
  name: 'Results',
  url: '/claim/results',
};

export const CLAIMS_MULTIPLE_PATIENTS: BreadCrumb = {
  name: 'Multiple Patients',
  url: '/claim/multiplepatients',
};

export const CLAIMS_UPLOAD_ERROR =
  'This filename and extension has already been uploaded. If this is a new submission, please rename and try again.';
export const CLAIMS_NO_FILE_CHOSEN = 'No file chosen';
export const CLAIMS_FILE_MAX_SIZE = 350000000;
export const CLAIMS_FILE_MIN_SIZE = 10000000;

export const createCoverageParams = (
  claimDetail: ClaimDetails,
  // TODO: resolve CHCP disables by bot
  // eslint-disable-next-line sonarjs/cognitive-complexity
): { asof: string } => {
  let asOfDate: string = format(new Date(), 'yyyy-MM-dd');
  if (
    !!claimDetail &&
    claimDetail.claimInfoObject &&
    claimDetail.claimInfoObject.claimDateOfService
  ) {
    // date comes in the format as 06/02/2019-06/14/2019
    if (claimDetail.claimInfoObject.claimDateOfService.indexOf('-') > -1) {
      const dos = claimDetail.claimInfoObject.claimDateOfService.split('-');
      if (dos && dos.length > 1 && dos[0].indexOf('/') > -1) {
        const dosDate = dos[0].split('/');
        asOfDate =
          dosDate && dosDate.length === 3
            ? `${dosDate[2]}-${dosDate[0]}-${dosDate[1]}`
            : asOfDate;
      }
    } else {
      const dos = claimDetail.claimInfoObject.claimDateOfService.split('/');
      asOfDate =
        dos && dos.length === 3 ? `${dos[2]}-${dos[0]}-${dos[1]}` : asOfDate;
    }
  }
  return {
    asof: asOfDate,
  };
};

export const currencyFormatter = (
  value?: string | number | null,
  currency = '$',
  absolute = true,
) => {
  if ((!value && value !== 0) || isNaN(+value)) {
    return value;
  }
  return absolute && +value < 0
    ? `(${formatCurrency(Math.abs(+value), 'en-US', currency)})`
    : formatCurrency(+value, 'en-US', currency);
};
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum FILTER_GROUP {
  all = 'all',
}
