import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

@NgModule({
  imports: [ApolloModule],
})
export class ChcpSharedGraphqlDataAccessModule {
  static withConfig(
    graphqlServerUri: string,
  ): ModuleWithProviders<ChcpSharedGraphqlDataAccessModule> {
    return {
      ngModule: ChcpSharedGraphqlDataAccessModule,
      providers: [
        {
          provide: APOLLO_OPTIONS,
          useFactory: (httpLink: HttpLink) => ({
            cache: new InMemoryCache({
              typePolicies: {
                FlaggedClaim: {
                  keyFields: ['claimSummary', ['claimReferenceNumber']],
                },
              },
            }),
            link: httpLink.create({
              uri: graphqlServerUri,
            }),
            connectToDevTools: true,
          }),
          deps: [HttpLink],
        },
      ],
    };
  }
}
