import { InjectionToken, Provider } from '@angular/core';

export interface OktaFlowConfig {
  issuer: string;
  redirectUri: string;
  postLogoutRedirectUri: string;
  proxy?: string;
  clientId: string;
  debug?: boolean;
  tokenExpirationInMin: number;
  cookieStorage?: boolean;
  retrieveTokensFromNative?: boolean;
  clockSkew?: number;
}

export const OKTA_FLOW_CONFIG = new InjectionToken<OktaFlowConfig>(
  'OKTA_FLOW_CONFIG',
);

export function oktaFlowConfig(config: OktaFlowConfig): Provider {
  return {
    provide: OKTA_FLOW_CONFIG,
    useValue: config,
  };
}
