import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GatewayConfig, GATEWAY_CONFIG } from './gateway.config';
import { GatewayConfig as SharedGatewayConfig } from '@cigna/shared/angular/gateways-util';

@NgModule({
  imports: [CommonModule],
})
export class PdlViewerSharedGatewayModule {
  static withConfig(
    config: GatewayConfig,
  ): ModuleWithProviders<PdlViewerSharedGatewayModule> {
    return {
      ngModule: PdlViewerSharedGatewayModule,
      providers: gatewaysProviders(config),
    };
  }
}
export function gatewaysProviders(config: GatewayConfig): Provider[] {
  return [{ provide: GATEWAY_CONFIG, useValue: config }];
}

export function toSharedGatewaysConfig(
  config: GatewayConfig,
): SharedGatewayConfig {
  return {
    cdn: config.cdn,
    resourceContent: config.resourceContent,
    directory: '',
    directoryTypeahead: '',
    directoryGeolocation: '',
    gsg: config.gsg,
    launchDarkly: '',

    eirAnalytics: '',
    elevateSurvey: '',
    communicationPreferencesUrl: '',
  };
}
