import { InjectionToken } from '@angular/core';

export const GATEWAY_CONFIG = new InjectionToken('PDL_VIEW_GATEWAY_CONFIG');

export interface GatewayConfig {
  cdn: string;
  resourceContent: string;
  drugsearch: string;
  gsg: string;
  drugSearchPublic: string;
}
