import { Pipe, PipeTransform } from '@angular/core';
import { STATES } from '@cigna/chcp/shared/util';

@Pipe({
  name: 'stateName',
})
export class StateNamePipe implements PipeTransform {
  transform(stateCode: string): string {
    return STATES.find((s) => s.value === stateCode)?.name || '';
  }
}
