import { Injectable } from '@angular/core';
import { UrlFactory } from './url-factory';

interface DrugSearchParams {
  groupNumber?: string;
  benefitOptionCode?: string;
  eligibilitySystemCode?: string;
  classId?: string;
  searchQuery?: string;
  pageNumber?: number;
  therapeuticClassCode?: string;
}

export enum ApiVersion {
  v1 = '/my/rx/v1/formulary/druglist/',
  v2 = '/my/rx/v2/formulary/druglist/',
  v3 = '/my/rx/v3/formulary/druglist/',
}

export enum ApiPublicVersion {
  v1 = '/public/rx/v1/formulary/druglist/',
  v2 = '/public/rx/v2/formulary/druglist/',
  v3 = '/public/rx/v3/formulary/druglist/',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PdfVersion {
  v1 = '/druglist/pdf/',
}

@Injectable({ providedIn: 'root' })
export class PdlGateway {
  constructor(private _urlFactory: UrlFactory) {}

  drugSearchURL(params?: DrugSearchParams) {
    return this._urlFactory.createUrl('drugsearch', ApiVersion.v1, params);
  }

  typeaHeadURL(params?: DrugSearchParams) {
    return this._urlFactory.createUrl(
      'drugsearch',
      '/my/rx/v1/formulary/druglist/',
      params,
    );
  }

  drugAndTypeAHeadPublicURL(params?: DrugSearchParams) {
    return this._urlFactory.createUrl(
      'drugSearchPublic',
      ApiPublicVersion.v1,
      params,
    );
  }

  downloadPdfURL() {
    return this._urlFactory.createUrl('drugsearch', PdfVersion.v1);
  }

  downloadPdfPublicURL() {
    return this._urlFactory.createUrl('cdn', PdfVersion.v1);
  }
}
