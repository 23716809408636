import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.scss'],
})
export class SkeletonComponent {
  @Input()
  set repeat(repeat: string) {
    const [times, repeater] = repeat.split(':');

    this.repeatTimes = Array(+times).fill(repeater);
  }
  @Input()
  set layout(layout: string) {
    this.lines = layout.split(' ').map((s) => `${+s * 10}%`);
  }
  @Input()
  set gap(gap: string) {
    const [gapV, gapH] = gap.split(' ');

    this.lineGapV = `${+gapV}em`;

    if (gapH) {
      this.lineGapH = `${+gapH * 10}%`;
    }
  }

  public repeatTimes: string[] = [''];
  public lines: string[] = ['10%'];
  public lineGapH = '5%';
  public lineGapV = '.8em';
}
