import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  ConversationMessageDialogLinkText,
  LinkTextItem,
} from '../interfaces/link-text.interface';

@Pipe({
  name: 'linkText',
})
export class LinkTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    message: string | number,
    linkText?: ConversationMessageDialogLinkText,
  ): SafeHtml {
    if (!linkText) {
      return this.replaceHtmlEncoding(message.toString());
    }
    return this.updateLinkText(message.toString(), linkText);
  }

  private replaceHtmlEncoding(message: string): SafeHtml {
    const encodedMessage = message
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace('§', '&sect');
    return this.sanitizer.bypassSecurityTrustHtml(encodedMessage);
  }

  private updateLinkText(
    message: string,
    linkText: ConversationMessageDialogLinkText,
  ): SafeHtml {
    const linksList = Object.keys(linkText);
    let _message = message;
    for (const link of linksList) {
      const linkInfo = linkText[link];
      _message = _message.replace(`%${link}%`, this.generateLink(linkInfo));
    }
    return this.replaceHtmlEncoding(_message);
  }

  private generateLink(linkInfo: LinkTextItem): string {
    const link =
      linkInfo.url.indexOf('http') !== 0
        ? `routerLink="${linkInfo.url}"`
        : `href="${linkInfo.url}"`;
    const target =
      linkInfo.url.indexOf('http') !== 0 ? `` : `target="${linkInfo.target}"`;
    return `<a ${link} ${target}>${linkInfo.label}</a>`;
  }
}
