import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[cignaButton],a[cignaButton]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./button-var.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @HostBinding('attr.cignaButton')
  @Input()
  cignaButton: string;
}
