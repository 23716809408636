import { createAction, props } from '@ngrx/store';
import { DcmAssetsResponse, DcmAssetsErrorResponse } from './dcm.interface';

export const getDcmAssets = createAction(
  '[Secure DcmAssets] Get DcmAssets',
  props<{
    fileName?: string | undefined;
  }>(),
);

export const getDcmAssetsUnavailable = createAction(
  '[Secure DcmAssets] Get DcmAssets Unavailable',
);

export const getDcmAssetsSuccess = createAction(
  '[Secure DcmAssets] Get Success',
  props<{
    response?: DcmAssetsResponse;
  }>(),
);

export const getDcmAssetsFailure = createAction(
  '[Secure DcmAssets] Get Failure',
  props<{
    error: DcmAssetsErrorResponse;
  }>(),
);
