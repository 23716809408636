/* eslint-disable @nx/enforce-module-boundaries */
import { Component, Inject } from '@angular/core';
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';
import { AppContext } from '@cigna/chcp/shared/util';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Component({
  selector: 'cigna-dialog-explain-access',
  templateUrl: './dialog-explain-access.component.html',
  styleUrls: ['./dialog-explain-access.component.scss'],
})
export class DialogExplainAccessComponent {
  isDentalUser$ = this.authFacade.isDentalUser$;
  isMedicarePilotUser$ = this.featureTogglesFacade.featuresEnabled([
    'pilotMHKUser',
  ]);

  constructor(
    public dialogRef: MatDialogRef<DialogExplainAccessComponent>,
    @Inject(APP_CONTEXT) public appContext: AppContext,
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string },
    private authFacade: AuthFacade,
    private featureTogglesFacade: FeatureTogglesFacade,
  ) {}
}
