import {
  isAsyncCHEConversation,
  isConversation,
  isTransferredConversation,
  isDMContextTags,
  ConversationEventWithState,
} from '@cigna/omni/shared-util';
import {
  ConversationDTO,
  ConversationEvent,
  ConversationSummaryDTO,
  ConversationParticipantDTO,
} from '@cigna/vampire-dto';
import { Dictionary } from '@ngrx/entity/src/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import findIndex from 'lodash/fp/findIndex';
import {
  adapter,
  ConversationsState,
  CONVERSATIONS_FEATURE_KEY,
} from './conversations.reducer';
import {
  ConversationDTOAgentID,
  sortByDates,
  generateAgentSummaryListItem,
} from './conversations.transforms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ChatState } from '@cigna/omni/chat-state-data-access';

export interface ConversationEventExtended extends ConversationEvent {
  isUserMsg?: boolean;
}

const selectConversationsFeature = createFeatureSelector<ConversationsState>(
  CONVERSATIONS_FEATURE_KEY,
);

const getChat = createFeatureSelector<ChatState>('omni-chat-feature');

const { selectAll, selectEntities } = adapter.getSelectors();

const selectConversations = createSelector(
  selectConversationsFeature,
  selectEntities,
);

export const removeBotTransferMessage = (
  events: ConversationEvent[],
  prev?: ConversationDTO | ConversationSummaryDTO,
): ConversationEvent[] => {
  const index = findIndex(({ type }) => type === 'message', events);
  const transferManualIndex = findIndex(
    ({ state }: ConversationEventWithState) => state === 'transfer-manual',
    (prev as ConversationDTO)?.events || [],
  );
  if (
    index > -1 &&
    transferManualIndex > -1 &&
    prev &&
    prev.type === 'bot' &&
    !(events as ConversationEventExtended[])?.[index]?.isUserMsg
  ) {
    return [...events.slice(0, index), ...events.slice(index + 1)];
  }
  return events;
};

export const combineMessages = (
  conversation: ConversationSummaryDTO | ConversationDTO | undefined,
  conversations: Dictionary<ConversationSummaryDTO | ConversationDTO>,
) => {
  if (conversation && isTransferredConversation(conversation)) {
    const allPrevConversations = conversation.transferIds.map(
      (id) => conversations[id],
    );
    return {
      ...conversation,
      // participants are combined to match up message events from multiple conversations (transfers)
      participants: [
        ...conversation.participants,
        ...allPrevConversations.reduce<ConversationParticipantDTO[]>(
          (prev, curr) => [
            ...prev,
            ...(isConversation(curr) ? curr.participants : []),
          ],
          [],
        ),
      ],
      events: [
        ...allPrevConversations.reduce<ConversationEvent[]>(
          (prev, curr, idx, prevConversations) => [
            ...prev,
            ...(isConversation(curr)
              ? removeBotTransferMessage(
                  curr.events,
                  prevConversations[idx - 1],
                )
              : []),
          ],
          [],
        ),
        ...removeBotTransferMessage(
          conversation.events,
          allPrevConversations[allPrevConversations.length - 1],
        ),
      ],
    };
  }

  return conversation;
};

export const sortByUpdatedDate = (
  a: ConversationSummaryDTO,
  b: ConversationSummaryDTO,
) => (a.updated > b.updated ? -1 : a.updated < b.updated ? 1 : 0);

const selectConversation = createSelector(
  selectConversationsFeature,
  selectConversations,
  ({ selectedConversationId }, conversations) =>
    selectedConversationId &&
    conversations &&
    conversations[selectedConversationId]
      ? combineMessages(conversations[selectedConversationId], conversations)
      : null,
);

const selectSelectedConversationId = createSelector(
  selectConversationsFeature,
  ({ selectedConversationId }) => selectedConversationId,
);

const selectSelectedAgentName = createSelector(
  selectConversationsFeature,
  ({ selectedAgentName }) => selectedAgentName,
);

const selectAllConversations = createSelector(
  selectConversationsFeature,
  selectAll,
);

const selectActiveLiveConversation = createSelector(
  selectAllConversations,
  (conversations) =>
    conversations.find(
      (conv) => conv.type === 'live' && conv.state !== 'closed',
    ),
);

const selectAllNonBotConversations = createSelector(
  selectAllConversations,
  (conversations) =>
    conversations && conversations.filter((conv) => conv.type !== 'bot'),
);

const selectConversationCount = createSelector(
  selectAllNonBotConversations,
  (conversations) => conversations.length,
);

const selectDMConversationCount = createSelector(
  selectAllNonBotConversations,
  (conversations) =>
    conversations.filter(({ type }) => type === 'async').length,
);

const selectOpenDMConversations = createSelector(
  selectAllNonBotConversations,
  (conversations) =>
    conversations
      .filter(({ type, state }) => type === 'async' && state !== 'closed')
      .sort((conv1, conv2) =>
        sortByDates(
          conv1 as ConversationDTOAgentID,
          conv2 as ConversationDTOAgentID,
        ),
      )
      .map(generateAgentSummaryListItem),
);

const selectUnreadOpenDMConversationCount = createSelector(
  selectOpenDMConversations,
  (conversations) =>
    conversations.filter(({ newMessages }) => newMessages).length,
);

const selectActiveAsyncConvCount = createSelector(
  selectConversationsFeature,
  ({ activeAsyncConvCount }) => activeAsyncConvCount,
);

const selectClosedAsyncConvCount = createSelector(
  selectConversationsFeature,
  ({ closedAsyncConvCount }) => closedAsyncConvCount,
);

const selectOpenConversations = createSelector(
  selectAllNonBotConversations,
  (conversations) =>
    conversations
      .filter(
        ({ type, state }) =>
          (type === 'async' || type === 'live') && state !== 'closed',
      )
      .sort(sortByUpdatedDate),
);

const selectNotificationCount = createSelector(
  selectAllNonBotConversations,
  (conversations) => {
    const count = conversations.filter(({ newMessages }) => newMessages).length;
    return count > 99 ? 99 : count;
  },
);

const selectConversationEvents = createSelector(
  selectConversation,
  (conversation) =>
    conversation && isConversation(conversation) ? conversation.events : [],
);

const selectUnreadConversations = createSelector(
  selectAllNonBotConversations,
  (conversations) =>
    conversations
      .filter(({ newMessages }) => newMessages)
      .sort(sortByUpdatedDate),
);

const selectUnreadOpenDMConversations = createSelector(
  selectUnreadConversations,
  (conversations) =>
    conversations.filter(
      (conversation) =>
        isDMContextTags(conversation.context.tags) &&
        conversation.state !== 'closed',
    ),
);

const selectFancyInvitationConvs = createSelector(
  selectUnreadOpenDMConversations,
  (unreadDms) =>
    unreadDms.filter(
      (dm) =>
        isConversation(dm) &&
        !dm.events.find((ev) => ev.type === 'message')?.viewed,
    ) as ConversationDTO[],
);

const selectRecentActiveOrUnreadConversation = createSelector(
  selectOpenConversations,
  selectUnreadConversations,
  (open, unread) => (unread.length ? unread[0] : open.length ? open[0] : null),
);

const selectIsMyConversationsFeatured = createSelector(
  selectRecentActiveOrUnreadConversation,
  (isActiveOrUnread) => !!isActiveOrUnread,
);

const getActivityDebounceTime = createSelector(
  selectConversationsFeature,
  ({ activityDebounceTime }) => activityDebounceTime,
);

const getActiveChatProvider = createSelector(
  selectConversationsFeature,
  ({ activeChatProvider }) => activeChatProvider,
);

const getCareAdvocateData = createSelector(
  selectConversationsFeature,
  ({ careAdvocateData }) => careAdvocateData,
);

const getOpenLiveConvExists = createSelector(
  selectOpenConversations,
  (conversations) =>
    conversations.some(
      (conv) =>
        conv.type === 'live' ||
        (isAsyncCHEConversation(conv.context, conv.type) &&
          !!conv.participants &&
          conv.participants.length > 1),
    ),
);

const getExpander = createSelector(
  selectConversationsFeature,
  ({ expanderBubble }) => expanderBubble,
);

const getCloseOrExitFlag = createSelector(
  selectConversationsFeature,
  ({ closedOrExitFlag }) => closedOrExitFlag,
);

const getCloseSplashScreen = createSelector(
  selectConversationsFeature,
  ({ closeSplashScreen }) => closeSplashScreen,
);

const autoOpenConversation = createSelector(
  selectConversationsFeature,
  ({ canAutoOpenConversation }) => canAutoOpenConversation,
);

const getIsUpdatingConversation = createSelector(
  selectConversationsFeature,
  ({ isUpdatingConversation }) => isUpdatingConversation,
);

const getIsAiBot = createSelector(
  selectConversationsFeature,
  ({ isAiBot }) => isAiBot,
);

const getExistingConversationId = createSelector(
  selectConversationsFeature,
  ({ existingConversationId }) => existingConversationId,
);

const getShouldFocusOnHeader = createSelector(
  selectConversationsFeature,
  ({ shouldFocusOnHeader }) => shouldFocusOnHeader,
);

const getConversationMetadata = createSelector(
  selectConversationsFeature,
  ({ conversationMetadata }) => conversationMetadata,
);

const getIsLoadingHistory = createSelector(
  selectConversationsFeature,
  ({ isLoadingHistory }) => isLoadingHistory,
);

const getTerminatedUserMessage = createSelector(
  selectConversationsFeature,
  ({ terminatedUserMessage }) => terminatedUserMessage,
);

const getConversationError = createSelector(
  selectConversationsFeature,
  ({ conversationsError }) => conversationsError,
);

const getTokenRefreshThresholdTime = createSelector(
  selectConversationsFeature,
  ({ tokenRefreshThresholdTime }) => tokenRefreshThresholdTime,
);

const getHistoryState = createSelector(getChat, ({ history }) => history);

const isHistory = createSelector(getHistoryState, (historyState): boolean =>
  historyState.includes('history'),
);

export const conversationsQuery = {
  getActiveChatProvider,
  getActivityDebounceTime,
  getOpenLiveConvExists,
  getCareAdvocateData,
  selectAllConversations,
  selectActiveLiveConversation,
  selectAllNonBotConversations,
  selectConversation,
  autoOpenConversation,
  getIsUpdatingConversation,
  getIsAiBot,
  selectConversationCount,
  selectDMConversationCount,
  selectOpenDMConversations,
  selectUnreadOpenDMConversationCount,
  selectActiveAsyncConvCount,
  selectClosedAsyncConvCount,
  selectConversationEvents,
  selectConversations,
  selectIsMyConversationsFeatured,
  selectNotificationCount,
  selectRecentActiveOrUnreadConversation,
  selectUnreadOpenDMConversations,
  selectFancyInvitationConvs,
  selectSelectedConversationId,
  selectSelectedAgentName,
  getExpander,
  getCloseOrExitFlag,
  getCloseSplashScreen,
  getExistingConversationId,
  getShouldFocusOnHeader,
  getConversationMetadata,
  getIsLoadingHistory,
  getTerminatedUserMessage,
  getConversationError,
  getTokenRefreshThresholdTime,
  isHistory,
};
