import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OmniConversationSummaryCard } from '@cigna/omni/conversation-summary-card-feature';
import { Observable, timer } from 'rxjs';
import {
  bufferCount,
  concat,
  concatMap,
  scan,
  take,
  map,
} from 'rxjs/operators';
import { ConvSummaryListFacade } from '../+state/omni-conversation-summary-list.facade';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';

@Component({
  selector: 'cigna-omni-conversation-summary-list',
  templateUrl: './omni-conversation-summary-list.component.html',
  styleUrls: ['./omni-conversation-summary-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniConversationSummaryListComponent implements OnInit, OnDestroy {
  @Input() public canEnableNewDMUI: boolean;
  public dmConversationCount$ = this.conversationsFacade.dmConversationCount$;
  public summaryCardList$: Observable<OmniConversationSummaryCard[]>;
  public filtersList = [
    { label: 'All', value: '', selected: true },
    { label: 'Live Chats', value: 'live', selected: false },
    { label: 'Direct Messages', value: 'async', selected: false },
  ];
  public selectedFilter = '';
  private loadOneListItemAtATime$ = this.facade.sortedSummaryCardItems$.pipe(
    take(1),
    concatMap((data) => data),
    bufferCount(3),
    concatMap((data) => timer(0).pipe(map(() => data))),
    scan<OmniConversationSummaryCard[], OmniConversationSummaryCard[]>(
      (acc, val) => [...acc, ...val],
      [],
    ),
  );
  historyFilterSub = this.chatFacade.historyFilter$.subscribe(
    (historyFilter) => {
      this.setFilter(historyFilter);
    },
  );

  private loadAllListItemsAtOnce$ = this.facade.sortedSummaryCardItems$;

  // public summaryCardList$ = this.loadOneListItemAtATime$.pipe(
  //   concat(this.loadAllListItemsAtOnce$),
  // );

  setSummaryCardList() {
    this.summaryCardList$ = this.loadOneListItemAtATime$.pipe(
      concat(this.loadAllListItemsAtOnce$),
      map((itemsList) =>
        this.canEnableNewDMUI && this.selectedFilter !== ''
          ? itemsList.filter((item) => item.type === this.selectedFilter)
          : itemsList,
      ),
    );
  }

  public trackByFunc(index: number, item: OmniConversationSummaryCard): string {
    return item.conversationId;
  }

  public setFilter(value: string) {
    this.selectedFilter = value;
    this.filtersList.forEach(
      (filterObj) =>
        (filterObj.selected = filterObj.value === value ? true : false),
    );
    this.setSummaryCardList();
  }

  constructor(
    public facade: ConvSummaryListFacade,
    public conversationsFacade: OmniConversationsFacade,
    public chatFacade: OmniChatFacade,
  ) {}

  ngOnInit() {
    this.setSummaryCardList();
  }

  ngOnDestroy() {
    this.historyFilterSub.unsubscribe();
  }
}
