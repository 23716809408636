<cigna-omni-comm-channel
  *ngIf="content$ | async as content"
  data-omni-qa="cigna-omni-comm-channel-support"
>
  <cigna-omni-comm-channel-header
    omniQaTag="omni-comm-channel-support-header"
    >{{ content.header }}</cigna-omni-comm-channel-header
  >
  <cigna-omni-comm-channel-subheader
    *ngIf="content.headerByline"
    omniQaTag="omni-comm-channel-support-subheader"
    >{{ content.headerByline }}</cigna-omni-comm-channel-subheader
  >
  <cigna-omni-comm-channel-content
    *ngIf="content.body || content.icon"
    [icon]="content.icon"
    [iconColors]="iconColors"
    [iconData]="iconData"
    omniQaTag="omni-comm-channel-support-content-icon"
  >
    <ng-container *ngFor="let line of content.body; index as i">
      <p
        [class.is-bold]="line.bold"
        [attr.data-omni-qa]="'omni-comm-channel-support-content-' + i"
      >
        {{ line.text }}
      </p>
    </ng-container>
  </cigna-omni-comm-channel-content>
  <cigna-omni-comm-channel-button
    *ngIf="content.buttonText"
    (interaction)="interact(content)"
    [copy]="content.buttonText"
    omniQaTag="omni-comm-channel-support-button"
  ></cigna-omni-comm-channel-button>
</cigna-omni-comm-channel>
