import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  isDevMode,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import { LobPreferenceDialogComponent } from '@cigna/chcp/shared/ui';
import { UserFacade } from '@cigna/chcp/shared/user-profile-data-access';
import { RouteData } from '@cigna/chcp/shared/util';
import { ShellFacade } from '@cigna/chcp/shell/data-access';
import { RouterFacade } from '@cigna/shared/angular/core/router-state-data-access';
import {
  FeatureToggleType,
  FeatureTogglesFacade,
} from '@cigna/shared/angular/features-feature';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, combineLatest } from 'rxjs';

import { LobPref } from '@cigna/chcp/auth/util';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
} from '@cigna/chcp/shared/environment-util';
import {
  filter,
  map,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

@Component({
  selector: 'cigna-layout-secure',
  templateUrl: './layout-secure.component.html',
  styleUrls: ['./layout-secure.component.scss'],
})
export class LayoutSecureComponent implements OnInit, OnDestroy {
  firstName$ = this.userFacade.firstName$;
  lastName$ = this.userFacade.lastName$;
  userid$ = this.authFacade.userid$;
  showPasswordChangeWarning$ = this.userFacade.showPasswordChangeWarning$;
  lastPasswordChangeDate$ = this.userFacade.lastPasswordChangeDate$;
  hasUndefinedLobPref$ = this.userFacade.hasUndefinedLobPref$;
  onDestroy$ = new Subject<void>();
  impersonator$ = this.authFacade.isImpersonator$;

  isChatAllowedImpersonator$ = combineLatest([
    this.authFacade.isImpersonator$,
    this.authFacade.isChatAllowedImpersonator$,
    this.authFacade.isUserAuthenticated$,
  ])
    .pipe(
      filter(
        ([_isImpersonator, _isChatAllowedImpersonator, isUserAuthenticated]) =>
          isUserAuthenticated,
      ),
      take(1),
      takeUntil(this.onDestroy$),
    )
    .subscribe(
      ([isImpersonator, isChatAllowedImpersonator, _isUserAuthenticated]) => {
        this.featureToggleFacade.updateFeatureToggles(
          {
            isNotImpersonator: !isImpersonator || isChatAllowedImpersonator,
          },
          FeatureToggleType.PERMISSION,
        );
        return isChatAllowedImpersonator;
      },
    );

  isMedicalUser$ = this.authFacade.isMedicalUser$;

  profileNav$ = this.shellFacade.profileNav$;
  profileNavError$ = this.shellFacade.profileNavError$;
  siteNav$ = this.shellFacade.siteNav$;
  siteNavError$ = this.shellFacade.siteNavError$;
  alert$ = this.shellFacade.alertSecure$;
  messageCenterFunction$ = this.authFacade.hasMessageCenterCapability$;

  routeData$ = this.routerFacade.data$ as Observable<RouteData>;
  routeUrl$ = this.routerFacade.url$;

  isResourcePage$ = this.routeData$.pipe(
    map((data) => data.routeCode === 'resources'),
  );
  logoLink$ = combineLatest([this.isResourcePage$, this.isMedicalUser$]).pipe(
    map(([isResourcePage, isMedicalUser]) =>
      isResourcePage
        ? isMedicalUser
          ? `${this.envConfig.api.gateways.cdn}assets/chcp/medical/resourceLibrary/resourceLibraryMed.html`
          : `${this.envConfig.api.gateways.cdn}assets/chcp/dental/resourceLibrary/resourceLibraryDent.html`
        : '/dashboard',
    ),
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  inBrowserPlatform: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DEV_MODE = false;

  constructor(
    public shellFacade: ShellFacade,
    private userFacade: UserFacade,
    private authFacade: AuthFacade,
    private routerFacade: RouterFacade,
    private cookieService: CookieService,
    private featureToggleFacade: FeatureTogglesFacade,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) platformId: {},
    @Inject(ENVIRONMENT_CONFIG) private envConfig: EnvironmentConfig,
  ) {
    this.inBrowserPlatform = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.featureToggleFacade.updateFeatureToggles(
      {
        isUserAuthorized: true,
      },
      FeatureToggleType.PERMISSION,
    );

    /* FOR LOCAL DEV ONLY */
    if (isDevMode()) {
      this.DEV_MODE = true;
      this.authFacade.loadMockTokens();
    }

    // todo: should we move this to graphql
    this.authFacade.lob$
      .pipe(
        filter((lob) => lob !== undefined && lob !== null),
        take(1),
      )
      .subscribe(() => {
        this.userFacade.getLoginProfile();
      });

    this.shellFacade.loadNav();
    this.shellFacade.loadSecureAlerts();

    // remove deeplink cookie after login unless we are in the middle of mfa flow
    this.routeUrl$
      .pipe(
        filter((url) => !url?.startsWith('/mfa')),
        take(1),
        tap(() => {
          this.cookieService.delete('CHCP_DEEPLINK', '/');
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe();

    this.hasUndefinedLobPref$
      .pipe(take(2), withLatestFrom(this.authFacade.isImpersonator$))
      .subscribe(([hasUndefinedLobPref, isImpersonator]) => {
        if (hasUndefinedLobPref && !isImpersonator) {
          this.dialog
            .open(LobPreferenceDialogComponent, {
              disableClose: true,
              autoFocus: false,
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe(({ lobPref }: { lobPref: LobPref }) => {
              if (lobPref) {
                this.userFacade.updateProfile({ lobPref });
              }
            });
        }
      });
  }

  // checkGlobalAlert() {
  // in case neeed for a global popup comes back, first argument vehemently against it, then after failing use this code
  // if (this.cookieService.get('COVID_ALERT_SHOWN') !== 'Y' && !isDevMode()) {
  //   this.dialog.open(DialogSecureAlertComponent, {
  //     width: '700px',
  //     disableClose: true,
  //     autoFocus: false,
  //   });
  //   this.cookieService.put('COVID_ALERT_SHOWN', 'Y', {
  //     expires: addDays(new Date(), 365),
  //     path: '/',
  //     secure: true,
  //   });
  // }
  // }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
