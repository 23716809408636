import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { IconDynamicColors, IconDynamicData } from '@cigna/omni/shared-util';
import { ConversationDTO } from '@cigna/vampire-dto';
import {
  GatewayConfig,
  SHARED_GATEWAY_CONFIG,
} from '@cigna/shared/angular/gateways-util';
import { DcmAssetsFacade } from '@cigna/omni/dcm-state-data-access';

@Component({
  selector: 'cigna-dcm-summary-header',
  templateUrl: './dcm-summary-header.component.html',
  styleUrls: ['./dcm-summary-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DcmSummaryHeaderComponent implements AfterContentInit {
  @Input()
  public body?: string;

  @Input()
  public chevronColor: IconDynamicColors;

  @Input()
  public conversationId: string;

  @Input()
  public iconData?: IconDynamicData;

  @Input()
  public isClosed: boolean;

  @Input()
  public date: string;

  @Input()
  public name: string;

  @Input()
  public hasNewMessages: boolean;

  @Input()
  public state: string;

  @Input()
  public context: ConversationDTO['context'];

  @Output()
  buttonClicked: EventEmitter<void> = new EventEmitter();
  public interaction: EventEmitter<void> = new EventEmitter();
  isEvernorth: boolean;
  canUpdateAnalyticsData: boolean;
  cdn: string;

  assets$ = this.dcmAssetsFacade.DcmAssets$;

  public buttonClick(): void {
    this.buttonClicked.emit();
  }

  constructor(
    private readonly dcmAssetsFacade: DcmAssetsFacade,
    @Inject(SHARED_GATEWAY_CONFIG) private readonly _config: GatewayConfig,
  ) {
    this.cdn = this._config.cdn;
  }

  ngAfterContentInit() {
    this.dcmAssetsFacade.loadAssets(this.conversationId);
  }
}
