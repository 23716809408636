import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeaturesModule } from '@cigna/shared/angular/features-feature';
import { OmniChatModule } from '@cigna/omni/chat-feature';
import { OmniChatBubbleModule } from '@cigna/omni/chat-bubble-feature';
import { OmniInterceptionsModule } from '@cigna/omni/interceptions-feature';
import { OmnibusInitializationModule } from '@cigna/omni/omnibus-initialization-data-access';
import { OmniPollingStateModule } from '@cigna/omni/polling-state-data-access';
import { OmniRightNowDataAccessModule } from '@cigna/omni/right-now-data-access';
import { OmniEntryComponent } from './omni-entry/omni-entry.component';
import { OmniNoBubbleEntryComponent } from './omni-no-bubble-entry/omni-no-bubble-entry.component';
import { OmniFancyInvitationsModule } from '@cigna/omni/fancy-invitations-feature';

@NgModule({
  imports: [
    CommonModule,
    OmnibusInitializationModule,
    OmniChatBubbleModule,
    OmniChatModule,
    OmniFancyInvitationsModule,
    OmniInterceptionsModule,
    OmniPollingStateModule,
    OmniRightNowDataAccessModule,
    FeaturesModule,
  ],
  declarations: [OmniEntryComponent, OmniNoBubbleEntryComponent],
  exports: [OmniEntryComponent, OmniNoBubbleEntryComponent],
})
export class OmniEntryModule {}
