export const LOB_DENTAL = 'DEN';
export const LOB_MEDBH = 'MEDBH';
export const LOB_MEDICAL = 'MED';
export const LOB_BEHAVIORAL = 'BEH';

export type LOB =
  | typeof LOB_DENTAL
  | typeof LOB_MEDBH
  | typeof LOB_MEDICAL
  | typeof LOB_BEHAVIORAL;

export type LobPref = 'EBH' | 'CHCP' | 'BOTH' | 'TBD';
