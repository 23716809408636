<p class="error-message">
  We had trouble loading this information. Please try again later.
</p>

<details *ngIf="!production">
  <summary>Error Details</summary>

  <div class="error-details">
    <strong cignaTestTag="error-details__name">{{ error.name }}:</strong>
    <code cignaTestTag="error-details__message">{{ error.message }}</code>
    <pre *ngIf="error.stack">{{ error.stack }}</pre>
  </div>
</details>
