<cigna-tab-navbar
  [class.tab-border]="border"
  [class.tab-style-links]="tabStyle === 'links'"
  [class.tab-style-buttons]="tabStyle === 'buttons'"
  role="tablist"
  *ngIf="!hideSingleTab || tabs.length > 1"
  [isMultiLineTab]="isMultiLineTab"
>
  <ng-container
    *ngFor="let tab of tabs"
    [ngTemplateOutlet]="tab.labelRef.template"
  >
  </ng-container>
</cigna-tab-navbar>

<div *ngIf="activeTab$ | async; let activeTab" class="tabs-content">
  <ng-container [ngTemplateOutlet]="activeTab.contentTemplate"></ng-container>
</div>
