import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, map, timeout } from 'rxjs/operators';

import { UserProfile } from '@cigna/chcp/shared/user-profile-data-access';
import {
  apiGatewayTimeout,
  checkValidResponse,
  logError,
} from '@cigna/chcp/shared/util';
import {
  IsamResponse,
  MfaWamUpdateRequestBody,
  SendCodeRequest,
  VerifyCodeRequest,
} from './mfa.model';

@Injectable({
  providedIn: 'root',
})
export class MfaService {
  public mfaPublicApi: string;
  public delegationApi: string;
  public preferencesApi: string;
  public isamApi: string;
  public isamStepupApi: string;
  public CONSUMER_CODE = '1000';
  constructor(
    private http: HttpClient,
    @Inject('secureApi') secureApi: string,
    @Inject('consumerCode') private consumerCode: string,
  ) {
    this.mfaPublicApi = '/prv/public/mfa/v1';
    this.delegationApi = `${secureApi}/delegation/v2`;
    this.preferencesApi = `${secureApi}/preferences/v2`;
    this.isamApi = '/mga/sps/apiauthsvc';
    this.isamStepupApi = '/route/secure/mfastepup';
  }

  private isamHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  isamStepUp() {
    return this.http.get(this.isamStepupApi).pipe(catchError(logError));
  }

  isamGenerateCode(stateId: string, contactId: string) {
    const params: HttpParams = new HttpParams().set('StateId', stateId);

    const requestBody = {
      operation: 'generate',
      'otp.user.otp-method.id': contactId,
    };

    return this.http
      .put(this.isamApi, requestBody, {
        params,
        headers: this.isamHeaders,
      })
      .pipe(catchError(logError));
  }

  isamRegenerateCode(stateId: string) {
    const params: HttpParams = new HttpParams().set('StateId', stateId);

    const requestBody = {
      operation: 'generate',
      Regenerate: 'Regenerate',
    };

    return this.http
      .put(this.isamApi, requestBody, {
        params,
        headers: this.isamHeaders,
      })
      .pipe(catchError(logError));
  }

  isamReselectCode(stateId: string) {
    const params: HttpParams = new HttpParams().set('StateId', stateId);

    const requestBody = {
      operation: 'select',
      Reselect: 'Reselect',
    };

    return this.http
      .put(this.isamApi, requestBody, {
        params,
        headers: this.isamHeaders,
      })
      .pipe(catchError(logError));
  }

  isamVerifyCode(
    stateId: string,
    code: string,
    hint: string,
    remember: boolean,
  ) {
    const params: HttpParams = new HttpParams().set('StateId', stateId);

    const requestBody = {
      'otp.user.otp': code,
      'otp.user.otp-hint': hint,
      operation: 'verify',
      ...(remember && { register: 'consent' }),
    };

    return this.http
      .put(this.isamApi, requestBody, {
        params,
        headers: this.isamHeaders,
        observe: 'response',
      })
      .pipe(
        map((res) => ({ status: res.status, body: res.body as IsamResponse })),
        catchError(logError),
      );
  }

  sendContactCodePublic(sendCodeRequest: SendCodeRequest) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.consumerCode,
    );
    return this.http
      .post(`${this.mfaPublicApi}/send-code`, sendCodeRequest, {
        params,
      })
      .pipe(map(checkValidResponse), catchError(logError));
  }

  verifyContactCodePublic(verifyCodeRequest: VerifyCodeRequest) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.consumerCode,
    );
    return this.http
      .post(`${this.mfaPublicApi}/verify-code`, verifyCodeRequest, {
        params,
      })
      .pipe(map(checkValidResponse), catchError(logError));
  }

  sendContactCodeSecure(sendCodeRequest: SendCodeRequest) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.consumerCode,
    );
    return this.http
      .post(`${this.preferencesApi}/send-code`, sendCodeRequest, {
        params,
      })
      .pipe(map(checkValidResponse), catchError(logError));
  }

  verifyContactCodeSecure(verifyCodeRequest: VerifyCodeRequest) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.consumerCode,
    );
    return this.http
      .post(`${this.preferencesApi}/verify-code`, verifyCodeRequest, {
        params,
      })
      .pipe(map(checkValidResponse), catchError(logError));
  }

  getUserDetails() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.consumerCode,
    );
    return this.http
      .get(`${this.mfaPublicApi}/user/details`, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) => res.user),
        catchError(logError),
      );
  }

  updateUserDetails(userData: Partial<UserProfile>) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.consumerCode,
    );
    return this.http
      .put(`${this.mfaPublicApi}/user/details`, userData, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) => res.user),
        catchError(logError),
      );
  }

  getMfaTinDetailsByWam() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );
    return this.http
      .get(`${this.delegationApi}/wam/mfaDetails`, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) => res.mfaTinDetails),
        catchError(logError),
      );
  }

  putMfaTinDetails(mfaTinDetails: MfaWamUpdateRequestBody[]) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );
    return this.http
      .put(
        `${this.delegationApi}/wam/mfaDetails`,
        {
          mfaDetails: mfaTinDetails,
        },
        {
          params,
        },
      )
      .pipe(
        map(checkValidResponse),
        catchError(logError),
        timeout(apiGatewayTimeout),
      );
  }
}
