<div class="advocate-matched">
  <h1 class="advocate-matched-heading" data-test-id="header-advocate-matched">
    You’ve been matched to a Care Advocate!
  </h1>
  <ng-container *ngIf="assets$ | async as assets; else defaultComp">
    <div class="advocate-matched-avatar">
      <img
        data-test-id="image-advocate-matched"
        *ngIf="
          conversationId && assets.data && assets.data.get(conversationId);
          else defaultAvatar
        "
        [src]="
          assets.data.get(conversationId)
            ? cdn + assets.data.get(conversationId).avatar
            : ''
        "
        alt="Agent Avatar"
      />
    </div>
    <div class="advocate-matched-name" data-test-id="text-advocate-matchedname">
      <b>
        <span *ngIf="assets.data; else defaultAgentName">{{
          conversationId && assets.data.get(conversationId)
            ? assets.data.get(conversationId).first_name
            : agentName
        }}</span>
      </b>
      <span class="advocate-matched-name-details"
        >,
        {{
          conversationId && assets.data.get(conversationId)
            ? assets.data.get(conversationId).job_title
            : 'Care Advocate'
        }}
        of
        {{
          conversationId && assets.data.get(conversationId)
            ? assets.data.get(conversationId).years_of_experience
            : '1'
        }}
        years</span
      >
    </div>
    <div
      class="advocate-matched-buttons"
      *ngIf="assets.data; else defaultButtons"
    >
      <button
        class="send-message"
        data-test-id="button-sendmessage"
        (click)="
          goToSendMessage(
            conversationId && assets.data.get(conversationId)
              ? assets.data.get(conversationId).first_name
              : agentName
          )
        "
      >
        Send a Message
      </button>
      <button
        class="view-profile"
        data-test-id="button-view-profile"
        (click)="goToProfile()"
      >
        View Advocate Profile
      </button>
    </div>
  </ng-container>
</div>
<ng-template #defaultAvatar>
  <div class="advocate-matched-avatar-default"></div>
</ng-template>
<ng-template #defaultAgentName>
  <b>{{ agentName }}</b>
</ng-template>
<ng-template #defaultButtons>
  <div class="advocate-matched-buttons">
    <button class="send-message" (click)="goToSendMessage(agentName ?? '')">
      Send a Message
    </button>
    <button class="view-profile" (click)="goToProfile()">
      View Advocate Profile
    </button>
  </div>
</ng-template>
<ng-template #defaultComp>
  <div class="advocate-matched-avatar">
    <ng-container *ngTemplateOutlet="defaultAvatar"> </ng-container>
  </div>
  <div class="advocate-matched-name">
    <ng-container *ngTemplateOutlet="defaultAgentName"> </ng-container>
  </div>
  <ng-container *ngTemplateOutlet="defaultButtons"> </ng-container>
</ng-template>
