<button
  *cignaTabLabel
  cignaTabButton
  [ngStyle]="width !== '' ? { width: width } : {}"
  [ngClass]="returnColorClass()"
  (click)="onClick($event)"
  lang="{{ languageAttr }}"
  role="tab"
  [attr.id]="id"
  [attr.aria-controls]="id ? id + '-panel' : null"
  [tabindex]="isActive ? 0 : -1"
  [attr.data-test-id]="'button-'.concat((label || '').split(' ').join(''))"
>
  {{ label }}
</button>

<ng-template #contentTemplate><ng-content></ng-content></ng-template>
