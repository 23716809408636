import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cigna-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  /**
   * Set the type of alert.
   *
   * - `'info'`: Blue box, with dark blue info-circle (i) icon
   * - `'warning'`: Orange box, with dark orange exclamation-triangle icon
   * - `'error'`: Red box, with dark red exclamation-circle icon
   */
  @Input() type: 'info' | 'warning' | 'error' | 'success';
  /**
   * Set the size of the alert icon. Valid values are `'default'`
   * (default) for normal size and `'small'` for a smaller icon.
   */
  @Input() size: 'small' | 'default' = 'default';
  /**
   * Determine if there is a close button (X) for the alert and what
   * text appears next to the X. If this value is `null` (default), do not show the close button.
   * If this value is a non-empty string, show a close button with the given text.
   */
  @Input() close: string | null = null;
  /**
   * Emits when the close button (X) was clicked.
   */
  /**
   overrides background color regardless of alert type to white.
   */
  @Input() hasWhiteBackground = false;
  @Input() closeContainerClass = '';

  @Output() closeClicked = new EventEmitter<void>();

  get iconArialabel(): string {
    switch (this.type) {
      case 'info':
        return 'Important information';
      case 'warning':
        return 'Warning alert';
      case 'error':
        return 'Error message';
      case 'success':
        return 'Confirmation message';
    }
  }

  closeAlert() {
    this.closeClicked.emit();
  }
}
