import { InjectionToken } from '@angular/core';

export interface OmniRightNowConfig {
  liveChatUrl: string;
  sdkUrl: string;
  fedRedirectUrl: string;
}

export const OMNI_RIGHT_NOW_CONFIG = new InjectionToken<OmniRightNowConfig>(
  'OMNI_RIGHT_NOW_CONFIG',
);
