import { Component, Input } from '@angular/core';
export enum SpinnerSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

@Component({
  selector: 'cigna-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() hint = 'Loading...';
  @Input() size: SpinnerSize = SpinnerSize.large;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() silent: boolean;
}
