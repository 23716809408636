import { Injectable, TemplateRef } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

export interface Tab {
  label: string;
  contentTemplate: TemplateRef<any>;
  isActive: boolean;
  activate(): void;
  deactivate(): void;
}

/** @internal */
@Injectable()
export class TabSwitchService {
  private _tabs: Tab[] = [];
  private _activeTab$ = new BehaviorSubject<Tab | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _switchingTabs = false;
  private get _activeTab() {
    return this._activeTab$.value;
  }

  get activeTab$(): Observable<Tab | undefined> {
    return this._activeTab$.asObservable();
  }

  updateLayout(tabs: Tab[]) {
    this._tabs = tabs;
    this.ensureActiveTab();
  }

  switchToTab(tab: Tab) {
    this._switchingTabs = true;

    try {
      if (this._activeTab) {
        this._activeTab.deactivate();
      }

      this._activeTab$.next(tab);
    } finally {
      this._switchingTabs = false;
    }
  }

  ensureActiveTab() {
    if (this._switchingTabs) {
      return;
    }

    const newActiveTab = this._tabs.find((t) => t.isActive) || this._tabs[0];

    if (newActiveTab) {
      newActiveTab.activate();
    } else {
      this._activeTab$.next(undefined);
    }
  }
}
