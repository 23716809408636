<div class="cg-dialog">
  <h3 class="cg-dialog__header">
    <span *ngIf="data?.title">{{ data.title }}</span>
    <span *ngIf="!data?.title">{{
      'shared.dialog.explainAccess.header' | translate
    }}</span>
    <button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      (click)="dialogRef.close()"
    >
      {{ 'shared.dialog.explainAccess.closeBtn' | translate }}
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h3>
  <mat-dialog-content *ngrxLet="isDentalUser$ as isDentalUser">
    <p [innerHTML]="'shared.dialog.explainAccess.delegate' | translate"></p>
    <p
      [innerHTML]="'shared.dialog.explainAccess.patientsearch' | translate"
    ></p>
    <p [innerHTML]="'shared.dialog.explainAccess.claimsearch' | translate"></p>
    <p
      [innerHTML]="'shared.dialog.explainAccess.reconsideration' | translate"
    ></p>
    <p [innerHTML]="'shared.dialog.explainAccess.remittance' | translate"></p>
    <p [innerHTML]="'shared.dialog.explainAccess.directory' | translate"></p>
    <p [innerHTML]="'shared.dialog.explainAccess.eft' | translate"></p>
    <p
      *ngIf="!isDentalUser"
      [innerHTML]="'shared.dialog.explainAccess.precert' | translate"
    ></p>
    <p
      *ngIf="
        appContext === 'chcp' && (isMedicarePilotUser$ | async) && !isDentalUser
      "
      [innerHTML]="'shared.dialog.explainAccess.medicareview' | translate"
    ></p>
    <p
      *ngIf="
        appContext === 'chcp' && (isMedicarePilotUser$ | async) && !isDentalUser
      "
      [innerHTML]="'shared.dialog.explainAccess.medicaresubmit' | translate"
    ></p>

    <p
      *ngIf="appContext === 'chcp' && !isDentalUser"
      [innerHTML]="
        'shared.dialog.explainAccess.eocActionableReporting' | translate
      "
    ></p>
    <p
      *ngIf="appContext === 'chcp' && !isDentalUser"
      [innerHTML]="
        'shared.dialog.explainAccess.cccClinicalRepPortal' | translate
      "
    ></p>
    <p
      *ngIf="appContext === 'chcp' && !isDentalUser"
      [innerHTML]="
        'shared.dialog.explainAccess.iCollabClinicalRepPortal' | translate
      "
    ></p>
  </mat-dialog-content>
</div>
