<cigna-icon
  *ngIf="hasRole === 'bot' && isEvernorth"
  type="evernorth-chat"
  class="cigna-logo"
></cigna-icon>

<img
  *ngIf="!isEvernorth && hasRole === 'bot'"
  alt="Cigna"
  class="cigna-logo logoWrapper"
  [src]="'assets/images/cignahlthcr_logo_wht_bluebg.svg' | cdnPrefix"
/>

<div
  *ngIf="hasRole === 'agent'"
  [class]="
    agentLogoColor === 'red' ? 'agentLogoWrapper red' : 'agentLogoWrapper green'
  "
>
  <label
    [class]="
      agentLogoColor === 'red'
        ? 'agentCharLogo redChar'
        : 'agentCharLogo greenChar'
    "
    >{{ participantName.substring(0, 1).toLocaleUpperCase() }}</label
  >
</div>
