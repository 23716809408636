import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cigna-dialog-video-embed',
  templateUrl: './dialog-video-embed.component.html',
  styleUrls: ['./dialog-video-embed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogVideoEmbedComponent {
  videoUrl = '';

  constructor(
    public dialogRef: MatDialogRef<DialogVideoEmbedComponent>,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      player: 'limelight' | 'youtube';
      id: string;
      width: number;
      height: number;
    },
  ) {}

  getYoutubeUrl() {
    return `https://www.youtube.com/embed/${this.data.id}`;
  }

  getLimelightUrl() {
    return `https://link.videoplatform.limelight.com/media/?mediaId=${this.data.id}&width=${this.data.width}&height=${this.data.height}&playerForm=LVPPlayer&embedMode=html&htmlPlayerFilename=limelightjs-player.js&orgid=6540f51f4b444780ba7c63c5e866d66a`;
  }
}
