import { SystemMessageListItem } from '../interfaces';
import {
  ConversationDTO,
  ConversationSystemMessageEvent,
} from '@cigna/vampire-dto';

export const transformDMAutoReply = (
  conversation: ConversationDTO,
): SystemMessageListItem[] =>
  conversation.events
    .filter(
      (e): e is ConversationSystemMessageEvent => e.type === 'systemMessage',
    )
    .map((se) => ({
      _id: se._id,
      copy: se.message,
      created: se.created,
      type: se.type,
    }));
