<ng-container *ngIf="(dmConversationCount$ | async) && canEnableNewDMUI">
  <div class="filter-container">
    <div class="filter-btns-container">
      <button
        *ngFor="let filter of filtersList; index as i"
        cignaButton="primary medium"
        [ngClass]="{ selected: filter.selected }"
        [attr.aria-label]="
          filter.selected ? filter.label + ' Selected' : filter.label
        "
        (click)="setFilter(filter.value)"
      >
        {{ filter.label }}
      </button>
    </div>
    <p class="desc">Sorted Newest to Oldest</p>
  </div>
</ng-container>
<ng-container *ngIf="summaryCardList$ | async as summaryCardList">
  <ul>
    <li *ngFor="let item of summaryCardList; trackBy: trackByFunc; index as i">
      <cigna-omni-conversation-summary-card
        [attr.data-omni-qa-summary-card-conversation-newMessages]="
          item.newMessages
        "
        [attr.data-omni-qa-summary-card-conversation-state]="item.state"
        [attr.data-omni-qa-summary-card-conversationId]="item.conversationId"
        [attr.data-omni-qa-summary-card-index]="i"
        [body]="item.body"
        [chevronColor]="item.chevronColor"
        [conversationId]="item.conversationId"
        [date]="item.date"
        [icon]="item.icon"
        [iconColors]="item.iconColors"
        [iconData]="item.iconData"
        [isClosed]="item.isClosed"
        [hasNewMessages]="item.newMessages"
        [subject]="item.subject"
        [state]="item.state"
        [canEnableNewDMUI]="(dmConversationCount$ | async) && canEnableNewDMUI"
        [type]="item.type"
        [typeLabel]="item.typeLabel"
        [labelIcon]="item.labelIcon"
        [lastMessageBy]="item.lastMessageBy"
        [context]="item.context"
        [agentData]="item.agentData"
      ></cigna-omni-conversation-summary-card>
    </li>
  </ul>
  <ng-container *ngIf="summaryCardList?.length === 0">
    <div class="message-container">
      <div class="message-box">You have no previous conversations.</div>
    </div>
  </ng-container>
</ng-container>
