import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-omni-outage-screen',
  templateUrl: './chat-outage-screen.component.html',
  styleUrls: ['./chat-outage-screen.component.scss'],
})
export class OmniMessagingOutageScreenComponent {
  @Input() public chatOutageMsg: string;
  @Input() public hasChatOutage: boolean;

  constructor() {}
}
