import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { ConversationMessageDialogTableData } from '../interfaces';

@Component({
  selector: 'cigna-omni-messaging-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingDataTableComponent implements OnInit {
  @Input() public props: ConversationMessageDialogTableData;
  @Input() public displayType = 'regular';

  public primaryKeyIndex: number;

  ngOnInit() {
    this.setPrimaryKeyIndex();
  }

  setPrimaryKeyIndex() {
    const primaryKey = this.props.primary;
    const index = this.props.columnHeaders.findIndex(
      (item) => item[0] === primaryKey,
    );
    this.primaryKeyIndex = index === -1 ? 0 : index;
  }
}
