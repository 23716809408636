import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  FANCYINVITATIONS_FEATURE_KEY,
  reducer,
} from './+state/fancy-invitations.reducer';
import { FancyInvitationsFacade } from './+state/fancy-invitations.facade';
import { FancyInvitationsEffects } from './+state/fancy-invitations.effect';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FANCYINVITATIONS_FEATURE_KEY, reducer),
    EffectsModule.forFeature([FancyInvitationsEffects]),
  ],
  providers: [FancyInvitationsFacade],
})
export class OmniFancyInvitationsStateModule {}
