import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ServiceError, ReconsiderationType } from '@cigna/chcp/shared/util';
import {
  AdjustmentDisplayStatus,
  Adjustment,
  AdjustmentStatus,
  isDentalPreserviceAppeal,
} from '@cigna/chcp/claims/shared/util';
import { differenceInDays } from 'date-fns';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AsyncData } from '@cigna/shared/angular/core/interfaces-util';

@Component({
  selector: 'cigna-reconsideration-history',
  templateUrl: './reconsideration-history.component.html',
  styleUrls: ['./reconsideration-history.component.scss'],
})
export class ReconsiderationHistoryComponent {
  @Input() adjustments: AsyncData<Adjustment[], ServiceError>;
  @Input() hasReconsiderationAccess: boolean;
  @Input() isImpersonator: boolean;
  @Input() isPrecert = false;

  @Output() historyData = new EventEmitter<number>();
  @Output() removeAdjustment = new EventEmitter<{
    id: string;
    reconType: ReconsiderationType;
  }>();

  @ViewChild('removeConfirmationTemplate', { static: false })
  removeConfirmationTemplate: TemplateRef<any>;

  dialogRef: MatDialogRef<any>;
  AdjustmentDisplayStatus: { [key: string]: string } = AdjustmentDisplayStatus;

  adjustmentStatus = AdjustmentStatus;
  noNotesStatuses = [AdjustmentStatus.IN_PROCESS, AdjustmentStatus.RECEIVED];

  constructor(private dialog: MatDialog) {}

  accessHistory(i: number) {
    this.historyData.emit(i);
  }

  remove(id: string, reconType: ReconsiderationType) {
    this.dialogRef = this.dialog.open(this.removeConfirmationTemplate, {
      autoFocus: false,
      disableClose: true,
      width: '600px',
      data: { id, reconType },
    });
  }

  confirmRemoveAdjustment(id: string, reconType: ReconsiderationType) {
    if (!this.isImpersonator && this.hasReconsiderationAccess) {
      this.removeAdjustment.emit({ id, reconType });
    }
  }

  getDraftDaysRemaining(adjustment: Adjustment): number {
    return differenceInDays(
      (adjustment.expirationTime as number) * 1000,
      new Date(),
    );
  }

  getDisplayReconsiderationType(adjustment: Adjustment): string {
    return (
      (isDentalPreserviceAppeal(adjustment)
        ? 'pre-treatment request appeal'
        : adjustment.reconsiderationType) || ''
    );
  }

  openDecisionNotes(container: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(container, {
      autoFocus: false,
      width: '600px',
    });
  }
}
