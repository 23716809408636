import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  SECURE_DCM_ASSETS_FEATURE_KEY,
  dcmAssetsReducer,
} from './+state/dcm.reducer';
import { DcmAssetsFacade } from './+state/dcm.facade';
import { SecureDcmAssetsEffects } from './+state/dcm.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SECURE_DCM_ASSETS_FEATURE_KEY, dcmAssetsReducer),
    EffectsModule.forFeature([SecureDcmAssetsEffects]),
  ],
  providers: [DcmAssetsFacade],
})
export class OmniDcmStateModule {}
