import { Injectable, TemplateRef } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

export enum PortalTarget {
  /**
   * The space at the bottom of page header.
   */
  PageHeaderBottom = 'page-header-bottom',
  /**
   * The space between page header and page content.
   */
  InnerPageTop = 'inner-page-top',
  /**
   * The space at the bottom of the page for modals.
   */
  Modal = 'modal',
}

export interface PortalHandle {
  detach: () => void;
}

interface PortalMetadata {
  [target: string]: {
    exitsAmount: number;
    templates$: BehaviorSubject<Array<TemplateRef<any>>>;
  };
}

/** @internal */
@Injectable({ providedIn: 'root' })
export class PortalService {
  private _metadata: PortalMetadata = {};

  getEvents(target: PortalTarget): Observable<Array<TemplateRef<any>>> {
    return this.getTargetMetadata(target).templates$.asObservable();
  }

  getAttachedTemplatesAmount(target: PortalTarget): number {
    return this.getTargetMetadata(target).templates$.value.length;
  }

  getExitsAmount(target: PortalTarget): number {
    return this.getTargetMetadata(target).exitsAmount;
  }

  attach(target: PortalTarget, template: TemplateRef<any>): PortalHandle {
    const { templates$ } = this.getTargetMetadata(target);

    templates$.next([...templates$.value, template]);

    return {
      detach: () => this.detach(target, template),
    };
  }

  detach(target: PortalTarget, template: TemplateRef<any>) {
    const { templates$ } = this.getTargetMetadata(target);

    templates$.next(templates$.value.filter((t) => t !== template));
  }

  /** @internal */
  registerExitStatus(target: PortalTarget, created: boolean) {
    const metadata = this.getTargetMetadata(target);

    metadata.exitsAmount += created ? 1 : -1;
  }

  private getTargetMetadata(target: PortalTarget) {
    return (
      this._metadata[target] ||
      (this._metadata[target] = {
        exitsAmount: 0,
        templates$: new BehaviorSubject([]),
      })
    );
  }
}
