import { Component, OnInit } from '@angular/core';
import { MessagingFacade } from '@cigna/omni/messaging-feature';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniConversationSummaryCard } from '@cigna/omni/conversation-summary-card-feature';

@Component({
  selector: 'cigna-omni-active-dms',
  templateUrl: './active-dms.component.html',
  styleUrls: ['./active-dms.component.scss'],
})
export class ActiveDMsComponent implements OnInit {
  public disclaimer$ = this.msgFacade.disclaimer$;
  activeDMConversations$ = this.conversationsFacade.openDMConversations$;
  constructor(
    private msgFacade: MessagingFacade,
    private conversationsFacade: OmniConversationsFacade,
  ) {}

  ngOnInit() {
    this.conversationsFacade.getActiveAsyncConversation();
  }

  public trackByFunc(index: number, item: OmniConversationSummaryCard): string {
    return item.conversationId;
  }
}
