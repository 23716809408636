import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { OidcResult, AuthError } from '@cigna/shared/angular/auth/oidc-util';
import { AuthPartialState } from './auth.reducer';
import { isAuthLoading, getAuthData, getAuthError } from './auth.selectors';
import {
  loadAuth,
  loadAuthError,
  loadAuthSuccess,
  unauthorizedError,
} from './auth.actions';

@Injectable()
export class AuthFacade {
  isLoading$ = this.store$.pipe(select(isAuthLoading));
  authDataSnapshot$: Observable<OidcResult | undefined> = this.store$.pipe(
    select(getAuthData),
  );
  authData$: Observable<OidcResult> = this.authDataSnapshot$.pipe(
    filter((x): x is NonNullable<ReturnType<typeof getAuthData>> => !!x),
  );
  errorSnapshot$: Observable<AuthError | undefined> = this.store$.pipe(
    select(getAuthError),
  );
  error$: Observable<AuthError> = this.errorSnapshot$.pipe(
    filter((x): x is NonNullable<ReturnType<typeof getAuthError>> => !!x),
  );

  constructor(private store$: Store<AuthPartialState>) {}

  authenticate() {
    this.store$.dispatch(loadAuth());
  }

  loadAuthSuccess(auth: OidcResult) {
    this.store$.dispatch(loadAuthSuccess({ auth }));
  }

  unauthorizedError() {
    this.store$.dispatch(unauthorizedError());
  }

  loadAuthError(error: AuthError) {
    this.store$.dispatch(loadAuthError({ error }));
  }
}
