import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  initialState,
  mfaReducer,
  MFA_FEATURE_KEY,
} from './+state/mfa.reducer';
import { MfaEffects } from './+state/mfa.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(MFA_FEATURE_KEY, mfaReducer, { initialState }),
    EffectsModule.forFeature([MfaEffects]),
  ],
})
export class ChcpMfaDataAccessModule {}
