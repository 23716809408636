import { Directive, Input, OnInit, Injector } from '@angular/core';

import { TRACK_FN } from '../../utils';
import { TrackUtil } from '../../track-util';

@Directive({
  selector: '[cignaTrackInit]',
})
export class TrackInitDirective implements OnInit {
  constructor(private _util: TrackUtil, private _injector: Injector) {}

  @Input('cignaTrackInit') params: string | [string, ...unknown[]];

  ngOnInit() {
    this._util[TRACK_FN]({ injector: this._injector }, this.params);
  }
}
