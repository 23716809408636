import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverComponent } from './popover/popover.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { UiButtonModule } from '@cigna/shared/angular/button-ui';

const DECLARATIONS = [PopoverComponent];
@NgModule({
  imports: [CommonModule, PopoverModule.forRoot(), UiButtonModule],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class UiPopoverModule {}
