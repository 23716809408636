/* eslint-disable max-classes-per-file */
import { NgModule, Provider, ProviderToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModalModule } from '@cigna/shared/angular/modal-ui';

import {
  OnLogout,
  TimeoutPopupConfig,
  TimeoutPopupEvents,
  TIMEOUT_POPUP_CONFIG,
  TIMEOUT_POPUP_EVENTS,
} from './timeout-popup.config';
import { TimeoutPopupComponent } from './timeout-popup.component';

@NgModule({
  imports: [CommonModule, UiModalModule],
  declarations: [TimeoutPopupComponent],
  exports: [TimeoutPopupComponent],
})
export class SharedTimeoutPopupFeatureModule {}

export function timeoutPopupProviders<
  TDeps extends Array<ProviderToken<unknown>>,
>(
  config: TimeoutPopupConfig,
  eventsFactory?: (...args: Deps<TDeps>) => TimeoutPopupEvents | OnLogout,
  ...deps: TDeps
): Provider[] {
  if (eventsFactory) {
    return [
      { provide: TIMEOUT_POPUP_CONFIG, useValue: config },
      { provide: TIMEOUT_POPUP_EVENTS, useFactory: eventsFactory, deps },
    ];
  }

  return [{ provide: TIMEOUT_POPUP_CONFIG, useValue: config }];
}

type Deps<T> = T extends [
  ProviderToken<infer U1>,
  ProviderToken<infer U2>,
  ProviderToken<infer U3>,
]
  ? [U1, U2, U3]
  : T extends [ProviderToken<infer U1>, ProviderToken<infer U2>]
  ? [U1, U2]
  : T extends Array<ProviderToken<infer U>>
  ? U[]
  : never[];
