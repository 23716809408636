import { Component } from '@angular/core';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@cigna/chcp/auth/data-access';

@Component({
  selector: 'cigna-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(private authFacade: AuthFacade) {}

  loggedIn$ = this.authFacade.isUserAuthenticated$;
  url = window.location.href;
}
