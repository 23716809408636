import {
  Injectable,
  ComponentFactoryResolver,
  Injector,
  Inject,
  ApplicationRef,
  OnDestroy,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import {
  PortalService,
  PortalTarget,
  PortalExitComponent,
} from '@cigna/shared/angular/portals-ui';

const HOST_CLASS = 'cigna-modal-exit-host';

@Injectable()
export class ModalExitFactory {
  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _portalService: PortalService,
    private _injector: Injector,
    private _appRef: ApplicationRef,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  ensureModalExitExists() {
    if (this._portalService.getExitsAmount(PortalTarget.Modal) > 0) {
      return;
    }

    const host =
      this._document.body.querySelector(`.${HOST_CLASS}`) || this.createHost();

    const component = this._componentFactoryResolver
      .resolveComponentFactory(PortalExitComponent)
      .create(this._injector, undefined, host);

    component.instance.target = PortalTarget.Modal;

    this._appRef.attachView(component.hostView);
  }

  private createHost() {
    const host = this._document.createElement('div');

    host.classList.add(HOST_CLASS);

    this._document.body.appendChild(host);

    return host;
  }
}
