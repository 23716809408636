import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { content, OmniAnalyticsService } from '@cigna/omni/shared-util';
import { playAudio } from '../input/util';
import {
  FeatureTogglesFacade,
  FeatureToggleType,
} from '@cigna/shared/angular/features-feature';
import {
  CtaListItemData,
  MessageList,
  ParticipantEventListItem,
} from '../interfaces';
import { ConversationInterface } from '../interfaces/conversation.interface';
import { OutroObj } from '../interfaces/outro.interface';
import { WaitTimeTypes } from '../wait-time/wait-time.component';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniCommChannelFacade } from '@cigna/omni/comm-channel-feature';
import isEqual from 'lodash/isEqual';
import { take } from 'rxjs/operators';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import {
  ConversationEvent,
  ConversationMessageEventDTO,
} from '@cigna/vampire-dto';
@Component({
  selector: 'cigna-omni-messaging-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingListComponent
  implements AfterViewChecked, OnChanges, OnDestroy, OnInit
{
  @Input()
  public messages: MessageList;
  @Input()
  public outroObj: OutroObj;
  @Input()
  public conversationType: WaitTimeTypes;
  @Input()
  public disclaimer: string;
  @Input()
  public conversation: ConversationInterface;
  @Input()
  public isLiveChatAvailable: boolean;
  @Input()
  public chatCloseHrsMsg: string;
  @Output()
  public messageViewed = new EventEmitter<string>();
  @Output()
  public sendCta = new EventEmitter<CtaListItemData>();
  @Output()
  public navigateBack = new EventEmitter();
  private mostRecentMessageId: string;
  public isTyping: boolean;
  @Input() private soundFileLink: string;
  @Input()
  public isUnmuted: boolean;
  public isEvernorth: boolean;
  public isDcm: boolean;
  @Input() public isDcmChat: boolean;
  public canUpdateAnalyticsData: boolean;
  @Input() public chatTitle: string;
  @Input() public shouldEnablePreChatWelcome: boolean;
  @Input() public isHistoryView: boolean;
  @Input() public isAiBot: boolean;
  public isHistoryLinkEnable: boolean;
  @Input() public isUseRightNowChat: boolean;
  @Input() public shouldEnabledBackToPrevious: boolean;
  @Input() public canReplaceBackToPreviousWithEndChat: boolean;
  @Input() public isUpdatingConversation: boolean;
  @Input() public isTerminatedUser: boolean;
  @Input() public terminatedUserMessage: Record<string, string>;
  @Input() public canEnableNewDMUI: boolean;
  @Input() public hasChatOutage: boolean;
  @Input() public chatOutageMsg: string;
  @Input() public hasActiveLiveChat: boolean;

  public agentLeftMessageCopy: string;
  public isTransferMessage: boolean;
  public hasNewMessage: boolean;
  public isKoreAiOutage: boolean;
  primaryTopicName: string;
  primaryTopicValue: string;
  secondaryTopicName: string;
  private isAutoScrollAllowed: boolean;
  isDCMChat$ = this.featureTogglesFacade.featuresEnabled(['isDcmChat']);

  isInitialized = false;
  tabFlashIntervalId: ReturnType<typeof setInterval>;
  defaultTitle = '';
  newMessageTitle = '';
  private titleMapping: { [index: string]: string } = {
    myCigna: 'myCigna - new message',
    CHCP: 'CHCP - new message',
    'Cigna for Employers': 'Cigna for Employers - new message',
    Evernorth: 'Evernorth - new message',
  };
  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    const target = event?.target as HTMLElement;
    // Checking if we are at bottom of chat
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight - 2) {
      this.hasNewMessage = false;
    }
  }

  constructor(
    private elRef: ElementRef,
    private featureTogglesFacade: FeatureTogglesFacade,
    private analytics: OmniAnalyticsService,
    private conversationsFacade: OmniConversationsFacade,
    private commChannelFacade: OmniCommChannelFacade,
    private chatFacade: OmniChatFacade,
  ) {
    this.featureTogglesFacade
      .featuresEnabled(['isEvernorthEnabled'])
      .pipe(take(2))
      .subscribe((isEvm: boolean) => {
        this.isEvernorth = isEvm;
      });

    this.featureTogglesFacade
      .featuresEnabled(['enableChatAnalyticsDataLayer'])
      .pipe(take(2))
      .subscribe((canUpdateAnalyticsData: boolean) => {
        this.canUpdateAnalyticsData = canUpdateAnalyticsData;
      });
    this.resetTitle = this.resetTitle.bind(this);
  }

  ngOnInit() {
    this.defaultTitle = document.title;
    this.setNewMessageTitle();
    if (this.canEnableNewDMUI) {
      this.terminatedUserMessage = {
        ...content.newDMUITerminatedUserMessage,
      };
    }
  }

  setNewMessageTitle() {
    const matchingTitle = Object.keys(this.titleMapping).find((key) =>
      document.title.includes(key),
    );
    this.newMessageTitle = matchingTitle
      ? this.titleMapping[matchingTitle]
      : 'Chat - new message';
  }

  public trackByMostRecent(_i: any, message: any): string | void {
    if (message && message._id) {
      return `${message._id}${message.mostRecent ? 'mostRecent' : ''}`;
    }
  }

  public playAudioOnAgentJoining(
    last: ParticipantEventListItem,
    previousValue: MessageList,
  ): void {
    const doesIncludeCopy =
      previousValue?.length &&
      Object.keys(previousValue.slice(-1)[0])?.includes('copy');
    const hasClosedChat = this.verifyTimeStampForCloseChat();

    if ((this.conversation?.state as string) === 'opening') {
      this.agentLeftMessageCopy = '';
    }

    if (
      last?.copy &&
      !doesIncludeCopy &&
      last?.copy.includes(content.agentJoinMessage) &&
      this.isUnmuted
    ) {
      playAudio(this.soundFileLink);
    }

    if (
      (this.conversation?.state as string) === 'closed' &&
      last?.copy &&
      !doesIncludeCopy &&
      hasClosedChat &&
      last?.copy.includes(content.agentLeftMessage)
    ) {
      this.agentLeftMessageCopy = last?.copy;
      this.conversationsFacade.setCloseOrExitFlag(false);
      this.isHistoryLinkEnable = false;
      if (!this.canUpdateAnalyticsData) {
        this.analytics.trackEvent('chatEndByAgent', {});
      }
    }
  }

  verifyTimeStampForCloseChat() {
    const createdDate = this.conversation?.events
      ? this.conversation?.events?.slice(-1)[0].created
      : '';
    return new Date(createdDate) > new Date(Date.now() - 5000);
  }

  newMessageFlash() {
    if (document.hidden) {
      document.title = this.newMessageTitle;
      this.clearIntervalId(this.tabFlashIntervalId);
      this.tabFlashIntervalId = setInterval(() => {
        document.title =
          document.title === this.newMessageTitle
            ? this.defaultTitle
            : this.newMessageTitle;
      }, 1000);
      document.addEventListener('visibilitychange', this.resetTitle);
    }
  }

  resetTitle() {
    if (!document.hidden) {
      document.title = this.defaultTitle;
      this.clearIntervalId(this.tabFlashIntervalId);
      document.removeEventListener('visibilitychange', this.resetTitle);
    }
  }

  playAudioOnAgentsMessage() {
    if (
      this.isUnmuted &&
      this.conversation.newMessages &&
      this.conversation.lastMessageBy?.role.toLocaleLowerCase() === 'agent'
    ) {
      this.newMessageFlash();
      playAudio(this.soundFileLink);
    }
  }

  public checkIsAgentTyping() {
    if (this.conversation.events) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const [lastConvoEvent] = this.conversation.events?.slice(-1);
      this.isTyping =
        lastConvoEvent?.type === 'activity' &&
        lastConvoEvent?.activityType === 'responding';
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public ngOnChanges(changes: ComponentChanges<this>): void {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const isSystemMessage = (this.conversation?.events || []).find(
      (event: ConversationEvent): event is ConversationMessageEventDTO =>
        event.type === 'systemMessage',
    );

    if (
      (this.conversation?.state as string) === 'transfer-manual' ||
      (this.isAiBot &&
        // eslint-disable-next-line no-unsafe-optional-chaining
        this.conversation?.state !== 'closed' &&
        !isSystemMessage)
    ) {
      this.featureTogglesFacade.updateFeatureToggles(
        { enableInput: true },
        FeatureToggleType.PERMISSION,
      );
    } else {
      this.featureTogglesFacade.updateFeatureToggles(
        { enableInput: false },
        FeatureToggleType.PERMISSION,
      );
    }

    if (changes.messages && changes.messages.currentValue) {
      const [last] = changes.messages.currentValue.slice(-1);
      const id = (last && last._id) || null;
      const previousValue = changes.messages.previousValue;
      if (id && id !== this.mostRecentMessageId) {
        this.messageViewed.emit(id);
        this.mostRecentMessageId = id;
      }
      this.newMessageAction();
      this.playAudioOnAgentJoining(
        last as ParticipantEventListItem,
        previousValue,
      );
    }

    if (
      this.isUnmuted &&
      changes.outroObj?.currentValue?.show &&
      !changes.outroObj?.previousValue?.show &&
      this.verifyTimeStampForCloseChat()
    ) {
      playAudio(this.soundFileLink);
      this.scrollUpdate();
    }

    if (changes.conversation?.currentValue) {
      if (
        !isEqual(
          changes.conversation.currentValue.events?.filter(
            (event) => event.type === 'message',
          ),
          changes.conversation.previousValue?.events?.filter(
            (event) => event.type === 'message',
          ),
        )
      ) {
        this.playAudioOnAgentsMessage();
      }
      if (
        !isEqual(
          changes.conversation.currentValue.events,
          changes.conversation.previousValue?.events,
        )
      ) {
        this.isAutoScrollAllowed = true;
        this.newMessageAction();
        this.updateTopicNames();
        this.checkIsAgentTyping();
      }
    }
    if (
      changes.isLiveChatAvailable &&
      !changes.isLiveChatAvailable.currentValue &&
      this.conversation?.type === 'bot' &&
      !this.shouldEnablePreChatWelcome
    ) {
      this.navigateBack.emit();
    }
    if (
      !changes.isLiveChatAvailable?.previousValue &&
      changes.isLiveChatAvailable?.currentValue &&
      !changes.isLiveChatAvailable.firstChange &&
      this.shouldEnablePreChatWelcome &&
      !this.conversation
    ) {
      this.commChannelFacade.interact(
        {
          buttonText: 'Chat with a Guide',
          buttonId: 'chatGuide',
        },
        {
          tags:
            this.isEvernorth && !this.canUpdateAnalyticsData
              ? ['advocate', 'evn']
              : this.isUseRightNowChat
              ? ['advocate', 'member']
              : ['default'],
        },
        false,
      );
    }

    const transferEventManualIndex = this.conversation?.events?.findIndex(
      (event) => event.state === 'transfer-manual',
    );
    const transferEventIndex = this.conversation?.events?.findIndex(
      (event) => event.state === 'transfer',
    );
    const transferEventWithMessage =
      transferEventManualIndex > -1
        ? this.conversation?.events
            ?.slice(transferEventManualIndex)
            .filter((event) => event.type === 'message')
        : [];

    if (
      (transferEventWithMessage?.length > 1 || transferEventIndex > -1) &&
      (this.conversation?.state as string) !== 'closed' &&
      !this.isHistoryView
    ) {
      this.conversationsFacade.setCloseOrExitFlag(true);
      this.isHistoryLinkEnable = true;
    }
    if (
      (this.conversation?.state as string) === 'closed' &&
      !this.isHistoryView
    ) {
      this.conversationsFacade.setCloseOrExitFlag(false);
      this.isHistoryLinkEnable = false;
    }
  }

  newMessageAction(): void {
    if (this.isDcmChat) {
      this.hasNewMessage = this.hasNewMessageAvailable();
    } else {
      this.scrollUpdate();
    }
  }

  updateTopicNames() {
    const messageEvents = this.conversation?.events?.filter(
      (event) => event.type === 'message',
    );
    this.primaryTopicName = messageEvents?.[1]?.message || '';

    this.secondaryTopicName = messageEvents?.[3]?.message || '';
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public sendCtaFn(data: CtaListItemData) {
    this.sendCta.emit({
      ...data,
      topicId:
        this.conversationType === 'hil'
          ? 'nurseTopic'
          : this.secondaryTopicName
          ? 'chatMinibotTopic'
          : this.primaryTopicName
          ? 'agentSecondaryTopic'
          : 'agentPrimaryTopic',
      primaryTopicName: this.primaryTopicName,
    });
    if (this.isEvernorth || !this.isAiBot) {
      if (!this.canUpdateAnalyticsData) {
        const topicLabel = data.value ? data.value : '';
        this.analytics.trackEvent('trackPrimaryTopicClick', { topicLabel });
      } else if (!this.primaryTopicName && !this.secondaryTopicName) {
        // todo: update to new use data layer format standard
        this.primaryTopicValue = data.value;
        this.analytics.dataLayer.updateAdobeDataLayer({
          event: 'pageAction',
          actionData: {
            actionName: 'chatPrimaryTopic',
            hitType: 'action',
            widgetName: 'chat-primary-topic',
            customLinkText: data.value,
            requirementId: 'ac24062',
            userStory: 'ODCTC-586',
          },
        });
      } else if (this.primaryTopicName && !this.secondaryTopicName) {
        this.analytics.dataLayer.updateAdobeDataLayer({
          event: 'pageAction',
          actionData: {
            actionName: 'chatSecondaryTopic',
            hitType: 'action',
            widgetName: 'chat-secondary-topic',
            customLinkText: data.value,
            linkLocation: this.primaryTopicValue,
            requirementId: 'ac24063',
            userStory: 'ODCTC-586',
          },
        });
      } else if (this.primaryTopicName && this.secondaryTopicName) {
        this.analytics.dataLayer.updateAdobeDataLayer({
          event: 'pageAction',
          actionData: {
            actionName: 'idCardResponse',
            hitType: 'action',
            widgetName: 'id-card-more-assistance-response',
            customLinkText: data.label,
            requirementId: 'acCHT005',
            userStory: 'ODCTC-586',
          },
        });
      }
    }
  }

  public backToPrevious() {
    const paramTags = this.isEvernorth
      ? ['advocate', 'evn']
      : this.isUseRightNowChat
      ? ['advocate', 'member']
      : ['default'];
    if (this.canReplaceBackToPreviousWithEndChat) {
      this.conversationsFacade.endConversation(this.conversation?._id);
      this.chatFacade.closeDialog();
    } else {
      this.conversationsFacade.backToPrevious(this.conversation?._id, {
        tags: paramTags,
      });
    }
    this.conversationsFacade.setHeaderFocus(true);
  }

  scrollUpdate() {
    this.elRef.nativeElement.scrollTop = this.elRef.nativeElement.scrollHeight;
  }

  minibotLinkClick(linkName: string) {
    if (!this.canUpdateAnalyticsData) {
      this.analytics.trackEvent('chatMinibotLinkClick', {
        linkName,
        prop20: this.primaryTopicName as string,
      });
    } else {
      this.analytics.dataLayer.updateAdobeDataLayer({
        event: 'pageAction',
        actionData: {
          actionName: 'idCardMessageClickHereLink',
          hitType: 'action',
          widgetName: 'id-card-message-click-here-link',
          customLinkText: 'click here',
          requirementId: 'acCHT004',
          userStory: 'ODCTC-586',
        },
      });
    }
  }

  hasNewMessageAvailable(): boolean {
    return this.messages.some((message) => message.newMessage);
  }
  hasCustomerSendNewMessage(): boolean {
    return this.conversation.lastMessageBy?.role === 'customer';
  }

  ngAfterViewChecked() {
    this.isKoreAiOutage =
      this.isAiBot && this.messages.some((msg) => msg.type === 'systemMessage');
    if (this.isAutoScrollAllowed) {
      if (this.isInitialized) {
        this.newMessageAction();
        if (this.hasCustomerSendNewMessage()) {
          this.scrollUpdate();
        }
      } else {
        this.isInitialized = true;
        this.scrollUpdate();
      }
      this.isAutoScrollAllowed = false;
    }
  }

  ngOnDestroy(): void {
    this.clearIntervalId(this.tabFlashIntervalId);
  }

  clearIntervalId(intervalId: ReturnType<typeof setInterval>) {
    if (intervalId) {
      clearInterval(intervalId);
    }
  }
}
