import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniGateway } from '@cigna/omni/shared-util';
import { Observable } from 'rxjs';
import { UserEvent } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PollingDataAccessService {
  constructor(private http: HttpClient, private gateway: OmniGateway) {}

  public getEvents(
    keepAlive: boolean = false,
    convType?: string,
  ): Observable<UserEvent[]> {
    const url =
      convType === 'async'
        ? this.gateway.awsUserEventsUrl()
        : this.gateway.userEventsUrl({ keepAlive });

    return this.http.get<UserEvent[]>(url);
  }
}
