import { RightNowAction, RightNowActionTypes } from './right-now.actions';

export const RIGHTNOW_FEATURE_KEY = 'rightNow';

export interface RightNowState {
  widgetInitialized: boolean;
}

export interface RightNowPartialState {
  readonly [RIGHTNOW_FEATURE_KEY]: RightNowState;
}

export const initialState: RightNowState = {
  widgetInitialized: false,
};

export function rightNowReducer(
  state: RightNowState = initialState,
  action: RightNowAction,
): RightNowState {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case RightNowActionTypes.InitializeWidgetSuccess: {
      return {
        ...state,
        widgetInitialized: true,
      };
    }
    default:
      return state;
  }
}
