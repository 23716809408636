import { AfterViewInit, Component } from '@angular/core';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';

@Component({
  selector: 'cigna-omni-chat-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent implements AfterViewInit {
  constructor(private chatFacade: OmniChatFacade) {}

  ngAfterViewInit() {
    this.chatFacade.dialogView();
  }
}
