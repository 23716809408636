import { Injectable } from '@angular/core';
import { LiveAvailability } from '@cigna/omni/data-access';
import { Store, select } from '@ngrx/store';
import {
  GetLiveAvailability,
  GetLiveAvailabilitySuccess,
  LiveAvailabilityLoaded,
  SetChatOutage,
  SetChatOutageMsg,
} from './live-availability.actions';
import { LiveAvailabilityState } from './live-availability.reducer';
import { liveAvailabilityQuery } from './live-availability.selectors';

@Injectable()
export class OmniLiveAvailabilityFacade {
  liveChatAvailable$ = this.store$.pipe(
    select(liveAvailabilityQuery.getLiveChatAvailable),
  );

  chatCloseHrsMsg$ = this.store$.pipe(
    select(liveAvailabilityQuery.getChatCloseHrsMsg),
  );

  isLiveAvailabilityLoaded$ = this.store$.pipe(
    select(liveAvailabilityQuery.getLiveAvailabilityLoaded),
  );

  outageSplashScreen$ = this.store$.pipe(
    select(liveAvailabilityQuery.getChatOutage),
  );

  outageSplashScreenMsg$ = this.store$.pipe(
    select(liveAvailabilityQuery.getChatOutageMsg),
  );

  constructor(private store$: Store<LiveAvailabilityState>) {}

  setLiveAvailability(liveAvailability: LiveAvailability) {
    this.store$.dispatch(new GetLiveAvailabilitySuccess(liveAvailability));
  }

  setLiveAvailabilityLoaded(isLoaded: boolean) {
    this.store$.dispatch(new LiveAvailabilityLoaded(isLoaded));
  }

  setChatOutage(hasChatOutage: boolean) {
    this.store$.dispatch(new SetChatOutage(hasChatOutage));
  }

  setChatOutageMsg(chatOutageMsg: string) {
    this.store$.dispatch(new SetChatOutageMsg(chatOutageMsg));
  }
}
