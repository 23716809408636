import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DcmAssetsResponse } from './dcm.interface';
import { DcmAssetsGateway } from '@cigna/omni/shared-util';

@Injectable({ providedIn: 'root' })
export class DcmAssetsService {
  constructor(private _gw: DcmAssetsGateway, private _http: HttpClient) {}

  getSecureDcmAssets(fileName: string): Observable<DcmAssetsResponse> {
    return this._http.get<DcmAssetsResponse>(
      `${this._gw.DcmAssetsUrl(fileName)}`,
    );
  }
}
