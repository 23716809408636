export const DEPRECATED_KEY_CODE_MAP: { [key: number]: string } = {
  9: 'Tab',
  13: 'Enter',
  27: 'Escape',
  38: 'ArrowUp',
  40: 'ArrowDown',
};

export function getKeyCode(event: KeyboardEvent): string {
  return event.key || DEPRECATED_KEY_CODE_MAP[event.which];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum MOUSE_BUTTON {
  Left = 1,
  Right = 2,
}
