<div
  *ngIf="shouldDisplayWaitMessage"
  #waitSection
  tabindex="-1"
  [ngClass]="{ live: type === 'live', waitMessage: hasEnablePreChatWelcome }"
  [ngSwitch]="type"
>
  <ng-container *ngSwitchCase="'live'">
    <p class="live" [ngClass]="{ 'customer-service': !personalGuideEligible }">
      {{ lineOne }}
    </p>
    <time [attr.datetime]="datetime">{{ lineTwo }}</time>
  </ng-container>
  <ng-container *ngSwitchCase="'asyncSupport'">
    <p class="async-support line-one">{{ lineOne }}</p>
    <p>{{ lineTwo }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="'asyncChe'">
    <p class="async-che">{{ lineOne }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="'asyncScheduleCoaching'">
    <p class="async-che">{{ lineOne }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="'hil'">
    <p class="live">{{ lineOne }}</p>
    <time *ngIf="lineTwo" [attr.datetime]="datetime">{{ lineTwo }}</time>
  </ng-container>
  <ng-container *ngSwitchCase="'noop'">
    <p class="async-support line-one">{{ lineOne }}</p>
  </ng-container>
  <ng-container *ngIf="hasEnablePreChatWelcome && footer">
    <hr />
    <footer class="waitMsgFooter">{{ footer }}</footer>
  </ng-container>
</div>
<footer *ngIf="footer && !hasEnablePreChatWelcome">{{ footer }}</footer>
