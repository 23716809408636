/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable, Inject } from '@angular/core';
import get from 'lodash/get';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AnalyticsService,
  AnalyticsTransforms,
  FeatureAnalyticsService,
  RuleSet,
  DATA_STORE,
  DataStore,
} from '@cigna/shared/angular/analytics-data-access';
import { ChatButtonId } from '../models';
import { getConversationType } from '../utilities';
import { ConversationDTO } from '@cigna/vampire-dto';
import {
  TrackChatStatus,
  TrackChatBack,
  TrackChatMinimize,
  TrackChatMaximize,
  TrackChatError,
  TrackChatEndClick,
  TrackChatBubbleClick,
  TrackFirstMessageViewed,
  TrackNewMessage,
  TrackFirstMessageSent,
  TrackFancyInvitationViewed,
  TrackFancyInvitationDismissed,
  TrackFancyInvitationReplyToMessage,
  TrackAutoReplyDisplayed,
  TrackChatBubbleClickOnEvm,
  TrackchatCloseClick,
  TrackChatWithUsButtonClickOnEvm,
  TrackAgentJoinOnEvm,
  TrackPrimaryTopicClick,
  TrackChatShellMinimize,
  TrackChatShellOpenAfterMinimize,
  TrackChatConversationHistoryView,
  TrackChatConversationHistoryViewAll,
  TrackEndChatWithinConv,
  TrackChatBackToConversation,
  TrackChatEndByAgent,
  TrackChatErrorDuringConv,
  TrackBackToPreviousClick,
  TrackViewChatHistoryClick,
  TrackDownloadTranscriptClick,
  TrackAgentJoin,
  TrackVerbatimText,
  TrackMinibotLinkClick,
} from './analytics-models';

interface ChatRules extends RuleSet {
  chatStatus: {
    data: {
      bubbleText: string;
    };
    track: TrackChatStatus;
  };
  chatBack: {
    data: {
      currentComponent: string;
    };
    track: TrackChatBack;
  };
  chatMinimize: {
    data: {
      currentComponent: string;
    };
    track: TrackChatMinimize;
  };
  chatMaximize: {
    data: {
      currentComponent: string;
    };
    track: TrackChatMaximize;
  };
  chatClose: {
    data: {};
    track: {};
  };
  chatAgentMessageMaximize: {
    data: {};
    track: {};
  };
  chatAgentJoinMaximize: {
    data: {};
    track: {};
  };
  chatStateChangeMaximize: {
    data: {};
    track: {};
  };
  chatBubbleClick: {
    data: {
      bubbleText: string;
    };
    track: TrackChatBubbleClick;
  };
  chatHomeView: {
    data: {
      homeButtonIds: string[];
      homeButtonTexts: string[];
    };
    track: {
      chat: {
        homeButtonIds: string[];
        homeButtonTexts: string[];
      };
    };
  };
  chatStartClick: {
    data: {
      buttonText: string;
      buttonId: ChatButtonId;
    };
    track: {
      chat: {
        buttonText: string;
        buttonId: ChatButtonId;
      };
    };
  };
  chatTopicClick: {
    data: {
      topicId: string;
      topicLabel: string;
      primaryTopicName?: string;
    };
  };
  chatStartSuccess: {
    data: {
      chatContext: string[];
      chatId: string;
      chatType: ConversationDTO['type'];
    };
    track: {
      chat: {
        chatContext: string[];
        chatId: string;
        chatType: ConversationDTO['type'];
      };
    };
  };
  chatMinibotLinkClick: {
    data: {
      prop20: string;
      linkName: string;
    };
    track: TrackMinibotLinkClick;
  };
  chatTransfer: {
    data: {
      chatContext: string[];
      chatId: string;
      chatType: ConversationDTO['type'];
    };
    track: {
      chat: {
        chatContext: string[];
        chatId: string;
        chatType: ConversationDTO['type'];
      };
    };
  };
  chatEndClick: {
    data: {};
    track: TrackChatEndClick;
  };
  chatEnd: {
    data: {
      chatContext: string[];
      chatId: string;
      chatSurveyId: string;
      chatType: ConversationDTO['type'];
      agentId: string;
    };
    track: {
      chat: {
        chatContext: string[];
        chatId: string;
        chatSurveyId: string;
        chatType: ConversationDTO['type'];
      };
    };
  };
  chatEndError: {
    data: {
      chatContext: string[];
      chatId: string;
      chatType: ConversationDTO['type'];
      chatEndErrorReason: string;
    };
    track: {
      chat: {
        chatContext: string[];
        chatId: string;
        chatType: ConversationDTO['type'];
        chatEndErrorReason: string;
      };
    };
  };
  chatDialogView: {
    data: {
      chatId: string;
      chatType: string;
      chatContext: string[];
      chatState: string;
    };
    track: {
      chat: {
        chatId: string;
        chatType: string;
        chatContext: string[];
        chatState: string;
      };
    };
  };
  chatHistoryView: {
    data: {};
    track: {};
  };
  agentToAgentTransfer: {
    data: {};
    track: {};
  };
  viewAllConversations: {
    data: {};
    track: {};
  };
  viewChat: {
    data: {
      newMessages: boolean;
      chatState: string;
    };
    track: {
      chat: {
        newMessages: boolean;
        chatState: string;
      };
    };
  };
  firstMessageViewed: {
    data: {
      chatId: string;
    };
    track: TrackFirstMessageViewed;
  };
  autoReplyDisplayed: {
    data: {};
    track: TrackAutoReplyDisplayed;
  };
  viewNewMessage: {
    data: {
      chatContext: ConversationDTO['context'];
    };
    track: TrackNewMessage;
  };

  firstMessageSent: {
    data: {
      chatId: string;
    };
    track: TrackFirstMessageSent;
  };

  fancyInvitationViewed: {
    data: {};
    track: TrackFancyInvitationViewed;
  };

  fancyInvitationDismissed: {
    data: {};
    track: TrackFancyInvitationDismissed;
  };

  fancyInvitationReplyToMessage: {
    data: {};
    track: TrackFancyInvitationReplyToMessage;
  };

  chatError: {
    data: {
      serviceName: string;
      error: any;
    };
    track: TrackChatError;
  };

  chatBubbleClickOnEvm: {
    data: {
      bubbleText: string;
    };
    track: TrackChatBubbleClickOnEvm;
  };

  chatCloseClick: {
    data: {};
    track: TrackchatCloseClick;
  };

  chatWithUsClickOnEvm: {
    data: {
      buttonText: string;
    };
    track: TrackChatWithUsButtonClickOnEvm;
  };

  trackAgentJoinOnEvm: {
    data: {
      linkText: string;
    };
    track: TrackAgentJoinOnEvm;
  };

  trackPrimaryTopicClick: {
    data: {
      topicLabel: string;
    };
    track: TrackPrimaryTopicClick;
  };

  chatShellMinimize: {
    data: {
      currentComponent: string;
    };
    track: TrackChatShellMinimize;
  };

  chatShellOpenAfterMinimize: {
    data: {
      currentComponent: string;
    };
    track: TrackChatShellOpenAfterMinimize;
  };

  chatConversationHistoryView: {
    data: {
      linkText: string;
    };
    track: TrackChatConversationHistoryView;
  };

  chatConversationHistoryViewAll: {
    data: {
      linkText: string;
    };
    track: TrackChatConversationHistoryViewAll;
  };

  chatEndByAgent: {
    track: TrackChatEndByAgent;
  };

  endChatWithinConv: {
    data: {
      currentComponent: string;
    };
    track: TrackEndChatWithinConv;
  };

  chatBackToConversation: {
    data: {
      currentComponent: string;
    };
    track: TrackChatBackToConversation;
  };

  chatErrorDuringConv: {
    data: {
      serviceName: string;
      error: any;
    };
    track: TrackChatErrorDuringConv;
  };

  trackBackToPreviousClick: {
    data: {
      linkLabel: string;
      topicText: string;
    };
    track: TrackBackToPreviousClick;
  };

  trackViewChatHistoryClick: {
    data: {
      linkLabel: string;
    };
    track: TrackViewChatHistoryClick;
  };

  trackDownloadTranscriptClick: {
    data: {
      linkLabel: string;
    };
    track: TrackDownloadTranscriptClick;
  };

  trackAgentJoin: {
    data: {
      linkText: string;
    };
    track: TrackAgentJoin;
  };

  trackVerbatimText: {
    data: {
      linkText: string;
    };
    track: TrackVerbatimText;
  };
}

@Injectable({
  providedIn: 'root',
})
export class OmniAnalyticsService extends FeatureAnalyticsService<ChatRules> {
  protected readonly basePath = '';
  protected transformOptions = {
    chatDialogView: {
      replaceArrays: true,
    },
    chatStartSuccess: {
      replaceArrays: true,
    },
    chatTransfer: {
      replaceArrays: true,
    },
    chatEnd: {
      replaceArrays: true,
    },
    chatEndError: {
      replaceArrays: true,
    },
    chatHomeView: {
      replaceArrays: true,
    },
  };

  protected transforms: AnalyticsTransforms<ChatRules> = {
    chatStatus: ({ bubbleText }) => ({
      eVar95: bubbleText,
      prop13: 'pv-CHTB001b-Chat Status',
    }),
    chatBack: ({ currentComponent }) => ({
      prop3: 'Back',
      linkName: 'Back',
      prop13: 'ac-CHTB034',
      hier1: `${this.getHierarchy()}|Chat|${currentComponent}|Back`,
    }),
    chatBubbleClick: ({ bubbleText }) => ({
      prop3: bubbleText,
      linkName: bubbleText,
      prop13: 'ac-CHTB002-Bubble Click',
      hier1: `${this.getHierarchy()}|${bubbleText}`,
      events: ['event93'],
    }),
    chatHomeView: ({ homeButtonTexts, homeButtonIds }) => ({
      chat: {
        homeButtonTexts,
        homeButtonIds,
      },
    }),
    chatStartClick: ({ buttonText, buttonId }) => ({
      chat: {
        buttonText,
        buttonId,
      },
    }),
    chatTopicClick: ({ topicId, topicLabel, primaryTopicName }) => ({
      chat: {
        topicId,
        topicLabel,
        primaryTopicName,
      },
    }),
    chatStartSuccess: ({ chatContext, chatId, chatType }) => ({
      chat: {
        chatContext,
        chatId,
        chatType,
      },
    }),
    // eslint-disable-next-line sonarjs/no-identical-functions
    chatTransfer: ({ chatContext, chatId, chatType }) => ({
      chat: {
        chatContext,
        chatId,
        chatType,
      },
    }),
    chatEndClick: () => ({
      prop3: 'End Chat',
      linkName: 'End Chat',
      prop13: 'ac-CHTB030-Chat Close',
      hier1: `${this.getHierarchy()}|Chat|End Chat`,
    }),
    chatMinibotLinkClick: ({ prop20, linkName }) => ({
      prop20,
      linkName,
      prop13: 'ac24070',
      eVar119: 'ODCTC-378',
      eVar120: 'chat-minibot-link',
    }),
    chatEnd: ({ chatContext, chatId, chatSurveyId, chatType, agentId }) => ({
      chat: {
        chatContext,
        chatId,
        chatSurveyId,
        chatType,
        agentId,
      },
    }),
    chatEndError: ({ chatContext, chatId, chatType, chatEndErrorReason }) => ({
      chat: {
        chatContext,
        chatId,
        chatType,
        chatEndErrorReason,
      },
    }),
    chatDialogView: ({ chatId, chatState, chatType, chatContext }) => ({
      chat: {
        chatId,
        chatState,
        chatType,
        chatContext,
      },
    }),
    chatHistoryView: () => ({}),
    agentToAgentTransfer: () => ({}),
    viewAllConversations: () => ({}),
    viewChat: ({ newMessages, chatState }) => ({
      chat: {
        newMessages,
        chatState,
      },
    }),
    firstMessageSent: ({ chatId }) => ({
      eVar96: chatId,
      prop3: 'DM Customer Initial Reply',
      linkName: 'DM Customer Initial Reply',
      prop13: 'ac-CHTB031',
      hier1: `${this.getHierarchy()}|Chat|DM Customer Initial Reply`,
      events: ['event458'],
    }),
    firstMessageViewed: ({ chatId }) => ({
      eVar96: chatId,
      prop3: 'Case Manager DM Initial Message',
      linkName: 'Case Manager DM Initial Message',
      prop13: 'ac-CHTB038',
      hier1: `${this.getHierarchy()}|Chat|Case Manager DM Initial Message`,
      events: ['event457'],
    }),
    autoReplyDisplayed: () => ({
      prop3: 'DM Auto Reply',
      linkName: 'DM Auto Reply',
      prop13: 'ac-CHTB040',
      hier1: `${this.getHierarchy()}|Chat|DM Auto Reply`,
    }),
    chatMinimize: ({ currentComponent }) => ({
      prop3: 'Minimize',
      linkName: 'Minimize',
      prop13: 'ac-CHTB035',
      hier1: `${this.getHierarchy()}|Chat|${currentComponent}|Minimize`,
    }),
    chatMaximize: ({ currentComponent }) => ({
      prop3: 'Open',
      linkName: 'Open',
      prop13: 'ac-CHTB037',
      hier1: `${this.getHierarchy()}|Chat|${currentComponent}|Open`,
    }),
    chatClose: () => ({}),
    chatStateChangeMaximize: () => ({}),
    chatAgentMessageMaximize: () => ({}),
    chatAgentJoinMaximize: () => ({}),
    viewNewMessage: ({ chatContext }) => ({
      prop3: 'New Message',
      linkName: 'New Message',
      prop13: 'ac-CHTB036',
      hier1: `${this.getHierarchy()}|Chat|New Message`,
      eVar111: `conversationtype:${getConversationType(chatContext)}`,
    }),
    fancyInvitationViewed: () => ({
      // eslint-disable-next-line sonarjs/no-duplicate-string
      prop3: 'Fancy Invite modal',
      linkName: 'Fancy Invite modal',
      prop13: 'ac-CHTB032',
      hier1: `${this.getHierarchy()}|Chat|Fancy Invite modal`,
    }),
    fancyInvitationDismissed: () => ({
      prop3: 'Close',
      linkName: 'Close',
      prop13: 'ac-CHTB038',
      prop20: 'Fancy Invite modal',
      hier1: `${this.getHierarchy()}|Fancy Invite modal|Close`,
    }),
    fancyInvitationReplyToMessage: () => ({
      prop3: 'Reply To Message',
      linkName: 'Reply To Message',
      prop13: 'ac-CHTB033',
      prop20: 'Fancy Invite modal',
      hier1: `${this.getHierarchy()}|Fancy Invite modal|Reply To Message`,
    }),
    chatError: ({ serviceName, error }) => ({
      prop3: error?.statusText,
      linkName: error?.statusText,
      prop37: `Service Err^${serviceName}^${error?.status}^${error?.statusText}`,
      prop13: 'ac-CHTB003-Chat Error',
      hier1: `${this.getHierarchy()}|Chat|Error:${error?.statusText}`,
      events: ['event291'],
    }),
    chatBubbleClickOnEvm: ({ bubbleText }) => ({
      prop3: bubbleText,
      linkName: bubbleText,
      prop13: 'ac23485',
      hier1: `${this.getHierarchyForEvm()}|${bubbleText}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-110',
      prop67: 'action',
      eVar120: 'chat-bubble',
    }),
    chatCloseClick: () => ({
      prop3: 'chat closed',
      linkName: 'Chat closed',
      prop13: 'ac23485',
      hier1: `${this.getHierarchyForEvm()}|Chat closed`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-110',
      prop67: 'action',
      eVar120: 'chat-bubble',
    }),
    chatWithUsClickOnEvm: ({ buttonText }) => ({
      prop3: buttonText,
      linkName: buttonText,
      prop13: 'ac23486',
      hier1: `${this.getHierarchyForEvm()}|${buttonText}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-111',
      prop67: 'action',
      eVar120: 'chat-with-us-button',
    }),
    trackAgentJoinOnEvm: ({ linkText }) => ({
      prop3: linkText,
      linkName: linkText,
      prop13: 'ac23487',
      hier1: `${this.getHierarchyForEvm()}|${linkText}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-112',
      prop67: 'action',
      eVar120: 'chat-advocate-joins',
    }),
    trackPrimaryTopicClick: ({ topicLabel }) => ({
      prop3: topicLabel,
      linkName: topicLabel,
      prop13: 'ac23488',
      hier1: `${this.getHierarchyForEvm()}|${topicLabel}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-113',
      prop67: 'action',
      eVar120: 'chat-primary-topic',
    }),
    endChatWithinConv: ({ currentComponent }) => ({
      prop3: 'End Chat',
      linkName: 'End Chat',
      prop13: 'ac23490',
      hier1: `${this.getHierarchyForEvm()}|Chat|${currentComponent}|End Chat`,
      channel: this.getSiteSection(),
      prop67: 'action',
      eVar119: 'ODCTC-115',
      eVar120: 'chat-user-end-chat-link',
    }),
    chatEndByAgent: () => ({
      prop3: 'Advocate Ends Chat',
      linkName: 'Advocate Ends Chat',
      prop13: 'ac23491',
      hier1: `${this.getHierarchyForEvm()}|Chat|Advocate Ends Chat`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-116',
      prop67: 'action',
      eVar120: 'chat-advocate-ends',
    }),
    chatConversationHistoryViewAll: ({ linkText }) => ({
      prop3: linkText,
      linkName: linkText,
      prop13: 'ac23492',
      hier1: `${this.getHierarchyForEvm()}|${linkText}`,
      channel: this.getSiteSection(),
      prop67: 'action',
      eVar119: 'ODCTC-117',
      eVar120: 'chat-view-all-conversations',
    }),
    chatConversationHistoryView: ({ linkText }) => ({
      prop3: linkText,
      linkName: linkText,
      prop13: 'ac23493',
      hier1: `${this.getHierarchyForEvm()}|${linkText}`,
      channel: this.getSiteSection(),
      prop67: 'action',
      eVar119: 'ODCTC-118',
      eVar120: 'chat-conversation-history-item',
    }),
    chatShellMinimize: ({ currentComponent }) => ({
      prop3: 'Minimize',
      linkName: 'Minimize',
      prop13: 'ac23494',
      hier1: `${this.getHierarchyForEvm()}|Chat|${currentComponent}|Minimize`,
      channel: this.getSiteSection(),
      prop67: 'action',
      eVar119: 'ODCTC-119',
      eVar120: 'chat-minimize-link',
    }),
    chatBackToConversation: ({ currentComponent }) => ({
      prop3: 'Back',
      linkName: 'Back',
      prop13: 'ac23495',
      hier1: `${this.getHierarchyForEvm()}|Chat|${currentComponent}|Back`,
      channel: this.getSiteSection(),
      prop67: 'action',
      eVar119: 'ODCTC-120',
      eVar120: 'chat-back-link',
    }),
    chatShellOpenAfterMinimize: ({ currentComponent }) => ({
      prop3: 'Open chat',
      linkName: 'Open chat',
      prop13: 'ac23496',
      hier1: `${this.getHierarchyForEvm()}|Chat|${currentComponent}|Open chat`,
      channel: this.getSiteSection(),
      prop67: 'action',
      eVar119: 'ODCTC-121',
      eVar120: 'chat-open-minimized-link',
    }),
    chatErrorDuringConv: ({ serviceName, error }) => ({
      prop3: error?.statusText,
      linkName: error?.statusText,
      prop37: `Service Err^${serviceName}^${error?.status}^${error?.statusText}`,
      prop13: 'im23497',
      hier1: `${this.getHierarchyForEvm()}|Chat|Error:${error?.statusText}`,
    }),
    trackBackToPreviousClick: ({ linkLabel, topicText }) => ({
      prop3: linkLabel,
      linkName: linkLabel,
      prop13: 'ac24064',
      prop20: topicText,
      hier1: `${this.getHierarchy()}|${linkLabel}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-371',
      prop67: 'action',
      eVar120: 'chat-back-to-previous',
    }),
    trackViewChatHistoryClick: ({ linkLabel }) => ({
      prop3: linkLabel,
      linkName: linkLabel,
      prop13: 'ac24061',
      hier1: `${this.getHierarchy()}|${linkLabel}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-375',
      eVar120: 'chat-view-history',
      prop67: 'action',
    }),

    trackDownloadTranscriptClick: ({ linkLabel }) => ({
      prop3: linkLabel,
      linkName: linkLabel,
      prop13: 'ac24072',
      hier1: `${this.getHierarchy()}|${linkLabel}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-379',
      prop67: 'action',
      eVar120: 'chat-download-transcript',
    }),

    trackAgentJoin: ({ linkText }) => ({
      prop3: linkText,
      linkName: linkText,
      prop13: 'ac24067',
      hier1: `${this.getHierarchy()}|${linkText}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-372',
      prop67: 'action',
      eVar120: 'chat-agent-joins',
    }),

    trackVerbatimText: ({ linkText }) => ({
      prop3: linkText,
      linkName: linkText,
      prop13: 'ac24066',
      hier1: `${this.getHierarchy()}|${linkText}`,
      channel: this.getSiteSection(),
      eVar119: 'ODCTC-376',
      prop67: 'action',
      eVar120: 'chat-verbatim-text-sent',
    }),
  };

  getHierarchy() {
    return `myCigna.com|${get(
      this._digitalData,
      'page.pageInfo.siteSection',
      // eslint-disable-next-line sonarjs/no-duplicate-string
    )}|${get(this._digitalData, 'page.pageInfo.pageName')}`;
  }

  getHierarchyForEvm() {
    return `well.Evernorth.com|${get(
      this._digitalData,
      'page.pageInfo.siteSection',
    )}|${get(this._digitalData, 'page.pageInfo.pageName')}`;
  }

  getSiteSection() {
    return get<DataStore, string>(this._digitalData, 'page.pageInfo.pageName');
  }

  constructor(
    @Inject(DATA_STORE) private _digitalData: DataStore,
    analytics: AnalyticsService,
  ) {
    super(analytics);
  }
}
