import { InjectionToken, Provider } from '@angular/core';

export interface ImplicitFlowConfig {
  clientId: string;
  authorizeUri: string;
  tokenUri?: string;
  redirectUri: string;
  metadataUri: string;
  responseType?: 'token' | 'id_token' | 'token id_token' | 'code';
  clockSkew?: number;
  timeout?: number;
}

export const IMPLICIT_FLOW_CONFIG = new InjectionToken<ImplicitFlowConfig>(
  'IMPLICIT_FLOW_CONFIG',
);

export function implicitFlowConfig(config: ImplicitFlowConfig): Provider {
  return {
    provide: IMPLICIT_FLOW_CONFIG,
    useValue: config,
  };
}
