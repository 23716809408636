import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

export function sortById<T extends { id: number }>(a: T, b: T): number {
  return a.id - b.id;
}

export const objectsDiff = (newObject: any, originalObject: any) =>
  transform(newObject, (result, value, key) => {
    if (!isEqual(value, originalObject[key])) {
      (result as any)[key] =
        isObject(value) && isObject(originalObject[key])
          ? objectsDiff(value, originalObject[key])
          : value;
    }
  });

export const cleanObject = (o: any) => {
  for (const k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }

    // The property is an object
    cleanObject(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
};
