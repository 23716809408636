import capitalize from 'lodash/capitalize';
import { LOB } from '..';

export interface EntitlementLob {
  entitlement: Entitlement;
  lobs: LOB[];
}

export enum Entitlement {
  BasicInformation = 'BasicInformation',
  CCCCACClinicalReportingPortal = 'CCCCACClinicalReportingPortal',
  CollaborativeCare = 'CollaborativeCare',
  ClaimsSearch = 'ClaimsSearch',
  ClaimsSearch_Reconsideration = 'ClaimsSearch-Reconsideration',
  ClaimsSearch_ClaimStatusNotifications = 'ClaimsSearch-ClaimStatusNotifications',
  EOCActionableReporting = 'EOCActionableReporting',
  DelegationAccessAndViews = 'DelegationAccessAndViews',
  DirectoryUpdates = 'DirectoryUpdates',
  EnrollAndManageEFT = 'EnrollAndManageEFT',
  ICollaborateClinicalReportingPortal = 'ICollaborateClinicalReportingPortal',
  PatientSearch = 'PatientSearch',
  PatientReviewsView = 'PatientReviewsView',
  Precertification = 'Precertification',
  RemittanceReports = 'RemittanceReports',
  MedicareView = 'MedicareAdvantagePrecertificationViewOnly',
  MedicareSubmit = 'MedicareAdvantagePrecertificationSubmitRequest',
}

const isUpperCase = (str: string): boolean => str === str.toUpperCase();

// used only for local dev mocking
export const ALL_ENTITLEMENTS = Object.values(Entitlement);

// take entitlement as plain string and convert to entitlement format we get in JWT as defined in 'Entitlement' const
// eg, ("Delegation access and views") => "DelegationAccessAndViews"
export const mapEntitlementToTokenFormat = (entitlement: string): string =>
  `${entitlement
    .split(' ')
    .map((v) => (isUpperCase(v) ? v : capitalize(v)))
    .join('')}`;
