import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PortalTarget, PortalService } from './portal.service';

@Component({
  selector: 'cigna-portal-exit',
  template: `
    <ng-container
      *ngFor="let tpl of templates"
      [ngTemplateOutlet]="tpl"
      [ngTemplateOutletContext]="context"
    >
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalExitComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  constructor(
    private _portalService: PortalService,
    private _cdRef: ChangeDetectorRef,
  ) {}

  @Input() target: PortalTarget;
  @Input() context: object;

  templates: Array<TemplateRef<any>>;

  ngOnInit() {
    this._portalService.registerExitStatus(this.target, true);

    this._portalService
      .getEvents(this.target)
      .pipe(takeUntil(this._destroy$))
      .subscribe((templates) => {
        this.templates = templates;

        this._cdRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this._portalService.registerExitStatus(this.target, false);

    this._destroy$.next();
    this._destroy$.complete();
  }
}
