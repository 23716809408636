/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable } from '@angular/core';

import {
  DashboardClaimsDocumentGQL,
  DashboardClaimsQueryGQL,
  DashboardPatientsDocumentGQL,
  DashboardPatientsQueryGQL,
  FlagClaimInputGQL,
  FlagClaimUpdateGQLService,
  FlaggedClaimGQL,
  FlaggedPatientGQL,
  FlagPatientInputGQL,
  FlagPatientUpdateGQLService,
  FlagTypeGQL,
  RecentClaimInputGQL,
  SaveRecentClaimGQLService,
  PrecertInputGQL,
  SaveRecentPrecertificationGQLService,
  FlagPrecertificationUpdateGQLService,
  DashboardPrecertificationsQueryGQL,
  DashboardPrecertificationsDocumentGQL,
  PrecertResponseGQL,
} from './gql.generated';

import { format } from 'date-fns';

// todo: in future it might make sense to locate these services in data-access libs where they are used, for now putting in central location to keep isolated from ngrx

@Injectable({
  providedIn: 'root',
})
export class GraphQLMutations {
  constructor(
    private flaggedPatientGQL: FlagPatientUpdateGQLService,
    private flaggedClaimGQL: FlagClaimUpdateGQLService,
    private saveRecentClaimGQL: SaveRecentClaimGQLService,
    private saveRecentPrecertGQL: SaveRecentPrecertificationGQLService,
    private flagPrecertGQL: FlagPrecertificationUpdateGQLService,
  ) {}

  saveRecentClaim(claim: RecentClaimInputGQL): void {
    this.saveRecentClaimGQL.mutate({ claim }).subscribe();
  }

  saveRecentPrecert(precert: PrecertInputGQL) {
    return this.saveRecentPrecertGQL
      .mutate({ precertInput: precert })
      .subscribe();
  }

  updateFlaggedPrecert(
    flagType: FlagTypeGQL,
    precertData: PrecertResponseGQL,
  ): void {
    const precertDataFormatted: PrecertInputGQL = {
      payload: {
        member: {
          memberId: precertData.payload?.member?.memberId,
          patientSSN: precertData.payload?.member?.patientSSN,
          patientFirstName: precertData.payload?.member?.patientFirstName,
          patientLastName: precertData.payload?.member?.patientLastName,
          claimSystemCode: precertData.payload?.member?.claimSystemCode,
          sourceSystemCode: precertData.payload?.member?.sourceSystemCode,
          coverage: precertData.payload?.member?.coverage,
          asOfDate: precertData.payload?.member?.asOfDate,
          patientPlan: precertData.payload?.member?.patientPlan,
        },
        precertificaton: {
          precertNumber: precertData.payload?.precertificaton?.precertNumber,
          status: precertData.payload?.precertificaton?.status,
          startDate: precertData.payload?.precertificaton?.startDate,
          endDate: precertData.payload?.precertificaton?.endDate,
          precertSource: precertData.payload?.precertificaton?.precertSource,
        },
        providerDetails: {
          requestingProvider: {
            id:
              precertData.payload?.providerDetails?.requestingProvider?.id ||
              '',
            name: precertData.payload?.providerDetails?.requestingProvider
              ?.name,
            tinNumber:
              precertData.payload?.providerDetails?.requestingProvider
                ?.tinNumber,
          },
          servicingProvider: {
            id:
              precertData.payload?.providerDetails?.servicingProvider?.id || '',
            name: precertData.payload?.providerDetails?.servicingProvider?.name,
            tinNumber:
              precertData.payload?.providerDetails?.servicingProvider
                ?.tinNumber,
          },
        },
        compositeKey: precertData.payload?.compositeKey,
      },
      precertNumber: precertData.precertNumber,
      expirationTime: precertData.expirationTime,
      createdDt: format(new Date(), 'MM/dd/yyyy'),
    };
    this.flagPrecertGQL
      .mutate(
        {
          precertInput: precertDataFormatted,
          type: flagType,
        },
        {
          optimisticResponse: {
            flagPrecertificationUpdate: precertDataFormatted,
          },
          update: (proxy, { data }) => {
            // Read exisiting data from our cache for this query.
            const queryData = proxy.readQuery({
              query: DashboardPrecertificationsDocumentGQL,
            }) as DashboardPrecertificationsQueryGQL;

            // construct new query data based on response/optimistic update
            const newQueryData = {
              ...queryData,
              flaggedPrecertifications:
                flagType === 'A' && data?.flagPrecertificationUpdate
                  ? queryData.flaggedPrecertifications?.concat([
                      data.flagPrecertificationUpdate,
                    ])
                  : queryData.flaggedPrecertifications?.filter(
                      (precert) =>
                        precert?.precertNumber !== precertData.precertNumber,
                    ),
            };

            // Write our new data back to the cache.
            proxy.writeQuery({
              query: DashboardPrecertificationsDocumentGQL,
              data: newQueryData,
            });
          },
        },
      )
      .subscribe();
  }

  updateFlaggedPatients(
    flagType: FlagTypeGQL,
    patientData: FlaggedPatientGQL[],
  ): void {
    // currently we send more info along to flag operation than we need and rest api just ignores it
    // for graphql we need to be make sure input shape is exact when doing mutation
    const patientDataFormatted: FlagPatientInputGQL[] = patientData.map(
      (patient) => ({
        ami: patient.ami,
        asOfDate: patient.asOfDate,
        coverage: {
          account: patient.coverage?.account,
          clientName: patient.coverage?.clientName,
          compositeKey: patient.coverage?.compositeKey,
          coverageFrom: patient.coverage?.coverageFrom,
          coverageStatus: patient.coverage?.coverageStatus,
          coverageTo: patient.coverage?.coverageTo,
          coverageType: patient.coverage?.coverageType,
          isEffectivelyTermed: patient.coverage?.isEffectivelyTermed,
          isInactive: patient.coverage?.isInactive,
          showLink: patient.coverage?.showLink,
        },
        dateFlagged: patient.dateFlagged || format(new Date(), 'MM/dd/yyyy'),
        dob: patient.dob,
        firstName: patient.firstName,
        lastName: patient.lastName,
        patientId: patient.patientId,
      }),
    );

    this.flaggedPatientGQL
      .mutate(
        {
          patientFlags: patientDataFormatted,
          type: flagType,
        },
        {
          optimisticResponse: {
            flagPatientsUpdate: patientDataFormatted,
          },
          update: (proxy, { data }) => {
            // Read exisiting data from our cache for this query.
            const queryData = proxy.readQuery({
              query: DashboardPatientsDocumentGQL,
            }) as DashboardPatientsQueryGQL;

            // array of flag identifiers used if we need to remove from cache
            const updatedFlagIds = patientDataFormatted.map(
              (patient) => patient.coverage?.compositeKey || '',
            );

            // construct new query data based on response/optimistic update
            const newQueryData = {
              ...queryData,
              flaggedPatients:
                flagType === 'A' && data?.flagPatientsUpdate
                  ? queryData.flaggedPatients?.concat(data.flagPatientsUpdate)
                  : queryData.flaggedPatients?.filter(
                      (patient) =>
                        patient?.coverage?.compositeKey &&
                        !updatedFlagIds.includes(patient.coverage.compositeKey),
                    ),
            };

            // Write our new data back to the cache.
            proxy.writeQuery({
              query: DashboardPatientsDocumentGQL,
              data: newQueryData,
            });
          },
        },
      )
      .subscribe();
  }

  updateFlaggedClaims(
    flagType: FlagTypeGQL,
    claimData: FlagClaimInputGQL[],
  ): void {
    // currently we send more info along to flag operation than we need and rest api just ignores it
    // for graphql we need to be make sure input shape is exact when doing mutation
    const claimDataFormatted: FlagClaimInputGQL[] = claimData.map((claim) => ({
      id: claim.id || null,
      tinNumbers: claim.tinNumbers,
      dateFlagged: claim.dateFlagged || format(new Date(), 'MM/dd/yyyy'),
      member: {
        memberFirstName: claim.member.memberFirstName,
        memberLastName: claim.member.memberLastName,
        memberId: claim.member.memberId,
      },
      claimSummary: {
        chargeAmount: claim.claimSummary.chargeAmount,
        compositeClaimId: claim.claimSummary.compositeClaimId || null,
        medicare: claim.claimSummary.medicare || false,
        claimCompositeKey: claim.claimSummary.claimCompositeKey,
        claimReferenceNumber: claim.claimSummary.claimReferenceNumber,
        claimStatus: claim.claimSummary.claimStatus,
        dateOfService: claim.claimSummary.dateOfService,
        providerGeneratedAccountNumber:
          claim.claimSummary.providerGeneratedAccountNumber === '--'
            ? ''
            : claim.claimSummary.providerGeneratedAccountNumber || '',
      },
    }));

    this.flaggedClaimGQL
      .mutate(
        {
          claimsFlags: claimDataFormatted,
          type: flagType,
        },
        {
          optimisticResponse: {
            flagClaimsUpdate: claimDataFormatted,
          },
          update: (proxy, { data }) => {
            // Read exisiting data from our cache for this query.
            const queryData = proxy.readQuery({
              query: DashboardClaimsDocumentGQL,
            }) as DashboardClaimsQueryGQL;

            // array of flag identifiers used if we need to remove from cache
            const updatedFlagIds = claimDataFormatted.map(
              (claim) => claim.claimSummary?.claimReferenceNumber || '',
            );

            // construct new query data based on response/optimistic update
            const newQueryData = {
              ...queryData,
              flaggedClaims:
                flagType === 'A' && data?.flagClaimsUpdate
                  ? queryData.flaggedClaims?.concat(data.flagClaimsUpdate)
                  : queryData.flaggedClaims?.filter(
                      (claim) =>
                        claim?.claimSummary?.claimReferenceNumber &&
                        !updatedFlagIds.includes(
                          claim.claimSummary.claimReferenceNumber,
                        ),
                    ),
            };

            // Write our new data back to the cache.
            proxy.writeQuery({
              query: DashboardClaimsDocumentGQL,
              data: newQueryData,
            });
          },
        },
      )
      .subscribe();
  }
}
