import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdnPrefixPipe } from './cdn-prefix.pipe';
import { ResourceLinkPipe } from './resource-link.pipe';
import { CrossOriginLinkPipe } from './cross-origin-link.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CdnPrefixPipe, ResourceLinkPipe, CrossOriginLinkPipe],
  exports: [CdnPrefixPipe, ResourceLinkPipe, CrossOriginLinkPipe],
})
export class ChcpSharedEnvironmentUtilModule {}
