<div id="formErrors">
  <p
    [attr.data-test]="
      errors?.hasOwnProperty(item.formKey) ? 'icn-error-' + i : 'icn-valid-' + i
    "
    *ngFor="let item of passowrdHints; let i = index"
  >
    <cigna-icon
      class="cg-icon-md"
      [type]="
        errors?.hasOwnProperty(item.formKey)
          ? 'exclamation-circle'
          : 'check-circle'
      "
      [ngClass]="
        errors?.hasOwnProperty(item.formKey)
          ? 'cg-color-severe-warn'
          : 'cg-color-success'
      "
    ></cigna-icon
    ><span [attr.data-test]="'lbl-password-hint-' + i">
      {{ item.name }}
    </span>
  </p>
</div>
