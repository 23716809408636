import { Injectable, Provider } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import { mergeMap } from 'rxjs/operators';

// anything listed in this array will NOT be mocked, aka hit real apis
const salesDemoBlacklist = [
  'f-gsg.digitaledge.cigna.com',
  'f-digital-static.digitaledge.cigna.com',
  'f.static.cigna.com',
  '/public/',
  '/navigation/v1/app',
  '/navigation/v1/header',
  '/flag/v1/toggle',
  '/apollo-graphql',
  '/patients/med/appendix/v1/proc-codes/favorites',
  '/provider/v1/fee-schedule/dropdown',
  '/preferences/v2/opioidPledge',
  '/profile/v2/details',
  '/search/v1/codes',
];

@Injectable({
  providedIn: 'root',
})
export class SalesDemoInterceptor implements HttpInterceptor {
  constructor(private authFacade: AuthFacade) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return this.authFacade.isSalesDemoUser$.pipe(
      mergeMap((isSalesDemo) => {
        if (
          !isSalesDemo ||
          salesDemoBlacklist.some((u) => request.urlWithParams.includes(u))
        ) {
          return next.handle(request);
        }

        if (request.urlWithParams.includes('/prv/secure')) {
          return next.handle(
            request.clone({
              url: request.url.replace('/prv/secure', '/prv/secure/demo'),
            }),
          );
        }

        return next.handle(
          request.clone({
            url: request.url.replace('.com/', '.com/demo/'),
          }),
        );
      }),
    );
  }
}

export function salesDemoProviders(enabled: boolean): Provider[] {
  return enabled
    ? [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SalesDemoInterceptor,
          multi: true,
        },
      ]
    : [];
}
