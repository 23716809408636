import { Component, Input } from '@angular/core';
import { ShellTrackHandlers } from '@cigna/chcp/shared/analytics-util';

export interface Alertv2 {
  id: string;
  body: string;
}

@Component({
  selector: 'cigna-alerts-v2',
  templateUrl: './alerts-v2.component.html',
  styleUrl: './alerts-v2.component.scss',
})
export class AlertsV2Component {
  @Input() alerts: Alertv2[];

  constructor(private analytics: ShellTrackHandlers) {}

  alertClick(event: MouseEvent) {
    const linkTarget = event.target as HTMLLinkElement;
    if (linkTarget instanceof HTMLAnchorElement) {
      const text = linkTarget.textContent || '';
      const href = linkTarget.href;

      if (href.includes('.pdf')) {
        this.analytics.trackEventDownload(
          {
            mouseEvent: {
              currentTarget: linkTarget,
            } as any,
            injector: {} as any,
          },
          text.trim(),
          'ac-DB008-Alert Click',
        );
      } else {
        this.analytics.trackEventOther({}, text.trim(), 'ac-DB008-Alert Click');
      }
    }
  }
}
