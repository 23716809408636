import { InjectionToken } from '@angular/core';

export type DataStore = object | undefined;

export const DATA_STORE = new InjectionToken<DataStore>('AnalyticsDataStore');

// eslint-disable-next-line no-var
export declare var digitalData: object;

export function dataStoreFactory(): DataStore {
  if (typeof digitalData !== 'undefined') {
    return digitalData;
  }

  // eslint-disable-next-line no-console
  console.warn(
    'DTM global digitalData object not found, DTM will not be able to see event tracking data',
  );
}
