import { Action, createReducer, on } from '@ngrx/store';
import { DcmAssetsResponse, DcmAssetsErrorResponse } from './dcm.interface';
import * as Actions from './dcm.actions';
export const SECURE_DCM_ASSETS_FEATURE_KEY = 'dcm';
const responseDataMap = new Map<string, DcmAssetsResponse | undefined>();

export interface DcmAssetsState {
  data: Map<string, DcmAssetsResponse | undefined>;
  loaded: boolean;
  isDcmAssetsUnavailable: boolean;
  error?: DcmAssetsErrorResponse;
}

export interface DcmAssetsPartialState {
  readonly [SECURE_DCM_ASSETS_FEATURE_KEY]: DcmAssetsState;
}

export const dcmAssetsInitialState: DcmAssetsState = {
  data: new Map<string, DcmAssetsResponse | undefined>(),
  loaded: false,
  isDcmAssetsUnavailable: false,
};

const reducer = createReducer(
  dcmAssetsInitialState,
  on(Actions.getDcmAssets, (state, { fileName }) => ({
    ...state,
    error: undefined,
    loaded: false,
  })),
  on(Actions.getDcmAssetsSuccess, (state, { response }) => {
    if (response && response.lan_id) {
      responseDataMap.set(response.lan_id, response);
    }
    return {
      ...state,
      data: responseDataMap,
      loaded: true,
    };
  }),
  on(Actions.getDcmAssetsUnavailable, (state) => ({
    ...state,
    isAssetsUnavailable: true,
    loaded: true,
  })),
  on(Actions.getDcmAssetsFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: true,
  })),
);

export function dcmAssetsReducer(
  state: DcmAssetsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
