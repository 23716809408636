<div
  class="content-padding status-badge"
  [class.badge-color-success]="type === 'success'"
  [class.badge-color-warning]="type === 'warning'"
  [class.badge-color-error]="type === 'error'"
  [class.badge-color-info]="type === 'info'"
  [class.cg-bold]="style === 'bold'"
  [class.cg-font-size-m]="size === 'medium'"
  [class.cg-font-size-ml]="size === 'large'"
  [attr.data-test]="'status-badge-' + type"
>
  <div *ngIf="hasIcon" style="display: inline-flex">
    <mat-icon
      *ngIf="type === 'success'"
      [class.cg-icon-sm]="size === 'small'"
      [class.cg-icon-md]="size === 'medium'"
      [class.cg-icon-lg]="size === 'large'"
      class="cg-margin-right-sm"
      svgIcon="leaf-system-regular-success"
    ></mat-icon>
    <mat-icon
      *ngIf="type === 'error'"
      [class.cg-icon-sm]="size === 'small'"
      [class.cg-icon-md]="size === 'medium'"
      [class.cg-icon-lg]="size === 'large'"
      class="cg-margin-right-sm"
      svgIcon="leaf-system-regular-error"
    ></mat-icon>
    <mat-icon
      *ngIf="type === 'warning'"
      [class.cg-icon-sm]="size === 'small'"
      [class.cg-icon-md]="size === 'medium'"
      [class.cg-icon-lg]="size === 'large'"
      class="cg-margin-right-sm"
      svgIcon="leaf-system-regular-warning"
    ></mat-icon>
    <mat-icon
      *ngIf="type === 'info'"
      [class.cg-icon-sm]="size === 'small'"
      [class.cg-icon-md]="size === 'medium'"
      [class.cg-icon-lg]="size === 'large'"
      class="cg-margin-right-sm"
      svgIcon="leaf-system-regular-info"
    ></mat-icon>
  </div>
  <div>{{ text }}</div>
</div>
