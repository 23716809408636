<div
  [attr.aria-label]="iconArialabel"
  role="note"
  [ngClass]="[
    type,
    'alert-' + size,
    'alert-container',
    'whiteBackground-' + hasWhiteBackground
  ]"
>
  <div class="img-container">
    <cigna-icon
      *ngIf="type === 'info'"
      type="info-circle"
      class="icon"
    ></cigna-icon>
    <cigna-icon
      *ngIf="type === 'warning'"
      type="exclamation-triangle"
      class="icon"
    ></cigna-icon>
    <cigna-icon
      *ngIf="type === 'error'"
      type="exclamation-circle"
      class="icon"
    ></cigna-icon>
    <cigna-icon
      *ngIf="type === 'success'"
      type="green-check"
      class="icon"
    ></cigna-icon>
  </div>
  <div class="alert-body">
    <button
      cignaButton="link inline no-underline"
      class="{{ closeContainerClass }}"
      [ngClass]="{
        'success-close': type === 'success',
        'close-btn': type !== 'success'
      }"
      *ngIf="close !== null"
      (click)="closeAlert()"
    >
      {{ close }}
      <cigna-icon class="close-icon" type="close-thin"></cigna-icon>
    </button>
    <div class="alert-title" data-test-id="alert-message-title">
      <ng-content select=".title"></ng-content>
    </div>
    <div class="alert-message" data-test-id="alert-message">
      <ng-content select=".message"></ng-content>
    </div>
    <div class="alert-actions" data-test-id="alert-message-action">
      <ng-content select=".actions"></ng-content>
    </div>
  </div>
</div>
