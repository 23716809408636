import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Action } from '@ngrx/store';

import {
  UserActionTypes,
  GetUserProfileSuccess,
  GetUserProfileError,
  GetLoginUserProfileSuccess,
  GetLoginUserProfileError,
  UpdateUserProfile,
  UpdateUserProfileError,
  LoadTinsSuccess,
  LoadTinsError,
  GetSecurityQuestionsSuccess,
  GetSecurityQuestionsError,
  GetUserFlaggedPatientsSuccess,
  GetUserFlaggedPatientsError,
  UpdateUserFlaggedPatients,
  UpdateUserFlaggedPatientsSuccess,
  UpdateUserFlaggedPatientsError,
  GetUserFlaggedClaimsSuccess,
  GetUserFlaggedClaimsError,
  UpdateUserFlaggedClaims,
  UpdateUserFlaggedClaimsSuccess,
  UpdateUserFlaggedClaimsError,
  GetUserRecentPatientsSuccess,
  GetUserRecentPatientsError,
  GetUserRecentClaimsSuccess,
  GetUserRecentClaimsError,
  UpdateUserProfileSuccess,
  LoadTinsCached,
  UpdateSkipCount,
  UpdateSkipCountSuccess,
  UpdateSkipCountError,
  LoadTinFunctionsSuccess,
  LoadTinFunctionsError,
  UpdateUserProfileIndicator,
} from './user.actions';

import { of, EMPTY, Observable } from 'rxjs';
import {
  map,
  switchMap,
  catchError,
  concatMap,
  withLatestFrom,
  tap,
} from 'rxjs/operators';

import {
  LoginProfileResponse,
  UserProfile,
} from '../user-profile/user-profile.model';
import { UserProfileService } from '../user-profile/user-profile.service';
import { UserTinsService } from '../user-tins/tins.service';
import { UserSecurityQuestionsService } from '../user-security-questions/security-questions.service';
import { UserFlagService } from '../user-flags/flags.service';
import { RecentSearchService } from '../user-recently-searched/recent-search.service';
import { UserFacade } from './user.facade';
import { ShellTrackHandlers } from '@cigna/chcp/shared/analytics-util';
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import { AppContext } from '@cigna/chcp/shared/util';
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userFacade: UserFacade,
    private userProfileService: UserProfileService,
    private tinService: UserTinsService,
    private securityQuestionService: UserSecurityQuestionsService,
    private flagService: UserFlagService,
    private recentSearchService: RecentSearchService,
    private analytics: ShellTrackHandlers,
    private authFacade: AuthFacade,
    private router: Router,
    private featureTogglesFacade: FeatureTogglesFacade,
    @Inject(APP_CONTEXT) public appContext: AppContext,
  ) {}

  getUserProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.GetUserProfile),
      switchMap(() =>
        this.userProfileService.getUserProfile().pipe(
          map((data: any) => new GetUserProfileSuccess(data)),
          catchError((err) => of(new GetUserProfileError(err))),
        ),
      ),
    ),
  );

  getUserProfileServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.GetUserProfileError),
        map((action: GetUserProfileError) => action.payload),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/profile/v2/details:GET',
            error,
            'Error loading user profile.',
          );
          this.router.navigateByUrl('/unavailable', {
            skipLocationChange: true,
          });
        }),
      ),
    { dispatch: false },
  );

  getLoginUserProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.GetLoginUserProfile),
      withLatestFrom(this.authFacade.isDentalUser$),
      switchMap(([, isDentalUser]) =>
        this.userProfileService
          .getLoginUserProfile(isDentalUser as boolean)
          .pipe(
            map(
              (data: LoginProfileResponse) =>
                new GetLoginUserProfileSuccess(data),
            ),
            catchError((err) => of(new GetLoginUserProfileError(err))),
          ),
      ),
    ),
  );

  getLoginUserProfileServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.GetLoginUserProfileError),
        map((action: GetLoginUserProfileError) => action.payload),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            `/identity/${
              this.appContext === 'evernorth' ? 'epp' : 'med'
            }/v1/login-user-profile:GET`,
            error,
            'Error loading login user profile.',
          );
          this.router.navigateByUrl('/unavailable', {
            skipLocationChange: true,
          });
        }),
      ),
    { dispatch: false },
  );

  updateUserProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UpdateUserProfile),
      map((action: UpdateUserProfile) => action.payload),
      concatMap((userData) =>
        this.userProfileService.updateUserProfile(userData).pipe(
          map((data: UserProfile) => new UpdateUserProfileSuccess(data)),
          catchError((err) => of(new UpdateUserProfileError(err))),
        ),
      ),
    ),
  );

  updateUserAgreementIndicator$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UpdateUserProfileIndicator),
      map((action: UpdateUserProfileIndicator) => action.payload),
      concatMap((userData) =>
        this.userProfileService.updateUserProfileIndicators(userData).pipe(
          map(
            (data: Partial<UserProfile>) => new UpdateUserProfileSuccess(data),
          ),
          catchError((err) => of(new UpdateUserProfileError(err))),
        ),
      ),
    ),
  );

  updateUserProfileServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.UpdateUserProfileError),
        map((action: UpdateUserProfileError) => action.payload),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/preferences/v2/details:PUT',
            error,
            'Error updating user profile.',
          );
        }),
      ),
    { dispatch: false },
  );

  loadTins$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LoadTins),
      withLatestFrom(this.userFacade.unFilteredTins$),
      switchMap(([, tins]) => {
        if (tins.length > 0) {
          return of(new LoadTinsCached());
        }
        return this.tinService.getTins().pipe(
          withLatestFrom(this.authFacade.lob$),
          map(
            ([result, lob]) =>
              new LoadTinsSuccess({ tins: result, lob: lob as string }),
          ),
          catchError((err) => of(new LoadTinsError(err))),
        );
      }),
    ),
  );

  loadTinFunctions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LoadTinFunctions),
      switchMap(
        ({
          payload,
        }: {
          payload: { entitlements: string[]; functions: string[] };
        }) =>
          this.tinService
            .getTinFunctions(payload.entitlements, payload.functions)
            .pipe(
              map((result) => new LoadTinFunctionsSuccess({ tins: result })),
              catchError((err) => of(new LoadTinFunctionsError(err))),
            ),
      ),
    ),
  );

  loadTinsServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.LoadTinsError),
        map((action: LoadTinsError) => action.payload),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/profile/v2/tinDetails:GET',
            error,
            'Error loading user tins.',
          );
        }),
      ),
    { dispatch: false },
  );

  getSecurityQuestions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.GetSecurityQuestions),
      switchMap(() =>
        this.securityQuestionService.getSecurityQuestions().pipe(
          map((result) => new GetSecurityQuestionsSuccess(result)),
          catchError((err) => of(new GetSecurityQuestionsError(err))),
        ),
      ),
    ),
  );

  getFlaggedPatients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.GetUserFlaggedPatients),
      withLatestFrom(
        this.userFacade.flaggedPatients$,
        this.authFacade.isDentalUser$,
        this.featureTogglesFacade.featuresEnabled([
          'ftr-chcp-pulsar-team-web-patient-lambda-recent-dashboard',
        ]),
      ),
      switchMap(([, patients, isDentalUser, hasDashboardLambda]) => {
        if (patients.length > 0) {
          return EMPTY;
        }
        return this.flagService
          .getFlaggedPatients(isDentalUser as boolean, hasDashboardLambda)
          .pipe(
            map((result) => new GetUserFlaggedPatientsSuccess(result)),
            catchError((err) => of(new GetUserFlaggedPatientsError(err))),
          );
      }),
    ),
  );

  getFlaggedPatientsServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.GetUserFlaggedPatientsError),
        map((action: GetUserFlaggedPatientsError) => action.payload),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/flag/v1/benefits:GET',
            error,
            'Error loading flagged patients.',
          );
        }),
      ),
    { dispatch: false },
  );

  getFlaggedClaims$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.GetUserFlaggedClaims),
      withLatestFrom(this.userFacade.flaggedClaims$),
      switchMap(([, claims]) => {
        if (claims.length > 0) {
          return EMPTY;
        }
        return this.flagService.getFlaggedClaims().pipe(
          map((result) => new GetUserFlaggedClaimsSuccess(result)),
          catchError((err) => of(new GetUserFlaggedClaimsError(err))),
        );
      }),
    ),
  );

  getFlaggedClaimsServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.GetUserFlaggedClaimsError),
        map((action: GetUserFlaggedClaimsError) => action.payload),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/flag/v1/claims:GET',
            error,
            'Error loading flagged claims.',
          );
        }),
      ),
    { dispatch: false },
  );

  updateFlaggedPatients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UpdateUserFlaggedPatients),
      map((action: UpdateUserFlaggedPatients) => action.payload),
      withLatestFrom(
        this.authFacade.isDentalUser$,
        this.featureTogglesFacade.featuresEnabled([
          'ftr-chcp-pulsar-team-web-patient-lambda-recent-dashboard',
        ]),
      ),
      switchMap(([payload, isDentalUser, hasDashboardLambda]) =>
        this.flagService
          .updateFlaggedPatients(
            payload,
            isDentalUser as boolean,
            hasDashboardLambda,
          )
          .pipe(
            map(() => new UpdateUserFlaggedPatientsSuccess()),
            catchError((error) =>
              of(new UpdateUserFlaggedPatientsError({ ...payload, error })),
            ),
          ),
      ),
    ),
  );

  updateFlaggedPatientsServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.UpdateUserFlaggedPatientsError),
        map((action: UpdateUserFlaggedPatientsError) => action.payload.error),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/flag/v1/benefit/{type}:PUT',
            error,
            'Error saving flagged patient.',
          );
        }),
      ),
    { dispatch: false },
  );

  updateFlaggedClaims$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UpdateUserFlaggedClaims),
      map((action: UpdateUserFlaggedClaims) => action.payload),
      concatMap((payload) =>
        this.flagService.updateFlaggedClaims(payload).pipe(
          map(() => new UpdateUserFlaggedClaimsSuccess()),
          catchError((error) =>
            of(new UpdateUserFlaggedClaimsError({ ...payload, error })),
          ),
        ),
      ),
    ),
  );

  updateFlaggedClaimsServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.UpdateUserFlaggedClaimsError),
        map((action: UpdateUserFlaggedClaimsError) => action.payload.error),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/flag/v1/claim/{type}:PUT',
            error,
            'Error saving flagged claim.',
          );
        }),
      ),
    { dispatch: false },
  );

  getRecentPatients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.GetUserRecentPatients),
      switchMap(() =>
        this.recentSearchService.getRecentPatients().pipe(
          map((result) => new GetUserRecentPatientsSuccess(result)),
          catchError((err) => of(new GetUserRecentPatientsError(err))),
        ),
      ),
    ),
  );

  getRecentPatientsServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.GetUserRecentPatientsError),
        map((action: GetUserRecentPatientsError) => action.payload),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/dashboard/v1/recentBenefits:GET',
            error,
            'Error loading recent patient searches.',
          );
        }),
      ),
    { dispatch: false },
  );

  getRecentClaims$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.GetUserRecentClaims),
      switchMap(() =>
        this.recentSearchService.getRecentClaims().pipe(
          map((result) => new GetUserRecentClaimsSuccess(result)),
          catchError((err) => of(new GetUserRecentClaimsError(err))),
        ),
      ),
    ),
  );

  getRecentClaimsServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.GetUserRecentClaimsError),
        map((action: GetUserRecentClaimsError) => action.payload),
        tap((error) => {
          this.analytics.trackServiceError(
            {},
            'chcp/dashboard/v1/recentClaims:GET',
            error,
            'Error loading recent claim searches.',
          );
        }),
      ),
    { dispatch: false },
  );

  updateSkipCount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UpdateSkipCount),
      map((action: UpdateSkipCount) => action.payload),
      concatMap((userData) =>
        this.userProfileService
          .updateUserProfile(
            userData.type === 'popup'
              ? { contactCntr: userData.count }
              : { emailVerifySkipCount: userData.count },
          )
          .pipe(
            map((data: UserProfile) => new UpdateSkipCountSuccess(data)),
            catchError((_err) => of(new UpdateSkipCountError())),
          ),
      ),
    ),
  );
}
