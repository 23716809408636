import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ChcpRoute } from '@cigna/chcp/shared/util';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { Observable, of } from 'rxjs';

@Injectable()
export class FeatureToggleGuard {
  constructor(private featureTogglesFacade: FeatureTogglesFacade) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const features = (route as ChcpRoute).data?.guards?.features;

    if (!features) {
      return of(true);
    }
    return this.featureTogglesFacade.featuresEnabled(features, 'OR');
  }
}
