import { Component, Input } from '@angular/core';

export enum CurrencySize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

@Component({
  selector: 'cigna-chcp-round-graph',
  templateUrl: './round-graph.component.html',
  styleUrls: ['./round-graph.component.scss'],
  preserveWhitespaces: false,
})
export class RoundGraphComponent {
  @Input() size: CurrencySize = CurrencySize.small;
  @Input() amount: number;
  @Input() met: number;
  @Input() remaining: number;
  @Input() benefit: string;
  @Input() title: string;
  @Input() fontType = 'heading';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() showWarning = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  showNegativeIndicator = false;

  CurrencySize = CurrencySize;

  get dollars() {
    return Math.floor(this.remaining);
  }

  get cents() {
    if (!this.isValidNumber(this.remaining)) {
      return 0;
    }
    return Math.round((this.remaining - this.dollars) * 100);
  }

  isValidNumber(val: number | '--'): val is number {
    return val !== '--';
  }
}
