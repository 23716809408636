<cigna-spinner
  *ngIf="isLoadingHistory$ | async; else history"
  hint="Loading history..."
  size="small"
>
</cigna-spinner>
<ng-template #history
  ><cigna-omni-conversation-summary-list
    [canEnableNewDMUI]="'enableNewDMUI' | cignaFeatureEnabled"
  ></cigna-omni-conversation-summary-list
></ng-template>
