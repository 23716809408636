import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ConversationMessageDialogDataList,
  ListDisplayType,
} from '../interfaces';

@Component({
  selector: 'cigna-omni-messaging-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingDataListComponent {
  @Input() public props: ConversationMessageDialogDataList;
  @Input() public displayType: ListDisplayType = 'unordered';
}
