<div
  *ngFor="let fileWithUrl of files; let i = index; trackBy: trackByFn"
  class="files"
  data-test="files"
>
  <div
    *ngIf="fileWithUrl"
    class="file"
    [ngClass]="
      fileWithUrl.error
        ? 'error'
        : fileWithUrl.isLoading
        ? 'loading'
        : 'success'
    "
    [attr.data-test]="'file-' + i"
  >
    <button
      (click)="onRemoveFile(fileWithUrl)"
      data-test="remove-file"
      cignaButton="link no-underline small"
      [disabled]="isRemoveDisabled"
    >
      <cigna-icon type="close-thin" class="remove-icon"></cigna-icon>
    </button>

    <div class="file-info">
      <button
        cignaButton="link small"
        class="name"
        [title]="fileWithUrl.file.name"
        data-test="file-name"
        (click)="onFileNameClick(fileWithUrl)"
        [disabled]="fileWithUrl.error || fileWithUrl.isLoading"
      >
        {{ fileWithUrl.file.name }}
      </button>
      <span class="size" data-test="file-size">
        (<ng-container *ngIf="fileWithUrl.isLoading"
          >{{ fileWithUrl.loaded || 0 | formatBytes }}/ </ng-container
        >{{ fileWithUrl.file.size | formatBytes }})
      </span>
    </div>

    <span *ngIf="fileWithUrl.error; else loading" class="status-message">
      <cigna-icon
        type="exclamation-triangle"
        class="error-icon"
        data-test="lbl-file-upload-error"
      ></cigna-icon>
      {{ FileUploadErrors[fileWithUrl.error] }}
    </span>

    <ng-template #loading>
      <ng-container *ngIf="fileWithUrl.isLoading; else success">
        <span class="status-message cg-spinner cg-spinner-small spinner"></span>
        <span class="status-message" data-test="lbl-file-uploading"
          >File uploading...</span
        >
      </ng-container>
    </ng-template>

    <ng-template #success
      ><span class="status-message" data-test="lbl-upload-success"
        >Upload successful</span
      ></ng-template
    >
  </div>
</div>

<ng-template #confirmationTemplate let-data>
  <div
    class="cg-dialog"
    aria-labelledby="header"
    data-test="delete-confirmation-modal"
  >
    <h3
      id="header"
      class="cg-dialog__header cg-dialog__header--modal dialog-header"
    >
      Are you sure you want to delete file "{{ data.fileName }}"?
    </h3>
    <p>
      {{
        customConfirmationText ||
          'If you delete this file, you will have to re-attach it if it’s needed.'
      }}
    </p>

    <div class="cg-button-group cg-margin-top-lg">
      <button
        cignaButton="primary"
        (click)="onConfirmRemoveFile(data.fileName); dialogRef.close()"
        data-test="delete-file"
      >
        Delete
      </button>
      <button
        cignaButton="link no-underline"
        (click)="dialogRef.close()"
        data-test="cancel"
      >
        Cancel
      </button>
    </div>
  </div>
</ng-template>
