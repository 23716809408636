<div
  aria-labelledby="conv-message-list-outro-farewell-message"
  class="conv-message-list-outro"
  data-omni-qa="conv-farewell"
  *ngIf="!shouldEnablePreChatWelcome"
>
  <div *ngIf="isEvernorthEnabled$ | async">
    <img
      alt="EVERNORTH"
      class="evernorth-logo"
      [src]="'/assets/images/evn_logo_hs_r_rgb_tempermint.svg' | cdnPrefix"
    />
  </div>
  <div *ngIf="(isEvernorthEnabled$ | async) === false">
    <img
      alt="Cigna"
      class="cigna-footer-tree"
      [src]="'/assets/images/cignahlthcr_logo_color.svg' | cdnPrefix"
    />
  </div>
  <h2
    class="message"
    [innerHTML]="outro?.message"
    data-omni-qa="omni-dialog-farewell-message"
  ></h2>
  <h2 class="footer" data-omni-qa="omni-dialog-farewell-footer">
    {{ outro?.footer }}
  </h2>
</div>

<div
  aria-labelledby="conv-message-list-outro-farewell-message"
  class="conv-message-list-outro"
  data-omni-qa="conv-farewell"
  *ngIf="shouldEnablePreChatWelcome"
>
  <ng-container *ngIf="(isDCMChat$ | async) === false && isHistory">
    <div *ngIf="isEvernorthEnabled$ | async">
      <img
        alt="EVERNORTH"
        class="evernorth-logo"
        [src]="'assets/images/evn_logo_hs_r_rgb_tempermint.svg' | cdnPrefix"
      />
    </div>
    <div *ngIf="(isEvernorthEnabled$ | async) === false">
      <img
        alt="Cigna"
        class="cigna-footer-tree"
        [src]="'assets/images/cignahlthcr_logo_color.svg' | cdnPrefix"
      />
    </div>
    <h2
      class="message"
      [innerHTML]="outro?.message"
      data-omni-qa="omni-dialog-farewell-message"
    ></h2>
    <h2 class="footer" data-omni-qa="omni-dialog-farewell-footer">
      {{ outro?.footer }}
    </h2>
  </ng-container>

  <ng-container *ngIf="!isHistory">
    <div class="footer-message-cover">
      <div class="footerLineFirst">
        <h2>{{ outro.footer }}</h2>
      </div>
      <div class="footerLineSecond">
        <h2>{{ agentLeftMessageCopy }}</h2>
      </div>
      <div class="footer-buttons-group">
        <div
          class="footer-download"
          *ngIf="{
            canUpdateAnalyticsData: canUpdateAnalyticsData$ | async
          } as data"
        >
          <button
            cignaButton="blue-link"
            (click)="downloadTranscript(data.canUpdateAnalyticsData)"
          >
            Download Transcript
          </button>
        </div>
        <div class="footer-backtostart">
          <button cignaButton="blue-link" (click)="backTostart()">
            {{
              canReplaceBackToPreviousWithEndChat
                ? 'Close Chat'
                : 'Back to Start'
            }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
