import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalComponent } from '@cigna/shared/angular/modal-ui';
import { TimeoutPopupService } from './timeout-popup.service';

@UntilDestroy()
@Component({
  selector: 'cigna-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeoutPopupComponent implements OnInit {
  constructor(
    private changeDetector: ChangeDetectorRef,
    public service: TimeoutPopupService,
  ) {}

  @ViewChild(ModalComponent) modal: ModalComponent;

  ngOnInit() {
    this.service.events$.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event === 'inactive') {
        this.modal.open();
      }
      this.changeDetector.markForCheck();
    });
  }

  stayLoggedIn() {
    this.service.reset();
    this.modal.close();
  }
}
