import { Component, Input } from '@angular/core';
import { IconDynamicColors } from '../../interfaces/colors';
import { IconDynamicData } from '../../interfaces/data';

@Component({
  selector: 'cigna-icon-dynamic-close',
  templateUrl: './close-icon.component.html',
})
export class IconDynamicCloseComponent {
  @Input() public colors: IconDynamicColors;
  @Input() public data: IconDynamicData;
}
