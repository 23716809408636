<a *ngIf="shouldUseRouterLink && path" [routerLink]="path">
  <ng-container *ngTemplateOutlet="img"></ng-container>
</a>
<a *ngIf="!shouldUseRouterLink && path" [href]="path">
  <ng-container *ngTemplateOutlet="img"></ng-container>
</a>
<ng-container *ngIf="!path">
  <ng-container *ngTemplateOutlet="img"></ng-container>
</ng-container>

<ng-template #img>
  <img
    title="Cigna Healthcare"
    alt="Cigna Healthcare Logo"
    width="144"
    height="78"
    [src]="'assets/images/cignahlthcr_logo_color.svg' | cdnPrefix"
    data-test="header-cigna-logo"
  />
</ng-template>
