import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@cigna/chcp/auth/data-access';
import { ShellTrackHandlers } from '@cigna/chcp/shared/analytics-util';
import {
  GetUserProfileSuccess,
  HideEmailPopup,
  UpdateSkipCountError,
  UpdateSkipCountSuccess,
  UserActionTypes,
} from '@cigna/chcp/shared/user-profile-data-access';
import { deepestActivatedRoute } from '@cigna/chcp/shared/util';
import { ShellFacade } from '@cigna/chcp/shell/data-access';
import { WindowService } from '@cigna/shared/angular/core/window-util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ISAM_RESPONSE_CODES,
  ISAM_SUCCESS_MSG,
  MFA_ERROR_MESSAGES,
  ROUTE_PUT_MFA_DETAILS_COMPLETE,
} from '../mfa/mfa.constant';
import { MfaService } from '../mfa/mfa.service';
import {
  exitContactVerification,
  getMfaTinDetailsByWam,
  getMfaTinDetailsByWamError,
  getMfaTinDetailsByWamSuccess,
  isamResendCode,
  isamResendCodeError,
  isamResendCodeSuccess,
  isamSendCode,
  isamSendCodeError,
  isamSendCodeSuccess,
  isamStepup,
  isamStepupError,
  isamStepupSuccessMultipleContact,
  isamStepupSuccessPasthrough,
  isamStepupSuccessSingleContact,
  isamVerifyCode,
  isamVerifyCodeInputError,
  isamVerifyCodeRetryLimitError,
  isamVerifyCodeServiceError,
  isamVerifyCodeSuccess,
  loadUserProfile,
  putMfaTinDetails,
  putMfaTinDetailsError,
  putMfaTinDetailsSuccess,
  sendContactVerificationCode,
  sendContactVerificationCodeError,
  sendContactVerificationCodeSuccess,
  skipEmailVerification,
  storeMfaWamUpdate,
  verifyContactCode,
  verifyContactCodeError,
  verifyContactCodeSuccess,
} from './mfa.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { IsamResponse } from '../mfa/mfa.model';
import { MfaFacade } from './mfa.facade';

@Injectable()
export class MfaEffects {
  constructor(
    private actions$: Actions,
    private mfaService: MfaService,
    private mfaFacade: MfaFacade,
    private router: Router,
    private authFacade: AuthFacade,
    private windowService: WindowService,
    private analytics: ShellTrackHandlers,
    private shellFacade: ShellFacade,
    private featureTogglesFacade: FeatureTogglesFacade,
  ) {}

  isamStepup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(isamStepup),
      switchMap(({ isMobileOnlyMfa }) =>
        this.mfaService.isamStepUp().pipe(
          map((data: IsamResponse) => {
            if (
              data.responseCode === ISAM_RESPONSE_CODES.success &&
              data.responseMsg === ISAM_SUCCESS_MSG &&
              data.location
            ) {
              return isamStepupSuccessPasthrough({ location: data.location });
            }
            if (data.otpMethods?.length && !isMobileOnlyMfa) {
              return isamStepupSuccessMultipleContact({
                isMobileOnlyMfa,
                data: {
                  stateId: data.stateId as string,
                  contacts: data.otpMethods
                    .filter(
                      (method) =>
                        this.getContactType(method.label) === 'phone' ||
                        this.getContactType(method.label) === 'email',
                    )
                    .map((method) => ({
                      ...method,
                      type: this.getContactType(method.label),
                    })),
                },
              });
            }
            // Check for displaying Mobile Only option in CHCP. Not applicable for EPP
            if (data.otpMethods?.length && isMobileOnlyMfa) {
              return isamStepupSuccessMultipleContact({
                isMobileOnlyMfa,
                data: {
                  stateId: data.stateId as string,
                  isMobileOnlyMfa,
                  contacts: data.otpMethods
                    .filter(
                      (method) => this.getContactType(method.label) !== 'email',
                    )
                    .map((method) => ({
                      ...method,
                      type: this.getContactType(method.label),
                    })),
                },
              });
            }
            if (data.sentTo && data.otpHint && data.stateId) {
              return isamStepupSuccessSingleContact({
                data: {
                  otpHint: this.cleanOtpHint(data.otpHint),
                  contacts: [
                    {
                      id: '',
                      type: 'email',
                      label: data.sentTo,
                    },
                  ],
                  stateId: data.stateId,
                },
              });
            }
            return isamStepupError({
              errorMessage: MFA_ERROR_MESSAGES.genericError,
            });
          }),
          catchError(() =>
            of(
              isamStepupError({
                errorMessage: MFA_ERROR_MESSAGES.genericError,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  isamSendCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(isamSendCode),
      withLatestFrom(this.mfaFacade.isamStateAndHint$),
      switchMap(([{ selectedContact }, { stateId }]) =>
        this.mfaService
          .isamGenerateCode(stateId, selectedContact.id || '')
          .pipe(
            map((data: IsamResponse) => {
              if (data.stateId && data.otpHint) {
                return isamSendCodeSuccess({
                  stateId: data.stateId,
                  otpHint: this.cleanOtpHint(data.otpHint),
                });
              }
              return isamSendCodeError();
            }),
            catchError(() => of(isamSendCodeError())),
          ),
      ),
    ),
  );

  isamResendCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(isamResendCode),
      withLatestFrom(this.mfaFacade.isamStateAndHint$),
      switchMap(([, { stateId }]) =>
        this.mfaService.isamRegenerateCode(stateId).pipe(
          map((data: IsamResponse) => {
            if (data.stateId && data.otpHint) {
              return isamResendCodeSuccess({
                stateId: data.stateId,
                otpHint: this.cleanOtpHint(data.otpHint),
              });
            }
            return isamResendCodeError();
          }),
          catchError(() => of(isamResendCodeError())),
        ),
      ),
    ),
  );

  isamVerifyCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(isamVerifyCode),
      withLatestFrom(
        this.mfaFacade.isamMobileOnlyMfa$,
        this.mfaFacade.isamStateAndHint$,
      ),
      switchMap(([{ code, remember }, isMobileOnlyMfa, { stateId, otpHint }]) =>
        this.mfaService.isamVerifyCode(stateId, code, otpHint, remember).pipe(
          map((res: { status: number; body: IsamResponse }) => {
            if (res.status === 204) {
              return isamStepup(isMobileOnlyMfa);
            }
            if (
              res.body.responseCode === ISAM_RESPONSE_CODES.success &&
              res.body.location
            ) {
              return isamVerifyCodeSuccess({
                location: res.body.location,
              });
            }
            if (
              res.body.responseCode === ISAM_RESPONSE_CODES.retry_limit &&
              res.body.stateId
            ) {
              return isamVerifyCodeInputError({
                errorMessage: MFA_ERROR_MESSAGES.retryLimitError,
                stateId: res.body.stateId,
              });
            }
            return isamVerifyCodeInputError({
              errorMessage: MFA_ERROR_MESSAGES.verifyCodeError,
              stateId: res.body.stateId || '',
            });
          }),
          catchError(() =>
            of(
              isamVerifyCodeServiceError({
                errorMessage: MFA_ERROR_MESSAGES.genericError,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  isamRetryLimitError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(isamVerifyCodeRetryLimitError),
        tap(() => {
          this.shellFacade.logOut({
            variation: 'logout',
            linkName: 'Logout MFA',
          });
        }),
      ),
    { dispatch: false },
  );

  isamFinalRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(isamStepupSuccessPasthrough, isamVerifyCodeSuccess),
        tap(({ location }) => {
          if (location.includes('/app')) {
            // baseHref is /app so we need to trim off /app from location or it will go to /app/app/dasbhoard
            this.router.navigateByUrl(location.replace('/app', ''));
          } else {
            this.windowService.location.href = location;
          }
        }),
      ),
    { dispatch: false },
  );

  isamRouteToChooseContact$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(isamStepupSuccessMultipleContact),
        tap(() => {
          this.router.navigate(['mfa/verify-code/choose'], {
            replaceUrl: true,
            skipLocationChange: true,
            queryParamsHandling: 'preserve',
          });
        }),
      ),
    { dispatch: false },
  );

  isamRouteToEnterCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(isamStepupSuccessSingleContact, isamSendCodeSuccess),
        tap(() => {
          this.router.navigate(['mfa/verify-code/enter'], {
            replaceUrl: true,
            skipLocationChange: true,
            queryParamsHandling: 'preserve',
          });
        }),
      ),
    { dispatch: false },
  );

  loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserProfile),
      withLatestFrom(this.authFacade.isUserAuthenticated$),
      filter(([, auth]) => !auth),
      switchMap(() =>
        this.mfaService.getUserDetails().pipe(
          map((res) => new GetUserProfileSuccess(res)),
          catchError((error) => of(verifyContactCodeError({ error }))),
        ),
      ),
    ),
  );

  hideEmailPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(skipEmailVerification, verifyContactCodeSuccess),
      switchMap(() => of(new HideEmailPopup())),
    ),
  );

  skipEmailVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(skipEmailVerification),
      switchMap(({ count, skipType }) =>
        this.mfaService
          .updateUserDetails(
            skipType === 'popup'
              ? { contactCntr: count }
              : { emailVerifySkipCount: count },
          )
          .pipe(
            map((data) => new UpdateSkipCountSuccess(data)),
            catchError((_error) => of(new UpdateSkipCountError())),
          ),
      ),
    ),
  );

  exitContactVerification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exitContactVerification, UserActionTypes.UpdateSkipCountSuccess),
        tap(() => {
          this.shellFacade.loginStepUp();
        }),
      ),
    { dispatch: false },
  );

  sendContactCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendContactVerificationCode),
      withLatestFrom(this.authFacade.isUserAuthenticated$),
      switchMap(([{ payload, isResend }, isAuth]) =>
        isAuth
          ? this.mfaService.sendContactCodeSecure(payload).pipe(
              map(() =>
                sendContactVerificationCodeSuccess({ navigate: !isResend }),
              ),
              catchError((error) =>
                of(sendContactVerificationCodeError({ error })),
              ),
            )
          : this.mfaService.sendContactCodePublic(payload).pipe(
              map(() =>
                sendContactVerificationCodeSuccess({ navigate: !isResend }),
              ),
              catchError((error) =>
                of(sendContactVerificationCodeError({ error })),
              ),
            ),
      ),
    ),
  );

  verifyContactCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyContactCode),
      tap(() => {
        this.analytics.trackEventOther({}, 'Submit', 'ac23408', {
          eVar119: 'PNTP-611',
          eVar120: 'submit-auth-code-button',
          prop20: 'CHCP - Mobile Verification Enter Authentication Code',
        });
      }),
      withLatestFrom(this.authFacade.isUserAuthenticated$),
      switchMap(([{ payload }, isAuth]) =>
        isAuth
          ? this.mfaService.verifyContactCodeSecure(payload).pipe(
              map(() => verifyContactCodeSuccess()),
              catchError((error) => of(verifyContactCodeError({ error }))),
            )
          : this.mfaService.verifyContactCodePublic(payload).pipe(
              map(() => verifyContactCodeSuccess()),
              catchError((error) => of(verifyContactCodeError({ error }))),
            ),
      ),
    ),
  );

  sendContactVerificationCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendContactVerificationCodeSuccess),
        filter(({ navigate }) => navigate),
        tap(() => {
          this.router.navigate(['./code'], {
            queryParamsHandling: 'preserve',
            relativeTo: deepestActivatedRoute(this.router),
          });
        }),
      ),
    { dispatch: false },
  );

  verifyContactCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyContactCodeSuccess),
        tap(() => {
          this.router.navigate(['../success'], {
            queryParamsHandling: 'preserve',
            relativeTo: deepestActivatedRoute(this.router),
          });
        }),
      ),
    { dispatch: false },
  );

  trackVerifyContactCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyContactCodeSuccess),
        tap(() => {
          this.analytics.trackEventOther({}, 'Submit', 'ac-mfa003', {
            events: 'event135',
          });
        }),
      ),
    { dispatch: false },
  );

  trackVerifyContactCodeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyContactCodeError),
        tap((serviceError) => {
          this.analytics.trackEventOther({}, 'Submit', 'ac-mfa003', {
            events: 'event136',
          });
          if (serviceError.error.errObject.code === 702003) {
            this.shellFacade.logOut({
              variation: 'logout',
              linkName: 'Logout MFA',
            });
          }
        }),
      ),
    { dispatch: false },
  );

  trackIsamVerifyCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(isamVerifyCode),
        tap(({ remember }) => {
          this.analytics.trackEventOther({}, 'Submit', 'ac-mfa004', {
            eVar111: `type:mfa remember device^remember device:${
              remember ? 'y' : 'n'
            }`,
          });
        }),
      ),
    { dispatch: false },
  );

  trackIsamVerifyCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(isamVerifyCodeSuccess, isamStepupSuccessPasthrough),
        tap(() => {
          this.analytics.trackEventOther({}, 'API response', 'ac-mfa004', {
            events: 'event135',
          });
        }),
      ),
    { dispatch: false },
  );

  trackIsamVerifyCodeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(isamVerifyCodeServiceError, isamVerifyCodeInputError),
        tap(() => {
          this.analytics.trackEventOther({}, 'API response', 'ac-mfa004', {
            events: 'event136',
          });
        }),
      ),
    { dispatch: false },
  );

  getMfaTinDetailsByWam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMfaTinDetailsByWam),
      switchMap(() =>
        this.mfaService.getMfaTinDetailsByWam().pipe(
          map((mfaTinDetails) =>
            getMfaTinDetailsByWamSuccess({ mfaTinDetails }),
          ),
          catchError((error) => of(getMfaTinDetailsByWamError({ error }))),
        ),
      ),
    ),
  );

  putMfaTinDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putMfaTinDetails),
      withLatestFrom(this.mfaFacade.mfaChanges$),
      exhaustMap(([, mfaTinDetails]) => {
        // map store format into object service expects
        const details = mfaTinDetails.map((t) => ({
          tin: t.tin,
          jobRoles: {
            optional: t.changes
              .filter((jobRole) => jobRole.mfaTypeName === 'Optional')
              .map((jobRole) => jobRole.jobRoleId),
            mandatory: t.changes
              .filter((jobRole) => jobRole.mfaTypeName === 'Mandatory')
              .map((jobRole) => jobRole.jobRoleId),
          },
        }));
        return this.mfaService.putMfaTinDetails(details).pipe(
          map(() => putMfaTinDetailsSuccess()),
          catchError((error) => of(putMfaTinDetailsError({ error }))),
        );
      }),
    ),
  );

  // NOTE: Impl TBD
  getMfaTinDetailsByWamSuccess$ = createEffect(
    () => this.actions$.pipe(ofType(getMfaTinDetailsByWamSuccess)),
    { dispatch: false },
  );

  putMfaTinDetailsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(putMfaTinDetailsSuccess),
        tap(() => {
          this.router.navigate([ROUTE_PUT_MFA_DETAILS_COMPLETE], {
            queryParamsHandling: 'preserve',
          });
        }),
      ),
    { dispatch: false },
  );

  storeMfaWamUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(storeMfaWamUpdate),
        tap(() => {
          this.router.navigate(
            ['/my-practice/two-step-auth-settings/confirm'],
            {
              queryParamsHandling: 'preserve',
            },
          );
        }),
      ),
    { dispatch: false },
  );

  cleanOtpHint(hint: string): string {
    // in some odd scenarios otpHint comes back from isamm as 8 digits, but when we send it back for subsequent calls it expects 4, so we trim it down to 4 to address it
    return hint.substr(0, 4);
  }

  getContactType = (label: string): 'email' | 'phone' =>
    label.includes('@') ? 'email' : 'phone';
}
