/* eslint-disable sonarjs/no-duplicate-string */
/*

  🚧 THIS IS AN AUTOMATICALLY GENERATED FILE. DO NOT EDIT! 🚧

  Any changes to this file may be overwritten when it is regenerated.
  To edit this file, edit the script 'tools/scripts/generate-svg-icons.js'.

  */

import { IconConfig } from '../icon-config.interface';

export const CIGNA_SVGS_NAMESPACE = 'cigna-svgs';
export const CIGNA_SVGS_FILENAME =
  'assets/images/cigna-icons-293890445a63a7e0fe8e2fdf9a05f276d95e140b.svg';

export enum CignaSvgsIcon {
  Ambulance = 'ambulance',
  AppTouch = 'app-touch',
  Apple = 'apple',
  Arrow = 'arrow',
  ArtificialIntelligence = 'artificial-intelligence',
  AwardRibbon = 'award-ribbon',
  BabyStroller = 'baby-stroller',
  Bandaid = 'bandaid',
  Bizcard = 'bizcard',
  BookOpen = 'book-open',
  Book = 'book',
  Boy = 'boy',
  Briefcase = 'briefcase',
  Bullhorn = 'bullhorn',
  Calculator = 'calculator',
  Calendar = 'calendar',
  Capitol = 'capitol',
  Carrot = 'carrot',
  Cart = 'cart',
  Certificate = 'certificate',
  ChartMobile = 'chart-mobile',
  ChartUptrend = 'chart-uptrend',
  ChatSquare = 'chat-square',
  ChatWDots = 'chat-w-dots',
  ChatWLines = 'chat-w-lines',
  ChatWoLines = 'chat-wo-lines',
  Checklist = 'checklist',
  CircleAtoms = 'circle-atoms',
  Clipboard = 'clipboard',
  Clock = 'clock',
  Coach = 'coach',
  Coffee = 'coffee',
  Compass = 'compass',
  Couple = 'couple',
  DirectionalSigns = 'directional-signs',
  DisasterCenter = 'disaster-center',
  DoctorM = 'doctor-m',
  DoctorW = 'doctor-w',
  Documents = 'documents',
  DollarSign = 'dollar-sign',
  Earth = 'earth',
  Employer = 'employer',
  EnvelopeClosed = 'envelope-closed',
  EnvelopeOpen = 'envelope-open',
  Espresso = 'espresso',
  Eye = 'eye',
  Family = 'family',
  FirstaidKit = 'firstaid-kit',
  Folder = 'folder',
  Friends = 'friends',
  Girl = 'girl',
  Glasses = 'glasses',
  Globe = 'globe',
  Golf = 'golf',
  Government = 'government',
  GraduationCap = 'graduation-cap',
  HandicapNew = 'handicap-new',
  Handicap = 'handicap',
  Handshake = 'handshake',
  Headset = 'headset',
  HeartBeat = 'heart-beat',
  HeartHand = 'heart-hand',
  HeartHug = 'heart-hug',
  HeartPlus = 'heart-plus',
  Heart = 'heart',
  HomeDelivery = 'home-delivery',
  Hospital = 'hospital',
  House = 'house',
  IdCard = 'id-card',
  InBox = 'in-box',
  InternetOfThings = 'internet-of-things',
  LabBeaker = 'lab-beaker',
  Laptop = 'laptop',
  Leaf = 'leaf',
  Leaves = 'leaves',
  LifeguardBuoy = 'lifeguard-buoy',
  LightbulbOff = 'lightbulb-off',
  LightbulbOn = 'lightbulb-on',
  Location = 'location',
  Lock = 'lock',
  LongtermHealth = 'longterm-health',
  Magnify = 'magnify',
  Man = 'man',
  MapMarker = 'map-marker',
  MedCaller = 'med-caller',
  MedicalCross = 'medical-cross',
  MedicalForm = 'medical-form',
  Medication = 'medication',
  Microphone = 'microphone',
  Mobile = 'mobile',
  Mug = 'mug',
  NameTag = 'name-tag',
  Network = 'network',
  News = 'news',
  NurseHead = 'nurse-head',
  Nurse = 'nurse',
  OutBox = 'out-box',
  Padlock = 'padlock',
  Paperclip = 'paperclip',
  Pencil = 'pencil',
  PersonalGuide = 'personal-guide',
  Phone = 'phone',
  Piggybank = 'piggybank',
  PillsCapsules = 'pills-capsules',
  Pills = 'pills',
  PlayBtn = 'play-btn',
  PreventativeCare = 'preventative-care',
  PushPin = 'push-pin',
  Rain = 'rain',
  Recycle = 'recycle',
  ScaleFeet = 'scale-feet',
  Scale = 'scale',
  ScalesJustice = 'scales-justice',
  Shield = 'shield',
  Sneakers = 'sneakers',
  Star = 'star',
  StehtoscopeSimple = 'stehtoscope-simple',
  StethoscopeNew = 'stethoscope-new',
  StethoscopeOld = 'stethoscope-old',
  Sun = 'sun',
  Syringe = 'syringe',
  Tablet = 'tablet',
  Thermometer = 'thermometer',
  ThumbsUp = 'thumbs-up',
  Tooth = 'tooth',
  TrackingDevice = 'tracking-device',
  Umbrealla = 'umbrealla',
  Unlock = 'unlock',
  Vegetables = 'vegetables',
  Video = 'video',
  VirtualCare = 'virtual-care',
  Virus = 'virus',
  Weights = 'weights',
  Whistle = 'whistle',
  Woman = 'woman',
  Bars = 'bars',
  BtnX = 'btn-x',
  CaretDown = 'caret-down',
  CaretRight = 'caret-right',
  CheckCircleInverted = 'check-circle-inverted',
  CheckCircle = 'check-circle',
  Check = 'check',
  ChevronCircleRightInverted = 'chevron-circle-right-inverted',
  ChevronCircleRight = 'chevron-circle-right',
  ChevronRight = 'chevron-right',
  ChevronThinRight = 'chevron-thin-right',
  CircleMinus = 'circle-minus',
  CirclePlus = 'circle-plus',
  Close = 'close',
  Coins = 'coins',
  EnvelopeO = 'envelope-o',
  ExclamationCircle = 'exclamation-circle',
  ExclamationTriangle = 'exclamation-triangle',
  ExternalLink = 'external-link',
  FacebookSquare = 'facebook-square',
  Facebook = 'facebook',
  GoogleG = 'google-g',
  GoogleSquare = 'google-square',
  Google = 'google',
  InfoCircle = 'info-circle',
  InstagramSquare = 'instagram-square',
  Instagram = 'instagram',
  LinkedinSquare = 'linkedin-square',
  Linkedin = 'linkedin',
  PintrestSquare = 'pintrest-square',
  Pintrest = 'pintrest',
  Print = 'print',
  Search = 'search',
  SnapchatSquare = 'snapchat-square',
  Snapchat = 'snapchat',
  TwitterSquare = 'twitter-square',
  Twitter = 'twitter',
  WeiboSquare = 'weibo-square',
  Weibo = 'weibo',
  YoutubePlaySquare = 'youtube-play-square',
  YoutubePlay = 'youtube-play',
  ChatBlue = 'Chat-blue',
  AccidentalBlue = 'accidental-blue',
  Accredo = 'accredo',
  ActiveEapChat = 'active_eap_chat',
  AddLight = 'add-light',
  AddSymbol = 'add-symbol',
  AlertOrangeTriangle = 'alert-orange-triangle',
  AlertTriangle = 'alert-triangle',
  AlternativeMed = 'alternative-med',
  Americanexpress = 'americanexpress',
  Archive = 'archive',
  ArrowIc = 'arrow-ic',
  Back = 'back',
  BankAccountNumber = 'bank-account-number',
  BankRoutingNumber = 'bank-routing-number',
  BehavioralHealth = 'behavioral-health',
  Behavioral = 'behavioral',
  BellHeavy = 'bell-heavy',
  Bell = 'bell',
  BizcardLg = 'bizcard-lg',
  BloodTests = 'blood-tests',
  BlueThumbsUp = 'blue-thumbs-up',
  BriefcasePlus = 'briefcase-plus',
  BrighterMatch = 'brighter-match',
  BuilderInCircle = 'builder-in-circle',
  BullhornGreen = 'bullhorn-green',
  CalendarAlt = 'calendar-alt',
  CartBold = 'cart-bold',
  Challenge = 'challenge',
  CheckMarkHeavy = 'check-mark-heavy',
  CheckboxSelected = 'checkbox-selected',
  Checkbox = 'checkbox',
  CheckmarkCircleOutline = 'checkmark-circle-outline',
  CheckmarkOrange = 'checkmark-orange',
  CheckmarkThin = 'checkmark-thin',
  Checkmark = 'checkmark',
  ChevronDownMedium = 'chevron-down-medium',
  CiCoverageIcon = 'ci-coverage-icon',
  CignaCareDesignation = 'cigna-care-designation',
  CignaIconDollarSign = 'cigna-icon-dollar-sign',
  CignaIconMedication = 'cigna-icon-medication',
  CignaIconPiggyBank = 'cigna-icon-piggy-bank',
  CignaIconTruck = 'cigna-icon-truck',
  CircleCheckOverlay = 'circle-check-overlay',
  Circle = 'circle',
  ClipboardBlue = 'clipboard-blue',
  CloseThin = 'close-thin',
  CloudDownArrow = 'cloud-down-arrow',
  CoeStar = 'coe-star',
  Congrats = 'congrats',
  ContactUsPhone = 'contact-us-phone',
  ContactUs = 'contact-us',
  CovidIcon = 'covid-icon',
  CovidTestKit = 'covid-test-kit',
  CriticalBlue = 'critical-blue',
  CuratedMoments = 'curated-moments',
  CurrentDirectMessage = 'current-direct-message',
  DcmChatIcon = 'dcm-chat-icon',
  DcmLightbulbOn = 'dcm-lightbulb-on',
  DebitCard = 'debit-card',
  Devices = 'devices',
  DirectMessageHistory = 'direct-message-history',
  DirectMessage = 'direct-message',
  Disability = 'disability',
  Discover = 'discover',
  DollarDownBlue = 'dollar-down-blue',
  DollarDown = 'dollar-down',
  DollarIcon = 'dollar-icon',
  Download = 'download',
  EmailBlue2 = 'email-blue2',
  Email = 'email',
  EmergencyResponderSmall = 'emergency-responder-small',
  EppIcon = 'epp-icon',
  Epp = 'epp',
  EvernorthChat = 'evernorth-chat',
  EvernorthDollar = 'evernorth-dollar',
  EvernorthInformation = 'evernorth-information',
  EvernorthLetter = 'evernorth-letter',
  EvernorthLightBulb = 'evernorth-light-bulb',
  EvernorthMedicalForm = 'evernorth-medical-form',
  EvernorthPatients = 'evernorth-patients',
  EvernorthPerson = 'evernorth-person',
  EvernorthSearch = 'evernorth-search',
  ExclamationThinCircle = 'exclamation-thin-circle',
  ExistingAlert = 'existing-alert',
  ExpressScripts = 'express-scripts',
  Facebook2 = 'facebook2',
  FastAccess = 'fast-access',
  FileLinesBlue = 'file-lines-blue',
  File = 'file',
  Filter = 'filter',
  FlagSolid = 'flag-solid',
  Flag = 'flag',
  GearsBlue = 'gears-blue',
  GenericFile = 'generic_file',
  GlobeSolid = 'globe-solid',
  GrayHeartGrayOutline = 'gray-heart-gray-outline',
  GreenCheck = 'green-check',
  GreyCross = 'grey-cross',
  GreyOval = 'grey-oval',
  HandDocument = 'hand-document',
  HcCoverageIcon = 'hc-coverage-icon',
  HealthAssessment = 'health-assessment',
  HealthRewards = 'health-rewards',
  HeartBlue = 'heart-blue',
  HeartCircleActive = 'heart-circle-active',
  HeartCircleInactive = 'heart-circle-inactive',
  HeartInactive = 'heart-inactive',
  HomeCare = 'home-care',
  HomeDeliveryTruck = 'home-delivery-truck',
  HospitalBlue = 'hospital-blue',
  HospitalInCircle = 'hospital-in-circle',
  IconClose = 'icon-close',
  IconDrugsFree = 'icon-drugs-free',
  IconMore = 'icon-more',
  IconPillThickContainer = 'icon-pill-thick-container',
  IconRefresh = 'icon-refresh',
  IconSearch = 'icon-search',
  ImmediateCare = 'immediate-care',
  InBoxLg = 'in-box-lg',
  InNetworkCheck = 'in-network-check',
  InProgress = 'in-progress',
  InactiveEapChat = 'inactive_eap_chat',
  Incentive = 'incentive',
  Infertility = 'infertility',
  InfoCircleOutline = 'info-circle-outline',
  Journey = 'journey',
  LaunchExternal = 'launch-external',
  LaunchRight = 'launch-right',
  LeftArrow = 'left-arrow',
  LeftPointerGreenTriangle = 'left-pointer-green-triangle',
  LightbulbGreen = 'lightbulb-green',
  Lightbulb = 'lightbulb',
  LinkCarat = 'link-carat',
  Linkedin2 = 'linkedin2',
  ListToggle = 'list-toggle',
  LiveChatGroup = 'live-chat-group',
  LiveChat = 'live-chat',
  LocationIcon = 'location-icon',
  LockSolid = 'lock-solid',
  MailBlue = 'mail-blue',
  MailboxOpen = 'mailbox-open',
  MapMarkerSolid = 'map-marker-solid',
  MapMarkerWhite = 'map-marker-white',
  MapToggle = 'map-toggle',
  Mastercard = 'mastercard',
  MaternityStroller = 'maternity-stroller',
  MdLive = 'md-live',
  MedEquipment = 'med-equipment',
  Media = 'media',
  MinusSign = 'minus-sign',
  MinusSymbol = 'minus-symbol',
  MoreInfo = 'more-info',
  Mute = 'mute',
  NewBanner = 'new-banner',
  Newspaper = 'newspaper',
  Next = 'next',
  NinetyDayMedicationPam = 'ninety-day-medication-pam',
  NinetyDayMedication = 'ninety-day-medication',
  NoChange = 'no-change',
  NoPatientsAlert = 'no-patients-alert',
  OnboardingBehavioralSa = 'onboarding-behavioral-sa',
  OnboardingBehavioral = 'onboarding-behavioral',
  OnboardingDental = 'onboarding-dental',
  OnboardingEap = 'onboarding-eap',
  OnboardingHearing = 'onboarding-hearing',
  OnboardingMedical = 'onboarding-medical',
  OnboardingRx = 'onboarding-rx',
  OnboardingSupplemental = 'onboarding-supplemental',
  OnboardingVision = 'onboarding-vision',
  OnlineBlue = 'online-blue',
  OvalThumbsUp = 'oval-thumbs-up',
  Oval = 'oval',
  PamSdpBanner = 'pam-sdp-banner',
  PencilLined = 'pencil-lined',
  PersonHearing = 'person-hearing',
  PharmacyBowl = 'pharmacy-bowl',
  PhoneBlue = 'phone-blue',
  PhonePlain = 'phone-plain',
  PhoneVirtual = 'phone-virtual',
  PhysicalTherapy = 'physical-therapy',
  Pillar = 'pillar',
  PinkPiggy = 'pink-piggy',
  PlusButton = 'plus-button',
  PlusSign = 'plus-sign',
  Plus = 'plus',
  Prescriptions = 'prescriptions',
  Preventative = 'preventative',
  ProfileBlue = 'profile-blue',
  ProfileHead = 'profile-head',
  ProfilePerson = 'profile-person',
  ProfilePlaceholderAssociation = 'profile-placeholder-association',
  ProfilePlaceholderFemale = 'profile-placeholder-female',
  ProfilePlaceholderMale = 'profile-placeholder-male',
  ProfilePlaceholderNeutral = 'profile-placeholder-neutral',
  ProfileSolid = 'profile-solid',
  Profile = 'profile',
  ProviderBehavioral = 'provider-behavioral',
  ProviderDentist = 'provider-dentist',
  ProviderDermatologist = 'provider-dermatologist',
  ProviderFemale = 'provider-female',
  ProviderObgyn = 'provider-obgyn',
  ProviderOralSurgeon = 'provider-oral-surgeon',
  ProviderOrthodontist = 'provider-orthodontist',
  ProviderOrthopedicSurgeon = 'provider-orthopedic-surgeon',
  ProviderPediatricDentist = 'provider-pediatric-dentist',
  ProviderPrimaryCare = 'provider-primary-care',
  ProviderPsychiatrist = 'provider-psychiatrist',
  ProviderPsychologist = 'provider-psychologist',
  ProviderTherapist = 'provider-therapist',
  ProviderVisits = 'provider-visits',
  PushPinSolid = 'push-pin-solid',
  QuestionCircle = 'question-circle',
  QuestionMarkCircle = 'question-mark-circle',
  RatingStarOn = 'rating-star-on',
  RedAlert = 'red-alert',
  ReloadArrow = 'reload-arrow',
  RemoveHover = 'remove-hover',
  Remove = 'remove',
  Reward = 'reward',
  RightArrow = 'right-arrow',
  RoundQuestionMark = 'round-question-mark',
  RtmBox = 'rtm-box',
  RtmDeliveryTruck = 'rtm-delivery-truck',
  RxOnboardingPil = 'rx-onboarding-pil',
  RxTruck = 'rx-truck',
  SearchMachine = 'search-machine',
  SearchThin = 'search-thin',
  Settings = 'settings',
  ShieldSolid = 'shield-solid',
  ShippingBox = 'shipping-box',
  SingleChatBubble = 'single-chat-bubble',
  SmallIdCard = 'small-id-card',
  SoundOff = 'sound-off',
  SoundOn = 'sound-on',
  Sound = 'sound',
  StarEmpty = 'star-empty',
  StarFilled = 'star-filled',
  StepCompleteCheck = 'step-complete-check',
  StepDenial = 'step-denial',
  StepFourComplete = 'step-four-complete',
  StepFourIncomplete = 'step-four-incomplete',
  StepThreeComplete = 'step-three-complete',
  StepThreeIncomplete = 'step-three-incomplete',
  StepTwoComplete = 'step-two-complete',
  StepTwoIncomplete = 'step-two-incomplete',
  Store = 'store',
  Support = 'support',
  Surgeries = 'surgeries',
  Teletypewriter = 'teletypewriter',
  ThumbsDownBlk = 'thumbs-down-blk',
  ThumbsDownRed = 'thumbs-down-red',
  ThumbsUpBlk = 'thumbs-up-blk',
  ThumbsUpBlue = 'thumbs-up-blue',
  ThumbsUpGreen = 'thumbs-up-green',
  ThumbsUpSolid = 'thumbs-up-solid',
  ToggleMenu = 'toggle-menu',
  ToothHeart = 'tooth-heart',
  TopDentistsCalendar = 'top-dentists-calendar',
  Truck = 'truck',
  Twitter2 = 'twitter2',
  Unavailable = 'unavailable',
  VaIcon = 'va-icon',
  VirtualCounseling = 'virtual-counseling',
  Visa = 'visa',
  Vision = 'vision',
  WellnessBlue = 'wellness-blue',
  WhiteGraphicThumbsUp = 'white-graphic-thumbs-up',
  WhiteHeartGrayOutline = 'white-heart-gray-outline',
  WorkLife = 'work-life',
  WriteReview = 'write-review',
  XCircleOutline = 'x-circle-outline',
  XRay = 'x-ray',
  YellowAlertTriangleLine = 'yellow-alert-triangle-line',
  Youtube2 = 'youtube2',
  ThumbsDown = 'thumbs-down',
  CaretUp = 'caret-up',
  CaretLeft = 'caret-left',
  ChevronCircleUp = 'chevron-circle-up',
  ChevronCircleDown = 'chevron-circle-down',
  ChevronCircleLeft = 'chevron-circle-left',
  ChevronUp = 'chevron-up',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronThinUp = 'chevron-thin-up',
  ChevronThinDown = 'chevron-thin-down',
  ChevronThinLeft = 'chevron-thin-left',
  BlueThumbsDown = 'blue-thumbs-down',
  DollarUp = 'dollar-up',
  LaunchUp = 'launch-up',
  LaunchDown = 'launch-down',
  LaunchLeft = 'launch-left',
  OvalThumbsDown = 'oval-thumbs-down',
  WhiteGraphicThumbsDown = 'white-graphic-thumbs-down',
}

export function cignaSvgsConfig(icon: CignaSvgsIcon): IconConfig | undefined {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (icon) {
    case CignaSvgsIcon.Ambulance:
      return { svgIcon: 'cigna-svgs:ambulance' };
    case CignaSvgsIcon.AppTouch:
      return { svgIcon: 'cigna-svgs:app-touch' };
    case CignaSvgsIcon.Apple:
      return { svgIcon: 'cigna-svgs:apple' };
    case CignaSvgsIcon.Arrow:
      return { svgIcon: 'cigna-svgs:arrow' };
    case CignaSvgsIcon.ArtificialIntelligence:
      return { svgIcon: 'cigna-svgs:artificial-intelligence' };
    case CignaSvgsIcon.AwardRibbon:
      return { svgIcon: 'cigna-svgs:award-ribbon' };
    case CignaSvgsIcon.BabyStroller:
      return { svgIcon: 'cigna-svgs:baby-stroller' };
    case CignaSvgsIcon.Bandaid:
      return { svgIcon: 'cigna-svgs:bandaid' };
    case CignaSvgsIcon.Bizcard:
      return { svgIcon: 'cigna-svgs:bizcard' };
    case CignaSvgsIcon.BookOpen:
      return { svgIcon: 'cigna-svgs:book-open' };
    case CignaSvgsIcon.Book:
      return { svgIcon: 'cigna-svgs:book' };
    case CignaSvgsIcon.Boy:
      return { svgIcon: 'cigna-svgs:boy' };
    case CignaSvgsIcon.Briefcase:
      return { svgIcon: 'cigna-svgs:briefcase' };
    case CignaSvgsIcon.Bullhorn:
      return { svgIcon: 'cigna-svgs:bullhorn' };
    case CignaSvgsIcon.Calculator:
      return { svgIcon: 'cigna-svgs:calculator' };
    case CignaSvgsIcon.Calendar:
      return { svgIcon: 'cigna-svgs:calendar' };
    case CignaSvgsIcon.Capitol:
      return { svgIcon: 'cigna-svgs:capitol' };
    case CignaSvgsIcon.Carrot:
      return { svgIcon: 'cigna-svgs:carrot' };
    case CignaSvgsIcon.Cart:
      return { svgIcon: 'cigna-svgs:cart' };
    case CignaSvgsIcon.Certificate:
      return { svgIcon: 'cigna-svgs:certificate' };
    case CignaSvgsIcon.ChartMobile:
      return { svgIcon: 'cigna-svgs:chart-mobile' };
    case CignaSvgsIcon.ChartUptrend:
      return { svgIcon: 'cigna-svgs:chart-uptrend' };
    case CignaSvgsIcon.ChatSquare:
      return { svgIcon: 'cigna-svgs:chat-square' };
    case CignaSvgsIcon.ChatWDots:
      return { svgIcon: 'cigna-svgs:chat-w-dots' };
    case CignaSvgsIcon.ChatWLines:
      return { svgIcon: 'cigna-svgs:chat-w-lines' };
    case CignaSvgsIcon.ChatWoLines:
      return { svgIcon: 'cigna-svgs:chat-wo-lines' };
    case CignaSvgsIcon.Checklist:
      return { svgIcon: 'cigna-svgs:checklist' };
    case CignaSvgsIcon.CircleAtoms:
      return { svgIcon: 'cigna-svgs:circle-atoms' };
    case CignaSvgsIcon.Clipboard:
      return { svgIcon: 'cigna-svgs:clipboard' };
    case CignaSvgsIcon.Clock:
      return { svgIcon: 'cigna-svgs:clock' };
    case CignaSvgsIcon.Coach:
      return { svgIcon: 'cigna-svgs:coach' };
    case CignaSvgsIcon.Coffee:
      return { svgIcon: 'cigna-svgs:coffee' };
    case CignaSvgsIcon.Compass:
      return { svgIcon: 'cigna-svgs:compass' };
    case CignaSvgsIcon.Couple:
      return { svgIcon: 'cigna-svgs:couple' };
    case CignaSvgsIcon.DirectionalSigns:
      return { svgIcon: 'cigna-svgs:directional-signs' };
    case CignaSvgsIcon.DisasterCenter:
      return { svgIcon: 'cigna-svgs:disaster-center' };
    case CignaSvgsIcon.DoctorM:
      return { svgIcon: 'cigna-svgs:doctor-m' };
    case CignaSvgsIcon.DoctorW:
      return { svgIcon: 'cigna-svgs:doctor-w' };
    case CignaSvgsIcon.Documents:
      return { svgIcon: 'cigna-svgs:documents' };
    case CignaSvgsIcon.DollarSign:
      return { svgIcon: 'cigna-svgs:dollar-sign' };
    case CignaSvgsIcon.Earth:
      return { svgIcon: 'cigna-svgs:earth' };
    case CignaSvgsIcon.Employer:
      return { svgIcon: 'cigna-svgs:employer' };
    case CignaSvgsIcon.EnvelopeClosed:
      return { svgIcon: 'cigna-svgs:envelope-closed' };
    case CignaSvgsIcon.EnvelopeOpen:
      return { svgIcon: 'cigna-svgs:envelope-open' };
    case CignaSvgsIcon.Espresso:
      return { svgIcon: 'cigna-svgs:espresso' };
    case CignaSvgsIcon.Eye:
      return { svgIcon: 'cigna-svgs:eye' };
    case CignaSvgsIcon.Family:
      return { svgIcon: 'cigna-svgs:family' };
    case CignaSvgsIcon.FirstaidKit:
      return { svgIcon: 'cigna-svgs:firstaid-kit' };
    case CignaSvgsIcon.Folder:
      return { svgIcon: 'cigna-svgs:folder' };
    case CignaSvgsIcon.Friends:
      return { svgIcon: 'cigna-svgs:friends' };
    case CignaSvgsIcon.Girl:
      return { svgIcon: 'cigna-svgs:girl' };
    case CignaSvgsIcon.Glasses:
      return { svgIcon: 'cigna-svgs:glasses' };
    case CignaSvgsIcon.Globe:
      return { svgIcon: 'cigna-svgs:globe' };
    case CignaSvgsIcon.Golf:
      return { svgIcon: 'cigna-svgs:golf' };
    case CignaSvgsIcon.Government:
      return { svgIcon: 'cigna-svgs:government' };
    case CignaSvgsIcon.GraduationCap:
      return { svgIcon: 'cigna-svgs:graduation-cap' };
    case CignaSvgsIcon.HandicapNew:
      return { svgIcon: 'cigna-svgs:handicap-new' };
    case CignaSvgsIcon.Handicap:
      return { svgIcon: 'cigna-svgs:handicap' };
    case CignaSvgsIcon.Handshake:
      return { svgIcon: 'cigna-svgs:handshake' };
    case CignaSvgsIcon.Headset:
      return { svgIcon: 'cigna-svgs:headset' };
    case CignaSvgsIcon.HeartBeat:
      return { svgIcon: 'cigna-svgs:heart-beat' };
    case CignaSvgsIcon.HeartHand:
      return { svgIcon: 'cigna-svgs:heart-hand' };
    case CignaSvgsIcon.HeartHug:
      return { svgIcon: 'cigna-svgs:heart-hug' };
    case CignaSvgsIcon.HeartPlus:
      return { svgIcon: 'cigna-svgs:heart-plus' };
    case CignaSvgsIcon.Heart:
      return { svgIcon: 'cigna-svgs:heart' };
    case CignaSvgsIcon.HomeDelivery:
      return { svgIcon: 'cigna-svgs:home-delivery' };
    case CignaSvgsIcon.Hospital:
      return { svgIcon: 'cigna-svgs:hospital' };
    case CignaSvgsIcon.House:
      return { svgIcon: 'cigna-svgs:house' };
    case CignaSvgsIcon.IdCard:
      return { svgIcon: 'cigna-svgs:id-card' };
    case CignaSvgsIcon.InBox:
      return { svgIcon: 'cigna-svgs:in-box' };
    case CignaSvgsIcon.InternetOfThings:
      return { svgIcon: 'cigna-svgs:internet-of-things' };
    case CignaSvgsIcon.LabBeaker:
      return { svgIcon: 'cigna-svgs:lab-beaker' };
    case CignaSvgsIcon.Laptop:
      return { svgIcon: 'cigna-svgs:laptop' };
    case CignaSvgsIcon.Leaf:
      return { svgIcon: 'cigna-svgs:leaf' };
    case CignaSvgsIcon.Leaves:
      return { svgIcon: 'cigna-svgs:leaves' };
    case CignaSvgsIcon.LifeguardBuoy:
      return { svgIcon: 'cigna-svgs:lifeguard-buoy' };
    case CignaSvgsIcon.LightbulbOff:
      return { svgIcon: 'cigna-svgs:lightbulb-off' };
    case CignaSvgsIcon.LightbulbOn:
      return { svgIcon: 'cigna-svgs:lightbulb-on' };
    case CignaSvgsIcon.Location:
      return { svgIcon: 'cigna-svgs:location' };
    case CignaSvgsIcon.Lock:
      return { svgIcon: 'cigna-svgs:lock' };
    case CignaSvgsIcon.LongtermHealth:
      return { svgIcon: 'cigna-svgs:longterm-health' };
    case CignaSvgsIcon.Magnify:
      return { svgIcon: 'cigna-svgs:magnify' };
    case CignaSvgsIcon.Man:
      return { svgIcon: 'cigna-svgs:man' };
    case CignaSvgsIcon.MapMarker:
      return { svgIcon: 'cigna-svgs:map-marker' };
    case CignaSvgsIcon.MedCaller:
      return { svgIcon: 'cigna-svgs:med-caller' };
    case CignaSvgsIcon.MedicalCross:
      return { svgIcon: 'cigna-svgs:medical-cross' };
    case CignaSvgsIcon.MedicalForm:
      return { svgIcon: 'cigna-svgs:medical-form' };
    case CignaSvgsIcon.Medication:
      return { svgIcon: 'cigna-svgs:medication' };
    case CignaSvgsIcon.Microphone:
      return { svgIcon: 'cigna-svgs:microphone' };
    case CignaSvgsIcon.Mobile:
      return { svgIcon: 'cigna-svgs:mobile' };
    case CignaSvgsIcon.Mug:
      return { svgIcon: 'cigna-svgs:mug' };
    case CignaSvgsIcon.NameTag:
      return { svgIcon: 'cigna-svgs:name-tag' };
    case CignaSvgsIcon.Network:
      return { svgIcon: 'cigna-svgs:network' };
    case CignaSvgsIcon.News:
      return { svgIcon: 'cigna-svgs:news' };
    case CignaSvgsIcon.NurseHead:
      return { svgIcon: 'cigna-svgs:nurse-head' };
    case CignaSvgsIcon.Nurse:
      return { svgIcon: 'cigna-svgs:nurse' };
    case CignaSvgsIcon.OutBox:
      return { svgIcon: 'cigna-svgs:out-box' };
    case CignaSvgsIcon.Padlock:
      return { svgIcon: 'cigna-svgs:padlock' };
    case CignaSvgsIcon.Paperclip:
      return { svgIcon: 'cigna-svgs:paperclip' };
    case CignaSvgsIcon.Pencil:
      return { svgIcon: 'cigna-svgs:pencil' };
    case CignaSvgsIcon.PersonalGuide:
      return { svgIcon: 'cigna-svgs:personal-guide' };
    case CignaSvgsIcon.Phone:
      return { svgIcon: 'cigna-svgs:phone' };
    case CignaSvgsIcon.Piggybank:
      return { svgIcon: 'cigna-svgs:piggybank' };
    case CignaSvgsIcon.PillsCapsules:
      return { svgIcon: 'cigna-svgs:pills-capsules' };
    case CignaSvgsIcon.Pills:
      return { svgIcon: 'cigna-svgs:pills' };
    case CignaSvgsIcon.PlayBtn:
      return { svgIcon: 'cigna-svgs:play-btn' };
    case CignaSvgsIcon.PreventativeCare:
      return { svgIcon: 'cigna-svgs:preventative-care' };
    case CignaSvgsIcon.PushPin:
      return { svgIcon: 'cigna-svgs:push-pin' };
    case CignaSvgsIcon.Rain:
      return { svgIcon: 'cigna-svgs:rain' };
    case CignaSvgsIcon.Recycle:
      return { svgIcon: 'cigna-svgs:recycle' };
    case CignaSvgsIcon.ScaleFeet:
      return { svgIcon: 'cigna-svgs:scale-feet' };
    case CignaSvgsIcon.Scale:
      return { svgIcon: 'cigna-svgs:scale' };
    case CignaSvgsIcon.ScalesJustice:
      return { svgIcon: 'cigna-svgs:scales-justice' };
    case CignaSvgsIcon.Shield:
      return { svgIcon: 'cigna-svgs:shield' };
    case CignaSvgsIcon.Sneakers:
      return { svgIcon: 'cigna-svgs:sneakers' };
    case CignaSvgsIcon.Star:
      return { svgIcon: 'cigna-svgs:star' };
    case CignaSvgsIcon.StehtoscopeSimple:
      return { svgIcon: 'cigna-svgs:stehtoscope-simple' };
    case CignaSvgsIcon.StethoscopeNew:
      return { svgIcon: 'cigna-svgs:stethoscope-new' };
    case CignaSvgsIcon.StethoscopeOld:
      return { svgIcon: 'cigna-svgs:stethoscope-old' };
    case CignaSvgsIcon.Sun:
      return { svgIcon: 'cigna-svgs:sun' };
    case CignaSvgsIcon.Syringe:
      return { svgIcon: 'cigna-svgs:syringe' };
    case CignaSvgsIcon.Tablet:
      return { svgIcon: 'cigna-svgs:tablet' };
    case CignaSvgsIcon.Thermometer:
      return { svgIcon: 'cigna-svgs:thermometer' };
    case CignaSvgsIcon.ThumbsUp:
      return { svgIcon: 'cigna-svgs:thumbs-up' };
    case CignaSvgsIcon.Tooth:
      return { svgIcon: 'cigna-svgs:tooth' };
    case CignaSvgsIcon.TrackingDevice:
      return { svgIcon: 'cigna-svgs:tracking-device' };
    case CignaSvgsIcon.Umbrealla:
      return { svgIcon: 'cigna-svgs:umbrealla' };
    case CignaSvgsIcon.Unlock:
      return { svgIcon: 'cigna-svgs:unlock' };
    case CignaSvgsIcon.Vegetables:
      return { svgIcon: 'cigna-svgs:vegetables' };
    case CignaSvgsIcon.Video:
      return { svgIcon: 'cigna-svgs:video' };
    case CignaSvgsIcon.VirtualCare:
      return { svgIcon: 'cigna-svgs:virtual-care' };
    case CignaSvgsIcon.Virus:
      return { svgIcon: 'cigna-svgs:virus' };
    case CignaSvgsIcon.Weights:
      return { svgIcon: 'cigna-svgs:weights' };
    case CignaSvgsIcon.Whistle:
      return { svgIcon: 'cigna-svgs:whistle' };
    case CignaSvgsIcon.Woman:
      return { svgIcon: 'cigna-svgs:woman' };
    case CignaSvgsIcon.Bars:
      return { svgIcon: 'cigna-svgs:bars' };
    case CignaSvgsIcon.BtnX:
      return { svgIcon: 'cigna-svgs:btn-x' };
    case CignaSvgsIcon.CaretDown:
      return { svgIcon: 'cigna-svgs:caret-down' };
    case CignaSvgsIcon.CaretRight:
      return { svgIcon: 'cigna-svgs:caret-right' };
    case CignaSvgsIcon.CheckCircleInverted:
      return { svgIcon: 'cigna-svgs:check-circle-inverted' };
    case CignaSvgsIcon.CheckCircle:
      return { svgIcon: 'cigna-svgs:check-circle' };
    case CignaSvgsIcon.Check:
      return { svgIcon: 'cigna-svgs:check' };
    case CignaSvgsIcon.ChevronCircleRightInverted:
      return { svgIcon: 'cigna-svgs:chevron-circle-right-inverted' };
    case CignaSvgsIcon.ChevronCircleRight:
      return { svgIcon: 'cigna-svgs:chevron-circle-right' };
    case CignaSvgsIcon.ChevronRight:
      return { svgIcon: 'cigna-svgs:chevron-right' };
    case CignaSvgsIcon.ChevronThinRight:
      return { svgIcon: 'cigna-svgs:chevron-thin-right' };
    case CignaSvgsIcon.CircleMinus:
      return { svgIcon: 'cigna-svgs:circle-minus' };
    case CignaSvgsIcon.CirclePlus:
      return { svgIcon: 'cigna-svgs:circle-plus' };
    case CignaSvgsIcon.Close:
      return { svgIcon: 'cigna-svgs:close' };
    case CignaSvgsIcon.Coins:
      return { svgIcon: 'cigna-svgs:coins' };
    case CignaSvgsIcon.EnvelopeO:
      return { svgIcon: 'cigna-svgs:envelope-o' };
    case CignaSvgsIcon.ExclamationCircle:
      return { svgIcon: 'cigna-svgs:exclamation-circle' };
    case CignaSvgsIcon.ExclamationTriangle:
      return { svgIcon: 'cigna-svgs:exclamation-triangle' };
    case CignaSvgsIcon.ExternalLink:
      return { svgIcon: 'cigna-svgs:external-link' };
    case CignaSvgsIcon.FacebookSquare:
      return { svgIcon: 'cigna-svgs:facebook-square' };
    case CignaSvgsIcon.Facebook:
      return { svgIcon: 'cigna-svgs:facebook' };
    case CignaSvgsIcon.GoogleG:
      return { svgIcon: 'cigna-svgs:google-g' };
    case CignaSvgsIcon.GoogleSquare:
      return { svgIcon: 'cigna-svgs:google-square' };
    case CignaSvgsIcon.Google:
      return { svgIcon: 'cigna-svgs:google' };
    case CignaSvgsIcon.InfoCircle:
      return { svgIcon: 'cigna-svgs:info-circle' };
    case CignaSvgsIcon.InstagramSquare:
      return { svgIcon: 'cigna-svgs:instagram-square' };
    case CignaSvgsIcon.Instagram:
      return { svgIcon: 'cigna-svgs:instagram' };
    case CignaSvgsIcon.LinkedinSquare:
      return { svgIcon: 'cigna-svgs:linkedin-square' };
    case CignaSvgsIcon.Linkedin:
      return { svgIcon: 'cigna-svgs:linkedin' };
    case CignaSvgsIcon.PintrestSquare:
      return { svgIcon: 'cigna-svgs:pintrest-square' };
    case CignaSvgsIcon.Pintrest:
      return { svgIcon: 'cigna-svgs:pintrest' };
    case CignaSvgsIcon.Print:
      return { svgIcon: 'cigna-svgs:print' };
    case CignaSvgsIcon.Search:
      return { svgIcon: 'cigna-svgs:search' };
    case CignaSvgsIcon.SnapchatSquare:
      return { svgIcon: 'cigna-svgs:snapchat-square' };
    case CignaSvgsIcon.Snapchat:
      return { svgIcon: 'cigna-svgs:snapchat' };
    case CignaSvgsIcon.TwitterSquare:
      return { svgIcon: 'cigna-svgs:twitter-square' };
    case CignaSvgsIcon.Twitter:
      return { svgIcon: 'cigna-svgs:twitter' };
    case CignaSvgsIcon.WeiboSquare:
      return { svgIcon: 'cigna-svgs:weibo-square' };
    case CignaSvgsIcon.Weibo:
      return { svgIcon: 'cigna-svgs:weibo' };
    case CignaSvgsIcon.YoutubePlaySquare:
      return { svgIcon: 'cigna-svgs:youtube-play-square' };
    case CignaSvgsIcon.YoutubePlay:
      return { svgIcon: 'cigna-svgs:youtube-play' };
    case CignaSvgsIcon.ChatBlue:
      return { svgIcon: 'cigna-svgs:Chat-blue' };
    case CignaSvgsIcon.AccidentalBlue:
      return { svgIcon: 'cigna-svgs:accidental-blue' };
    case CignaSvgsIcon.Accredo:
      return { svgIcon: 'cigna-svgs:accredo' };
    case CignaSvgsIcon.ActiveEapChat:
      return { svgIcon: 'cigna-svgs:active_eap_chat' };
    case CignaSvgsIcon.AddLight:
      return { svgIcon: 'cigna-svgs:add-light' };
    case CignaSvgsIcon.AddSymbol:
      return { svgIcon: 'cigna-svgs:add-symbol' };
    case CignaSvgsIcon.AlertOrangeTriangle:
      return { svgIcon: 'cigna-svgs:alert-orange-triangle' };
    case CignaSvgsIcon.AlertTriangle:
      return { svgIcon: 'cigna-svgs:alert-triangle' };
    case CignaSvgsIcon.AlternativeMed:
      return { svgIcon: 'cigna-svgs:alternative-med' };
    case CignaSvgsIcon.Americanexpress:
      return { svgIcon: 'cigna-svgs:americanexpress' };
    case CignaSvgsIcon.Archive:
      return { svgIcon: 'cigna-svgs:archive' };
    case CignaSvgsIcon.ArrowIc:
      return { svgIcon: 'cigna-svgs:arrow-ic' };
    case CignaSvgsIcon.Back:
      return { svgIcon: 'cigna-svgs:back' };
    case CignaSvgsIcon.BankAccountNumber:
      return { svgIcon: 'cigna-svgs:bank-account-number' };
    case CignaSvgsIcon.BankRoutingNumber:
      return { svgIcon: 'cigna-svgs:bank-routing-number' };
    case CignaSvgsIcon.BehavioralHealth:
      return { svgIcon: 'cigna-svgs:behavioral-health' };
    case CignaSvgsIcon.Behavioral:
      return { svgIcon: 'cigna-svgs:behavioral' };
    case CignaSvgsIcon.BellHeavy:
      return { svgIcon: 'cigna-svgs:bell-heavy' };
    case CignaSvgsIcon.Bell:
      return { svgIcon: 'cigna-svgs:bell' };
    case CignaSvgsIcon.BizcardLg:
      return { svgIcon: 'cigna-svgs:bizcard-lg' };
    case CignaSvgsIcon.BloodTests:
      return { svgIcon: 'cigna-svgs:blood-tests' };
    case CignaSvgsIcon.BlueThumbsUp:
      return { svgIcon: 'cigna-svgs:blue-thumbs-up' };
    case CignaSvgsIcon.BriefcasePlus:
      return { svgIcon: 'cigna-svgs:briefcase-plus' };
    case CignaSvgsIcon.BrighterMatch:
      return { svgIcon: 'cigna-svgs:brighter-match' };
    case CignaSvgsIcon.BuilderInCircle:
      return { svgIcon: 'cigna-svgs:builder-in-circle' };
    case CignaSvgsIcon.BullhornGreen:
      return { svgIcon: 'cigna-svgs:bullhorn-green' };
    case CignaSvgsIcon.CalendarAlt:
      return { svgIcon: 'cigna-svgs:calendar-alt' };
    case CignaSvgsIcon.CartBold:
      return { svgIcon: 'cigna-svgs:cart-bold' };
    case CignaSvgsIcon.Challenge:
      return { svgIcon: 'cigna-svgs:challenge' };
    case CignaSvgsIcon.CheckMarkHeavy:
      return { svgIcon: 'cigna-svgs:check-mark-heavy' };
    case CignaSvgsIcon.CheckboxSelected:
      return { svgIcon: 'cigna-svgs:checkbox-selected' };
    case CignaSvgsIcon.Checkbox:
      return { svgIcon: 'cigna-svgs:checkbox' };
    case CignaSvgsIcon.CheckmarkCircleOutline:
      return { svgIcon: 'cigna-svgs:checkmark-circle-outline' };
    case CignaSvgsIcon.CheckmarkOrange:
      return { svgIcon: 'cigna-svgs:checkmark-orange' };
    case CignaSvgsIcon.CheckmarkThin:
      return { svgIcon: 'cigna-svgs:checkmark-thin' };
    case CignaSvgsIcon.Checkmark:
      return { svgIcon: 'cigna-svgs:checkmark' };
    case CignaSvgsIcon.ChevronDownMedium:
      return { svgIcon: 'cigna-svgs:chevron-down-medium' };
    case CignaSvgsIcon.CiCoverageIcon:
      return { svgIcon: 'cigna-svgs:ci-coverage-icon' };
    case CignaSvgsIcon.CignaCareDesignation:
      return { svgIcon: 'cigna-svgs:cigna-care-designation' };
    case CignaSvgsIcon.CignaIconDollarSign:
      return { svgIcon: 'cigna-svgs:cigna-icon-dollar-sign' };
    case CignaSvgsIcon.CignaIconMedication:
      return { svgIcon: 'cigna-svgs:cigna-icon-medication' };
    case CignaSvgsIcon.CignaIconPiggyBank:
      return { svgIcon: 'cigna-svgs:cigna-icon-piggy-bank' };
    case CignaSvgsIcon.CignaIconTruck:
      return { svgIcon: 'cigna-svgs:cigna-icon-truck' };
    case CignaSvgsIcon.CircleCheckOverlay:
      return { svgIcon: 'cigna-svgs:circle-check-overlay' };
    case CignaSvgsIcon.Circle:
      return { svgIcon: 'cigna-svgs:circle' };
    case CignaSvgsIcon.ClipboardBlue:
      return { svgIcon: 'cigna-svgs:clipboard-blue' };
    case CignaSvgsIcon.CloseThin:
      return { svgIcon: 'cigna-svgs:close-thin' };
    case CignaSvgsIcon.CloudDownArrow:
      return { svgIcon: 'cigna-svgs:cloud-down-arrow' };
    case CignaSvgsIcon.CoeStar:
      return { svgIcon: 'cigna-svgs:coe-star' };
    case CignaSvgsIcon.Congrats:
      return { svgIcon: 'cigna-svgs:congrats' };
    case CignaSvgsIcon.ContactUsPhone:
      return { svgIcon: 'cigna-svgs:contact-us-phone' };
    case CignaSvgsIcon.ContactUs:
      return { svgIcon: 'cigna-svgs:contact-us' };
    case CignaSvgsIcon.CovidIcon:
      return { svgIcon: 'cigna-svgs:covid-icon' };
    case CignaSvgsIcon.CovidTestKit:
      return { svgIcon: 'cigna-svgs:covid-test-kit' };
    case CignaSvgsIcon.CriticalBlue:
      return { svgIcon: 'cigna-svgs:critical-blue' };
    case CignaSvgsIcon.CuratedMoments:
      return { svgIcon: 'cigna-svgs:curated-moments' };
    case CignaSvgsIcon.CurrentDirectMessage:
      return { svgIcon: 'cigna-svgs:current-direct-message' };
    case CignaSvgsIcon.DcmChatIcon:
      return { svgIcon: 'cigna-svgs:dcm-chat-icon' };
    case CignaSvgsIcon.DcmLightbulbOn:
      return { svgIcon: 'cigna-svgs:dcm-lightbulb-on' };
    case CignaSvgsIcon.DebitCard:
      return { svgIcon: 'cigna-svgs:debit-card' };
    case CignaSvgsIcon.Devices:
      return { svgIcon: 'cigna-svgs:devices' };
    case CignaSvgsIcon.DirectMessageHistory:
      return { svgIcon: 'cigna-svgs:direct-message-history' };
    case CignaSvgsIcon.DirectMessage:
      return { svgIcon: 'cigna-svgs:direct-message' };
    case CignaSvgsIcon.Disability:
      return { svgIcon: 'cigna-svgs:disability' };
    case CignaSvgsIcon.Discover:
      return { svgIcon: 'cigna-svgs:discover' };
    case CignaSvgsIcon.DollarDownBlue:
      return { svgIcon: 'cigna-svgs:dollar-down-blue' };
    case CignaSvgsIcon.DollarDown:
      return { svgIcon: 'cigna-svgs:dollar-down' };
    case CignaSvgsIcon.DollarIcon:
      return { svgIcon: 'cigna-svgs:dollar-icon' };
    case CignaSvgsIcon.Download:
      return { svgIcon: 'cigna-svgs:download' };
    case CignaSvgsIcon.EmailBlue2:
      return { svgIcon: 'cigna-svgs:email-blue2' };
    case CignaSvgsIcon.Email:
      return { svgIcon: 'cigna-svgs:email' };
    case CignaSvgsIcon.EmergencyResponderSmall:
      return { svgIcon: 'cigna-svgs:emergency-responder-small' };
    case CignaSvgsIcon.EppIcon:
      return { svgIcon: 'cigna-svgs:epp-icon' };
    case CignaSvgsIcon.Epp:
      return { svgIcon: 'cigna-svgs:epp' };
    case CignaSvgsIcon.EvernorthChat:
      return { svgIcon: 'cigna-svgs:evernorth-chat' };
    case CignaSvgsIcon.EvernorthDollar:
      return { svgIcon: 'cigna-svgs:evernorth-dollar' };
    case CignaSvgsIcon.EvernorthInformation:
      return { svgIcon: 'cigna-svgs:evernorth-information' };
    case CignaSvgsIcon.EvernorthLetter:
      return { svgIcon: 'cigna-svgs:evernorth-letter' };
    case CignaSvgsIcon.EvernorthLightBulb:
      return { svgIcon: 'cigna-svgs:evernorth-light-bulb' };
    case CignaSvgsIcon.EvernorthMedicalForm:
      return { svgIcon: 'cigna-svgs:evernorth-medical-form' };
    case CignaSvgsIcon.EvernorthPatients:
      return { svgIcon: 'cigna-svgs:evernorth-patients' };
    case CignaSvgsIcon.EvernorthPerson:
      return { svgIcon: 'cigna-svgs:evernorth-person' };
    case CignaSvgsIcon.EvernorthSearch:
      return { svgIcon: 'cigna-svgs:evernorth-search' };
    case CignaSvgsIcon.ExclamationThinCircle:
      return { svgIcon: 'cigna-svgs:exclamation-thin-circle' };
    case CignaSvgsIcon.ExistingAlert:
      return { svgIcon: 'cigna-svgs:existing-alert' };
    case CignaSvgsIcon.ExpressScripts:
      return { svgIcon: 'cigna-svgs:express-scripts' };
    case CignaSvgsIcon.Facebook2:
      return { svgIcon: 'cigna-svgs:facebook2' };
    case CignaSvgsIcon.FastAccess:
      return { svgIcon: 'cigna-svgs:fast-access' };
    case CignaSvgsIcon.FileLinesBlue:
      return { svgIcon: 'cigna-svgs:file-lines-blue' };
    case CignaSvgsIcon.File:
      return { svgIcon: 'cigna-svgs:file' };
    case CignaSvgsIcon.Filter:
      return { svgIcon: 'cigna-svgs:filter' };
    case CignaSvgsIcon.FlagSolid:
      return { svgIcon: 'cigna-svgs:flag-solid' };
    case CignaSvgsIcon.Flag:
      return { svgIcon: 'cigna-svgs:flag' };
    case CignaSvgsIcon.GearsBlue:
      return { svgIcon: 'cigna-svgs:gears-blue' };
    case CignaSvgsIcon.GenericFile:
      return { svgIcon: 'cigna-svgs:generic_file' };
    case CignaSvgsIcon.GlobeSolid:
      return { svgIcon: 'cigna-svgs:globe-solid' };
    case CignaSvgsIcon.GrayHeartGrayOutline:
      return { svgIcon: 'cigna-svgs:gray-heart-gray-outline' };
    case CignaSvgsIcon.GreenCheck:
      return { svgIcon: 'cigna-svgs:green-check' };
    case CignaSvgsIcon.GreyCross:
      return { svgIcon: 'cigna-svgs:grey-cross' };
    case CignaSvgsIcon.GreyOval:
      return { svgIcon: 'cigna-svgs:grey-oval' };
    case CignaSvgsIcon.HandDocument:
      return { svgIcon: 'cigna-svgs:hand-document' };
    case CignaSvgsIcon.HcCoverageIcon:
      return { svgIcon: 'cigna-svgs:hc-coverage-icon' };
    case CignaSvgsIcon.HealthAssessment:
      return { svgIcon: 'cigna-svgs:health-assessment' };
    case CignaSvgsIcon.HealthRewards:
      return { svgIcon: 'cigna-svgs:health-rewards' };
    case CignaSvgsIcon.HeartBlue:
      return { svgIcon: 'cigna-svgs:heart-blue' };
    case CignaSvgsIcon.HeartCircleActive:
      return { svgIcon: 'cigna-svgs:heart-circle-active' };
    case CignaSvgsIcon.HeartCircleInactive:
      return { svgIcon: 'cigna-svgs:heart-circle-inactive' };
    case CignaSvgsIcon.HeartInactive:
      return { svgIcon: 'cigna-svgs:heart-inactive' };
    case CignaSvgsIcon.HomeCare:
      return { svgIcon: 'cigna-svgs:home-care' };
    case CignaSvgsIcon.HomeDeliveryTruck:
      return { svgIcon: 'cigna-svgs:home-delivery-truck' };
    case CignaSvgsIcon.HospitalBlue:
      return { svgIcon: 'cigna-svgs:hospital-blue' };
    case CignaSvgsIcon.HospitalInCircle:
      return { svgIcon: 'cigna-svgs:hospital-in-circle' };
    case CignaSvgsIcon.IconClose:
      return { svgIcon: 'cigna-svgs:icon-close' };
    case CignaSvgsIcon.IconDrugsFree:
      return { svgIcon: 'cigna-svgs:icon-drugs-free' };
    case CignaSvgsIcon.IconMore:
      return { svgIcon: 'cigna-svgs:icon-more' };
    case CignaSvgsIcon.IconPillThickContainer:
      return { svgIcon: 'cigna-svgs:icon-pill-thick-container' };
    case CignaSvgsIcon.IconRefresh:
      return { svgIcon: 'cigna-svgs:icon-refresh' };
    case CignaSvgsIcon.IconSearch:
      return { svgIcon: 'cigna-svgs:icon-search' };
    case CignaSvgsIcon.ImmediateCare:
      return { svgIcon: 'cigna-svgs:immediate-care' };
    case CignaSvgsIcon.InBoxLg:
      return { svgIcon: 'cigna-svgs:in-box-lg' };
    case CignaSvgsIcon.InNetworkCheck:
      return { svgIcon: 'cigna-svgs:in-network-check' };
    case CignaSvgsIcon.InProgress:
      return { svgIcon: 'cigna-svgs:in-progress' };
    case CignaSvgsIcon.InactiveEapChat:
      return { svgIcon: 'cigna-svgs:inactive_eap_chat' };
    case CignaSvgsIcon.Incentive:
      return { svgIcon: 'cigna-svgs:incentive' };
    case CignaSvgsIcon.Infertility:
      return { svgIcon: 'cigna-svgs:infertility' };
    case CignaSvgsIcon.InfoCircleOutline:
      return { svgIcon: 'cigna-svgs:info-circle-outline' };
    case CignaSvgsIcon.Journey:
      return { svgIcon: 'cigna-svgs:journey' };
    case CignaSvgsIcon.LaunchExternal:
      return { svgIcon: 'cigna-svgs:launch-external' };
    case CignaSvgsIcon.LaunchRight:
      return { svgIcon: 'cigna-svgs:launch-right' };
    case CignaSvgsIcon.LeftArrow:
      return { svgIcon: 'cigna-svgs:left-arrow' };
    case CignaSvgsIcon.LeftPointerGreenTriangle:
      return { svgIcon: 'cigna-svgs:left-pointer-green-triangle' };
    case CignaSvgsIcon.LightbulbGreen:
      return { svgIcon: 'cigna-svgs:lightbulb-green' };
    case CignaSvgsIcon.Lightbulb:
      return { svgIcon: 'cigna-svgs:lightbulb' };
    case CignaSvgsIcon.LinkCarat:
      return { svgIcon: 'cigna-svgs:link-carat' };
    case CignaSvgsIcon.Linkedin2:
      return { svgIcon: 'cigna-svgs:linkedin2' };
    case CignaSvgsIcon.ListToggle:
      return { svgIcon: 'cigna-svgs:list-toggle' };
    case CignaSvgsIcon.LiveChatGroup:
      return { svgIcon: 'cigna-svgs:live-chat-group' };
    case CignaSvgsIcon.LiveChat:
      return { svgIcon: 'cigna-svgs:live-chat' };
    case CignaSvgsIcon.LocationIcon:
      return { svgIcon: 'cigna-svgs:location-icon' };
    case CignaSvgsIcon.LockSolid:
      return { svgIcon: 'cigna-svgs:lock-solid' };
    case CignaSvgsIcon.MailBlue:
      return { svgIcon: 'cigna-svgs:mail-blue' };
    case CignaSvgsIcon.MailboxOpen:
      return { svgIcon: 'cigna-svgs:mailbox-open' };
    case CignaSvgsIcon.MapMarkerSolid:
      return { svgIcon: 'cigna-svgs:map-marker-solid' };
    case CignaSvgsIcon.MapMarkerWhite:
      return { svgIcon: 'cigna-svgs:map-marker-white' };
    case CignaSvgsIcon.MapToggle:
      return { svgIcon: 'cigna-svgs:map-toggle' };
    case CignaSvgsIcon.Mastercard:
      return { svgIcon: 'cigna-svgs:mastercard' };
    case CignaSvgsIcon.MaternityStroller:
      return { svgIcon: 'cigna-svgs:maternity-stroller' };
    case CignaSvgsIcon.MdLive:
      return { svgIcon: 'cigna-svgs:md-live' };
    case CignaSvgsIcon.MedEquipment:
      return { svgIcon: 'cigna-svgs:med-equipment' };
    case CignaSvgsIcon.Media:
      return { svgIcon: 'cigna-svgs:media' };
    case CignaSvgsIcon.MinusSign:
      return { svgIcon: 'cigna-svgs:minus-sign' };
    case CignaSvgsIcon.MinusSymbol:
      return { svgIcon: 'cigna-svgs:minus-symbol' };
    case CignaSvgsIcon.MoreInfo:
      return { svgIcon: 'cigna-svgs:more-info' };
    case CignaSvgsIcon.Mute:
      return { svgIcon: 'cigna-svgs:mute' };
    case CignaSvgsIcon.NewBanner:
      return { svgIcon: 'cigna-svgs:new-banner' };
    case CignaSvgsIcon.Newspaper:
      return { svgIcon: 'cigna-svgs:newspaper' };
    case CignaSvgsIcon.Next:
      return { svgIcon: 'cigna-svgs:next' };
    case CignaSvgsIcon.NinetyDayMedicationPam:
      return { svgIcon: 'cigna-svgs:ninety-day-medication-pam' };
    case CignaSvgsIcon.NinetyDayMedication:
      return { svgIcon: 'cigna-svgs:ninety-day-medication' };
    case CignaSvgsIcon.NoChange:
      return { svgIcon: 'cigna-svgs:no-change' };
    case CignaSvgsIcon.NoPatientsAlert:
      return { svgIcon: 'cigna-svgs:no-patients-alert' };
    case CignaSvgsIcon.OnboardingBehavioralSa:
      return { svgIcon: 'cigna-svgs:onboarding-behavioral-sa' };
    case CignaSvgsIcon.OnboardingBehavioral:
      return { svgIcon: 'cigna-svgs:onboarding-behavioral' };
    case CignaSvgsIcon.OnboardingDental:
      return { svgIcon: 'cigna-svgs:onboarding-dental' };
    case CignaSvgsIcon.OnboardingEap:
      return { svgIcon: 'cigna-svgs:onboarding-eap' };
    case CignaSvgsIcon.OnboardingHearing:
      return { svgIcon: 'cigna-svgs:onboarding-hearing' };
    case CignaSvgsIcon.OnboardingMedical:
      return { svgIcon: 'cigna-svgs:onboarding-medical' };
    case CignaSvgsIcon.OnboardingRx:
      return { svgIcon: 'cigna-svgs:onboarding-rx' };
    case CignaSvgsIcon.OnboardingSupplemental:
      return { svgIcon: 'cigna-svgs:onboarding-supplemental' };
    case CignaSvgsIcon.OnboardingVision:
      return { svgIcon: 'cigna-svgs:onboarding-vision' };
    case CignaSvgsIcon.OnlineBlue:
      return { svgIcon: 'cigna-svgs:online-blue' };
    case CignaSvgsIcon.OvalThumbsUp:
      return { svgIcon: 'cigna-svgs:oval-thumbs-up' };
    case CignaSvgsIcon.Oval:
      return { svgIcon: 'cigna-svgs:oval' };
    case CignaSvgsIcon.PamSdpBanner:
      return { svgIcon: 'cigna-svgs:pam-sdp-banner' };
    case CignaSvgsIcon.PencilLined:
      return { svgIcon: 'cigna-svgs:pencil-lined' };
    case CignaSvgsIcon.PersonHearing:
      return { svgIcon: 'cigna-svgs:person-hearing' };
    case CignaSvgsIcon.PharmacyBowl:
      return { svgIcon: 'cigna-svgs:pharmacy-bowl' };
    case CignaSvgsIcon.PhoneBlue:
      return { svgIcon: 'cigna-svgs:phone-blue' };
    case CignaSvgsIcon.PhonePlain:
      return { svgIcon: 'cigna-svgs:phone-plain' };
    case CignaSvgsIcon.PhoneVirtual:
      return { svgIcon: 'cigna-svgs:phone-virtual' };
    case CignaSvgsIcon.PhysicalTherapy:
      return { svgIcon: 'cigna-svgs:physical-therapy' };
    case CignaSvgsIcon.Pillar:
      return { svgIcon: 'cigna-svgs:pillar' };
    case CignaSvgsIcon.PinkPiggy:
      return { svgIcon: 'cigna-svgs:pink-piggy' };
    case CignaSvgsIcon.PlusButton:
      return { svgIcon: 'cigna-svgs:plus-button' };
    case CignaSvgsIcon.PlusSign:
      return { svgIcon: 'cigna-svgs:plus-sign' };
    case CignaSvgsIcon.Plus:
      return { svgIcon: 'cigna-svgs:plus' };
    case CignaSvgsIcon.Prescriptions:
      return { svgIcon: 'cigna-svgs:prescriptions' };
    case CignaSvgsIcon.Preventative:
      return { svgIcon: 'cigna-svgs:preventative' };
    case CignaSvgsIcon.ProfileBlue:
      return { svgIcon: 'cigna-svgs:profile-blue' };
    case CignaSvgsIcon.ProfileHead:
      return { svgIcon: 'cigna-svgs:profile-head' };
    case CignaSvgsIcon.ProfilePerson:
      return { svgIcon: 'cigna-svgs:profile-person' };
    case CignaSvgsIcon.ProfilePlaceholderAssociation:
      return { svgIcon: 'cigna-svgs:profile-placeholder-association' };
    case CignaSvgsIcon.ProfilePlaceholderFemale:
      return { svgIcon: 'cigna-svgs:profile-placeholder-female' };
    case CignaSvgsIcon.ProfilePlaceholderMale:
      return { svgIcon: 'cigna-svgs:profile-placeholder-male' };
    case CignaSvgsIcon.ProfilePlaceholderNeutral:
      return { svgIcon: 'cigna-svgs:profile-placeholder-neutral' };
    case CignaSvgsIcon.ProfileSolid:
      return { svgIcon: 'cigna-svgs:profile-solid' };
    case CignaSvgsIcon.Profile:
      return { svgIcon: 'cigna-svgs:profile' };
    case CignaSvgsIcon.ProviderBehavioral:
      return { svgIcon: 'cigna-svgs:provider-behavioral' };
    case CignaSvgsIcon.ProviderDentist:
      return { svgIcon: 'cigna-svgs:provider-dentist' };
    case CignaSvgsIcon.ProviderDermatologist:
      return { svgIcon: 'cigna-svgs:provider-dermatologist' };
    case CignaSvgsIcon.ProviderFemale:
      return { svgIcon: 'cigna-svgs:provider-female' };
    case CignaSvgsIcon.ProviderObgyn:
      return { svgIcon: 'cigna-svgs:provider-obgyn' };
    case CignaSvgsIcon.ProviderOralSurgeon:
      return { svgIcon: 'cigna-svgs:provider-oral-surgeon' };
    case CignaSvgsIcon.ProviderOrthodontist:
      return { svgIcon: 'cigna-svgs:provider-orthodontist' };
    case CignaSvgsIcon.ProviderOrthopedicSurgeon:
      return { svgIcon: 'cigna-svgs:provider-orthopedic-surgeon' };
    case CignaSvgsIcon.ProviderPediatricDentist:
      return { svgIcon: 'cigna-svgs:provider-pediatric-dentist' };
    case CignaSvgsIcon.ProviderPrimaryCare:
      return { svgIcon: 'cigna-svgs:provider-primary-care' };
    case CignaSvgsIcon.ProviderPsychiatrist:
      return { svgIcon: 'cigna-svgs:provider-psychiatrist' };
    case CignaSvgsIcon.ProviderPsychologist:
      return { svgIcon: 'cigna-svgs:provider-psychologist' };
    case CignaSvgsIcon.ProviderTherapist:
      return { svgIcon: 'cigna-svgs:provider-therapist' };
    case CignaSvgsIcon.ProviderVisits:
      return { svgIcon: 'cigna-svgs:provider-visits' };
    case CignaSvgsIcon.PushPinSolid:
      return { svgIcon: 'cigna-svgs:push-pin-solid' };
    case CignaSvgsIcon.QuestionCircle:
      return { svgIcon: 'cigna-svgs:question-circle' };
    case CignaSvgsIcon.QuestionMarkCircle:
      return { svgIcon: 'cigna-svgs:question-mark-circle' };
    case CignaSvgsIcon.RatingStarOn:
      return { svgIcon: 'cigna-svgs:rating-star-on' };
    case CignaSvgsIcon.RedAlert:
      return { svgIcon: 'cigna-svgs:red-alert' };
    case CignaSvgsIcon.ReloadArrow:
      return { svgIcon: 'cigna-svgs:reload-arrow' };
    case CignaSvgsIcon.RemoveHover:
      return { svgIcon: 'cigna-svgs:remove-hover' };
    case CignaSvgsIcon.Remove:
      return { svgIcon: 'cigna-svgs:remove' };
    case CignaSvgsIcon.Reward:
      return { svgIcon: 'cigna-svgs:reward' };
    case CignaSvgsIcon.RightArrow:
      return { svgIcon: 'cigna-svgs:right-arrow' };
    case CignaSvgsIcon.RoundQuestionMark:
      return { svgIcon: 'cigna-svgs:round-question-mark' };
    case CignaSvgsIcon.RtmBox:
      return { svgIcon: 'cigna-svgs:rtm-box' };
    case CignaSvgsIcon.RtmDeliveryTruck:
      return { svgIcon: 'cigna-svgs:rtm-delivery-truck' };
    case CignaSvgsIcon.RxOnboardingPil:
      return { svgIcon: 'cigna-svgs:rx-onboarding-pil' };
    case CignaSvgsIcon.RxTruck:
      return { svgIcon: 'cigna-svgs:rx-truck' };
    case CignaSvgsIcon.SearchMachine:
      return { svgIcon: 'cigna-svgs:search-machine' };
    case CignaSvgsIcon.SearchThin:
      return { svgIcon: 'cigna-svgs:search-thin' };
    case CignaSvgsIcon.Settings:
      return { svgIcon: 'cigna-svgs:settings' };
    case CignaSvgsIcon.ShieldSolid:
      return { svgIcon: 'cigna-svgs:shield-solid' };
    case CignaSvgsIcon.ShippingBox:
      return { svgIcon: 'cigna-svgs:shipping-box' };
    case CignaSvgsIcon.SingleChatBubble:
      return { svgIcon: 'cigna-svgs:single-chat-bubble' };
    case CignaSvgsIcon.SmallIdCard:
      return { svgIcon: 'cigna-svgs:small-id-card' };
    case CignaSvgsIcon.SoundOff:
      return { svgIcon: 'cigna-svgs:sound-off' };
    case CignaSvgsIcon.SoundOn:
      return { svgIcon: 'cigna-svgs:sound-on' };
    case CignaSvgsIcon.Sound:
      return { svgIcon: 'cigna-svgs:sound' };
    case CignaSvgsIcon.StarEmpty:
      return { svgIcon: 'cigna-svgs:star-empty' };
    case CignaSvgsIcon.StarFilled:
      return { svgIcon: 'cigna-svgs:star-filled' };
    case CignaSvgsIcon.StepCompleteCheck:
      return { svgIcon: 'cigna-svgs:step-complete-check' };
    case CignaSvgsIcon.StepDenial:
      return { svgIcon: 'cigna-svgs:step-denial' };
    case CignaSvgsIcon.StepFourComplete:
      return { svgIcon: 'cigna-svgs:step-four-complete' };
    case CignaSvgsIcon.StepFourIncomplete:
      return { svgIcon: 'cigna-svgs:step-four-incomplete' };
    case CignaSvgsIcon.StepThreeComplete:
      return { svgIcon: 'cigna-svgs:step-three-complete' };
    case CignaSvgsIcon.StepThreeIncomplete:
      return { svgIcon: 'cigna-svgs:step-three-incomplete' };
    case CignaSvgsIcon.StepTwoComplete:
      return { svgIcon: 'cigna-svgs:step-two-complete' };
    case CignaSvgsIcon.StepTwoIncomplete:
      return { svgIcon: 'cigna-svgs:step-two-incomplete' };
    case CignaSvgsIcon.Store:
      return { svgIcon: 'cigna-svgs:store' };
    case CignaSvgsIcon.Support:
      return { svgIcon: 'cigna-svgs:support' };
    case CignaSvgsIcon.Surgeries:
      return { svgIcon: 'cigna-svgs:surgeries' };
    case CignaSvgsIcon.Teletypewriter:
      return { svgIcon: 'cigna-svgs:teletypewriter' };
    case CignaSvgsIcon.ThumbsDownBlk:
      return { svgIcon: 'cigna-svgs:thumbs-down-blk' };
    case CignaSvgsIcon.ThumbsDownRed:
      return { svgIcon: 'cigna-svgs:thumbs-down-red' };
    case CignaSvgsIcon.ThumbsUpBlk:
      return { svgIcon: 'cigna-svgs:thumbs-up-blk' };
    case CignaSvgsIcon.ThumbsUpBlue:
      return { svgIcon: 'cigna-svgs:thumbs-up-blue' };
    case CignaSvgsIcon.ThumbsUpGreen:
      return { svgIcon: 'cigna-svgs:thumbs-up-green' };
    case CignaSvgsIcon.ThumbsUpSolid:
      return { svgIcon: 'cigna-svgs:thumbs-up-solid' };
    case CignaSvgsIcon.ToggleMenu:
      return { svgIcon: 'cigna-svgs:toggle-menu' };
    case CignaSvgsIcon.ToothHeart:
      return { svgIcon: 'cigna-svgs:tooth-heart' };
    case CignaSvgsIcon.TopDentistsCalendar:
      return { svgIcon: 'cigna-svgs:top-dentists-calendar' };
    case CignaSvgsIcon.Truck:
      return { svgIcon: 'cigna-svgs:truck' };
    case CignaSvgsIcon.Twitter2:
      return { svgIcon: 'cigna-svgs:twitter2' };
    case CignaSvgsIcon.Unavailable:
      return { svgIcon: 'cigna-svgs:unavailable' };
    case CignaSvgsIcon.VaIcon:
      return { svgIcon: 'cigna-svgs:va-icon' };
    case CignaSvgsIcon.VirtualCounseling:
      return { svgIcon: 'cigna-svgs:virtual-counseling' };
    case CignaSvgsIcon.Visa:
      return { svgIcon: 'cigna-svgs:visa' };
    case CignaSvgsIcon.Vision:
      return { svgIcon: 'cigna-svgs:vision' };
    case CignaSvgsIcon.WellnessBlue:
      return { svgIcon: 'cigna-svgs:wellness-blue' };
    case CignaSvgsIcon.WhiteGraphicThumbsUp:
      return { svgIcon: 'cigna-svgs:white-graphic-thumbs-up' };
    case CignaSvgsIcon.WhiteHeartGrayOutline:
      return { svgIcon: 'cigna-svgs:white-heart-gray-outline' };
    case CignaSvgsIcon.WorkLife:
      return { svgIcon: 'cigna-svgs:work-life' };
    case CignaSvgsIcon.WriteReview:
      return { svgIcon: 'cigna-svgs:write-review' };
    case CignaSvgsIcon.XCircleOutline:
      return { svgIcon: 'cigna-svgs:x-circle-outline' };
    case CignaSvgsIcon.XRay:
      return { svgIcon: 'cigna-svgs:x-ray' };
    case CignaSvgsIcon.YellowAlertTriangleLine:
      return { svgIcon: 'cigna-svgs:yellow-alert-triangle-line' };
    case CignaSvgsIcon.Youtube2:
      return { svgIcon: 'cigna-svgs:youtube2' };
    case CignaSvgsIcon.ThumbsDown:
      return { classes: ['flip-v'], svgIcon: 'cigna-svgs:thumbs-up' };
    case CignaSvgsIcon.CaretUp:
      return { classes: ['flip-v'], svgIcon: 'cigna-svgs:caret-down' };
    case CignaSvgsIcon.CaretLeft:
      return { classes: ['flip-h'], svgIcon: 'cigna-svgs:caret-right' };
    case CignaSvgsIcon.ChevronCircleUp:
      return {
        classes: ['rotate-cw'],
        svgIcon: 'cigna-svgs:chevron-circle-right',
      };
    case CignaSvgsIcon.ChevronCircleDown:
      return {
        classes: ['rotate-ccw'],
        svgIcon: 'cigna-svgs:chevron-circle-right',
      };
    case CignaSvgsIcon.ChevronCircleLeft:
      return {
        classes: ['flip-h'],
        svgIcon: 'cigna-svgs:chevron-circle-right',
      };
    case CignaSvgsIcon.ChevronUp:
      return { classes: ['rotate-cw'], svgIcon: 'cigna-svgs:chevron-right' };
    case CignaSvgsIcon.ChevronDown:
      return { classes: ['rotate-ccw'], svgIcon: 'cigna-svgs:chevron-right' };
    case CignaSvgsIcon.ChevronLeft:
      return { classes: ['flip-h'], svgIcon: 'cigna-svgs:chevron-right' };
    case CignaSvgsIcon.ChevronThinUp:
      return {
        classes: ['rotate-cw'],
        svgIcon: 'cigna-svgs:chevron-thin-right',
      };
    case CignaSvgsIcon.ChevronThinDown:
      return {
        classes: ['rotate-ccw'],
        svgIcon: 'cigna-svgs:chevron-thin-right',
      };
    case CignaSvgsIcon.ChevronThinLeft:
      return { classes: ['flip-h'], svgIcon: 'cigna-svgs:chevron-thin-right' };
    case CignaSvgsIcon.BlueThumbsDown:
      return { classes: ['flip-v'], svgIcon: 'cigna-svgs:blue-thumbs-up' };
    case CignaSvgsIcon.DollarUp:
      return { classes: ['flip-v'], svgIcon: 'cigna-svgs:dollar-down' };
    case CignaSvgsIcon.LaunchUp:
      return { classes: ['rotate-cw'], svgIcon: 'cigna-svgs:launch-right' };
    case CignaSvgsIcon.LaunchDown:
      return { classes: ['rotate-ccw'], svgIcon: 'cigna-svgs:launch-right' };
    case CignaSvgsIcon.LaunchLeft:
      return { classes: ['flip-h'], svgIcon: 'cigna-svgs:launch-right' };
    case CignaSvgsIcon.OvalThumbsDown:
      return { classes: ['flip-v'], svgIcon: 'cigna-svgs:oval-thumbs-up' };
    case CignaSvgsIcon.WhiteGraphicThumbsDown:
      return {
        classes: ['flip-v'],
        svgIcon: 'cigna-svgs:white-graphic-thumbs-up',
      };
  }
}
