/* eslint-disable sonarjs/cognitive-complexity */
import { throwError, Observable } from 'rxjs';
import { gzip } from 'pako';

export const apiGatewayTimeout = 30 * 1000;
export const isamApiTimeout = 45 * 1000;

export interface Error {
  status: string;
  code: string;
}

export interface ServiceError {
  errMsg: string;
  status: number;
  errObject: any | CignaStandardOutcome;
}

export interface CignaStandardOutcome {
  status?: number;
  type?: string;
  message?: string;
  code?: number;
  developerMessage?: string;
  moreInfo?: string;
  additionalDetails?: CignaOutcomeAdditionalDetails[];
}

export interface CignaOutcomeAdditionalDetails {
  severity: string;
  message: string;
  code: number;
}

export const openResponseInNewTab = (binaryData: any) => {
  const fileURL = window.URL.createObjectURL(
    new Blob([binaryData], { type: 'application/pdf' }),
  );
  openURLInNewTab(fileURL);
};

export const openURLInNewTab = (fileUrl: string) => {
  const tab = window.open();
  if (tab) {
    tab.location.href = fileUrl;
  }
};

export const compressPayload = (requestBody: object): Blob =>
  new Blob([gzip(JSON.stringify(requestBody))]);

export const checkValidResponse = (response: any) => {
  if (response && response.metadata) {
    const meta = response.metadata.outcome;

    if (meta.status === 200) {
      return response;
    }
    throw meta;
  } else {
    throw new Error();
  }
};

export function downloadCsv(data: string, filename: string) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export const logError = (error: any): Observable<ServiceError> => {
  let errMsg: string;
  let status: number;

  if (error) {
    const body = error;
    const err = body.error || JSON.stringify(body);
    if (err?.metadata?.outcome) {
      return throwError({
        errObject: err.metadata.outcome,
        status: err.metadata.outcome.status,
        errMsg: err.metadata.outcome.message,
      });
    }
    status = error.status || 500;

    if (
      (typeof error.additionalDetails !== 'undefined' ||
        typeof error.developerMessage !== 'undefined') &&
      typeof error.message !== 'undefined' &&
      typeof error.code !== 'undefined'
    ) {
      // this is cigna outcome status error
      errMsg = `${error.message}`;
    } else {
      // this is for http standard error
      errMsg = error.statusText || err;
    }
  } else {
    errMsg = error.message ? error.message : error.toString();
    status = 500;
  }

  return throwError({
    errMsg,
    status,
    errObject: error,
  });
};
