import { InjectionToken } from '@angular/core';

export const ENVIRONMENT_CONFIG = new InjectionToken<Config>(
  'environmentConfig',
);

export interface OauthConfig {
  audience: string;
  authorizeUri: string;
  client_id: string;
  response_type: string;
  state: string;
  scope: string;
  redirect_uri: string;
  code_challenge_method: string;
  response_mode: string;
  grant_type: string;
  post_logout_redirect_uri: string;
}

export interface ExternalSites {
  mycignaCommPreferences?: string;
  myCignaDirectory?: string;
  myCignaDomain?: string;
}

export interface Config {
  production: boolean;
  api: {
    gateways: {
      cdn: string;
      dcmProfile: string;
      communicationPreferencesUrl: string;
      auth0Auth: string;
      resourceContent: string;
      dcmCustomer: string;
      customerProfile: string;
      activity: string;
      elevateSurvey: string;
    };
  };
  launchDarklyClientId: string;
  oauth: {
    auth0: OauthConfig;
  };
  /**
   * Uris that require authentication.
   *
   * Each entry can contain RegExp statements in a string form.
   */
  secureUris: string[];
  /**
   * Uris that don't require authentication.
   * Authorization header won't be added to such requests.
   * This setting takes precedence over `secureUris` i.e. if a certain
   * request matches both, it will be treated as public.
   *
   * Each entry can contain RegExp statements in a string form.
   */
  publicUris?: string[];
  externalSites: ExternalSites;
  omni: {
    sonicNotification: string;
    identityUrl: string;
    botUrl: string;
    liveUrl: string;
    publicUrl: string;
  };
}

export const CDN_BASE_URL = new InjectionToken<string>('CDN_BASE_URL');
