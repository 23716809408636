import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent {
  @Input() text: string;
  @Input() type: 'success' | 'warning' | 'error' | 'info';
  @Input() style: 'normal' | 'bold' = 'normal';
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() hasIcon = false;
}
