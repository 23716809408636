<div class="filter-header" data-test="filter-label">
  <strong>{{ filterName }}</strong>
  <button
    (click)="labelClick.emit()"
    cignaButton="link"
    style="padding: 0 0.5em"
    data-test="filter-label-button"
    [title]="selectionText"
  >
    <span data-test="filter-label-text">{{ selectionText }}</span>
    <cigna-icon class="cg-margin-left-xs" type="caret-down"></cigna-icon>
  </button>
</div>
