<div class="alerts-wrapper cg-hide-for-print">
  <div class="alerts-inner">
    <div>
      <mat-icon
        *ngIf="alert"
        svgIcon="leaf-system-filled-warning"
        class="alert-icon cg-color-warn"
      ></mat-icon>
    </div>
    <div class="alert-text" *ngIf="alert">
      <strong
        *ngIf="alert.header"
        data-test="alert-header"
        class="alert-header"
        >{{ alert.header }}</strong
      >
      <div
        data-test="alert-body"
        (click)="alertClick($event)"
        [innerHtml]="alert.body"
        class="alert-body"
      ></div>
    </div>
  </div>
</div>
