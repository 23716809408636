import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import {
  take,
  switchMap,
  retryWhen,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AuthFacade } from './+state/auth.facade';
import { AuthConfig, AUTH_CONFIG } from './config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _secureUris: RegExp[];
  private _publicUris: RegExp[];
  private _noRefreshUris: RegExp[];
  private _retriedRequests: Record<string, boolean | undefined> = {};

  constructor(
    private _authFacade: AuthFacade,
    @Inject(AUTH_CONFIG)
    { secureUris, publicUris = [], noRefreshUris = [] }: AuthConfig,
  ) {
    this._secureUris = secureUris.map((u) => new RegExp(u, 'i'));
    this._publicUris = publicUris.map((u) => new RegExp(u, 'i'));
    this._noRefreshUris = noRefreshUris.map((u) => new RegExp(u, 'i'));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      this._publicUris.some((u) => u.test(req.urlWithParams)) ||
      !this._secureUris.some((u) => u.test(req.urlWithParams))
    ) {
      return next.handle(req);
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const noRefresh = this._noRefreshUris.some((u) =>
      u.test(req.urlWithParams),
    );

    const authData$ = this._authFacade.authDataSnapshot$.pipe(
      take(1),
      withLatestFrom(
        this._authFacade.errorSnapshot$,
        this._authFacade.isLoading$,
      ),
      switchMap(([authData, error, isLoading]) =>
        authData
          ? of(authData)
          : of(undefined).pipe(
              tap(() => {
                if (!error && !isLoading) {
                  // no auth data and no error - means it's initial state
                  this._authFacade.authenticate();
                }
              }),
              switchMap(() => this._authFacade.authData$),
              take(1),
            ),
      ),
    );

    return authData$.pipe(
      switchMap(({ accessToken }) =>
        next.handle(
          req.clone({
            setHeaders: { Authorization: `Bearer ${accessToken}` },
          }),
        ),
      ),
      retryWhen((error$) =>
        error$.pipe(
          mergeMap((error: HttpErrorResponse) =>
            this.retryFunction(error, noRefresh),
          ),
        ),
      ),
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.handleSuccessfulRequest(req.url);
        }
      }),
    );
  }

  retryFunction(error: HttpErrorResponse, noRefresh: boolean) {
    if (error.status === 401 && !this.isRetriedRequest(error.url)) {
      if (noRefresh) {
        return of(undefined).pipe(
          tap(() => this._authFacade.unauthorizedError()),
          switchMap(() => throwError(() => error)),
        );
      }
      return of(undefined).pipe(
        tap(() => {
          if (error.url) {
            this.trackRetriedRequest(error.url);
          }
          this._authFacade.authenticate();
        }),
      );
    }
    return throwError(() => error);
  }

  getRetriedRequestKey(url: string) {
    return url.split('?')[0];
  }

  trackRetriedRequest(url: string) {
    this._retriedRequests[this.getRetriedRequestKey(url)] = true;
  }

  isRetriedRequest(url: string | null): boolean {
    return !!(url && this._retriedRequests[this.getRetriedRequestKey(url)]);
  }

  handleSuccessfulRequest(url: string): void {
    delete this._retriedRequests[this.getRetriedRequestKey(url)];
  }
}
