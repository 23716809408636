import { Component } from '@angular/core';
import { WindowService } from '@cigna/shared/angular/core/window-util';

@Component({
  selector: 'cigna-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss'],
})
export class UnavailableComponent {
  constructor(private window: WindowService) {}

  refreshBrowser() {
    this.window.location.reload();
  }
}
