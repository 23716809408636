<div data-omni-qa="omni-icon-chat-bubble" *cignaResponsive="let screen">
  <svg
    [attr.width]="screen.isSmallerOrEqual('xs') ? '60px' : '88px'"
    [attr.height]="screen.isSmallerOrEqual('xs') ? '60px' : '90px'"
    viewBox="0 0 88 90"
  >
    <defs>
      <circle id="path-1" cx="9" cy="9" r="9"></circle>
      <circle id="path-2" cx="13.5" cy="13.5" r="13.5"></circle>
      <filter
        x="-25.0%"
        y="-13.9%"
        width="138.9%"
        height="138.9%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset
          dx="-1"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
      <path
        d="M1803,472.845622 C1803,480.202359 1805.17242,487.306942 1809.28625,493.394056 C1809.83268,494.199183 1809.99262,495.20338 1809.73495,496.141221 L1805.78673,510.124924 C1805.48659,511.187935 1806.10503,512.292984 1807.16804,512.593119 C1807.58798,512.711687 1808.03517,512.689661 1808.44143,512.530402 L1823.12043,506.775975 C1823.93342,506.457464 1824.83526,506.470735 1825.63937,506.806942 C1830.18413,508.718013 1835.01765,509.686819 1840.00222,509.686819 C1860.40253,509.686819 1877,493.159596 1877,472.845622 C1877,452.527223 1860.40253,436 1840.00222,436 C1819.59747,436 1803,452.527223 1803,472.845622 Z"
        id="chatBubblePath"
      ></path>
      <filter
        x="-16.7%"
        y="-16.1%"
        width="133.4%"
        height="132.1%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feOffset
          dx="0"
          dy="0"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="CHAT-ICON---WEB"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(-1795.000000, -428.000000)" fill-rule="nonzero">
        <g
          id="Path"
          transform="translate(1840.000000, 475.000000) scale(-1, 1) translate(-1840.000000, -475.000000) "
        >
          <use
            fill="black"
            fill-opacity="1"
            filter="url(#filter-2)"
            xlink:href="#chatBubblePath"
          ></use>
          <use
            data-omni-qa="omni-chat-bubble-color"
            [attr.fill]="backgroundColor"
            [attr.stroke]="strokeColor"
            [attr.filter]="dropShadow"
            xlink:href="#chatBubblePath"
          ></use>
        </g>
      </g>
      <text
        [attr.font-size]="screen.isSmallerOrEqual('xs') ? '12px' : '14px'"
        font-family="Montserrat, sans-serif"
        [attr.font-weight]="screen.isSmallerOrEqual('xs') ? 'bold' : 'normal'"
        line-spacing="16"
        data-omni-qa="omni-icon-chat-bubble-text-line-one"
        [attr.fill]="textColor"
      >
        <tspan
          x="51%"
          [attr.y]="screen.isSmallerOrEqual('xs') ? '46' : '39'"
          text-anchor="middle"
          dy="0.6ex"
        >
          {{ data?.lineOne }}
        </tspan>
      </text>
      <text
        [attr.font-size]="screen.isSmallerOrEqual('xs') ? '12px' : '14px'"
        font-family="Montserrat, sans-serif"
        [attr.font-weight]="screen.isSmallerOrEqual('xs') ? 'bold' : 'normal'"
        line-spacing="16"
        data-omni-qa="omni-icon-chat-bubble-text-line-two"
        [attr.fill]="textColor"
      >
        <tspan x="51%" y="55" text-anchor="middle" dy="0.6ex">
          {{ data?.lineTwo }}
        </tspan>
      </text>
    </g>

    <g
      id="SYMBOLS"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      *ngIf="data?.showNotificationBadge"
    >
      <g id="Orb-/-TWU-Badge" transform="translate(0, 4.000000)">
        <g id="Group" transform="translate(58.000000, 4.000000)">
          <g id="Oval-4" fill-rule="nonzero">
            <use
              fill="black"
              fill-opacity="1"
              filter="url(#filter-1)"
              [attr.xlink:href]="
                screen.isSmallerOrEqual('xs') ? '#path-2' : '#path-1'
              "
            ></use>
            <use
              fill="#E35205"
              [attr.xlink:href]="
                screen.isSmallerOrEqual('xs') ? '#path-2' : '#path-1'
              "
              data-omni-qa="omni-icon-chat-bubble-badge"
            ></use>
          </g>
          <text
            id="1"
            font-family="Montserrat, sans-serif"
            [attr.font-size]="screen.isSmallerOrEqual('xs') ? '12px' : '14px'"
            font-weight="400"
            line-spacing="16"
            fill="#FFFFFF"
          >
            <tspan
              [attr.x]="screen.isSmallerOrEqual('xs') ? '16%' : '10%'"
              [attr.y]="screen.isSmallerOrEqual('xs') ? '15' : '9.75'"
              text-anchor="middle"
              dy="0.6ex"
              data-omni-qa="omni-icon-chat-bubble-badge-count"
            >
              {{ data?.badgeCount }}
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</div>
