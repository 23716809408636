import { HttpErrorResponse } from '@angular/common/http';

interface InnerError {
  message?: string;
  error?: string;
}

export class AuthError extends Error {
  constructor(private _inner: InnerError, private _sourceError?: unknown) {
    super(_inner.message);

    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AuthError.prototype);
  }

  isLoginRequired(): boolean {
    return this._inner.error === 'login_required';
  }

  isFrameTimeout(): boolean {
    return this._inner.message === 'Frame window timed out';
  }

  isOffline(): boolean {
    return (
      (this._sourceError instanceof HttpErrorResponse &&
        this._sourceError.status === 0) ||
      this._inner.message === 'Network Error'
    );
  }
}
