// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// eslint-disable-next-line @nx/enforce-module-boundaries
import { featuresEnabled } from '@cigna/shared/angular/features-feature';
import { chatQuery } from '@cigna/omni/chat-state-data-access';
import { FancyInvitationsSelectors } from '@cigna/omni/fancy-invitations-state-data-access';
import { liveAvailabilityQuery } from '@cigna/omni/live-availability-state-data-access';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  adapter,
  InterceptionsState,
  INTERCEPTIONS_FEATURE_KEY,
} from './interceptions.reducer';

const selectInterceptions = createFeatureSelector<InterceptionsState>(
  INTERCEPTIONS_FEATURE_KEY,
);

const { selectAll } = adapter.getSelectors();

const selectAllInterceptions = createSelector(selectInterceptions, selectAll);

const selectVisibleInterceptions = createSelector(
  selectInterceptions,
  selectAllInterceptions,
  (state, interceptions) =>
    interceptions.filter((i) => !state.dismissed.find((d) => d === i._id)),
);

const selectInterceptionsDisplayMax = createSelector(
  selectInterceptions,
  (interceptions) => interceptions.displayMax,
);

const selectInterceptionsToPresent = createSelector(
  selectVisibleInterceptions,
  selectInterceptionsDisplayMax,
  (queue, max) => queue.slice(0, max),
);

const shouldShowInterceptions = createSelector(
  featuresEnabled(['useRightNowChat']),
  featuresEnabled(['asyncSupportChat']),
  liveAvailabilityQuery.getLiveChatAvailable,
  chatQuery.isChatClosed,
  FancyInvitationsSelectors.unviewedFancyInvitationConv,
  (
    useRightNowChat,
    asyncSupport,
    liveAvailable,
    isClosed,
    fancyInvitationConv,
  ) =>
    !useRightNowChat &&
    (asyncSupport || liveAvailable) &&
    isClosed &&
    !fancyInvitationConv,
);

export const interceptionsQuery = {
  selectAllInterceptions,
  selectVisibleInterceptions,
  selectInterceptionsToPresent,
  selectInterceptionsDisplayMax,
  shouldShowInterceptions,
};
