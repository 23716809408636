import { Pipe, PipeTransform, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ENVIRONMENT_CONFIG, EnvironmentConfig, APP_CONTEXT } from './config';

@Pipe({
  name: 'cdnPrefix',
})
export class CdnPrefixPipe implements PipeTransform {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private _config: EnvironmentConfig,
    @Inject(APP_CONTEXT) private appContext: string,
  ) {}

  transform(path: string, context: 'spa' | 'root' = 'spa'): string {
    return Location.joinWithSlash(
      `${this._config.api.gateways.cdn}${
        context === 'spa' && this._config.api.gateways.cdn !== ''
          ? `spa/${
              this.appContext === 'evernorth'
                ? 'evernorth-provider'
                : this.appContext
            }/`
          : ''
      }`,
      path,
    );
  }
}
