// eslint-disable-next-line max-classes-per-file
import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[cignaModalLayoutTitle]',
})
export class ModalLayoutTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[cignaModalLayoutSubTitle]',
})
export class ModalLayoutSubTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[cignaModalLayoutButtons]',
})
export class ModalLayoutButtonsDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
