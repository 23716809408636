import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'cigna-collapsible-group',
  templateUrl: './collapsible-group.component.html',
  styleUrls: ['./collapsible-group.component.css'],
})
export class CollapsibleGroupComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() showAction = true;
  @Input() expandLabel = 'Expand';
  @Input() collapseLabel = 'Collapse';
  @Input() groupLabel = 'all';

  readonly _openCloseAllActions: Subject<boolean> = new Subject<boolean>();

  @Input()
  get expanded(): boolean {
    return this._allExpanded;
  }

  set expanded(expanded: boolean) {
    if (this._allExpanded !== expanded) {
      this._allExpanded = expanded;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _allExpanded = false;

  toggleAll(): void {
    this.expanded = !this.expanded;
    this.openCloseAll(this.expanded);
  }

  // not used right now
  openAll(): void {
    this.openCloseAll(true);
  }

  // not used right now
  closeAll(): void {
    this.openCloseAll(false);
  }

  private openCloseAll(expanded: boolean): void {
    this._openCloseAllActions.next(expanded);
  }
}
