import { Component, Input, OnChanges } from '@angular/core';
import fromPairs from 'lodash/fromPairs';

import { IconConfig } from './icon-config.interface';
import {
  CignaSvgsIcon as CignaIcon,
  cignaSvgsConfig,
} from './generated/cigna-svgs';

/**
 * SVG icons that behave like icon fonts, taking their size and color from the
 * CSS `font-size` and `color` properties.
 *
 * The icons are SVG `symbol`s, loaded from the file `assets/images/icons.svg`,
 * which is automatically generated from the SVG files in the [`cigna-svgs`
 * repository][1] and the `libs/ui/icon/src/extra-icons` directory.
 *
 * To add your icon:
 * 1. Copy svg icon to `libs/ui/icon/src/extra-icons` directory
 * 2. File name is a name of new icon (naming convention is lower case dash delimited)
 * 3. Run script `tools/scripts/generate-svg-icons.js` or `yarn icons` that will clone the repository
 * and generate the symbols.
 *
 * [1]: https://git.sys.cigna.com/gsg-gcms-cigna-web/cigna-svgs
 */

@Component({
  selector: 'cigna-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
  CignaIcon = CignaIcon;
  @Input() type: CignaIcon;
  icon: IconConfig;
  classes: { [cls: string]: true };

  ngOnChanges() {
    this.icon = iconConfig(this.type);
    this.classes = fromPairs((this.icon.classes || []).map((c) => [c, true]));
  }
}

export { CignaIcon };
export const ALL_ICONS: CignaIcon[] = Object.values(CignaIcon).filter(
  (x) => typeof x === 'string',
);

const NO_ICON: IconConfig = {};

function iconConfig(icon: CignaIcon): IconConfig {
  return cignaSvgsConfig(icon) || NO_ICON;
}
