// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
import { chatQuery } from '@cigna/omni/chat-state-data-access';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
import { conversationsQuery } from '@cigna/omni/conversations-state-data-access';
import { liveAvailabilityQuery } from '@cigna/omni/live-availability-state-data-access';
import { createSelector } from '@ngrx/store';
import { featuresEnabled } from '@cigna/shared/angular/features-feature';

const isLiveChatOpen = createSelector(
  chatQuery.getChatState,
  conversationsQuery.selectConversation,
  (chatState, conversation) =>
    chatState !== 'closed' &&
    !!conversation &&
    conversation.type === 'live' &&
    conversation.state !== 'closed',
);

const linkToDisplay = createSelector(
  isLiveChatOpen,
  liveAvailabilityQuery.getLiveChatAvailable,
  featuresEnabled(['generalChat']),

  (liveChatOpen, liveChatAvailable, generalChat) =>
    liveChatOpen
      ? 'liveChatOpen'
      : liveChatAvailable && generalChat
      ? 'activeChatLink'
      : 'liveChatUnavailable',
);

export const chatLinkQuery = {
  isLiveChatOpen,
  linkToDisplay,
};
