import { Store, select } from '@ngrx/store';

import { UserOptionsState } from './user-options.reducer';
import { GetLookUpData } from './user-options.actions';
import { userOptionsQuery } from './user-options.selectors';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserOptionsFacade {
  jobRoles$ = this.store$.pipe(select(userOptionsQuery.getJobRoles));
  securityQuestions$ = this.store$.pipe(
    select(userOptionsQuery.getSecurityQuestions),
  );

  constructor(private store$: Store<UserOptionsState>) {}

  getUserOptions(): void {
    this.store$.dispatch(new GetLookUpData());
  }
}
