import { Injectable, isDevMode, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import jwt_decode from 'jwt-decode';

import {
  AuthPartialState,
  authActions,
  AuthFacade as CignaAuthFacade,
} from '@cigna/shared/angular/auth-data-access';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppContext } from '@cigna/chcp/shared/util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';
import {
  EntitlementLob,
  Entitlement,
  ALL_ENTITLEMENTS,
  LOB_MEDBH,
  LOB_BEHAVIORAL,
  LOB_DENTAL,
  LOB_MEDICAL,
  LOB,
} from '@cigna/chcp/auth/util';

import { authQuery } from './auth.selectors';
import { AccessToken } from '../auth/access-token.model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  // identity token
  userFullName$ = this.store$.pipe(select(authQuery.getUserFullName));

  // access token
  isAuthLoading$ = this.store$.pipe(select(authQuery.isAuthLoading));
  isUserAuthenticated$ = this.store$.pipe(
    select(authQuery.isUserAuthenticated),
  );
  lob$ = this.store$.pipe(select(authQuery.getUserLob));
  lobId$ = this.store$.pipe(select(authQuery.getUserLobId));
  lobForHexApi$ = this.store$.pipe(select(authQuery.getLobHexApiPrefix));
  userid$ = this.store$.pipe(select(authQuery.getUserId));
  useridEncrypted$ = this.store$.pipe(select(authQuery.getUserIdEncrypted));
  userdn$ = this.store$.pipe(select(authQuery.getUserSub));
  isMedicalUser$ = this.store$.pipe(select(authQuery.isMedicalUser));
  isDentalUser$ = this.store$.pipe(select(authQuery.isDentalUser));
  isBehavioralUser$ = this.store$.pipe(select(authQuery.isBehUser));
  isSalesDemoUser$ = this.store$.pipe(select(authQuery.isSalesDemoUser));
  operatorRole$ = this.store$.pipe(select(authQuery.operatorRole));
  operatorId$ = this.store$.pipe(select(authQuery.operatorId));
  isImpersonator$ = this.store$.pipe(select(authQuery.isImpersonator));
  isChatAllowedImpersonator$ = this.store$.pipe(
    select(authQuery.isChatAllowedImpersonator),
  );
  permissionToggles$ = this.store$.pipe(select(authQuery.getPermissionToggles));
  isRestrictedImpersonator$ = this.store$.pipe(
    select(authQuery.isRestrictedImpersonator),
  );
  isUnRestrictedImpersonator$ = this.store$.pipe(
    select(authQuery.isUnRestrictedImpersonator),
  );
  authLevel$ = this.store$.pipe(select(authQuery.authLevel));

  hasAllEntitlements$ = (entitlements: Array<Entitlement | EntitlementLob>) =>
    this.store$.pipe(select(authQuery.hasAllEntitlements({ entitlements })));
  hasOneOfEntitlements$ = (entitlements: Array<Entitlement | EntitlementLob>) =>
    this.store$.pipe(select(authQuery.hasOneOfEntitlements({ entitlements })));

  // putting here until full GA rollout across all users to avoid repeating all over app
  // eslint-disable-next-line @typescript-eslint/member-ordering
  hasMessageCenterCapability$ = combineLatest([
    this.lob$,
    this.hasOneOfEntitlements$([
      Entitlement.ClaimsSearch,
      Entitlement.Precertification,
    ]),
  ]).pipe(
    map(
      ([lob, hasEntitlement]) =>
        hasEntitlement &&
        [LOB_MEDBH, LOB_MEDICAL, LOB_BEHAVIORAL, LOB_DENTAL].includes(
          lob as LOB,
        ),
    ),
  );

  constructor(
    private store$: Store<AuthPartialState>,
    private cignaAuthFacade: CignaAuthFacade,
    @Inject('permissionToggleList') private permissionToggleList: string[],
    @Inject(APP_CONTEXT) public appContext: AppContext,
  ) {}

  authenticate(): void {
    if (isDevMode()) {
      this.loadMockTokens();
    } else {
      this.cignaAuthFacade.authenticate();
    }
  }

  refreshToken(): void {
    this.cignaAuthFacade.authenticate();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  loadMockTokens(): void {
    let accessToken = '';

    const lobLocal = localStorage.getItem('chcpDevUserType') || LOB_MEDBH;

    const lobType = lobLocal;

    const impersonation =
      localStorage.getItem('chcpDevUserImpersonation') || '';
    const permissions =
      localStorage.getItem('chcpDevUserPermissions')?.split(',') ||
      this.permissionToggleList;
    const entitlements =
      localStorage.getItem(`chcpDevUserEntitlements-${lobType}`)?.split(',') ||
      ALL_ENTITLEMENTS;
    if (lobType === LOB_DENTAL) {
      accessToken =
        'eyJqa3UiOiJodHRwczovL2EtaGNwcG9ydGFsLTAwMzYuY2lnbmEuY29tL21nYS9zcHMvb2F1dGgvb2F1dGgyMC9qd2tzL2NoY3Bfc3BhX2RlZiIsImtpZCI6Imw5OHdmNXZuSjh3X0NJTFhCZzdFMmFFZHBESzZGNnpxbVFwdGpJTUZ2eUEiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1NzEzNDQzMTMsInN1YiI6ImNuPXJlZ2RlbnRjbG0wMSxvdT1wcm92aWRlcnBlb3BsZSxvPWNpZ25hLmNvbSIsImF1ZCI6ImNoY3Bfc3BhX2NsaWVudCIsImh0dHBzOi8vY2lnbmFmb3JoY3AuY2lnbmEuY29tIjp7InNlc3Npb25JZCI6IjE2MTczZGEwLWYxMWQtMTFlOS1iNDkxLTAwNTA1NmJlN2U5MyIsImNuIjoicmVnZGVudGNsbTAxIiwibG9iIjoiREVOIiwiZW50aXRsZW1lbnRzIjpbIkRlbl9DbGFpbXNTZWFyY2giLCJEZW5fQ2xpbmljYWxSZXBvcnRzIiwiRGVuX0RlbGVnYXRpb25BY2Nlc3NBbmRWaWV3cyIsIkRlbl9EaXJlY3RvcnlVcGRhdGVzIiwiRGVuX0Vucm9sbEFuZE1hbmFnZUVGVCIsIkRlbl9QYXRpZW50U2VhcmNoIiwiRGVuX1BhdGllbnRSZXZpZXdzVmlldyIsIkRlbl9QcmVjZXJ0aWZpY2F0aW9uIiwiRGVuX0Jhc2ljSW5mb3JtYXRpb24iXX0sInNjb3BlIjoib3BlbmlkIiwiY2xpZW50IjoiY2hjcF9zcGFfY2xpZW50IiwiaXNzIjoiaHR0cHM6Ly9kLWhjcHBvcnRhbC5jaWduYS5jb20iLCJleHAiOjE1NzEzNDc5MTN9.Yc8TM7txwyaYuAYYDW3lt3rUQFwyOpdAbhd0qUgPrJWYhjIUvf-g_uBCzY4bh_RKJN5COVEPJbypJqsaG5gCXJvolHh88ypcEFN-SZo1aiFxSlmSDvxMNWjiDC_fOIZyZ5zq3e6LqYOVDU2DiTlbuKKMiAqLoI6JxoCol9fyvtKH40aBjhkIdiqChGE4hcIUH8svPCw4lPl-utZvBSMLzN_Ypft00Kkv7OXq_svEWiZo2qcZIPA2qCG35Jd1mn4RWemh8BsTGHvYZ5TrGfoIZS8Tn9xsILWn3sclJlHVgN6cTcdghaBThKoMzrB7MUdpGH58kVZWcWH6GZAzdu5l-A';
    }
    if (lobType === LOB_MEDBH || LOB_MEDICAL) {
      accessToken =
        'eyJqa3UiOiJodHRwczovL2NpZ25hZm9yaGNwLmNpZ25hLmNvbS9tZ2Evc3BzL29hdXRoL29hdXRoMjAvandrcy9jaGNwX3NwYV9kZWYiLCJraWQiOiJHeURtNHR0Wk1ZUGs0bGRRTUEtU0VlWnpDU0l2OVd4VE5ieHFzY1pQX0JVIiwiYWxnIjoiUlMyNTYifQ.eyJpYXQiOjE1ODE0NTE2MTQsInN1YiI6ImNuPWhrdGVzdDMsb3U9cHJvdmlkZXJwZW9wbGUsbz1jaWduYS5jb20iLCJhdWQiOiJjaGNwX3NwYV9jbGllbnQiLCJodHRwczovL2NpZ25hZm9yaGNwLmNpZ25hLmNvbSI6eyJzZXNzaW9uSWQiOiIwODE1NDU0Ni00ZDBhLTExZWEtYTNjZS0wMDUwNTY4ZjA4NzIiLCJjbiI6ImhrdGVzdDMiLCJsb2IiOiJNRURCSCIsImVudGl0bGVtZW50cyI6WyJNZWRCaF9DbGFpbXNTZWFyY2giLCJNZWRCaF9DbGluaWNhbFJlcG9ydHMiLCJNZWRCaF9Db2xsYWJvcmF0aXZlQ2FyZSIsIk1lZEJoX0RlbGVnYXRpb25BY2Nlc3NBbmRWaWV3cyIsIk1lZEJoX0RpcmVjdG9yeVVwZGF0ZXMiLCJNZWRCaF9FbnJvbGxBbmRNYW5hZ2VFRlQiLCJNZWRCaF9QYXRpZW50U2VhcmNoIiwiTWVkQmhfUGF0aWVudFJldmlld3NWaWV3IiwiTWVkQmhfUHJlY2VydGlmaWNhdGlvbiIsIk1lZEJoX1JlbWl0dGFuY2VSZXBvcnRzIiwiTWVkQmhfQmFzaWNJbmZvcm1hdGlvbiJdfSwic2NvcGUiOiJvcGVuaWQiLCJjbGllbnQiOiJjaGNwX3NwYV9jbGllbnQiLCJpc3MiOiJodHRwczovL2NpZ25hZm9yaGNwLmNpZ25hLmNvbSIsImV4cCI6MTU4MTQ1NTIxM30.AtUGLbtW-aW93QnTHPx-BctIeU_igk-7I9as93JafSncUcX2AweLgnLqHDys-Rq1ArxYIbEROY9GKe5uZICiacqCfZATtvQ6273XLWfxZ3atwiPcOorEjJ3e7-WY6BR_qMN7Iuw7Be69cfE5CU-IkkLAZPWpDLrfe4hzINH8R5GX0gfARW6-LKjweUBq-OGZB6diPlUky8tTJLpI0n3w6lhM5tsrQIETfAVnlifPHcNhx-lfOUhCMOq8Taka1XlC907e_JNNbTYOPOuaobmpaHJMdu-Y_xDfrzpqOAny4xtHlMYR8Ukv1XKdrAYP8a--XAaYf_dDhZ4J1EnQmTYrwA';
    }
    if (lobType === LOB_BEHAVIORAL) {
      accessToken =
        'IltvYmplY3QgT2JqZWN0XSAi.eyJpYXQiOjE1ODIyMjczMTIsInN1YiI6ImNuPW9ocF9uaXRpbjIyMixvdT1wcm92aWRlcnBlb3BsZSxvPWNpZ25hLmNvbSIsImF1ZCI6ImV2ZXJub3J0aF9wcm92aWRlcl9zcGFfY2xpZW50IiwiaHR0cHM6Ly9wcm92aWRlci5ldmVybm9ydGguY29tIjp7InNlc3Npb25JZCI6IjFiNDY1NTMyLTU0MTgtMTFlYS1iZDljLTAwNTA1NmJlN2U5MyIsImNuIjoib2hwX25pdGluMjIyIiwibG9iIjoiRUJIIiwiZW50aXRsZW1lbnRzIjpbIkViaF9DbGFpbXNTZWFyY2giLCJFYmhfRGlyZWN0b3J5VXBkYXRlcyIsIkViaF9FbnJvbGxBbmRNYW5hZ2VFRlQiLCJFYmhfUGF0aWVudFNlYXJjaCIsIkViaF9QcmVjZXJ0aWZpY2F0aW9uIiwiRWJoX1JlbWl0dGFuY2VSZXBvcnRzIiwiRWJoX0Jhc2ljSW5mb3JtYXRpb24iXX0sInNjb3BlIjoib3BlbmlkIiwiY2xpZW50IjoiZXZlcm5vcnRoX3Byb3ZpZGVyX3NwYV9jbGllbnQiLCJpc3MiOiJodHRwczovL2QtcHJvdmlkZXItYmVoYXZpb3JhbC5jaWduYS5jb20iLCJleHAiOjE1ODIyMzA5MTF9._xlyVGui8nbvvoJ8yB3n0HWyDTa5rPpMAbFFQqPPfaQ';
    }

    const idToken =
      'eyJraWQiOiJsOTh3ZjV2bko4d19DSUxYQmc3RTJhRWRwREs2RjZ6cW1RcHRqSU1GdnlBIiwiYWxnIjoiUlMyNTYifQ.eyJodHRwczovL2NpZ25hZm9yaGNwLmNpZ25hLmNvbSI6eyJmaXJzdE5hbWUiOiJzaWRkaGFydGgiLCJsYXN0TmFtZSI6Im1hbGhvdHJheloifSwibm9uY2UiOiJkYWYwZmEwNTY3ZGY0OGNkODA1MGY2YzM0N2QzYTJmOSIsImlhdCI6MTU1OTU3NDM2NCwiaXNzIjoiaHR0cHM6Ly9kLWhjcHBvcnRhbC0wMDE5LmNpZ25hLmNvbSIsInN1YiI6ImNuPW1lZGFuMDEsb3U9cHJvdmlkZXJwZW9wbGUsbz1jaWduYS5jb20iLCJhdF9oYXNoIjoiY1RnTmszS1ZLeWJEYlFtOHROTUdvZyIsImV4cCI6MTU1OTU3NDk2NCwiYXVkIjoiY2hjcF9zcGFfY2xpZW50In0.pZviyjFDwcX57zuuWhzkpRY9pgLhOfIUrZhx0vog_QOhOpNbPOZA9FVyWQON7XhP34rAscuJJnf2M5ggEW9Rp8RMFhbSPP_hLx8Rce5pa62quKCB5P4iH3BlWUx0q5exESkvucuU-9U0BJuPhC0_egoKzZS9jfGu7WTgqctPpdZVSWIBrnSS_-f2IrPh3u-3cZWPpWiLcX-9HmCSM9IyhD2CDMr-zwR9pf1wRsUXGDKcMae0tT1jn2BJpb9fRP9a1RiwqQltJ2r_3vbNHNW2WDgkeT7RHvq9Gidp37_HHns6CUUbkqHJ-g4oqCXTiTdAT345P5CB2wyoWHt5DnOenw';

    const accessTokenBodyOriginal = jwt_decode(accessToken) as any;
    const accessTokenBodyUpdated: AccessToken = {
      ...accessTokenBodyOriginal,
      'cigna.entitlements': entitlements.join(' '),
      'cigna.permissionToggles': permissions.join(' '),
      'cigna.encryptedCn': 'encryptedId',
      'cigna.lob': lobType,
      'cigna.cn': 'salesdemo_user123',
      'cigna.appName': 'chcp-web',
      ...(impersonation !== ''
        ? { 'cigna.operatorRole': impersonation }
        : null),
    };

    // firing this off to set auth isLoading: true
    // this.store$.dispatch(authActions.loadAuth());

    // adding simulated delay to help test delayed token response on load and catch race order bugs
    setTimeout(() => {
      this.store$.dispatch(
        authActions.loadAuthSuccess({
          auth: {
            accessToken,
            accessTokenBody: accessTokenBodyUpdated,
            idTokenBody: jwt_decode(idToken),
          },
        }),
      );
    }, 0);
  }
}
