import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DcmAssetsState, SECURE_DCM_ASSETS_FEATURE_KEY } from './dcm.reducer';

export const getDcmAssetsState = createFeatureSelector<DcmAssetsState>(
  SECURE_DCM_ASSETS_FEATURE_KEY,
);

export const getDcmAssets = createSelector(
  getDcmAssetsState,
  (s: DcmAssetsState) => ({
    data: s.data,
    loaded: s.loaded,
  }),
);
