import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  LiveAvailabilityState,
  LIVE_AVAILABILITY_FEATURE_KEY,
} from './live-availability.reducer';

// Lookup the 'LiveAvailability' feature state managed by NgRx
const getLiveAvailabilityState = createFeatureSelector<LiveAvailabilityState>(
  LIVE_AVAILABILITY_FEATURE_KEY,
);

const getLiveAvailabilityLoaded = createSelector(
  getLiveAvailabilityState,
  ({ loaded }) => loaded,
);

const getLiveChatAvailable = createSelector(
  getLiveAvailabilityState,
  (state) => state.inOperatingHours && !state.inHoliday,
);

const getChatCloseHrsMsg = createSelector(
  getLiveAvailabilityState,
  ({ chatCloseHrsMsg }) => chatCloseHrsMsg,
);

const isLiveCheckPaused = createSelector(
  getLiveAvailabilityState,
  (state) => state.pauseLiveCheck,
);

const getChatOutage = createSelector(
  getLiveAvailabilityState,
  ({ hasChatOutage }) => hasChatOutage,
);

const getChatOutageMsg = createSelector(
  getLiveAvailabilityState,
  ({ chatOutageMsg }) => chatOutageMsg,
);

export const liveAvailabilityQuery = {
  getLiveAvailabilityLoaded,
  getLiveChatAvailable,
  getChatCloseHrsMsg,
  isLiveCheckPaused,
  getChatOutage,
  getChatOutageMsg,
};
