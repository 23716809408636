import {
  content,
  getParticipant,
  getParticipantFirstName,
  isDMContextTags,
} from '@cigna/omni/shared-util';
import {
  ConversationDTO,
  ConversationParticipantDTO,
  ConversationParticipantEventDTO,
  ConversationEvent,
  ConversationSummaryDTO,
} from '@cigna/vampire-dto';
import flow from 'lodash/fp/flow';
import { ParticipantEventListItem } from '../interfaces';

const isParticipantEvent = (
  event: ConversationEvent,
): event is ConversationParticipantEventDTO => event.type === 'participant';

export const extractParticipantEvents = (
  conversation: ConversationDTO,
): ConversationParticipantEventDTO[] => {
  const participantEvents = (conversation.events || []).filter(
    (event: ConversationEvent): event is ConversationParticipantEventDTO =>
      isParticipantEvent(event) && event.role === 'agent',
  );
  if (isDMContextTags(conversation.context.tags)) {
    participantEvents.shift();
  }

  return participantEvents;
};

export const transformParticipantEvents =
  (
    participants: ConversationParticipantDTO[],
    conversation: ConversationSummaryDTO,
  ): ((
    events: ConversationParticipantEventDTO[],
  ) => ParticipantEventListItem[]) =>
  (participantEvents: ConversationParticipantEventDTO[] = []) =>
    participantEvents
      .filter(
        (event) =>
          (event.changeType === 'join' || event.changeType === 'left') &&
          getParticipant(event.participantId, participants),
      )
      .map((event) => {
        const copy =
          getParticipantFirstName(event.participantId, participants) +
          (event.changeType === 'join'
            ? content.agentJoinMessage
            : event.changeType === 'left' && conversation?.state === 'opened'
            ? content.agentTransferMessage
            : content.agentLeftMessage);

        return {
          copy,
          _id: event._id,
          created: event.created,
          type: event.type,
        };
      });

export const participantEventTransformFlow = (
  conversation: ConversationDTO,
): ParticipantEventListItem[] =>
  flow(
    extractParticipantEvents,
    transformParticipantEvents(conversation.participants, conversation),
  )(conversation);
