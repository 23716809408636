import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  OnInit,
  ViewContainerRef,
  EmbeddedViewRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MediaQueryService, ScreenSize } from './media-query.service';

/**
 * Responsive structural directive. Internally listens to pre-defined set of media query matchers.
 *
 * Usage:
 * ```html
 * <div *cignaResponsive="'<screen>'; or '<optional modifier>'; let screen">...</div>
 * ```
 *
 * Few examples:
 * - render only if screen size is small: `*cignaResponsive="'sm'"`
 * - render if screen size is medium or smaller: `*cignaResponsive="'md'; or 'smaller'"`
 * - custom render: `*cignaResponsive="let screen"`. Contextual `screen` could be used to
 *  render some parts of the template:
 *
 *  ```html
 *  <div *ngIf="screen.isEqual('sm')"></div>
 *  <div *ngIf="screen.isLargerOrEqual('md')"></div>
 *  <div *ngIf="screen.isSmallerOrEqual('xl')"></div>
 *  ```
 */
@Directive({
  selector: '[cignaResponsive]',
})
export class ResponsiveDirective implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _currentView: EmbeddedViewRef<any> | null = null;

  constructor(
    private _template: TemplateRef<any>,
    private _container: ViewContainerRef,
    private _mq: MediaQueryService,
  ) {}

  @Input() cignaResponsive?: ScreenSize;
  @Input() cignaResponsiveOr?: 'larger' | 'smaller';

  ngOnInit() {
    this._mq.match$.pipe(takeUntil(this._destroy$)).subscribe(this.tryRender);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();

    this.clearContainer();
  }

  private tryRender = () => {
    let isMatching = false;

    if (!this.cignaResponsive) {
      isMatching = true;
    } else if (this.cignaResponsiveOr === 'larger') {
      isMatching = this._mq.isLargerOrEqual(this.cignaResponsive);
    } else if (this.cignaResponsiveOr === 'smaller') {
      isMatching = this._mq.isSmallerOrEqual(this.cignaResponsive);
    } else {
      isMatching = this._mq.isEqual(this.cignaResponsive);
    }

    if (!isMatching) {
      this.clearContainer();
    } else if (!this._currentView) {
      this._currentView = this._container.createEmbeddedView(this._template, {
        $implicit: this._mq,
      });
    }
  };

  private clearContainer() {
    if (this._currentView) {
      this._currentView.destroy();
    }

    this._currentView = null;
  }
}
