import { Injectable, Inject } from '@angular/core';
import { defer } from 'rxjs';
import {
  UserManager,
  WebStorageStateStore,
  InMemoryWebStorage,
  Log,
} from 'oidc-client';
import jwt_decode from 'jwt-decode';
import { WindowService } from '@cigna/shared/angular/core/window-util';
import { LoggingService } from '@cigna/shared/angular/logging-util';
import { TealeafService } from '@cigna/shared/angular/tealeaf-util';
import {
  ImplicitFlowConfig,
  IMPLICIT_FLOW_CONFIG,
} from './implicit-flow-config';
import { AuthError } from './auth-error';
import { Authenticator } from './interfaces';
import { nxMetadataService, nxResponseValidator } from './oidc-client-nx';

@Injectable({
  providedIn: 'root',
})
export class ImplicitFlowService implements Authenticator {
  private _manager: UserManager;

  private _sessionTrace: Array<{ ts: string; msg: unknown[] }> = [];

  constructor(
    private _logger: LoggingService,
    @Inject(IMPLICIT_FLOW_CONFIG) config: ImplicitFlowConfig,
    private _windowService: WindowService,
    private _tealeafService: TealeafService,
  ) {
    this.logWindowMessages();

    Log.logger = {
      debug: (...args) =>
        this._sessionTrace.push({ ts: new Date().toISOString(), msg: args }),
      info: (...args) =>
        this._sessionTrace.push({ ts: new Date().toISOString(), msg: args }),
      warn: (...args) => _logger.warn(...args),
      error: (...args) => _logger.error(...args),
    };
    Log.level = Log.DEBUG;

    const redirectUri =
      config.redirectUri.startsWith('http') ||
      config.redirectUri.startsWith('com.')
        ? config.redirectUri
        : _windowService.location.origin + config.redirectUri;

    this._manager = new UserManager({
      authority: _windowService.location.origin,
      metadataUrl: config.metadataUri,
      client_id: config.clientId,
      silent_redirect_uri: redirectUri,
      response_type: config.responseType ?? 'token id_token',
      scope: 'openid',
      loadUserInfo: false,
      includeIdTokenInSilentRenew: false,
      monitorSession: false,
      userStore: new WebStorageStateStore({
        store: new InMemoryWebStorage(),
      }),
      stateStore: new WebStorageStateStore({
        store: new InMemoryWebStorage(),
      }),
      silentRequestTimeout:
        typeof config.timeout === 'undefined' ? 30000 : config.timeout,
      clockSkew:
        typeof config.clockSkew === 'undefined'
          ? Number.POSITIVE_INFINITY
          : config.clockSkew,
      MetadataServiceCtor: nxMetadataService(config),
      ResponseValidatorCtor: nxResponseValidator(config),
    });
  }

  authenticate() {
    return defer(() => this.silentSignIn());
  }

  private async silentSignIn() {
    try {
      await this._manager.clearStaleState();
    } catch (e) {
      this._logger.error(e, 'failed to clear oidc state');

      this._tealeafService.logCustomError(
        'OIDCEvents',
        'failed to clear oidc state',
        {
          ts: new Date().toISOString(),
          error: pojofyError(e),
        },
      );
    }

    this._tealeafService.logInfo('OIDCLogging', 'from SignIn()', {
      ts: new Date().toISOString(),
      message: 'login using OIDC signinSilent()',
    });

    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { access_token, id_token } = await this._manager.signinSilent();

      return {
        accessToken: access_token,
        accessTokenBody: jwt_decode(access_token) as any,
        idTokenBody: jwt_decode(id_token) as any,
      };
    } catch (e) {
      this._logger.error(e, 'error initiating silent auth flow');

      const authError = new AuthError(e);

      this._tealeafService.logCustomError(
        'OIDCEvents',
        authError.isLoginRequired()
          ? 'Failed due to 401'
          : 'error initiating silent auth flow',
        {
          sessionTrace: this._sessionTrace,
          ts: new Date().toISOString(),
          error: pojofyError(e),
        },
      );
      this._sessionTrace = [];

      throw authError;
    }
  }

  private logWindowMessages() {
    const logToTealeaf = this.logToTealeaf.bind(this);
    this._windowService.removeEventListener('message', logToTealeaf);
    this._windowService.addEventListener('message', logToTealeaf, false);
  }

  private logToTealeaf(event: MessageEvent) {
    this._tealeafService.logInfo(
      'AuthCallbackLogging',
      'postMessage to window',
      {
        ts: new Date().toISOString(),
        data: event.data,
        origin: event.origin,
      },
    );
  }
}

function pojofyError(error: Error): Error {
  return { name: error.name, message: error.message, stack: error.stack };
}
