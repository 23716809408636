<div class="advocate-unavailable-group">
  <div
    class="advocate-unavailable-text"
    data-test-id="text-advocate-unavailable"
  >
    {{
      careAdvocateNotAssignedMessage
        ? careAdvocateNotAssignedMessage.title
        : "We're working on matching you to a care advocate!"
    }}
  </div>
  <div
    class="advocate-unavailable-subtext"
    data-test-id="subtext-advocate-unavailable"
  >
    {{
      careAdvocateNotAssignedMessage
        ? careAdvocateNotAssignedMessage.msg
        : "You'll get a notification when you're matched."
    }}
  </div>
</div>
