import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';

import '@esi/leaf-web/lib/wc/components/heading/heading';
import type { LeafHeading } from '@esi/leaf-web/lib/wc';

@Component({
  selector: 'cigna-leaf-heading',
  template: `
    <leaf-heading
      [type]="type"
      [tagVariant]="tagVariant"
      [inverted]="inverted"
      [brandColor]="brandColor"
    >
      <ng-content></ng-content>
    </leaf-heading>
  `,
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CignaLeafHeadingComponent {
  @Input() type: LeafHeading['type'];
  @Input() tagVariant: LeafHeading['tagVariant'] = 'h2';
  @Input() inverted: LeafHeading['inverted'];
  @Input() brandColor: LeafHeading['brandColor'];
}
