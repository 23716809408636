import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UiResponsiveModule } from '@cigna/shared/angular/responsive-ui';
import {
  IconDynamicComponent,
  IconDynamicChatBubbleComponent,
  IconDynamicChevronComponent,
  IconDynamicCloseComponent,
  IconDynamicDirectionArrowComponent,
  IconDynamicHeadsetComponent,
  IconDynamicHeartInHandComponent,
  IconDynamicMessageChatComponent,
  IconDynamicStethoscopeComponent,
  IconDynamicDirective,
  IconCollapsibleChatBubbleComponent,
} from './icon-dynamic';

const DECLARATIONS = [
  IconDynamicComponent,
  IconDynamicChatBubbleComponent,
  IconDynamicChevronComponent,
  IconDynamicCloseComponent,
  IconDynamicDirectionArrowComponent,
  IconDynamicHeadsetComponent,
  IconDynamicHeartInHandComponent,
  IconDynamicMessageChatComponent,
  IconDynamicStethoscopeComponent,
  IconDynamicDirective,
  IconCollapsibleChatBubbleComponent,
];
@NgModule({
  imports: [CommonModule, RouterModule.forChild([]), UiResponsiveModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class OmniSharedUiModule {}
