<div class="cg-dialog">
  <h2 class="cg-dialog__header">
    Provider Relations<button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      (click)="dialogRef.close()"
    >
      Close
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h2>

  <mat-dialog-content>
    <div class="cg-margin-bottom-sm">
      <h4 class="cg-h4">We're here to help. Call on us.</h4>
      <div class="cg-margin-top-sm cg-margin-bottom-lg">
        Please call the office nearest you:
      </div>
    </div>
    <div>
      <div class="cg-margin-bottom-md">
        <strong>All callers</strong>
      </div>
      <span>(except Puerto Rico)</span>
      <span class="cg-flex cg-float-right">800.8824462 (88CIGNA)</span>
    </div>
    <div class="cg-margin-bottom-md cg-margin-top-xl">
      <strong> Puerto Rico </strong>
    </div>
    <div
      class="cg-margin-bottom-md border-bottom"
      *ngFor="let items of CALLERS_LIST"
    >
      <span>{{ items.name }}</span>
      <span class="cg-float-right">{{ items.number }}</span>
    </div>
  </mat-dialog-content>
</div>
