import { Type } from '@angular/core';
import {
  isClinicalNursingContextTags,
  isLegacyCoachingContextTags,
  isScheduleCoachingContextTags,
  isSupportOpsContextTags,
  omniColors,
  isBehavioralContextTags,
  isMedicalContextTags,
  IconDynamicHeadsetComponent,
  IconDynamicHeartInHandComponent,
  IconDynamicStethoscopeComponent,
} from '@cigna/omni/shared-util';
import { ConversationSummaryDTO } from '@cigna/vampire-dto';

export function sortByDates(
  conv1: ConversationDTOAgentID,
  conv2: ConversationDTOAgentID,
) {
  const date1 = new Date(conv1.agentUpdatedTimestamp);
  const date2 = new Date(conv2.agentUpdatedTimestamp);
  // within new and unread groups we sort by date
  return date2.getTime() - date1.getTime();
}

export function generateIconType(
  context: ConversationSummaryDTO['context'],
): Type<
  | IconDynamicHeadsetComponent
  | IconDynamicHeartInHandComponent
  | IconDynamicStethoscopeComponent
> {
  if (
    isClinicalNursingContextTags(context.tags) ||
    isLegacyCoachingContextTags(context.tags)
  ) {
    return IconDynamicStethoscopeComponent;
  }
  if (
    isScheduleCoachingContextTags(context.tags) ||
    isSupportOpsContextTags(context.tags)
  ) {
    return IconDynamicHeadsetComponent;
  }

  if (
    isBehavioralContextTags(context.tags) ||
    isMedicalContextTags(context.tags)
  ) {
    return IconDynamicHeartInHandComponent;
  }
  return IconDynamicHeadsetComponent;
}

function generateSummaryListItemType(
  state: ConversationSummaryDTO['state'],
  newMessages: boolean,
  type: ConversationSummaryDTO['type'],
): string {
  if (type === 'async' && state === 'opened' && newMessages) {
    return 'New Message';
  }
  return '';
}

function generateSummaryListItemTypeIcon(
  type: ConversationSummaryDTO['type'],
): string {
  if (type === 'async') {
    return 'direct-message';
  }
  return '';
}

export function generateAgentSummaryListItem(c: ConversationDTOAgentID) {
  return {
    body: c.title,
    chevronColor: { color: omniColors.blue },
    icon: generateIconType(c.context),
    conversationId: c._id,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    date: c.agentUpdatedTimestamp
      ? c.agentUpdatedTimestamp.toString()
      : c.updated.toString(), // this is not typed well for consumers, it is actually a string, but the model has Date as the type
    isClosed: c.state === 'closed',
    iconColors: {
      badgeFillColor:
        c.state === 'closed' ? omniColors.digitalRed : omniColors.digitalGreen,
      badgeStrokeColor: omniColors.white,
      color: c.state === 'closed' ? omniColors.gray40 : omniColors.blue,
    },
    iconData: {
      showBorder: true,
      showNotificationBadge: c.newMessages,
    },
    newMessages: c.newMessages,
    state: c.state,
    subject: '',
    context: c.context,
    agentData: c.agentData,
    type: c.type,
    typeLabel: generateSummaryListItemType(c.state, c.newMessages, c.type),
    labelIcon: generateSummaryListItemTypeIcon(c.type),
    lastMessageBy:
      c.participants?.find((participant) => participant.role === 'agent')
        ?.name || '',
  };
}

export interface ConversationDTOAgentID extends ConversationSummaryDTO {
  agentData?: ConversationAgentData;
  agentUpdatedTimestamp: Date;
}

export interface ConversationAgentData {
  firstName?: string;
  id?: string;
}
