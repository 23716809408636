<ng-container
  *cignaWaitToLoad="
    adjustments;
    let adjustmentsData;
    errorState: error;
    loadingState: loading
  "
>
  <table
    *ngIf="adjustmentsData?.length; else noAdjustments"
    class="cg-datatable cg-datatable--borderless"
    data-test="claim-adjustment-history"
  >
    <thead>
      <tr>
        <th
          data-test="reference-number-header"
          class="cg-datatable__header nosort-header-container"
          style="width: 200px"
        >
          {{
            (isPrecert
              ? 'shared.reconsiderationHistory.headers.appealNumber'
              : 'shared.reconsiderationHistory.headers.referenceNumber'
            ) | translate
          }}
        </th>
        <th
          data-test="recon-type-header"
          class="cg-datatable__header nosort-header-container"
          *ngIf="!isPrecert"
        >
          {{ 'shared.reconsiderationHistory.headers.reconType' | translate }}
        </th>
        <th
          data-test="modified-by-header"
          class="cg-datatable__header nosort-header-container"
        >
          {{ 'shared.reconsiderationHistory.headers.modifiedBy' | translate }}
        </th>
        <th
          data-test="modified-date-header"
          class="cg-datatable__header nosort-header-container"
        >
          {{ 'shared.reconsiderationHistory.headers.modifiedDate' | translate }}
        </th>
        <th
          data-test="status-header"
          class="cg-datatable__header nosort-header-container"
          style="width: 300px"
        >
          {{ 'shared.reconsiderationHistory.headers.status' | translate }}
        </th>
        <th
          data-test="decision-notes-header"
          class="cg-datatable__header nosort-header-container"
          style="width: 135px"
        >
          {{
            'shared.reconsiderationHistory.headers.decisionNotes' | translate
          }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let adjustment of adjustmentsData; index as i"
        data-test="adjustment"
      >
        <td class="cg-datatable__cell ref-number" data-test="reference-number">
          <button
            *ngIf="adjustment.status === 'DRAFT'"
            data-test="remove-reconsideration"
            cignaButton="link no-underline small"
            class="remove"
            (click)="remove(adjustment.id, adjustment.reconsiderationType)"
            [disabled]="!hasReconsiderationAccess || isImpersonator"
          >
            <cigna-icon type="close-thin"></cigna-icon>
          </button>

          <button
            style="padding: 0"
            cignaButton="link"
            [attr.data-test]="'lnk-' + adjustment.extRefNum"
            (click)="accessHistory(i)"
            [disabled]="!hasReconsiderationAccess && !adjustment.extRefNum"
            [cignaTrackClicks]="[
              'trackEventOther',
              adjustment.extRefNum
                ? isPrecert
                  ? 'Appeal Number Click'
                  : 'Reconsideration Number Click'
                : 'Open Draft',
              'ac-rec014'
            ]"
          >
            {{
              adjustment.extRefNum
                ? '#' + adjustment.extRefNum
                : ('shared.reconsiderationHistory.draftReferenceNumberFallback'
                  | translate)
            }}
          </button>
        </td>

        <td
          class="cg-datatable__cell"
          *ngIf="!isPrecert"
          data-test="reconsideration-type"
        >
          {{ getDisplayReconsiderationType(adjustment) | titlecase }}
        </td>
        <td class="cg-datatable__cell" data-test="modified-by">
          {{
            adjustment.updatedBy?.firstName +
              ' ' +
              adjustment.updatedBy?.lastName
          }}
        </td>
        <td class="cg-datatable__cell" data-test="modified-date">
          <ng-container>{{
            adjustment.updateDate | date : 'M/d/yyyy'
          }}</ng-container>
          at
          <ng-container>{{
            adjustment.updateDate | date : 'h:mm a'
          }}</ng-container>
        </td>

        <td class="cg-datatable__cell" data-test="lbl-status-cell">
          <ng-container
            *ngIf="
              adjustment.status === 'DRAFT' &&
                adjustment.expirationTime !== null;
              else status
            "
          >
            <div
              *ngIf="
                getDraftDaysRemaining(adjustment);
                let daysRemaining;
                else: lastDay
              "
              data-test="daysRemaining"
            >
              {{ daysRemaining }}
              {{ 'shared.reconsiderationHistory.daysRemaining' | translate }}
            </div>
            <ng-template #lastDay>
              <div data-test="lastDayRemaining">
                {{
                  'shared.reconsiderationHistory.lastDayRemaining' | translate
                }}
              </div>
            </ng-template>
          </ng-container>

          <ng-template #status>
            <div data-test="status">
              {{ AdjustmentDisplayStatus[adjustment.status] }}
            </div>
          </ng-template>
        </td>
        <td class="cg-datatable__cell cg-datatable__cell--centered">
          <ng-container
            *ngIf="
              adjustment.decisionNotes &&
                !noNotesStatuses.includes(adjustment.status);
              else noNotes
            "
          >
            <button
              cignaButton="link"
              style="padding: 0; position: relative; bottom: 8px"
              (click)="openDecisionNotes(decisionNotesPopup)"
              data-test="decision-notes-popup"
              [cignaTrackClicks]="[
                'trackEventOther',
                'Decision Notes',
                'ac-rec025'
              ]"
            >
              <cigna-icon type="clipboard" class="cg-icon-lg"></cigna-icon>
            </button>
          </ng-container>
          <ng-template #noNotes>
            <div>N/A</div>
          </ng-template>
          <ng-template #decisionNotesPopup>
            <div
              class="cg-dialog"
              [cignaTrackInit]="[
                'trackEventOther',
                'Decision notes modal',
                'ac-rec001'
              ]"
            >
              <h3 class="cg-dialog__header cg-dialog__header--modal">
                {{
                  'shared.reconsiderationHistory.headers.decisionNotes'
                    | translate
                }}
                <button
                  cignaButton="link inline no-underline"
                  class="cg-dialog__close_new"
                  (click)="dialogRef.close()"
                  [cignaTrackClicks]="[
                    'trackEventOther',
                    'Close',
                    'ac-rec003',
                    { prop20: 'Decision notes modal' }
                  ]"
                >
                  {{ 'shared.reconsiderationHistory.close' | translate }}
                  <cigna-icon
                    class="cml-close-icon"
                    type="close-thin"
                  ></cigna-icon>
                </button>
              </h3>

              <mat-dialog-content>
                <div class="cg-margin-bottom-xl">
                  <ng-container
                    *ngIf="
                      adjustment.status.toLowerCase() ===
                        adjustmentStatus.PENDED.toLowerCase();
                      else oneViewNotes
                    "
                  >
                    <p>
                      {{
                        'shared.reconsiderationHistory.decisionNotesStandardMsg'
                          | translate
                      }}
                    </p>
                  </ng-container>
                  <ng-template #oneViewNotes>
                    <p>{{ adjustment.decisionNotes }}</p>
                  </ng-template>
                </div>
              </mat-dialog-content>
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #loading>
  <div class="loading">
    <div class="cg-spinner"></div>
  </div>
</ng-template>

<ng-template #error let-e>
  <div
    *ngIf="e?.code || e?.status"
    data-test="error-msg"
    class="cg-error-msg-large cg-margin-bottom-lg"
  >
    <cigna-icon type="exclamation-circle"></cigna-icon>
    <div data-spa-service-error>
      RECHSTR{{ e.code ? e.code : e.status }}:
      <span> {{ e.errObject.message }} </span>
    </div>
  </div>
</ng-template>

<ng-template #noAdjustments>
  <div class="no-history">
    {{
      (isPrecert
        ? 'shared.reconsiderationHistory.noAppeals'
        : 'shared.reconsiderationHistory.noAdjustmentData'
      ) | translate
    }}
  </div>
</ng-template>

<ng-template #removeConfirmationTemplate let-data>
  <div
    class="cg-dialog"
    aria-labelledby="header"
    data-test="delete-reconsideration-confirmation-modal"
  >
    <h3
      id="header"
      class="cg-dialog__header cg-dialog__header--modal dialog-header"
    >
      {{
        'shared.reconsiderationHistory.removeConfirmationDialog.header'
          | translate
      }}
    </h3>
    <p>
      {{
        'shared.reconsiderationHistory.removeConfirmationDialog.text'
          | translate
      }}
    </p>

    <div class="cg-button-group cg-margin-top-lg">
      <button
        cignaButton="primary"
        (click)="
          confirmRemoveAdjustment(data.id, data.reconType); dialogRef.close()
        "
        [cignaTrackClicks]="[
          'trackEventOther',
          'shared.reconsiderationHistory.removeConfirmationDialog.delete'
            | translate,
          'ac-rec031',
          { prop20: 'Delete Open Draft modal' }
        ]"
        data-test="confirm-delete-reconsideration"
      >
        {{
          'shared.reconsiderationHistory.removeConfirmationDialog.delete'
            | translate
        }}
      </button>
      <button
        cignaButton="link no-underline"
        (click)="dialogRef.close()"
        data-test="cancel-delete-reconsideration"
      >
        {{
          'shared.reconsiderationHistory.removeConfirmationDialog.cancel'
            | translate
        }}
      </button>
    </div>
  </div>
</ng-template>
