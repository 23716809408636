<div *ngIf="status">
  <div class="cg-margin-right-sm">
    <cigna-leaf-inline-alert
      *ngIf="status === 'APPROVE' || status === 'PARTIAL_APPROVE'"
      status="success"
      [fullWidth]="size === 'default'"
      iconTitle="Success"
    >
      {{ PrecertificationStatus[status] }}
    </cigna-leaf-inline-alert>
    <cigna-leaf-inline-alert
      *ngIf="status === 'PEND' || status === 'VOID' || status === 'MIXED'"
      status="info"
      [fullWidth]="size === 'default'"
      iconTitle="Pending"
    >
      {{ PrecertificationStatus[status] }}
    </cigna-leaf-inline-alert>
    <cigna-leaf-inline-alert
      *ngIf="status === 'DENY' || status === 'PARTIAL_DENY'"
      status="error"
      [fullWidth]="size === 'default'"
      iconTitle="Denied"
    >
      {{ PrecertificationStatus[status] }}
    </cigna-leaf-inline-alert>
  </div>
</div>
