import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cigna-history-ui',
  templateUrl: './history-ui.component.html',
  styleUrls: ['./history-ui.component.scss'],
})
export class HistoryUiComponent {
  @Input() title: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() showMoreLink = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() expanded = false;
  @Input() historyIndex: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() clearAll = false;
  @Output() clear = new EventEmitter<string>();
  @Output() showMore = new EventEmitter<number>();

  showMoreClick() {
    this.showMore.emit(this.historyIndex + 5);
  }
}
