import {
  EnvironmentConfig,
  environmentDefaults,
  featureFlagDefaults,
  oidcDefault,
} from '@cigna/chcp/shared/environment-util';

export const environment: EnvironmentConfig = {
  ...environmentDefaults,
  oidc: {
    ...oidcDefault,
    secureUris: [
      'https://p-chcp.digitaledge.cigna.com',
      'https://p-gsg.digitaledge.cigna.com',
      '/prv/secure',
    ],
  },
  redirect: {
    crossOrigin: {
      domain: 'https://provider.evernorth.com',
    },
    mhk: '/isam/sps/chcp-p-idp-mhk/saml20/logininitial?PartnerId=https://cignaportal.medhokapps.com/pportal',
    inbox:
      '/isam/sps/chcp-p-idp-proofpoint/saml20/logininitial?PartnerId=https://saml-entity-id.proofpoint.com/cigna_hosted-pe3',
    upx: {
      submit: 'https://mypa-cignaforhcp.cigna.com?state=new',
      modify: 'https://mypa-cignaforhcp.cigna.com?state=resume',
    },
  },
  omni: {
    sonicNotification: 'https://static.cigna.com/spa/chcp/',
    identityUrl: 'https://p-chcp.digitaledge.cigna.com/converse/identity',
    botUrl: 'https://p-chcp.digitaledge.cigna.com/converse/bot',
    liveUrl: 'https://p-chcp.digitaledge.cigna.com/converse/live/user',
    publicUrl: 'https://p-chcp.digitaledge.cigna.com/converse',
  },

  api: {
    proxy: {
      headers: {
        accept: '*/*',
        content_type: 'application/json',
      },
    },
    gateways: {
      cdn: 'https://static.cigna.com/',
      resourceContent: 'https://p-digital-static.digitaledge.cigna.com/',
      secureApi: 'https://p-chcp.digitaledge.cigna.com',
      publicApi: 'https://p-chcp.digitaledge.cigna.com/public',
      gsgPublicApi: 'https://p-gsg.digitaledge.cigna.com/public',
      gsgSecureApi: 'https://p-gsg.digitaledge.cigna.com',
      graphql: 'https://p-chcp.digitaledge.cigna.com/apollo-graphql',
    },
    searchIndex: 'prod',
    salesDemo: false,
  },
  features: {
    ...featureFlagDefaults,
    nextRelease: false,
    patientEnhPower: false,
    globalBenEnh: false,
    hasCBHAmIInNetwork: false,
    cocePathwellPCLM: false,
  },
  launchDarklyClientId: '6627eacd49bac51024871748',
  disabledRoutes: [],
};
