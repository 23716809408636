<div class="">
  <cigna-omni-messaging-disclaimer
    [isHistory]="false"
    [isHistoryLinkEnable]="false"
    [disclaimer]="disclaimer$ | async"
    [shouldEnablePreChatWelcome]="true"
    [isUpdatingConversation]="false"
    [isTerminatedUser]="false"
    [canEnableNewDMUI]="true"
    [conversationType]="''"
  ></cigna-omni-messaging-disclaimer>
  <ng-container *ngIf="activeDMConversations$ | async as activeDMConversations">
    <p class="desc" *ngIf="activeDMConversations?.length > 0">
      Sorted Newest to Oldest
    </p>
    <ul>
      <li
        *ngFor="
          let item of activeDMConversations;
          trackBy: trackByFunc;
          index as i
        "
      >
        <cigna-omni-conversation-summary-card
          [body]="item.body"
          [chevronColor]="item.chevronColor"
          [conversationId]="item.conversationId"
          [date]="item.date"
          [icon]="item.icon"
          [iconColors]="item.iconColors"
          [iconData]="item.iconData"
          [isClosed]="item.isClosed"
          [hasNewMessages]="item.newMessages"
          [subject]="item.subject"
          [state]="item.state"
          [canEnableNewDMUI]="true"
          [type]="item.type"
          [typeLabel]="item.typeLabel"
          [labelIcon]="item.labelIcon"
          [lastMessageBy]="item.lastMessageBy"
          [context]="item.context"
          [agentData]="item.agentData"
        ></cigna-omni-conversation-summary-card>
      </li>
    </ul>
    <div class="message-container" *ngIf="activeDMConversations?.length === 0">
      <div class="message-box">You have no active Direct Messages.</div>
    </div>
  </ng-container>
</div>
