import { NgModule } from '@angular/core';
import { OmniLiveAvailabilityStateModule } from '@cigna/omni/live-availability-state-data-access';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  ConversationsEffects,
  conversationsReducer,
  OmniConversationsFacade,
  CONVERSATIONS_FEATURE_KEY,
  ConversationsAnalyticsEffects,
} from './+state';
import { TitleCasePipe } from '@angular/common';
import { AuthFacade } from '@cigna/shared/angular/auth-data-access';
@NgModule({
  imports: [
    StoreModule.forFeature(CONVERSATIONS_FEATURE_KEY, conversationsReducer),
    EffectsModule.forFeature([
      ConversationsEffects,
      ConversationsAnalyticsEffects,
    ]),
    OmniLiveAvailabilityStateModule,
  ],
  providers: [AuthFacade, OmniConversationsFacade, TitleCasePipe],
})
export class OmniConversationsStateModule {}
