import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  merge,
  fromEvent,
  interval,
  Observable,
  EMPTY,
  of,
  Subject,
} from 'rxjs';
import {
  share,
  auditTime,
  debounceTime,
  filter,
  tap,
  switchMap,
  takeWhile,
  ignoreElements,
  mapTo,
  catchError,
} from 'rxjs/operators';
import {
  LogoutReason,
  OnLogout,
  TimeoutPopupConfig,
  TIMEOUT_POPUP_CONFIG,
  TIMEOUT_POPUP_EVENTS,
} from './timeout-popup.config';

const TRACK_EVENTS = ['click', 'mousemove', 'keydown', 'touchstart'];

@Injectable({ providedIn: 'root' })
export class TimeoutPopupService {
  private keepAlive$ = new Subject<void>();
  private lastTime: number;
  private countdown: number;
  private onLogout: OnLogout;
  private onKeepAlive: () => Observable<unknown>;

  events$: Observable<Events>;
  isLoggedOut = false;
  get timeUntilLogout() {
    return this.countdown;
  }

  constructor(
    @Inject(TIMEOUT_POPUP_CONFIG) public readonly config: TimeoutPopupConfig,
    @Inject(DOCUMENT) document: Document,
    http: HttpClient,
    zone: NgZone,
    injector: Injector,
  ) {
    if (!document.documentElement) {
      this.events$ = EMPTY;
      return;
    }

    const events = injector.get(TIMEOUT_POPUP_EVENTS) ?? (() => {});

    if (typeof events === 'function') {
      this.onLogout = events;
      this.onKeepAlive = getDefaultOnKeepAlive(config, http);
    } else {
      this.onLogout = events.onLogout;
      this.onKeepAlive =
        events.onKeepAlive ?? getDefaultOnKeepAlive(config, http);
    }

    zone.runOutsideAngular(() => {
      const trackEvents$ = merge(
        of(undefined).pipe(tap(() => this.resetInternal())),
        ...TRACK_EVENTS.map((e) => fromEvent(document, e)),
      );

      const keepAlive$ = merge(
        this.keepAlive$,
        trackEvents$.pipe(
          auditTime(toMs(config.keepAliveInterval ?? config.timeoutDuration)),
          filter(() => !this.isLoggedOut),
        ),
      ).pipe(
        switchMap(() => {
          try {
            return this.onKeepAlive().pipe(catchError(() => []));
          } catch {
            return [];
          }
        }),
        ignoreElements(),
      );

      const ticks$ = trackEvents$.pipe(
        debounceTime(toMs(config.timeoutDuration)),
        filter(() => !this.countdown && !this.isLoggedOut),
        tap(() => {
          this.countdown = toMs(config.logoutDuration);
          this.lastTime = Date.now();
        }),
        switchMap(() =>
          merge(
            of<Events>('inactive'),
            interval(1000).pipe(
              takeWhile(() => this.countdown > 0),
              tap(() => {
                this.tick();
                if (this.countdown === 0) {
                  this.logout('inactive');
                } else if (this.countdown === 3000) {
                  dispatchEvent(new Event('TealeafDynamicActivity'));
                }
              }),
              mapTo<unknown, Events>('tick'),
            ),
          ),
        ),
      );

      // allows to consume events outside of timeout popup component
      this.events$ = merge(keepAlive$, ticks$).pipe(share());
    });
  }

  reset() {
    this.resetInternal();
    this.keepAlive$.next();
  }

  logout(reason: LogoutReason) {
    this.isLoggedOut = true;
    try {
      this.onLogout(reason);
    } catch {
      return;
    }
  }

  private tick() {
    const current = Date.now();
    const elapsed = Math.max(
      Math.round((current - this.lastTime) / 1000) * 1000,
      1000,
    );
    this.lastTime = current;
    this.countdown -= elapsed;
    if (this.countdown < 0) {
      this.countdown = 0;
    }
  }

  private resetInternal() {
    this.lastTime = Date.now();
    this.countdown = 0;
    this.isLoggedOut = false;
  }
}

function toMs(timeInMinutes: number): number {
  return timeInMinutes * 60 * 1000;
}

function getDefaultOnKeepAlive(
  { keepAliveUrl }: TimeoutPopupConfig,
  http: HttpClient,
): () => Observable<unknown> {
  if (!keepAliveUrl) {
    return () => of();
  }

  return () => {
    const cacheBust = new Date().getTime().toString();
    return http.get(keepAliveUrl, {
      responseType: 'arraybuffer',
      params: {
        ver: cacheBust,
      },
    });
  };
}

export type Events = 'tick' | 'inactive';
