import { ChangeDetectionStrategy, Component, Input, Type } from '@angular/core';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniLiveAvailabilityFacade } from '@cigna/omni/live-availability-state-data-access';
import {
  IconDynamicChatBubbleComponent,
  IconDynamicColors,
  IconDynamicData,
} from '@cigna/omni/shared-util';
import { ScreenSize } from '@cigna/shared/angular/responsive-ui';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, pairwise, take, tap } from 'rxjs/operators';
import { ChatBubbleFacade } from '../chat-bubble-state/chat-bubble.facade';

@Component({
  selector: 'cigna-omni-chat-bubble-data',
  templateUrl: './chat-bubble-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBubbleDataComponent {
  public chatBubbleData$: Observable<
    Record<ScreenSize.XSmall | ScreenSize.Medium, IconDynamicData>
  > = this.chatBubbleFacade.chatBubbleData$;
  public showChatBubble$ = this.chatBubbleFacade.showChatBubble$;
  public iconColors$: Observable<IconDynamicColors> =
    this.chatBubbleFacade.iconColors$;

  public icon: Type<IconDynamicChatBubbleComponent> =
    IconDynamicChatBubbleComponent;
  public expanderBubble$: Observable<boolean> = this.convFacade.expanderBubble$;
  public isShellOpen$ = this.facade.isShellOpen$.pipe(
    tap((data) => {
      if (data) {
        this.toggleChatIcon(true);
      }
    }),
  );
  public isShellMinimized$ = this.facade.isShellMinimized$;
  public isShellOpenOrMinimized$ = combineLatest([
    this.isShellOpen$,
    this.isShellMinimized$,
  ]).pipe(map(([isOpen, isMinimized]) => isOpen || isMinimized));

  enableNewPreChatWelcome$: Observable<boolean> =
    this._features.featuresEnabled(['enableNewPreChatWelcome']);

  public isTerminatedUser$ = this._features.featuresEnabled([
    'isTerminatedUser',
  ]);
  isDcmChat$ = this._features.featuresEnabled(['isDcmChat']);

  public hasChatOutage$ = this.liveAvailableFacade.outageSplashScreen$;
  public chatOutageMsg$ = this.liveAvailableFacade.outageSplashScreenMsg$;

  isMaximizeContainer: boolean;
  isExpand: Observable<boolean>;
  isChatBoxMinMax: boolean;
  isChatAvailable: boolean;
  @Input() public isLiveChatAvailable: boolean;

  constructor(
    private chatBubbleFacade: ChatBubbleFacade,
    private _features: FeatureTogglesFacade,
    private facade: OmniChatFacade,
    private convFacade: OmniConversationsFacade,
    private liveAvailableFacade: OmniLiveAvailabilityFacade,
  ) {
    this.convFacade.canAutoOpenConversation$
      .pipe(
        filter((value) => !!value),
        take(1),
      )
      .subscribe(() => {
        this.facade.openDialog();
      });
    this.liveAvailableFacade.outageSplashScreen$
      .pipe(
        pairwise(),
        filter(([prev, curr]) => Boolean(prev && !curr)),
        take(1),
      )
      .subscribe(() => {
        const context = { tags: ['default'] };
        this.convFacade.createConversation('bot', context);
      });
  }

  public handleEvent(event: any) {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (event.type) {
      case 'click':
        combineLatest(
          this._features.featuresEnabled(['enableNewDMUI']),
          this.convFacade.activeAsyncConvCount$,
          this.convFacade.closedAsyncConvCount$,
        )
          .pipe(take(1))
          .subscribe(
            ([enableNewDMUI, activeAsyncConvCount, closedAsyncConvCount]) => {
              if (
                enableNewDMUI &&
                activeAsyncConvCount + closedAsyncConvCount
              ) {
                this.chatBubbleFacade.launchSplashView(event.text);
              } else {
                this.convFacade.canAutoOpenConversation$
                  .pipe(
                    filter((value) => !!value),
                    take(1),
                  )
                  .subscribe(() => {
                    this.facade.openDialog();
                  });
                this.chatBubbleFacade.launchChat(event.text);
              }
            },
          );
        break;
      case 'minimize':
        this.facade.toggleChatState();
        break;
      case 'hideOvalChatIcon':
        this.toggleChatIcon(true);
        break;
      case 'showOvalChatIcon':
        this.toggleChatIcon(false);
        break;
      default:
        throw new Error(`Unknown event type: ${event.type}`);
    }
  }

  toggleChatIcon(shouldExpand: boolean) {
    this.convFacade.setExpanderBubble(shouldExpand);
  }
}
