/* eslint-disable sonarjs/no-identical-functions */
import { MessageEventItemTypes } from '../interfaces';
import {
  messageEventTransformFlowV1,
  messageEventTransformFlowV2,
} from './message-events.transform';
import { participantEventTransformFlow } from './participant-events.transform';
import {
  addAsyncWaitMessage,
  cacheComputedEvents,
  ConversationDTOStatus,
} from './utils';
import { waitEventTransformFlow } from './wait-events.transform';
import dayjs from 'dayjs';
import { transformDMAutoReply } from './dm-auto-reply.tranform';

export const labelFirstOfDay = (
  messageEvents: MessageEventItemTypes[] = [],
): MessageEventItemTypes[] => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let firstEventOfDay: boolean;
  let firstEventOfDayDate: Date;
  return messageEvents.reduce<MessageEventItemTypes[]>((ret, cur, i) => {
    if (dayjs(cur.created).isSame(firstEventOfDayDate, 'd') && i !== 0) {
      firstEventOfDay = false;
    } else {
      firstEventOfDay = true;
      firstEventOfDayDate = cur.created;
    }

    ret.push({
      ...cur,
      firstEventOfDay,
    });

    return ret;
  }, []);
};

export const transformEventsV1 = (
  conversation: ConversationDTOStatus,
  personalGuideEligible: boolean,
  isEvernorthEnabled: boolean,
  enableNewPreChatWelcome: boolean,
): MessageEventItemTypes[] => {
  let events = [
    ...messageEventTransformFlowV1(conversation),
    ...waitEventTransformFlow(
      conversation,
      personalGuideEligible,
      isEvernorthEnabled,
      enableNewPreChatWelcome,
    ),
    ...participantEventTransformFlow(conversation),
    ...transformDMAutoReply(conversation),
  ].sort((a, b) => {
    if (a.created > b.created) {
      return 1;
    }

    if (a.created < b.created) {
      return -1;
    }

    return 0;
  });

  if (
    conversation.type === 'async' &&
    (conversation.state === 'opening' ||
      (conversation.state === 'opened' &&
        conversation.status === 'AWAITING_AGENT'))
  ) {
    events = addAsyncWaitMessage(
      events,
      conversation.context.tags,
      conversation.created,
    );
  }

  return labelFirstOfDay(events);
};

export const transformEventsV2 = (
  conversation: ConversationDTOStatus,
  personalGuideEligible: boolean,
  isEvernorthEnabled: boolean,
  enableNewPreChatWelcome: boolean,
): MessageEventItemTypes[] => {
  let events = [
    ...messageEventTransformFlowV2(conversation),
    ...waitEventTransformFlow(
      conversation,
      personalGuideEligible,
      isEvernorthEnabled,
      enableNewPreChatWelcome,
    ),
    ...participantEventTransformFlow(conversation),
    ...transformDMAutoReply(conversation),
  ].sort((a, b) => {
    if (a.created > b.created) {
      return 1;
    }

    if (a.created < b.created) {
      return -1;
    }

    return 0;
  });

  if (
    conversation.type === 'async' &&
    (conversation.state === 'opening' ||
      (conversation.state === 'opened' &&
        conversation.status === 'AWAITING_AGENT'))
  ) {
    events = addAsyncWaitMessage(
      events,
      conversation.context.tags,
      conversation.created,
    );
  }

  return labelFirstOfDay(events);
};

export const getCachedTransformedEventsV1 =
  cacheComputedEvents(transformEventsV1);

export const getCachedTransformedEventsV2 =
  cacheComputedEvents(transformEventsV2);
