import { Component } from '@angular/core';
import { OmniLiveAvailabilityFacade } from '@cigna/omni/live-availability-state-data-access';
import { Observable } from 'rxjs';
@Component({
  selector: 'cigna-omni-entry',
  templateUrl: './omni-entry.component.html',
  styleUrls: ['./omni-entry.component.scss'],
})
export class OmniEntryComponent {
  public liveChatAvailable$: Observable<boolean> =
    this.liveAvailabilityFacade.liveChatAvailable$;
  constructor(private liveAvailabilityFacade: OmniLiveAvailabilityFacade) {}
}
