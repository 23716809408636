import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OmniSharedUiModule } from '@cigna/omni/shared-util';
import { UI_COMPONENTS } from './ui';
import { CommChannelCheComponent } from './che/che.component';
import { CommChannelSupportComponent } from './support/support.component';
import { MyConversationsCardComponent } from './my-conversations-card/my-conversations-card.component';
import { OmniConversationsStateModule } from '@cigna/omni/conversations-state-data-access';
import { OmniConversationSummaryCardModule } from '@cigna/omni/conversation-summary-card-feature';
import { UiButtonModule } from '@cigna/shared/angular/button-ui';
import { CommChannelEapComponent } from './eap/eap.component';

@NgModule({
  imports: [
    CommonModule,
    OmniConversationsStateModule,
    OmniConversationSummaryCardModule,
    UiButtonModule,
    OmniSharedUiModule,
  ],
  declarations: [
    ...UI_COMPONENTS,
    CommChannelCheComponent,
    CommChannelSupportComponent,
    CommChannelEapComponent,
    MyConversationsCardComponent,
  ],
  exports: [
    ...UI_COMPONENTS,
    CommChannelCheComponent,
    CommChannelSupportComponent,
    CommChannelEapComponent,
    MyConversationsCardComponent,
  ],
})
export class OmniCommChannelFeatureModule {}
