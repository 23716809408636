<button
  tabindex="0"
  cignaButton="secondary"
  [ngClass]="
    shouldEnablePreChatWelcome
      ? 'conv-message-list-item-cta ctaItem'
      : 'conv-message-list-item-cta'
  "
  type="button"
  (click)="handleClick()"
  data-omni-qa="conv-list-item-cta"
>
  <span>{{ props.label }}</span>
</button>
