<div class="intro">
  <ng-container *ngIf="shouldEnablePreChatWelcome">
    <div
      class="chatHistoryLink"
      *ngIf="!isHistory && !isHistoryLinkEnable && conversationType !== 'async'"
    >
      <cigna-icon type="clock" class="clock"></cigna-icon>
      <div class="historyText">
        <span *ngIf="!canEnableNewDMUI">{{ historyLabel }}</span>
        <button
          data-test-id="link-chat-history"
          cignaButton="link inline no-underline"
          [ngClass]="{
            'disable-link': isUpdatingConversation
          }"
          (click)="openHistory()"
        >
          {{ historyButtonText }}
        </button>
      </div>
    </div>
  </ng-container>
  <span
    tabindex="0"
    class="disclaimer-text"
    data-omni-qa="omni-dialog-disclaimer"
    *ngIf="(!isTerminatedUser || isHistory) && disclaimer !== ''"
  >
    {{ disclaimer }}
    <br />
    <span
      class="OneLinkTx spanish"
      *ngIf="isSpanishActive && (isReleaseFlagEnabled$ | async)"
      >{{ chatSpanishDisclaimer }}</span
    >
  </span>
</div>
