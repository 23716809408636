<cigna-alert
  *ngIf="showDisclaimer && files.length === 0"
  type="info"
  [close]="null"
  data-test="upload-disclaimer"
>
  <div class="title">{{ disclaimerHeader }}</div>
  <div class="message">
    <p [innerHTML]="disclaimerBody"></p>
  </div>
  <div class="actions">
    <div class="cg-margin-top-lg">
      <button
        cignaButton="primary"
        (click)="hideDisclaimer()"
        data-test="btn-accept"
      >
        {{ disclaimerAccept }}
      </button>
    </div>
  </div>
</cigna-alert>

<cigna-file-upload-dnd
  *ngIf="!showDisclaimer || files.length > 0"
  [allowedExtensions]="allowedExtensions"
  [attachmentsRequired]="attachmentsRequired"
  [isUploadDisabled]="isUploadDisabled"
  (uploadFiles)="onUploadFiles($event)"
></cigna-file-upload-dnd>

<div *ngIf="files?.length">
  <div class="section-title">Uploaded</div>
  <div
    *ngIf="totalSizeLimit"
    class="total-limit"
    [ngClass]="{ error: totalFilesSize > totalSizeLimit }"
  >
    ({{ totalFilesSize | formatBytes }} / {{ totalSizeLimit | formatBytes }})
    <span *ngIf="totalFilesSize > totalSizeLimit" class="error-message">
      <cigna-icon type="exclamation-triangle" class="error-icon"></cigna-icon>
      You have reached the maximum size of uploaded files for this request.
    </span>
  </div>
  <div
    *ngIf="totalFilesCountLimit"
    class="total-limit"
    [ngClass]="{ error: (files?.length || 0) > totalFilesCountLimit }"
  >
    ({{ files?.length || 0 }}/{{ totalFilesCountLimit }}) files
    <span
      *ngIf="(files?.length || 0) > totalFilesCountLimit"
      class="error-message"
    >
      <cigna-icon type="exclamation-triangle" class="error-icon"></cigna-icon>
      You have reached the maximum number of uploaded files for this request.
    </span>
  </div>
  <div
    *ngIf="totalPageCountLimit"
    class="total-limit"
    [ngClass]="{ error: totalPages > totalPageCountLimit }"
  >
    ({{ totalPages }}/{{ totalPageCountLimit }}) pages
  </div>
</div>

<div class="cg-margin-top-md">
  <cigna-file-upload-list
    [files]="files"
    [isRemoveDisabled]="isRemoveDisabled"
    [customConfirmationText]="customConfirmationText"
    (removeFile)="onRemoveFile($event)"
    (fileNameClick)="onFileNameClick($event)"
  ></cigna-file-upload-list>
</div>
