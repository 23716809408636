import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RIGHTNOW_FEATURE_KEY, RightNowState } from './right-now.reducer';

// Lookup the 'RightNow' feature state managed by NgRx
const getRightNowState =
  createFeatureSelector<RightNowState>(RIGHTNOW_FEATURE_KEY);

export const isWidgetInitialized = createSelector(
  getRightNowState,
  ({ widgetInitialized }) => widgetInitialized,
);

export const rightNowQuery = {
  isWidgetInitialized,
};
