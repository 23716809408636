import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniGateway } from '@cigna/omni/shared-util';
import {
  InterceptionDTO,
  InterceptionDismissedReasonType,
} from '@cigna/vampire-dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OmniInterceptionsDataAccessService {
  constructor(private http: HttpClient, private gateway: OmniGateway) {}

  public view(id: string): Observable<InterceptionDTO> {
    const url = this.gateway.interceptionsUrl({ id });
    return this.http.post<InterceptionDTO>(url, {});
  }

  public dismiss(
    id: string,
    dismissedReason: InterceptionDismissedReasonType,
  ): Observable<InterceptionDTO> {
    const url = this.gateway.interceptionsUrl({ id });
    return this.http.post<InterceptionDTO>(url, {
      dismissedReason,
    });
  }
}
