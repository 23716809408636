import { Component } from '@angular/core';
import { AuthFacade } from '@cigna/chcp/auth/data-access';

@Component({
  selector: 'cigna-layout-page-not-found',
  templateUrl: './layout-page-not-found.component.html',
  styleUrls: ['./layout-page-not-found.component.scss'],
})
export class LayoutPageNotFoundComponent {
  constructor(private authFacade: AuthFacade) {}

  loggedIn$ = this.authFacade.isUserAuthenticated$;
}
