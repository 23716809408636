import { Component, Input } from '@angular/core';

import { NavigationItem } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  @Input() navItem: NavigationItem;
  @Input() isTopLevel = false;
  @Input() dataTestAttribute: string;
  @Input() isImpersonator = false;
  @Input() hasChildren = false;
}
