<div
  [ngClass]="{
    active: activate,
    'top-border': showTopBorder
  }"
  class="accordion-wrapper"
>
  <button
    #button
    type="button"
    (key.enter)="toggleOpen()"
    (click)="toggleOpen()"
    class="accordion"
    [attr.aria-expanded]="activate"
    data-test-id="accordion-header"
  >
    <div class="header-wrapper">
      <i
        *ngIf="showArrow"
        class="accordion-arrow"
        [ngClass]="activate ? 'down' : 'right'"
        data-test-id="icon-profile"
      >
        <cigna-icon
          [attr.data-test-id]="
            activate ? 'icon-caret-down' : 'icon-caret-right'
          "
          [type]="activate ? activatedIcon : deactivatedIcon"
        ></cigna-icon>
      </i>
      <ng-content select=".header"></ng-content>
      <cigna-icon
        *ngIf="showRightArrow"
        class="arrow"
        [ngClass]="activate ? 'arrow-up' : 'arrow-down'"
        type="arrow-ic"
      ></cigna-icon>
      <cigna-icon
        *ngIf="shouldShowIconRightSide"
        class="right-side-icon"
        [type]="activate ? activatedIcon : deactivatedIcon"
      ></cigna-icon>
    </div>
    <div
      *ngIf="activate"
      class="description-wrapper"
      data-test-id="accordion-description"
    >
      <ng-content select=".description"></ng-content>
    </div>
  </button>

  <ng-content select=".sub-header"></ng-content>

  <div
    class="panel"
    [ngClass]="{
      accordionOpened: activate,
      accordionClosed: !activate,
      'panel-blue-backgrd': isPanelBlueBackgrd
    }"
    data-test-id="accordion-panel"
  >
    <ng-content select=".content"></ng-content>
  </div>
</div>
