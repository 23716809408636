import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';

import { JobRole } from './user-options.model';

import { checkValidResponse, logError } from '@cigna/chcp/shared/util';

@Injectable({ providedIn: 'root' })
export class UserOptionsService {
  public CONSUMER_CODE = '1000';

  public registrationApi: string;

  constructor(
    private http: HttpClient,
    @Inject('publicApi') publicApi: string,
    @Inject('consumerCode') consumerCode: string,
  ) {
    this.registrationApi = `${publicApi}/v2/registration`;
    this.CONSUMER_CODE = consumerCode;
  }

  getLookUpData() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    return this.http.get(`${this.registrationApi}/lookUpData`, { params }).pipe(
      map(checkValidResponse),
      map((res) => ({
        jobRoles: this.parseJobRoleResponse(res.lookUpData.jobRoles),
        securityQuestions: res.lookUpData.securityQuestions,
      })),
      catchError(logError),
    );
  }

  private parseJobRoleResponse(roles: any): JobRole[] {
    return roles.map((role: any) => ({
      id: role.id,
      name: role.name.trim(),
      description: role.desc.trim(),
    }));
  }
}
