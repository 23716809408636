// TODO: resolve CHCP disables by bot
// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

export enum UserOptionsActionTypes {
  GetLookUpData = '[UserOptions] Load Lookup Data (Job Roles and Security Questions)',
  GetLookUpDataSuccess = '[UserOptions] Load Lookup Data Success',
  GetLookUpDataError = '[UserOptions] Load Lookup Data Error',
}

export class GetLookUpData implements Action {
  readonly type = UserOptionsActionTypes.GetLookUpData;
}

export class GetLookUpDataSuccess implements Action {
  readonly type = UserOptionsActionTypes.GetLookUpDataSuccess;
  constructor(
    public payload: {
      jobRoles: any;
      securityQuestions: any;
    },
  ) {}
}

export class GetLookUpDataError implements Action {
  readonly type = UserOptionsActionTypes.GetLookUpDataError;
  constructor(public payload: string) {}
}

export type UserOptionsActions =
  | GetLookUpData
  | GetLookUpDataSuccess
  | GetLookUpDataError;
