<div class="cg-dialog">
  <h3 class="cg-dialog__header">
    Preferred Line of Business<button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      (click)="dialogRef.close()"
    >
      {{ 'am.dialog.explainAccess.closeBtn' | translate }}
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h3>
  <mat-dialog-content>
    <form [formGroup]="lobPrefForm" (ngSubmit)="updateUserTinLob()">
      <div>
        <p>
          Please select your preferred line of business below for
          {{ data.firstName }} {{ data.lastName }}.
        </p>
      </div>
      <label for="preferredLob"
        ><strong> PREFERRED LINE OF BUSINESS </strong></label
      >
      <div class="cg-margin-top-xs">
        <select
          id="preferredLob"
          cignaSelect
          [ngModel]="selectPrefLob"
          class="cg-select-width-auto"
          name="preferredLob"
          formControlName="lobControl"
          data-test="preferred-lob-dropdown"
        >
          <option value="" disabled>Choose</option>
          <ng-container *ngFor="let lob of preferredLobOptions">
            <option [value]="lob">
              {{ preferredLOBs[lob] }}
            </option>
          </ng-container>
        </select>

        <div
          class="cg-flex cg-margin-top-md"
          *ngIf="lobControl.value === 'BEH'"
        >
          <div>
            <cigna-icon
              type="alert-triangle"
              class="cg-color-warn cg-icon-sm cg-margin-right-sm"
            ></cigna-icon>
          </div>
          This selection steers the user to Provider.Evernorth.com upon login.
        </div>
        <div
          class="cg-flex cg-margin-top-md"
          *ngIf="lobControl.value === 'MED'"
        >
          <div>
            <cigna-icon
              type="alert-triangle"
              class="cg-color-warn cg-icon-sm cg-margin-right-sm"
            ></cigna-icon>
          </div>
          This selection steers the user to CignaforHCP.com upon login.
        </div>
      </div>
      <div class="cg-button-group cg-margin-top-lg">
        <button
          cignaButton="primary"
          type="submit"
          [disabled]="!lobControl.value"
        >
          Submit
        </button>
        <button
          cignaButton="link no-underline inline"
          type="button"
          (click)="dialogRef.close()"
        >
          Cancel
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
