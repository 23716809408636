<div class="cg-dialog">
  <h2 class="cg-dialog__header">
    Site Help
    <button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      (click)="dialogRef.close()"
    >
      Close
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h2>

  <mat-dialog-content>
    <h4 class="cg-margin-bottom-sm">Security and privacy</h4>
    <cigna-collapsible-item headerText="What is Cigna's privacy policy?">
      <p>
        To review Cigna's privacy policy, click on the Privacy Information link
        at the bottom of any page.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="How can I tell if my online session with Cigna is encrypted?"
    >
      <p>
        Microsoft Internet Explorer, Google Chrome, Mozilla Firefox, and Apple
        Safari all use a padlock icon by the browser field to represent an
        encrypted session. An open lock indicates that your session is not
        encrypted.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="What steps should I take to ensure the security of my information?"
    >
      <p>
        You share responsibility for maintaining the security of your personal
        and provider information when using online services. Never share your
        password with anyone. When you finish with this website, always use the
        log out button to leave the service and close down the browser.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item headerText="What if someone learns my password?">
      <p>
        If you suspect that someone knows your password, you can change it
        online by clicking Settings & Preferences > Security & Login > Change
        Password. Each user is required to have their own individual password.
      </p>
      <p>
        If you have trouble changing your password, request help from your
        website access manager.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="How can I change my password on the Cigna for Health Care Professionals website?"
      ><p>
        Click on Settings & Preferences > Security and Login, then Change
        Password. Each user is required to have their own individual password.
      </p>
      <p>
        If you use a third-party website, such as OneHealthPort or NaviNet, to
        access CignaforHCP.com, you will not have access to Settings &
        Preferences on the Cigna website. You will need to change your password
        on the third-party website.
      </p>
      <p>
        If you have trouble changing your password, you can request help from
        your website access manager.
      </p>
    </cigna-collapsible-item>

    <h4 class="cg-margin-bottom-sm cg-margin-top-xl">Browser</h4>
    <cigna-collapsible-item headerText="What kind of browser do I need?">
      <p>
        The Cigna for Health Care Professionals website works well with a
        variety of browsers, including Microsoft Edge, Mozilla FireFox, Google
        Chrome, and Apple Safari. Using the most up-to-date browser version will
        minimize the likelihood that your session with Cigna could be
        interrupted. If you are having trouble registering or using the site,
        and you have checked your browser, please contact your internet service
        provider or your employer's technical support group.
      </p>
    </cigna-collapsible-item>

    <h4 class="cg-margin-bottom-sm cg-margin-top-xl">Helpful information</h4>
    <cigna-collapsible-item headerText="When is the website available?">
      <p>Hours of Availability:</p>
      <p>
        The Cigna for Health Care Professionals website and EDI real-time
        transactions are generally available 24 hours a day, 365 days a year.
      </p>

      <p>
        Online precertification transactions are available Monday through
        Saturday from 5:00 a.m. to 3:00 a.m. ET and Sunday from 5:00 a.m. to
        9:00 p.m. ET.
      </p>

      <p>
        To allow for maintenance, the website and EDI real-time transactions may
        be unavailable on Sundays from 12:01 a.m. until 10:00 a.m. ET.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item headerText="What is a website access manager?">
      <p>
        Each group registered on the Cigna for Health Care Professionals website
        (CignaforHCP.com) is required to assign at least one, and up to 15,
        user(s) as the website access manager(s). The website access manager at
        each practice manages how providers within the group register for and
        obtain the appropriate level of access to the website.
      </p>
      <p>Website access managers have three key responsibilities:</p>
      <ol>
        <li>
          Obtain access and approve new users in your group for CignaforHCP.com
        </li>
        <li>
          Assign or modify the website functions to which users in your group
          have access
        </li>
        <li>Remove access for users in your group who no longer need it.</li>
      </ol>

      <p>
        View this
        <a
          target="_blank"
          rel="noopener"
          [href]="
            '/pdf/resourceLibrary/eCourses/website-access-managers-ecourse.pdf'
              | resourceLink : { type: 'file', secure: false }
              | async
          "
          >Website Access Manager Tutorial</a
        >
        to learn more.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item headerText="What is a 'cookie?'">
      <p>
        A cookie is a small file on your computer that saves internet settings.
        Cookies can keep you signed in, remember your site preferences, and give
        you locally relevant content. Because cookies are saved to your
        computer, the settings they save will only apply to the specific
        computer and browser that you used to set those preferences. Therefore,
        cookies are especially helpful if you typically use the same computer
        and browser.
      </p>
      <p>
        Cookies should be enabled in your internet browser in order for you to
        experience the full convenience of the Health Care Professional portal.
      </p>
    </cigna-collapsible-item>

    <cigna-collapsible-item headerText="What does 'clear my cache' mean?">
      <p>
        When you use web browsers, your history, temporary internet files, and
        cookies are stored on your computer.  While these stored files can help
        you access information faster, they also take up disk space.  If your
        disk is full, certain web-based applications may not open properly or
        run well.  It is a best practice to remove temporary files on a regular
        basis.  This is often referred to as "clearing your cache."
      </p>
      <p>
        Generally, you can clear your cache by using your browser's settings and
        looking for the browser's history, temporary files, and "cookies" – and
        then delete them.  Each browser will have help information specific to
        how to do this for that browser.  Please look for your browser's "Help"
        section.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="Do I need a separate user ID and password for each tax ID?"
    >
      <p>
        No. You can request access to another TIN by going to Settings &
        Preferences > Online Access > Request TIN access. The website access
        manager for that TIN will add or deny your access to that TIN.
      </p>
      <p>
        If you receive a temporary ID for another TIN, you can add that access
        to your existing ID by logging in and going to Settings & Preferences >
        Online Access > Add access rights from temporary ID.
      </p>
    </cigna-collapsible-item>
    <cigna-collapsible-item
      headerText="I am registered but do not have access to information I need"
    >
      <p>
        You may contact your website access manager and request the access you
        need. If you do not know who your website manager is, access Settings
        and Preferences > Online access > View TIN access. Select your TIN and
        the name of the website access manager will be provided at the bottom of
        the screen.
      </p>
      <p>
        If you are the first person in your practice or facility to attempt to
        register, you can obtain a user ID and create a password by clicking
        "Register Now" on the Log In page. Your office will be called by Cigna
        to help establish a website access manager.
      </p>
    </cigna-collapsible-item>
    <h4 class="cg-margin-bottom-sm cg-margin-top-xl">
      If you still need assistance with this website, please call 1.800.981.9114
    </h4>
  </mat-dialog-content>
</div>
