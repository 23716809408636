import {
  ChangeDetectionStrategy,
  Component,
  Input,
  HostBinding,
  ElementRef,
  Optional,
  Output,
  EventEmitter,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { InputHost } from './input-host';

let nextId = 0;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'input[cignaInput],select[cignaSelect],textarea[cignaInput]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'cignaInput,cignaSelect',
})
export class InputComponent implements OnDestroy {
  constructor(
    private _element: ElementRef<HTMLInputElement>,
    @Optional() private _host?: InputHost,
    @Optional() public model?: NgModel,
  ) {
    nextId += 1;
    this.id = `cigna-input-${nextId}`;

    if (this._host) {
      this._host.register(this);
    }
  }

  @HostBinding('attr.id')
  @Input()
  id: string;

  @Output() touched = new EventEmitter<InputComponent>();

  get element(): HTMLInputElement {
    return this._element.nativeElement;
  }

  ngOnDestroy() {
    if (this._host) {
      this._host.clear();
    }
  }

  @HostListener('blur')
  blur() {
    this.touched.next(this);
  }

  setHostMetaFocus(focused: boolean) {
    if (this._host) {
      this._host.setMetaFocus(focused);
    }
  }
}
