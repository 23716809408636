import {
  InterceptionsActionsUnion,
  InterceptionsActionTypes,
} from './interceptions.actions';
import { InterceptionDTO } from '@cigna/vampire-dto';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const INTERCEPTIONS_FEATURE_KEY = 'omni-interceptions-feature';

export interface InterceptionsState extends EntityState<InterceptionDTO> {
  displayMax: number;
  dismissed: string[];
}

export interface InterceptionsPartialState {
  readonly [INTERCEPTIONS_FEATURE_KEY]: InterceptionsState;
}

const sortByCreatedDate = (a: InterceptionDTO, b: InterceptionDTO) => {
  if (a.created < b.created) {
    return -1;
  }
  if (a.created > b.created) {
    return 1;
  }
  return 0;
};

const selectId = (entity: InterceptionDTO) => entity._id;

export const adapter = createEntityAdapter<InterceptionDTO>({
  selectId,
  sortComparer: sortByCreatedDate,
});

export const initialState: InterceptionsState = adapter.getInitialState({
  displayMax: 2,
  dismissed: [],
});

export function interceptionsReducer(
  state = initialState,
  action: InterceptionsActionsUnion,
): InterceptionsState {
  switch (action.type) {
    case InterceptionsActionTypes.AddOne:
      return adapter.addOne(action.payload.interception, state);

    case InterceptionsActionTypes.AddMany:
      return adapter.addMany(action.payload.interceptions, state);

    case InterceptionsActionTypes.EngageSuccess:
    case InterceptionsActionTypes.DismissSuccess:
      return {
        ...adapter.removeOne(action.payload.id, state),
        dismissed: [...state.dismissed, action.payload.id],
      };

    case InterceptionsActionTypes.Snooze:
    default:
      return state;
  }
}
