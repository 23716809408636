import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import {
  ConversationEventExtended,
  OmniConversationsFacade,
} from '@cigna/omni/conversations-state-data-access';
import { OmniLiveAvailabilityFacade } from '@cigna/omni/live-availability-state-data-access';
import { MessagingFacade } from '../facade/messaging.facade';
import { CtaListItemData } from '../interfaces';
import { ConversationInterface } from '../interfaces/conversation.interface';
import { CdnPrefixPipe } from '../pipes/cdn-prefix.pipe';
import { ConversationDTOStatus } from '@cigna/omni/shared-util';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cigna-omni-messaging-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingDataComponent {
  public inputEnabled$ = this.msgFacade.inputEnabled$;
  public disclaimer$ = this.msgFacade.disclaimer$;
  public events$ = this.msgFacade.events$;
  public outroObj$ = this.msgFacade.outro$;
  public conversationType$ = this.msgFacade.conversationType$;
  public minimized$ = this.msgFacade.minimized$;
  public liveChatAvailable$ = this.liveAvailableFacade.liveChatAvailable$;
  public chatCloseHrsMsg$ = this.liveAvailableFacade.chatCloseHrsMsg$;
  public isUnmuted$ = this.omniChatFacade.isUnmuted$;
  public title$ = this.omniChatFacade.headerTitle$;
  public closeSplashScreen$ = this.cnvFacade.closeSplashScreen$;
  public conversation$ = this.cnvFacade.conversation$;
  public shouldEnablePreChatWelcome$ = this.features.featuresEnabled([
    'enableNewPreChatWelcome',
  ]);
  public hasCharLimit$ = this.features.featuresEnabled(['hasCharLimit']);
  public isAiBot$ = this.cnvFacade.isAiBot$;
  public screenText = 'Are you sure you want to end this chat?';
  public isHistoryView$ = this.omniChatFacade.isHistory$;
  public useRightNowChat$ = this.features.featuresEnabled(['useRightNowChat']);
  public shouldEnabledBackToPrevious$ =
    this.msgFacade.shouldEnabledBackToPrevious$;
  public replaceBackToPreviousWithEndChat$ =
    this.msgFacade.replaceBackToPreviousWithEndChat$;
  public isUpdatingConversation$ = this.cnvFacade.isUpdatingConversation$;
  public isTerminatedUser$ = this.features.featuresEnabled([
    'isTerminatedUser',
  ]);
  public isDcmChat$ = this.features.featuresEnabled(['isDcmChat']);
  public terminatedUserMessage$ = this.cnvFacade.terminatedUserMessage$;
  public hasChatOutage$ = this.liveAvailableFacade.outageSplashScreen$;
  public chatOutageMsg$ = this.liveAvailableFacade.outageSplashScreenMsg$;
  public hasActiveLiveChat$ = this.cnvFacade.getOpenLiveConvExists$;

  agentName$ = this.cnvFacade.agentName$;
  public coversationError$ = this.cnvFacade.conversationsError$;
  public enableNewDMUI$ = this.features.featuresEnabled(['enableNewDMUI']);
  public soundFileLink: string;
  userQuestion: string;

  constructor(
    private cnvFacade: OmniConversationsFacade,
    private msgFacade: MessagingFacade,
    private liveAvailableFacade: OmniLiveAvailabilityFacade,
    private omniChatFacade: OmniChatFacade,
    private features: FeatureTogglesFacade,
    private cdnPrefix: CdnPrefixPipe,
  ) {
    this.soundFileLink = this.cdnPrefix.transform('/assets/sounds/typing.mp3');
  }

  public setMessageViewed(messageId: string): void {
    this.cnvFacade.messageViewed(messageId);
  }

  public postCta({
    sourceMessageId,
    value,
    topicId,
    label,
    primaryTopicName,
    _id,
  }: CtaListItemData) {
    this.cnvFacade.postCta(
      sourceMessageId || '',
      value,
      topicId || '',
      label || '',
      _id || '',
      primaryTopicName || '',
    );
  }

  public postMessage(
    message: string,
    conversation: ConversationInterface,
  ): void {
    if ((conversation.state as string) === 'transfer-manual') {
      this.cnvFacade.transferConversation(conversation._id, 'live', message);
    } else {
      let canEnableNewDMUI = false;
      const isNewDM =
        conversation.type === 'async' &&
        (conversation as ConversationDTOStatus).status ===
          'AGENT_CREATE_CONVERSATION' &&
        conversation.events
          .filter((event) => event.type === 'message')
          .every(
            (event) => (event as ConversationEventExtended).isUserMsg === false,
          );
      if (isNewDM) {
        this.enableNewDMUI$
          .pipe(take(1))
          .subscribe((enableNewDMUI: boolean) => {
            canEnableNewDMUI = enableNewDMUI;
          });
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.cnvFacade.postMessage(message, canEnableNewDMUI && isNewDM);
    }
  }

  public navigateBack(): void {
    this.omniChatFacade.back();
  }

  public keyHandler() {
    this.cnvFacade.onKeyUp();
  }
}
