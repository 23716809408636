import { Component, Input, Type } from '@angular/core';
import {
  IconDynamicData,
  IconDynamicColors,
  IconsDynamic,
} from '@cigna/omni/shared-util';

@Component({
  selector: 'cigna-omni-comm-channel-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
  @Input()
  public icon?: Type<IconsDynamic>;
  @Input()
  public iconColors?: IconDynamicColors;
  @Input()
  public iconData?: IconDynamicData;
  @Input()
  public omniQaTag: string | null;
}
