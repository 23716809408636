import { Component, Input, AfterViewChecked } from '@angular/core';

export const PASSWORD_HINTS: Array<{ name: string; formKey: string }> = [
  {
    name: '8-64 characters',
    formKey: 'invalidMinMaxPassword',
  },
  {
    name: 'Minimum 1 number',
    formKey: 'invalidAtleatOnePassword',
  },
  {
    name: 'Minimum 1 upper case letter and 1 lower case letter',
    formKey: 'invalidOneUpLower',
  },
  {
    name: 'Minimum 1 special character (accepted symbols are _ ! . & @ -)',
    formKey: 'invalidAcceptSymbols',
  },
  {
    name: 'Maximum 2 characters repeated beside themselves, e.g.; the two "o"s in "Looking4!"',
    formKey: 'invalidMaxTwoCharRepeat',
  },
];

@Component({
  selector: 'cigna-password-hints',
  templateUrl: './password-hints.component.html',
  styleUrls: ['./password-hints.component.scss'],
})
export class PasswordHintsComponent implements AfterViewChecked {
  @Input() errors: any;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() fnlnCheckReq: boolean;
  passowrdHints = PASSWORD_HINTS;
  ngAfterViewChecked() {
    const flnFound = this.passowrdHints.filter(
      (fl) => fl.formKey === 'invalidDisallowNameId',
    );
    if (this.fnlnCheckReq && !flnFound.length) {
      this.passowrdHints.push({
        name: 'Cannot contain first name, last name, or user ID',
        formKey: 'invalidDisallowNameId',
      });
    }
  }
}
