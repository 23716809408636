import { Component, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import type { LeafBadge } from '@esi/leaf-web/lib/wc';

import '@esi/leaf-web/lib/wc/components/badge/badge';

@Component({
  selector: 'cigna-leaf-badge',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <leaf-badge
      [text]="text"
      [status]="status"
      [variant]="variant"
      [svg]="svg"
    ></leaf-badge>
  `,
})
export class CignaLeafBadgeComponent {
  @Input() text: LeafBadge['text'] = '';
  @Input() status: LeafBadge['status'] = 'neutral';
  @Input() variant: LeafBadge['variant'] = 'strong';
  @Input() svg: LeafBadge['svg'];
}
