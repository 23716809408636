import { createAction, props } from '@ngrx/store';
import { AuthError, OidcResult } from '@cigna/shared/angular/auth/oidc-util';

export const loadAuth = createAction('[Auth] Load Auth');

export const noopLoadAuth = createAction('[Auth] NOOP Load Auth');

export const loadAuthSuccess = createAction(
  '[Auth] Load Auth Success',
  props<{ auth: OidcResult }>(),
);

export const loadAuthError = createAction(
  '[Auth] Load Auth Error',
  props<{ error: AuthError }>(),
);

export const unauthorizedError = createAction('[Auth] Unauthorized Error');
