import { Component, Input } from '@angular/core';
import {
  PrecertificationStatusType,
  PrecertificationStatus,
} from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-precert-status',
  templateUrl: './precert-status.component.html',
  styleUrls: ['./precert-status.component.scss'],
})
export class PrecertStatusComponent {
  @Input() status: PrecertificationStatusType;
  @Input() size: 'small' | 'medium' | 'large' = 'small';

  PrecertificationStatus = PrecertificationStatus;

  get statusType(): 'success' | 'warning' | 'error' | 'info' {
    if (['APPROVE', 'PARTIAL_APPROVE'].includes(this.status)) {
      return 'success';
    }

    if (['PEND', 'VOID', 'MIXED'].includes(this.status)) {
      return 'warning';
    }

    if (['DENY', 'PARTIAL_DENY'].includes(this.status)) {
      return 'error';
    }

    return 'info';
  }
}
