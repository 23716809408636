<div class="cg-dialog">
  <h2
    data-test="leave-chcp-dialog-header"
    class="cg-dialog__header cg-dialog__header--modal"
  >
    You are now leaving CignaforHCP.com
  </h2>

  <mat-dialog-content>
    <div class="cg-margin-bottom-xl" data-test="leave-chcp-content">
      {{ data.content }}
    </div>

    <div data-test="btn-continue" class="cg-button-group cg-margin-bottom-sm">
      <a
        cignaButton="primary"
        [href]="data.link"
        target="_blank"
        rel="noopener"
        (click)="leaveChcpDialogRef.close()"
      >
        Continue
      </a>
    </div>
  </mat-dialog-content>
</div>
