import { Injectable } from '@angular/core';
import { UrlFactory } from './url-factory';

@Injectable({ providedIn: 'root' })
export class ContentGateway {
  constructor(private _urlFactory: UrlFactory) {}

  contentUrl({ filename }: { filename: string }): string {
    return this._urlFactory.createUrl('resourceContent', filename);
  }
}
