<ng-container
  *ngIf="outroObj?.show && shouldEnablePreChatWelcome && (isDCMChat$ | async)"
>
  <div class="dcm-chat-inactive-block" data-test-id="chat-inactive-block">
    <cigna-icon type="archive" alt="archive"></cigna-icon>
    <span data-test-id="text-inactive">This conversation is inactive.</span>
  </div>
</ng-container>
<cigna-omni-messaging-disclaimer
  [isHistory]="isHistoryView"
  [isHistoryLinkEnable]="isHistoryLinkEnable"
  [disclaimer]="disclaimer"
  [shouldEnablePreChatWelcome]="shouldEnablePreChatWelcome"
  [isUpdatingConversation]="isUpdatingConversation"
  [isTerminatedUser]="isTerminatedUser"
  [canEnableNewDMUI]="canEnableNewDMUI"
  [conversationType]="conversation?.type"
  *ngIf="!isDcmChat"
></cigna-omni-messaging-disclaimer>
<ng-container
  *ngIf="
    !shouldEnablePreChatWelcome ||
    (shouldEnablePreChatWelcome &&
      (isLiveChatAvailable || isHistoryView || conversation?.type !== 'bot') &&
      (!hasChatOutage || hasActiveLiveChat))
  "
>
  <div class="mainCover" tabindex="0">
    <ng-container *ngIf="hasNewMessage">
      <button
        class="unread-msg-button"
        data-test-id="button-unread-msg"
        *cignaFeatureToggle="'isDcmChat'"
        (click)="scrollUpdate()"
      >
        New message
        <cigna-icon
          type="chevron-thin-down"
          class="unread-msg-icon"
        ></cigna-icon>
      </button>
    </ng-container>
    <ul>
      <ng-container
        *ngFor="
          let message of messages;
          let last = last;
          trackBy: trackByMostRecent
        "
      >
        <li *ngIf="message.firstEventOfDay && !isKoreAiOutage">
          <cigna-omni-messaging-date-change
            [date]="message.created"
          ></cigna-omni-messaging-date-change>
        </li>
        <ng-container [ngSwitch]="message.type">
          <ng-template [ngSwitchCase]="'message'">
            <li>
              <cigna-omni-messaging-item
                (sendCta)="sendCtaFn($event)"
                (backToPrevious)="backToPrevious()"
                (minibotLinkClick)="minibotLinkClick($event)"
                [props]="message"
                [shouldEnablePreChatWelcome]="shouldEnablePreChatWelcome"
                [shouldEnabledBackToPrevious]="shouldEnabledBackToPrevious"
                [canReplaceBackToPreviousWithEndChat]="
                  canReplaceBackToPreviousWithEndChat
                "
                [isEvernorth]="isEvernorth"
                [isDcmChat]="isDCMChat$ | async"
                [attr.data-scroll-id]="message._id"
              ></cigna-omni-messaging-item>
            </li>
          </ng-template>
          <ng-template [ngSwitchCase]="'wait'">
            <li>
              <cigna-omni-messaging-wait-time
                [datetime]="message.datetime"
                [footer]="message.footer"
                [lineOne]="message.lineOne"
                [lineTwo]="message.lineTwo"
                [personalGuideEligible]="message.personalGuideEligible"
                [type]="conversationType"
                [waitTimeinSeconds]="message.waitTimeinSeconds"
                data-omni-qa="omni-dialog-wait-time"
                [hasEnablePreChatWelcome]="shouldEnablePreChatWelcome"
              ></cigna-omni-messaging-wait-time>
            </li>
          </ng-template>
          <ng-template [ngSwitchCase]="'participant'">
            <li
              *ngIf="
                (conversation.uswState !== 'ended' || isHistoryView) &&
                (isDCMChat$ | async) === false
              "
            >
              <cigna-omni-messaging-system-generated-message>
                <cigna-omni-messaging-system-event
                  [message]="message.copy"
                  [isTransferMessage]="
                    conversation.uswState === 'inQueue' &&
                    last &&
                    message.copy?.includes('transferred')
                  "
                  [shouldEnablePreChatWelcome]="shouldEnablePreChatWelcome"
                  data-omni-qa="omni-dialog-participant-joined"
                ></cigna-omni-messaging-system-event>
              </cigna-omni-messaging-system-generated-message>
            </li>
          </ng-template>
          <ng-template [ngSwitchCase]="'systemMessage'">
            <li>
              <cigna-omni-messaging-system-generated-message>
                <cigna-omni-messaging-system-event
                  [message]="message.copy"
                  data-omni-qa="omni-dm-auto-reply"
                ></cigna-omni-messaging-system-event>
              </cigna-omni-messaging-system-generated-message>
            </li>
          </ng-template>
        </ng-container>
      </ng-container>
    </ul>
    <p class="typing" *ngIf="!shouldEnablePreChatWelcome && isTyping">
      Agent is typing
    </p>
    <p *ngIf="shouldEnablePreChatWelcome && isTyping" class="typingIndicator">
      Advocate is typing...
    </p>

    <cigna-omni-messaging-outro
      [outro]="outroObj"
      *ngIf="outroObj?.show && !hasChatOutage"
      [isHistory]="isHistoryView"
      [shouldEnablePreChatWelcome]="shouldEnablePreChatWelcome"
      [canReplaceBackToPreviousWithEndChat]="
        canReplaceBackToPreviousWithEndChat
      "
      [agentLeftMessageCopy]="agentLeftMessageCopy"
      [isEapUser]="isUseRightNowChat"
      [isEvernorth]="isEvernorth"
    ></cigna-omni-messaging-outro>
  </div>
</ng-container>
<cigna-omni-messaging-after-hours-chat
  *ngIf="
    shouldEnablePreChatWelcome &&
    !isLiveChatAvailable &&
    !isHistoryView &&
    !isTerminatedUser &&
    (!conversation || conversation?.type === 'bot') &&
    !hasChatOutage
  "
  [chatCloseHrsMsg]="chatCloseHrsMsg"
>
</cigna-omni-messaging-after-hours-chat>
<cigna-omni-messaging-terminated-user-message
  *ngIf="shouldEnablePreChatWelcome && !isHistoryView && isTerminatedUser"
  [terminatedUserMessage]="terminatedUserMessage"
></cigna-omni-messaging-terminated-user-message>
<cigna-omni-outage-screen
  *ngIf="hasChatOutage && !hasActiveLiveChat"
  [chatOutageMsg]="chatOutageMsg"
  [hasChatOutage]="hasChatOutage"
></cigna-omni-outage-screen>
