// TODO: resolve CHCP disables by bot
/* eslint-disable no-useless-escape */
import {
  FormGroup,
  AbstractControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
  FormControl,
  FormArray,
} from '@angular/forms';

import {
  isDate,
  isBefore,
  isAfter,
  differenceInMonths,
  format,
  subMonths,
} from 'date-fns';
import isObject from 'lodash/isObject';

export const nonEmptyValid = (control: AbstractControl): boolean =>
  control.value && control.valid;

const allEmpty = (controls: AbstractControl[]): boolean =>
  controls.every((control) => control.value === '');

const dateFormat = 'MM/dd/yyyy';
export const anyInvalid = (controls: AbstractControl[]): boolean =>
  controls.some((control) => control.invalid);

export const validCdtInputs = (group: FormGroup) => {
  const tooth: AbstractControl = group.controls.tooth;
  const arch: AbstractControl = group.controls.arch;
  const quadrant: AbstractControl = group.controls.quadrant;

  if (allEmpty([tooth, arch, quadrant])) {
    return { fieldError: true };
  }
  return null;
};

export const validPatientSearchRow: ValidatorFn = (group: FormGroup) => {
  const patientId: AbstractControl = group.controls.patientId;
  const patientDob: AbstractControl = group.controls.patientDob;
  const patientFirstName: AbstractControl = group.controls.patientFirstName;
  const patientLastName: AbstractControl = group.controls.patientLastName;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const fieldErrors = anyInvalid([
    patientId,
    patientDob,
    patientFirstName,
    patientLastName,
  ]);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const emptyRow = allEmpty([
    patientId,
    patientDob,
    patientFirstName,
    patientLastName,
  ]);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const combo1 = nonEmptyValid(patientId) && nonEmptyValid(patientDob);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const combo2 =
    nonEmptyValid(patientId) &&
    nonEmptyValid(patientFirstName) &&
    nonEmptyValid(patientLastName);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const combo3 =
    nonEmptyValid(patientDob) &&
    nonEmptyValid(patientFirstName) &&
    nonEmptyValid(patientLastName);

  if (fieldErrors) {
    return { fieldError: true, comboError: true };
  }
  if (!(emptyRow || combo1 || combo2 || combo3)) {
    return { fieldError: false, comboError: true };
  }
  return null;
};

// group of 3 inputs, with formControl names of phone1, phone2, phone3
export const validateOptionalPhoneGroup = (group: FormGroup) => {
  const phoneCombined: string = ''.concat(
    group.value.phone1,
    group.value.phone2,
    group.value.phone3,
  );

  if (
    phoneCombined !== '' &&
    (phoneCombined.length !== 10 ||
      group.controls.phone1.invalid ||
      group.controls.phone2.invalid ||
      group.controls.phone3.invalid)
  ) {
    return { phoneGroupError: true };
  }
  return null;
};

export const validateCheckboxTextGroup = (group: FormGroup) => {
  if (
    group.controls.checkboxTin.value &&
    group.controls.checkboxTinName.value === ''
  ) {
    return { checkboxTextInput: true };
  }
  return null;
};

export const validateNoRepeatingSpecialCharacters = (
  control: AbstractControl,
) => {
  if (/([\.\'\s\-])\1+/.test(control.value)) {
    return { specialCharacters: true };
  }
  return null;
};

const isValidText = (value: any): boolean => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let result = false;
  if (value && value.length > 0) {
    const repeatEx = /([\.\'\s\-])\1+/;
    const charsEx = /^[a-zA-Z.' \-]{1,}$/;
    result = !repeatEx.test(value) && charsEx.test(value);
  }
  return result;
};

// TODO: update refffs renamed validator from isValidText to validText
export const validText: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const isText = isValidText(control.value.trim());
  return isText ? null : { isValidText: 'is not a valid text' };
};

export const areChildrenEqual: ValidatorFn = (
  group: FormGroup,
): ValidationErrors | null => {
  const [parent, ...children] = Object.keys(group.controls);
  const isEqual = children.every((child): boolean => {
    const c = group.get(child);
    const p = group.get(parent);
    if (c === null || p === null) {
      return false;
    }
    return c.value === p.value;
  });
  return isEqual ? null : { areChildrenEqual: true };
};

export function validMultiSelectOptions(
  min: number,
  max: number,
): ValidationErrors | null {
  return (control: FormControl) => {
    if (control.value.length > max || control.value.length < min) {
      return { multiSelectOptionsError: true };
    }

    return null;
  };
}

export const validCignaDate: ValidatorFn = (
  control: FormControl,
): ValidationErrors | null => {
  const isValid = isDate(control.value);
  return isValid ? null : { isValidDate: 'not a valid date' };
};

export const validCignaDateOptional: ValidatorFn = (
  control: FormControl,
): ValidationErrors | null => {
  const isValid =
    control.value === '' || control.value === null || isDate(control.value);
  return isValid ? null : { isValidDate: 'not a valid date' };
};

export function validMinDate(minDate: Date): ValidationErrors | null {
  return (control: FormControl) => {
    const isValid = isDate(control.value);
    if (!isValid) {
      return { minDateError: true };
    }
    const mDate = new Date(format(minDate, dateFormat));
    const cDate = new Date(format(control.value, dateFormat));
    if (isBefore(cDate, mDate)) {
      return { minDateError: true };
    }
    return null;
  };
}

export function validMaxDate(maxDate: Date): ValidationErrors | null {
  return (control: FormControl) => {
    if (isAfter(control.value, maxDate)) {
      return { minDateError: true };
    }
    return null;
  };
}

export const validDateBeforeToday: ValidatorFn = (
  control: FormControl,
): ValidationErrors | null => {
  if (isAfter(control.value, new Date())) {
    return { maxDateError: true };
  }
  return null;
};

export function validDateRangeOrder(group: FormGroup): ValidationErrors | null {
  if (isBefore(group.controls.endDate.value, group.controls.startDate.value)) {
    return { endDateBeforeError: true };
  }
  return null;
}

export function validDateRange(months: number): ValidationErrors | null {
  return (group: FormGroup) => {
    const endDate = group.controls.endDate.value;
    const startDate = group.controls.startDate.value;
    if (
      isDate(endDate) &&
      isDate(startDate) &&
      isBefore(
        new Date(format(startDate, dateFormat)),
        new Date(format(subMonths(endDate, months), dateFormat)),
      )
    ) {
      return { monthSpanError: true };
    }
    return null;
  };
}
export function validDateRangeSpanMonths(
  months: number,
): ValidationErrors | null {
  return (group: FormGroup) => {
    if (
      differenceInMonths(
        group.controls.endDate.value,
        group.controls.startDate.value,
      ) > months
    ) {
      return { monthSpanError: true };
    }
    return null;
  };
}

// Allow min max characters with 8-12 letters
export function minMaxLengthWithspaces(): ValidatorFn {
  return (control: AbstractControl) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let result = false;
    const passwordRegEx = /^(\S|\s){8,64}$/;
    result = passwordRegEx.test(control.value);
    return result ? null : { invalidMinMaxPassword: true };
  };
}
// Allow atleast 2 letter and 1 number
export function atleastOneLetter(): ValidatorFn {
  return (control: AbstractControl) => {
    const passwordRegEx = /^(?:(?=.*\d){1,}.*)$/;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const result = passwordRegEx.test(control.value);
    return result ? null : { invalidAtleatOnePassword: true };
  };
}

// Allow Accepted symbols: _ ! . & @ -
export function acceptSymbols(): ValidatorFn {
  return (control: AbstractControl) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let result = false;
    const passwordRegEx = /^((?=.*[!_.&@-]).*)(^[a-zA-Z\d !_.&@-]+$)/;
    result = passwordRegEx.test(!control.value ? ' ' : control.value);
    return result ? null : { invalidAcceptSymbols: true };
  };
}

// Minimum: (1 Upper case and 1 Lower case)
export function minOneUpperLower(): ValidatorFn {
  return (control: AbstractControl) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let result = false;
    const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
    result = passwordRegEx.test(control.value);
    return result ? null : { invalidOneUpLower: true };
  };
}
// Maximum 2 characters can be repeated
export function maxTwoCharRepeat(): ValidatorFn {
  return (control: AbstractControl) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let result = false;
    const passwordRegEx = /^(?!.*([a-zA-Z\d!_.&@ -])\1{2})/;
    result = passwordRegEx.test(!control.value ? '   ' : control.value);
    return result ? null : { invalidMaxTwoCharRepeat: true };
  };
}
// Disallow First Name, Last Name, User ID
export function disallowNameId(excludeItems: string[]): ValidatorFn {
  const validExcludeItems = [] as string[];
  for (const item of excludeItems) {
    if (item && item.length !== 1) {
      validExcludeItems.push(item);
    }
  }

  return (control: AbstractControl) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let result = false;
    const excludeRegx = validExcludeItems.join('|');
    let passwordRegEx = new RegExp(excludeRegx, 'i');
    if (!excludeRegx) {
      passwordRegEx = /^\S$/i;
    }
    result = !control.value ? false : !passwordRegEx.test(control.value);
    return result ? null : { invalidDisallowNameId: true };
  };
}
export function passwordStrengthRulesList(excludeItems: string[]) {
  const valRuleList = [
    Validators.required,
    minMaxLengthWithspaces(),
    atleastOneLetter(),
    acceptSymbols(),
    minOneUpperLower(),
    maxTwoCharRepeat(),
  ];
  if (excludeItems) {
    valRuleList.push(disallowNameId(excludeItems));
  }
  return valRuleList;
}

// somewhat modeled after https://rxweb.io/form-validations/requiredTrue/validators#conditionalexpression, but that lib was huge so recreating part i needed
export function requiredIfCondition(config: {
  conditionalExpression: (x: any) => boolean;
}): ValidatorFn {
  return (control: FormControl | FormGroup) => {
    if (!control.parent) {
      return null;
    }

    if (
      config.conditionalExpression(control.parent.value) &&
      (isObject(control.value) && !isDate(control.value)
        ? Object.values(control.value).every(
            (nestedValue) => nestedValue === '',
          )
        : control.value === '')
    ) {
      return { requiredIf: true };
    }
    return null;
  };
}
/* check if Minimum number of checkboxes required are selected */
/* control name is required if array includes controls and if the control value should be true for at least 1 array */
export function minSelectedCheckboxes(
  min = 1,
  controlName?: string,
): ValidatorFn {
  return (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map((control) => control.value)
      .map((control) => (controlName ? control[controlName] : control))
      .reduce((prev, next) => (next ? prev + next : prev), 0);
    return totalSelected >= min ? null : { required: true };
  };
}
