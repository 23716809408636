<div
  cignaDndFiles
  class="dropzone"
  [ngClass]="{
    hovered: isHovered && !isUploadDisabled,
    'mandatory-attachments': attachmentsRequired
  }"
  (hovered)="onHover($event)"
  (dropped)="onFilesUpload($event, FileUploadMethod.drag)"
  data-test="dropzone"
>
  <input
    type="file"
    id="browseFilesRef"
    [disabled]="isUploadDisabled"
    multiple
    (change)="onFilesUpload($event.target.files, FileUploadMethod.browse)"
    onclick="this.value = null"
    [accept]="acceptExt"
    data-test="file-input"
  />
  <cigna-icon type="documents" class="icon"></cigna-icon>
  <span data-test="dropzone-text">Drag and Drop your files here</span>
  <span>or</span>
  <label
    for="browseFilesRef"
    class="browse-label cg-link-color"
    data-test="file-input-label"
    >Browse your computer</label
  >
</div>
