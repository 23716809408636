import { Component, Input } from '@angular/core';
import { IconDynamicColors } from '../../interfaces/colors';
import { IconDynamicData } from '../../interfaces/data';

@Component({
  selector: 'cigna-icon-dynamic-message-chat',
  templateUrl: './message-chat-icon.component.html',
})
export class IconDynamicMessageChatComponent {
  @Input() public colors: IconDynamicColors;
  @Input()
  public set data(payload: IconDynamicData) {
    if (!payload) {
      return;
    }

    if (payload.showBorder) {
      this.showBorder = !!payload.showBorder;
    }

    if (payload.size) {
      this.size = payload.size;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public showBorder = false;
  public size = '36px';
}
