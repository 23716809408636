import { Injectable } from '@angular/core';
import { OmniAnalyticsService } from '@cigna/omni/shared-util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import {
  dismissFancyInvitation,
  trackReplyToMessageFancyInvitation,
  fancyInvitationsViewed,
} from './fancy-invitations.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Injectable()
export class FancyInvitationsEffects {
  constructor(
    private actions$: Actions,
    private analyticsService: OmniAnalyticsService,
    public featuresFacade: FeatureTogglesFacade,
  ) {}

  fancyInvitationsViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fancyInvitationsViewed),
        withLatestFrom(this.featuresFacade.featuresEnabled(['enableNewDMUI'])),
        tap(([, canEnableNewDMUI]) => {
          if (!canEnableNewDMUI) {
            return this.analyticsService.trackEvent(
              'fancyInvitationViewed',
              {},
            );
          }
          return this.analyticsService.dataLayer.updateAdobeDataLayer({
            event: 'pageAction',
            actionData: {
              actionName: 'DM window pop up',
              hitType: 'action',
              widgetName: 'dm-pop-up-display',
              customLinkText: 'DM pop up display',
              requirementId: 'ac-CHTB032',
              userStory: 'ODCTC-2658',
            },
          });
        }),
      ),
    { dispatch: false },
  );

  fancyInvitationsDismissed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dismissFancyInvitation),
        withLatestFrom(this.featuresFacade.featuresEnabled(['enableNewDMUI'])),
        tap(([, canEnableNewDMUI]) => {
          if (!canEnableNewDMUI) {
            return this.analyticsService.trackEvent(
              'fancyInvitationDismissed',
              {},
            );
          }
          return this.analyticsService.dataLayer.updateAdobeDataLayer({
            event: 'pageAction',
            actionData: {
              actionName: 'Close DM pop up',
              hitType: 'action',
              widgetName: 'dm-pop-up-close-x',
              customLinkText: 'DM pop-up CloseX',
              requirementId: 'ac-CHTB038',
              userStory: 'ODCTC-2659',
            },
          });
        }),
      ),
    { dispatch: false },
  );

  fancyInvitationsReplyToMessageAnalytics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackReplyToMessageFancyInvitation),
        withLatestFrom(this.featuresFacade.featuresEnabled(['enableNewDMUI'])),
        tap(([, canEnableNewDMUI]) => {
          if (!canEnableNewDMUI) {
            return this.analyticsService.trackEvent(
              'fancyInvitationReplyToMessage',
              {},
            );
          }
          return this.analyticsService.dataLayer.updateAdobeDataLayer({
            event: 'pageAction',
            actionData: {
              actionName: 'Reply to message',
              hitType: 'action',
              widgetName: 'dm-reply-message-link',
              customLinkText: 'Reply to message',
              requirementId: 'acCHT007',
              userStory: 'ODCTC-2487',
            },
          });
        }),
      ),
    { dispatch: false },
  );
}
