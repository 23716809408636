export const getDaysofMonth = (month: number, year?: number): string[] => {
  const days = [];
  let index = 31;
  if (month === 2) {
    index = 28;
    if (year && year % 4 === 0) {
      index = 29;
    }
  } else if ((month % 2 === 0 && month < 8) || (month % 2 === 1 && month > 8)) {
    index = 30;
  }
  for (let i = 1; i <= index; i += 1) {
    const temp = (i < 10 ? '0' : '') + i;
    days.push(temp.toString());
  }
  return days;
};

export const getYears = (limit: number): string[] => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i > currentYear - limit; i -= 1) {
    years.push(i.toString());
  }
  return years;
};

export const apiDateFormat = 'yyyy-MM-dd';
export const uiDateFormat = 'MM/dd/yyyy';
