<cigna-omni-chat-bubble-container
  (events)="handleEvent($event)"
  [chatBubbleData]="chatBubbleData$ | async"
  [iconColors]="iconColors$ | async"
  [showButton]="showChatBubble$ | async"
  [icon]="icon"
  [shouldShowNewBubble]="enableNewPreChatWelcome$ | async"
  [isShellOpenOrMinimized]="isShellOpenOrMinimized$ | async"
  [isBubbleExpand]="expanderBubble$ | async"
  [isShellMinimized]="isShellMinimized$ | async"
  [isLiveChatAvailable]="isLiveChatAvailable"
  [isTerminatedUser]="isTerminatedUser$ | async"
  [isDcmChat]="isDcmChat$ | async"
  [hasChatOutage]="hasChatOutage$ | async"
></cigna-omni-chat-bubble-container>
