import {
  Component,
  Input,
  Output,
  EventEmitter,
  Optional,
  OnDestroy,
} from '@angular/core';
import { CollapsibleGroupComponent } from '../collapsible-group/collapsible-group.component';
import { Subscription } from 'rxjs';

/**
 * @deprecated
 *
 * Please use newer cigna-collapsible or @cigna/shared/angular/accordion-ui instead.
 */
@Component({
  selector: 'cigna-collapsible-block',
  templateUrl: './collapsible-block.component.html',
  styleUrls: ['./collapsible-block.component.scss'],
})
export class CollapsibleBlockComponent implements OnDestroy {
  /** subscription to openAll/closeAll events coming from collapsible-group */
  private _openCloseAllSubscription = Subscription.EMPTY;

  constructor(@Optional() public collapsibleGroup: CollapsibleGroupComponent) {
    if (this.collapsibleGroup) {
      this._openCloseAllSubscription = this.subscribeToOpenCloseAllActions();
    }
  }

  @Input()
  get expanded(): boolean {
    return this._isExpanded;
  }

  set expanded(expanded: boolean) {
    if (this._isExpanded !== expanded) {
      this._isExpanded = expanded;
    }
  }

  private _isExpanded = false;

  @Input()
  get blocked(): boolean {
    return this._isBlocked;
  }

  set blocked(blocked: boolean) {
    if (this._isBlocked !== blocked) {
      this._isBlocked = blocked;
    }
  }

  private _isBlocked = false;

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disabled = false;

  @Input() headerText: string;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  toggle(): void {
    if (!this.disabled) {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.opened.emit();
      } else {
        this.closed.emit();
      }
    }
  }

  private subscribeToOpenCloseAllActions(): Subscription {
    return this.collapsibleGroup._openCloseAllActions.subscribe((expanded) => {
      if (!this.disabled) {
        this.expanded = expanded;
        if (this.expanded) {
          this.opened.emit();
        } else {
          this.closed.emit();
        }
      }
    });
  }

  ngOnDestroy() {
    this._openCloseAllSubscription.unsubscribe();
  }
}
