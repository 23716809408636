import {
  Directive,
  Output,
  Renderer2,
  ElementRef,
  OnInit,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { default as ResizeObserverPony } from 'resize-observer-polyfill';

import {
  ResizeObserverLike,
  WindowService,
} from '@cigna/shared/angular/core/window-util';

@Directive({
  selector: '[cignaScrollable],[cignaResized]',
  exportAs: 'cignaScrollable',
})
export class ScrollableDirective implements OnInit, OnDestroy {
  private _cleanup?: () => void;
  private _resized = new Subject<HTMLElement>();

  isScrollable: { vertical: boolean; horizontal: boolean } = {
    vertical: false,
    horizontal: false,
  };

  @Output('cignaResized') resized = this._resized.pipe(
    throttleTime(50, undefined, { leading: true, trailing: true }),
  );

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
    private _zone: NgZone,
    private _window: WindowService,
  ) {}

  ngOnInit() {
    this._zone.runOutsideAngular(() => {
      this.onResize();

      const disconnect = this.connectObserver(
        new (this._window.ResizeObserver || ResizeObserverPony)(this.onResize),
      );

      const sub = this.resized.subscribe((e) => this.detectScrollable(e));

      this._cleanup = () => {
        disconnect();

        sub.unsubscribe();
      };
    });
  }

  ngOnDestroy() {
    if (this._cleanup) {
      this._cleanup();
    }
  }

  private onResize = () => {
    this._zone.run(() => this._resized.next(this._elementRef.nativeElement));
  };

  private detectScrollable(element: HTMLElement) {
    this.isScrollable = { vertical: false, horizontal: false };

    if (element.scrollHeight > element.clientHeight) {
      this.isScrollable.vertical = true;

      this._renderer.addClass(element, 'cigna-scrollable-v');
    } else {
      this._renderer.removeClass(element, 'cigna-scrollable-v');
    }

    if (element.scrollWidth > element.clientWidth) {
      this.isScrollable.horizontal = true;

      this._renderer.addClass(element, 'cigna-scrollable-h');
    } else {
      this._renderer.removeClass(element, 'cigna-scrollable-h');
    }
  }

  private connectObserver(observer: ResizeObserverLike) {
    observer.observe(this._elementRef.nativeElement);

    return () => {
      observer.disconnect();
    };
  }
}
