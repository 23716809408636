import * as he from 'he';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

/**
 * Sanitizes the input string according to the following rules:
 * 1. Encoded HTML entities such as &emp; are decoded
 * 2. HTML tags, such as <b> and </b> are removed
 * 3. The following characters are removed: $;,()
 * 4. Unicode characters above code 127 are removed
 *
 * @param text
 * @return an updated string with characters decoded or removed
 */
export function sanitizeText(text: string) {
  const decoded = he.decode(text);

  const regex = /<\/?[^>]*>|[$;,()]/g;
  const replaced = decoded.replace(regex, '');

  let result = '';
  for (let i = 0; i < replaced.length; i += 1) {
    const code = replaced.charCodeAt(i);
    if (code < 128) {
      result += replaced.charAt(i);
    }
  }

  return result.trim();
}

export function sanitizeData<D>(data: D): D {
  const sanitize = (obj: any): any =>
    transform(obj, (accum: any, value: any, key: string) => {
      if (isString(value)) {
        accum[key] = sanitizeText(value);
      } else if (isObject(value)) {
        accum[key] = sanitize(value);
      } else {
        accum[key] = value;
      }
    });

  return sanitize(data);
}
