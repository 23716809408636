import { Injectable } from '@angular/core';
import { WindowService } from '@cigna/shared/angular/core/window-util';
import {
  IconDynamicColors,
  IconDynamicData,
  OmniAnalyticsService,
} from '@cigna/omni/shared-util';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  BubbleClick,
  LaunchChat,
  LaunchSplashView,
} from './chat-bubble.actions';
import {
  getChatBubbleData,
  getIconColors,
  showChatBubble,
} from './chat-bubble.selectors';
import { ScreenSize } from '@cigna/shared/angular/responsive-ui';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { take, filter, switchMap, startWith } from 'rxjs/operators';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';

@Injectable()
export class ChatBubbleFacade {
  public chatBubbleData$: Observable<
    Record<ScreenSize.XSmall | ScreenSize.Medium, IconDynamicData>
  > = this.store$.pipe(select(getChatBubbleData));
  public iconColors$: Observable<IconDynamicColors> = this.store$.pipe(
    select(getIconColors),
  );
  public showChatBubble$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(this.router),
    switchMap((event) => this.store$.pipe(select(showChatBubble(event.url)))),
  );
  isEvernorth: boolean;
  canUpdateAnalyticsData: boolean;

  constructor(
    private analytics: OmniAnalyticsService,
    private store$: Store<any>,
    private _window: WindowService,
    private featureToggleFacade: FeatureTogglesFacade,
    private router: Router,
  ) {
    this.featureToggleFacade
      .featuresEnabled(['isEvernorthEnabled'])
      .pipe(take(2))
      .subscribe((isEvm) => {
        this.isEvernorth = isEvm;
      });
    this.featureToggleFacade
      .featuresEnabled(['enableChatAnalyticsDataLayer'])
      .pipe(take(2))
      .subscribe((canUpdateAnalyticsData) => {
        this.canUpdateAnalyticsData = canUpdateAnalyticsData;
      });
  }

  public chatBubbleClick(text: string): void {
    if (!this.canUpdateAnalyticsData) {
      if (this.isEvernorth) {
        this.analytics.trackEvent('chatBubbleClickOnEvm', { bubbleText: text });
      } else {
        this.analytics.trackEvent('chatBubbleClick', { bubbleText: text });
      }
    }

    this.store$.dispatch(new BubbleClick());
  }

  public launchChat(text: string) {
    this.store$.dispatch(new LaunchChat(text));
  }

  public launchSplashView(text: string) {
    this.store$.dispatch(new LaunchSplashView(text));
  }
}
