import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-omni-messaging-terminated-user-message',
  templateUrl: './terminated-user-message.component.html',
  styleUrls: ['./terminated-user-message.component.scss'],
})
export class OmniMessagingTerminatedUserMessageComponent {
  @Input()
  public terminatedUserMessage: Record<string, string>;

  constructor() {}
}
