// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

export enum ChatBubbleActionTypes {
  BubbleClick = '[Omni - Chat Bubble] BubbleClick',
  LaunchChat = '[Omni - Chat Bubble] LaunchChat',
  LaunchSplashView = '[Omni - Chat Bubble] LaunchSplashView',
}

export class BubbleClick implements Action {
  readonly type = ChatBubbleActionTypes.BubbleClick;
}

export class LaunchChat implements Action {
  readonly type = ChatBubbleActionTypes.LaunchChat;
  constructor(public text: string) {}
}

export class LaunchSplashView implements Action {
  readonly type = ChatBubbleActionTypes.LaunchSplashView;
  constructor(public text: string) {}
}

export const chatBubbleActions = {
  BubbleClick,
  LaunchChat,
  LaunchSplashView,
};

export type ChatBubbleActions = BubbleClick | LaunchChat | LaunchSplashView;
