<section
  class="conv-dialogue-input"
  data-omni-qa="conv-input-section"
  data-test-id="input-conv-textmsg"
  (keydown.enter)="textareaFocus()"
>
  <form
    novalidate
    (ngSubmit)="handleSubmit()"
    [class]="shouldEnablePreChatWelcome ? 'changeColor' : ''"
  >
    <textarea
      [formControl]="formControl"
      [attr.placeholder]="placeholder"
      (keyup)="onChange($event.key === 'Enter')"
      (paste)="changeAfterDomUpdate()"
      (cut)="changeAfterDomUpdate()"
      #textarea
      data-omni-qa="conv-input-text"
      data-test-id="input-conv-textmsg"
      role="textbox"
      rows="1"
      tabindex="0"
      wrap="hard"
      [attr.aria-label]="
        hasCharLimit
          ? 'Enter Text up to ' + maxTextLimit + ' characters'
          : 'Enter Text'
      "
      [maxlength]="hasCharLimit ? maxTextLimit : undefined"
    >
    </textarea>
    <div class="controls">
      <button
        #submit
        type="submit"
        [disabled]="
          formControl.pristine || formControl.disabled || formControl.invalid
        "
        aria-label="Send message"
        data-omni-qa="button-send"
        [class]="shouldEnablePreChatWelcome ? 'changefontColor' : ''"
      >
        {{ submitButtonCopy }}
      </button>
      <div
        class="text-count"
        data-test-id="text-charcount"
        *ngIf="hasCharLimit"
      >
        {{ formControl.value?.length }}/{{ maxTextLimit }}
      </div>
    </div>
  </form>
</section>
