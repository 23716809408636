<ng-container *ngIf="isDCMChat$ | async; else newCardSummary">
  <cigna-dcm-summary-header
    [conversationId]="agentData?.id ?? conversationId"
    [name]="agentData?.firstName"
    [date]="date | omniSummaryCardDatetimePipe | async"
    (buttonClicked)="onClick(agentData?.id)"
    [body]="body"
    [chevronColor]="chevronColor"
    [iconData]="iconData"
    [isClosed]="isClosed"
    [hasNewMessages]="hasNewMessages"
    *ngIf="shouldEnablePreChatWelcome$ | async"
  ></cigna-dcm-summary-header>
</ng-container>
<ng-template #newCardSummary>
  <ng-container *ngIf="shouldEnablePreChatWelcome$ | async; else coverBorder">
    <button (click)="onClick()" class="new-card-summary-copy" type="button">
      <div>
        <div
          *ngIf="
            canEnableNewDMUI &&
            !(state === 'opened' && type === 'async' && !hasNewMessages)
          "
          class="omni-summary-card-label-row"
        >
          <span
            [ngClass]="
              type === 'async' && hasNewMessages ? 'green-new-msg' : 'blue'
            "
            ><cigna-icon
              *ngIf="state === 'closed'"
              [type]="labelIcon"
              class="icon"
            ></cigna-icon
            >{{ typeLabel }}</span
          >
        </div>
        <div
          class="omni-summary-card new-card-summary"
          [ngClass]="{ 'new-dm-ui': canEnableNewDMUI }"
        >
          <div class="omni-summary-card-content">
            <div
              class="omni-summary-card-content-copy lineAlign"
              [ngClass]="{ bold: hasNewMessages }"
            >
              <div
                *ngIf="canEnableNewDMUI"
                class="new-dm-ui-row"
                data-omni-qa="omni-summary-card-content-copy lineAlign"
              >
                <img
                  alt="Cigna"
                  class="cigna-logo logoWrapper"
                  [src]="
                    'assets/images/cignahlthcr_logo_wht_bluebg.svg' | cdnPrefix
                  "
                />
                <span
                  ><strong>{{ lastMessageBy }}</strong
                  ><strong>{{ ' • ' }}</strong>
                  <span>{{ subject }}</span>
                  <strong>{{ ' ' }}</strong>
                  <span data-omni-qa="omni-summary-card-dateTime">{{
                    date | omniSummaryCardDatetimePipe | async
                  }}</span>
                </span>
              </div>
              <div
                *ngIf="!canEnableNewDMUI"
                data-omni-qa="omni-summary-card-content-copy lineAlign"
              >
                <span
                  class="subject"
                  [ngClass]="isClosed ? 'closed grey' : 'green'"
                  data-omni-qa="omni-summary-card-subject"
                  >{{ subject }}</span
                >
                <span class="new-divider"></span>
                <span data-omni-qa="omni-summary-card-dateTime">{{
                  date | omniSummaryCardDatetimePipe | async
                }}</span>
              </div>
              <p data-omni-qa="omni-summary-card-body">{{ body }}</p>
            </div>
          </div>
          <cigna-icon-dynamic-chevron
            [colors]="chevronColor"
            [data]="iconData"
            class="omni-summary-card-chevron"
          ></cigna-icon-dynamic-chevron>
        </div>
      </div>
    </button>
  </ng-container>
</ng-template>
<ng-template #coverBorder>
  <button
    (click)="onClick()"
    class="omni-summary-card cover-border"
    type="button"
  >
    <div class="omni-summary-card-content">
      <cigna-icon-dynamic
        [icon]="icon"
        [colors]="iconColors"
        [data]="iconData"
        class="omni-summary-card-content-icon"
      ></cigna-icon-dynamic>

      <div
        class="omni-summary-card-content-copy"
        [ngClass]="{ bold: hasNewMessages }"
      >
        <div data-omni-qa="omni-summary-card-content-copy">
          <span
            class="subject"
            [ngClass]="{ closed: isClosed }"
            data-omni-qa="omni-summary-card-subject"
            >{{ subject }}</span
          >
          <span class="divider">|</span>
          <span data-omni-qa="omni-summary-card-dateTime">{{
            date | omniSummaryCardDatetimePipe | async
          }}</span>
        </div>
        <p data-omni-qa="omni-summary-card-body">{{ body }}</p>
      </div>
    </div>
    <cigna-icon-dynamic-chevron
      [colors]="chevronColor"
      [data]="iconData"
      class="omni-summary-card-chevron"
    ></cigna-icon-dynamic-chevron>
  </button>
</ng-template>
