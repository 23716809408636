<p *ngIf="!isHTMLString">
  {{ message }}
</p>
<div *ngIf="isHTMLString" [innerHTML]="message" class="sys-message"></div>
<ng-container *ngIf="isTransferMessage && shouldEnablePreChatWelcome">
  <p class="transferText">
    We are currently experiencing higher than normal chat volume. We will join
    your chat as soon as possible.
  </p>
  <p class="thankyouText">Thank you for waiting.</p>
</ng-container>
