import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RoundProgressModule } from 'angular-svg-round-progressbar';

import { UiTabsModule } from '@cigna/shared/angular/tabs-ui';
import { MatIconModule, UiIconModule } from '@cigna/shared/angular/icon-ui';
import { UiButtonModule } from '@cigna/shared/angular/button-ui';
import { UiInputModule } from '@cigna/shared/angular/input-ui';
import { AnalyticsUiModule } from '@cigna/shared/angular/analytics-ui';
import { UiProgressModule } from '@cigna/shared/angular/progress-ui';
import { UiCollapsibleModule } from '@cigna/shared/angular/collapsible-ui';
import { UiAlertModule } from '@cigna/shared/angular/alert-ui';
import {
  CignaLeafHeadingComponent,
  CignaLeafInlineAlertComponent,
} from '@cigna/shared/angular/leaf-ui';

import { FlagComponent } from './components/flag/flag.component';
import { HistoryUiComponent } from './components/history-ui/history-ui.component';
import { BarGraphComponent } from './components/bar-graph/bar-graph.component';
import { ProcessStepsComponent } from './components/process-steps/process-steps.component';
import { ResizeableHeightContainerComponent } from './components/resizeable-height-container/resizeable-height-container.component';
import { FilterTextInputComponent } from './components/filter-text-input/filter-text-input.component';
import { LobSelectorComponent } from './components/lob-selector/lob-selector.component';
import { LoginComponent } from './components/login/login.component';
import { PasswordHintsComponent } from './components/password-hints/password-hints.component';
import { SelectTinsGroupComponent } from './components/select-tins-group/select-tins-group.component';
import { DialogAppealAttachHelpComponent } from './components/dialog-appeal-attach-help/dialog-appeal-attach-help.component';
import {
  MaskTextPipe,
  PhoneNumberPipe,
  RestrictedTextPipe,
  FormatBytesPipe,
  HighlightPipe,
  StateNamePipe,
  SafePipe,
  ObfuscatePipe,
} from './pipes';

import {
  TrimInputWhitespaceDirective,
  HoverDropdownDirective,
} from './directives';

import {
  CignaDateAdapterModule,
  CIGNA_DATE_FORMATS,
} from './modules/cigna-date-adapter.module';

import { ChcpSharedEnvironmentUtilModule } from '@cigna/chcp/shared/environment-util';
import { ChcpSharedLeafIconsUiModule } from '@cigna/chcp/shared/leaf-icons-ui';

import { LobPreferenceDialogComponent } from './components/lob-preference-dialog/lob-preference-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DndFilesDirective } from './components/file-upload-dnd/dnd-files.directive';
import { FileUploadListComponent } from './components/file-upload-list/file-upload-list.component';
import { FileUploadDndComponent } from './components/file-upload-dnd/file-upload-dnd.component';
import { AttachmentsListComponent } from './components/attachments-list/attachments-list.component';
import { ReconsiderationHistoryComponent } from './components/reconsideration-history/reconsideration-history.component';
import { RoundGraphComponent } from './components/round-graph/round-graph.component';
import { PrecertStatusComponent } from './components/precert-status/precert-status.component';
import { ClaimStatusComponent } from './components/claim-status/claim-status.component';
import { CoverageStatusComponent } from './components/coverage-status/coverage-status.component';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
import { FiltersBarSharedComponent } from './components/filters-bar/filters-bar.component';
import { FilterLabelSharedComponent } from './components/filter-label/filter-label.component';
import { FilterMultiselectSharedComponent } from './components/filter-multiselect/filter-multiselect.component';
import { FilterTypeDateSharedComponent } from './components/filter-type-date/filter-type-date.component';
import { TrimInputCurrencyDirective } from './directives/trim-input-currency.directive';
import { DialogVideoEmbedComponent } from './components/dialog-video-embed/dialog-video-embed.component';
import { DialogUpdatePrefLobComponent } from './components/dialog-update-pref-lob/dialog-update-pref-lob.component';
import { PrecertStatusAlertComponent } from './components/precert-status-alert/precert-status-alert.component';
import { LetDirective } from '@ngrx/component';
import { DialogErrorComponent } from './components/dialog-error/dialog-error.component';
import { DialogExplainAccessComponent } from './components/dialog-explain-access/dialog-explain-access.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { LeaveChcpDialogComponent } from './components/leave-chcp-dialog/leave-chcp-dialog.component';
import { CorrespondenceMessagesListComponent } from './components/correspondence-messages-list/correspondence-messages-list.component';
import { TutorialPopoverComponent } from './components/tutorial-popover/tutorial-popover.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { PromoMessagingSectionComponent } from './components/promo-messaging-section/promo-messaging-section.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';

@NgModule({
  imports: [
    CommonModule,
    UiIconModule,
    UiInputModule,
    UiButtonModule,
    UiTabsModule,
    MatIconModule,
    UiCollapsibleModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    RoundProgressModule,
    AnalyticsUiModule,
    MatDialogModule,
    UiProgressModule,
    TranslateModule,
    UiAlertModule,
    FormsModule,
    ChcpSharedEnvironmentUtilModule,
    ChcpSharedLeafIconsUiModule,
    CignaLeafHeadingComponent,
    CignaLeafInlineAlertComponent,
    LetDirective,
    InfiniteScrollModule,
  ],
  declarations: [
    FlagComponent,
    HistoryUiComponent,
    ProcessStepsComponent,
    ResizeableHeightContainerComponent,
    FilterTextInputComponent,
    LoginComponent,
    PasswordHintsComponent,
    BarGraphComponent,
    MaskTextPipe,
    FormatBytesPipe,
    PhoneNumberPipe,
    RestrictedTextPipe,
    HighlightPipe,
    ObfuscatePipe,
    TrimInputWhitespaceDirective,
    TrimInputCurrencyDirective,
    HoverDropdownDirective,
    DndFilesDirective,
    FileUploadComponent,
    FileUploadListComponent,
    FileUploadDndComponent,
    AttachmentsListComponent,
    ClaimStatusComponent,
    CoverageStatusComponent,
    BackToTopComponent,
    RoundGraphComponent,
    ReconsiderationHistoryComponent,
    PrecertStatusComponent,
    StateNamePipe,
    SafePipe,
    SelectTinsGroupComponent,
    DialogAppealAttachHelpComponent,
    FiltersBarSharedComponent,
    FilterLabelSharedComponent,
    FilterMultiselectSharedComponent,
    FilterTypeDateSharedComponent,
    LobPreferenceDialogComponent,
    DialogVideoEmbedComponent,
    DialogUpdatePrefLobComponent,
    PrecertStatusAlertComponent,
    LobSelectorComponent,
    DialogErrorComponent,
    DialogExplainAccessComponent,
    StatusBadgeComponent,
    LeaveChcpDialogComponent,
    CorrespondenceMessagesListComponent,
    TutorialPopoverComponent,
    PasswordInputComponent,
    PromoMessagingSectionComponent,
    DropdownMenuComponent,
  ],
  exports: [
    MatDatepickerModule,
    FlagComponent,
    HistoryUiComponent,
    ProcessStepsComponent,
    ResizeableHeightContainerComponent,
    FilterTextInputComponent,
    LoginComponent,
    PasswordHintsComponent,
    BarGraphComponent,
    MaskTextPipe,
    FormatBytesPipe,
    PhoneNumberPipe,
    RestrictedTextPipe,
    HighlightPipe,
    ObfuscatePipe,
    TrimInputWhitespaceDirective,
    TrimInputCurrencyDirective,
    HoverDropdownDirective,
    DndFilesDirective,
    FileUploadComponent,
    AttachmentsListComponent,
    ClaimStatusComponent,
    CoverageStatusComponent,
    BackToTopComponent,
    RoundGraphComponent,
    ReconsiderationHistoryComponent,
    PrecertStatusComponent,
    StateNamePipe,
    SafePipe,
    SelectTinsGroupComponent,
    DialogAppealAttachHelpComponent,
    FiltersBarSharedComponent,
    FilterLabelSharedComponent,
    FilterMultiselectSharedComponent,
    FilterTypeDateSharedComponent,
    LobPreferenceDialogComponent,
    DialogUpdatePrefLobComponent,
    PrecertStatusAlertComponent,
    LobSelectorComponent,
    DialogErrorComponent,
    StatusBadgeComponent,
    LeaveChcpDialogComponent,
    CorrespondenceMessagesListComponent,
    TutorialPopoverComponent,
    PasswordInputComponent,
    PromoMessagingSectionComponent,
    DropdownMenuComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: DateAdapter,
      useClass: CignaDateAdapterModule,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CIGNA_DATE_FORMATS,
    },
    PhoneNumberPipe,
    DatePipe,
  ],
})
export class ChcpSharedUiModule {}
