import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-omni-comm-channel-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input()
  public omniQaTag: string | null;
}
