import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OmnibusInitializeRetry } from './omnibus-initialization.actions';

@Injectable()
export class OmnibusInitializationFacade {
  constructor(private _store$: Store) {}

  public omnibusInitializeRetry() {
    this._store$.dispatch(new OmnibusInitializeRetry());
  }
}
