import { createReducer, on, Action } from '@ngrx/store';

import * as FancyInvitationsActions from './fancy-invitations.actions';

export const FANCYINVITATIONS_FEATURE_KEY = 'omni-fancy-invitations-feature';

export interface State {
  dismissedDms: string[];
}

export const initialState: State = {
  dismissedDms: [],
};

const fancyInvitationsReducer = createReducer(
  initialState,
  on(FancyInvitationsActions.dismissFancyInvitation, (state, { id }) => ({
    ...state,
    dismissedDms: [...state.dismissedDms, id],
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return fancyInvitationsReducer(state, action);
}
