import { Component } from '@angular/core';
import {
  IconDynamicColors,
  IconDynamicData,
  IconDynamicHeadsetComponent,
  CommChannelContent,
  omniConfig,
} from '@cigna/omni/shared-util';
import { OmniCommChannelFacade } from '../+state/comm-channel.facade';
import { filter, map, take } from 'rxjs/operators';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Component({
  selector: 'cigna-omni-comm-channel-eap',
  templateUrl: './eap.component.html',
  styleUrls: ['./eap.component.scss'],
})
export class CommChannelEapComponent {
  public contextTags = ['advocate', 'member'];
  public content$ = this.commChannelFacade.eapContent$.pipe(
    map((content) => {
      if (content?.icon) {
        return { ...content, icon: IconDynamicHeadsetComponent };
      }
      return content;
    }),
  );
  public contextTags$ = this.featureToggleFacade
    .featuresEnabled(['isEvernorthEnabled'])
    .pipe(
      filter((evmEnabled: boolean) => evmEnabled),
      take(1),
    )
    .subscribe(() => (this.contextTags = ['advocate', 'evn']));
  public iconColors: IconDynamicColors = {
    color: omniConfig.omniColors.gray60,
  };
  public iconData: IconDynamicData = {
    size: '46px',
  };

  constructor(
    private commChannelFacade: OmniCommChannelFacade,
    private featureToggleFacade: FeatureTogglesFacade,
  ) {}

  public interact(content: CommChannelContent) {
    this.commChannelFacade.interact(content, { tags: this.contextTags });
  }
}
