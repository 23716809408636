import { InjectionToken } from '@angular/core';

export interface TealeafCaptureLibrary {
  logCustomEvent(description: string, data: object): void;
}

export const TEALEAF = new InjectionToken<TealeafCaptureLibrary | undefined>(
  'TealeafCaptureLibrary',
  {
    providedIn: 'root',
    factory: tealeafFactory,
  },
);

declare const DCX: TealeafCaptureLibrary;

export function tealeafFactory(): TealeafCaptureLibrary {
  if (typeof DCX !== 'undefined') {
    return DCX;
  }

  // eslint-disable-next-line no-console
  console.warn('Discover capture library (DCX) not found');

  return {
    logCustomEvent: () => {},
  };
}
