import { InjectionToken, Type, Provider } from '@angular/core';
import { Authenticator, AuthError } from '@cigna/shared/angular/auth/oidc-util';

export interface AuthConfig {
  /**
   * Authentication flow implementation.
   */
  authenticator: Type<Authenticator>;
  /**
   * With 'immediate' load, authentication starts with application bootstrap.
   * With 'onDemand' load, authentication starts only when a secure request is made.
   * @default immediate
   */
  loadBehavior?: 'immediate' | 'onDemand';
  /**
   * Error handling implementation.
   */
  errorHandler?: Type<AuthErrorHandler>;
  /**
   * Uris that require authentication.
   *
   * Each entry can contain RegExp statements in a string form.
   */
  secureUris: string[];
  /**
   * Uris that don't require authentication.
   * Authorization header won't be added to such requests.
   * This setting takes precedence over `secureUris` i.e. if a certain
   * request matches both, it will be treated as public.
   *
   * Each entry can contain RegExp statements in a string form.
   */
  publicUris?: string[];
  /**
   * Uris that don't require token refresh.
   * After token is expired, requests won't trigger another authentication and
   * consumer code will receive authentication error.
   *
   * Each entry can contain RegExp statements in a string form.
   */
  noRefreshUris?: string[];
}

export interface AuthErrorHandler {
  onError(error: AuthError): void;
}

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('AUTH_CONFIG');

export function authConfig(config: AuthConfig): Provider {
  return { provide: AUTH_CONFIG, useValue: config };
}
