<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 36 36"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-80.000000, -265.000000)">
      <g transform="translate(80.000000, 265.000000)">
        <rect
          fill-rule="nonzero"
          x="0"
          y="5.68434189e-14"
          [attr.width]="size"
          [attr.height]="size"
        ></rect>

        <circle
          *ngIf="showBorder"
          [attr.stroke]="colors.color"
          stroke-width="1.25"
          fill-rule="nonzero"
          cx="18"
          cy="18"
          r="17.375"
        ></circle>

        <g [attr.fill]="colors.color" data-omni-qa="omni-icon-headset">
          <path
            d="M25.2034464,22.7167905 L25.2034464,16.8204968 C27.9046888,17.0098099 28.1432573,18.3207316 28.1432573,19.7686436 C28.1432573,21.2165557 27.9046888,22.5274774 25.2034464,22.7167905 Z M11.364569,22.7167905 C8.66427708,22.5274774 8.42570852,21.2165557 8.42570852,19.7686436 C8.42570852,18.3207316 8.66427708,17.0098099 11.364569,16.8204968 L11.364569,22.7167905 Z M27.0045915,15.7274279 C26.5939874,10.6140722 23.0506265,7 18.2754534,7 C13.4983794,7 9.95501852,10.6169262 9.54631542,15.7331358 C7.32125965,16.4932421 7,18.3140723 7,19.7686436 C7,21.249852 7.33266532,23.1096864 9.67177777,23.844107 C10.006344,26.9511246 12.1506096,28.4837043 16.2433436,28.4837043 L16.2433436,28.4285276 C16.4990206,28.6673094 16.8392897,28.816667 17.2166273,28.816667 L19.5795015,28.816667 C20.3674431,28.816667 21.0061605,28.1773786 21.0061605,27.3887327 C21.0061605,26.6010381 20.3674431,25.9617497 19.5795015,25.9617497 L17.2166273,25.9617497 C16.5503461,25.9617497 15.9943198,26.4212383 15.8374919,27.0395975 C12.9613626,26.9625405 11.5099913,26.0882756 11.1383566,24.1237957 C11.433003,24.1494814 11.7409561,24.1675565 12.0774233,24.1675565 C12.4709188,24.1675565 12.7902775,23.8479123 12.7902775,23.454065 L12.7902775,16.0832223 C12.7902775,15.689375 12.4709188,15.3697308 12.0774233,15.3697308 C11.6915315,15.3697308 11.3446091,15.3935138 11.0147952,15.4277614 C11.5052389,11.248604 14.3481017,8.42698297 18.2754534,8.42698297 C22.2018547,8.42698297 25.0447175,11.2476526 25.5361117,15.4258588 C25.2110502,15.3925625 24.8688801,15.3697308 24.4905921,15.3697308 C24.0970966,15.3697308 23.7777379,15.689375 23.7777379,16.0832223 L23.7777379,23.454065 C23.7777379,23.8479123 24.0970966,24.1675565 24.4905921,24.1675565 C29.0424042,24.1675565 29.5689659,21.6570178 29.5689659,19.7686436 C29.5689659,18.3093157 29.2458053,16.4837288 27.0045915,15.7274279 Z"
            id="Fill-1"
          ></path>
        </g>

        <circle
          *ngIf="showNotificationBadge"
          [attr.fill]="colors?.badgeFillColor"
          [attr.stroke]="colors?.badgeStrokeColor"
          cx="31.5"
          cy="6.5"
          data-omni-qa="omni-icon-headset-badge"
          fill-rule="nonzero"
          r="4.5"
          stroke-width="1.5"
        ></circle>
      </g>
    </g>
  </g>
</svg>
