<form
  class="resources-search-form"
  ngNoForm
  [action]="formAction"
  method="GET"
  (submit)="submitSearch()"
  [attr.target]="!resourcesPage ? 'resourcesWindow' : '_self'"
>
  <label for="query" class="cdk-visually-hidden">Search Resources</label>
  <input
    #searchInput
    class="resources-search-form__input"
    id="query"
    name="query"
    data-test="txt-search-resources"
    cignaInput
    type="text"
    placeholder="Search Resources"
  />
  <button
    class="resources-search-form__button cg-link-color"
    type="submit"
    aria-label="Search"
  >
    <mat-icon svgIcon="leaf-system-regular-search"></mat-icon>
  </button>
</form>
