// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';
import { ConversationEvent } from '@cigna/vampire-dto';

export enum ChatActionTypes {
  Back = '[Omni - Chat] Back',
  BeforeBack = '[Omni - Chat] BeforeBack',
  HomeView = '[Omni - Chat] Home View',
  GoHome = '[Omni - Chat] Go Home',
  OpenDialog = '[Omni - Chat] Open Dialog',
  DialogView = '[Omni - Chat] Dialog View',
  SplashView = '[Omni - Chat] Splash View',
  ActiveDMsView = '[Omni - Chat] Active DMs View',
  OpenHistory = '[Omni - Chat] Open History',
  OpenDMHistory = '[Omni - Chat] Open DM History',
  DMHistoryView = '[Omni - Chat] DM History View',
  ChatBubbleOpen = '[Omni - Chat] Chat Bubble Open',
  HeaderMinimize = '[Omni - Chat] Header Minimize',
  HeaderMaximize = '[Omni - Chat] Header Maximize',
  HeaderClose = '[Omni - Chat] Header Close',
  AutoMaximize = '[Omni - Chat] Auto Maximize',
  ToggleChatState = '[Omni - Chat] Toggle Chat State',
  OpenConversation = '[Omni - Chat] Open Conversation',
  ToggleMuteState = '[Omni - Chat] Toggle Mute State',
  SetCfeUserType = '[Omni - Chat] SetCfeUserType',
  SetChatByClientList = '[Omni - Chat] SetChatByClientList',
  SetSelectedClientDetail = '[Omni - Chat] SetSelectedClientDetail',
  ShowOptionsMenu = '[Omni - Chat] Should Show Chat Options',
  SetShowOptionsMenu = '[Omni - Chat] Set Should Show Chat Options',
}

export class Back implements Action {
  readonly type = ChatActionTypes.Back;
}

export class BeforeBack implements Action {
  readonly type = ChatActionTypes.BeforeBack;
}

export class HomeView implements Action {
  readonly type = ChatActionTypes.HomeView;
}

export class GoHome implements Action {
  readonly type = ChatActionTypes.GoHome;
}

export class OpenConversation implements Action {
  readonly type = ChatActionTypes.OpenConversation;
  constructor(public convId: string) {}
}

export class OpenDialog implements Action {
  readonly type = ChatActionTypes.OpenDialog;
}

export class DialogView implements Action {
  readonly type = ChatActionTypes.DialogView;
}

export class SplashView implements Action {
  readonly type = ChatActionTypes.SplashView;
}

export class ActiveDMsView implements Action {
  readonly type = ChatActionTypes.ActiveDMsView;
}

export class OpenHistory implements Action {
  readonly type = ChatActionTypes.OpenHistory;
}

export class OpenDMHistory implements Action {
  readonly type = ChatActionTypes.OpenDMHistory;
}

export class DMHistoryView implements Action {
  readonly type = ChatActionTypes.DMHistoryView;
}

export class ChatBubbleOpen implements Action {
  readonly type = ChatActionTypes.ChatBubbleOpen;
}

export class HeaderMinimize implements Action {
  readonly type = ChatActionTypes.HeaderMinimize;
}

export class HeaderMaximize implements Action {
  readonly type = ChatActionTypes.HeaderMaximize;
}

export class HeaderClose implements Action {
  readonly type = ChatActionTypes.HeaderClose;
}
export class AutoMaximize implements Action {
  readonly type = ChatActionTypes.AutoMaximize;
  constructor(public eventType: ConversationEvent['type']) {}
}
export class ToggleChatState implements Action {
  readonly type = ChatActionTypes.ToggleChatState;
}

export class ShowOptionsMenu implements Action {
  readonly type = ChatActionTypes.ShowOptionsMenu;
}

export class ToggleMuteState implements Action {
  readonly type = ChatActionTypes.ToggleMuteState;
}

export class SetCfeUserType implements Action {
  readonly type = ChatActionTypes.SetCfeUserType;
  constructor(public isInternalUser: boolean | undefined) {}
}

export class SetChatByClientList implements Action {
  readonly type = ChatActionTypes.SetChatByClientList;
  constructor(public generalChatByClient: Record<string, boolean>) {}
}

export class SetSelectedClientDetail implements Action {
  readonly type = ChatActionTypes.SetSelectedClientDetail;
  constructor(
    public selectedClientDetail: Record<string, string | number | undefined>,
  ) {}
}

export class SetShowOptionsMenu implements Action {
  readonly type = ChatActionTypes.SetShowOptionsMenu;
  constructor(public showOptionsMenu: boolean) {}
}

export const chatActions = {
  Back,
  BeforeBack,
  HomeView,
  GoHome,
  OpenConversation,
  OpenDialog,
  DialogView,
  SplashView,
  ActiveDMsView,
  OpenHistory,
  OpenDMHistory,
  DMHistoryView,
  ChatBubbleOpen,
  HeaderMinimize,
  HeaderMaximize,
  HeaderClose,
  AutoMaximize,
  ToggleChatState,
  ShowOptionsMenu,
  SetShowOptionsMenu,
  ToggleMuteState,
  SetCfeUserType,
  SetChatByClientList,
  SetSelectedClientDetail,
};

export type ChatActionsUnion =
  | Back
  | HomeView
  | GoHome
  | OpenConversation
  | OpenDialog
  | DialogView
  | SplashView
  | ActiveDMsView
  | OpenHistory
  | OpenDMHistory
  | DMHistoryView
  | ChatBubbleOpen
  | HeaderMinimize
  | HeaderMaximize
  | HeaderClose
  | AutoMaximize
  | ToggleChatState
  | ShowOptionsMenu
  | SetShowOptionsMenu
  | ToggleMuteState
  | SetCfeUserType
  | SetChatByClientList
  | SetSelectedClientDetail;
