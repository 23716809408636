import { Injectable } from '@angular/core';
import { WindowService } from '@cigna/shared/angular/core/window-util';
import {
  FeatureTogglesFacade,
  updateFeatureToggles,
} from '@cigna/shared/angular/features-feature';
import {
  GetLiveAvailabilitySuccess,
  LiveAvailabilityActionTypes,
} from '@cigna/omni/live-availability-state-data-access';
import {
  RightNowActionTypes,
  InitializeWidgetSuccess,
} from '@cigna/omni/right-now-data-access';
import {
  chatBubbleContent,
  OmniAnalyticsService,
} from '@cigna/omni/shared-util';
import {
  MediaQueryService,
  ScreenSize,
} from '@cigna/shared/angular/responsive-ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  getChatBubbleContentId,
  showChatBubble,
} from './chat-bubble.selectors';

@Injectable()
export class ChatBubbleAnalyticsEffects {
  chatStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<
          | GetLiveAvailabilitySuccess
          | ReturnType<typeof updateFeatureToggles>
          | InitializeWidgetSuccess
        >(
          LiveAvailabilityActionTypes.GetLiveAvailabilitySuccess,
          updateFeatureToggles,
          RightNowActionTypes.InitializeWidgetSuccess,
        ),
        withLatestFrom(
          this.store$.pipe(select(getChatBubbleContentId)),
          this.store$.pipe(
            select(showChatBubble(this.windowService.location.href)),
          ),
          this.featuresFacade.featuresEnabled(['enableChatAnalyticsDataLayer']),
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        filter(
          ([, , showBubble, canUpdateAnalyticsData]) =>
            showBubble && !canUpdateAnalyticsData,
        ),
        map(([_, bubbleId]) => bubbleId),
        distinctUntilChanged(),
        tap((bubbleId) => {
          const screenSize = this.mediaQueryService.isSmallerOrEqual(
            ScreenSize.XSmall,
          )
            ? ScreenSize.XSmall
            : ScreenSize.Medium;
          const { lineOne, lineTwo } = chatBubbleContent[bubbleId][screenSize];
          const bubbleText = `${lineOne} ${lineTwo}`;
          // this.analytics.trackEvent('chatStatus', { bubbleText });
        }),
      ),
    { dispatch: false },
  );
  constructor(
    private actions$: Actions,
    private analytics: OmniAnalyticsService,
    private mediaQueryService: MediaQueryService,
    private featuresFacade: FeatureTogglesFacade,
    private store$: Store<any>,
    private windowService: WindowService,
  ) {}
}
