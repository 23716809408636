<svg
  width="10px"
  height="13px"
  viewBox="0 0 10 13"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  [style.transform]="direction"
  data-omni-qa="omni-icon-arrow"
>
  <g
    id="SYMBOLS"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
    stroke-linecap="round"
  >
    <g
      id="Icon-/-Nav-/-Open-/-Blue"
      transform="translate(-39.000000, 0.000000)"
      fill-rule="nonzero"
      [attr.stroke]="colors.color"
      stroke-width="1.5"
    >
      <g
        id="Group-2-Copy"
        transform="translate(43.500000, 6.500000) rotate(180.000000) translate(-43.500000, -6.500000) translate(39.000000, 1.000000)"
      >
        <g
          id="Group"
          transform="translate(4.375000, 8.500000) rotate(-90.000000) translate(-4.375000, -8.500000) translate(1.875000, 4.125000)"
        >
          <path
            d="M0.416666667,4.28571429 L4.58333333,0.38961039"
            id="Line-2"
          ></path>
          <path
            d="M0.416666667,8.18181818 L4.58333333,4.28571429"
            id="Line-2"
            transform="translate(2.500000, 6.233766) scale(-1, 1) translate(-2.500000, -6.233766) "
          ></path>
        </g>
        <path d="M4.25,0.5 L4.25,9.5" id="Line"></path>
      </g>
    </g>
  </g>
</svg>
