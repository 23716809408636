import { FEATURETOGGLES_FEATURE_KEY } from '@cigna/shared/angular/features-feature';
import { CONTENT_BUNDLE_FEATURE_KEY } from '@cigna/shared/angular/resource-content-data-access';

enum ActionTypes {
  Reset = '[Shell] Reset All Stores to Initial State',
}

export function resetReducer(reducer: any) {
  return (state: any, action: any) => {
    if (action.type === ActionTypes.Reset) {
      // preserve router and feature toggle state
      const routerState = state.router;
      const featureTogglesState = state[FEATURETOGGLES_FEATURE_KEY];
      const contentBundleState = state[CONTENT_BUNDLE_FEATURE_KEY];

      // eslint-disable-next-line no-param-reassign
      state = {
        router: routerState,
        [FEATURETOGGLES_FEATURE_KEY]: featureTogglesState,
        [CONTENT_BUNDLE_FEATURE_KEY]: contentBundleState,
      };
    }

    return reducer(state, action);
  };
}
