import { TealeafConfig } from './tealeaf.config';
import { LogEvent, LoggingService } from '@cigna/shared/angular/logging-util';
import { TealeafService } from './tealeaf.service';

export function initializeTealeafFactory(
  config: TealeafConfig,
  tealeaf: TealeafService,
  logging: LoggingService,
) {
  return () => {
    logging.events$.subscribe((event: LogEvent) => {
      if (event.level >= config.logLevel && event.error) {
        tealeaf.logError(pojofyError(event.error));
      }
    });
  };
}

function pojofyError(error: Error): Error {
  return { name: error.name, message: error.message, stack: error.stack };
}
