import { Component, Type } from '@angular/core';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniLiveAvailabilityFacade } from '@cigna/omni/live-availability-state-data-access';
import { OmniCommChannelFacade } from '@cigna/omni/comm-channel-feature';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import {
  IconDynamicChevronComponent,
  IconDynamicColors,
  IconDynamicData,
  IconsDynamic,
  omniConfig,
} from '@cigna/omni/shared-util';

@Component({
  selector: 'cigna-omni-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent {
  public Icon: Type<IconsDynamic> = IconDynamicChevronComponent;
  public IconData: IconDynamicData = {
    direction: 'right',
  };
  public iconColors: IconDynamicColors = {
    color: omniConfig.omniColors.blue,
  };

  public liveChatAvailable$ = this.liveAvailableFacade.liveChatAvailable$;
  public chatCloseHrsMsg$ = this.liveAvailableFacade.chatCloseHrsMsg$;
  public unreadDMConversationCount$ =
    this.conversationsFacade.unreadDMConversationCount$;

  constructor(
    private conversationsFacade: OmniConversationsFacade,
    private liveAvailableFacade: OmniLiveAvailabilityFacade,
    private commChannelFacade: OmniCommChannelFacade,
    private chatFacade: OmniChatFacade,
  ) {}

  launchLiveChat() {
    this.commChannelFacade.chatWithHuman({ tags: ['default'] });
    this.focusOnChatHeader();
  }

  openActiveDMs() {
    this.chatFacade.openActiveDMsView();
    this.focusOnChatHeader();
  }
  private focusOnChatHeader() {
    setTimeout(() => {
      const headerElement = document.querySelector(
        '[data-test-id="header-title-chat"]',
      ) as HTMLElement;
      headerElement.focus();
    }, 100);
  }
}
