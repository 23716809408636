import { Injectable } from '@angular/core';
import { chatActions } from '@cigna/omni/chat-state-data-access';
import { SetSelectedConversationId } from '@cigna/omni/conversations-state-data-access';
import { Action, select, Store } from '@ngrx/store';
import {
  dismissFancyInvitation,
  trackReplyToMessageFancyInvitation,
} from './fancy-invitations.actions';
import * as fromFancyInvitations from './fancy-invitations.reducer';
import * as FancyInvitationsSelectors from './fancy-invitations.selectors';

@Injectable()
export class FancyInvitationsFacade {
  public fancyInvitationConv$ = this.store$.pipe(
    select(FancyInvitationsSelectors.unviewedFancyInvitationConv),
  );

  constructor(private store$: Store<fromFancyInvitations.State>) {}

  dispatch(action: Action) {
    this.store$.dispatch(action);
  }

  public dismissFancyInvitation(id: string) {
    this.dispatch(dismissFancyInvitation({ id }));
  }

  public openConvDetailById(conversationId: string, convType: string) {
    this.dispatch(new SetSelectedConversationId(conversationId, convType));
    this.dispatch(new chatActions.OpenDialog());
    this.dispatch(new chatActions.AutoMaximize('state'));
    this.dispatch(trackReplyToMessageFancyInvitation());
  }
}
