import { Injector, Injectable, NgZone } from '@angular/core';
import { LoggingService } from '@cigna/shared/angular/logging-util';
import { MouseTrackEvent, TRACK_HANDLERS, TrackEvent } from './track-handlers';
import {
  findEventHandler,
  normalizeEventParams,
  normalizeHandlers,
  TRACK_FN,
} from './utils';

@Injectable({ providedIn: 'root' })
export class TrackUtil {
  constructor(
    private _injector: Injector,
    private _logger: LoggingService,
    private _zone: NgZone,
  ) {}

  track(...params: [string, ...unknown[]]) {
    this[TRACK_FN]({ injector: this._injector }, params);
  }

  trackMouseEvent(
    eventName: string,
    mouseEvent: MouseEvent,
    ...extras: unknown[]
  ): void {
    this[TRACK_FN]<MouseTrackEvent>(
      {
        mouseEvent,
        injector: this._injector,
      },
      [eventName, ...extras],
    );
  }

  [TRACK_FN]<TEvent extends TrackEvent>(
    event: TEvent,
    params: string | [string, ...unknown[]],
  ) {
    this._zone.runOutsideAngular(() => {
      const instance = event.injector.get(TRACK_HANDLERS, []);
      const handlersList = normalizeHandlers(instance);

      if (!handlersList.length) {
        this._logger.warn('no track handlers provided');

        return;
      }

      const [eventName, ...extra] = normalizeEventParams(params);
      const { handler, matchingHandlersList } = findEventHandler(
        handlersList,
        eventName,
      );

      if (!handler) {
        this._logger.warn(
          `the specified handler (${eventName}) was not found in the provided track handlers:`,
          handlersList,
        );

        return;
      }

      if (matchingHandlersList.length > 1) {
        this._logger.warn(
          `the specified handler (${eventName}) was found in multiple provided track handlers (will use the first one):`,
          matchingHandlersList,
        );
      }

      handler(event, ...extra);
    });
  }
}
