import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  initialState,
  LiveAvailabilityEffects,
  liveAvailabilityReducer,
  OmniLiveAvailabilityFacade,
  LIVE_AVAILABILITY_FEATURE_KEY,
} from './+state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      LIVE_AVAILABILITY_FEATURE_KEY,
      liveAvailabilityReducer,
    ),
    EffectsModule.forFeature([LiveAvailabilityEffects]),
  ],
  providers: [OmniLiveAvailabilityFacade],
})
export class OmniLiveAvailabilityStateModule {}
