import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  AfterViewInit,
  HostListener,
  SimpleChanges,
} from '@angular/core';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { take } from 'rxjs/operators';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Component({
  selector: 'cigna-omni-shell-prechat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss'],
})
export class OmniShellChatHeaderComponent implements OnChanges, AfterViewInit {
  @ViewChild('headerTitle')
  headerTitle: ElementRef;

  public isBackButtonDisabled$ = this.facade.isBackButtonDisabled$;
  public title$ = this.facade.headerTitle$;
  public headerBackgroundClass$ = this.facade.headerBackgroundClass$;
  public isUnmuted$ = this.facade.isUnmuted$;
  public toggleButton$ = this.facade.toggleChatStateButton$;
  public currentComponent$ = this.facade.currentComponent$;
  public shouldCloseOrExit$ = this.conversationsFacade.closeOrExitFlag$;
  public conversation$ = this.conversationsFacade.conversation$;
  public showOptionsMenu$ = this.facade.showOptionsMenu$;
  public canGoBack$ = this.facade.canGoBack$;
  public enableChatShellDrag$ = this.features.featuresEnabled([
    'moveableChatWindow',
  ]);

  @Input() public isHistory: boolean;
  @Input() public existingConvId: string;
  @Input() public shouldCloseSplashScreen: boolean;
  @Input() public shouldFocusOnHeader: boolean;
  @Input() public isDcmChat: boolean;
  @Input() public canEnableNewDMUI: boolean;
  @Input() shouldEnableChatShellDrag = false;

  @Output() public printEmitter: EventEmitter<void> = new EventEmitter();
  @Output() public closeChat: EventEmitter<void> = new EventEmitter();
  @ViewChild('chatHeader') chatHeader: ElementRef<HTMLElement>;

  isMenuOpen = false;

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const e = event.target as HTMLElement;

    // We do not want to close the menu if the menu icon is clicked
    if (e?.parentElement?.parentElement?.parentElement?.id !== 'menu_icon') {
      this.isMenuOpen = false;
    }
  }

  public toggleMute() {
    this.facade.toggleMuteState();
  }

  constructor(
    public facade: OmniChatFacade,
    public conversationsFacade: OmniConversationsFacade,
    private features: FeatureTogglesFacade,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shouldFocusOnHeader?.currentValue) {
      this.headerTitle.nativeElement.focus();
      this.conversationsFacade.setHeaderFocus(false);
    }
  }

  ngAfterViewInit() {
    this.headerTitle.nativeElement.focus();
  }

  public backToDialog(): void {
    if (this.isHistory) {
      this.conversationsFacade.setConversationId(this.existingConvId);
    }
    this.conversation$.pipe(take(1)).subscribe((conversation) => {
      if (
        !this.isHistory &&
        this.canEnableNewDMUI &&
        conversation?.type === 'async'
      ) {
        this.conversationsFacade.setConversationId('');
      }
    });
    this.facade.back();
    this.headerTitle.nativeElement.focus();
  }

  public toggleChatState(): void {
    this.facade.toggleChatState();
  }

  public closeOrEndChat(close: boolean): void {
    if (close) {
      if (this.existingConvId) {
        this.conversationsFacade.endConversation(this.existingConvId);
      }
      this.conversationsFacade.setConversationId('');
      this.facade.closeDialog();
      this.closeChat.emit();
    } else {
      this.conversationsFacade.setCloseSplashScreen(true);
    }
  }

  public focusOnChatHeader() {
    this.headerTitle.nativeElement.focus();
  }
}
