import { FlaggedPatient, FlaggedClaim } from './flags.model';
import { format } from 'date-fns';

export const removeFlaggedClaims = (
  updatedFlags: FlaggedClaim[],
  existingFlags: FlaggedClaim[],
) =>
  existingFlags.filter(
    (flag) =>
      !updatedFlags.some(
        (updatedFlag) =>
          `${flag.claimSummary.claimCompositeKey}${
            flag.claimSummary.claimReferenceNumber
          }${flag.tinNumbers.join()}` ===
          `${updatedFlag.claimSummary.claimCompositeKey}${
            updatedFlag.claimSummary.claimReferenceNumber
          }${updatedFlag.tinNumbers.join()}`,
      ),
  );

export const removeFlaggedPatients = (
  updatedFlags: FlaggedPatient[],
  existingFlags: FlaggedPatient[],
) =>
  existingFlags.filter(
    (flag) =>
      !updatedFlags.some(
        (updatedFlag) =>
          flag.coverage.compositeKey === updatedFlag.coverage.compositeKey,
      ),
  );

export const addDateToFlaggedClaim = (flag: FlaggedClaim): FlaggedClaim => ({
  ...flag,
  dateFlagged: format(new Date(), 'MM/dd/yyyy'),
});

export const addDateToFlaggedPatient = (
  flag: FlaggedPatient,
): FlaggedPatient => ({
  ...flag,
  dateFlagged: format(new Date(), 'MM/dd/yyyy'),
});
