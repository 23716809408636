import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShellTrackHandlers } from './analytics/shell-track-handlers';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TRACK_HANDLERS } from '@cigna/shared/angular/analytics-ui';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: TRACK_HANDLERS,
      useExisting: ShellTrackHandlers,
    },
  ],
})
export class ChcpAnalyticsUtilModule {}
