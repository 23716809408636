<header
  class="cml-title"
  data-test-id="header-modal"
  [title]="title"
  [ngClass]="{ 'title-less': !hasTitle }"
>
  <div class="cml-title-controls">
    <button
      *ngIf="!modal.options?.static && dismiss"
      cignaButton="link inline no-underline"
      class="cml-btn-dismiss"
      data-test-id="btnDismiss"
      (click)="dismissClick($event)"
      [ngClass]="{
        OneLinkTx: isMyCignaTranslate
      }"
    >
      {{ dismiss }}
      <cigna-icon class="cml-dismiss-icon" type="close-thin"></cigna-icon>
    </button>
    <div *ngIf="imagePath" class="cml-img-container">
      <img class="cml-img OneLinkTx" [alt]="imageAltText" [src]="imagePath" />
    </div>
    <button
      *ngIf="!modal.options?.static && close !== null"
      cignaButton="link inline no-underline"
      class="cml-btn-close"
      data-test-id="btnClose"
      (click)="closeClick($event)"
      [ngClass]="{
        OneLinkTx: isMyCignaTranslate
      }"
    >
      {{ close }}
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </div>
  <button
    *ngIf="hasBackButton"
    cignaButton="link inline"
    aria-label="back"
    class="cml-back-button"
    (click)="backClick($event)"
  >
    <cigna-icon
      class="chevron-left"
      [type]="CignaIcon.ChevronLeft"
      alt="left arrow to go back"
    ></cigna-icon>
    <span class="OneLinkTx">Back</span>
  </button>
  <h2
    *ngIf="title; else titleTemplate?.templateRef"
    [attr.id]="modal.labelledBy"
    data-test-id="header-title"
    [ngClass]="{
      OneLinkTx: isMyCignaTranslate || titleTranslateClass === 'OneLinkTx'
    }"
  >
    {{ title }}
  </h2>

  <div *ngIf="subTitle; else subTitleTemplate?.templateRef">{{ subTitle }}</div>
</header>

<div
  #modalContent
  class="cml-content"
  data-test-id="modal-content"
  [attr.id]="modal.describedBy"
  tabindex="-1"
  cignaScrollable
>
  <ng-content></ng-content>
</div>

<footer class="cml-controls" *ngIf="hasControls">
  <ng-container *ngIf="!buttonsTemplate; else buttonsTemplate">
    <cigna-modal-layout-control *ngIf="ok">
      <button
        cignaButton="primary small"
        (click)="okClick($event)"
        data-test-id="footer-button-ok"
        class="{{ okTranslateClass }}"
        [ngClass]="{
          OneLinkTx: isMyCignaTranslate
        }"
      >
        {{ ok }}
      </button>
    </cigna-modal-layout-control>

    <cigna-modal-layout-control *ngIf="alt">
      <button
        cignaButton="secondary small"
        (click)="altClick()"
        data-test-id="footer-button-cancel"
      >
        {{ alt }}
      </button>
    </cigna-modal-layout-control>
  </ng-container>
</footer>
