import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Action } from '@ngrx/store';

import {
  UserOptionsActionTypes,
  GetLookUpDataSuccess,
  GetLookUpDataError,
} from './user-options.actions';

import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { UserOptionsService } from '../user-options/user-options.service';

@Injectable()
export class UserOptionsEffects {
  constructor(
    private actions$: Actions,
    private userOptionsService: UserOptionsService,
  ) {}

  getLookUpData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOptionsActionTypes.GetLookUpData),
      switchMap(() =>
        this.userOptionsService.getLookUpData().pipe(
          map((data: any) => new GetLookUpDataSuccess(data)),
          catchError((err) => of(new GetLookUpDataError(''))),
        ),
      ),
    ),
  );
}
