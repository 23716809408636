import { Component, AfterViewInit } from '@angular/core';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';

@Component({
  selector: 'cigna-omni-chat-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements AfterViewInit {
  public isMyConversationsFeatured$ =
    this.conversationsFacade.isMyConversationsFeatured$;

  constructor(
    private conversationsFacade: OmniConversationsFacade,
    private chatFacade: OmniChatFacade,
  ) {}

  ngAfterViewInit(): void {
    this.chatFacade.homeView();
  }
}
