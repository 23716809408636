import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureToggleType } from './+state/feature-toggles.actions';

export const LOADER = new InjectionToken<FeatureToggleLoaderConfig>(
  'FEATURE_TOGGLES_LOADER',
);

export interface FeatureToggleLoaderConfig<
  TDep extends FeatureToggleLoaderDep<unknown> = never,
> {
  type: FeatureToggleType;
  namespace?: string;
  load: (dep: InstanceType<TDep>) => Observable<Record<string, boolean>>;
  deps?: [TDep];
}

export type FeatureToggleLoaderDep<T> = new (...args: unknown[]) => T;
