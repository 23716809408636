import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'omniSummaryCardDatetimePipe',
})
export class OmniSummaryCardDatetimePipe implements PipeTransform {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  transform(date: string): Observable<string> {
    if (date && typeof date === 'string') {
      const dateMoment = dayjs(date);
      if (!dateMoment.isValid()) {
        return of('--');
      }

      const timerInterval = dayjs().isSame(dateMoment, 'd') ? 60000 : undefined;

      return (timerInterval ? timer(0, timerInterval) : timer(0)).pipe(
        map(() => {
          const now = dayjs();
          const yesterday = now.clone().subtract(1, 'day');

          if (now.diff(dateMoment, 'm') <= 2) {
            return 'Now';
          }
          if (dateMoment.isSame(now, 'd')) {
            return dayjs(date).format('h:mm A').toString();
          }
          if (dateMoment.isSame(yesterday, 'd')) {
            return 'Yesterday';
          }

          return dayjs(date).format('M/D/YY');
        }),
      );
    }
    return of('--');
  }
}
