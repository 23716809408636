/* eslint-disable @nx/enforce-module-boundaries */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
})
export class HeaderLogoComponent {
  @Input() path = '';
  @Input() shouldUseRouterLink = true;
}
