<div
  [@shell]
  *ngIf="
    (isShellOpenOrMinimized$ | async) &&
    (enableNewPreChatWelcome$ | async) === false
  "
  class="shell OneLinkNoTx"
  [ngClass]="{
    'shell-minimized': isShellMinimized$ | async
  }"
>
  <div
    class="tooltip-text"
    *ngIf="shouldShowToolTip && (enableChatShellDrag$ | async)"
  >
    <p class="tooltip-paragraph-section">
      Tip:
      <span class="tooltip-paragraph"
        >You can drag your chat window around the screen.</span
      >
    </p>
    <button class="close-tip" (click)="closeTooltip()">Ok, thanks</button>
  </div>
  <div class="shell-container">
    <cigna-omni-shell-header></cigna-omni-shell-header>
    <span
      class="OneLinkTx disclaimer-text-spanish"
      *ngIf="
        (isSplashView$ | async) &&
        (isReleaseFlagEnabled$ | async) &&
        isSpanishActive
      "
      >{{ chatSpanishDisclaimer }}</span
    >

    <div class="router" [@flyInOut]="animationState$ | async">
      <ng-container
        *ngComponentOutlet="currentComponent$ | async"
      ></ng-container>
    </div>
  </div>
</div>

<div
  [@shell]
  *ngIf="
    (isShellOpenOrMinimized$ | async) &&
    !shouldHideHeader &&
    (enableNewPreChatWelcome$ | async)
  "
  #chatShell
  class="shell new-box"
  [ngClass]="{
    'shell-minimized': isShellMinimized$ | async
  }"
>
  <div
    class="tooltip-text"
    *ngIf="shouldShowToolTip && (enableChatShellDrag$ | async)"
  >
    <p class="tooltip-paragraph-section">
      Tip:
      <span class="tooltip-paragraph"
        >You can drag your chat window around the screen.</span
      >
    </p>
    <button class="close-tip" (click)="closeTooltip()">Ok, thanks</button>
  </div>
  <div
    class="shell-container"
    *ngIf="{
      canUpdateAnalyticsData: canUpdateAnalyticsData$ | async
    } as data"
  >
    <cigna-omni-shell-header
      *ngIf="(shouldEnablePreChatWelcome$ | async) === false"
    ></cigna-omni-shell-header>
    <cigna-omni-shell-prechat-header
      (closeChat)="closeChatWindow()"
      [isHistory]="isShowHistory$ | async"
      [existingConvId]="existingConvId | async"
      [shouldCloseSplashScreen]="closeSplashScreen$ | async"
      [shouldFocusOnHeader]="shouldFocusOnHeader$ | async"
      [isDcmChat]="isDcmChat$ | async"
      [canEnableNewDMUI]="'enableNewDMUI' | cignaFeatureEnabled"
      (printEmitter)="printTranscript(data.canUpdateAnalyticsData)"
      *ngIf="shouldEnablePreChatWelcome$ | async"
    ></cigna-omni-shell-prechat-header>
    <span
      class="OneLinkTx disclaimer-text-spanish"
      *ngIf="
        (isSplashView$ | async) &&
        (isReleaseFlagEnabled$ | async) &&
        isSpanishActive
      "
      >{{ chatSpanishDisclaimer }}</span
    >
    <div class="router" [@flyInOut]="animationState$ | async">
      <ng-container
        *ngComponentOutlet="currentComponent$ | async"
      ></ng-container>
    </div>
  </div>
</div>
