import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  InterceptionsAnalyticsEffects,
  InterceptionsEffects,
  interceptionsReducer,
  INTERCEPTIONS_FEATURE_KEY,
  OmniInterceptionsFacade,
} from './+state';

@NgModule({
  imports: [
    StoreModule.forFeature(INTERCEPTIONS_FEATURE_KEY, interceptionsReducer),
    EffectsModule.forFeature([
      InterceptionsEffects,
      InterceptionsAnalyticsEffects,
    ]),
  ],
  providers: [OmniInterceptionsFacade],
})
export class OmniInterceptionsStateModule {}
