// eslint-disable-next-line max-classes-per-file
import { UserEvent } from '@cigna/omni/data-access';
import { Action } from '@ngrx/store';
import { PollingState } from './polling.reducer';

export enum PollingActionTypes {
  ChangeRegime = '[Omni - Polling] Change Polling Regime',
  GetEvents = '[Omni - Polling] Get Events',
  PauseEvents = '[Omni - Polling] Pause Get Events',
  ResumeEvents = '[Omni - Polling] Resume Get Events',
  PollingConfigLoaded = '[Omni - Polling] Polling Config Loaded',
  PollingConfigError = '[Omni - Polling] Polling Config Error',
  UserEventReceived = '[Omni - Polling] User Event Received',
}

export class ChangeRegime implements Action {
  public readonly type = PollingActionTypes.ChangeRegime;
  constructor(
    public payload: {
      attempts: number | null;
      rate: number;
      keepalive: boolean;
    },
  ) {}
}

export class GetEvents implements Action {
  public readonly type = PollingActionTypes.GetEvents;
  constructor(public keepalive: boolean) {}
}

export class PauseEvents implements Action {
  public readonly type = PollingActionTypes.PauseEvents;
}
export class ResumeEvents implements Action {
  public readonly type = PollingActionTypes.ResumeEvents;
}

export class PollingConfigLoaded implements Action {
  readonly type = PollingActionTypes.PollingConfigLoaded;
  constructor(public payload: PollingState) {}
}
export class PollingConfigError implements Action {
  readonly type = PollingActionTypes.PollingConfigError;
  constructor(public error: any) {}
}

export class UserEventReceived implements Action {
  public readonly type = PollingActionTypes.UserEventReceived;
  constructor(public userEvent: UserEvent) {}
}

export type PollingAction =
  | ChangeRegime
  | GetEvents
  | PauseEvents
  | ResumeEvents
  | PollingConfigLoaded
  | PollingConfigError
  | UserEventReceived;

export const fromPollingActions = {
  ChangeRegime,
  GetEvents,
  PauseEvents,
  ResumeEvents,
  PollingConfigLoaded,
  PollingConfigError,
  UserEventReceived,
};
