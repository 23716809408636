import { InjectionToken } from '@angular/core';

export type AdobeDataLayer = object[] | undefined;

export const ADOBE_DATA_LAYER = new InjectionToken<AdobeDataLayer>(
  'AdobeAnalyticsDataLayer',
);

// eslint-disable-next-line no-var
export declare var adobeDataLayer: object[];

export function adobeDataLayerFactory(): AdobeDataLayer {
  if (typeof adobeDataLayer !== 'undefined') {
    return adobeDataLayer;
  }

  // eslint-disable-next-line no-console
  console.warn(
    'Global window object "adobeDataLayer" not found, will not be able to send tracking data. make sure following script included in index.html: <script>adobeDataLayer = [];</script>',
  );
}

/*

Adobe Event Based Data Layer (EDDL)

docs: https://confluence.sys.cigna.com/pages/viewpage.action?pageId=696511979

*/

export interface DataLayerPageAction {
  event: 'pageAction';
  reqData: ReqData;
  actionData: ActionData;
  userData?: UserData;
  impressionData?: ImpressionData;
}

export interface DataLayerPageLoad {
  event: 'pageLoad';
  reqData: ReqData;
  pageData: PageData;
  userData?: UserData;
  impressionData?: ImpressionData;
}

export interface DataLayerError {
  event: 'error';
  reqData: ReqData;
  errorData: {
    errorType?: string;
    widgetName?: string;
    customLinkText?: string;
    errorCause: string; // error message displayed to the user
    errorCode: string;
  };
  v2?: string;
}

interface ReqData {
  hitType: string;
  requirementId: string;
  userStory: string;
  language?: string;
}
interface ActionData {
  customLinkText: string;
  widgetName: string;
  actionName?: string; // unclear from BRD spec if this is required
  destinationUrl?: string;
  linkLocation?: string;
  pseudoTin?: string;
  selectedItem?: string;
  producerLob?: string;
}
interface PageData {
  pageName: string;
  siteSection: string;
  URL: string;
  pageVersion?: string;
  previousPageName?: string;
  siteSubSection?: string;
  tabInteractions?: string;
  searchLocationZipCode?: string;
}

interface UserData {
  accountID?: string; // account ids separated by comma
  clientID?: string; // primary client id
  LOB?: string;
  SSOID?: string;
}

interface ImpressionData {
  type?: string;
  eventType?: string;
  slots?: unknown[];
}

/*

Impression Structure

Slot1 - name / label / widget name (format like widget name (all lowercase seperated by -))
Slot2 - reserved for ordinal value - order displayed
Slot3 - category/type
Slot4 - link text
Slot5 - unique identifier
Slot6 - usage drivers (ex: cost) (can be left blank but do not delete line)
*/
