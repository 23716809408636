import { TrackHandlers } from './track-handlers';

export function normalizeEventParams(
  params: string | [string, ...unknown[]],
): [string, ...unknown[]] {
  return typeof params === 'string' ? [params] : params;
}

export function normalizeHandlers(
  handlers: TrackHandlers<unknown> | Array<TrackHandlers<unknown>>,
): Array<TrackHandlers<unknown>> {
  return Array.isArray(handlers) ? handlers : [handlers];
}

export function findEventHandler<K extends string>(
  handlersList: Array<TrackHandlers<unknown>>,
  eventName: K,
): {
  handler: TrackHandlers<Record<K, unknown>>[K] | undefined;
  matchingHandlersList: Array<TrackHandlers<Record<K, unknown>>>;
} {
  const matchingHandlersList = handlersList.filter(
    (h): h is TrackHandlers<Record<K, unknown>> => eventName in h,
  );
  const firstHandlers = matchingHandlersList.length
    ? matchingHandlersList[0]
    : undefined;

  return {
    matchingHandlersList,
    handler: firstHandlers?.[eventName].bind(firstHandlers),
  };
}

export const TRACK_FN = Symbol('TRACK_FN');
