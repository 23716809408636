import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { stringify } from 'qs';
import { GATEWAY_CONFIG, GatewayConfig } from '../gateway.config';

@Injectable({ providedIn: 'root' })
export class UrlFactory {
  constructor(@Inject(GATEWAY_CONFIG) private _config: GatewayConfig) {}

  createUrl<TParams>(
    gateway: keyof GatewayConfig,
    path: string,
    params?: Params<TParams>,
  ): string {
    const pathWithParams =
      Location.stripTrailingSlash(path) + toQueryString(params);

    return Location.joinWithSlash(this._config[gateway], pathWithParams);
  }
}

export type ParamType = string | string[] | number | boolean | undefined;

export type Params<TParams> = { [P in keyof TParams]: ParamType };

export function toQueryString<TParams>(params?: Params<TParams>): string {
  if (!params) {
    return '';
  }

  return stringify(params, {
    indices: false,
    skipNulls: true,
    addQueryPrefix: true,
  });
}
