import { InjectionToken, Injector } from '@angular/core';

export const TRACK_HANDLERS = new InjectionToken<
  TrackHandlers<unknown> | Array<TrackHandlers<unknown>>
>('TRACK_HANDLERS');

export interface TrackEvent {
  injector: Injector;
}

export type TrackHandlers<T, TEvent = TrackEvent> = {
  [P in keyof T]: (trackEvent: TEvent, ...extra: unknown[]) => void;
};

export interface MouseTrackEvent extends TrackEvent {
  mouseEvent: MouseEvent;
}

export interface ValueTrackEvent<T> extends TrackEvent {
  value: T;
}

export interface PageView {
  hier1?: string;
  eVar1?: string;
  eVar2?: string;
  eVar3?: string;
  eVar25?: string;
  eVar31?: string;
  prop1?: string;
  prop2?: string;
  prop3?: string;
  prop13: string;
  prop31?: string;
  prop38?: string;
  channel: string;
  pageName: string;
  eVar121?: string;
  prop67?: string;
  eVar119?: string;
  path?: string;
  prop51?: string;
  eVar111?: string;
  eVar124?: string;
  products?: string;
}

export interface PageAction {
  hier1?: string;
  prop7?: string;
  prop13: string;
  eVar7?: string;
  eVar20?: string;
  eVar31?: string;
  eVar119?: string;
  eVar120?: string;
  prop20?: string;
  prop31?: string;
  prop38?: string;
  linkName: string; // pev2
  channel: string;
  events?: string;
  products?: string;
}
