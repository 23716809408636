import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  exitContactVerification,
  getAllWamTins,
  getMfaTinDetailsByWam,
  isamResendCode,
  isamSendCode,
  isamStepup,
  isamVerifyCode,
  loadUserProfile,
  putMfaTinDetails,
  resetWamState,
  sendContactVerificationCode,
  skipEmailVerification,
  storeMfaWamUpdate,
  storeWamSelectedTins,
  verifyContactCode,
} from './mfa.actions';
import { MfaPartialState } from './mfa.reducer';
import { mfaQuery } from './mfa.selectors';

import {
  ContactMethod,
  MfaSkipType,
  MfaTinDetailsByWam,
  SendCodeRequest,
  VerifyCodeRequest,
} from '../mfa/mfa.model';

@Injectable({
  providedIn: 'root',
})
export class MfaFacade {
  constructor(private store$: Store<MfaPartialState>) {}
  isamContactMethods$ = this.store$.pipe(select(mfaQuery.getIsamContacts));
  isamSelectedContact$ = this.store$.pipe(
    select(mfaQuery.getIsamSelectedContact),
  );
  isamStateAndHint$ = this.store$.pipe(select(mfaQuery.getIsamStateAndHint));
  isamSubmitting$ = this.store$.pipe(select(mfaQuery.getIsamSubmitting));
  isamRetryExceeded$ = this.store$.pipe(select(mfaQuery.getIsamRetryExceeded));
  isamErrorMessage$ = this.store$.pipe(select(mfaQuery.getIsamErrorMsg));
  isamMobileOnlyMfa$ = this.store$.pipe(select(mfaQuery.getIsamMobileOnlyMfa));

  submitting$ = this.store$.pipe(select(mfaQuery.getSubmitting));
  serviceError$ = this.store$.pipe(select(mfaQuery.getServiceError));
  updatedEmail$ = this.store$.pipe(select(mfaQuery.getUpdatedEmail));
  updatedMobilePhone$ = this.store$.pipe(
    select(mfaQuery.getUpdatedMobilePhone),
  );

  wamTins$ = this.store$.pipe(select(mfaQuery.getWamTins));
  wamSelectedTins$ = this.store$.pipe(select(mfaQuery.getWamSelectedTins));
  mfaTinDetails$ = this.store$.pipe(select(mfaQuery.getMfaTinDetailsByWam));
  mfaTinDetailsUpdated$ = this.store$.pipe(
    select(mfaQuery.getMfaTinDetailsByWamUpdated),
  );
  mfaChanges$ = this.store$.pipe(select(mfaQuery.getMfaChanges));

  loadUserProfile() {
    this.store$.dispatch(loadUserProfile());
  }

  skipEmailVerification(count: number, skipType: MfaSkipType) {
    this.store$.dispatch(skipEmailVerification({ count, skipType }));
  }

  exitContactVerification() {
    this.store$.dispatch(exitContactVerification());
  }

  sendContactCode(request: SendCodeRequest) {
    this.store$.dispatch(
      sendContactVerificationCode({
        payload: request,
        isResend: false,
      }),
    );
  }

  resendContactCode(request: SendCodeRequest) {
    this.store$.dispatch(
      sendContactVerificationCode({
        payload: request,
        isResend: true,
      }),
    );
  }

  verifyContactCode(request: VerifyCodeRequest) {
    this.store$.dispatch(verifyContactCode({ payload: request }));
  }

  getMfaTinDetailsByWam() {
    this.store$.dispatch(getMfaTinDetailsByWam());
  }

  storeWamSelectedTins(wamSelectedTins: string[]) {
    this.store$.dispatch(storeWamSelectedTins({ wamSelectedTins }));
  }

  putMfaTinDetails() {
    this.store$.dispatch(putMfaTinDetails());
  }

  storeMfaWamUpdate(mfaTinDetails: MfaTinDetailsByWam[]) {
    this.store$.dispatch(storeMfaWamUpdate({ mfaTinDetails }));
  }

  getAllWamTins() {
    this.store$.dispatch(getAllWamTins());
  }

  resetWamState() {
    this.store$.dispatch(resetWamState());
  }

  isamStepup(isMobileOnlyMfa: boolean) {
    this.store$.dispatch(isamStepup({ isMobileOnlyMfa }));
  }

  isamSendCode(selectedContact: ContactMethod) {
    this.store$.dispatch(isamSendCode({ selectedContact }));
  }

  isamResendCode() {
    this.store$.dispatch(isamResendCode());
  }

  isamVerifyCode(code: string, remember: boolean) {
    this.store$.dispatch(isamVerifyCode({ code, remember }));
  }
}
