import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getDcmAssets,
  getDcmAssetsUnavailable,
  getDcmAssetsFailure,
  getDcmAssetsSuccess,
} from './dcm.actions';
import { DcmAssetsService } from './dcm.service';

@Injectable()
export class SecureDcmAssetsEffects {
  loadAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDcmAssets),
      mergeMap((asset) => {
        const ownerId = asset.fileName;
        if (ownerId) {
          return this.service.getSecureDcmAssets(`${ownerId}.json`).pipe(
            map((response) => {
              if (response) {
                return getDcmAssetsSuccess({ response });
              }
              return getDcmAssetsUnavailable();
            }),
            catchError((error) => of(getDcmAssetsFailure({ error }))),
          );
        }
        return of(getDcmAssetsUnavailable());
      }),
      catchError((error) => of(getDcmAssetsFailure({ error }))),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private service: DcmAssetsService,
  ) {}
}
