import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OmniSharedUiModule } from '@cigna/omni/shared-util';
import { OmniConversationsStateModule } from '@cigna/omni/conversations-state-data-access';
import { OmniFancyInvitationsStateModule } from '@cigna/omni/fancy-invitations-state-data-access';
import { FancyInvitationComponent } from './fancy-invitation/fancy-invitation.component';
import { FancyInvitationsDataComponent } from './fancy-invitations-data/fancy-invitations-data.component';
import { FeaturesModule } from '@cigna/shared/angular/features-feature';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    OmniSharedUiModule,
    OmniConversationsStateModule,
    OmniFancyInvitationsStateModule,
    FeaturesModule,
  ],
  declarations: [FancyInvitationComponent, FancyInvitationsDataComponent],
  exports: [FancyInvitationsDataComponent],
})
export class OmniFancyInvitationsModule {}
