import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map, retry } from 'rxjs/operators';

import {
  UserProfileIndicatorName,
  UserProfile,
  LoginProfileResponse,
} from './user-profile.model';

import { TinMapObject } from '../user-tins/tins.model';

import {
  AppContext,
  checkValidResponse,
  logError,
} from '@cigna/chcp/shared/util';
import { APP_CONTEXT } from '@cigna/chcp/shared/environment-util';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  public profileApi: string;
  public preferencesApi: string;
  public identityApi: string;

  public CONSUMER_CODE = '1000';

  constructor(
    private http: HttpClient,
    @Inject('secureApi') secureApi: string,
    @Inject(APP_CONTEXT) public appContext: AppContext,
  ) {
    this.profileApi = `${secureApi}/profile/v2`;
    this.preferencesApi = `${secureApi}/preferences/v2`;
    this.identityApi = `${secureApi}/identity/profile`;
  }

  getUserProfile() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    return this.http
      .get(`${this.profileApi}/details`, {
        params,
      })
      .pipe(
        retry(2),
        map(checkValidResponse),
        map(this.parseUserResponse),
        catchError(logError),
      );
  }

  getLoginUserProfile(isDentalUser: boolean) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    return this.http
      .get(
        `${this.identityApi}${
          isDentalUser ? '/dent' : this.appContext === 'evernorth' ? '' : '/med'
        }/login-user-profile`,
        {
          params,
        },
      )
      .pipe(
        retry(2),
        map(checkValidResponse),
        map((res) => {
          if (!res.user) {
            throw new Error();
          }
          return res.user;
        }),
        catchError(logError),
      );
  }

  updateUserProfile(userData: Partial<UserProfile>) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    return this.http
      .put(`${this.preferencesApi}/details`, userData, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map(this.parseUserResponse),
        catchError(logError),
      );
  }

  updateUserProfileIndicators(agreementName: UserProfileIndicatorName) {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.CONSUMER_CODE,
    );

    const payload = {
      agreementName,
      agreementIndicator: agreementName === 'wamOnboarding' ? 'N' : '',
    };

    return this.http
      .put(
        `${this.profileApi}/user/agreement` /* not the user agreement show on login, but random user profile info stored in user agreement table :( */,
        payload,
        {
          params,
        },
      )
      .pipe(
        map(checkValidResponse),
        map((res) => res.agreements),
        catchError(logError),
      );
  }

  private parseUserResponse = (res: any): UserProfile => {
    const user = res.user;
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      dob: user.dob,
      phone: user.phone,
      mobilePhone: user.mobilePhone,
      mobileVerified: user.mobileVerified,
      phoneExtn: user.phoneExtn,
      addressLine1: user.addressLine1,
      addressLine2: user.addressLine2,
      email: user.email,
      city: user.city,
      state: user.state,
      zip: user.zip,
      jobRole: user.jobRoles.trim(),
      secPwdLastChangedDate: user.secPwdLastChangedDate,
      contactUpdated: user.contactUpdated,
      contactCntr: user.contactCntr,
      emailVerified: user.emailVerified,
      emailVerifySkipCount: user.emailVerifySkipCount,
      lob: user.lob,
      lobPref: user.lobPref,
      isClaims360PopupSuppressed: user.isClaims360PopupSuppressed,
    };
  };
}
