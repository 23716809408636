import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { stringify } from 'qs';
import { OMNI_CONFIG, OmniConfig } from './gateway.config';
import { Observable, filter, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UrlFactory {
  _config: OmniConfig;
  constructor(@Inject(OMNI_CONFIG) private _config$: Observable<OmniConfig>) {
    this._config$
      .pipe(
        filter((conf: OmniConfig) => !!conf),
        take(1),
      )
      .subscribe((conf: OmniConfig) => (this._config = conf));
  }

  createIdentityUrl<TParams>(path: string, params?: Params<TParams>): string {
    return this.factory(this._config?.omni?.identityUrl, path, params);
  }
  createAwsIdentityUrl<TParams>(
    path: string,
    params?: Params<TParams>,
  ): string {
    return this.factory(this._config.omni.awsIdentityUrl, path, params);
  }
  createBotUrl<TParams>(path: string, params?: Params<TParams>): string {
    return this.factory(this._config?.omni?.botUrl, path, params);
  }
  createLiveUrl<TParams>(path: string, params?: Params<TParams>): string {
    return this.factory(this._config?.omni?.liveUrl, path, params);
  }
  createAwsLiveUrl<TParams>(path: string, params?: Params<TParams>): string {
    return this.factory(this._config.omni.awsLiveUrl, path, params);
  }
  createAssetsUrl<TParams>(path: string, params?: Params<TParams>): string {
    if (this._config.api && this._config.api.gateways) {
      return this.factory(this._config.api.gateways.cdn, path, params);
    }
    return '';
  }

  createPublicUrl<TParams>(path: string, params?: Params<TParams>): string {
    return this.factory(this._config?.omni?.publicUrl as string, path, params);
  }

  private factory<TParams>(
    base: string,
    path: string,
    params?: Params<TParams>,
  ): string {
    const pathWithParams =
      Location.stripTrailingSlash(path) + toQueryString(params);
    return Location.joinWithSlash(base, pathWithParams);
  }
}

export type ParamType = string | string[] | number | boolean | undefined;

export type Params<TParams> = { [P in keyof TParams]: ParamType };

export function toQueryString<TParams>(
  params?: Params<TParams>,
  encode = true,
): string {
  if (!params) {
    return '';
  }

  return stringify(params, {
    encode,
    indices: false,
    skipNulls: true,
    addQueryPrefix: true,
  });
}
