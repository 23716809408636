import { featuresEnabled } from '@cigna/shared/angular/features-feature';
import { chatQuery } from '@cigna/omni/chat-state-data-access';
import { conversationsQuery } from '@cigna/omni/conversations-state-data-access';
import {
  content,
  ConversationDTOStatus,
  isAgentTimedOutConversation,
  isAsyncCHEConversation,
  isAsyncScheduleConversation,
  isAsyncSOConversation,
  isClinicalNursingContextTags,
  isConversation,
  isDmConversation,
  isLiveInitialMessage,
  isLiveScheduleConversation,
  isLiveSOConversation,
  isScheduleCoachingContextTags,
  outroMessages,
} from '@cigna/omni/shared-util';
import {
  ConversationEvent,
  ConversationMessageEventDTO,
} from '@cigna/vampire-dto';
import { createSelector } from '@ngrx/store';
import { OutroObj } from '../interfaces';
import {
  getCachedTransformedEventsV1,
  getCachedTransformedEventsV2,
} from '../transforms/events.transform';

const selectInputEnabled = createSelector(
  conversationsQuery.selectConversation,
  conversationsQuery.selectConversationEvents,
  featuresEnabled(['enableInput']),
  (conversation, conversationEvents, enableInput = false) => {
    const filteredMessages = (conversationEvents || []).filter(
      (event: ConversationEvent): event is ConversationMessageEventDTO =>
        event.type === 'message',
    );

    if (enableInput) {
      return true;
    }

    const message = {
      ...(filteredMessages[filteredMessages.length - 1] || {}),
    };

    if (
      (conversation as ConversationDTOStatus)?.status === 'AGENT_JOINED' &&
      conversation?.transferIds?.length &&
      conversation.state !== 'closed'
    ) {
      return true;
    }

    if (message && message.dialog) {
      if (message.dialog.contentLink && conversation?.state !== 'closed') {
        return true;
      }
      return message.dialog.acceptInput;
    }
    if (conversation && isLiveInitialMessage(conversation)) {
      return false;
    }
    if (conversation && conversation.type === 'bot') {
      return isScheduleCoachingContextTags(conversation.context.tags);
    }
    return conversation && conversation.state !== 'closed';
  },
);

const selectOutro = createSelector(
  conversationsQuery.selectConversation,
  conversationsQuery.selectConversationEvents,
  featuresEnabled(['isEvernorthEnabled']),
  featuresEnabled(['enableRestrictedChatInPortal']),
  featuresEnabled(['enableNewDMUI']),
  (
    conversation,
    conversationEvents,
    isEvernorthEnabled,
    enableRestrictedChatInPortal,
    enableNewDMUI,
  ): OutroObj => ({
    message: isEvernorthEnabled
      ? outroMessages.evernorthMessage
      : enableRestrictedChatInPortal && !enableNewDMUI
      ? outroMessages.thankYouMessage
      : outroMessages.message,
    footer:
      conversation &&
      conversationEvents &&
      isAgentTimedOutConversation(conversationEvents)
        ? outroMessages.agentTimeoutFooter
        : outroMessages.footer,
    show: !!conversation && conversation.state === 'closed',
  }),
);

const selectEventsV1 = createSelector(
  conversationsQuery.selectConversation,
  featuresEnabled(['personalGuideChat']),
  featuresEnabled(['isEvernorthEnabled']),
  featuresEnabled(['enableNewPreChatWelcome']),
  (
    conversation,
    isPersonalGuide,
    isEvernorthEnabled,
    enableNewPreChatWelcome,
  ) =>
    conversation && isConversation(conversation)
      ? getCachedTransformedEventsV1(
          conversation,
          isPersonalGuide,
          isEvernorthEnabled,
          enableNewPreChatWelcome,
        )
      : [],
);

const selectEventsV2 = createSelector(
  conversationsQuery.selectConversation,
  featuresEnabled(['personalGuideChat']),
  featuresEnabled(['isEvernorthEnabled']),
  featuresEnabled(['enableNewPreChatWelcome']),
  (
    conversation,
    isPersonalGuide,
    isEvernorthEnabled,
    enableNewPreChatWelcome,
  ) =>
    conversation && isConversation(conversation)
      ? getCachedTransformedEventsV2(
          conversation,
          isPersonalGuide,
          isEvernorthEnabled,
          enableNewPreChatWelcome,
        )
      : [],
);

const selectEvents = createSelector(
  selectEventsV1,
  selectEventsV2,
  featuresEnabled(['ftr-mycigna-omni-web-12-04-2024-release']),
  (showSelectEventsV1, showSelectEventsV2, isReleaseEnabled) =>
    isReleaseEnabled ? showSelectEventsV2 : showSelectEventsV1,
);

const selectEventsForBackToPrevious = createSelector(
  conversationsQuery.selectConversation,
  conversationsQuery.selectConversationEvents,
  featuresEnabled(['enableNewPreChatWelcome']),
  (conversation, selectConversationEvents, enableNewPreChatWelcome) => {
    if (
      conversation &&
      isConversation(conversation) &&
      enableNewPreChatWelcome
    ) {
      const checkMessageEventLength = selectConversationEvents.filter(
        (e) => e.type === 'message',
      ).length;
      if (
        checkMessageEventLength > 1 &&
        selectConversationEvents.every((event) => event.type !== 'transfer')
      ) {
        return true;
      }
    }
    return false;
  },
);

const replaceBackToPreviousWithEndChat = createSelector(
  conversationsQuery.getConversationMetadata,
  chatQuery.getChatByClientList,
  chatQuery.getSelectedClientDetail,
  (convMeta, generalChatByClient, selectedClientDetail) => {
    const canEnableChat = selectedClientDetail?.cs1id
      ? generalChatByClient?.[selectedClientDetail.cs1id]
      : false;
    return (
      convMeta &&
      ((convMeta.currentType === 'claimDetails' &&
        (!convMeta.claimDetails || convMeta.claimDetails.medicare)) ||
        (convMeta.currentType === 'cfe' && !canEnableChat))
    );
  },
);

const selectConversationType = createSelector(
  conversationsQuery.selectConversation,
  (conversation) => {
    if (!conversation) {
      return 'noop';
    }

    if (isLiveSOConversation(conversation.context, conversation.type)) {
      return 'live';
    }

    if (isAsyncSOConversation(conversation.context, conversation.type)) {
      return 'asyncSupport';
    }

    if (isAsyncCHEConversation(conversation.context, conversation.type)) {
      return 'asyncChe';
    }

    if (isLiveScheduleConversation(conversation.context, conversation.type)) {
      return 'live';
    }

    if (isAsyncScheduleConversation(conversation.context, conversation.type)) {
      return 'asyncScheduleCoaching';
    }

    if (isDmConversation(conversation.context, conversation.type)) {
      return 'dm';
    }

    if (isClinicalNursingContextTags(conversation.context.tags)) {
      return 'hil';
    }

    return 'noop';
  },
);

const selectDisclaimer = createSelector(
  selectConversationType,
  featuresEnabled(['hideDisclaimer']),
  featuresEnabled(['enableNewDMUI']),
  (type, hideDisclaimer, enableNewDMUI) =>
    hideDisclaimer
      ? ''
      : type === 'dm'
      ? enableNewDMUI
        ? content.newDMUIDisclaimer
        : content.dmDisclaimer
      : content.disclaimer,
);

export const messagingQuery = {
  selectConversationType,
  selectDisclaimer,
  selectEvents,
  selectInputEnabled,
  selectOutro,
  selectEventsForBackToPrevious,
  replaceBackToPreviousWithEndChat,
};
