import {
  Component,
  Input,
  ViewChildren,
  QueryList,
  OnDestroy,
} from '@angular/core';

import { NavigationItem, ServiceError } from '@cigna/chcp/shared/util';
import { HoverDropdownDirective } from '@cigna/chcp/shared/ui';
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RouterFacade } from '@cigna/shared/angular/core/router-state-data-access';
import { skip, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cigna-primary-nav',
  templateUrl: './primary-nav.component.html',
  styleUrls: ['./primary-nav.component.scss'],
})
export class PrimaryNavComponent implements OnDestroy {
  @Input() links: NavigationItem[] = [];
  @Input() isImpersonator = false;
  @Input() error?: ServiceError;

  @ViewChildren(HoverDropdownDirective)
  dropdownList: QueryList<HoverDropdownDirective>;

  private _onDestroy$ = new Subject<void>();

  constructor(private routerFacade: RouterFacade) {
    this.routerFacade.url$
      .pipe(
        skip(1),
        filter(() => this.dropdownList && this.dropdownList.length > 0),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() =>
        this.dropdownList.forEach((d) => d.isOpen && d.onFocusOut()),
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
