export function zelisISAMLink(): string {
  let hostname = '';
  let environmentCategory = 'p';
  let environmentNumber = '';
  let appPrefix: 'chcp' | 'epp' = 'chcp';

  if (window.location.host.includes('.evernorth.com')) {
    hostname = window.location.host.split('.evernorth.com')[0];
    appPrefix = 'epp';
  } else if (window.location.host.includes('provider-behavioral.cigna.com')) {
    hostname = window.location.host.split('.cigna.com')[0];
    appPrefix = 'epp';
  } else {
    hostname = window.location.host.split('.cigna.com')[0];
  }

  switch (hostname) {
    case 'd-hcpportal-0019':
      environmentCategory = 'd';
      environmentNumber = 'dev0019';
      break;
    case 'd-hcpportal-0031':
      environmentCategory = 'd';
      environmentNumber = '0031';
      break;
    case 'd-hcpportal-0032':
      environmentCategory = 'd';
      environmentNumber = '0032';
      break;
    case 'd-provider-behavioral':
      environmentCategory = 'd';
      break;
    case 'a-hcpportal-0035':
      environmentCategory = 'a';
      environmentNumber = '0035';
      break;
    case 'a-hcpportal-0036':
      environmentCategory = 'a';
      environmentNumber = '0036';
      break;
    case 'a-provider-behavioral':
      environmentCategory = 'a';
      break;
    case 'cignaforhcp':
    case 'chk-cignaforhcp':
      environmentCategory = 'p';
      break;
    case 'chk-provider-behavioral':
    case 'provider':
      environmentCategory = 'p';
      break;
  }

  // interim evernorth solution send to static login page
  if (appPrefix === 'epp') {
    return 'https://provider.zelispayments.com/';
  }

  return `/isam/sps/${appPrefix}-${environmentCategory}${
    environmentNumber ? `-${environmentNumber}` : ''
  }-idp-zelis/saml20/logininitial?PartnerId=Zelis`;
}
