<cigna-skeleton
  *ngIf="lastName === ''; else utilUser"
  repeat="1"
  layout="10"
  class="nav-skeleton"
>
</cigna-skeleton>

<ng-template #utilUser>
  <div class="util-user" data-test="profile-nav" cignaHoverDropdown>
    <button cignaButton="link" class="util-user-button">
      <mat-icon
        svgIcon="leaf-system-regular-account"
        class="util-user__img"
      ></mat-icon>

      <span class="util-user__name" data-test="header-user-name"
        >{{ firstName }} {{ lastName }}</span
      >
      <cigna-icon type="chevron-thin-down" class="util-dropdown"></cigna-icon>
    </button>

    <div class="util-user__links" #dropdownMenu>
      <div class="util-user__link-item">
        Signed in as (<span cg-test="userid" data-test="header-user-id">{{
          userid
        }}</span
        >)
      </div>

      <ng-container *ngFor="let link of profileLinks">
        <div
          class="util-user__link-item"
          *ngIf="!link.openExternal; else externalLink"
        >
          <a
            [href]="link.path"
            [routerLink]="link.path"
            [class.cg-disabled-link]="isImpersonator && !link.allowImpersonator"
            [class.cg-non-clickable]="isImpersonator && !link.allowImpersonator"
            [attr.data-test]="'profile-nav-child-' + link.identifier"
            [cignaTrackClicks]="[
              'trackEventOther',
              link.title,
              'ac-NV002-Name Subnav',
              { prop20: 'nav-sub-utility' }
            ]"
            >{{ link.title }}</a
          >
        </div>

        <ng-template #externalLink>
          <div class="util-user__link-item">
            <a
              [href]="link.path"
              target="_blank"
              rel="noopener noreferrer"
              [class.cg-disabled-link]="
                isImpersonator && !link.allowImpersonator
              "
              [class.cg-non-clickable]="
                isImpersonator && !link.allowImpersonator
              "
              [attr.data-test]="'profile-nav-child-' + link.identifier"
              [cignaTrackClicks]="[
                'trackEventOther',
                link.title,
                'ac-NV002-Name Subnav',
                { prop20: 'nav-sub-utility' }
              ]"
              >{{ link.title }}
              <mat-icon
                svgIcon="leaf-system-regular-external-link"
                class="cg-mat-icon-link-right"
                [class.cg-disabled-link]="
                  isImpersonator && !link.allowImpersonator
                "
              ></mat-icon>
            </a></div
        ></ng-template>
      </ng-container>
      <div class="util-user__link-item">
        <a
          [href]="inboxUrl"
          target="_blank"
          rel="noopener noreferrer"
          [class.cg-disabled-link]="isImpersonator"
          [class.cg-non-clickable]="isImpersonator"
          [attr.data-test]="'profile-nav-child-inbox'"
          [cignaTrackClicks]="[
            'trackEventOther',
            'Inbox',
            'ac-NV002-Name Subnav',
            { prop20: 'nav-sub-utility' }
          ]"
          >Inbox
          <mat-icon
            svgIcon="leaf-system-regular-external-link"
            class="cg-mat-icon-link-right"
            [class.cg-disabled-link]="isImpersonator"
          ></mat-icon>
        </a>
      </div>
      <div class="util-user__link-item" *ngIf="hasMessageCenterFunction">
        <a
          routerLink="/message-center"
          [attr.data-test]="'lnk-profile-nav-child-mcenter'"
          [cignaTrackClicks]="[
            'trackEventOther',
            'Message Center',
            'ac-NV002-Name Subnav',
            { prop20: 'nav-sub-utility' }
          ]"
          >Message Center</a
        >
      </div>
    </div>
  </div>
</ng-template>
