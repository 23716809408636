import { APP_INITIALIZER, InjectionToken, Provider } from '@angular/core';
import {
  initialize,
  LDClient,
  LDFlagChangeset,
  LDFlagSet,
  LDUser,
} from 'launchdarkly-js-client-sdk';
import { Observable } from 'rxjs';
import sha1 from 'crypto-js/sha1';
import { FeatureToggleType } from '../+state/feature-toggles.actions';
import { FeatureTogglesFacade } from '../+state/feature-toggles.facade';

const ANONYMOUS = 'anonymous';

export class LaunchDarklyStreamingService {
  private client: LDClient | undefined;
  private clientKey: string;
  private variationQueue = new Set<string>();
  private trackQueue = new Set<string>();

  constructor(
    private facade: FeatureTogglesFacade,
    settings$: SettingsAccesssor,
  ) {
    setTimeout(() => {
      settings$.subscribe(({ clientId, userKey, ...rest }) => {
        const clientKey = userKey || ANONYMOUS;
        if (this.clientKey !== clientKey) {
          const user: LDUser = {
            key: sha1(clientKey).toString(),
            anonymous: clientKey === ANONYMOUS,
            custom: rest as Record<string, string | number | boolean>,
          };
          this.closeClient(this.client);
          const client = (this.client = initialize(clientId, user, {
            bootstrap: 'localStorage',
            sendEventsOnlyForVariation: true,
          }));
          this.client.on('ready', () => {
            this.updateFlags(client.allFlags());
            this.processQueues();
          });
          this.client.on('change', (changes: LDFlagChangeset) =>
            this.updateFlags(
              Object.entries(changes).reduce((a: LDFlagSet, [key, c]) => {
                a[key] = c.current;
                return a;
              }, {}),
            ),
          );
          this.clientKey = clientKey;
        }
      });
    }, 0);
  }

  private updateFlags(flags: LDFlagSet) {
    this.facade.updateFeatureToggles(flags, FeatureToggleType.OPS);
  }

  private closeClient(client?: LDClient) {
    client?.close();
  }

  private processQueues() {
    this.variationQueue.forEach((toggle) => this.variation(toggle));
    this.trackQueue.forEach((key) => this.track(key));
    this.variationQueue.clear();
    this.trackQueue.clear();
  }

  variation(toggle: string) {
    if (this.client === undefined) {
      this.variationQueue.add(toggle);
      return;
    }

    this.client.variation(toggle);
  }

  track(key: string) {
    if (this.client === undefined) {
      this.trackQueue.add(key);
      return;
    }

    this.client.track(key);
  }
}

export function useLaunchdarkly<TDeps extends unknown[]>(
  settings: (...dep: UnwrappedDeps<TDeps>) => SettingsAccesssor,
  ...deps: TDeps
): Provider[] {
  return [
    {
      provide: LaunchDarklyStreamingService,
      useFactory: (
        facade: FeatureTogglesFacade,
        ...rest: UnwrappedDeps<TDeps>
      ) => new LaunchDarklyStreamingService(facade, settings(...rest)),
      deps: [FeatureTogglesFacade, ...deps],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => () => {},
      deps: [LaunchDarklyStreamingService],
    },
  ];
}

type UnwrappedDep<D> = D extends InjectionToken<infer U> ? U : Instance<D>;
type Instance<T> = T extends new (...args: unknown[]) => infer U ? U : T;
export type UnwrappedDeps<D extends unknown[]> = {
  [Idx in keyof D]: UnwrappedDep<D[Idx]>;
};

export type SettingsAccesssor = Observable<
  {
    clientId: string;
    userKey?: string;
  } & Partial<Record<string, string | number | boolean>>
>;
