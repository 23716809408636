import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { chatActions } from './chat.actions';
import { ChatState } from './chat.interfaces';
import { chatQuery, getCommChannelContent } from './chat.selectors';
import { OmniAnalyticsService } from '@cigna/omni/shared-util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class OmniChatFacade {
  public backButtonContent$ = this.store$.pipe(
    select(chatQuery.getBackButtonContent),
  );
  public canGoBack$ = this.store$.pipe(select(chatQuery.getCanGoBack));
  public commChannelContent$ = this.store$.pipe(select(getCommChannelContent));
  public currentComponent$ = this.store$.pipe(
    select(chatQuery.getCurrentComponent),
  );
  public direction$ = this.store$.pipe(select(chatQuery.getDirection));
  public historyFilter$ = this.store$.pipe(select(chatQuery.getHistoryFilter));
  public isBackButtonDisabled$ = this.store$.pipe(
    select(chatQuery.isBackButtonDisabled),
  );
  public isShellClosed$ = this.store$.pipe(select(chatQuery.isChatClosed));
  public isShellOpen$ = this.store$.pipe(select(chatQuery.isChatOpen));
  public isShellMinimized$ = this.store$.pipe(
    select(chatQuery.isChatMinimized),
  );
  public headerTitle$ = this.store$.pipe(select(chatQuery.getHeaderTitle));
  public headerBackgroundClass$ = this.store$.pipe(
    select(chatQuery.getHeaderBackgroundClass),
  );
  public shouldClose$ = this.store$.pipe(select(chatQuery.shouldClose));
  public toggleChatStateButton$ = this.store$.pipe(
    select(chatQuery.toggleChatStateButton),
  );
  public isUnmuted$ = this.store$.pipe(select(chatQuery.getMuteState));

  public isHistory$ = this.store$.pipe(select(chatQuery.isHistory));

  public getChatByClientList$ = this.store$.pipe(
    select(chatQuery.getChatByClientList),
  );

  public getIsInternalUser$ = this.store$.pipe(
    select(chatQuery.getIsInternalUser),
  );

  public getSelectedClientDetail$ = this.store$.pipe(
    select(chatQuery.getSelectedClientDetail),
  );

  public showOptionsMenu$ = this.store$.pipe(
    select(chatQuery.getShowOptionsMenu),
  );

  isEvernorth: boolean;
  canUpdateAnalyticsData: boolean;
  canEnableNewDMUI: boolean;

  constructor(
    private analytics: OmniAnalyticsService,
    private store$: Store<ChatState>,
    private featureToggleFacade: FeatureTogglesFacade,
  ) {
    this.featureToggleFacade
      .featuresEnabled(['isEvernorthEnabled'])
      .pipe(take(2))
      .subscribe((isEvm) => {
        this.isEvernorth = isEvm;
      });
    this.featureToggleFacade
      .featuresEnabled(['enableChatAnalyticsDataLayer'])
      .pipe(take(2))
      .subscribe((canUpdateAnalyticsData) => {
        this.canUpdateAnalyticsData = canUpdateAnalyticsData;
      });
    this.featureToggleFacade
      .featuresEnabled(['enableNewDMUI'])
      .pipe(take(2))
      .subscribe((canEnableNewDMUI) => {
        this.canEnableNewDMUI = canEnableNewDMUI;
      });
  }

  public back(): void {
    this.store$.dispatch(new chatActions.BeforeBack());
    this.store$.dispatch(new chatActions.Back());
  }

  public goHome(): void {
    this.store$.dispatch(new chatActions.GoHome());
  }

  public homeView(): void {
    this.store$.dispatch(new chatActions.HomeView());
  }

  public openDialog(): void {
    this.store$.dispatch(new chatActions.OpenDialog());
  }

  public dialogView(): void {
    this.store$.dispatch(new chatActions.DialogView());
  }

  public splashView(): void {
    this.store$.dispatch(new chatActions.SplashView());
  }

  public openActiveDMsView(): void {
    this.store$.dispatch(new chatActions.ActiveDMsView());
  }

  public historyView(): void {
    if (!this.canUpdateAnalyticsData) {
      this.analytics.track('chatHistoryView', {});
    }
  }

  public openHistory(): void {
    if (this.canUpdateAnalyticsData && !this.canEnableNewDMUI) {
      // todo: update to new use data layer format standard
      this.analytics.dataLayer.updateAdobeDataLayer({
        event: 'pageAction',
        actionData: {
          actionName: 'viewChatHistory',
          hitType: 'action',
          widgetName: 'chat-view-history',
          customLinkText: 'View Chat History',
          requirementId: 'ac24061',
          userStory: 'ODCTC-587',
        },
      });
    } else if (this.canEnableNewDMUI) {
      this.analytics.dataLayer.updateAdobeDataLayer({
        event: 'pageAction',
        actionData: {
          actionName: 'Go to conversation history',
          hitType: 'action',
          widgetName: 'conversation-history-link',
          customLinkText: 'Go to conversation history',
          requirementId: 'acCHT009',
          userStory: 'ODCTC-2489',
        },
      });
    } else {
      this.analytics.trackEvent('trackViewChatHistoryClick', {
        linkLabel: 'View Chat History',
      });
      if (this.isEvernorth) {
        const linkText = 'View All';
        this.analytics.trackEvent('chatConversationHistoryViewAll', {
          linkText,
        });
      } else {
        this.analytics.track('viewAllConversations', {});
      }
    }
    this.store$.dispatch(new chatActions.OpenHistory());
  }

  public openDMHistory(): void {
    this.store$.dispatch(new chatActions.OpenDMHistory());
  }

  public showDMHistory(): void {
    this.store$.dispatch(new chatActions.DMHistoryView());
  }

  public toggleChatState(): void {
    this.store$.dispatch(new chatActions.ToggleChatState());
  }

  public toggleMuteState(): void {
    this.store$.dispatch(new chatActions.ToggleMuteState());
  }

  public closeDialog(): void {
    this.store$.dispatch(new chatActions.BeforeBack());
    this.store$.dispatch(new chatActions.HeaderClose());
  }

  public setCfeUserType(isInternalUser: boolean | undefined): void {
    this.store$.dispatch(new chatActions.SetCfeUserType(isInternalUser));
  }

  public setChatByClientList(data: Record<string, boolean>): void {
    this.store$.dispatch(new chatActions.SetChatByClientList(data));
  }

  public setSelectedClientDetail(
    selectedClientDetail: Record<string, string | number | undefined>,
  ): void {
    this.store$.dispatch(
      new chatActions.SetSelectedClientDetail(selectedClientDetail),
    );
  }

  public setShowOptionsMenu(showOptionsMenu: boolean): void {
    this.store$.dispatch(new chatActions.SetShowOptionsMenu(showOptionsMenu));
  }
}
