import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { ConversationDTO } from '@cigna/vampire-dto';
import {
  GatewayConfig,
  SHARED_GATEWAY_CONFIG,
} from '@cigna/shared/angular/gateways-util';
import { DcmAssetsFacade } from '@cigna/omni/dcm-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { Router } from '@angular/router';

@Component({
  selector: 'cigna-dcm-profile-header',
  templateUrl: './dcm-profile-header.component.html',
  styleUrls: ['./dcm-profile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DcmProfileHeaderComponent implements AfterContentInit {
  @Input()
  public name: string;

  @Input()
  public isChatInactive: boolean;

  @Input()
  public disclaimer: string;

  @Input()
  public state: string;

  @Input()
  public context: ConversationDTO['context'];
  conversationId: string;
  cdn: string;

  assets$ = this.dcmAssetsFacade.DcmAssets$;

  constructor(
    private readonly dcmAssetsFacade: DcmAssetsFacade,
    @Inject(SHARED_GATEWAY_CONFIG) private readonly _config: GatewayConfig,
    private _conversations: OmniConversationsFacade,
    private router: Router,
  ) {
    this.cdn = this._config.cdn;
    this._conversations.conversationId$.subscribe((cid) => {
      if (cid) {
        this.conversationId = cid;
      }
    });
  }

  ngAfterContentInit() {
    this.dcmAssetsFacade.loadAssets(this.conversationId);
  }
  goToProfile(): void {
    this.router.navigateByUrl('/care-advocate/profile');
  }
}
