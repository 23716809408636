import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { ConversationDTO } from '@cigna/vampire-dto';
import {
  FancyInvitationsActions,
  FancyInvitationsFacade,
} from '@cigna/omni/fancy-invitations-state-data-access';

@Component({
  selector: 'cigna-omni-fancy-invitation-data',
  templateUrl: './fancy-invitations-data.component.html',
  styleUrls: ['./fancy-invitations-data.component.scss'],
})
export class FancyInvitationsDataComponent {
  constructor(private invitationsFacade: FancyInvitationsFacade) {}
  @Input()
  public fancyInvitationConv$: Observable<ConversationDTO | undefined> =
    this.invitationsFacade.fancyInvitationConv$;

  public handleOpenConvEvent(convData: { id: string; convType: string }) {
    this.invitationsFacade.openConvDetailById(convData.id, convData.convType);
  }

  public handleDismissEvent(id: string) {
    this.invitationsFacade.dismissFancyInvitation(id);
  }

  public handleViewedEvent() {
    this.invitationsFacade.dispatch(
      FancyInvitationsActions.fancyInvitationsViewed(),
    );
  }
}
