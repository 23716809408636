import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import {
  IconDynamicColors,
  IconDynamicData,
  iconDynamicMap,
  IconDynamicMessageChatComponent,
  IconsDynamic,
  omniColors,
  ConversationMessageDialogContentLinkExtn,
} from '@cigna/omni/shared-util';
import { ConversationMessageDialogFileDownload } from '@cigna/vampire-dto';
import {
  ConversationMessageDialogLinkText,
  CtaListItemData,
  MessageEventListItem,
  TableDataMeta,
} from '../interfaces';

@Component({
  selector: 'cigna-omni-messaging-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingItemComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('item') item: ElementRef;

  @Input()
  public props: MessageEventListItem;
  @Output()
  public sendCta = new EventEmitter<CtaListItemData>();
  @Output()
  public backToPrevious: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public minibotLinkClick = new EventEmitter<string>();
  @Input() public shouldEnablePreChatWelcome: boolean;
  @Input() public shouldEnabledBackToPrevious: boolean;
  @Input() public canReplaceBackToPreviousWithEndChat: boolean;
  @Input() public isEvernorth: boolean;
  @Input() public isDcmChat: boolean;

  shouldShowOpenFileError = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public agent: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public bot: boolean;
  public contentLink: ConversationMessageDialogContentLinkExtn | null = null;
  public displayType: string;
  public templateType: string;
  public ctaList: CtaListItemData[] | null = null;
  public ctaFooter: string | null = null;
  public dataList: string[];
  public tableData: TableDataMeta;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public customer: boolean;
  public fileDownload: ConversationMessageDialogFileDownload | null = null;
  public iconType?: Type<IconsDynamic> = IconDynamicMessageChatComponent; // default icon;
  public iconColors: IconDynamicColors = {
    color: omniColors.blue,
  };
  public iconData: IconDynamicData = {
    showBorder: true,
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public showAvatar?: boolean;
  public containerClasses: { [key: string]: boolean | undefined };
  public messageClasses: { [key: string]: boolean | undefined };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private locked = false;
  public linkText: ConversationMessageDialogLinkText;

  public handleCtaEvent(event: CtaListItemData): void {
    this.sendCta.emit(event);
  }

  constructor() {}

  setMeta({ type, data, displayType, footer }: any): void {
    this.displayType = displayType;
    this.templateType = type;
    switch (type) {
      case 'cta_list':
        this.ctaList = data;
        this.ctaFooter = footer;
        break;

      case 'cta_link_list':
        this.ctaList = data.ctaList;
        this.linkText = data.linkText ? data.linkText : null;
        this.ctaFooter = footer;
        break;

      case 'data_list':
        this.dataList = data;
        break;

      case 'table_data':
        this.tableData = data;
        break;

      case 'content_link':
        this.contentLink = data;
        break;

      case 'link_text':
        this.linkText = data;
        break;

      case 'file_download':
        this.fileDownload = data;
        break;

      default:
        break;
    }
  }

  public resetMeta(): void {
    this.ctaList = null;
    this.displayType = '';
    this.ctaFooter = null;
    this.contentLink = null;
    this.fileDownload = null;
  }

  private setClasses() {
    const baseClasses = {
      'conv-message-list-item-agent': this.agent,
      'conv-message-list-item-bot': this.bot,
      'conv-message-list-item-customer': this.customer,
      'conv-message-list-item-first': this.props.firstInSet,
      'conv-message-list-item-single': this.props.single,
      'conv-message-list-item-last': this.props.lastInSet,
    };

    this.containerClasses = {
      ...baseClasses,
      'conv-message-list-item-multiple':
        !this.props.single && !this.props.firstInSet && !this.props.lastInSet,
    };

    this.messageClasses = this.locked
      ? { 'conv-message-list-item-locked': true }
      : {
          ...baseClasses,
          'conv-message-list-item-intermediate':
            !this.props.single &&
            !this.props.firstInSet &&
            !this.props.lastInSet,
          'conv-message-list-item-message': true,
          ctaCover: this.shouldEnablePreChatWelcome,
          'mar-left-none': this.isDcmChat,
        };
  }

  public ngOnInit() {
    if (!this.props) {
      return;
    }
    if (!this.props.message || !this.props.role) {
      this.locked = true;
    }

    this.agent = this.props.role === 'agent';
    this.bot = this.props.role === 'bot';
    this.customer = ['customer', 'chcp'].includes(this.props.role || '');
    if (this.props.avatar) {
      this.iconType = iconDynamicMap[this.props.avatar];
    }
    this.showAvatar =
      !this.customer && (this.props.single || this.props.firstInSet);

    this.disabled =
      this.props.meta && this.props.meta.type === 'cta_list'
        ? this.props.meta.disabled
        : false;
    this.setClasses();
  }

  ngAfterViewInit() {
    if (
      this.templateType !== 'table_data' &&
      this.item.nativeElement.outerText?.includes('delivery prescriptions')
    ) {
      this.item.nativeElement.focus();
    }
  }

  toggleOpenFileError(event: boolean) {
    this.shouldShowOpenFileError = event;
  }

  public ngOnChanges() {
    if (!this.props) {
      return;
    }

    if (this.props.meta) {
      this.setMeta(this.props.meta);
    } else {
      this.resetMeta();
    }

    this.setClasses();
  }
}
