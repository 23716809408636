import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { UserFacade } from '@cigna/chcp/shared/user-profile-data-access';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cigna-dialog-update-pref-lob',
  templateUrl: './dialog-update-pref-lob.component.html',
  styleUrls: ['./dialog-update-pref-lob.component.scss'],
})
export class DialogUpdatePrefLobComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  getUpdatePrefLobSuccess$ = this.userFacade.getUpdatePrefLobSuccess$;
  selectPrefLob = '';

  preferredLOBs: { [key: string]: string } = {
    MEDBH: 'Medical/Behavioral',
    MED: 'Medical',
    BEH: 'Behavioral',
  };
  preferredLobOptions = ['MEDBH', 'MED', 'BEH'];
  lobPrefForm: FormGroup;
  hasSubmitted = false;

  constructor(
    public dialogRef: MatDialogRef<DialogUpdatePrefLobComponent>,
    private fb: FormBuilder,
    private userFacade: UserFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lobPref: string;
      firstName: string;
      lastName: string;
    },
  ) {
    this.lobPrefForm = this.fb.group({
      lobControl: [this.data.lobPref || '', Validators.required],
    });
  }

  get lobControl(): FormControl {
    return this.lobPrefForm.get('lobControl') as FormControl;
  }

  ngOnInit() {
    this.getUpdatePrefLobSuccess$
      .pipe(takeUntil(this._destroy$))
      .subscribe((success) => {
        if (success && this.hasSubmitted) {
          this.dialogRef.close();
        }
      });
  }

  updateUserTinLob() {
    this.hasSubmitted = true;
    this.userFacade.updateProfile({
      lobPref:
        this.lobControl.value === 'MED'
          ? 'CHCP'
          : this.lobControl.value === 'BEH'
          ? 'EBH'
          : this.lobControl.value === 'MEDBH'
          ? 'BOTH'
          : 'TBD',
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
