<button
  (click)="buttonClick()"
  class="omni-summary-card omni-history-chat"
  data-test-id="button-chathistory-card"
  type="button"
>
  <ng-container *ngIf="this.assets$ | async as assets; else defaultAssets">
    <div class="omni-history-chat-avatar">
      <img
        data-test-id="image-chathistory-avatar"
        *ngIf="conversationId && assets.data && assets.data.get(conversationId)"
        [src]="
          assets.data.get(conversationId)
            ? this.cdn + assets.data.get(conversationId).avatar
            : ''
        "
        alt="Agent Avatar"
      />
      <div
        class="omni-history-chat-avatar-default"
        *ngIf="assets.data && !assets.data.get(conversationId)"
      ></div>
      <div
        class="dot red"
        data-test-id="chathistory-reddot"
        *ngIf="!isClosed && hasNewMessages"
      ></div>
    </div>
    <div
      class="omni-history-chat-details"
      data-test-id="text-chathistory-details"
    >
      <div
        class="omni-history-chat-details-name"
        data-test-id="text-chathistory-name"
      >
        <div class="data">
          <span *ngIf="assets.data">{{
            conversationId && assets.data.get(conversationId)
              ? assets.data.get(conversationId).first_name
              : name
          }}</span>
          <span
            class="inactive"
            *ngIf="isClosed"
            [ngClass]="isClosed ? 'closed grey' : 'green'"
            data-omni-qa="omni-summary-card-subject"
            data-test-id="summary-card-subject"
            >Inactive</span
          >
        </div>
        <div class="omni-history-chat-details-name-time">
          <span
            data-omni-qa="omni-summary-card-dateTime"
            data-test-id="summary-card-datetime"
            >{{ date }}</span
          >
          <cigna-icon-dynamic-chevron
            [colors]="chevronColor"
            [data]="iconData"
            class="omni-summary-card-chevron"
          ></cigna-icon-dynamic-chevron>
        </div>
      </div>
      <div class="omni-history-chat-details-message">
        <span
          class="subject"
          data-omni-qa="omni-summary-card-subject"
          data-test-id="chathistory-card-status"
          >{{ body }}</span
        >
      </div>
    </div>
  </ng-container>
</button>
<ng-template #defaultAssets>
  <div class="omni-history-chat-avatar">
    <div class="omni-history-chat-avatar-default"></div>
    <div class="dot red" *ngIf="!isClosed && hasNewMessages"></div>
  </div>
  <div class="omni-history-chat-details">
    <div class="omni-history-chat-details-name">
      <span>{{ name }}</span>
      <span
        class="inactive"
        *ngIf="isClosed"
        [ngClass]="isClosed ? 'closed grey' : 'green'"
        data-omni-qa="omni-summary-card-subject"
        >Inactive</span
      >
    </div>
    <div class="omni-history-chat-details-name-time">
      <span data-omni-qa="omni-summary-card-dateTime">{{ date }}</span>
      <cigna-icon-dynamic-chevron
        [colors]="chevronColor"
        [data]="iconData"
        class="omni-summary-card-chevron"
      ></cigna-icon-dynamic-chevron>
    </div>
    <div class="omni-history-chat-details-message">
      <span class="subject" data-omni-qa="omni-summary-card-subject">{{
        body
      }}</span>
    </div>
  </div>
</ng-template>
