/* eslint-disable sonarjs/no-duplicate-string */
import { Store, select } from '@ngrx/store';

import { UserState } from './user.reducer';
import { userQuery } from './user.selectors';
import { Injectable } from '@angular/core';
import {
  GetUserProfile,
  GetLoginUserProfile,
  LoadTins,
  GetSecurityQuestions,
  GetUserFlaggedPatients,
  GetUserFlaggedClaims,
  GetUserRecentPatients,
  GetUserRecentClaims,
  UpdateUserProfile,
  UpdateUserProfileSuccess,
  UpdateSkipCount,
  LoadTinFunctions,
  UpdateUserProfileIndicator,
} from './user.actions';

import {
  FlaggedClaim,
  FlaggedPatient,
  FlaggedPrecert,
} from '../user-flags/flags.model';
import { FlagUpdateType } from '../user-flags/flags.constant';
import {
  UserProfile,
  MfaSkipRequest,
  UserProfileIndicatorName,
} from '../user-profile/user-profile.model';
import { Entitlement, LOB } from '@cigna/chcp/auth/util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { map, startWith } from 'rxjs/operators';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  DashboardClaimsGQLService,
  DashboardPatientsGQLService,
  DashboardPrecertificationsGQLService,
  FlagClaimInputGQL,
  FlaggedClaimGQL,
  FlaggedPatientGQL,
  FlagTypeGQL,
  GraphQLMutations,
  PrecertResponseGQL,
} from '@cigna/chcp/shared/graphql-util';
import { AppContext } from '@cigna/chcp/shared/util';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  userProfile$ = this.store$.pipe(select(userQuery.getUserProfile));
  firstName$ = this.store$.pipe(select(userQuery.getFirstName));
  preferredLob$ = this.store$.pipe(select(userQuery.getPreferredLob));
  lastName$ = this.store$.pipe(select(userQuery.getLastName));
  userFullName$ = this.store$.pipe(select(userQuery.getUserFullName));
  email$ = this.store$.pipe(select(userQuery.getUserEmail));
  phone$ = this.store$.pipe(select(userQuery.getUserPhone));
  mobilePhone$ = this.store$.pipe(select(userQuery.getUserMobilePhone));
  contactUpdated$ = this.store$.pipe(select(userQuery.getContactUpdated));
  isEmailVerified$ = this.store$.pipe(select(userQuery.isEmailVerified));
  isMobileVerified$ = this.store$.pipe(select(userQuery.isMobileVerified));
  hasUndefinedLobPref$ = this.store$.pipe(
    select(userQuery.hasUndefinedLobPref),
  );
  contactCntr$ = this.store$.pipe(select(userQuery.getContactCntr));
  isSkipExceeded$ = this.store$.pipe(select(userQuery.isSkipExceeded));
  isEmailVerifiedSkipExceeded$ = this.store$.pipe(
    select(userQuery.isEmailVerifiedSkipExceeded),
  );
  emailVerifySkipCount$ = this.store$.pipe(
    select(userQuery.getEmailVerifySkipCount),
  );
  claimsPopupSupressed$ = this.store$.pipe(
    select(userQuery.getClaimsPopupSupressed),
  );
  wamOnboardingStatus$ = this.store$.pipe(
    select(userQuery.getWamOnboardingStatus),
  );
  showPasswordChangeWarning$ = this.store$.pipe(
    select(userQuery.shouldShowExpiredPasswordWarning),
  );
  lastPasswordChangeDate$ = this.store$.pipe(
    select(userQuery.getPasswordLastChangedDate),
  );
  tinStatus$ = this.store$.pipe(select(userQuery.getTinStatus));
  getDualTinStatus$ = this.store$.pipe(select(userQuery.getDualTinStatus));
  securityQuestions$ = this.store$.pipe(select(userQuery.getSecurityQuestions));

  getUpdatePrefLobSuccess$ = this.store$.pipe(
    select(userQuery.getUpdatePrefLobSuccess),
  );

  flaggedPatients$ = this.store$.pipe(select(userQuery.getFlaggedPatients));

  flaggedPatientKeys$ = this.dashboardPatientsGQL
    .watch({}, { fetchPolicy: 'cache-first' })
    .valueChanges.pipe(
      startWith({ data: { flaggedPatients: [] } }), // downstream consumers expecting empty array and not null for now
      map((result) =>
        result.data.flaggedPatients
          ? result.data.flaggedPatients.map(
              (patient) => patient?.coverage?.compositeKey,
            )
          : [],
      ),
    );

  recentPatients$ = this.store$.pipe(select(userQuery.getRecentPatients));
  patientsLoadingState$ = this.store$.pipe(
    select(userQuery.patientsLoadingState),
  );

  flaggedClaims$ = this.store$.pipe(select(userQuery.getFlaggedClaims));

  flaggedClaimsKeys$ = this.dashboardClaimsGQL
    .watch({}, { fetchPolicy: 'cache-first' })
    .valueChanges.pipe(
      startWith({ data: { flaggedClaims: [] } }), // downstream consumers expecting empty array and not null for now
      map((result) =>
        result.data.flaggedClaims
          ? result.data.flaggedClaims.map(
              (claim) => claim?.claimSummary?.claimReferenceNumber,
            )
          : [],
      ),
    );

  claimsLoadingState$ = this.store$.pipe(select(userQuery.claimsLoadingState));
  recentClaims$ = this.store$.pipe(select(userQuery.getRecentClaims));

  patientDashboardData$ = this.dashboardPatientsGQL
    .watch({}, { fetchPolicy: 'cache-and-network', errorPolicy: 'all' })
    .valueChanges.pipe(
      startWith({
        loading: true,
        data: {
          recentPatients: null,
          flaggedPatients: null,
        },
        errors: undefined,
      }),
      map((result) => ({
        loading: result.loading,
        data: {
          recentPatients: result.data?.recentPatients,
          flaggedPatients: result.data?.flaggedPatients,
          flaggedIds: result.data?.flaggedPatients
            ? result.data.flaggedPatients.map(
                (patient) => patient?.coverage?.compositeKey || '',
              )
            : [],
        },
        errors: result.errors,
      })),
    );

  claimDashboardData$ = this.dashboardClaimsGQL
    .watch({}, { fetchPolicy: 'cache-and-network', errorPolicy: 'all' })
    .valueChanges.pipe(
      startWith({
        loading: true,
        data: {
          recentClaims: null,
          flaggedClaims: null,
        },
        errors: undefined,
      }),
      map((result) => ({
        loading: result.loading,
        data: {
          recentClaims: result.data?.recentClaims,
          flaggedClaims: result.data?.flaggedClaims,
          flaggedIds: result.data?.flaggedClaims
            ? result.data.flaggedClaims.map(
                (claim) => claim?.claimSummary?.claimReferenceNumber || '',
              )
            : [],
        },
        errors: result.errors,
      })),
    );

  precertDashboardData$ = this.dashboardPrecertGQL
    .watch({}, { fetchPolicy: 'cache-and-network', errorPolicy: 'all' })
    .valueChanges.pipe(
      startWith({
        loading: true,
        data: {
          recentPrecertifications: null,
          flaggedPrecertifications: null,
        },
        errors: undefined,
      }),
      map((result) => ({
        loading: result.loading,
        data: {
          recentPrecerts: result.data?.recentPrecertifications,
          flaggedPrecerts: result.data?.flaggedPrecertifications,
          flaggedIds: result.data?.flaggedPrecertifications
            ? result.data.flaggedPrecertifications.map(
                (precert) => precert?.precertNumber || '',
              )
            : [],
        },
        errors: result.errors,
      })),
    );

  flaggedPrecertKeys$ = this.dashboardPrecertGQL
    .watch({}, { fetchPolicy: 'cache-first' })
    .valueChanges.pipe(
      startWith({ data: { flaggedPrecertifications: [] } }), // downstream consumers expecting empty array and not null for now
      map((result) =>
        result.data.flaggedPrecertifications
          ? result.data.flaggedPrecertifications.map(
              (precert) => precert?.precertNumber || '',
            )
          : [],
      ),
    );

  getLobs$ = this.store$.pipe(select(userQuery.getLobsFromTins));

  hideEmailPopup$ = this.store$.pipe(select(userQuery.getHideEmailPopup));

  unFilteredTins$ = this.store$.pipe(select(userQuery.getTinsWithDetails));

  tinFunctions$ = this.store$.pipe(select(userQuery.getTinFunctionsDetails));
  tinFunctionsStatus$ = this.store$.pipe(
    select(userQuery.getTinFunctionsStatus),
  );

  tins$ = (config?: {
    entitlements?: Entitlement[];
    onlyContracted?: boolean;
    lob?: LOB;
    context?: '' | AppContext;
  }) =>
    this.store$.pipe(
      select(
        userQuery.getTinsWithFilters({
          entitlements: config?.entitlements || [],
          onlyContracted: config?.onlyContracted || false,
          lob: config?.lob || '',
          context: config?.context || '',
        }),
      ),
    );

  tinLob$ = (tin: string) => this.store$.pipe(select(userQuery.getTinLob(tin)));

  isFlaggedPatient$ = (flagkey: string) =>
    this.flaggedPatientKeys$.pipe(map((keys) => keys.includes(flagkey)));

  isFlaggedClaim$ = (flagkey: string) =>
    this.flaggedClaimsKeys$.pipe(map((keys) => keys.includes(flagkey)));

  constructor(
    private store$: Store<UserState>,
    private featureToggleFacade: FeatureTogglesFacade,
    private dashboardPatientsGQL: DashboardPatientsGQLService,
    private dashboardClaimsGQL: DashboardClaimsGQLService,
    private dashboardPrecertGQL: DashboardPrecertificationsGQLService,
    private gqlMutationsService: GraphQLMutations,
  ) {}

  getProfile(): void {
    this.store$.dispatch(new GetUserProfile());
  }

  getLoginProfile(): void {
    this.store$.dispatch(new GetLoginUserProfile());
  }

  updateProfile(user: Partial<UserProfile>): void {
    this.store$.dispatch(new UpdateUserProfile(user));
  }

  updateProfileIndicator(indicator: UserProfileIndicatorName): void {
    this.store$.dispatch(new UpdateUserProfileIndicator(indicator));
  }

  updateProfileSuccess(user: UserProfile): void {
    this.store$.dispatch(new UpdateUserProfileSuccess(user));
  }

  loadTins(): void {
    this.store$.dispatch(new LoadTins());
  }

  loadTinFunctions(entitlements: string[], functions: string[]): void {
    this.store$.dispatch(new LoadTinFunctions({ entitlements, functions }));
  }

  getSecurityQuestions(): void {
    this.store$.dispatch(new GetSecurityQuestions());
  }

  getFlaggedPatients(): void {
    this.store$.dispatch(new GetUserFlaggedPatients());
  }

  getFlaggedClaims(): void {
    this.store$.dispatch(new GetUserFlaggedClaims());
  }

  updateFlaggedPatients(
    flagType: FlagUpdateType | FlagTypeGQL,
    patientData: FlaggedPatient[] | FlaggedPatientGQL[],
  ): void {
    this.gqlMutationsService.updateFlaggedPatients(
      flagType as FlagTypeGQL,
      patientData,
    );
  }

  updateFlaggedClaims(
    flagType: FlagUpdateType | FlagTypeGQL,
    claimData: FlaggedClaim[] | FlagClaimInputGQL[],
  ): void {
    this.dashboardClaimsGQL
      .fetch({}, { fetchPolicy: 'cache-first' })
      .subscribe((results) => {
        // for delete we need to get flag id from store to combine with update request
        const claimFromStore: FlaggedClaimGQL | undefined | null =
          results.data.flaggedClaims?.find(
            (flag) =>
              flag?.claimSummary?.claimReferenceNumber ===
              claimData[0].claimSummary.claimReferenceNumber,
          );
        this.gqlMutationsService.updateFlaggedClaims(
          flagType as FlagTypeGQL,
          (flagType === 'D'
            ? [{ ...claimData[0], id: claimFromStore?.id }]
            : claimData) as FlagClaimInputGQL[],
        );
      });
  }

  getRecentPatients(): void {
    this.store$.dispatch(new GetUserRecentPatients());
  }

  getRecentClaims(): void {
    this.store$.dispatch(new GetUserRecentClaims());
  }

  updateSkipCount(skipRequest: MfaSkipRequest): void {
    this.store$.dispatch(new UpdateSkipCount(skipRequest));
  }

  updateFlaggedPrecert(
    flagType: FlagUpdateType | FlagTypeGQL,
    precertData: FlaggedPrecert | PrecertResponseGQL,
  ): void {
    this.gqlMutationsService.updateFlaggedPrecert(
      flagType as FlagTypeGQL,
      precertData as PrecertResponseGQL,
    );
  }
}
