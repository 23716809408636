import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResponsiveDirective } from './responsive.directive';
import { ResponsiveConfig, RESPONSIVE_CONFIG } from './responsive.config';
import { ScrollableDirective } from './scrollable.directive';
import { ResponsiveTitleComponent } from './responsive-title/responsive-title.component';
import { UiUtilsModule } from '@cigna/shared/angular/utils-ui';

const DECLARATIONS = [
  ResponsiveDirective,
  ScrollableDirective,
  ResponsiveTitleComponent,
];

@NgModule({
  imports: [CommonModule, UiUtilsModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class UiResponsiveModule {
  static withConfig(
    config: ResponsiveConfig,
  ): ModuleWithProviders<UiResponsiveModule> {
    return {
      ngModule: UiResponsiveModule,
      providers: responsiveProviders(config),
    };
  }
}

export function responsiveProviders(config: ResponsiveConfig): Provider[] {
  return [
    {
      provide: RESPONSIVE_CONFIG,
      useValue: config,
    },
  ];
}
