import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Type,
} from '@angular/core';
import {
  IconDynamicChatBubbleComponent,
  IconDynamicColors,
  IconDynamicData,
} from '@cigna/omni/shared-util';
import { ScreenSize } from '@cigna/shared/angular/responsive-ui';

@Component({
  selector: 'cigna-omni-chat-bubble-container',
  templateUrl: './chat-bubble-container.component.html',
  styleUrls: ['./chat-bubble-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('chatBubbleFlyInOut', [
      transition(':enter', [
        animate(
          600,
          keyframes([
            style({ transform: 'translateY(100%)', offset: 0 }),
            style({ transform: 'translateY(-16px)', offset: 0.6 }),
            style({ transform: 'translateY(0)', offset: 1 }),
          ]),
        ),
      ]),
      transition(':leave', [
        animate(
          500,
          keyframes([
            style({ transform: 'translateY(0)', offset: 0 }),
            style({ transform: 'translateY(100%)', offset: 1 }),
          ]),
        ),
      ]),
    ]),
    trigger('flyInOut', [
      transition(':enter', [
        animate(
          1000,
          keyframes([
            style({ transform: 'translateX(100%)', offset: 0 }),
            style({ transform: 'translateX(-16px)', offset: 0.6 }),
            style({ transform: 'translateX(0)', offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class ChatBubbleContainerComponent implements OnChanges {
  @Input() public chatBubbleData: Record<
    ScreenSize.XSmall | ScreenSize.Medium,
    IconDynamicData
  >;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() public showButton: boolean;
  @Input() public icon: Type<IconDynamicChatBubbleComponent>;
  @Input() public iconColors: IconDynamicColors;
  @Output() public events: EventEmitter<any> = new EventEmitter<any>();
  public chatBubbleFullText = 'open message center';
  @Input() public shouldShowNewBubble: boolean;
  @Input() public isShellOpenOrMinimized: boolean;
  @Input() public isBubbleExpand: boolean;
  @Input() public isShellMinimized: boolean;
  @Input() public isLiveChatAvailable: boolean;
  @Input() public isTerminatedUser: boolean;
  @Input() public isDcmChat: boolean;
  @Input() public hasChatOutage: boolean;
  public buttonAriaLable = 'Chat with us';
  hasNewMessages: boolean;

  public onClick() {
    this.events.emit({ type: 'click', text: this.chatBubbleFullText });
  }

  public onOpenClose() {
    if (!this.isShellOpenOrMinimized) {
      this.events.emit({ type: 'click', text: this.chatBubbleFullText });
      this.events.emit({ type: 'hideOvalChatIcon' });
    } else {
      this.events.emit({ type: 'minimize' });
    }
  }

  public ngOnChanges() {
    if (this.chatBubbleData) {
      this.chatBubbleFullText = `${
        this.chatBubbleData[ScreenSize.Medium].lineOne
      } ${this.chatBubbleData[ScreenSize.Medium].lineTwo}`;
      this.hasNewMessages =
        this.chatBubbleData[ScreenSize.Medium].hasNewMessages || false;
    }
  }

  changeIconOnHover(changeIcon: boolean) {
    if (changeIcon && (!this.isShellOpenOrMinimized || this.isShellMinimized)) {
      this.events.emit({ type: 'showOvalChatIcon' });
    } else {
      this.events.emit({ type: 'hideOvalChatIcon' });
    }
  }

  constructor() {
    setTimeout(() => {
      if (this.showButton) {
        this.events.emit({ type: 'hideOvalChatIcon' });
      }
    }, 15000);
  }
}
