<div class="screen-container OneLinkNoTx">
  <button class="launch-link" type="button" (click)="launchLiveChat()">
    <div class="title">
      <cigna-icon type="live-chat-group" class="title-icon chat"></cigna-icon
      ><strong>Chat with a human</strong>
    </div>
    <div class="blue" *ngIf="(liveChatAvailable$ | async) === false">
      <span>Live chat is closed.</span>
    </div>
    <div class="description-box">
      <div class="desc-text">
        <ul>
          <li>Chat live or go to your chat history.</li>
          <ng-container *ngIf="chatCloseHrsMsg$ | async as chatCloseHrsMsg">
            <ng-container *ngFor="let msg of chatCloseHrsMsg">
              <li [innerHTML]="msg"></li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
      <cigna-icon-dynamic
        [colors]="iconColors"
        [icon]="Icon"
        [data]="IconData"
        class="right-arrow"
      ></cigna-icon-dynamic>
    </div>
  </button>
  <button class="launch-link" type="button" (click)="openActiveDMs()">
    <div class="description-box">
      <div class="desc-text">
        <div class="title">
          <cigna-icon type="direct-message" class="title-icon dm"></cigna-icon
          ><strong data-test-id="label-direct-message">Direct Messages</strong
          ><span class="dm-count" *ngIf="unreadDMConversationCount$ | async"
            ><span>{{ unreadDMConversationCount$ | async }}</span></span
          >
        </div>
        <div class="">Go to your direct messages.</div>
      </div>
      <cigna-icon-dynamic
        [colors]="iconColors"
        [icon]="Icon"
        [data]="IconData"
        class="right-arrow"
      ></cigna-icon-dynamic>
    </div>
  </button>
</div>
