import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniGateway } from '@cigna/omni/shared-util';
import { Observable } from 'rxjs';
import { ChatPreferences } from '../models/chat-preferences';

@Injectable({
  providedIn: 'root',
})
export class ChatPreferencesDataAccessService {
  constructor(private http: HttpClient, private gateway: OmniGateway) {}

  public getChatPreferences(): Observable<ChatPreferences> {
    const url = this.gateway.chatPreferencesUrl();
    return this.http.get<ChatPreferences>(url);
  }
}
