<ng-container *ngIf="coversationError$ | async as error">
  <div
    class="message-error-wrapper"
    *ngIf="error && error.status && isDcmChat$ | async"
  >
    <div class="message-error">
      <h4>Chat feature is not available.</h4>
      <p>
        Chat services are not available right now. Please close the chat and try
        again in a few minutes.
      </p>
    </div>
  </div>
</ng-container>
<ng-container *ngrxLet="conversation$ as conversation">
  <div *ngIf="(minimized$ | async) === false" class="conv-dialog-wrapper">
    <div
      [ngClass]="{
        'conv-dialog-container': true,
        'pad-bottom-10': (isDcmChat$ | async)
      }"
      data-omni-qa="conv-dialog"
    >
      <ng-container *ngrxLet="outroObj$ as outroObj">
        <cigna-dcm-profile-header
          [name]="agentName$ | async"
          [disclaimer]="disclaimer$ | async"
          [isChatInactive]="
            outroObj?.show &&
            (shouldEnablePreChatWelcome$ | async) &&
            (isDcmChat$ | async)
          "
          *ngIf="isDcmChat$ | async"
        ></cigna-dcm-profile-header>
      </ng-container>
      <cigna-omni-messaging-list
        id="printSection"
        (messageViewed)="setMessageViewed($event)"
        (sendCta)="postCta($event)"
        (navigateBack)="navigateBack()"
        [conversationType]="conversationType$ | async"
        [disclaimer]="disclaimer$ | async"
        [messages]="events$ | async"
        [outroObj]="outroObj$ | async"
        [conversation]="conversation"
        [isLiveChatAvailable]="liveChatAvailable$ | async"
        [chatCloseHrsMsg]="chatCloseHrsMsg$ | async"
        [isUnmuted]="isUnmuted$ | async"
        [chatTitle]="title$ | async"
        [isHistoryView]="isHistoryView$ | async"
        [isAiBot]="isAiBot$ | async"
        [shouldEnablePreChatWelcome]="shouldEnablePreChatWelcome$ | async"
        [isUseRightNowChat]="useRightNowChat$ | async"
        [shouldEnabledBackToPrevious]="shouldEnabledBackToPrevious$ | async"
        [canReplaceBackToPreviousWithEndChat]="
          replaceBackToPreviousWithEndChat$ | async
        "
        [isUpdatingConversation]="isUpdatingConversation$ | async"
        [isTerminatedUser]="isTerminatedUser$ | async"
        [terminatedUserMessage]="terminatedUserMessage$ | async"
        [isDcmChat]="isDcmChat$ | async"
        [canEnableNewDMUI]="'enableNewDMUI' | cignaFeatureEnabled"
        [soundFileLink]="soundFileLink"
        [hasChatOutage]="hasChatOutage$ | async"
        [chatOutageMsg]="chatOutageMsg$ | async"
        [hasActiveLiveChat]="hasActiveLiveChat$ | async"
      ></cigna-omni-messaging-list>

      <cigna-omni-messaging-input
        [isDcmChat]="isDcmChat$ | async"
        *ngIf="(inputEnabled$ | async) && (isTerminatedUser$ | async) !== true"
        [isAiBot]="isAiBot$ | async"
        [shouldEnablePreChatWelcome]="shouldEnablePreChatWelcome$ | async"
        [hasCharLimit]="hasCharLimit$ | async"
        [conversation]="conversation"
        [isUpdatingConversation]="isUpdatingConversation$ | async"
        (sendMessage)="postMessage($event, conversation)"
        (sendCta)="postCta($event)"
        (keyUp)="keyHandler()"
      ></cigna-omni-messaging-input>
    </div>
  </div>
  <cigna-omni-splash-close-screen
    [screenText]="screenText"
    [conversationId]="conversation?._id"
    *ngIf="closeSplashScreen$ | async"
  ></cigna-omni-splash-close-screen>
</ng-container>
