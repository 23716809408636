import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';

export type WaitTimeTypes =
  | 'live'
  | 'asyncSupport'
  | 'asyncChe'
  | 'hil'
  | 'noop';

@Component({
  selector: 'cigna-omni-messaging-wait-time',
  templateUrl: './wait-time.component.html',
  styleUrls: ['./wait-time.component.scss'],
})
export class OmniMessagingWaitTimeComponent implements OnInit, OnDestroy {
  waitMessageTimeout: ReturnType<typeof setTimeout>;
  shouldDisplayWaitMessage = false;

  @ViewChild('waitSection') public waitSection: ElementRef;

  @Input()
  public datetime: string;
  @Input()
  public footer: string;
  @Input()
  public lineOne: string;
  @Input()
  public lineTwo: string;
  @Input()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public personalGuideEligible: boolean;
  @Input()
  public type: WaitTimeTypes;
  @Input() public waitTimeinSeconds: number;
  @Input() public hasEnablePreChatWelcome: boolean;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.waitMessageTimeout = setTimeout(() => {
      this.shouldDisplayWaitMessage = true;
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
      this.waitSection.nativeElement.focus();
    }, this.waitTimeinSeconds * 1000);
  }

  ngOnDestroy() {
    if (this.waitMessageTimeout) {
      clearTimeout(this.waitMessageTimeout);
    }
  }
}
