import { props, createAction } from '@ngrx/store';
import { Locale, Manifest } from './content-bundle.config';

export const loadContentBundle = createAction(
  '[Content Bundle] Load Content Bundle',
  props<{ name: string; filename: string; lang: Locale }>(),
);

export const resetContentBundle = createAction(
  '[Content Bundle] Reset Content Bundle',
);

export const contentBundleLoaded = createAction(
  '[Content Bundle] Content Bundle Loaded',
  props<{
    name: string;
    lang: Locale;
    filename?: string;
    translations?: {};
  }>(),
);

export const contentBundleLoadError = createAction(
  '[Content Bundle] Content Bundle Load Error',
  props<{ name: string; filename: string; lang: Locale; error: string }>(),
);

export const changeLanguage = createAction(
  '[Content Bundle] Change Language',
  props<{ lang: Locale }>(),
);

export const languageChanged = createAction(
  '[Content Bundle] Language Changed',
  props<{ lang: Locale }>(),
);

export const loadManifest = createAction('[Content Bundle] Load Manifest');

export const manifestLoaded = createAction(
  '[Content Bundle] Manifest Loaded',
  props<{ manifest: Manifest }>(),
);

export const manifestLoadError = createAction(
  '[Content Bundle] Manifest Load Error',
  props<{ error: string }>(),
);

export const requestBundles = createAction(
  '[Content Bundle] Request Bundles',
  props<{ bundles: string[]; lang?: Locale }>(),
);
