import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniGateway } from '@cigna/omni/shared-util';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatTooltipService {
  private tooltipState = new BehaviorSubject<boolean>(true);
  public tooltipState$ = this.tooltipState.asObservable();

  constructor(private http: HttpClient, private gateway: OmniGateway) {}

  updateTooltipView(): Observable<void> {
    this.tooltipState.next(false);
    const url = this.gateway.tooltipViewUrl();
    return this.http.post<void>(url, {});
  }
}
