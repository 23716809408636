import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import {
  initialState,
  userOptionsReducer,
  USER_OPTIONS_FEATURE_KEY,
} from './+state/user-options.reducer';
import { UserOptionsEffects } from './+state/user-options.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_OPTIONS_FEATURE_KEY, userOptionsReducer, {
      initialState,
    }),
    EffectsModule.forFeature([UserOptionsEffects]),
  ],
})
export class ChcpSharedUserOptionsDataAccessModule {}
