import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniAnalyticsService } from '@cigna/omni/shared-util';
import { ConversationSummaryDTO } from '@cigna/vampire-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
  AutoMaximize,
  ChatActionTypes,
  DialogView,
  ActiveDMsView,
  HeaderClose,
  HeaderMaximize,
  HeaderMinimize,
  HomeView,
  BeforeBack,
} from './chat.actions';
import { OmniChatFacade } from './chat.facade';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

@Injectable()
export class ChatAnalyticsEffects {
  chatStateChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AutoMaximize | HeaderClose | HeaderMaximize | HeaderMinimize>(
          ChatActionTypes.AutoMaximize,
          ChatActionTypes.HeaderClose,
          ChatActionTypes.HeaderMaximize,
          ChatActionTypes.HeaderMinimize,
        ),
        withLatestFrom(
          this.chatFacade.currentComponent$,
          this.featureToggleFacade.featuresEnabled(['isEvernorthEnabled']),
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, , , canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(([action, currentComponent, isEvernorthEnabled]) => {
          switch (action.type) {
            case ChatActionTypes.AutoMaximize: {
              switch (action.eventType) {
                case 'message':
                  this.analytics.track('chatAgentMessageMaximize', {});
                  break;
                case 'participant':
                  this.analytics.track('chatAgentJoinMaximize', {});
                  break;
                case 'state':
                  this.analytics.track('chatStateChangeMaximize', {});
                  break;
              }
              break;
            }
            case ChatActionTypes.HeaderClose:
              if (isEvernorthEnabled) {
                this.analytics.trackEvent('chatCloseClick', {});
              } else {
                this.analytics.track('chatClose', {});
              }
              break;
            case ChatActionTypes.HeaderMaximize:
              if (isEvernorthEnabled) {
                this.analytics.trackEvent('chatShellOpenAfterMinimize', {
                  currentComponent:
                    this.titleCasePipe.transform(currentComponent),
                });
              } else {
                this.analytics.trackEvent('chatMaximize', {
                  currentComponent:
                    this.titleCasePipe.transform(currentComponent),
                });
              }
              break;
            case ChatActionTypes.HeaderMinimize:
              if (isEvernorthEnabled) {
                this.analytics.trackEvent('chatShellMinimize', {
                  currentComponent:
                    this.titleCasePipe.transform(currentComponent),
                });
              } else {
                this.analytics.trackEvent('chatMinimize', {
                  currentComponent:
                    this.titleCasePipe.transform(currentComponent),
                });
              }
              break;
          }
        }),
      ),
    { dispatch: false },
  );

  chatBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BeforeBack>(ChatActionTypes.BeforeBack),
        withLatestFrom(
          this.conversationsFacade.conversation$,
          this.chatFacade.currentComponent$,
          this.featureToggleFacade.featuresEnabled(['isEvernorthEnabled']),
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        tap(
          ([
            ,
            conv,
            currentComponent,
            isEvernorthEnabled,
            canUpdateAnalyticsData,
          ]) => {
            if (
              !!conv &&
              conv.type !== 'async' &&
              !['closed', 'transfer'].includes(conv.state)
            ) {
              if (!canUpdateAnalyticsData) {
                const eventType = isEvernorthEnabled
                  ? 'endChatWithinConv'
                  : 'chatEndClick';
                this.analytics.trackEvent(eventType, {});
              }
              this.conversationsFacade.setCloseOrExitFlag(false);
              this.conversationsFacade.setCloseSplashScreen(false);
            } else if (!canUpdateAnalyticsData) {
              const eventType = isEvernorthEnabled
                ? 'chatBackToConversation'
                : 'chatBack';
              this.analytics.trackEvent(eventType, {
                currentComponent:
                  this.titleCasePipe.transform(currentComponent),
              });
            }
          },
        ),
      ),
    { dispatch: false },
  );

  chatHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<HomeView>(ChatActionTypes.HomeView),
        withLatestFrom(
          this.chatFacade.commChannelContent$,
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, , canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(([_, contentObject]) => {
          const homeButtonTexts = Object.values(contentObject)
            .map((content) => content?.buttonText ?? '')
            .filter((content) => !!content);
          const homeButtonIds = Object.values(contentObject)
            .map((content) => content?.buttonId ?? '')
            .filter((content) => !!content);
          this.analytics.track('chatHomeView', {
            homeButtonTexts,
            homeButtonIds,
          });
        }),
      ),
    { dispatch: false },
  );

  dialogView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DialogView>(ChatActionTypes.DialogView),
        switchMap(() =>
          this.conversationsFacade.conversation$.pipe(
            filter((c): c is ConversationSummaryDTO => !!c),
            take(1),
          ),
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(([{ _id, state, type, context }]) => {
          this.analytics.track('chatDialogView', {
            chatId: _id,
            chatState: state,
            chatType: type,
            chatContext: context.tags,
          });
        }),
      ),
    { dispatch: false },
  );

  activeDMsView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ActiveDMsView>(ChatActionTypes.ActiveDMsView),
        tap(() => {
          this.analytics.dataLayer.updateAdobeDataLayer({
            event: 'pageAction',
            actionData: {
              actionName: 'Direct Messages',
              hitType: 'action',
              widgetName: 'chat-type-selection',
              customLinkText: 'Direct Messages',
              requirementId: 'acCHT008',
              userStory: 'ODCTC-2488',
            },
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private analytics: OmniAnalyticsService,
    private actions$: Actions,
    private conversationsFacade: OmniConversationsFacade,
    private chatFacade: OmniChatFacade,
    private titleCasePipe: TitleCasePipe,
    private featureToggleFacade: FeatureTogglesFacade,
  ) {}
}
