<ng-container *ngFor="let messageObj of messageObjList">
  <span
    *ngIf="messageObj.type === 'text'"
    [innerHTML]="messageObj.text | htmlDecode"
  ></span>
  <ng-container *ngIf="messageObj.type === 'link'">
    <a
      *ngIf="messageObj.linkInfo.url.indexOf('http') === 0"
      [href]="messageObj.linkInfo.url | htmlDecode"
      target="{{ messageObj.linkInfo.target }}"
    >
      <cigna-icon
        class="cg-margin-right"
        [ngClass]="{
          'cg-mat-icon-video': messageObj.linkInfo.type === 'video',
          'cg-mat-icon-url':
            messageObj.linkInfo.type === 'url' ||
            messageObj.linkInfo.type === 'web_url'
        }"
        [type]="iconMapper[messageObj.linkInfo.type]"
      ></cigna-icon
      >{{ messageObj.linkInfo.label }}</a
    >
    <button
      class="link"
      *ngIf="messageObj.linkInfo.url.indexOf('http') !== 0"
      cignaButton="link"
      (click)="openLink(messageObj.linkInfo.url)"
    >
      {{ messageObj.linkInfo.label }}
    </button>
  </ng-container>
</ng-container>
