import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';

import { UiButtonModule } from '@cigna/shared/angular/button-ui';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { UiResponsiveModule } from '@cigna/shared/angular/responsive-ui';
import {
  UiPortalsModule,
  PortalExitComponent,
} from '@cigna/shared/angular/portals-ui';
import { ModalContentDirective } from './modal/modal-content.directive';
import { ModalComponent } from './modal/modal.component';
import { PreventNavigationDirective } from './prevent-navigation/prevent-navigation.directive';
import {
  ModalLayoutTitleDirective,
  ModalLayoutSubTitleDirective,
  ModalLayoutButtonsDirective,
} from './modal-layout/modal-layout-extensions';
import { ModalLayoutControlComponent } from './modal-layout-control/modal-layout-control.component';
import { ModalLayoutComponent } from './modal-layout/modal-layout.component';

const DECLARATIONS = [
  PreventNavigationDirective,
  ModalContentDirective,
  ModalComponent,
  ModalLayoutTitleDirective,
  ModalLayoutSubTitleDirective,
  ModalLayoutButtonsDirective,
  ModalLayoutControlComponent,
  ModalLayoutComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UiButtonModule,
    UiIconModule,
    UiResponsiveModule,
    UiPortalsModule,
    A11yModule,
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class UiModalModule {}
