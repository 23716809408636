import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeaturesModule } from '@cigna/shared/angular/features-feature';
import { OmniChatStateModule } from '@cigna/omni/chat-state-data-access';
import { OmniCommChannelFeatureModule } from '@cigna/omni/comm-channel-feature';
import { OmniConversationSummaryListModule } from '@cigna/omni/conversation-summary-list-feature';
import { OmniDcmModule } from '@cigna/omni/dcm-feature';
import { OmniMessagingModule } from '@cigna/omni/messaging-feature';
import { OmniSharedUiModule } from '@cigna/omni/shared-util';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { UiProgressModule } from '@cigna/shared/angular/progress-ui';
import { LetDirective } from '@ngrx/component';
import { OmniShellChatHeaderComponent } from './chat-header/chat-header.component';
import { DialogComponent } from './dialog/dialog.component';
import { DcmDialogComponent } from './dcm-dialog/dcm-dialog.component';
import { OmniShellHeaderComponent } from './header/header.component';
import { HistoryComponent } from './history/history.component';
import { HomeComponent } from './home/home.component';
import { ShellComponent } from './shell/shell.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { ActiveDMsComponent } from './active-dms/active-dms.component';
import { OmniConversationSummaryCardModule } from '@cigna/omni/conversation-summary-card-feature';

@NgModule({
  imports: [
    CommonModule,
    FeaturesModule,
    OmniChatStateModule,
    OmniCommChannelFeatureModule,
    OmniMessagingModule,
    OmniConversationSummaryListModule,
    OmniSharedUiModule,
    OmniDcmModule,
    UiIconModule,
    UiProgressModule,
    LetDirective,
    OmniConversationSummaryCardModule,
  ],
  declarations: [
    ShellComponent,
    DialogComponent,
    DcmDialogComponent,
    HomeComponent,
    HistoryComponent,
    OmniShellHeaderComponent,
    OmniShellChatHeaderComponent,
    SplashScreenComponent,
    ActiveDMsComponent,
  ],
  exports: [ShellComponent],
})
export class OmniChatModule {}
