import { createAction, props } from '@ngrx/store';
import { ToggleValue } from './interfaces';

export enum FeatureToggleType {
  OPS = 'ops',
  PERMISSION = 'permission',
  RELEASE = 'release',
}

export const updateFeatureToggles = createAction(
  '[Feature Toggles] Update feature toggles',
  props<{
    toggles: Record<string, ToggleValue>;
    toggleType: FeatureToggleType;
    namespace?: string;
  }>(),
);

export const sendLdAnalyticEvent = createAction(
  '[Feature Toggles] Send analytic event for Launch Darkly feature flag evaluation',
  props<{
    toggles: string[];
  }>(),
);

export const trackMetric = createAction(
  '[Feature Toggles] Track custom Launch Darkly metric',
  props<{
    key: string;
  }>(),
);
