import { Component, Input } from '@angular/core';
import { ClaimStatus } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-claim-status',
  templateUrl: './claim-status.component.html',
  styleUrls: ['./claim-status.component.scss'],
})
export class ClaimStatusComponent {
  @Input() status: keyof typeof ClaimStatus;
  @Input() size: 'small' | 'medium' | 'large' = 'small';

  ClaimStatus = ClaimStatus;

  get statusType(): 'success' | 'warning' | 'error' | 'info' {
    if (
      ['PTRESTIMATE', 'PAID', 'PROCESSED', 'APPROVED'].includes(this.status)
    ) {
      return 'success';
    }

    if (['DENIED', 'REJECTED', 'PTRESTIMATEDENIED'].includes(this.status)) {
      return 'error';
    }

    if (
      [
        'IN_PROCESS',
        'PENDED',
        'DUPLICATE',
        'ADJUSTED',
        'PTRESTIMATEPENDING',
      ].includes(this.status)
    ) {
      return 'warning';
    }

    return 'info';
  }
}
