import { InjectionToken } from '@angular/core';

export const RESPONSIVE_CONFIG = new InjectionToken<ResponsiveConfig>(
  'RESPONSIVE_CONFIG',
  {
    providedIn: 'root',
    factory: () => ({
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    }),
  },
);

export interface ResponsiveConfig {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}
