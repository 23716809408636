import { Attachment } from '@cigna/chcp/shared/util';

export function fileCountByExtension(
  files: Attachment[],
  extension: string,
): string {
  const count = files.filter((f) =>
    f.name.toLowerCase().includes(extension),
  ).length;
  if (count > 0) {
    return `^${extension}:${count}`;
  }
  return `^`;
}

export function prepareSelections(selections: string[]): string {
  return selections.map((s) => s.toLowerCase()).join('|');
}
