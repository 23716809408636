import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnChanges,
  Output,
  ViewChild,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { WindowService } from '@cigna/shared/angular/core/window-util';
import { ConversationSummaryDTO } from '@cigna/vampire-dto';
import { CtaListItemData } from '../interfaces';
import { customValidator, trimmedValidator } from './util';

@Component({
  selector: 'cigna-omni-messaging-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniMessagingInputComponent
  implements AfterViewInit, OnChanges, OnInit
{
  @Input() isAiBot = false;
  @Input() conversation: ConversationSummaryDTO;
  @Input() hasCharLimit: boolean;
  @Input() isDcmChat = false;

  @Output() public sendMessage = new EventEmitter<string>();
  @Output() public sendCta = new EventEmitter<CtaListItemData>();
  @Output() public keyUp: EventEmitter<void> = new EventEmitter();

  @ViewChild('submit', { static: true }) public submit: ElementRef;
  @ViewChild('textarea', { static: true }) public textarea: ElementRef;

  @Input() public shouldEnablePreChatWelcome: boolean;
  @Input() public isUpdatingConversation = true;

  public placeholder = 'Enter Text...';
  public submitButtonCopy = 'send';
  public verticalPadding: number;
  public lineHeight: number;
  public maxTextLimit = 255;
  public formControl = new FormControl(
    { value: '', disabled: this.isUpdatingConversation },
    [customValidator(trimmedValidator, 'notTrimmed')],
  );

  constructor(private _window: WindowService) {}
  ngOnInit() {
    if (this.isDcmChat) {
      this.placeholder = 'Type message...';
    }
  }
  public ngAfterViewInit(): void {
    if (this.hasCharLimit) {
      this.formControl = new FormControl(
        { value: '', disabled: this.isUpdatingConversation },
        [
          customValidator(trimmedValidator, 'notTrimmed'),
          Validators.maxLength(this.maxTextLimit),
        ],
      );
    }
    const textarea = this.textarea.nativeElement as HTMLTextAreaElement;
    textarea.focus();

    this.verticalPadding =
      parseFloat(
        this._window
          .getComputedStyle(textarea, null)
          .getPropertyValue('padding-top'),
      ) +
      parseFloat(
        this._window
          .getComputedStyle(textarea, null)
          .getPropertyValue('padding-bottom'),
      );

    this.lineHeight = parseFloat(
      this._window
        .getComputedStyle(textarea, null)
        .getPropertyValue('line-height'),
    );
  }

  public ngOnChanges(changes: ComponentChanges<this>): void {
    if (changes.isUpdatingConversation?.currentValue !== undefined) {
      if (this.isUpdatingConversation) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }

  public textareaFocus() {
    this.textarea.nativeElement.focus();
  }

  public handleSubmit() {
    if (this.formControl.valid) {
      if (!this.isAiBot || this.conversation?.type !== 'bot') {
        this.sendMessage.emit(this.formControl.value?.trim());
      } else {
        this.sendCta.emit({ value: (this.formControl.value || '').trim() });
      }
    }
    this.formControl.reset('');
    this.resizeTextArea();
  }

  public onChange(enterPressed = false) {
    this.resizeTextArea();
    if (!enterPressed) {
      this.keyUp.emit();
    }
  }

  public resizeTextArea() {
    const textarea = this.textarea.nativeElement as HTMLTextAreaElement;
    textarea.rows = 1;
    // subtract top and bottom padding and divide by line height to get rows
    const rows =
      (textarea.scrollHeight - this.verticalPadding) / this.lineHeight;
    textarea.rows = rows > 4 ? 4 : rows;
  }

  public changeAfterDomUpdate() {
    setTimeout(() => this.onChange(), 0);
  }

  @HostListener('keydown.enter', ['$event'])
  public handleEnter(e: any) {
    e.preventDefault();
    this.submit.nativeElement.click();
  }
}
