import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DeactivateNavigationGuard {
  private preventNavList = new Set<CanDeactivateRoute>();

  register(activeRoute: CanDeactivateRoute) {
    this.preventNavList.add(activeRoute);
  }

  unregister(activeRoute: CanDeactivateRoute) {
    this.preventNavList.delete(activeRoute);
  }

  canDeactivate(
    _: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
  ) {
    if (this.preventNavList.size === 0) {
      return true;
    }
    return combineLatest(
      Array.from(this.preventNavList)
        .map((a) => a.canDeactivate(currentRoute, currentState))
        .map((a$) => (typeof a$ === 'boolean' ? of(a$) : a$)),
    ).pipe(map((res) => res.reduce((a, b) => a && b)));
  }
}

interface CanDeactivateRoute {
  canDeactivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
  ): Observable<boolean> | boolean;
}
