<div class="cg-dialog">
  <h3 class="cg-dialog__header">
    {{ 'shared.attachHelpDialog.header' | translate }}
    <button
      cignaButton="link inline no-underline"
      class="cg-dialog__close_new"
      data-test="modal-close-btn"
      (click)="dialogRef.close()"
    >
      {{ 'shared.common.closeBtn' | translate }}
      <cigna-icon class="cml-close-icon" type="close-thin"></cigna-icon>
    </button>
  </h3>

  <div class="cg-margin-bottom-md">
    <div>
      {{ 'shared.attachHelpDialog.subHeader' | translate }}
    </div>
  </div>

  <mat-dialog-content>
    <cigna-collapsible-item
      *ngFor="let help of helpItems"
      [headerText]="help.header | translate"
      (opened)="trackOpen($event)"
    >
      {{ help.body | translate }}
    </cigna-collapsible-item>
  </mat-dialog-content>
</div>
