<div [@flyInOut] class="fancy-invite-container" aria-live="polite">
  <button
    class="fancy-invite-close"
    (click)="dismissHandler()"
    aria-label="close case manager message notification"
    data-qa-omni="fancyInviteClose"
  >
    <span>Close</span>
    <cigna-icon-dynamic
      [icon]="closeIcon"
      [colors]="iconColors"
    ></cigna-icon-dynamic>
  </button>
  <div class="fancy-invite-agent-profile">
    <cigna-icon-dynamic
      [icon]="avatarIcon"
      [colors]="iconColors"
      [data]="iconData"
    ></cigna-icon-dynamic>
    <p class="fancy-invite-agent-profile-name">{{ agentFirstName }}</p>
    <p class="fancy-invite-agent-profile-role" *ngIf="!canEnableNewDMUI">
      Cigna Care Advocate
    </p>
    <p class="fancy-invite-agent-profile-role" *ngIf="canEnableNewDMUI">
      Cigna Healthcare<sup>SM</sup>
    </p>
  </div>

  <h1
    class="fancy-invite-title"
    [ngClass]="{
      'title-margin-reset': canEnableNewDMUI
    }"
  >
    New Message
    <span *ngIf="!canEnableNewDMUI">from your Cigna Care Advocate</span>
  </h1>
  <p class="fancy-invite-message">{{ message }}</p>
  <hr />
  <button
    class="fancy-invite-reply"
    data-qa-omni="fancyInviteReply"
    (click)="openConvHandler()"
  >
    {{ 'Reply to message' }}
  </button>
  <div class="arrow-container">
    <div class="arrow-down">▼</div>
  </div>
</div>
