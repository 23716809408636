import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FileWithUrl } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  // TODO: resolve CHCP disables by bot
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('files')
  set _files(_files: FileWithUrl[]) {
    this.files = this.validateFiles(_files || []);
    this.totalFilesSize = this.files.reduce(
      (total: number, v: FileWithUrl) => total + (v.file.size || 0),
      0,
    );
    this.totalPages = this.files.reduce(
      (total: number, v: FileWithUrl) => total + (v.pages || 0),
      0,
    );
    // need to wait till the change cycle finishes to emit a new change;
    setTimeout(() => {
      this.isValidUploadCheck(this.files);
    }, 0);
  }
  @Input() totalFilesCountLimit: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() attachmentsRequired = false;
  @Input() totalSizeLimit = 0;
  @Input() oneFileSizeLimit = 0;
  @Input() totalPageCountLimit = 0;
  @Input() allowedExtensions: string[] = [];
  @Input() customConfirmationText: string;
  @Input() isUploadDisabled = false;
  @Input() isRemoveDisabled = true;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() showDisclaimer = false;
  @Input() disclaimerHeader = 'Attachment agreement';
  @Input() disclaimerBody =
    'Only submit attachments that contain information regarding your request. Click "Accept" below to continue with attachments.';
  @Input() disclaimerAccept = 'Accept';

  @Output() uploadFiles = new EventEmitter<FileWithUrl[]>();
  @Output() removeFile = new EventEmitter<string>();
  @Output() fileNameClick = new EventEmitter<FileWithUrl>();
  @Output() isValid = new EventEmitter<boolean>();

  totalFilesSize: number;
  totalPages: number;

  files: FileWithUrl[];

  validateFile = (_fileWithUrl: FileWithUrl): FileWithUrl => {
    const fileWithUrl = { ..._fileWithUrl };
    const fileExt = (_fileWithUrl.file.name || '')
      .split('.')
      .pop()
      ?.toLowerCase();

    if (
      this.oneFileSizeLimit &&
      fileWithUrl.file.size > this.oneFileSizeLimit
    ) {
      fileWithUrl.error = 'MAX_SIZE';
      // Handles a rare scenario where a file is 0 kb or 0 bytes, if that's even a thing
    } else if (fileWithUrl.file.size === 0) {
      fileWithUrl.error = 'UNDER_SIZED';
    } else if (
      this.allowedExtensions.length &&
      !(fileExt && this.allowedExtensions.includes(`.${fileExt}`))
    ) {
      fileWithUrl.error = 'BAD_EXTENSION';
    } else if (
      !(
        fileWithUrl.file.name &&
        // TODO: resolve CHCP disables by bot
        // eslint-disable-next-line no-useless-escape
        /^([a-zA-Z0-9_\-\.\s@!&]{5,128})\.[A-Za-z]{2,5}$/.test(
          fileWithUrl.file.name.trim(),
        )
      )
    ) {
      fileWithUrl.error = 'BAD_NAME';
    } else if (fileWithUrl.pages === 0) {
      fileWithUrl.error = 'INVALID_FILE';
    }

    return fileWithUrl;
  };

  validateFiles(filesWithUrl: FileWithUrl[]): FileWithUrl[] {
    return filesWithUrl.map((fileWithUrl) => this.validateFile(fileWithUrl));
  }

  isValidUploadCheck(filesWithUrl: FileWithUrl[]) {
    const isValid =
      filesWithUrl.every((v) => !v.error && !v.isLoading) &&
      !(
        this.totalFilesCountLimit &&
        filesWithUrl.length > this.totalFilesCountLimit
      ) &&
      !(this.totalSizeLimit && this.totalFilesSize > this.totalSizeLimit) &&
      !(this.totalPageCountLimit && this.totalPages > this.totalPageCountLimit);
    this.isValid.emit(isValid);
  }

  onUploadFiles(files: FileWithUrl[]): void {
    const validatedFiles = this.validateFiles(files);
    this.uploadFiles.emit(validatedFiles);
  }

  onRemoveFile(fileName: string): void {
    this.removeFile.emit(fileName);
  }

  onFileNameClick(file: FileWithUrl): void {
    this.fileNameClick.emit(file);
  }

  hideDisclaimer(): void {
    this.showDisclaimer = false;
  }
}
