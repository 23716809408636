// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';
import { LiveAvailability } from '@cigna/omni/data-access';

export enum LiveAvailabilityActionTypes {
  GetLiveAvailability = '[Omni - LiveAvailability] GetLiveAvailability',
  GetLiveAvailabilitySuccess = '[Omni - LiveAvailability] LiveAvailabilitySuccess',
  PauseLiveAvailabilityCheck = '[Omni - LiveAvailability] Pause Live Availability Check',
  ResumeLiveAvailabilityCheck = '[Omni - LiveAvailability] Resume Live Availability Check',
  LiveAvailabilityLoaded = '[Omni - LiveAvailability] LiveAvailability Loaded',
  SetChatOutage = '[Omni - Conversations] SetChatOutage',
  SetChatOutageMsg = '[Omni - Conversations] SetChatOutageMsg',
}

export class GetLiveAvailability implements Action {
  public readonly type = LiveAvailabilityActionTypes.GetLiveAvailability;
}

export class GetLiveAvailabilitySuccess implements Action {
  public readonly type = LiveAvailabilityActionTypes.GetLiveAvailabilitySuccess;
  constructor(public liveAvailability: LiveAvailability) {}
}

export class PauseLiveAvailabilityCheck implements Action {
  public readonly type = LiveAvailabilityActionTypes.PauseLiveAvailabilityCheck;
}
export class ResumeLiveAvailabilityCheck implements Action {
  public readonly type =
    LiveAvailabilityActionTypes.ResumeLiveAvailabilityCheck;
}

export class LiveAvailabilityLoaded implements Action {
  public readonly type = LiveAvailabilityActionTypes.LiveAvailabilityLoaded;
  constructor(public isLoaded: boolean) {}
}

export class SetChatOutage implements Action {
  public readonly type = LiveAvailabilityActionTypes.SetChatOutage;
  constructor(public hasChatOutage: boolean) {}
}

export class SetChatOutageMsg implements Action {
  public readonly type = LiveAvailabilityActionTypes.SetChatOutageMsg;
  constructor(public chatOutageMsg: string) {}
}

export type LiveAvailabilityAction =
  | GetLiveAvailability
  | PauseLiveAvailabilityCheck
  | ResumeLiveAvailabilityCheck
  | GetLiveAvailabilitySuccess
  | LiveAvailabilityLoaded
  | SetChatOutage
  | SetChatOutageMsg;

export const fromLiveAvailabilityActions = {
  GetLiveAvailability,
  GetLiveAvailabilitySuccess,
  PauseLiveAvailabilityCheck,
  ResumeLiveAvailabilityCheck,
  LiveAvailabilityLoaded,
  SetChatOutage,
  SetChatOutageMsg,
};
