// TODO: resolve CHCP disables by bot
// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

import {
  UserProfile,
  MfaSkipRequest,
  UserProfileIndicatorName,
  LoginProfileResponse,
} from '../user-profile/user-profile.model';
import { ProviderTin, TinFunctions } from '../user-tins/tins.model';
import { FlaggedPatient, FlaggedClaim } from '../user-flags/flags.model';
import { FlagUpdateType } from '../user-flags/flags.constant';

import { ServiceError } from '@cigna/chcp/shared/util';

export enum UserActionTypes {
  GetUserProfile = '[User] Get User Profile',
  GetUserProfileSuccess = '[User] Get User Profile Success',
  GetUserProfileError = '[User] Get User Profile Error',
  GetLoginUserProfile = '[User] Get Login User Profile',
  GetLoginUserProfileSuccess = '[User] Get Login User Profile Success',
  GetLoginUserProfileError = '[User] Get Login User Profile Error',
  UpdateUserProfile = '[User] Update User Profile',
  UpdateUserProfileSuccess = '[User] Update User Profile Success',
  UpdateUserProfileError = '[User] Update User Profile Error',
  UpdateUserProfileIndicator = '[User] Update User Profile Indicator',
  LoadTins = '[User] Load Tins',
  LoadTinsSuccess = '[User] Load Tins Success',
  LoadTinsError = '[User] Load Tins Error',
  LoadTinsCached = '[User] Load Tins Cache Success',
  LoadTinFunctions = '[User] Load Tin Functions',
  LoadTinFunctionsSuccess = '[User] Load Tins Functions Success',
  LoadTinFunctionsError = '[User] Load Tins Functions Error',
  GetSecurityQuestions = '[User] Get Security Questions',
  GetSecurityQuestionsSuccess = '[User] Get Security Questions Success',
  GetSecurityQuestionsError = '[User] Get Security Questions Error',
  GetUserFlaggedPatients = '[User] Get User Flagged Patients',
  GetUserFlaggedPatientsSuccess = '[User] Get User Flagged Patients Success',
  GetUserFlaggedPatientsError = '[User] Get User Flagged Patients Error',
  UpdateUserFlaggedPatients = '[User] Update User Flagged Patients',
  UpdateUserFlaggedPatientsSuccess = '[User] Update User Flagged Patients Success',
  UpdateUserFlaggedPatientsError = '[User] Update User Flagged Patients Error',
  GetUserFlaggedClaims = '[User] Get User Flagged Claims',
  GetUserFlaggedClaimsSuccess = '[User] Get User Flagged Claims Success',
  GetUserFlaggedClaimsError = '[User] Get User Flagged Claims Error',
  UpdateUserFlaggedClaims = '[User] Update User Flagged Claims',
  UpdateUserFlaggedClaimsSuccess = '[User] Update User Flagged Claims Success',
  UpdateUserFlaggedClaimsError = '[User] Update User Flagged Claims Error',
  GetUserRecentPatients = '[User] Get User Recent Patients',
  GetUserRecentPatientsSuccess = '[User] Get User Recent Patients Success',
  GetUserRecentPatientsError = '[User] Get User Recent Patients Error',
  GetUserRecentClaims = '[User] Get User Recent Claims',
  GetUserRecentClaimsSuccess = '[User] Get User Recent Claims Success',
  GetUserRecentClaimsError = '[User] Get User Recent Claims Error',
  UpdateSkipCount = '[User] Update Skip Count',
  UpdateSkipCountSuccess = '[User] Update Skip Count Success',
  UpdateSkipCountError = '[User] Update Skip Count Error',
  HideEmailPopup = '[User] Hide Email Popup',
}

export class GetUserProfile implements Action {
  readonly type = UserActionTypes.GetUserProfile;
}

export class GetUserProfileSuccess implements Action {
  readonly type = UserActionTypes.GetUserProfileSuccess;
  constructor(public payload: UserProfile) {}
}

export class GetUserProfileError implements Action {
  readonly type = UserActionTypes.GetUserProfileError;
  constructor(public payload: ServiceError) {}
}

export class GetLoginUserProfile implements Action {
  readonly type = UserActionTypes.GetLoginUserProfile;
}

export class GetLoginUserProfileSuccess implements Action {
  readonly type = UserActionTypes.GetLoginUserProfileSuccess;
  constructor(public payload: LoginProfileResponse) {}
}

export class GetLoginUserProfileError implements Action {
  readonly type = UserActionTypes.GetLoginUserProfileError;
  constructor(public payload: ServiceError) {}
}

export class UpdateUserProfile implements Action {
  readonly type = UserActionTypes.UpdateUserProfile;
  constructor(public payload: Partial<UserProfile>) {}
}

export class UpdateUserProfileSuccess implements Action {
  readonly type = UserActionTypes.UpdateUserProfileSuccess;
  constructor(public payload: Partial<UserProfile>) {}
}

export class UpdateUserProfileError implements Action {
  readonly type = UserActionTypes.UpdateUserProfileError;
  constructor(public payload: ServiceError) {}
}

export class UpdateUserProfileIndicator implements Action {
  readonly type = UserActionTypes.UpdateUserProfileIndicator;
  constructor(public payload: UserProfileIndicatorName) {}
}

export class LoadTins implements Action {
  readonly type = UserActionTypes.LoadTins;
}
export class LoadTinsSuccess implements Action {
  readonly type = UserActionTypes.LoadTinsSuccess;
  constructor(public payload: { tins: ProviderTin[]; lob: string }) {}
}

export class LoadTinsError implements Action {
  readonly type = UserActionTypes.LoadTinsError;
  constructor(public payload: ServiceError) {}
}
export class LoadTinsCached implements Action {
  readonly type = UserActionTypes.LoadTinsCached;
}

export class LoadTinFunctions implements Action {
  readonly type = UserActionTypes.LoadTinFunctions;
  constructor(
    public payload: { entitlements: string[]; functions: string[] },
  ) {}
}
export class LoadTinFunctionsSuccess implements Action {
  readonly type = UserActionTypes.LoadTinFunctionsSuccess;
  constructor(public payload: { tins: TinFunctions }) {}
}

export class LoadTinFunctionsError implements Action {
  readonly type = UserActionTypes.LoadTinFunctionsError;
  constructor(public payload: ServiceError) {}
}

export class GetSecurityQuestions implements Action {
  readonly type = UserActionTypes.GetSecurityQuestions;
}

export class GetSecurityQuestionsSuccess implements Action {
  readonly type = UserActionTypes.GetSecurityQuestionsSuccess;
  constructor(public payload: string[]) {}
}

export class GetSecurityQuestionsError implements Action {
  readonly type = UserActionTypes.GetSecurityQuestionsError;
  constructor(public payload: any) {}
}

export class GetUserFlaggedPatients implements Action {
  readonly type = UserActionTypes.GetUserFlaggedPatients;
}

export class GetUserFlaggedPatientsSuccess implements Action {
  readonly type = UserActionTypes.GetUserFlaggedPatientsSuccess;
  constructor(public payload: FlaggedPatient[]) {}
}

export class GetUserFlaggedPatientsError implements Action {
  readonly type = UserActionTypes.GetUserFlaggedPatientsError;
  constructor(public payload: ServiceError) {}
}

export class UpdateUserFlaggedPatients implements Action {
  readonly type = UserActionTypes.UpdateUserFlaggedPatients;
  constructor(
    public payload: { type: FlagUpdateType; data: FlaggedPatient[] },
  ) {}
}

export class UpdateUserFlaggedPatientsSuccess implements Action {
  readonly type = UserActionTypes.UpdateUserFlaggedPatientsSuccess;
}

export class UpdateUserFlaggedPatientsError implements Action {
  readonly type = UserActionTypes.UpdateUserFlaggedPatientsError;
  constructor(
    public payload: {
      type: FlagUpdateType;
      data: FlaggedPatient[];
      error: ServiceError;
    },
  ) {}
}

export class GetUserFlaggedClaims implements Action {
  readonly type = UserActionTypes.GetUserFlaggedClaims;
}

export class GetUserFlaggedClaimsSuccess implements Action {
  readonly type = UserActionTypes.GetUserFlaggedClaimsSuccess;
  constructor(public payload: FlaggedClaim[]) {}
}

export class GetUserFlaggedClaimsError implements Action {
  readonly type = UserActionTypes.GetUserFlaggedClaimsError;
  constructor(public payload: ServiceError) {}
}

export class UpdateUserFlaggedClaims implements Action {
  readonly type = UserActionTypes.UpdateUserFlaggedClaims;
  constructor(public payload: { type: FlagUpdateType; data: FlaggedClaim[] }) {}
}

export class UpdateUserFlaggedClaimsSuccess implements Action {
  readonly type = UserActionTypes.UpdateUserFlaggedClaimsSuccess;
}

export class UpdateUserFlaggedClaimsError implements Action {
  readonly type = UserActionTypes.UpdateUserFlaggedClaimsError;
  constructor(
    public payload: {
      type: FlagUpdateType;
      data: FlaggedClaim[];
      error: ServiceError;
    },
  ) {}
}

export class GetUserRecentPatients implements Action {
  readonly type = UserActionTypes.GetUserRecentPatients;
}

export class GetUserRecentPatientsSuccess implements Action {
  readonly type = UserActionTypes.GetUserRecentPatientsSuccess;
  constructor(public payload: FlaggedPatient[]) {}
}

export class GetUserRecentPatientsError implements Action {
  readonly type = UserActionTypes.GetUserRecentPatientsError;
  constructor(public payload: ServiceError) {}
}

export class GetUserRecentClaims implements Action {
  readonly type = UserActionTypes.GetUserRecentClaims;
}

export class GetUserRecentClaimsSuccess implements Action {
  readonly type = UserActionTypes.GetUserRecentClaimsSuccess;
  constructor(public payload: FlaggedClaim[]) {}
}

export class GetUserRecentClaimsError implements Action {
  readonly type = UserActionTypes.GetUserRecentClaimsError;
  constructor(public payload: ServiceError) {}
}

export class UpdateSkipCount implements Action {
  readonly type = UserActionTypes.UpdateSkipCount;
  constructor(public payload: MfaSkipRequest) {}
}

export class UpdateSkipCountSuccess implements Action {
  readonly type = UserActionTypes.UpdateSkipCountSuccess;
  constructor(public payload: Partial<UserProfile>) {}
}

export class UpdateSkipCountError implements Action {
  readonly type = UserActionTypes.UpdateSkipCountError;
}

export class HideEmailPopup implements Action {
  readonly type = UserActionTypes.HideEmailPopup;
}

export type UserActions =
  | GetUserProfile
  | GetUserProfileSuccess
  | GetUserProfileError
  | GetLoginUserProfile
  | GetLoginUserProfileSuccess
  | GetLoginUserProfileError
  | UpdateUserProfile
  | UpdateUserProfileSuccess
  | UpdateUserProfileError
  | LoadTins
  | LoadTinsSuccess
  | LoadTinsCached
  | LoadTinsError
  | LoadTinFunctions
  | LoadTinFunctionsSuccess
  | LoadTinFunctionsError
  | GetSecurityQuestions
  | GetSecurityQuestionsSuccess
  | GetSecurityQuestionsError
  | GetUserFlaggedPatients
  | GetUserFlaggedPatientsSuccess
  | GetUserFlaggedPatientsError
  | UpdateUserFlaggedPatients
  | UpdateUserFlaggedPatientsSuccess
  | UpdateUserFlaggedPatientsError
  | GetUserFlaggedClaims
  | GetUserFlaggedClaimsSuccess
  | GetUserFlaggedClaimsError
  | UpdateUserFlaggedClaims
  | UpdateUserFlaggedClaimsSuccess
  | UpdateUserFlaggedClaimsError
  | GetUserRecentPatients
  | GetUserRecentPatientsSuccess
  | GetUserRecentPatientsError
  | GetUserRecentClaims
  | GetUserRecentClaimsSuccess
  | GetUserRecentClaimsError
  | UpdateSkipCount
  | UpdateSkipCountSuccess
  | UpdateSkipCountError
  | HideEmailPopup;
