import {
  AppContext,
  CignaOutcomeAdditionalDetails,
  CignaStandardOutcome,
  ClaimStatusInboundType,
  ClaimSummary,
  ClaimSystemCode,
  ServiceError,
} from '@cigna/chcp/shared/util';

export interface DetailRequest {
  claimReferenceNumber: string;
  claimCompositeKey: string;
  tinNumbers: string;
  compositeClaimId?: string;
  medicare?: string;
  memberSearch?: string;
  groupNumber?: string;
  ami?: string;
  idCardExtension?: string;
}
export interface ClaimsSearchCriteria {
  memberId: string;
  dateOfServiceOption: string;
  access: string;
  dob: Date | null;
  asOfDate: Date | null;
  toDate: Date | null;
  lastName: string;
  firstName: string;
  providerSelAccNum: string;
  pvdrGenPatAcctNo?: string;
  claimSystemCode?: string;
  relationShipCode?: string;
  memberSearchKey?: string;
  isPayerSolution?: boolean;
  hasBehavioral?: boolean;
  isSharedAdmin?: boolean;
  uniqueMemberKey?: string;
  patientId?: string;
  claimReferenceNumber?: string;
  generatedTinId?: string;
  tinName?: string;
  memberIdentifier?: string;
}

export interface ClaimsSearchTypes {
  value: string;
  id: string;
  name: string;
  description: string;
}
export interface DateOfServiceOptions {
  value: string;
  description: string;
}
export interface ClaimsReferSearchCriteria {
  claimReferNo: string;
  providerSelRefNum: string;
}

export interface MetaData {
  serviceReferenceId: string;
  outcome: CignaStandardOutcome;
}

export interface ClaimsResultsResponse {
  metadata: MetaData;
  searchClaimsResponseObj: ClaimsSearchResponse;
}

export interface ClaimsSearchResponse {
  isDisplayEnabled: boolean;
  claimSummaryMessages: ClaimSummaryMessages[];
  tooManyClaims: boolean;
  displayHideResultsLink: boolean;
  isMultiplePatients: boolean;
  showCoverage: boolean;
  coverageCompositeKey: string;
  noRecord: boolean;
  showDropDownLink: boolean;
  memberList: MemberSearch[];
  claimSummaryList: ClaimSummary[];
}

export interface ClaimSummaryMessages {
  claimSummaryText: string;
}

export interface MemberSearch {
  memberId: string;
  memberIdActual?: string;
  memberDateOfBirth: string;
  memberFirstName: string;
  memberLastName: string;
  hasRestrictedAccount: boolean;
  hasRestrictedGroup: boolean;
  claimSystemCode?: string;
  relationShipCode?: string;
  memberSearchKey?: string;
  isPayerSolution?: boolean;
  hasBehavioral?: boolean;
  isSharedAdmin?: boolean;
  notes: string;
  uniqueMemberKey?: string;
  patientId?: string;
  memberIdentifier?: string;
}
export interface MemberDetail {
  memberId: string;
  memberIdActual?: string;
  memberDateOfBirth: string;
  memberFirstName: string;
  memberLastName: string;
  relationShipCode?: string;
  memberSearchKey?: string;
  hasBehavioral?: boolean;
  uniqueMemberKey?: string;
  patientId?: string;
  memberIdentifier?: string;
}

export interface ClaimDetails {
  claimCompositeKey: string;
  memberObject: MemberDetail;
  claimInfoObject: ClaimsInfo;
  tinNumber?: string;
  medicare?: boolean;
  remittanceSummaryData?: RemittanceSummary;
  correspondenceHistory?: CorrespondenceHistory[];
}

export interface ClaimsInfo {
  serviceProvider: string;
  renderingProviderId?: string;
  renderingProviderAddressState?: string;
  networkStatusCode?: string;
  claimDateOfService: string;
  claimTotChargeAmount: string;
  claimReferenceNumber: string;
  compositeClaimId?: string;
  groupNumber: string;
  claimStatusDesc: ClaimStatusInboundType;
  coverageCompositeKey: string;
  uploadUrl?: string;
  isWebster: boolean;
  isDDAR?: boolean;
  isDOSEstimate: boolean;
  claimEngineClaimID: string;
  networkPPOIndicator?: string;
  sourceSystemCode: ClaimSystemCode;
  firstName: string;
  lastName: string;
  ssn: string;
  ami: string;
  idCardExtensionCode: string;
  remarkCodes: RemarksCodes[];
  claimDetailsInfoMessages: ClaimDetailsInfoMessages[];
  providerGeneratedAccountNumber?: string;
  isBehavioral: boolean;
  claimReceivedDate: string;
  claimProcessedDate: string;
  claimDrgUsedCode: string;
  claimStatusCodes: ClaimsStatusCode[];
  paymentInfoMessages: PaymentInfoMessages[];
  patientResponsibility: string;
  claimTotPaidAmount: string;
  paymentInfo: PaymentInfo;
  payeeInfo: PayeeInfo[];
  serviceLineInfoArray: ServiceLineInfo[];
  serviceLineTotalsObject: ServiceLineTotal;
  explanationOfRemarkCodes: ExplanationOfRemarkCodes[];
  additionalRemarks: string;
  multiPendRemarkCodes: MultiPendRemarkCodes[];
  remittanceSummaryData: RemittanceSummary;
  payeePaymentInfoMessages: PayeePaymentInfoMessages[];
  footNoteMessages?: string[];
  isZelis?: boolean;
  showPaymentInfo?: boolean;
  claimAmountDue?: string;
  recoupedAmount?: string;
  claimAmountPaid?: string;
  paymentProviderAmt?: string;
  hsaAmount?: string;
  hraAmount?: string;
  fsaAmount?: string;
  haaAmount?: string;
  hfAmount?: string;
  lpAmount?: string;
  overPaidClaimsInfo?: OverpaidClaimsInfo[];
}

export interface OverpaidClaimsInfo {
  claimReferenceNumber: string;
  patientAccountNumber: string;
  claimPaidDate: string;
  recoupmentAmount: string;
  recoupmentBalance: string;
  claimCompositeKey: string;
  compositeClaimId: string;
  tin: string;
  showLink: boolean;
}

export interface RemarksCodes {
  remarkCode: string;
  desc?: string;
}

export interface ClaimDetailsInfoMessages {
  claimInfoText: string;
}

export interface ClaimsStatusCode {
  statusCodeValue: string;
  statusCodeDesc: string;
  reasonCodeValue: string;
  reasonCodeDesc: string;
}

export interface PaymentInfoMessages {
  label: string;
  value: string;
}

export interface PaymentInfo {
  chargeAmount: number;
  adjustmentDueOtherInsurence: number;
  estimate: number;
  cignaPaidAmount: number;
}

export interface PayeeInfo {
  eftExists: boolean;
  chkExists: boolean;
  paymentInfoMessage?: string;
  payeeName: string;
  payeeAddress: string;
  checkAmount: string;
  remittanceNumber: string;
  checkNumber: string;
  checkStatus: string;
  checkIssuedDate: string;
  checkClearedDate: string;
  paymentMethod: string;
}

export interface PayeePaymentInfoMessages {
  paymentInfoText: string;
}

export interface ServiceLineInfo {
  id: string;
  dateOfService: string;
  cdtCode: string;
  procedureCode: string;
  toothNumber: string;
  amountCharged: string;
  svcLineContractedAmount: string;
  svcLinePaidAmount: string;
  placeOfService: string;
  allowedAmount: string;
  serviceLineApcCode: string;
  amountNotCovered: string;
  deductible: string;
  coveredBalance: string;
  planCoInsurancePer: string;
  memberCoInsurancePer: string;
  memberCoInsu?: string;
  memberResponsibility: string;
  patientCoinsuranceResponsibility: string;
  copay: string;
  remarkCode: string;
  propRemarkDescription: string;
  hraPayment: string;
  hsaPayment: string;
  fsaPayment: string;
  haPayment: string;
  amountNotAllowed?: string;
}

export interface ServiceLineTotal {
  amountChargedTotal: string;
  allowedAmountTotal?: string;
  amountNotCoveredTotal: string;
  deductibleTotal: string;
  coveredBalTotal: string;
  contractedAmountTotal: string;
  coordinationBenefitTotal: string;
  planCoinsurancePaidTotal: string;
  coPayTotal: string;
  memberCoInsuTotal: string;
  memberResponseTotal: string;
  paidFromHATotal: string;
  paidFromHSATotal: string;
  paidFromHRATotal: string;
  paidFromFSATotal: string;
}

export interface ExplanationOfRemarkCodes {
  remarkCode: string;
  remarkCodeDesc: string;
  additionalDesc?: string;
}

export interface MultiPendRemarkCodes {
  remarkCode: string;
  remarkCodeDesc: string;
}
export interface RemittanceDetailsRequest {
  tinNumbers: string;
  claimReferenceNumber: string;
}
export interface RemittanceDetailsResponse {
  metaData: MetaData;
  remittanceSummaryData: RemittanceSummary;
}
export interface RemittanceSummary {
  isNPINumber: boolean;
  remittanceData: RemittanceData[];
  isZelis?: boolean;
  paymentInfo?: PayeeInfo[];
  serviceError?: ServiceError;
  additionalDetails?: CignaOutcomeAdditionalDetails[];
}

export interface RemittanceData {
  controlStr: string;
  creationDate: string;
  creationDateStr?: string;
  dataPowerLink: string;
  ddarMaxpages: number;
  depositAmount: string;
  depositAmountStr?: string;
  depositDate: string;
  depositDateStr?: string;
  docId: string;
  documentType: string;
  documentTypeStr: string;
  expirationDate: string;
  generatedDate: string;
  isZelis: boolean;
  links: number;
  mbrDateofBirth: string;
  mbrFirstName: string;
  mbrLastName: string;
  npiList: string[];
  numberOfPages: number;
  pagesList: string[];
  paymentDate: string;
  processedDate: string;
  productType: string;
  readRemittance: boolean;
  remittanceTrackingNumber: string;
  reportCategory: string;
  taxId: string;
}

export interface SearchClaim {
  type: string;
  startDateOfService?: string | null;
  endDateOfService?: string | null;
  claimReferenceNumber?: string;
  tinNumbers: string;
  memberId?: string;
  memberDateOfBirth?: string | null;
  memberLastName?: string;
  memberFirstName?: string;
  providerGeneratedNumber?: string;
  claimSystemCode?: string;
  relationShipCode?: string;
  memberSearchKey?: string;
  isPayerSolution?: boolean;
  hasBehavioral?: boolean;
  isSharedAdmin?: boolean;
  multiPatient?: string;
  patientId?: string;
  memberIdentifier?: string;
}

export interface FlagClaims {
  flagClaim: ClaimSummary[];
}

export interface Tins {
  tinId: string;
  tinName: string;
  generatedTinId: string;
}
export interface SupportedDocumentData {
  claimEngId: string;
  claimSrcCd: string;
  fileSizeStr: string;
  inFileName: string;
  outFileNam: string;
  status: string;
  thumbnailEncodedString: string;
  uploadedDate: string;
  userId: string;
  documentByteArray: string;
  fileSize: string;
  imageSeqNum: string;
  reqSeqNum: string;
}
export interface ClaimAttachmentMeta {
  claimAttachmentMetaRequest: ClaimAttachmentMetaRequest;
}

export interface ClaimAttachmentMetaRequest {
  claimEngineClaimID: string;
  claimEngineSource: string;
  fileCount: string;
  fileNames: string[];
  intakeFileID: string;
  ediClaimID: string;
  receiptDate: string;
  claimAttachmentMetaResponse: ClaimAttachmentMetaResponse;
}

export interface ClaimAttachmentMetaResponse {
  code: string;
  description: string;
}

export interface RemittanceRead {
  remittanceTrackingNumber: string;
  creationDate: string;
  readStatus: boolean;
}

export interface RemittanceReadResponse {
  remittanceReadData: RemittanceReadData;
  metadata: MetaData;
}

export interface RemittanceReadData {
  remittance: Remittance[];
}

export interface Remittance {
  Num: string;
  Cdt: string;
}

export interface CodeSetValue {
  value: string;
  active: boolean;
  startDate: string;
  endDate: string;
  shortDescription: string;
  longDescription: string;
  codeSetId: string;
  codeSetName: string;
  created: string;
  updated: string;
}

export interface CodeSetResponse {
  metaData: any;
  codeValues: CodeSetValue[];
}

export type ClaimTabType = 'member' | 'claimNumber';

export enum ClaimTab {
  MEMBER = 'member',
  CLAIM_NUMBER = 'claimNumber',
}

export const INN = 'In-Network';
export const OON = 'Out-of-Network';

export interface UsefulLink {
  name: string;
  path: string;
  targetApp: AppContext;
}

export interface HelpDropdown {
  name: string;
  contactNumber: string;
}

export interface CorrespondenceHistory {
  claimId?: string;
  precertId?: string;
  corresType?: string;
  documentLink: string;
  generatedOn?: string;
  subject?: string;
  isRead?: boolean;
  docId: string;
  isViewPdfLoading?: boolean;
}
