import { Injectable } from '@angular/core';
import { OmniConversationSummaryCard } from '@cigna/omni/conversation-summary-card-feature';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getSortedSummaryCardItems } from './omni-conversation-summary-list.selectors';

@Injectable()
export class ConvSummaryListFacade {
  public sortedSummaryCardItems$: Observable<OmniConversationSummaryCard[]> =
    this.store$.pipe(select(getSortedSummaryCardItems));

  constructor(public store$: Store<any>) {}
}
