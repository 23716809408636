<div class="promo-wrap" [class.promo-accent]="hasAccentColor">
  <div class="circle-icon-bg">
    <mat-icon [svgIcon]="iconName" aria-label=""></mat-icon>
  </div>
  <cigna-leaf-heading
    type="title-default"
    [brandColor]="true"
    tagVariant="h3"
    >{{ title }}</cigna-leaf-heading
  >
  <div>
    <ng-content></ng-content>
  </div>
</div>
