import { Injectable } from '@angular/core';
import { OmniChatFacade, chatQuery } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { CommChannelContent } from '@cigna/omni/shared-util';
import { ConversationDTO } from '@cigna/vampire-dto';
import { select, Store } from '@ngrx/store';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { selectRecentActiveOrUnreadConversationSummaryListItem } from './comm-channel.selectors';
@Injectable({
  providedIn: 'root',
})
export class OmniCommChannelFacade {
  public supportContent$ = this.store$.pipe(
    select(chatQuery.getCommChannelContent),
    map((content) => content['support']),
  );

  public cheContent$ = this.store$.pipe(
    select(chatQuery.getCommChannelContent),
    map((content) => content['che']),
  );

  public eapContent$ = this.store$.pipe(
    select(chatQuery.getCommChannelContent),
    map((content) => content['eap']),
  );

  public hasNoHistory$: Observable<boolean> =
    this.conversationsFacade.conversationCount$.pipe(
      map((count) => !count),
      publishReplay(1),
      refCount(),
    );

  public recentActiveOrUnreadConversationSummaryListItem$ = this.store$.pipe(
    select(selectRecentActiveOrUnreadConversationSummaryListItem),
    publishReplay(1),
    refCount(),
  );

  constructor(
    private chatFacade: OmniChatFacade,
    private conversationsFacade: OmniConversationsFacade,
    private store$: Store<any>,
  ) {}

  public interact(
    { buttonText, buttonId }: CommChannelContent,
    context: ConversationDTO['context'],
    canTrackBubbleClick = true,
  ) {
    if (canTrackBubbleClick) {
      this.conversationsFacade.chatBubbleClick(buttonId, buttonText);
    }
    this.conversationsFacade.createConversation('bot', context);
    this.chatFacade.openDialog();
  }

  public launchSplashView({ buttonText, buttonId }: CommChannelContent) {
    this.conversationsFacade.chatBubbleClick(buttonId, buttonText);
  }

  public chatWithHuman(context: ConversationDTO['context']) {
    this.conversationsFacade.chatWithHumanClick();
    this.conversationsFacade.createConversation('bot', context);
    this.chatFacade.openDialog();
  }

  public nonLiveChatClicked({ buttonText, buttonId }: CommChannelContent) {
    this.conversationsFacade.chatBubbleClick(buttonId, buttonText, true);
  }
}
