import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabLabelDirective } from './shared/customization/tab-label.directive';
import { TabComponent } from './tab/tab.component';
import { TabButtonComponent } from './tab-button/tab-button.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabNavbarComponent } from './tab-navbar/tab-navbar.component';
import { SlidingTabComponent } from './sliding-tab/sliding-tab.component';
import { SlidingTabsGroupComponent } from './sliding-tabs-group/sliding-tabs-group.component';
import { UiResponsiveModule } from '@cigna/shared/angular/responsive-ui';
import { UiIconModule } from '@cigna/shared/angular/icon-ui';
import { UiUtilsModule } from '@cigna/shared/angular/utils-ui';

const DECLARATIONS = [
  TabComponent,
  TabLabelDirective,
  TabButtonComponent,
  TabGroupComponent,
  TabNavbarComponent,
  SlidingTabComponent,
  SlidingTabsGroupComponent,
];

@NgModule({
  imports: [CommonModule, UiIconModule, UiResponsiveModule, UiUtilsModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class UiTabsModule {}
