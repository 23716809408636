import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cigna-omni-comm-channel-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  public copy: string | null;
  @Input()
  public omniQaTag: string | null;

  @Output()
  public interaction: EventEmitter<void> = new EventEmitter();

  public buttonInteraction(): void {
    this.interaction.emit();
  }
}
