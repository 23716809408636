import { Injectable, Injector, Inject } from '@angular/core';
import { createEffect, OnInitEffects, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { timer, throwError } from 'rxjs';
import { map, tap, retryWhen, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Authenticator, AuthError } from '@cigna/shared/angular/auth/oidc-util';
import { AuthPartialState, AUTH_FEATURE_KEY } from './auth.reducer';
import {
  loadAuth,
  loadAuthSuccess,
  loadAuthError,
  noopLoadAuth,
} from './auth.actions';
import { AUTH_CONFIG, AuthConfig, AuthErrorHandler } from '../config';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthEffects implements OnInitEffects {
  private _authenticator: Authenticator;
  private _errorHandler: AuthErrorHandler;

  loadAuth$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadAuth),
      withLatestFrom(this._store$),
      fetch<[AuthPartialState], ReturnType<typeof loadAuth>>({
        run: (_, state) =>
          this._authenticator
            .authenticate({
              previousAccessToken:
                state?.[AUTH_FEATURE_KEY]?.previousAccessToken,
            })
            .pipe(
              retryWhen((error$) =>
                error$.pipe(
                  mergeMap((error: AuthError, attempt) =>
                    !(error instanceof AuthError) ||
                    error.isLoginRequired() ||
                    attempt > 0
                      ? throwError(error)
                      : timer(500),
                  ),
                ),
              ),
              map((auth) => loadAuthSuccess({ auth })),
            ),

        onError: (_, error: AuthError | Error) =>
          loadAuthError({
            error: error instanceof AuthError ? error : new AuthError(error),
          }),
      }),
    ),
  );

  loadAuthError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(loadAuthError),
        tap(({ error }) => this._errorHandler.onError(error)),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    @Inject(AUTH_CONFIG) private _config: AuthConfig,
    injector: Injector,
    private _store$: Store<AuthPartialState>,
  ) {
    this._authenticator = injector.get(this._config.authenticator);
    this._errorHandler = this._config.errorHandler
      ? injector.get(this._config.errorHandler)
      : { onError: () => {} };
  }

  ngrxOnInitEffects() {
    /** note that dispatching `loadAuth` action BEFORE ngrxOnInitEffects doesn't trigger loadAuth$ effect */

    if (this._config.loadBehavior === 'onDemand') {
      return noopLoadAuth();
    }

    return loadAuth();
  }
}
