<div class="breadcrumb">
  <div *ngFor="let breadcrumb of breadCrumbs; let last = last; let i = index">
    <a
      *ngIf="!last"
      [routerLink]="breadcrumb.url"
      [queryParams]="breadcrumb.queryParams ? breadcrumb.queryParams : null"
      [queryParamsHandling]="breadcrumb.preserveQueryParams ? 'preserve' : ''"
      [attr.data-test]="'breadcrumb-' + i"
      >{{ breadcrumb.name }}</a
    >
    <span class="breadcrumb-separator" *ngIf="!last">
      <ng-container [ngSwitch]="appContext">
        <ng-container *ngSwitchCase="'evernorth'">&bull;</ng-container>
        <ng-container *ngSwitchDefault>></ng-container>
      </ng-container>
    </span>
    <span *ngIf="last" data-test="breadcrumb-last">{{ breadcrumb.name }}</span>
  </div>
</div>
