import { createAction, props } from '@ngrx/store';

export const dismissFancyInvitation = createAction(
  '[Omni - FancyInvitations] Dismiss FancyInvitation',
  props<{ id: string }>(),
);

export const trackReplyToMessageFancyInvitation = createAction(
  '[Omni - FancyInvitations] Reply To Message Analytics Tracking',
);

export const fancyInvitationsViewed = createAction(
  '[Omni - FancyInvitations] View FancyInvitation',
);
