import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import {
  GatewayConfig,
  SHARED_GATEWAY_CONFIG,
} from '@cigna/shared/angular/gateways-util';
import { DcmAssetsFacade } from '@cigna/omni/dcm-state-data-access';

@Component({
  selector: 'cigna-dcm-advocate-matched',
  templateUrl: './dcm-advocate-matched.component.html',
  styleUrls: ['./dcm-advocate-matched.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DcmAdvocateMatchedComponent implements AfterContentInit {
  @Input()
  public agentName: string;
  @Input()
  public conversationId: string;
  @Output()
  openDialog: EventEmitter<void> = new EventEmitter();

  cdn: string;
  assets$ = this.dcmAssetsFacade.DcmAssets$;

  goToProfile(): void {
    this.router.navigateByUrl('/care-advocate/profile');
  }
  goToSendMessage(name: string): void {
    this.conversationsFacade.setConversationId(this.conversationId);
    this.conversationsFacade.setAgentName(this.agentName ?? name);
    this.openDialog.emit();
  }

  constructor(
    private router: Router,
    private conversationsFacade: OmniConversationsFacade,
    private readonly dcmAssetsFacade: DcmAssetsFacade,
    @Inject(SHARED_GATEWAY_CONFIG) private readonly _config: GatewayConfig,
  ) {
    this.cdn = this._config.cdn;
  }
  ngAfterContentInit() {
    this.dcmAssetsFacade.loadAssets(this.conversationId);
  }
}
