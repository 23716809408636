import { Component, Input } from '@angular/core';
// TODO: https://git.sys.cigna.com/angular-guild/cigna-nx/issues/567
// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AlertItem } from '@cigna/chcp/shell/data-access';
import { ShellTrackHandlers } from '@cigna/chcp/shared/analytics-util';

@Component({
  selector: 'cigna-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
  @Input() alert: AlertItem;
  constructor(private analytics: ShellTrackHandlers) {}

  alertClick(event: MouseEvent) {
    const linkTarget = event.target as HTMLLinkElement;
    if (linkTarget instanceof HTMLAnchorElement) {
      const text = linkTarget.textContent || '';
      const href = linkTarget.href;

      if (href.includes('.pdf')) {
        this.analytics.trackEventDownload(
          {
            mouseEvent: {
              currentTarget: linkTarget,
            } as any,
            injector: {} as any,
          },
          text.trim(),
          'ac-DB008-Alert Click',
        );
      } else {
        this.analytics.trackEventOther({}, text.trim(), 'ac-DB008-Alert Click');
      }
    }
  }
}
