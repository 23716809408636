import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { ChatPreferences } from '@cigna/omni/data-access';
import { observeOnZone } from '@cigna/omni/shared-util';
import { Observable, fromEventPattern, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { OmniRightNowConfig, OMNI_RIGHT_NOW_CONFIG } from './right-now-config';
import {
  RightNowClient,
  RightNowEventHandler,
  RightNowEventType,
  RightNowWidget,
  RightNowWindow,
} from './right-now.models';

@Injectable({
  providedIn: 'root',
})
export class RightNowService {
  public window: RightNowWindow = window as RightNowWindow;
  public widget: RightNowWidget;

  constructor(
    private ngZone: NgZone,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(OMNI_RIGHT_NOW_CONFIG) private config: OmniRightNowConfig,
  ) {}

  public loadClientScript(): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.window.RightNow) {
        observer.next();
        observer.complete();
      }
      const script = this._document.createElement('script');
      script.setAttribute('src', this.config.sdkUrl);
      script.setAttribute('type', 'text/javascript');
      script.onload = () => {
        if (this.window.RightNow) {
          observer.next();
          observer.complete();
        } else {
          observer.error(
            'loaded RightNow client script but could not find client on the DOM at window.RightNow',
          );
        }
      };
      script.onerror = () =>
        observer.error('Failed to load RightNow client script!');
      this._document.body.appendChild(script);
    });
  }

  public initializeWidget(
    preferences: ChatPreferences,
  ): Observable<RightNowWidget> {
    if (!this.window.RightNow) {
      return throwError('RightNow client not loaded on window!');
    }

    const widgetConfig = this.mapPrefsToWidgetConfig(preferences);

    const instanceId = widgetConfig['instance_id'];

    const widgetStream$ = this.rightNowEventAsObservable(
      this.window.RightNow.Client,
      'evt_widgetLoaded',
    ).pipe(
      map(() => {
        const widget = (this.window as any)[instanceId];
        if (!widget) {
          throw new Error(
            `RightNow widgetLoaded event received but widget not found on window at: ${instanceId}`,
          );
        }
        this.widget = widget;
        return widget;
      }),
    );
    this.window.RightNow.Client.Controller.addComponent(
      widgetConfig,
      this.config.liveChatUrl,
    );

    return widgetStream$;
  }

  startChat(provider: string) {
    if (provider === 'usw') {
      this.window.open(
        this.config.fedRedirectUrl,
        'window',
        'resizable=yes, width=450, height=600',
      );
    } else {
      this.widget.chatLinkClicked();
    }
  }

  private mapPrefsToWidgetConfig(
    preferences: ChatPreferences,
  ): Record<string, string> {
    return preferences.properties
      .filter((prop) => prop.name.startsWith('myc|'))
      .reduce(
        (config, { name, value }) => ({
          ...config,
          [name.substring(4)]: value,
        }),
        {},
      );
  }

  private rightNowEventAsObservable(
    client: RightNowClient,
    eventName: RightNowEventType,
  ) {
    return fromEventPattern(
      (handler: RightNowEventHandler) =>
        client.Event[eventName].subscribe(handler),
      (handler: RightNowEventHandler) =>
        client.Event[eventName].unsubscribe(handler),
    ).pipe(observeOnZone(this.ngZone));
  }
}
