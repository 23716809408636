import { NgModule, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

import { IconComponent } from './icon.component';
import { IconTriangleComponent } from './icon-triangle/icon-triangle.component';
import { ICON_ASSETS_BASE_URL, iconLoader } from './icon-loader';

export const FOR_ROOT_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: iconLoader,
    deps: [DomSanitizer, MatIconRegistry, ICON_ASSETS_BASE_URL],
  },
  // Don't warn about missing Material CSS, which isn't needed for icons
  { provide: MATERIAL_SANITY_CHECKS, useValue: false },
];

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [IconComponent, IconTriangleComponent],
  exports: [IconComponent, IconTriangleComponent, MatIconModule],
})
export class UiIconModule {
  /** Not needed if you've already imported `UIModule.forRoot()`! */
  static forRoot(iconUrlProvider: {
    factory: (...deps: any[]) => string;
    deps?: any[];
  }): ModuleWithProviders<UiIconModule> {
    return {
      ngModule: UiIconModule,
      providers: [
        ...FOR_ROOT_PROVIDERS,
        {
          provide: ICON_ASSETS_BASE_URL,
          useFactory: iconUrlProvider.factory,
          deps: iconUrlProvider.deps,
        },
      ],
    };
  }
}
