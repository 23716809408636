import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';

import {
  AppContext,
  checkValidResponse,
  logError,
} from '@cigna/chcp/shared/util';
import { AlertItem, AlertRaw } from './alerts.model';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
  APP_CONTEXT,
} from '@cigna/chcp/shared/environment-util';

@Injectable({ providedIn: 'root' })
export class AlertsService {
  public CONSUMER_CODE = '1000';

  public dashboardApi: string;
  public publicAlertsApi: string;
  public gsgApi: string;

  constructor(
    private http: HttpClient,
    @Inject('secureApi') secureApi: string,
    @Inject('consumerCode') public consumerCode: string,
    @Inject(APP_CONTEXT) public appContext: AppContext,
    @Inject(ENVIRONMENT_CONFIG) private envConfig: EnvironmentConfig,
  ) {
    this.dashboardApi = `${secureApi}/dashboard/v1`;
    this.publicAlertsApi = `${this.envConfig.api.gateways.cdn}`;
    this.gsgApi = `${this.envConfig.api.gateways.gsgSecureApi}/caas/api/v1/athlv/contents`;
  }

  getSecureAlertsAws(lob?: string | null) {
    const params: HttpParams = new HttpParams().set(
      'line_of_business',
      lob || 'MEDBH',
    );
    return this.http.get(`${this.gsgApi}/alert`, { params }).pipe(
      map(checkValidResponse),
      map((res) => res.contents.map(this.parseAlert)),
      catchError(logError),
    );
  }

  getPublicAlerts() {
    const params: HttpParams = new HttpParams().set(
      'consumerCode',
      this.consumerCode,
    );

    const jsonPath =
      this.appContext === 'chcp'
        ? 'chcp/alerts/chcpalerts.json'
        : 'evernorth/provider/alerts/enprovideralerts.json';

    return this.http
      .get(`${this.publicAlertsApi}assets/${jsonPath}`, {
        params,
      })
      .pipe(
        map(checkValidResponse),
        map((res) => res.alerts.map(this.parseAlert)),
        catchError(logError),
      );
  }

  parseAlert(alert: AlertRaw): AlertItem {
    return {
      id: alert.id,
      category: alert.category,
      subcategory: alert.subcategory,
      // alert.content.item.body response from api comes wrapped in various <p> tags that mess up formatting
      // tried to remove it at the source but no luck yet so we are cleaning it up here for them :)
      body: alert.content.item.body
        .replace(/&nbsp;/g, '')
        .replace(/\n/g, '<br/>')
        .replace(/<p>/g, '<span>')
        .replace(/<\/p>/g, '</span>'),
      header: alert.content.item.header,
    };
  }
}
