import { InjectionToken } from '@angular/core';

export const OMNI_CONFIG = new InjectionToken<OmniConfig>('OMNI_CONFIG');

export interface OmniConfig {
  omni: {
    sonicNotification: string;
    identityUrl: string;
    awsIdentityUrl: string;
    botUrl: string;
    liveUrl: string;
    awsLiveUrl: string;
    publicUrl?: string;
  };
  chatPreferencesUrl: string;
  api: {
    gateways: {
      cdn: string;
    };
  };
}

export enum ApiVersion {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3',
}
