<section
  class="collapsible"
  [class.collapsible--expanded]="expanded"
  [class.collapsible--compact]="size === 'compact'"
  [class.collapsible--without-border]="!hasBorder"
  data-test="collapsible-header-results"
>
  <button
    class="collapsible__header"
    (click)="toggle()"
    data-test="collapsible_header"
    [class.collapsible__header--with-icon]="icon"
    [class.collapsible__header--disabled]="disabled"
    [class.collapsible__header--expanded]="expanded"
    [attr.aria-expanded]="expanded"
  >
    <div class="collapsible__header-icon" *ngIf="icon">
      <cigna-icon
        class="collapsible__icon"
        [class.collapsible__icon--expanded]="expanded"
        [type]="icon"
        data-test-id="collapsible-header-icon"
      ></cigna-icon>
    </div>

    <span
      class="collapsible__header-text"
      data-test-id="collapsible-header-text"
      [attr.data-test]="'btn-' + headerText"
      [innerHTML]="headerText"
    ></span>

    <cigna-icon
      class="collapsible__header__arrow"
      [class.collapsible__header__arrow-down]="!expanded"
      type="arrow-ic"
      data-test-id="collapsible-header-arrow"
    ></cigna-icon>
  </button>
  <div *ngIf="expanded" class="collapsible__content">
    <ng-content></ng-content>
  </div>
</section>
