import {
  Component,
  HostListener,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ElementRef,
  Renderer2,
  Input,
} from '@angular/core';

@Component({
  selector: 'cigna-tab-navbar',
  templateUrl: './tab-navbar.component.html',
  styleUrls: ['./tab-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavbarComponent implements AfterViewChecked {
  tabElement: HTMLElement[] = [];
  @Input() isMultiLineTab = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    this.tabElement = Array.from(
      this.elementRef.nativeElement.children,
    ) as HTMLElement[];

    if (this.isMultiLineTab) {
      this.tabElement.forEach((el) => {
        this.renderer.addClass(el, 'responsive-multiline');
      });
    }
  }

  // Focus on the next tab, wrapping to the first tab
  switchToNextTab(currentIndex: number) {
    const nextIndex = currentIndex + 1;
    if (nextIndex < this.tabElement.length) {
      this.tabElement[nextIndex].focus();
    } else {
      this.tabElement[0].focus();
    }
  }

  // Focus on the previous tab, wrapping to the last tab
  switchToPreviousTab(currentIndex: number) {
    const previousIndex = currentIndex - 1;
    if (previousIndex >= 0) {
      this.tabElement[previousIndex].focus();
    } else {
      this.tabElement[this.tabElement.length - 1].focus();
    }
  }

  // Handle keydown on tabs
  keydownEventListener(event: KeyboardEvent) {
    const currentIndex = this.tabElement.findIndex(
      (tab) => tab === event.target,
    );

    const key: string = event.key;

    switch (key) {
      case 'ArrowLeft':
      case 'Left':
        event.preventDefault();
        this.switchToPreviousTab(currentIndex);
        break;
      case 'ArrowRight':
      case 'Right':
        event.preventDefault();
        this.switchToNextTab(currentIndex);
        break;
    }
  }

  @HostListener('keydown', ['$event']) onkeydown(event: KeyboardEvent) {
    this.keydownEventListener(event);
  }
}
