import {
  Pipe,
  PipeTransform,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { FeatureTogglesFacade } from './+state/feature-toggles.facade';
import { ToggleValue } from './+state/interfaces';

@Pipe({
  name: 'cignaFeatureValue',
  pure: false,
})
export class FeatureValuePipe implements PipeTransform, OnDestroy {
  private sub?: Subscription;
  private current: {
    toggle?: string;
    value: ToggleValue;
  } = { value: false };

  constructor(
    private _facade: FeatureTogglesFacade,
    private _ref: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  transform(toggle: string): ToggleValue {
    if (this.current.toggle === toggle) {
      return this.current.value;
    }

    this.current = { toggle, value: false };

    this.sub?.unsubscribe();
    this.sub = this._facade.featureValue(toggle).subscribe((v) => {
      this.current.value = v;
      this._ref.markForCheck();
    });

    return this.current.value;
  }
}
