// eslint-disable-next-line max-classes-per-file
import { ChatPreferences } from '@cigna/omni/data-access';
import { Action } from '@ngrx/store';

export enum RightNowActionTypes {
  GetChatPreferences = '[Omni - RightNow] Get ChatPreferences',
  GetChatPreferencesFailure = '[Omni - RightNow] ChatPreferences Failure',
  GetChatPreferencesSuccess = '[Omni - RightNow] ChatPreferences Success',
  LoadClientScript = '[Omni - RightNow] Load Client Script',
  LoadClientScriptFailure = '[Omni - RightNow] Client Script Failure',
  LoadClientScriptSuccess = '[Omni - RightNow] Client Script Success',
  InitializeWidget = '[Omni - RightNow] Initialize Widget',
  InitializeWidgetSuccess = '[Omni - RightNow] Widget Init Success',
  InitializeWidgetFailure = '[Omni - RightNow] Widget Init Failure',
  StartChat = '[Omni - RightNow] StartRightNowChat',
  StartChatFailure = '[Omni - RightNow] Start Chat Failure',
  StartChatSuccess = '[Omni - RightNow] Start Chat Success',
}

export class GetChatPreferences implements Action {
  readonly type = RightNowActionTypes.GetChatPreferences;
}

export class GetChatPreferencesFailure {
  readonly type = RightNowActionTypes.GetChatPreferencesFailure;
  constructor(public error: any) {}
}

export class GetChatPreferencesSuccess {
  readonly type = RightNowActionTypes.GetChatPreferencesSuccess;
  constructor(public preferences: ChatPreferences) {}
}

export class LoadClientScript implements Action {
  readonly type = RightNowActionTypes.LoadClientScript;
}

export class LoadClientScriptFailure implements Action {
  readonly type = RightNowActionTypes.LoadClientScriptFailure;
  constructor(public error: any) {}
}

export class LoadClientScriptSuccess implements Action {
  readonly type = RightNowActionTypes.LoadClientScriptSuccess;
}

export class InitializeWidget implements Action {
  readonly type = RightNowActionTypes.InitializeWidget;
  constructor(public preferences: ChatPreferences) {}
}

export class InitializeWidgetFailure implements Action {
  readonly type = RightNowActionTypes.InitializeWidgetFailure;
  constructor(public error: any) {}
}

export class InitializeWidgetSuccess implements Action {
  readonly type = RightNowActionTypes.InitializeWidgetSuccess;
}

export class StartChat implements Action {
  readonly type = RightNowActionTypes.StartChat;
  constructor(public provider: string) {}
}

export class StartChatFailure implements Action {
  readonly type = RightNowActionTypes.StartChatFailure;
  constructor(public error: any) {}
}

export class StartChatSuccess implements Action {
  readonly type = RightNowActionTypes.StartChatSuccess;
}

export type RightNowAction =
  | GetChatPreferences
  | GetChatPreferencesFailure
  | GetChatPreferencesSuccess
  | LoadClientScript
  | LoadClientScriptFailure
  | LoadClientScriptSuccess
  | InitializeWidget
  | InitializeWidgetFailure
  | InitializeWidgetSuccess
  | StartChat
  | StartChatFailure
  | StartChatSuccess;

export const fromRightNowActions = {
  GetChatPreferences,
  GetChatPreferencesFailure,
  GetChatPreferencesSuccess,
  LoadClientScript,
  LoadClientScriptFailure,
  LoadClientScriptSuccess,
  InitializeWidget,
  InitializeWidgetFailure,
  InitializeWidgetSuccess,
  StartChat,
  StartChatFailure,
  StartChatSuccess,
};
