<section
  class="collapsible-block"
  [class.collapsible-block--active]="expanded"
  [class.blocked-section]="blocked"
>
  <header
    class="collapsible-block__header"
    (click)="toggle()"
    [class.collapsible-block__header--disabled]="disabled"
    [class.collapsible-block__header--active]="expanded"
    [class.blocked-background]="blocked"
  >
    <div class="collapsible-block__header-icon">
      <cigna-icon-triangle
        size="medium"
        color="white"
        [direction]="expanded ? 'south' : 'east'"
      ></cigna-icon-triangle>
    </div>
    <button
      style="padding: 0"
      cignaButton="link"
      type="button"
      class="collapsible-block__header-text"
    >
      {{ headerText }}
    </button>
  </header>
  <div
    *ngIf="expanded"
    class="collapsible-block__content"
    [class.blocked-background]="blocked"
  >
    <ng-content></ng-content>
  </div>
</section>
