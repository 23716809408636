import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WindowService } from '@cigna/shared/angular/core/window-util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  Config,
  ENVIRONMENT_CONFIG,
} from '@cigna/dcm-customer-portal/shared/util';
import { of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import {
  ConversationMessageDialogContentLinkExtn,
  DownloadResponse,
} from '@cigna/omni/shared-util';

@Component({
  selector: 'cigna-omni-messaging-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss'],
})
export class OmniMessagingFileItemComponent implements OnChanges {
  @Input() public contentLink: ConversationMessageDialogContentLinkExtn;
  @Output() isError = new EventEmitter<boolean>();
  transformedName: string;
  attachmentApiRootUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) private envConfig: Config,
    private window: WindowService,
  ) {
    this.attachmentApiRootUrl = `${this.envConfig.omni.publicUrl}/attachment/v1/download`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.contentLink?.currentValue &&
      changes.contentLink.currentValue !== changes.contentLink.previousValue
    ) {
      const lastIndex = changes.contentLink.currentValue.label.lastIndexOf('.');
      if (lastIndex === -1) {
        this.transformedName = changes.contentLink.currentValue.label;
      } else {
        const fileName = changes.contentLink.currentValue.label.substring(
          0,
          lastIndex,
        );
        const extension = changes.contentLink.currentValue.label.substring(
          lastIndex + 1,
          changes.contentLink.currentValue.label.length,
        );
        this.transformedName = `${fileName} [${extension.toUpperCase()}]`;
      }
    }
  }

  public openFileNewTab() {
    const transformedContentLinkUrl = this.contentLink.url.replace(
      new RegExp('/', 'g'),
      '%2F',
    );
    this._http
      .get<DownloadResponse>(
        `${this.attachmentApiRootUrl}?key=${transformedContentLinkUrl}`,
      )
      .pipe(
        map((response) => {
          this.isError.emit(false);
          this.window.open(response.data, this.contentLink.target);
        }),
        catchError(() => {
          this.isError.emit(true);
          return of(undefined);
        }),
        take(1),
      )
      .subscribe();
  }
}
