import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { initializeTealeafFactory } from './initialize-tealeaf.factory';
import { LoggingService } from '@cigna/shared/angular/logging-util';
import { TEALEAF_CONFIG } from './tealeaf.config';
import { TealeafService } from './tealeaf.service';

@NgModule({
  imports: [CommonModule],
})
export class SharedTealeafUtilModule {
  static forRoot(): ModuleWithProviders<SharedTealeafUtilModule> {
    return {
      ngModule: SharedTealeafUtilModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initializeTealeafFactory,
          deps: [TEALEAF_CONFIG, TealeafService, LoggingService],
          multi: true,
        },
      ],
    };
  }
}
