// eslint-disable-next-line max-classes-per-file
import { Transfer } from '@cigna/omni/data-access';
import { ChatButtonId, DcmCareAdvocateData } from '@cigna/omni/shared-util';
import {
  ConversationDTO,
  ConversationActivityEventDTO,
  ConversationMessageEventDTO,
  ConversationSummaryDTO,
} from '@cigna/vampire-dto';
import { Action } from '@ngrx/store';

export enum ConversationsActionTypes {
  ConversationDiff = '[Omni - Conversations] ConversationDiff',
  ChatBubbleClick = '[Omni - Conversations] ChatBubbleClick',
  ChatWithHumanClick = '[Omni - Conversations] ChatWithHumanClick',
  AgentJoinChat = '[Omni - Conversations] AgentJoinChat',
  CreateConversation = '[Omni - Conversations] CreateConversation',
  CreateLiveConversation = '[Omni - Conversations] CreateLiveConversation',
  CreateConversationFailure = '[Omni - Conversations] CreateConversationFailure',
  CreateConversationSuccess = '[Omni - Conversations] CreateConversationSuccess',
  BackToPrevious = '[Omni - Conversations] BackToPrevious',
  BackToStart = '[Omni - Conversations] BackToStart',
  EndConversation = '[Omni - Conversations] EndConversation',
  TerminateConversation = '[Omni - Conversations] TerminateConversation',
  EndConversationFailure = '[Omni - Conversations] EndConversationFailure',
  EndConversationSuccess = '[Omni - Conversations] EndConversationSuccess',
  GetConversation = '[Omni - Conversations] GetConversation',
  GetConversationFailure = '[Omni - Conversations] GetConversationFailure',
  GetConversationSuccess = '[Omni - Conversations] GetConversationSuccess',
  GetConversationHistory = '[Omni - Conversations] GetConversationHistory',
  LoadHistoryInitiated = '[Omni - Conversations] LoadHistoryInitiated',
  GetTransferredConversation = '[Omni - Conversations] GetTransferredConversation',
  GetTransferredConversationSuccess = '[Omni - Conversations] GetTransferredConversationSuccess',
  GetTransferredConversationFailure = '[Omni - Conversations] GetTransferredConversationFailure',
  LoadConversations = '[Omni - Conversations] LoadConversations',
  LoadConversationsFailure = '[Omni - Conversations] LoadConversationsFailure',
  LoadConversationsSuccess = '[Omni - Conversations] LoadConversationsSuccess',
  GetActiveConversation = '[Omni - Conversations] GetActiveConversation',
  GetActiveConversationFailure = '[Omni - Conversations] GetActiveConversationFailure',
  GetActiveConversationSuccess = '[Omni - Conversations] GetActiveConversationSuccess',
  GetActiveAsyncConversation = '[Omni - Conversations] GetActiveAsyncConversation',
  UpdateActiveAsyncConvCount = '[Omni - Conversations] UpdateActiveAsyncConvCount',
  UpdateClosedAsyncConvCount = '[Omni - Conversations] UpdateClosedAsyncConvCount',
  OpenConversation = '[Omni - Conversations] OpenConversation',
  MessageViewed = '[Omni - Conversations] MessageViewed',
  MessageViewedFailure = '[Omni - Conversations] MessageViewedFailure',
  MessageViewedSuccess = '[Omni - Conversations] MessageViewedSuccess',
  OnKeyUp = '[Omni - Conversations] OnKeyUp',
  PostActivity = '[Omni - Conversations] PostActivity',
  PostActivityFailure = '[Omni - Conversations] PostActivityFailure',
  PostActivitySuccess = '[Omni - Conversations] PostActivitySuccess',
  PostCta = '[Omni - Conversations] PostCta',
  PostCtaFailure = '[Omni - Conversations] PostCtaFailure',
  PostCtaSuccess = '[Omni - Conversations] PostCtaSuccess',
  PostMessage = '[Omni - Conversations] PostMessage',
  PostMessageFailure = '[Omni - Conversations] PostMessageFailure',
  PostMessageSuccess = '[Omni - Conversations] PostMessageSuccess',
  SetActiveChatProvider = '[Omni Conversations] setActiveChatProvider',
  SetCareAdvocateData = '[Omni Conversations] setCareAdvocateData',
  SetActivityDebounceTime = '[Omni Conversations] SetActivityDebounceTime',
  SetSelectedConversationId = '[Omni - Conversations] SetConversationId',
  SetSelectedAgentName = '[Omni - Conversations] SetAgentName',
  TransferConversation = '[Omni - Conversations] TransferConversation',
  TransferConversationFailure = '[Omni - Conversations] TransferConversationFailure',
  TransferConversationSuccess = '[Omni - Conversations] TransferConversationSuccess',
  SetExpanderBubble = '[Omni Bubble Set] SetExpanderBubble',
  SetCloseOrExitFlag = '[Omni close or exist flag Set] SetCloseOrExitFlag',
  SetCloseSplashScreen = '[Omni splash screen flag Set] SetCloseSplashScreen',
  SetExistingConversationId = '[Omni - Conversations] SetExistingConversationId',
  SetHeaderFocus = '[Omni - Conversations] SetHeaderFocus',
  UpdateConversationMetadata = '[Omni - Conversations] UpdateConversationMetadata',
  SetTerminatedUserMessage = '[Omni - Conversations] SetTerminatedUserMessage',
}

export class ConversationDiff implements Action {
  public readonly type = ConversationsActionTypes.ConversationDiff;
  constructor(
    public payload: {
      newEvents: ConversationDTO['events'];
      _id: string;
    },
  ) {}
}

export class ChatBubbleClick implements Action {
  public readonly type = ConversationsActionTypes.ChatBubbleClick;
  constructor(
    public buttonId: ChatButtonId,
    public buttonText: string,
    public isChatClosed: boolean,
  ) {}
}

export class ChatWithHumanClick implements Action {
  public readonly type = ConversationsActionTypes.ChatWithHumanClick;
}

export class AgentJoinChat implements Action {
  public readonly type = ConversationsActionTypes.AgentJoinChat;
}

export class CreateConversation implements Action {
  public readonly type = ConversationsActionTypes.CreateConversation;
  constructor(
    public payload: {
      type: ConversationDTO['type'];
      context: ConversationDTO['context'];
      message?: string;
    },
  ) {}
}

export class CreateLiveConversation implements Action {
  public readonly type = ConversationsActionTypes.CreateLiveConversation;
  constructor(
    public payload: {
      type: ConversationDTO['type'];
      context: ConversationDTO['context'];
    },
  ) {}
}

export class CreateConversationSuccess implements Action {
  public readonly type = ConversationsActionTypes.CreateConversationSuccess;
  constructor(public payload: ConversationDTO) {}
}

export class CreateConversationFailure implements Action {
  public readonly type = ConversationsActionTypes.CreateConversationFailure;
  constructor(public error: any) {}
}

export class BackToPrevious implements Action {
  public readonly type = ConversationsActionTypes.BackToPrevious;
  constructor(
    public payload: { convId: string; context: ConversationDTO['context'] },
  ) {}
}

export class BackToStart implements Action {
  public readonly type = ConversationsActionTypes.BackToStart;
}

export class EndConversation implements Action {
  public readonly type = ConversationsActionTypes.EndConversation;
  constructor(public payload: string) {}
}

export class TerminateConversation implements Action {
  public readonly type = ConversationsActionTypes.TerminateConversation;
  constructor(
    public userAction: 'logout' | 'inactive',
    public logoutUrl: string,
    public ssoId?: string,
  ) {}
}

export class EndConversationFailure implements Action {
  public readonly type = ConversationsActionTypes.EndConversationFailure;
  constructor(public error: any) {}
}

export class EndConversationSuccess implements Action {
  public readonly type = ConversationsActionTypes.EndConversationSuccess;
  constructor(public payload: ConversationDTO) {}
}

export class GetConversation implements Action {
  public readonly type = ConversationsActionTypes.GetConversation;
  constructor(public conversationId: string, public convType?: string) {}
}

export class GetConversationFailure implements Action {
  public readonly type = ConversationsActionTypes.GetConversationFailure;
  constructor(public error: any) {}
}

export class GetConversationSuccess implements Action {
  public readonly type = ConversationsActionTypes.GetConversationSuccess;
  constructor(public payload: ConversationDTO) {}
}

export class GetConversationHistory implements Action {
  public readonly type = ConversationsActionTypes.GetConversationHistory;
}

export class LoadHistoryInitiated implements Action {
  public readonly type = ConversationsActionTypes.LoadHistoryInitiated;
}

export class GetTransferredConversation implements Action {
  public readonly type = ConversationsActionTypes.GetTransferredConversation;
  constructor(public payload: string) {}
}
export class GetTransferredConversationFailure implements Action {
  public readonly type =
    ConversationsActionTypes.GetTransferredConversationFailure;
  constructor(public error: any) {}
}
export class GetTransferredConversationSuccess implements Action {
  public readonly type =
    ConversationsActionTypes.GetTransferredConversationSuccess;
  constructor(public payload: ConversationDTO) {}
}

export class LoadConversations implements Action {
  public readonly type = ConversationsActionTypes.LoadConversations;
}

export class LoadConversationsSuccess implements Action {
  public readonly type = ConversationsActionTypes.LoadConversationsSuccess;
  constructor(public payload: ConversationDTO[] | ConversationSummaryDTO[]) {}
}

export class LoadConversationsFailure implements Action {
  public readonly type = ConversationsActionTypes.LoadConversationsFailure;
  constructor(public error: any) {}
}

export class GetActiveConversation implements Action {
  public readonly type = ConversationsActionTypes.GetActiveConversation;
}

export class GetActiveConversationSuccess implements Action {
  public readonly type = ConversationsActionTypes.GetActiveConversationSuccess;
  constructor(public response: Transfer) {}
}

export class OpenConversation implements Action {
  public readonly type = ConversationsActionTypes.OpenConversation;
  constructor(public canAutoOpenConversation: boolean) {}
}

export class GetActiveConversationFailure implements Action {
  public readonly type = ConversationsActionTypes.GetActiveConversationFailure;
  constructor(public error: any) {}
}

export class GetActiveAsyncConversation implements Action {
  public readonly type = ConversationsActionTypes.GetActiveAsyncConversation;
  constructor(
    public vampireHistory?: ConversationDTO[] | ConversationSummaryDTO[],
  ) {}
}

export class UpdateActiveAsyncConvCount implements Action {
  public readonly type = ConversationsActionTypes.UpdateActiveAsyncConvCount;
  constructor(
    public activeAsyncConvCount: number,
    public getClosedAsyncConvCount?: boolean,
  ) {}
}

export class UpdateClosedAsyncConvCount implements Action {
  public readonly type = ConversationsActionTypes.UpdateClosedAsyncConvCount;
  constructor(public closedAsyncConvCount: number) {}
}

export class MessageViewed implements Action {
  public readonly type = ConversationsActionTypes.MessageViewed;
  constructor(public payload: string) {}
}

export class MessageViewedFailure implements Action {
  public readonly type = ConversationsActionTypes.MessageViewedFailure;
  constructor(public error: any) {}
}

export class MessageViewedSuccess implements Action {
  public readonly type = ConversationsActionTypes.MessageViewedSuccess;
  constructor(
    public payload: {
      conversationId: string;
      eventId: string;
    },
  ) {}
}

export class OnKeyUp implements Action {
  public readonly type = ConversationsActionTypes.OnKeyUp;
}

export class PostActivity implements Action {
  public readonly type = ConversationsActionTypes.PostActivity;
  constructor(
    public activityType: ConversationActivityEventDTO['activityType'],
  ) {}
}

export class PostActivityFailure implements Action {
  public readonly type = ConversationsActionTypes.PostActivityFailure;
  constructor(public error: any) {}
}

export class PostActivitySuccess implements Action {
  public readonly type = ConversationsActionTypes.PostActivitySuccess;
  constructor(public payload: ConversationActivityEventDTO) {}
}

export class PostCta implements Action {
  public readonly type = ConversationsActionTypes.PostCta;
  constructor(
    public payload: { sourceMessageId: string; value: string; _id: string },
  ) {}
}

export class PostCtaFailure implements Action {
  public readonly type = ConversationsActionTypes.PostCtaFailure;
  constructor(public error: any) {}
}

export class PostCtaSuccess implements Action {
  public readonly type = ConversationsActionTypes.PostCtaSuccess;
  constructor(
    public payload: {
      conversationId: string;
      messageEvent: ConversationMessageEventDTO;
    },
  ) {}
}

export class PostMessage implements Action {
  public readonly type = ConversationsActionTypes.PostMessage;
  constructor(public payload: string) {}
}

export class PostMessageFailure implements Action {
  public readonly type = ConversationsActionTypes.PostMessageFailure;
  constructor(public error: any) {}
}

export class PostMessageSuccess implements Action {
  public readonly type = ConversationsActionTypes.PostMessageSuccess;
  constructor(
    public payload: {
      conversationId: string;
      messageEvent: ConversationMessageEventDTO;
    },
  ) {}
}

export class SetActiveChatProvider implements Action {
  public readonly type = ConversationsActionTypes.SetActiveChatProvider;
  constructor(public provider: string) {}
}

export class SetCareAdvocateData implements Action {
  public readonly type = ConversationsActionTypes.SetCareAdvocateData;
  constructor(public advocate: DcmCareAdvocateData) {}
}

export class SetActivityDebounceTime implements Action {
  public readonly type = ConversationsActionTypes.SetActivityDebounceTime;
  constructor(public debounce: number) {}
}

export class SetSelectedConversationId implements Action {
  public readonly type = ConversationsActionTypes.SetSelectedConversationId;
  constructor(public conversationId: string, public convType?: string) {}
}

export class SetSelectedAgentName implements Action {
  public readonly type = ConversationsActionTypes.SetSelectedAgentName;
  constructor(public payload: string) {}
}

export class TransferConversation implements Action {
  public readonly type = ConversationsActionTypes.TransferConversation;
  constructor(
    public payload: {
      conversationId: string;
      initial?: string;
      type: ConversationDTO['type'];
    },
  ) {}
}

export class TransferConversationSuccess implements Action {
  public readonly type = ConversationsActionTypes.TransferConversationSuccess;
  constructor(
    public payload: {
      prevConversation: ConversationDTO;
      nextConversation: ConversationDTO;
    },
  ) {}
}

export class TransferConversationFailure implements Action {
  public readonly type = ConversationsActionTypes.TransferConversationFailure;
  constructor(public error: any) {}
}

export class SetExpanderBubble implements Action {
  public readonly type = ConversationsActionTypes.SetExpanderBubble;
  constructor(public isHide: boolean) {}
}

export class SetCloseOrExitFlag implements Action {
  public readonly type = ConversationsActionTypes.SetCloseOrExitFlag;
  constructor(public isClosed: boolean) {}
}

export class SetCloseSplashScreen implements Action {
  public readonly type = ConversationsActionTypes.SetCloseSplashScreen;
  constructor(public closeSplashScreen: boolean) {}
}

export class SetExistingConversationId implements Action {
  public readonly type = ConversationsActionTypes.SetExistingConversationId;
  constructor(public existingConversationId: string) {}
}

export class SetHeaderFocus implements Action {
  public readonly type = ConversationsActionTypes.SetHeaderFocus;
  constructor(public shouldFocus: boolean) {}
}

export class UpdateConversationMetadata implements Action {
  public readonly type = ConversationsActionTypes.UpdateConversationMetadata;
  constructor(public payload: { meta: any; dataType: string }) {}
}

export class SetTerminatedUserMessage implements Action {
  public readonly type = ConversationsActionTypes.SetTerminatedUserMessage;
  constructor(public termedUser: Record<string, string>) {}
}

export type ConversationsActions =
  | ConversationDiff
  | ChatBubbleClick
  | ChatWithHumanClick
  | AgentJoinChat
  | CreateConversation
  | CreateConversationFailure
  | CreateConversationSuccess
  | BackToPrevious
  | BackToStart
  | EndConversation
  | EndConversationFailure
  | EndConversationSuccess
  | GetConversation
  | OpenConversation
  | GetConversationFailure
  | GetConversationSuccess
  | GetConversationHistory
  | LoadHistoryInitiated
  | GetActiveConversation
  | GetActiveConversationFailure
  | GetActiveConversationSuccess
  | GetActiveAsyncConversation
  | UpdateActiveAsyncConvCount
  | UpdateClosedAsyncConvCount
  | GetTransferredConversation
  | GetTransferredConversationFailure
  | GetTransferredConversationSuccess
  | LoadConversations
  | LoadConversationsFailure
  | LoadConversationsSuccess
  | MessageViewed
  | MessageViewedFailure
  | MessageViewedSuccess
  | PostActivity
  | PostActivityFailure
  | PostActivitySuccess
  | PostCta
  | PostCtaFailure
  | PostCtaSuccess
  | PostMessage
  | PostMessageFailure
  | PostMessageSuccess
  | SetActiveChatProvider
  | SetCareAdvocateData
  | SetActivityDebounceTime
  | SetSelectedConversationId
  | SetSelectedAgentName
  | TransferConversation
  | TransferConversationFailure
  | TransferConversationSuccess
  | SetExpanderBubble
  | SetCloseOrExitFlag
  | SetCloseSplashScreen
  | SetExistingConversationId
  | SetHeaderFocus
  | UpdateConversationMetadata
  | SetTerminatedUserMessage;

export type ConversationsFailureActions =
  | CreateConversationFailure
  | EndConversationFailure
  | GetConversationFailure
  | GetActiveConversationFailure
  | GetTransferredConversationFailure
  | LoadConversationsFailure
  | MessageViewedFailure
  | PostActivityFailure
  | PostCtaFailure
  | PostMessageFailure
  | TransferConversationFailure;

export const conversationsActions = {
  ConversationDiff,
  ChatBubbleClick,
  ChatWithHumanClick,
  AgentJoinChat,
  CreateConversation,
  CreateLiveConversation,
  CreateConversationFailure,
  CreateConversationSuccess,
  BackToPrevious,
  BackToStart,
  EndConversation,
  TerminateConversation,
  EndConversationFailure,
  EndConversationSuccess,
  GetConversation,
  OpenConversation,
  GetConversationFailure,
  GetConversationSuccess,
  GetConversationHistory,
  LoadHistoryInitiated,
  GetActiveConversation,
  GetActiveConversationFailure,
  GetActiveConversationSuccess,
  GetActiveAsyncConversation,
  UpdateActiveAsyncConvCount,
  UpdateClosedAsyncConvCount,
  GetTransferredConversation,
  GetTransferredConversationFailure,
  GetTransferredConversationSuccess,
  LoadConversations,
  LoadConversationsFailure,
  LoadConversationsSuccess,
  MessageViewed,
  MessageViewedFailure,
  MessageViewedSuccess,
  OnKeyUp,
  PostActivity,
  PostActivityFailure,
  PostActivitySuccess,
  PostCta,
  PostCtaFailure,
  PostCtaSuccess,
  PostMessage,
  PostMessageFailure,
  PostMessageSuccess,
  SetActiveChatProvider,
  SetCareAdvocateData,
  SetActivityDebounceTime,
  SetSelectedConversationId,
  SetSelectedAgentName,
  TransferConversation,
  TransferConversationFailure,
  TransferConversationSuccess,
  SetExpanderBubble,
  SetCloseOrExitFlag,
  SetCloseSplashScreen,
  SetExistingConversationId,
  SetHeaderFocus,
  UpdateConversationMetadata,
  SetTerminatedUserMessage,
};
