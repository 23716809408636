import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Type,
} from '@angular/core';
import {
  IconDynamicColors,
  IconDynamicData,
  IconDynamicHeadsetComponent,
  IconDynamicHeartInHandComponent,
  IconDynamicStethoscopeComponent,
  OmniAnalyticsService,
  getConversationType,
  ConversationAgentData,
} from '@cigna/omni/shared-util';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { ConversationDTO } from '@cigna/vampire-dto';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cigna-omni-conversation-summary-card',
  templateUrl: './omni-conversation-summary-card.component.html',
  styleUrls: ['./omni-conversation-summary-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmniConversationSummaryCardComponent {
  @Input()
  public body?: string;

  @Input()
  public chevronColor: IconDynamicColors;

  @Input()
  public conversationId: string;

  @Input()
  public icon: Type<
    | IconDynamicHeadsetComponent
    | IconDynamicHeartInHandComponent
    | IconDynamicStethoscopeComponent
  >;

  @Input()
  public iconColors: IconDynamicColors;

  @Input()
  public iconData?: IconDynamicData;

  @Input()
  public hasNewMessages: boolean;

  @Input()
  public subject: string;

  @Input()
  public isClosed: boolean;

  @Input()
  public date: string;

  @Input()
  public agentData: ConversationAgentData;

  @Input()
  public state: string;

  @Input() public canEnableNewDMUI: boolean;

  @Input() public type: string;

  @Input() public typeLabel: string;

  @Input() public labelIcon: string;

  @Input() public lastMessageBy: string;

  @Input()
  public context: ConversationDTO['context'];

  @Output()
  public interaction: EventEmitter<void> = new EventEmitter();
  isEvernorth: boolean;
  canUpdateAnalyticsData: boolean;
  public isDCMChat$ = this.featureToggleFacade.featuresEnabled(['isDcmChat']);
  public shouldEnablePreChatWelcome$ = this.featureToggleFacade.featuresEnabled(
    ['enableNewPreChatWelcome'],
  );

  public onClick(agentDataId?: string): void {
    this.conversationsFacade.setConversationId(
      agentDataId ?? this.conversationId,
      this.type,
    );
    this.conversationsFacade.setAgentName(this.agentData?.firstName ?? '');
    this.chatFacade.openDialog();

    if (!this.canUpdateAnalyticsData) {
      this.analytics.track('viewChat', {
        newMessages: this.hasNewMessages,
        chatState: this.state,
      });

      if (this.hasNewMessages && getConversationType(this.context) === 'DM') {
        this.analytics.trackEvent('viewNewMessage', {
          chatContext: this.context,
        });
      }

      if (this.isEvernorth) {
        this.analytics.trackEvent('chatConversationHistoryView', {
          linkText: 'Open Conversation History Item',
        });
      }
    }
    // In Active DMs screen
    if (
      this.canEnableNewDMUI &&
      this.type === 'async' &&
      this.state !== 'closed'
    ) {
      this.analytics.dataLayer.updateAdobeDataLayer({
        event: 'pageAction',
        actionData: {
          actionName: 'Track clicks on conversations',
          hitType: 'action',
          widgetName: 'conversation-links',
          customLinkText: 'conversation clicked',
          requirementId: 'acCHT010',
          userStory: 'ODCTC-2490',
        },
      });
    }
  }

  constructor(
    private chatFacade: OmniChatFacade,
    private conversationsFacade: OmniConversationsFacade,
    private analytics: OmniAnalyticsService,
    private featureToggleFacade: FeatureTogglesFacade,
  ) {
    this.featureToggleFacade
      .featuresEnabled(['isEvernorthEnabled'])
      .pipe(take(2))
      .subscribe((isEvm) => {
        this.isEvernorth = isEvm;
      });
    this.featureToggleFacade
      .featuresEnabled(['enableChatAnalyticsDataLayer'])
      .pipe(take(2))
      .subscribe((canUpdateAnalyticsData) => {
        this.canUpdateAnalyticsData = canUpdateAnalyticsData;
      });
  }
}
