import {
  isConversation,
  DcmCareAdvocateData,
  ConversationDTOStatus,
} from '@cigna/omni/shared-util';
import { ConversationDTO, ConversationSummaryDTO } from '@cigna/vampire-dto';
import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import {
  ConversationsActions,
  ConversationsActionTypes,
} from './conversations.actions';
import {
  LiveAvailabilityActionTypes,
  LiveAvailabilityAction,
} from '@cigna/omni/live-availability-state-data-access';
import { ConversationErrorDTO } from './conversation.interface';

export const CONVERSATIONS_FEATURE_KEY = 'omni-conversations';

interface ConversationDTOExtn extends ConversationDTO {
  botType?: string;
}

export interface ConversationsState
  extends EntityState<
    ConversationDTO | ConversationSummaryDTO | ConversationDTOStatus
  > {
  selectedConversationId: string | null;
  selectedAgentName: string | null;
  activityDebounceTime: number;
  activeChatProvider: string;
  careAdvocateData?: DcmCareAdvocateData;
  expanderBubble: boolean;
  closedOrExitFlag: boolean;
  closeSplashScreen: boolean;
  existingConversationId: string;
  shouldFocusOnHeader: boolean;
  conversationMetadata: any;
  canAutoOpenConversation: boolean;
  isUpdatingConversation: boolean;
  isAiBot: boolean;
  liveChatAvailable: boolean;
  isLoadingHistory: boolean;
  terminatedUserMessage: Record<string, string>;
  conversationsError: ConversationErrorDTO;
  activeAsyncConvCount: number;
  closedAsyncConvCount: number;
  tokenRefreshThresholdTime: number;
}

export const adapter = createEntityAdapter<
  ConversationDTO | ConversationSummaryDTO | ConversationDTOStatus
>({
  selectId: (c) => c._id,
});

export const initialState: ConversationsState = adapter.getInitialState({
  selectedConversationId: null,
  selectedAgentName: null,
  activityDebounceTime: 5000,
  activeChatProvider: '',
  careAdvocateData: undefined,
  expanderBubble: false,
  closedOrExitFlag: false,
  closeSplashScreen: false,
  existingConversationId: '',
  shouldFocusOnHeader: false,
  conversationMetadata: undefined,
  canAutoOpenConversation: false,
  isUpdatingConversation: false,
  isAiBot: false,
  liveChatAvailable: false,
  isLoadingHistory: false,
  terminatedUserMessage: {},
  conversationsError: {},
  activeAsyncConvCount: 0,
  closedAsyncConvCount: 0,
  tokenRefreshThresholdTime: 10,
});

export function conversationsReducer(
  state = initialState,
  action: ConversationsActions | LiveAvailabilityAction,
) {
  switch (action.type) {
    case ConversationsActionTypes.CreateConversation:
      return {
        ...state,
        isUpdatingConversation:
          state.liveChatAvailable && !state.selectedConversationId,
      };

    case ConversationsActionTypes.PostCta:
      return {
        ...state,
        isUpdatingConversation: true,
      };

    case ConversationsActionTypes.CreateConversationSuccess:
      return {
        ...adapter.addOne(action.payload, state),
        selectedConversationId: action.payload._id,
        isUpdatingConversation: false,
        isAiBot: (action.payload as ConversationDTOExtn).botType === 'ai-bot',
      };

    case ConversationsActionTypes.CreateConversationFailure: {
      const meta = action.error?.metadata?.outcome;
      return {
        ...state,
        conversationsError: {
          status: meta?.status || 400,
          message: meta?.message,
        },
      };
    }

    case ConversationsActionTypes.EndConversationSuccess:
      return {
        ...adapter.upsertOne(action.payload, state),
      };

    case ConversationsActionTypes.GetConversation:
      return {
        ...state,
        conversationsError: {},
      };

    case ConversationsActionTypes.GetConversationSuccess:
      return {
        ...adapter.upsertOne(action.payload, state),
        isUpdatingConversation: false,
        isAiBot: (action.payload as ConversationDTOExtn).botType === 'ai-bot',
      };

    case ConversationsActionTypes.GetTransferredConversationSuccess:
      return { ...adapter.upsertOne(action.payload, state) };

    case ConversationsActionTypes.UpdateActiveAsyncConvCount:
      return {
        ...state,
        activeAsyncConvCount: action.activeAsyncConvCount,
      };

    case ConversationsActionTypes.UpdateClosedAsyncConvCount:
      return {
        ...state,
        closedAsyncConvCount: action.closedAsyncConvCount,
      };

    case ConversationsActionTypes.LoadConversationsSuccess:
      return {
        ...adapter.setAll(action.payload, state),
        isLoadingHistory: false,
      };

    case ConversationsActionTypes.PostCtaSuccess:
    case ConversationsActionTypes.PostMessageSuccess: {
      const before = state.entities[action.payload.conversationId];
      const beforeEvents = isConversation(before) ? before.events : [];
      const toUpdate: Update<ConversationDTO> = {
        id: action.payload.conversationId,
        changes: {
          events: [...beforeEvents, action.payload.messageEvent],
          title: action.payload.messageEvent.message,
          updated: action.payload.messageEvent.created,
        },
      };
      return { ...adapter.updateOne(toUpdate, state) };
    }

    case ConversationsActionTypes.MessageViewedSuccess: {
      const toUpdate: Update<ConversationDTO> = {
        id: action.payload.conversationId,
        changes: {
          newMessages: false,
          participants: state.entities[
            action.payload.conversationId
          ]?.participants?.map((participant) => {
            if (
              ['customer', 'chcp'].includes(participant.role) &&
              !participant.lastViewedEvent
            ) {
              return {
                ...participant,
                lastViewedEvent: action.payload.eventId,
              };
            }
            return participant;
          }),
        },
      };
      return { ...adapter.updateOne(toUpdate, state) };
    }

    case ConversationsActionTypes.SetActiveChatProvider: {
      return { ...state, activeChatProvider: action.provider };
    }

    case ConversationsActionTypes.SetCareAdvocateData: {
      return { ...state, careAdvocateData: action.advocate };
    }

    case ConversationsActionTypes.SetActivityDebounceTime: {
      return { ...state, activityDebounceTime: action.debounce };
    }

    case ConversationsActionTypes.OpenConversation: {
      return {
        ...state,
        canAutoOpenConversation: action.canAutoOpenConversation,
      };
    }

    case ConversationsActionTypes.TransferConversationSuccess: {
      const tempState = adapter.addOne(action.payload.nextConversation, state);
      return {
        ...adapter.upsertOne(action.payload.prevConversation, tempState),
        selectedConversationId: action.payload.nextConversation._id,
      };
    }

    case ConversationsActionTypes.SetSelectedConversationId:
      return { ...state, selectedConversationId: action.conversationId };
    case ConversationsActionTypes.SetSelectedAgentName:
      return { ...state, selectedAgentName: action.payload };

    case ConversationsActionTypes.SetExpanderBubble: {
      return { ...state, expanderBubble: action.isHide };
    }

    case ConversationsActionTypes.SetCloseOrExitFlag: {
      return { ...state, closedOrExitFlag: action.isClosed };
    }

    case ConversationsActionTypes.SetCloseSplashScreen: {
      return { ...state, closeSplashScreen: action.closeSplashScreen };
    }

    case ConversationsActionTypes.SetExistingConversationId: {
      return {
        ...state,
        existingConversationId: action.existingConversationId,
      };
    }

    case ConversationsActionTypes.SetHeaderFocus: {
      return {
        ...state,
        shouldFocusOnHeader: action.shouldFocus,
      };
    }

    case ConversationsActionTypes.UpdateConversationMetadata: {
      return {
        ...state,
        conversationMetadata: {
          currentType: action.payload.dataType,
          [action.payload.dataType]: action.payload.meta
            ? {
                ...state.conversationMetadata?.[action.payload.dataType],
                ...action.payload.meta,
              }
            : undefined,
        },
      };
    }

    case ConversationsActionTypes.LoadHistoryInitiated: {
      return {
        ...state,
        isLoadingHistory: true,
      };
    }

    case ConversationsActionTypes.SetTerminatedUserMessage: {
      return {
        ...state,
        terminatedUserMessage: action.termedUser,
      };
    }

    case LiveAvailabilityActionTypes.GetLiveAvailabilitySuccess: {
      return {
        ...state,
        liveChatAvailable:
          action.liveAvailability.inOperatingHours &&
          !action.liveAvailability.inHoliday,
      };
    }

    default:
      return state;
  }
}
