import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  Renderer2,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import { OmniChatFacade } from '@cigna/omni/chat-state-data-access';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { content, OmniAnalyticsService } from '@cigna/omni/shared-util';
import { combineLatest, Subject } from 'rxjs';
import {
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { DcmDialogComponent } from '../dcm-dialog/dcm-dialog.component';
import { DialogComponent } from '../dialog/dialog.component';
import { HistoryComponent } from '../history/history.component';
import { HomeComponent } from '../home/home.component';
import { SplashScreenComponent } from '../splash-screen/splash-screen.component';
import { ActiveDMsComponent } from '../active-dms/active-dms.component';
import { FocusService } from '../service/focus-service';
import { OmniShellChatHeaderComponent } from '../chat-header/chat-header.component';
import { ChatTooltipService } from '../service/tooltip.service';
import { TranslateService } from '@ngx-translate/core';
import { Actions } from '@ngrx/effects';

const ANIMATION_TIME = 300;
const QUERY_ABSOLUTE = query(
  ':enter, :leave',
  style({
    display: 'block',
    left: 0,
    position: 'absolute',
    top: '48px', // Height of the header so there's no jumping in the animation
    width: '100%',
  }),
  { optional: true },
);
const STYLE_IN = style({ transform: 'translateX(0)', opacity: 1 });
const STYLE_RIGHT = style({ transform: 'translateX(120%)', opacity: 0 });
const STYLE_LEFT = style({ transform: 'translateX(-120%)', opacity: 0 });

@Component({
  selector: 'cigna-omni-chat-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [
    trigger('shell', [
      state('void', style({ transform: 'translateY(100%)', opacity: 0 })),
      transition(':enter, :leave', [animate(ANIMATION_TIME)]),
    ]),
    trigger('flyInOut', [
      transition('left => right, left2 => right, right <=> right2', [
        QUERY_ABSOLUTE,
        query(':enter', [STYLE_RIGHT], { optional: true }),
        query(':leave', [STYLE_IN, animate(ANIMATION_TIME, STYLE_LEFT)], {
          optional: true,
        }),
        query(':enter', [STYLE_RIGHT, animate(ANIMATION_TIME, STYLE_IN)], {
          optional: true,
        }),
      ]),
      transition('right => left, right2 => left, left <=> left2', [
        QUERY_ABSOLUTE,
        query(':enter', [STYLE_LEFT], { optional: true }),
        query(':leave', [STYLE_IN, animate(ANIMATION_TIME, STYLE_RIGHT)], {
          optional: true,
        }),
        query(':enter', [STYLE_LEFT, animate(ANIMATION_TIME, STYLE_IN)], {
          optional: true,
        }),
      ]),
    ]),
  ],
})
export class ShellComponent implements OnInit, OnDestroy {
  public chatSpanishDisclaimer = content.chatSpanishDisclaimer;
  shouldShowToolTip = false;
  isMobileScreen = false;
  public chatHeader: ElementRef<HTMLElement>;
  @ViewChild(OmniShellChatHeaderComponent)
  set headerComp(headerComp: OmniShellChatHeaderComponent) {
    if (headerComp && (!this.chatHeader || this.chatHeader.nativeElement)) {
      this.chatHeader = headerComp.chatHeader;
    }
  }
  public chatShell: ElementRef<HTMLElement>;
  public chatShellInit: Record<string, number>;
  @ViewChild('chatShell') set _chatShell(_chatShell: ElementRef<HTMLElement>) {
    if (_chatShell && (!this.chatShell || this.chatShell.nativeElement)) {
      this.chatShell = _chatShell;
      this.chatShellInit = {
        top: _chatShell.nativeElement.offsetTop,
        left: _chatShell.nativeElement.offsetLeft,
      };
      this.features
        .featuresEnabled(['moveableChatWindow'])
        .pipe(
          filter((enableChatShellDrag: boolean) => enableChatShellDrag),
          take(1),
        )
        .subscribe(() => {
          this.registerDragElement();
        });
    }
  }
  public enableChatShellDrag$ = this.features.featuresEnabled([
    'moveableChatWindow',
  ]);
  public isChatWindowVisible = true;
  public animationState$ = this.facade.direction$;
  public currentComponent$ = this.facade.currentComponent$.pipe(
    withLatestFrom(this.features.featuresEnabled(['isDcmChat'])),
    map(([component, isDcmChat]) => {
      switch (component) {
        case 'splashView':
          return SplashScreenComponent;

        case 'activeDMsView':
          return ActiveDMsComponent;

        case 'dialog':
          if (isDcmChat) {
            return DcmDialogComponent;
          }
          return DialogComponent;

        case 'history':
          return HistoryComponent;

        case 'home':
        default:
          return HomeComponent;
      }
    }),
  );
  public isShellOpen$ = this.facade.isShellOpen$;
  public isShellMinimized$ = this.facade.isShellMinimized$;
  public isShellOpenOrMinimized$ = combineLatest(
    this.isShellOpen$,
    this.isShellMinimized$,
  ).pipe(
    tap(([isOpen, isMinimized]) => {
      this.shouldHideHeader = isMinimized ? isMinimized : false;
      this.announceChatStateIfChanged(isOpen);
      if (isMinimized) {
        this.focusService.triggerFocus();
      }
    }),
    map(([isOpen, isMinimized]) => isOpen || isMinimized),
  );
  shouldHideHeader: boolean;
  public shouldEnablePreChatWelcome$ = this.features.featuresEnabled([
    'enableNewPreChatWelcome',
  ]);
  public enableNewPreChatWelcome$ = this.features.featuresEnabled([
    'enableNewPreChatWelcome',
  ]);
  canUpdateAnalyticsData$ = this.features.featuresEnabled([
    'enableChatAnalyticsDataLayer',
  ]);
  isDcmChat$ = this.features.featuresEnabled(['isDcmChat']);
  public isReleaseFlagEnabled$ = this.features.featuresEnabled([
    'ftr-mycigna-omni-web-chat-01-29-2025-release',
  ]);

  public isShowHistory$ = this.facade.isHistory$;
  public existingConvId = this.conversationsFacade.existingConversationId$;
  public closeSplashScreen$ = this.conversationsFacade.closeSplashScreen$;
  public shouldFocusOnHeader$ = this.conversationsFacade.shouldFocusOnHeader$;
  private hasPreviousChatStateChanged: boolean | null = null;
  private readonly CHAT_POSITION_KEY = 'chat-window-position';
  public isSplashView$ = this.facade.currentComponent$.pipe(
    map((component) => component === 'splashView'),
  );
  private destroy$ = new Subject<void>();

  constructor(
    private facade: OmniChatFacade,
    private features: FeatureTogglesFacade,
    private conversationsFacade: OmniConversationsFacade,
    private analytics: OmniAnalyticsService,
    private renderer: Renderer2,
    private el: ElementRef,
    private focusService: FocusService,
    private chatTooltipService: ChatTooltipService,
    private _translate: TranslateService,
    private actions$: Actions,
  ) {}

  ngOnInit() {
    this.features
      .featuresEnabled(['chatTooltip'])
      .pipe(
        combineLatestWith(this.chatTooltipService.tooltipState$),
        map(([featureEnabled, tooltipState]) => featureEnabled && tooltipState),
        takeUntil(this.destroy$),
      )
      .subscribe((shouldShow: boolean) => {
        this.shouldShowToolTip = shouldShow;
      });

    this.checkScreenSize();
    window.addEventListener('resize', () => this.checkScreenSize());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    window.removeEventListener('resize', () => this.checkScreenSize());
  }

  public closeTooltip() {
    this.shouldShowToolTip = false;
    this.chatTooltipService.updateTooltipView().subscribe();
  }

  private checkScreenSize() {
    this.isMobileScreen = window.innerWidth <= 768;
  }

  private savePosition(left: number, top: number) {
    sessionStorage.setItem(
      this.CHAT_POSITION_KEY,
      JSON.stringify({ left, top }),
    );
  }

  private loadPosition(): { left: number; top: number } | null {
    const savedPosition = sessionStorage.getItem(this.CHAT_POSITION_KEY);
    return savedPosition ? JSON.parse(savedPosition) : null;
  }

  private resetChatShellPosition(): void {
    this.setChatShellPosition(this.chatShellInit.left, this.chatShellInit.top);
  }

  private setChatShellPosition(left: number, top: number): void {
    this.chatShell.nativeElement.style.left = `${left}px`;
    this.chatShell.nativeElement.style.top = `${top}px`;
  }

  public closeChatWindow(): void {
    this.isChatWindowVisible = false;
    this.focusMainArea();
  }

  public resetChatPosition(): void {
    sessionStorage.removeItem(this.CHAT_POSITION_KEY);
    this.resetChatShellPosition();
  }

  private focusMainArea(): void {
    const h1Element = document.querySelector('h1');
    if (h1Element) {
      const h1HTMLElement = h1Element as HTMLElement;
      if (!h1HTMLElement.hasAttribute('tabindex')) {
        h1HTMLElement.setAttribute('tabindex', '-1');
      }
      h1HTMLElement.focus();
    } else {
      const topElement = document.body;
      topElement.focus();
    }
  }

  announceChatState(isOpen: boolean) {
    const liveRegion = this.renderer.createElement('div');
    this.renderer.setAttribute(liveRegion, 'aria-live', 'polite');
    this.renderer.addClass(liveRegion, 'sr-only');
    const announcement = this.renderer.createText(
      `Chat window has ${isOpen ? 'opened' : 'closed'}.`,
    );
    this.renderer.appendChild(liveRegion, announcement);
    this.renderer.appendChild(this.el.nativeElement, liveRegion);

    setTimeout(() => {
      this.renderer.removeChild(this.el.nativeElement, liveRegion);
    }, 100);
  }

  announceChatStateIfChanged(isOpen: boolean) {
    if (this.hasPreviousChatStateChanged !== isOpen) {
      this.announceChatState(isOpen);
      this.hasPreviousChatStateChanged = isOpen;
    }
  }

  printTranscript(canUpdateAnalyticsData: boolean) {
    const innerContents = document.querySelector('#printSection')?.innerHTML;
    if (innerContents) {
      const printWindow = window.open('', '_blank');
      if (printWindow) {
        printWindow.document.body.innerHTML = innerContents;
        printWindow.document.head.innerHTML = document.head.innerHTML;
        // adding setTimeout to support print in Safari
        setTimeout(() => {
          try {
            printWindow.document.execCommand('print', false);
          } catch (e) {
            window.print();
          }
          printWindow.document.close();
          printWindow.focus();
          printWindow.close();
        }, 200);
      }
    }
    if (canUpdateAnalyticsData) {
      // todo: update to new use data layer format standard
      this.analytics.dataLayer.updateAdobeDataLayer({
        event: 'pageAction',
        actionData: {
          actionName: 'downloadTranscript',
          hitType: 'action',
          widgetName: 'chat-download-transcript',
          customLinkText: 'Chat Transcript',
          requirementId: 'ac24072',
          userStory: 'ODCTC-593',
        },
      });
    }
  }

  registerDragElement() {
    const chatShell = this.chatShell.nativeElement;
    const chatHeader = this.chatHeader.nativeElement;
    const savedPosition = this.loadPosition();
    if (savedPosition) {
      this.setChatShellPosition(savedPosition.left, savedPosition.top);
    }

    let startX = 0;
    let startY = 0;
    let draggableWidth: number;
    let draggableHeight: number;

    const dragMouseDown = (evt: MouseEvent) => {
      const { left, top } = chatShell.getBoundingClientRect();
      startX = evt.clientX - left;
      startY = evt.clientY - top;

      draggableWidth = window.innerWidth - chatShell.offsetWidth;
      draggableHeight = window.innerHeight - chatShell.offsetHeight;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    };

    const elementDrag = (evt: MouseEvent) => {
      // Constrain the chatShell within the browser viewport
      const displacementX = evt.clientX - startX;
      const displacementY = evt.clientY - startY;
      const left = Math.max(0, Math.min(displacementX, draggableWidth));
      const top = Math.max(0, Math.min(displacementY, draggableHeight));
      this.setChatShellPosition(left, top);
    };

    const closeDragElement = () => {
      const { left, top } = chatShell.getBoundingClientRect();
      this.savePosition(left, top);
      document.onmouseup = null;
      document.onmousemove = null;
    };

    chatHeader.onmousedown = dragMouseDown;
  }

  get isSpanishActive() {
    return this._translate.currentLang === 'es-us';
  }
}
