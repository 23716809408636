// Importing Observable is required even though unused in order to publish module.
// @see https://github.com/dherges/ng-packagr/issues/305
import { fromEvent, Observable } from 'rxjs';

import { WindowService } from './window.service';

export class BrowserWindowService extends WindowService {
  public readonly resize$ = fromEvent(window, 'resize');
  public readonly analyticsLoaded$ = fromEvent(
    window,
    'adobe-analytics-loaded',
  );

  public get innerHeight() {
    return window.innerHeight;
  }

  public get innerWidth() {
    return window.innerWidth;
  }

  public get location() {
    return window.location;
  }

  public get localStorage() {
    return window.localStorage;
  }

  public get pageXOffset() {
    return window.pageXOffset;
  }

  public get pageYOffset() {
    return window.pageYOffset;
  }

  public get sessionStorage() {
    return window.sessionStorage;
  }

  public get screen() {
    return window.screen;
  }

  public get userAgent() {
    return window.navigator.userAgent;
  }

  public get ResizeObserver() {
    return (window as any).ResizeObserver;
  }

  public get _satellite() {
    return (window as any)._satellite;
  }

  public get navigator() {
    return (window as any).navigator;
  }

  public get parent() {
    return window.parent;
  }

  setTimeout(fn: () => void, delay: number): number {
    return window.setTimeout(fn, delay);
  }

  clearTimeout(timeoutId: number): void {
    return window.clearTimeout(timeoutId);
  }

  getComputedStyle(
    elt: Element,
    pseudoElt?: string | null,
  ): CSSStyleDeclaration {
    return window.getComputedStyle(elt, pseudoElt);
  }

  scroll(options: ScrollOptions): void;
  scroll(x?: number, y?: number): void;
  scroll(x?: any, y?: any) {
    window.scroll(x, y);
  }

  scrollTo(options: ScrollToOptions): void;
  scrollTo(x?: number, y?: number): void;
  scrollTo(x?: any, y?: any) {
    window.scrollTo(x, y);
  }

  matchMedia(mediaQuery: string) {
    return window.matchMedia(mediaQuery);
  }

  open(url: string, target: string = '_blank', features: string = '') {
    return window.open(url, target, features);
  }

  requestAnimationFrame(callback: FrameRequestCallback): number {
    return window.requestAnimationFrame(callback);
  }

  cancelAnimationFrame(handle: number): void {
    return window.cancelAnimationFrame(handle);
  }

  createObjectURL(blob: any): any {
    return window.URL.createObjectURL(blob);
  }

  print(): void {
    window.print();
  }

  close(): void {
    window.close();
  }

  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void {
    window.addEventListener(type, listener, options);
  }

  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void {
    window.removeEventListener(type, listener, options);
  }
}
