import { Injectable } from '@angular/core';
import { OmniConversationsFacade } from '@cigna/omni/conversations-state-data-access';
import { OmniInterceptionsDataAccessService } from '@cigna/omni/data-access';
import { OmniLiveAvailabilityFacade } from '@cigna/omni/live-availability-state-data-access';
import { liveScheduleCoachingUserMessage } from '@cigna/omni/shared-util';
import { ConversationDTO, InterceptionDTO } from '@cigna/vampire-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  interceptionsActions,
  InterceptionsActionTypes,
  InterceptionsDismiss,
  InterceptionsEngage,
  InterceptionsSnooze,
  InterceptionsViewed,
} from './interceptions.actions';

@Injectable()
export class InterceptionsEffects {
  dismiss$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InterceptionsDismiss>(InterceptionsActionTypes.Dismiss),
      switchMap(({ payload: { id } }) =>
        this.api.dismiss(id, 'user').pipe(
          map(
            () => new interceptionsActions.InterceptionsDismissSuccess({ id }),
          ),
          catchError((error) =>
            of(new interceptionsActions.InterceptionsDismissFailure(error)),
          ),
        ),
      ),
    ),
  );

  engage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InterceptionsEngage>(InterceptionsActionTypes.Engage),
      map(({ payload: { interception } }) => interception),
      withLatestFrom(this.liveAvailabilityFacade.liveChatAvailable$),
      filter(
        (
          params,
        ): params is [
          InterceptionDTO & { conversationContext: ConversationDTO['context'] },
          boolean,
        ] => !!params[0].conversationContext,
      ),
      switchMap(([{ _id, conversationContext }, liveChatAvailable]) =>
        this.api.dismiss(_id, 'action').pipe(
          map(() => {
            this.convFacade.createConversation(
              liveChatAvailable ? 'live' : 'bot',
              conversationContext,
              liveChatAvailable ? liveScheduleCoachingUserMessage : undefined,
            );
            return new interceptionsActions.InterceptionsEngageSuccess({
              id: _id,
            });
          }),
          catchError((error) =>
            of(new interceptionsActions.InterceptionsEngageFailure(error)),
          ),
        ),
      ),
    ),
  );

  snooze$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InterceptionsSnooze>(InterceptionsActionTypes.Snooze),
      switchMap(({ payload: { id } }) =>
        // This is temporary, it's probably not how we'll actually snooze interceptions
        // We wanted the effect in place so that when we need it, it's ready
        this.api.dismiss(id, 'user').pipe(
          map(
            () => new interceptionsActions.InterceptionsSnoozeSuccess({ id }),
          ),
          catchError((error) =>
            of(new interceptionsActions.InterceptionsFailure(error)),
          ),
        ),
      ),
    ),
  );

  viewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InterceptionsViewed>(InterceptionsActionTypes.Viewed),
      switchMap(({ payload: { id } }) =>
        this.api.view(id).pipe(
          map(
            () => new interceptionsActions.InterceptionsViewedSuccess({ id }),
          ),
          catchError((error) =>
            of(new interceptionsActions.InterceptionsFailure(error)),
          ),
        ),
      ),
    ),
  );

  constructor(
    public actions$: Actions,
    public convFacade: OmniConversationsFacade,
    public api: OmniInterceptionsDataAccessService,
    public liveAvailabilityFacade: OmniLiveAvailabilityFacade,
  ) {}
}
