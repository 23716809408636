import { MessagingPollingDetails } from '@cigna/vampire-dto';
import { PollingAction, PollingActionTypes } from './polling.actions';

export const POLLING_FEATURE_KEY = 'omni-polling';

export interface PollingState extends MessagingPollingDetails {
  pauseEvents?: boolean;
}

export const initialState: PollingState = {
  enabled: false,
  limitPolling: true,
  pollingAttempts: 5,
  pauseEvents: false,
  rate: { fast: 5000, slow: 15000 },
};

export function pollingReducer(
  state: PollingState = initialState,
  action: PollingAction,
): PollingState {
  switch (action.type) {
    case PollingActionTypes.PollingConfigLoaded:
      return { ...state, ...action.payload };
    case PollingActionTypes.PollingConfigError:
      return initialState;
    case PollingActionTypes.ResumeEvents:
      return { ...state, pauseEvents: false };
    case PollingActionTypes.PauseEvents:
      return { ...state, pauseEvents: true };
    default:
      return state;
  }
}
