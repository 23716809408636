import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PollingEffects, pollingReducer, POLLING_FEATURE_KEY } from './+state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(POLLING_FEATURE_KEY, pollingReducer),
    EffectsModule.forFeature([PollingEffects]),
  ],
})
export class OmniPollingStateModule {}
