import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileWithUrl, FileUploadMethod } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-file-upload-dnd',
  templateUrl: './file-upload-dnd.component.html',
  styleUrls: ['./file-upload-dnd.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadDndComponent implements OnInit {
  @Input() allowedExtensions: string[] = [];
  @Input() isUploadDisabled: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() attachmentsRequired: boolean;
  @Output() uploadFiles = new EventEmitter<FileWithUrl[]>();

  isHovered: boolean;
  acceptExt: string;

  FileUploadMethod = FileUploadMethod;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.acceptExt = this.allowedExtensions.toString();
  }

  onFilesUpload(fileList: FileList, uploadMethod: FileUploadMethod): void {
    if (!this.isUploadDisabled) {
      const files: FileWithUrl[] = Array.from(fileList || []).map((file) =>
        this.massageFile(file, uploadMethod),
      );

      if (files.length) {
        this.uploadFiles.emit(files);
      }
    }
  }

  onHover(_isHovered: boolean): void {
    this.isHovered = _isHovered;
  }

  massageFile(file: File, uploadMethod: FileUploadMethod): FileWithUrl {
    return {
      file,
      uploadMethod,
      url: this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file),
      ),
    };
  }
}
