import { NgModel } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface InputRef {
  id?: string;
  model?: NgModel;
  touched: Observable<InputRef>;
}

@Injectable()
export class InputHost {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _metaFocus = false;

  ref$ = new BehaviorSubject<InputRef | undefined>(undefined);

  getErrors(allowUntouched: boolean) {
    const input = this.ref$.value;

    if (
      input?.model &&
      input.model.invalid &&
      (input.model.touched || allowUntouched) &&
      !this._metaFocus
    ) {
      return input.model.errors;
    }

    return null;
  }

  register(ref: InputRef) {
    this.ref$.next(ref);
  }

  clear() {
    this.ref$.next(undefined);
  }

  setMetaFocus(value: boolean) {
    this._metaFocus = value;
  }
}
