<section>
  <div class="header-inner-wrapper">
    <div>
      <cigna-header-logo
        [path]="isNavigationDisabled ? '' : logoLink"
        [shouldUseRouterLink]="isLogoLinkInternal"
      ></cigna-header-logo>
    </div>
    <div class="header-util-container cg-hide-for-print">
      <cigna-resources-search-form
        *ngIf="!isNavigationDisabled || isResourceResults"
        [secure]="isAuthenticated"
        [resourcesPage]="isResourceLibraryApp"
      ></cigna-resources-search-form>
      <button
        *ngIf="
          isAuthenticated && !isNavigationDisabled && !isResourceLibraryApp
        "
        (click)="logOut.emit({ variation: 'logout', linkName: 'Logout' })"
        data-test="button-logout"
        cignaButton="link"
        class="logout-link"
      >
        Logout
      </button>

      <cigna-header-user-util
        *ngIf="
          isAuthenticated && !isNavigationDisabled && !isResourceLibraryApp
        "
        [firstName]="firstName"
        [lastName]="lastName"
        [userid]="userid"
        [profileLinks]="profileLinks"
        [profileNavError]="profileNavError"
        [isImpersonator]="isImpersonator"
        [hasMessageCenterFunction]="hasMessageCenterFunction"
      ></cigna-header-user-util>
    </div>
  </div>
</section>
