import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShellTrackHandlers } from '@cigna/chcp/shared/analytics-util';

interface HelpItem {
  header: string;
  body: string;
}

@Component({
  selector: 'cigna-dialog-appeal-attach-help',
  templateUrl: './dialog-appeal-attach-help.component.html',
  styleUrls: ['./dialog-appeal-attach-help.component.scss'],
})
export class DialogAppealAttachHelpComponent {
  medicalHelp: HelpItem[] = [
    {
      header: 'shared.attachHelpDialog.medicalAppeal.anesthesia',
      body: 'shared.attachHelpDialog.medicalAppeal.anesthesiaText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.surgeon',
      body: 'shared.attachHelpDialog.medicalAppeal.surgeonText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.assistantSurgeon',
      body: 'shared.attachHelpDialog.medicalAppeal.assistantSurgeonText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.inpatientLoc',
      body: 'shared.attachHelpDialog.medicalAppeal.inpatientLocText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.outpatientLoc',
      body: 'shared.attachHelpDialog.medicalAppeal.outpatientLocText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.outpatientSurgery',
      body: 'shared.attachHelpDialog.medicalAppeal.outpatientSurgeryText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.medications',
      body: 'shared.attachHelpDialog.medicalAppeal.medicationsText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.therapies',
      body: 'shared.attachHelpDialog.medicalAppeal.therapiesText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.growthHormones',
      body: 'shared.attachHelpDialog.medicalAppeal.growthHormonesText',
    },
    {
      header: 'shared.attachHelpDialog.medicalAppeal.labTesting',
      body: 'shared.attachHelpDialog.medicalAppeal.labTestingText',
    },
  ];

  dentalHelp: HelpItem[] = [
    {
      header: 'shared.attachHelpDialog.dentalAppeal.documentation',
      body: 'shared.attachHelpDialog.dentalAppeal.documentationText',
    },
    {
      header: 'shared.attachHelpDialog.dentalAppeal.anesthesia',
      body: 'shared.attachHelpDialog.dentalAppeal.anesthesiaText',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<DialogAppealAttachHelpComponent>,
    private analytics: ShellTrackHandlers,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lobType: 'medical' | 'dental';
    },
  ) {}

  trackOpen(header: string) {
    this.analytics.trackEventOther({}, header, 'ac-rec030', {
      prop20: 'What should I attach modal',
    });
  }

  get helpItems(): HelpItem[] {
    return this.data.lobType === 'dental' ? this.dentalHelp : this.medicalHelp;
  }
}
