import { Component, Input } from '@angular/core';

@Component({
  selector: 'cigna-dcm-advocate-unavailable',
  templateUrl: './dcm-advocate-unavailable.component.html',
  styleUrls: ['./dcm-advocate-unavailable.component.scss'],
})
export class DcmAdvocateUnavailableComponent {
  @Input() careAdvocateNotAssignedMessage: {
    msg: string;
    title: string;
  };
}
