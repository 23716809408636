import { Injectable } from '@angular/core';
import {
  isConversation,
  OmniAnalyticsService,
  isAgentTimedOutConversation,
  isDMContextTags,
  ConversationDTOStatus,
} from '@cigna/omni/shared-util';
import {
  ConversationDTO,
  ConversationMessageEventDTO,
  ConversationParticipantEventDTO,
  ConversationStateEventDTO,
  ConversationSystemMessageEvent,
} from '@cigna/vampire-dto';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  filter,
  map,
  tap,
  withLatestFrom,
  distinctUntilKeyChanged,
  take,
} from 'rxjs/operators';
import {
  ConversationDiff,
  ConversationsActionTypes,
  ConversationsFailureActions,
  CreateConversationSuccess,
  TransferConversationSuccess,
  MessageViewed,
  PostMessage,
  GetConversationSuccess,
  EndConversation,
  TransferConversation,
  BackToPrevious,
  BackToStart,
  ChatBubbleClick,
  ChatWithHumanClick,
  AgentJoinChat,
} from './conversations.actions';
import { OmniConversationsFacade } from './conversations.facade';
import { Dictionary } from '@ngrx/entity';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';
import {
  ConversationInterface,
  ConversationMetaExtens,
} from '@cigna/omni/data-access';
@Injectable()
export class ConversationsAnalyticsEffects {
  chatStartSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CreateConversationSuccess>(
          ConversationsActionTypes.CreateConversationSuccess,
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(
          ([
            {
              payload: { context, _id, type },
            },
          ]) => {
            this.analytics.track('chatStartSuccess', {
              chatContext: context.tags,
              chatId: _id,
              chatType: type,
            });
          },
        ),
      ),
    { dispatch: false },
  );

  chatBubbleClick$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ChatBubbleClick>(ConversationsActionTypes.ChatBubbleClick),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
          this.featureToggleFacade.featuresEnabled(['isEvernorthEnabled']),
          this.conversationsFacade.conversationMetadata$,
        ),
        tap(
          ([
            { buttonId, buttonText, isChatClosed },
            canUpdateAnalyticsData,
            isEvernorthEnabled,
            metadata,
          ]) => {
            if (canUpdateAnalyticsData) {
              // todo: update to new use data layer format standard
              this.analytics.dataLayer.updateAdobeDataLayer({
                event: 'pageAction',
                actionData: {
                  actionName: 'chatBubbleClick',
                  hitType: 'action',
                  widgetName: 'chat-bubble',
                  customLinkText: isChatClosed ? 'Chat Closed' : 'Talk with Us',
                  requirementId: 'ac24060',
                  userStory: 'ODCTC-586',
                  pseudoTin: metadata?.[metadata.currentType]?.pseudoTin,
                },
              });
            } else if (isEvernorthEnabled && !isChatClosed) {
              this.analytics.trackEvent('chatWithUsClickOnEvm', {
                buttonText,
              });
            } else if (!isEvernorthEnabled) {
              this.analytics.track('chatStartClick', {
                buttonId,
                buttonText,
              });
            }
          },
        ),
      ),
    { dispatch: false },
  );

  chatWithHumanClick$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ChatWithHumanClick>(ConversationsActionTypes.ChatWithHumanClick),
        tap(() => {
          this.analytics.dataLayer.updateAdobeDataLayer({
            event: 'pageAction',
            actionData: {
              actionName: 'Chat with a human',
              hitType: 'action',
              widgetName: 'chat-type-selection',
              customLinkText: 'Chat with a human',
              requirementId: 'acCHT008',
              userStory: 'ODCTC-2488',
            },
          });
        }),
      ),
    { dispatch: false },
  );

  agentJoinChat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AgentJoinChat>(ConversationsActionTypes.AgentJoinChat),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
          this.featureToggleFacade.featuresEnabled(['isEvernorthEnabled']),
          this.conversationsFacade.conversationMetadata$,
        ),
        tap(([, canUpdateAnalyticsData, isEvernorthEnabled, metadata]) => {
          if (canUpdateAnalyticsData) {
            // todo: update to new use data layer format standard
            this.analytics.dataLayer.updateAdobeDataLayer({
              event: 'pageAction',
              actionData: {
                actionName: 'agentJoinChat',
                hitType: 'action',
                widgetName: 'chat-agent-joins',
                customLinkText: 'Agent Joins Chat',
                requirementId: 'ac24067',
                userStory: 'ODCTC-589',
                pseudoTin: metadata?.[metadata.currentType]?.pseudoTin,
              },
            });
          } else if (isEvernorthEnabled) {
            this.analytics.trackEvent('trackAgentJoinOnEvm', {
              linkText: 'Advocate Joins Chat',
            });
          } else {
            this.analytics.trackEvent('trackAgentJoin', {
              linkText: 'Agent Joins Chat',
            });
          }
        }),
      ),
    { dispatch: false },
  );

  trackVerbatimText$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TransferConversation>(
          ConversationsActionTypes.TransferConversation,
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        tap(([, canUpdateAnalyticsData]) => {
          if (!canUpdateAnalyticsData) {
            this.analytics.trackEvent('trackVerbatimText', {
              linkText: 'Initial Verbatim Text Sent',
            });
          } else {
            // todo: update to new use data layer format standard
            this.analytics.dataLayer.updateAdobeDataLayer({
              event: 'pageAction',
              actionData: {
                actionName: 'initialVerbatim',
                hitType: 'action',
                widgetName: 'chat-verbatim-text-sent',
                customLinkText: 'Initial Verbatim Text Sent',
                requirementId: 'ac24066',
                userStory: 'ODCTC-588',
              },
            });
          }
        }),
      ),
    { dispatch: false },
  );

  chatTransferSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<TransferConversationSuccess>(
          ConversationsActionTypes.TransferConversationSuccess,
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(
          ([
            {
              payload: {
                nextConversation: { context, _id, type },
              },
            },
          ]) => {
            this.analytics.track('chatTransfer', {
              chatContext: context.tags,
              chatId: _id,
              chatType: type,
            });
          },
        ),
      ),
    { dispatch: false },
  );

  backToStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BackToStart>(ConversationsActionTypes.BackToStart),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, canUpdateAnalyticsData]) => canUpdateAnalyticsData),
        tap(() => {
          // todo: update to new use data layer format standard
          this.analytics.dataLayer.updateAdobeDataLayer({
            event: 'pageAction',
            actionData: {
              actionName: 'backToStartClosed',
              hitType: 'action',
              widgetName: 'back-to-start-closed',
              customLinkText: 'Back to Start',
              requirementId: 'ac24508',
              userStory: 'ODCTC-1122',
            },
          });
        }),
      ),
    { dispatch: false },
  );

  backToPrevious$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BackToPrevious>(ConversationsActionTypes.BackToPrevious),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, canUpdateAnalyticsData]) => canUpdateAnalyticsData),
        tap(() => {
          // todo: update to new use data layer format standard
          this.analytics.dataLayer.updateAdobeDataLayer({
            event: 'pageAction',
            actionData: {
              actionName: 'backToStartOpen',
              hitType: 'action',
              widgetName: 'back-to-start-open',
              customLinkText: 'Back to Start',
              requirementId: 'acCHT001',
              userStory: 'ODCTC-592',
            },
          });
        }),
      ),
    { dispatch: false },
  );

  agentToAgentTransfer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetConversationSuccess>(
          ConversationsActionTypes.GetConversationSuccess,
        ),
        filter(({ payload }: { payload: ConversationInterface }) => {
          const [lastEventItem] = payload.events.slice(-1);
          return (
            payload.uswState === 'inQueue' &&
            lastEventItem.type === 'participant' &&
            lastEventItem.changeType === 'left'
          );
        }),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        tap(([, canUpdateAnalyticsData]) => {
          if (!canUpdateAnalyticsData) {
            this.analytics.track('agentToAgentTransfer', {});
          } else {
            // todo: update to new use data layer format standard
            this.analytics.dataLayer.updateAdobeDataLayer({
              event: 'pageAction',
              actionData: {
                actionName: 'agentTransferChat',
                hitType: 'action',
                widgetName: 'chat-agent-transfers',
                customLinkText: 'Agent Transfers Chat',
                requirementId: 'ac24068',
                userStory: 'ODCTC-590',
              },
            });
          }
        }),
      ),
    { dispatch: false },
  );

  agentJoinsChatTrigger$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConversationDiff>(ConversationsActionTypes.ConversationDiff),
        filter(
          ({ payload: { newEvents } }) =>
            !!newEvents.filter(
              (e: ConversationParticipantEventDTO) =>
                e.role === 'agent' && e.changeType === 'join',
            ).length,
        ),
        // take(1),
        tap(() => this.conversationsFacade.agentJoinChat()),
      ),
    { dispatch: false },
  );

  chatEnd$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConversationDiff>(ConversationsActionTypes.ConversationDiff),
        filter(
          ({ payload: { newEvents } }) =>
            !!newEvents
              .filter((e): e is ConversationStateEventDTO => e.type === 'state')
              .find((e) => e.state === 'closed'),
        ),
        withLatestFrom(this.conversationsFacade.conversationsEntities$),
        map(([{ payload }, convs]) => convs[payload._id]),
        filter((conv): conv is ConversationDTOStatus =>
          isConversation(conv) && conv.status
            ? conv.type !== 'bot' && conv.status === 'AGENT_CONCLUDED'
            : conv?.type !== 'bot',
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        tap(([conv, canUpdateAnalyticsData]) => {
          const chatInformation = {
            chatSurveyId: conv.context?.meta?.ENGAGEMENT_ID || conv._id,
            agentId: (conv.context?.meta as ConversationMetaExtens)
              ?.AGENT_IDENTIFIER,
          };
          const isAgentTimedOut = isAgentTimedOutConversation(conv.events);
          if (canUpdateAnalyticsData) {
            if (!isAgentTimedOut) {
              // todo: update to new use data layer format standard
              this.analytics.dataLayer.updateAdobeDataLayer({
                event: 'pageAction',
                actionData: {
                  ...chatInformation,
                  actionName: 'agentEndChat',
                  hitType: 'action',
                  widgetName: 'chat-agent-ends',
                  customLinkText: 'Agent Ends Chat',
                  requirementId: 'ac24071',
                  userStory: 'ODCTC-591',
                },
              });
            }
          } else {
            if (isAgentTimedOut) {
              this.analytics.track('chatEndError', {
                chatContext: conv.context.tags,
                chatId: conv._id,
                chatType: conv.type,
                chatEndErrorReason: 'AGENT_TIMEOUT',
              });
            } else {
              this.analytics.track('chatEnd', {
                chatContext: conv.context.tags,
                chatId: conv._id,
                chatSurveyId:
                  conv.context.meta && conv.context.meta.ENGAGEMENT_ID
                    ? conv.context.meta.ENGAGEMENT_ID
                    : conv._id,
                chatType: conv.type,
                agentId: (conv.context.meta as ConversationMetaExtens)
                  ?.AGENT_IDENTIFIER,
              });
            }
          }
        }),
      ),
    { dispatch: false },
  );

  endConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EndConversation>(ConversationsActionTypes.EndConversation),
        withLatestFrom(this.conversationsFacade.conversationsEntities$),
        map(([{ payload }, convs]) => convs[payload]),
        filter((conv: ConversationDTO) => conv && conv?.state !== 'closed'),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        tap(([conv, canUpdateAnalyticsData]: [ConversationDTO, boolean]) => {
          let chatInformation;
          const isWaitingConvo =
            isConversation(conv) &&
            conv.events.some((event) => event.type === 'wait');
          if (isWaitingConvo) {
            chatInformation = {
              chatSurveyId: conv.context?.meta?.ENGAGEMENT_ID || conv._id,
              agentId: (conv.context?.meta as ConversationMetaExtens)
                ?.AGENT_IDENTIFIER,
            };
          }
          if (canUpdateAnalyticsData) {
            const chatData =
              conv?.type === 'bot'
                ? {
                    actionName: 'userEndsCloseX',
                    widgetName: 'close-chat-x-icon',
                    customLinkText: 'CloseX',
                    requirementId: 'acCHT002',
                    userStory: 'ODCTC-1131',
                  }
                : {
                    actionName: 'userEndsChat',
                    widgetName: 'end-agent-chat-button',
                    customLinkText: 'End Chat',
                    requirementId: 'acCHT003',
                    userStory: 'ODCTC-1134',
                  };
            // todo: update to new use data layer format standard
            this.analytics.dataLayer.updateAdobeDataLayer({
              event: 'pageAction',
              actionData: {
                ...chatInformation,
                ...chatData,
                hitType: 'action',
              },
            });
          } else if (isWaitingConvo && chatInformation) {
            this.analytics.track('chatEnd', {
              ...chatInformation,
              chatId: conv._id,
              chatType: conv.type,
              chatContext: conv.context.tags,
            });
          }
        }),
      ),
    { dispatch: false },
  );

  firstMessageSent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PostMessage>(ConversationsActionTypes.PostMessage),
        withLatestFrom(
          this.conversationsFacade.conversationId$,
          this.conversationsFacade.conversationEntities$,
        ),
        filter((c): c is [PostMessage, string, Dictionary<ConversationDTO>] => {
          const conv = c[1] && c[2]?.[c[1]];
          return (
            !!conv && isConversation(conv) && isDMContextTags(conv.context.tags)
          );
        }),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(([[, id, entities]]) => {
          const selectedConv = entities?.[id];
          if (selectedConv) {
            const customerId = selectedConv.participants.find((p) =>
              ['customer', 'chcp'].includes(p.role),
            )?._id;
            const custMessages = selectedConv.events
              .filter(
                (e): e is ConversationMessageEventDTO => e.type === 'message',
              )
              .filter((me) => me.participantId === customerId);

            if (custMessages.length === 0) {
              this.analytics.trackEvent('firstMessageSent', {
                chatId: selectedConv._id,
              });
            }
          }
        }),
      ),
    { dispatch: false },
  );

  messageViewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<MessageViewed>(ConversationsActionTypes.MessageViewed),
        filter(({ payload }) => !!payload && payload !== 'noop'),
        distinctUntilKeyChanged('payload'),
        withLatestFrom(this.conversationsFacade.conversation$),
        filter(
          (c): c is [MessageViewed, ConversationDTO] =>
            !!c[1] && isDMContextTags(c[1].context.tags),
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(([[action, conversation]]) => {
          if (
            !conversation.participants.find((participant) =>
              ['customer', 'chcp'].includes(participant.role),
            )?.lastViewedEvent
          ) {
            this.analytics.trackEvent('firstMessageViewed', {
              chatId: conversation._id,
            });
          }
          if (
            conversation.events.find(
              (e): e is ConversationSystemMessageEvent =>
                e._id === action.payload,
            )?.type === 'systemMessage'
          ) {
            this.analytics.trackEvent('autoReplyDisplayed', {});
          }
        }),
      ),
    { dispatch: false },
  );

  conversationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ConversationsFailureActions>(
          ConversationsActionTypes.CreateConversationFailure,
          ConversationsActionTypes.EndConversationFailure,
          ConversationsActionTypes.GetConversationFailure,
          ConversationsActionTypes.GetTransferredConversationFailure,
          ConversationsActionTypes.TransferConversationFailure,
          ConversationsActionTypes.LoadConversationsFailure,
          ConversationsActionTypes.MessageViewedFailure,
          ConversationsActionTypes.PostCtaFailure,
          ConversationsActionTypes.PostMessageFailure,
        ),
        withLatestFrom(
          this.featureToggleFacade.featuresEnabled(['isEvernorthEnabled']),
          this.featureToggleFacade.featuresEnabled([
            'enableChatAnalyticsDataLayer',
          ]),
        ),
        filter(([, , canUpdateAnalyticsData]) => !canUpdateAnalyticsData),
        tap(([action, isEvernorthEnabled]) => {
          const eventName = isEvernorthEnabled
            ? 'chatErrorDuringConv'
            : 'chatError';
          this.analytics.trackEvent(eventName, {
            serviceName: 'Conversation Service',
            error: action.error,
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private analytics: OmniAnalyticsService,
    private conversationsFacade: OmniConversationsFacade,
    public actions$: Actions,
    private featureToggleFacade: FeatureTogglesFacade,
  ) {}
}
