import { Component, Inject } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RouterFacade } from '@cigna/shared/angular/core/router-state-data-access';
import { FeatureTogglesFacade } from '@cigna/shared/angular/features-feature';

import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfig,
} from '@cigna/chcp/shared/environment-util';
import { RouteData } from '@cigna/chcp/shared/util';

@Component({
  selector: 'cigna-layout-public',
  templateUrl: './layout-public.component.html',
  styleUrls: ['./layout-public.component.scss'],
})
export class LayoutPublicComponent {
  routeData$ = this.routerFacade.data$ as Observable<RouteData>;
  isResourcePage$ = this.routeData$.pipe(
    map((data) => data.routeCode === 'resources'),
  );
  logoLink$ = this.isResourcePage$.pipe(
    map((isResourcePage) =>
      isResourcePage
        ? `${this.envConfig.api.gateways.cdn}assets/chcp/resourceLibrary/resourceLibrary.html`
        : '/login',
    ),
  );

  constructor(
    private routerFacade: RouterFacade,
    @Inject(ENVIRONMENT_CONFIG) private envConfig: EnvironmentConfig,
    private featureToggleFacade: FeatureTogglesFacade,
  ) {}
}
