/* eslint-disable sonarjs/no-nested-template-literals */
import { Pipe, PipeTransform, Inject } from '@angular/core';

interface CrosOriginLinkOptions {
  secure?: boolean;
  data?: string;
}

@Pipe({
  name: 'crossOriginLink',
})
export class CrossOriginLinkPipe implements PipeTransform {
  constructor(@Inject('domainRedirectUrl') private domainRedirect: string) {}

  transform(
    path: string,
    { secure = true, data = undefined }: CrosOriginLinkOptions = {},
  ): string {
    if (secure) {
      return `/pkmscdsso?${
        this.domainRedirect
      }/route/secure/cdssostepup?destinationUrl=/app${path}${
        data ? `?data=${data}` : ''
      }`;
    }
    return `${this.domainRedirect}/app${path}`;
  }
}
