import { InjectionToken } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Satellite } from '@cigna/shared/angular/core/interfaces-util';

export const SATELLITE = new InjectionToken<Satellite>('_satellite');

// eslint-disable-next-line no-var
export declare var _satellite: Satellite;

export function satelliteFactory(): Satellite {
  if (typeof _satellite !== 'undefined') {
    return _satellite;
  }

  // eslint-disable-next-line no-console
  console.warn(
    '_satellite not detected, analytics events will be added to queue and loaded later if library appears',
  );
}
