import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cigna-omni-comm-channel-content-link',
  templateUrl: './content-link.component.html',
  styleUrls: ['./content-link.component.scss'],
})
export class ContentLinkComponent {
  @Input()
  public copy: string;
  @Input()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public disabled: boolean;
  @Input()
  public omniQaTag: string | null;

  @Output()
  public interaction: EventEmitter<void> = new EventEmitter();

  public buttonInteraction(): void {
    this.interaction.emit();
  }
}
