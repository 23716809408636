import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
}

export interface AccumulationGQL {
  __typename?: 'Accumulation';
  amount?: Maybe<Scalars['String']['output']>;
  cignaCareNetwork?: Maybe<Scalars['Boolean']['output']>;
  coveredServices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  covers?: Maybe<Scalars['String']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  met?: Maybe<Scalars['String']['output']>;
  networkId?: Maybe<Scalars['String']['output']>;
  networkName?: Maybe<Scalars['String']['output']>;
  networkTierDisplayOrderCd?: Maybe<Scalars['String']['output']>;
  networkTierDisplayOrderCdDescription?: Maybe<Scalars['String']['output']>;
  networkTierLabelName?: Maybe<Scalars['String']['output']>;
  networkType?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  planPeriodCode?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  remaining?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['String']['output']>;
  tierIndex?: Maybe<Scalars['String']['output']>;
}

export interface AddressGQL {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  lines?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
}

export interface AttachmentMetaDataGQL {
  __typename?: 'AttachmentMetaData';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  domainId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  scanSignature?: Maybe<Scalars['String']['output']>;
  scanStatus?: Maybe<AttachmentStatusTypeGQL>;
  signedUrl?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updateDate?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
}

export enum AttachmentStatusTypeGQL {
  InvalidGQL = 'INVALID',
  NewGQL = 'NEW',
  UnavailableGQL = 'UNAVAILABLE',
  ValidGQL = 'VALID',
}

export interface AuthRequiredDataGQL {
  __typename?: 'AuthRequiredData';
  authorizationProvider?: Maybe<Scalars['String']['output']>;
  networkType?: Maybe<Scalars['String']['output']>;
  reasonProAuth?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['String']['output']>;
}

export interface BenefitReferenceNumberGQL {
  __typename?: 'BenefitReferenceNumber';
  asOfDate?: Maybe<Scalars['String']['output']>;
  benefits?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  brn?: Maybe<Scalars['String']['output']>;
  compositeKey?: Maybe<Scalars['String']['output']>;
  coverage?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  detail?: Maybe<BenefitReferenceNumberDetailsGQL>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  sso?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
}

export interface BenefitReferenceNumberDetailsGQL {
  __typename?: 'BenefitReferenceNumberDetails';
  alliances?: Maybe<PatientAllianceGQL>;
  benefitReportDetails?: Maybe<PatientBenefitReportDetailsGQL>;
  ccfBenefit?: Maybe<PatientCcfBenefitGQL>;
  cobDetails?: Maybe<Array<Maybe<CobDetailGQL>>>;
  coverageDetail?: Maybe<PatientCoverageDetailGQL>;
  dentalOfficeDetails?: Maybe<PatientDentalOfficeDetailsGQL>;
  frequencyAgePlanDetails?: Maybe<FrequencyAgePlanDetailsGQL>;
  healthWellness?: Maybe<HealthWellnessGQL>;
  idcardDetail?: Maybe<PatientIdCardDetailsGQL>;
  infertilityServices?: Maybe<InfertilityServicesGQL>;
  maternityBenefitDetails?: Maybe<MaternityBenefitDetailsGQL>;
  medicalBenefits?: Maybe<Scalars['String']['output']>;
  notificationDetail?: Maybe<NotificationDetailGQL>;
  patientDetail?: Maybe<BrnPatientDetailGQL>;
  pharmacyBenefitDetails?: Maybe<PharmacyBenefitDetailsGQL>;
  planLevelAccumulation?: Maybe<Array<Maybe<PlanLevelAccumGQL>>>;
  primaryCareDetails?: Maybe<PcpDetailsGQL>;
  productDetails?: Maybe<PatientProductDetailGQL>;
  reference?: Maybe<BenefitReferenceNumberGQL>;
  runtimeError?: Maybe<BrnRuntimeErrorGQL>;
  serviceContact?: Maybe<Array<Maybe<PatientServiceContactGQL>>>;
  serviceLevelAccumulation?: Maybe<ServiceLevelAccumulationGQL>;
  subscriberDetail?: Maybe<PatientSubscriberDetailsGQL>;
  visionServices?: Maybe<VisionServicesGQL>;
}

export interface BrnDetailsInputGQL {
  asOfDate?: InputMaybe<Scalars['String']['input']>;
  brn?: InputMaybe<Scalars['String']['input']>;
  compositeKey?: InputMaybe<Scalars['String']['input']>;
  consumerCode?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<Scalars['String']['input']>;
}

export interface BrnPatientDetailGQL {
  __typename?: 'BrnPatientDetail';
  asOfDate?: Maybe<Scalars['String']['output']>;
  details?: Maybe<PatientInformationGQL>;
}

export interface BrnRuntimeErrorGQL {
  __typename?: 'BrnRuntimeError';
  errMsg?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
}

export interface CarveOutInfoGQL {
  __typename?: 'CarveOutInfo';
  address?: Maybe<Scalars['String']['output']>;
  carveOutMessage?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  entTypeQual?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idCodeQual?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  serviceTypeCode?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
}

export interface CignaServiceAdditionalGQL {
  __typename?: 'CignaServiceAdditional';
  code?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['String']['output']>;
}

export interface CignaServiceErrorGQL {
  __typename?: 'CignaServiceError';
  errMsg?: Maybe<Scalars['String']['output']>;
  errObject?: Maybe<CignaServiceErrorOutcomeGQL>;
  status?: Maybe<Scalars['Int']['output']>;
}

export interface CignaServiceErrorOutcomeGQL {
  __typename?: 'CignaServiceErrorOutcome';
  additionalDetails?: Maybe<Array<Maybe<CignaServiceAdditionalGQL>>>;
  code?: Maybe<Scalars['Int']['output']>;
  developerMessage?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  moreInfo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ClaimGQL {
  __typename?: 'Claim';
  amtBilled?: Maybe<Scalars['String']['output']>;
  claimCompositeKey: Scalars['String']['output'];
  claimReferenceNumber: Scalars['String']['output'];
  claimReferenceNumberDisplay?: Maybe<Scalars['String']['output']>;
  claimStatus?: Maybe<Scalars['String']['output']>;
  compositeClaimId?: Maybe<Scalars['String']['output']>;
  dateOfService?: Maybe<Scalars['String']['output']>;
  detail?: Maybe<ClaimDetailGQL>;
  documentType?: Maybe<Scalars['String']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  hasAttachments?: Maybe<Scalars['Boolean']['output']>;
  hasReconsiderations?: Maybe<Scalars['Boolean']['output']>;
  idCardExtension?: Maybe<Scalars['String']['output']>;
  medicare?: Maybe<Scalars['Boolean']['output']>;
  patient?: Maybe<PatientGQL>;
  patientDob?: Maybe<Scalars['String']['output']>;
  patientFirstName?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
  patientLastName?: Maybe<Scalars['String']['output']>;
  providerGenPatientAcct?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  tin: Scalars['String']['output'];
}

export interface ClaimDetailGQL {
  __typename?: 'ClaimDetail';
  claimCompositeKey?: Maybe<Scalars['String']['output']>;
  claimInfoObject?: Maybe<ClaimInfoGQL>;
  correspondenceHistory?: Maybe<Array<Maybe<CorrespondenceGQL>>>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  medicare?: Maybe<Scalars['Boolean']['output']>;
  memberObject?: Maybe<ClaimDetailMemberGQL>;
  reconsiderations?: Maybe<Array<Maybe<ReconsiderationGQL>>>;
  remittanceSummaryData?: Maybe<RemittanceSummaryGQL>;
  supportingInformation?: Maybe<Array<Maybe<SupportingInformationGQL>>>;
  tin?: Maybe<Scalars['String']['output']>;
}

export interface ClaimDetailInputGQL {
  claimCompositeKey: Scalars['String']['input'];
  claimReferenceNumber: Scalars['String']['input'];
  compositeClaimId?: InputMaybe<Scalars['String']['input']>;
  medicare?: InputMaybe<Scalars['Boolean']['input']>;
  tinNumbers: Scalars['String']['input'];
}

export interface ClaimDetailMemberGQL {
  __typename?: 'ClaimDetailMember';
  hasBehavioral?: Maybe<Scalars['Boolean']['output']>;
  memberDateOfBirth?: Maybe<Scalars['String']['output']>;
  memberFirstName?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  memberIdentifier?: Maybe<Scalars['String']['output']>;
  memberLastName?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
  relationShipCode?: Maybe<Scalars['String']['output']>;
}

export interface ClaimDetailsInfoMessagesGQL {
  __typename?: 'ClaimDetailsInfoMessages';
  claimInfoText?: Maybe<Scalars['String']['output']>;
}

export interface ClaimExplanationOfRemarkCodesGQL {
  __typename?: 'ClaimExplanationOfRemarkCodes';
  additionalDesc?: Maybe<Scalars['String']['output']>;
  remarkCode?: Maybe<Scalars['String']['output']>;
  remarkCodeDesc?: Maybe<Scalars['String']['output']>;
}

export interface ClaimInfoGQL {
  __typename?: 'ClaimInfo';
  additionalRemarks?: Maybe<Scalars['String']['output']>;
  ami?: Maybe<Scalars['String']['output']>;
  claimAmountDue?: Maybe<Scalars['String']['output']>;
  claimAmountPaid?: Maybe<Scalars['String']['output']>;
  claimDateOfService?: Maybe<Scalars['String']['output']>;
  claimDetailsInfoMessages?: Maybe<Array<Maybe<ClaimDetailsInfoMessagesGQL>>>;
  claimDrgUsedCode?: Maybe<Scalars['String']['output']>;
  claimEngineClaimID?: Maybe<Scalars['String']['output']>;
  claimProcessedDate?: Maybe<Scalars['String']['output']>;
  claimReceivedDate?: Maybe<Scalars['String']['output']>;
  claimReferenceNumber?: Maybe<Scalars['String']['output']>;
  claimStatusCodes?: Maybe<Array<Maybe<ClaimsStatusCodeGQL>>>;
  claimStatusDesc?: Maybe<Scalars['String']['output']>;
  claimTotChargeAmount?: Maybe<Scalars['String']['output']>;
  claimTotPaidAmount?: Maybe<Scalars['String']['output']>;
  compositeClaimId?: Maybe<Scalars['String']['output']>;
  coverageCompositeKey?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  explanationOfRemarkCodes?: Maybe<
    Array<Maybe<ClaimExplanationOfRemarkCodesGQL>>
  >;
  firstName?: Maybe<Scalars['String']['output']>;
  footNoteMessages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fsaAmount?: Maybe<Scalars['String']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  haaAmount?: Maybe<Scalars['String']['output']>;
  hfAmount?: Maybe<Scalars['String']['output']>;
  hraAmount?: Maybe<Scalars['String']['output']>;
  hsaAmount?: Maybe<Scalars['String']['output']>;
  idCardExtensionCode?: Maybe<Scalars['String']['output']>;
  isBehavioral?: Maybe<Scalars['Boolean']['output']>;
  isDDAR?: Maybe<Scalars['Boolean']['output']>;
  isDOSEstimate?: Maybe<Scalars['Boolean']['output']>;
  isWebster?: Maybe<Scalars['Boolean']['output']>;
  isZelis?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lpAmount?: Maybe<Scalars['String']['output']>;
  networkPPOIndicator?: Maybe<Scalars['String']['output']>;
  networkStatusCode?: Maybe<Scalars['String']['output']>;
  overPaidClaimsInfo?: Maybe<Array<Maybe<OverpaidClaimsInfoGQL>>>;
  patientResponsibility?: Maybe<Scalars['String']['output']>;
  payeeInfo?: Maybe<Array<Maybe<PayeeInfoGQL>>>;
  payeePaymentInfoMessages?: Maybe<Array<Maybe<PayeePaymentInfoMessagesGQL>>>;
  paymentInfoMessages?: Maybe<Array<Maybe<PaymentInfoMessagesGQL>>>;
  paymentProviderAmt?: Maybe<Scalars['String']['output']>;
  providerGeneratedAccountNumber?: Maybe<Scalars['String']['output']>;
  recoupedAmount?: Maybe<Scalars['String']['output']>;
  remarkCodes?: Maybe<Array<Maybe<RemarkCodeGQL>>>;
  renderingProviderAddressState?: Maybe<Scalars['String']['output']>;
  renderingProviderId?: Maybe<Scalars['String']['output']>;
  serviceLineInfoArray?: Maybe<Array<Maybe<ClaimServiceLineInfoGQL>>>;
  serviceLineTotalsObject?: Maybe<ServiceLineTotalGQL>;
  serviceProvider?: Maybe<Scalars['String']['output']>;
  showPaymentInfo?: Maybe<Scalars['Boolean']['output']>;
  sourceSystemCode?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  uploadUrl?: Maybe<Scalars['String']['output']>;
}

export interface ClaimRoutingGQL {
  __typename?: 'ClaimRouting';
  claimSystemCode?: Maybe<Scalars['String']['output']>;
  memKeyType?: Maybe<Scalars['String']['output']>;
  memKeyValue?: Maybe<Scalars['String']['output']>;
}

export interface ClaimSearchInputGQL {
  claimReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ClaimSearchInputFiltersGQL>;
  memberDateOfBirth?: InputMaybe<Scalars['String']['input']>;
  memberFirstName?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  memberLastName?: InputMaybe<Scalars['String']['input']>;
  officeName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  patientSearchType?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
  sortOn?: InputMaybe<Scalars['String']['input']>;
  tinNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface ClaimSearchInputFilterChargesGQL {
  chargeMax?: InputMaybe<Scalars['Int']['input']>;
  chargeMin?: InputMaybe<Scalars['Int']['input']>;
  chargeType: Scalars['String']['input'];
}

export interface ClaimSearchInputFilterDateGQL {
  dateMax?: InputMaybe<Scalars['String']['input']>;
  dateMin?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<Scalars['String']['input']>;
  dateType?: InputMaybe<Scalars['String']['input']>;
}

export interface ClaimSearchInputFiltersGQL {
  charges?: InputMaybe<ClaimSearchInputFilterChargesGQL>;
  date?: InputMaybe<ClaimSearchInputFilterDateGQL>;
  other?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ClaimSearchInputSortGQL {
  active?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
}

export interface ClaimSearchResponseGQL {
  __typename?: 'ClaimSearchResponse';
  claims?: Maybe<Array<Maybe<ClaimGQL>>>;
  total?: Maybe<Scalars['Int']['output']>;
}

export interface ClaimServiceLineInfoGQL {
  __typename?: 'ClaimServiceLineInfo';
  allowedAmount?: Maybe<Scalars['String']['output']>;
  amountCharged?: Maybe<Scalars['String']['output']>;
  amountNotAllowed?: Maybe<Scalars['String']['output']>;
  amountNotCovered?: Maybe<Scalars['String']['output']>;
  cdtCode?: Maybe<Scalars['String']['output']>;
  copay?: Maybe<Scalars['String']['output']>;
  coveredBalance?: Maybe<Scalars['String']['output']>;
  dateOfService?: Maybe<Scalars['String']['output']>;
  deductible?: Maybe<Scalars['String']['output']>;
  fsaPayment?: Maybe<Scalars['String']['output']>;
  haPayment?: Maybe<Scalars['String']['output']>;
  hraPayment?: Maybe<Scalars['String']['output']>;
  hsaPayment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  memberCoInsu?: Maybe<Scalars['String']['output']>;
  memberCoInsurancePer?: Maybe<Scalars['String']['output']>;
  memberResponsibility?: Maybe<Scalars['String']['output']>;
  patientCoinsuranceResponsibility?: Maybe<Scalars['String']['output']>;
  placeOfService?: Maybe<Scalars['String']['output']>;
  planCoInsurancePer?: Maybe<Scalars['String']['output']>;
  procedureCode?: Maybe<Scalars['String']['output']>;
  propRemarkDescription?: Maybe<Scalars['String']['output']>;
  remarkCode?: Maybe<Scalars['String']['output']>;
  serviceLineApcCode?: Maybe<Scalars['String']['output']>;
  svcLineContractedAmount?: Maybe<Scalars['String']['output']>;
  svcLinePaidAmount?: Maybe<Scalars['String']['output']>;
  toothNumber?: Maybe<Scalars['String']['output']>;
}

export enum ClaimStatusGQL {
  AdjustedGQL = 'ADJUSTED',
  ApprovedGQL = 'APPROVED',
  DeniedGQL = 'DENIED',
  DuplicateGQL = 'DUPLICATE',
  InProcessGQL = 'IN_PROCESS',
  PaidGQL = 'PAID',
  PendedGQL = 'PENDED',
  PendingGQL = 'PENDING',
  ProcessedGQL = 'PROCESSED',
  RejectedGQL = 'REJECTED',
}

export interface ClaimsStatusCodeGQL {
  __typename?: 'ClaimsStatusCode';
  reasonCodeDesc?: Maybe<Scalars['String']['output']>;
  reasonCodeValue?: Maybe<Scalars['String']['output']>;
  statusCodeDesc?: Maybe<Scalars['String']['output']>;
  statusCodeValue?: Maybe<Scalars['String']['output']>;
}

export interface ClearAllBrnInputGQL {
  asOfDate?: InputMaybe<Scalars['String']['input']>;
  compositeKey?: InputMaybe<Scalars['String']['input']>;
  consumerCode?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<Scalars['String']['input']>;
}

export interface ClearAllBrnOutputGQL {
  __typename?: 'ClearAllBrnOutput';
  result?: Maybe<Scalars['Boolean']['output']>;
}

export interface CobDetailGQL {
  __typename?: 'CobDetail';
  carrier?: Maybe<Scalars['String']['output']>;
  coverageType?: Maybe<Scalars['String']['output']>;
  effectiveFrom?: Maybe<Scalars['String']['output']>;
  effectiveTill?: Maybe<Scalars['String']['output']>;
  policyNo?: Maybe<Scalars['String']['output']>;
  primacy?: Maybe<Scalars['String']['output']>;
  verifiedOn?: Maybe<Scalars['String']['output']>;
}

export interface ComplianceTypesGQL {
  __typename?: 'ComplianceTypes';
  complianceType?: Maybe<Scalars['String']['output']>;
}

export interface ContactGQL {
  __typename?: 'Contact';
  addresses?: Maybe<Array<Maybe<AddressGQL>>>;
  name?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<PhoneGQL>>>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface CorrespondenceGQL {
  __typename?: 'Correspondence';
  claimId?: Maybe<Scalars['String']['output']>;
  corresType?: Maybe<Scalars['String']['output']>;
  docId?: Maybe<Scalars['String']['output']>;
  documentLink?: Maybe<Scalars['String']['output']>;
  generatedOn?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
}

export interface CoverageDetailSummaryGQL {
  __typename?: 'CoverageDetailSummary';
  benOptCode?: Maybe<Scalars['String']['output']>;
  branchCode?: Maybe<Scalars['String']['output']>;
  cancelDate?: Maybe<Scalars['String']['output']>;
  claimRoutings?: Maybe<Array<Maybe<ClaimRoutingGQL>>>;
  clientId?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  clinicalSystemLocations?: Maybe<Scalars['String']['output']>;
  complianceTypes?: Maybe<ComplianceTypesGQL>;
  coverageStatus?: Maybe<Scalars['String']['output']>;
  coverageType?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  embeddedCode?: Maybe<Scalars['String']['output']>;
  familyCode?: Maybe<Scalars['String']['output']>;
  fromIBORCoverage?: Maybe<Scalars['Boolean']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  isIFP?: Maybe<Scalars['Boolean']['output']>;
  isRetired?: Maybe<Scalars['String']['output']>;
  locationCode?: Maybe<Scalars['String']['output']>;
  networkId?: Maybe<Scalars['String']['output']>;
  networkType?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
}

export interface CreateBrnInputGQL {
  asOfDate?: InputMaybe<Scalars['String']['input']>;
  benefits?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brn?: InputMaybe<Scalars['String']['input']>;
  compositeKey?: InputMaybe<Scalars['String']['input']>;
  consumerCode?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  healthPlanId?: InputMaybe<Scalars['String']['input']>;
  lastModified?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  sso?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateBrnOutputGQL {
  __typename?: 'CreateBrnOutput';
  asOfDate?: Maybe<Scalars['String']['output']>;
  benefits?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  brn?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  sso?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
}

export interface CsnDetailGQL {
  __typename?: 'CsnDetail';
  csnId?: Maybe<Scalars['String']['output']>;
  displayOrderCd?: Maybe<Scalars['String']['output']>;
  displayOrderDesc?: Maybe<Scalars['String']['output']>;
  tierName?: Maybe<Scalars['String']['output']>;
}

export interface CsnNetworkDetailsGQL {
  __typename?: 'CsnNetworkDetails';
  csnNetwork?: Maybe<Array<Maybe<CsnDetailGQL>>>;
}

export interface DashboardClaimMemberInputGQL {
  memberFirstName: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  memberLastName: Scalars['String']['input'];
}

export interface DashboardClaimSummaryInputGQL {
  chargeAmount: Scalars['String']['input'];
  claimCompositeKey: Scalars['String']['input'];
  claimReferenceNumber: Scalars['String']['input'];
  claimStatus: Scalars['String']['input'];
  compositeClaimId?: InputMaybe<Scalars['String']['input']>;
  dateOfService: Scalars['String']['input'];
  medicare: Scalars['Boolean']['input'];
  providerGeneratedAccountNumber: Scalars['String']['input'];
}

export interface DashboardPatientCoverageInputGQL {
  account?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  compositeKey?: InputMaybe<Scalars['String']['input']>;
  coverageFrom?: InputMaybe<Scalars['String']['input']>;
  coverageStatus?: InputMaybe<Scalars['String']['input']>;
  coverageTo?: InputMaybe<Scalars['String']['input']>;
  coverageType?: InputMaybe<Scalars['String']['input']>;
  isEffectivelyTermed?: InputMaybe<Scalars['Boolean']['input']>;
  isInactive?: InputMaybe<Scalars['Boolean']['input']>;
  showLink?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface DeleteBrnInputGQL {
  asOfDate?: InputMaybe<Scalars['String']['input']>;
  brn?: InputMaybe<Scalars['String']['input']>;
  compositeKey?: InputMaybe<Scalars['String']['input']>;
  consumerCode?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<Scalars['String']['input']>;
}

export interface DeleteBrnOutputGQL {
  __typename?: 'DeleteBrnOutput';
  result?: Maybe<Scalars['Boolean']['output']>;
}

export interface DescriptionGQL {
  __typename?: 'Description';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface EcnNetworkDetailsGQL {
  __typename?: 'EcnNetworkDetails';
  csnNetworkDetails?: Maybe<CsnNetworkDetailsGQL>;
  ecnId?: Maybe<Scalars['String']['output']>;
}

export interface FeeScheduleGQL {
  __typename?: 'FeeSchedule';
  indicator?: Maybe<Scalars['String']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
}

export interface FlagClaimInputGQL {
  claimSummary: DashboardClaimSummaryInputGQL;
  dateFlagged: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  member: DashboardClaimMemberInputGQL;
  tinNumbers: Array<InputMaybe<Scalars['String']['input']>>;
}

export interface FlagPatientInputGQL {
  ami?: InputMaybe<Scalars['String']['input']>;
  asOfDate?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<DashboardPatientCoverageInputGQL>;
  dateFlagged?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
}

export enum FlagTypeGQL {
  AGQL = 'A',
  DGQL = 'D',
}

export interface FlaggedClaimGQL {
  __typename?: 'FlaggedClaim';
  claimSummary?: Maybe<FlaggedClaimSummaryGQL>;
  dateFlagged?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  member?: Maybe<FlaggedClaimMemberGQL>;
  tinNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface FlaggedClaimMemberGQL {
  __typename?: 'FlaggedClaimMember';
  memberFirstName?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  memberLastName?: Maybe<Scalars['String']['output']>;
}

export interface FlaggedClaimSummaryGQL {
  __typename?: 'FlaggedClaimSummary';
  chargeAmount?: Maybe<Scalars['String']['output']>;
  claimCompositeKey?: Maybe<Scalars['String']['output']>;
  claimReferenceNumber?: Maybe<Scalars['String']['output']>;
  claimStatus?: Maybe<Scalars['String']['output']>;
  compositeClaimId?: Maybe<Scalars['String']['output']>;
  dateOfService?: Maybe<Scalars['String']['output']>;
  medicare?: Maybe<Scalars['Boolean']['output']>;
  providerGeneratedAccountNumber?: Maybe<Scalars['String']['output']>;
}

export interface FlaggedPatientGQL {
  __typename?: 'FlaggedPatient';
  ami?: Maybe<Scalars['String']['output']>;
  asOfDate?: Maybe<Scalars['String']['output']>;
  coverage?: Maybe<FlaggedPatientCoverageGQL>;
  dateFlagged?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
}

export interface FlaggedPatientCoverageGQL {
  __typename?: 'FlaggedPatientCoverage';
  account?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  compositeKey?: Maybe<Scalars['String']['output']>;
  coverageFrom?: Maybe<Scalars['String']['output']>;
  coverageStatus?: Maybe<Scalars['String']['output']>;
  coverageTo?: Maybe<Scalars['String']['output']>;
  coverageType?: Maybe<Scalars['String']['output']>;
  isEffectivelyTermed?: Maybe<Scalars['Boolean']['output']>;
  isInactive?: Maybe<Scalars['Boolean']['output']>;
  showLink?: Maybe<Scalars['Boolean']['output']>;
}

export interface FrequencyAgePlanDetailsGQL {
  __typename?: 'FrequencyAgePlanDetails';
  benefitPDFName?: Maybe<Scalars['String']['output']>;
  procedureList?: Maybe<Array<Maybe<ProcedureListItemGQL>>>;
  showBenefitPDF?: Maybe<Scalars['Boolean']['output']>;
  specificCoverage?: Maybe<SpecificCoverageGQL>;
}

export interface FrequencyCoverageItemGQL {
  __typename?: 'FrequencyCoverageItem';
  code?: Maybe<Scalars['String']['output']>;
  freqAgeLimitTxt?: Maybe<Scalars['String']['output']>;
  inNVal?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  ooNVal?: Maybe<Scalars['String']['output']>;
}

export interface HealthWellnessGQL {
  __typename?: 'HealthWellness';
  clinicalExpansion?: Maybe<Scalars['String']['output']>;
  healthWellnessIndicator?: Maybe<Scalars['String']['output']>;
}

export interface HmoDetailsGQL {
  __typename?: 'HmoDetails';
  code?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<ContactGQL>;
  lob?: Maybe<Scalars['String']['output']>;
  networkDesc?: Maybe<Scalars['String']['output']>;
  networkId?: Maybe<Scalars['String']['output']>;
  networkIdCode?: Maybe<Scalars['String']['output']>;
  networkLob?: Maybe<Scalars['String']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
}

export interface InfertilityServicesGQL {
  __typename?: 'InfertilityServices';
  isUnlimitedExists?: Maybe<Scalars['Boolean']['output']>;
  services?: Maybe<Array<Maybe<InfertilityServicesDetailsGQL>>>;
}

export interface InfertilityServicesDetailsGQL {
  __typename?: 'InfertilityServicesDetails';
  category?: Maybe<Scalars['String']['output']>;
  inNetworkValue?: Maybe<Scalars['String']['output']>;
  outNetworkValue?: Maybe<Scalars['String']['output']>;
}

export enum LobGQL {
  BehGQL = 'BEH',
  DenGQL = 'DEN',
  MedGQL = 'MED',
  MedbhGQL = 'MEDBH',
  FdohpGQL = 'FDOHP',
}

export interface MaternityBenefitDetailsGQL {
  __typename?: 'MaternityBenefitDetails';
  hasMaternity?: Maybe<Scalars['Boolean']['output']>;
  healthWellnessProgram?: Maybe<Scalars['String']['output']>;
  newBornChargeType?: Maybe<Scalars['String']['output']>;
  newBornCoverageDuration?: Maybe<Scalars['String']['output']>;
  newBornCoverageProvision?: Maybe<Scalars['String']['output']>;
  newBornCoverageType?: Maybe<Scalars['String']['output']>;
}

export interface MedicareBenefitGQL {
  __typename?: 'MedicareBenefit';
  costShare?: Maybe<Array<Maybe<MedicareCostShareGQL>>>;
  description?: Maybe<Array<Maybe<DescriptionGQL>>>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  pbpCategoryId?: Maybe<Scalars['String']['output']>;
}

export interface MedicareBenefitCategoryGQL {
  __typename?: 'MedicareBenefitCategory';
  benefits?: Maybe<Array<Maybe<MedicareBenefitGQL>>>;
  categoryDescription?: Maybe<Array<Maybe<DescriptionGQL>>>;
  categoryName?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  pbpCategoryId?: Maybe<Scalars['String']['output']>;
}

export interface MedicareBenefitDetailGQL {
  __typename?: 'MedicareBenefitDetail';
  categories?: Maybe<Array<Maybe<MedicareBenefitCategoryGQL>>>;
  productBenefitType?: Maybe<Scalars['String']['output']>;
}

export interface MedicareCostShareGQL {
  __typename?: 'MedicareCostShare';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Array<Maybe<DescriptionGQL>>>;
  order?: Maybe<Scalars['String']['output']>;
  showPartBDeductible?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface MedicareDetailsGQL {
  __typename?: 'MedicareDetails';
  cobDetails?: Maybe<Array<Maybe<CobDetailGQL>>>;
  contractNumber?: Maybe<Scalars['String']['output']>;
  egwpIndicator?: Maybe<Scalars['String']['output']>;
  familyCode?: Maybe<Scalars['String']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  healthPlanName?: Maybe<Scalars['String']['output']>;
  operationalMarket?: Maybe<Scalars['String']['output']>;
  pcpRequired?: Maybe<Scalars['String']['output']>;
  planBenefitPackageNumber?: Maybe<Scalars['String']['output']>;
  regulatoryMarket?: Maybe<Scalars['String']['output']>;
  segmentNumber?: Maybe<Scalars['String']['output']>;
  specialNeedPlanType?: Maybe<Scalars['String']['output']>;
}

export interface MedicareMedicalDetailGQL {
  __typename?: 'MedicareMedicalDetail';
  benefitDetails?: Maybe<Array<Maybe<MedicareBenefitDetailGQL>>>;
  benefitOptionCode?: Maybe<Scalars['String']['output']>;
  benefitPlanId?: Maybe<Scalars['String']['output']>;
  contractNumber?: Maybe<Scalars['String']['output']>;
  egwpAccountNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  networkCategoryKeyCode?: Maybe<Scalars['String']['output']>;
  pbpNumber?: Maybe<Scalars['String']['output']>;
  planKey?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
  segmentNumber?: Maybe<Scalars['String']['output']>;
}

export interface MedicareMedicalDetailInputGQL {
  healthplanDigitalBenefitId: Scalars['String']['input'];
}

export interface MutationGQL {
  __typename?: 'Mutation';
  clearAllBrn?: Maybe<ClearAllBrnOutputGQL>;
  createBrn?: Maybe<CreateBrnOutputGQL>;
  deleteBrn?: Maybe<DeleteBrnOutputGQL>;
  echo: Scalars['String']['output'];
  flagClaimsUpdate?: Maybe<Array<Maybe<FlaggedClaimGQL>>>;
  flagPatientsUpdate?: Maybe<Array<Maybe<FlaggedPatientGQL>>>;
  flagPrecertificationUpdate?: Maybe<PrecertResponseGQL>;
  remittanceMarkRead?: Maybe<RemittanceMarkAsReadResponseGQL>;
  saveRecentClaim?: Maybe<RecentClaimGQL>;
  saveRecentPrecertification?: Maybe<PrecertResponseGQL>;
}

export interface MutationClearAllBrnArgsGQL {
  input: ClearAllBrnInputGQL;
}

export interface MutationCreateBrnArgsGQL {
  input: CreateBrnInputGQL;
}

export interface MutationDeleteBrnArgsGQL {
  input: DeleteBrnInputGQL;
}

export interface MutationEchoArgsGQL {
  message: Scalars['String']['input'];
}

export interface MutationFlagClaimsUpdateArgsGQL {
  claims: Array<InputMaybe<FlagClaimInputGQL>>;
  type: FlagTypeGQL;
}

export interface MutationFlagPatientsUpdateArgsGQL {
  patients: Array<InputMaybe<FlagPatientInputGQL>>;
  type: FlagTypeGQL;
}

export interface MutationFlagPrecertificationUpdateArgsGQL {
  precert: PrecertInputGQL;
  type: FlagTypeGQL;
}

export interface MutationRemittanceMarkReadArgsGQL {
  input?: InputMaybe<RemittanceMarkAsReadInputGQL>;
}

export interface MutationSaveRecentClaimArgsGQL {
  claim: RecentClaimInputGQL;
}

export interface MutationSaveRecentPrecertificationArgsGQL {
  precert: PrecertInputGQL;
}

export interface NetworkDetailsGQL {
  __typename?: 'NetworkDetails';
  baseNetworkId?: Maybe<Scalars['String']['output']>;
  dnspEligible?: Maybe<Scalars['String']['output']>;
  ecnNetworkDetails?: Maybe<EcnNetworkDetailsGQL>;
  effectiveFrom?: Maybe<Scalars['String']['output']>;
  effectiveTill?: Maybe<Scalars['String']['output']>;
  hmoDetails?: Maybe<HmoDetailsGQL>;
  iCareNetworkId?: Maybe<Scalars['String']['output']>;
  inOutIndicator?: Maybe<Scalars['String']['output']>;
  isAllegianceMember?: Maybe<Scalars['Boolean']['output']>;
  isHAPMember?: Maybe<Scalars['Boolean']['output']>;
  isNetworkTiered?: Maybe<Scalars['Boolean']['output']>;
  mpoId?: Maybe<Scalars['String']['output']>;
  mpoName?: Maybe<Scalars['String']['output']>;
  networkCategoryCode?: Maybe<Scalars['String']['output']>;
  networkId?: Maybe<Scalars['String']['output']>;
  networkName?: Maybe<Scalars['String']['output']>;
  networkType?: Maybe<Scalars['String']['output']>;
  npoId?: Maybe<Scalars['String']['output']>;
  npoName?: Maybe<Scalars['String']['output']>;
  pcp?: Maybe<PcpStatusGQL>;
  porgId?: Maybe<Scalars['String']['output']>;
  seamlessIndicator?: Maybe<Scalars['String']['output']>;
  superNetworkId?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<TierGQL>;
}

export interface NotificationDataGQL {
  __typename?: 'NotificationData';
  inNetworkValue?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  outNetworkValue?: Maybe<Scalars['String']['output']>;
}

export interface NotificationDetailGQL {
  __typename?: 'NotificationDetail';
  carveOutInfo?: Maybe<CarveOutInfoGQL>;
  displayOutPatientPreCertMngmtLevels?: Maybe<Scalars['Boolean']['output']>;
  indemnity?: Maybe<Scalars['Boolean']['output']>;
  mmmInd?: Maybe<Scalars['String']['output']>;
  notificationData?: Maybe<Array<Maybe<NotificationDataGQL>>>;
  outPatientPreCertMngmtLevelList?: Maybe<Array<Maybe<NotificationDataGQL>>>;
  prodInNetwork?: Maybe<Scalars['Boolean']['output']>;
  routerUrl?: Maybe<Scalars['String']['output']>;
  routerUrlInput?: Maybe<Scalars['String']['output']>;
  surefitEast?: Maybe<Scalars['Boolean']['output']>;
  surefitWest?: Maybe<Scalars['Boolean']['output']>;
}

export interface OverpaidClaimsInfoGQL {
  __typename?: 'OverpaidClaimsInfo';
  claimCompositeKey?: Maybe<Scalars['String']['output']>;
  claimPaidDate?: Maybe<Scalars['String']['output']>;
  claimReferenceNumber?: Maybe<Scalars['String']['output']>;
  compositeClaimId?: Maybe<Scalars['String']['output']>;
  patientAccountNumber?: Maybe<Scalars['String']['output']>;
  recoupmentAmount?: Maybe<Scalars['String']['output']>;
  recoupmentBalance?: Maybe<Scalars['String']['output']>;
  showLink?: Maybe<Scalars['Boolean']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
}

export interface PartnerGQL {
  __typename?: 'Partner';
  coordinatingParty?: Maybe<Scalars['String']['output']>;
  effectiveOn?: Maybe<Scalars['String']['output']>;
  endsOn?: Maybe<Scalars['String']['output']>;
  roleOfCigna?: Maybe<Scalars['String']['output']>;
  supportingParty?: Maybe<Scalars['String']['output']>;
  thirdPartyAdmin?: Maybe<Scalars['String']['output']>;
}

export interface PatientGQL {
  __typename?: 'Patient';
  asOfDate: Scalars['String']['output'];
  brnHistory?: Maybe<Array<Maybe<BenefitReferenceNumberGQL>>>;
  claims?: Maybe<Array<Maybe<ClaimGQL>>>;
  compositeKey: Scalars['String']['output'];
  coverageType: Scalars['String']['output'];
  detail?: Maybe<PatientDetailGQL>;
  dob?: Maybe<Scalars['String']['output']>;
  medicareMedicalDetail?: Maybe<MedicareMedicalDetailGQL>;
  patientId?: Maybe<Scalars['String']['output']>;
  planLevelAccumulation?: Maybe<Array<Maybe<PlanLevelAccumGQL>>>;
  primaryCareDetails?: Maybe<PcpDetailsGQL>;
}

export interface PatientClaimsArgsGQL {
  status?: InputMaybe<Array<InputMaybe<ClaimStatusGQL>>>;
}

export interface PatientDetailArgsGQL {
  input?: InputMaybe<PcpDetailsInputGQL>;
}

export interface PatientMedicareMedicalDetailArgsGQL {
  input?: InputMaybe<MedicareMedicalDetailInputGQL>;
}

export interface PatientPlanLevelAccumulationArgsGQL {
  input?: InputMaybe<PlanLevelAccumInputGQL>;
}

export interface PatientPrimaryCareDetailsArgsGQL {
  input?: InputMaybe<PcpDetailsInputGQL>;
}

export interface PatientAllianceGQL {
  __typename?: 'PatientAlliance';
  hasMVP?: Maybe<Scalars['Boolean']['output']>;
  partners?: Maybe<Array<Maybe<PartnerGQL>>>;
}

export interface PatientBenefitReportDetailsGQL {
  __typename?: 'PatientBenefitReportDetails';
  patientChargeSheet?: Maybe<PatientChargeSheetGQL>;
  showBenefitsInd?: Maybe<Scalars['Boolean']['output']>;
  stateBenefitInfo?: Maybe<StateBenefitInfoGQL>;
}

export interface PatientCcfBenefitGQL {
  __typename?: 'PatientCcfBenefit';
  ccfType?: Maybe<Scalars['String']['output']>;
  ccfbenefitFundList?: Maybe<Array<Maybe<PatientCcfBenefitFundListGQL>>>;
  footNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface PatientCcfBenefitFundListGQL {
  __typename?: 'PatientCcfBenefitFundList';
  carryOverAmount?: Maybe<Scalars['String']['output']>;
  carryOverPercentage?: Maybe<Scalars['String']['output']>;
  coverageList?: Maybe<Array<Maybe<PatientCcfCoverageListGQL>>>;
  currentAmount?: Maybe<Scalars['String']['output']>;
  fundType?: Maybe<Scalars['String']['output']>;
  fundTypeWithValues?: Maybe<Scalars['Boolean']['output']>;
  goalAmount?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  note2?: Maybe<Scalars['String']['output']>;
}

export interface PatientCcfCoverageListGQL {
  __typename?: 'PatientCcfCoverageList';
  eligibilityServiceType?: Maybe<Scalars['String']['output']>;
  eligibilityServiceValue?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface PatientChargeSheetGQL {
  __typename?: 'PatientChargeSheet';
  ctrl?: Maybe<Scalars['String']['output']>;
  docName?: Maybe<Scalars['String']['output']>;
  docTypCode?: Maybe<Scalars['String']['output']>;
  expireDt?: Maybe<Scalars['String']['output']>;
  fileSeqNum?: Maybe<Scalars['String']['output']>;
  fileTypeCode?: Maybe<Scalars['String']['output']>;
}

export interface PatientCompositeKeyInputGQL {
  asOfDate: Scalars['String']['input'];
  compositeKey: Scalars['String']['input'];
  coverageType: Scalars['String']['input'];
}

export interface PatientCoverageGQL {
  __typename?: 'PatientCoverage';
  account?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  compositeKey?: Maybe<Scalars['String']['output']>;
  coverageFrom?: Maybe<Scalars['String']['output']>;
  coverageStatus?: Maybe<Scalars['String']['output']>;
  coverageTo?: Maybe<Scalars['String']['output']>;
  coverageType?: Maybe<Scalars['String']['output']>;
  effectivelyTermed?: Maybe<Scalars['Boolean']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  inactive?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  showLink?: Maybe<Scalars['Boolean']['output']>;
}

export interface PatientCoverageDetailGQL {
  __typename?: 'PatientCoverageDetail';
  accountName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  claimSystemCode?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  coverageEndDate?: Maybe<Scalars['String']['output']>;
  coverageStatus?: Maybe<Scalars['String']['output']>;
  coverageType?: Maybe<Scalars['String']['output']>;
  coverages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  effectiveFrom?: Maybe<Scalars['String']['output']>;
  effectiveTill?: Maybe<Scalars['String']['output']>;
  erisaApplicable?: Maybe<Scalars['Boolean']['output']>;
  hasBayCareGroup?: Maybe<Scalars['Boolean']['output']>;
  hasCSN?: Maybe<Scalars['Boolean']['output']>;
  hasConflictingStatus?: Maybe<Scalars['Boolean']['output']>;
  hasCoveredBenefits?: Maybe<Scalars['Boolean']['output']>;
  hasIFP?: Maybe<Scalars['Boolean']['output']>;
  hasMHSR?: Maybe<Scalars['Boolean']['output']>;
  hasMultipleActive?: Maybe<Scalars['Boolean']['output']>;
  hasPDP?: Maybe<Scalars['Boolean']['output']>;
  hasPRN?: Maybe<Scalars['Boolean']['output']>;
  hasV2V?: Maybe<Scalars['Boolean']['output']>;
  initialCoverageDate?: Maybe<Scalars['String']['output']>;
  isAccountRestricted?: Maybe<Scalars['Boolean']['output']>;
  isMultipleCoverage?: Maybe<Scalars['Boolean']['output']>;
  medicalManagementPlan?: Maybe<Scalars['String']['output']>;
  medicare?: Maybe<MedicareDetailsGQL>;
  networkDescription?: Maybe<Scalars['String']['output']>;
  networkDetails?: Maybe<NetworkDetailsGQL>;
  ohip?: Maybe<Scalars['String']['output']>;
  otherInsurance?: Maybe<Scalars['String']['output']>;
  otherInsuranceVerifiedOn?: Maybe<Scalars['String']['output']>;
  pediatricInd?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<PatientPlanGQL>;
  referralCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  umAuthSystem?: Maybe<Scalars['String']['output']>;
  utilizationDataNotAvlble?: Maybe<Scalars['Boolean']['output']>;
  visionSourceCd?: Maybe<Scalars['String']['output']>;
}

export interface PatientDentalOfficeDetailsGQL {
  __typename?: 'PatientDentalOfficeDetails';
  effectiveFrom?: Maybe<Scalars['String']['output']>;
  effectiveTill?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface PatientDetailGQL {
  __typename?: 'PatientDetail';
  alliances?: Maybe<PatientAllianceGQL>;
  cobDetails?: Maybe<Array<Maybe<CobDetailGQL>>>;
  coverageDetails?: Maybe<PatientCoverageDetailGQL>;
  feeSchedule?: Maybe<FeeScheduleGQL>;
  patientDetails?: Maybe<PatientInformationGQL>;
  productDetails?: Maybe<PatientProductDetailGQL>;
  rewardDetails?: Maybe<Array<Maybe<RewardDetailsGQL>>>;
  serviceContact?: Maybe<Array<Maybe<PatientServiceContactGQL>>>;
  subscriberDetails?: Maybe<PatientSubscriberDetailsGQL>;
}

export interface PatientIdCardDetailsGQL {
  __typename?: 'PatientIdCardDetails';
  accountID?: Maybe<Scalars['String']['output']>;
  backImageFlag?: Maybe<Scalars['Boolean']['output']>;
  backImageGUID?: Maybe<Scalars['String']['output']>;
  backImageUrl?: Maybe<Scalars['String']['output']>;
  benOptionCode?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  claimSystemCode?: Maybe<Scalars['String']['output']>;
  contentSearchBeansList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  coverageFromDate?: Maybe<Scalars['String']['output']>;
  frontImageFlag?: Maybe<Scalars['Boolean']['output']>;
  frontImageGUID?: Maybe<Scalars['String']['output']>;
  frontImageUrl?: Maybe<Scalars['String']['output']>;
  idCardExtensionCode?: Maybe<Scalars['String']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  isIFP?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['String']['output']>;
  medicalPlan?: Maybe<Scalars['String']['output']>;
  memberCoverageDate?: Maybe<Scalars['String']['output']>;
  patientID?: Maybe<Scalars['String']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  pharmacyAvailable?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  relationshipCode?: Maybe<Scalars['String']['output']>;
  selectPharmacyAvailable?: Maybe<Scalars['String']['output']>;
}

export interface PatientIdDobInputGQL {
  dateOfBirth: Scalars['String']['input'];
  id: Scalars['String']['input'];
}

export interface PatientInformationGQL {
  __typename?: 'PatientInformation';
  addresses?: Maybe<Array<Maybe<AddressGQL>>>;
  dob?: Maybe<Scalars['String']['output']>;
  eligibleForCostEstimation?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idCardExtensionCode?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  internalProcessingId?: Maybe<Scalars['String']['output']>;
  isInternationalMember?: Maybe<Scalars['Boolean']['output']>;
  isPolicyHolder?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
}

export interface PatientNameDobInputGQL {
  dateOfBirth: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
}

export interface PatientPlanGQL {
  __typename?: 'PatientPlan';
  benefitOption?: Maybe<Scalars['String']['output']>;
  branch?: Maybe<Scalars['String']['output']>;
  classNumber?: Maybe<Scalars['String']['output']>;
  deductibleSequence?: Maybe<Scalars['String']['output']>;
  effectiveFrom?: Maybe<Scalars['String']['output']>;
  effectiveTill?: Maybe<Scalars['String']['output']>;
  fundingType?: Maybe<Scalars['String']['output']>;
  isIndemnity?: Maybe<Scalars['Boolean']['output']>;
  pathwellIndicator?: Maybe<Scalars['String']['output']>;
  planVariationIdentifier?: Maybe<Scalars['String']['output']>;
  planYears?: Maybe<PatientPlanYearsGQL>;
  progressiveRegressiveInd?: Maybe<Scalars['String']['output']>;
  rxCarveOutVendor?: Maybe<Scalars['String']['output']>;
  situsStateIndicator?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface PatientPlanYearsGQL {
  __typename?: 'PatientPlanYears';
  effectiveFrom?: Maybe<Scalars['String']['output']>;
  effectiveTill?: Maybe<Scalars['String']['output']>;
  renewalType?: Maybe<Scalars['String']['output']>;
}

export interface PatientProductDetailGQL {
  __typename?: 'PatientProductDetail';
  embeddedProducts?: Maybe<Array<Maybe<PatientProductEmbeddedGQL>>>;
  familyCode?: Maybe<Scalars['String']['output']>;
  hasFSA?: Maybe<Scalars['Boolean']['output']>;
  hasHRA?: Maybe<Scalars['Boolean']['output']>;
  hasHSA?: Maybe<Scalars['Boolean']['output']>;
  hraFundBalance?: Maybe<Scalars['String']['output']>;
  isIFP?: Maybe<Scalars['Boolean']['output']>;
  isV2V?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  typeCode?: Maybe<Scalars['String']['output']>;
}

export interface PatientProductEmbeddedGQL {
  __typename?: 'PatientProductEmbedded';
  effectiveOn?: Maybe<Scalars['String']['output']>;
  endsOn?: Maybe<Scalars['String']['output']>;
  productCode?: Maybe<Scalars['String']['output']>;
}

export interface PatientServiceContactGQL {
  __typename?: 'PatientServiceContact';
  addresses?: Maybe<Array<Maybe<AddressGQL>>>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<PhoneGQL>>>;
  type?: Maybe<Scalars['String']['output']>;
  web?: Maybe<Scalars['String']['output']>;
  webName?: Maybe<Scalars['String']['output']>;
}

export interface PatientSubscriberDetailsGQL {
  __typename?: 'PatientSubscriberDetails';
  dob?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  internalProcessingId?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
}

export interface PayeeInfoGQL {
  __typename?: 'PayeeInfo';
  checkAmount?: Maybe<Scalars['String']['output']>;
  checkClearedDate?: Maybe<Scalars['String']['output']>;
  checkIssuedDate?: Maybe<Scalars['String']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  checkStatus?: Maybe<Scalars['String']['output']>;
  chkExists?: Maybe<Scalars['Boolean']['output']>;
  eftExists?: Maybe<Scalars['Boolean']['output']>;
  payeeAddress?: Maybe<Scalars['String']['output']>;
  payeeName?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  remittanceNumber?: Maybe<Scalars['String']['output']>;
  sourceDataKey?: Maybe<Scalars['String']['output']>;
}

export interface PayeePaymentInfoMessagesGQL {
  __typename?: 'PayeePaymentInfoMessages';
  paymentInfoText?: Maybe<Scalars['String']['output']>;
}

export interface PaymentInfoMessagesGQL {
  __typename?: 'PaymentInfoMessages';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface PcpDetailsGQL {
  __typename?: 'PcpDetails';
  hasPCP?: Maybe<Scalars['Boolean']['output']>;
  pcpStatus?: Maybe<Scalars['String']['output']>;
  primaryCarePhysician?: Maybe<PrimaryCareServiceGQL>;
  providerOrganization?: Maybe<ProviderOrganizationGQL>;
}

export interface PcpDetailsInputGQL {
  idType: Scalars['String']['input'];
  networkId?: InputMaybe<Scalars['String']['input']>;
  providerId: Scalars['String']['input'];
}

export interface PcpStatusGQL {
  __typename?: 'PcpStatus';
  assignmentType?: Maybe<Scalars['String']['output']>;
  cancelDate?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  hasPCP?: Maybe<Scalars['Boolean']['output']>;
  pcpStatus?: Maybe<Scalars['String']['output']>;
  providerIdentity?: Maybe<ProviderIdentityGQL>;
}

export interface PharmacyBenefitGQL {
  __typename?: 'PharmacyBenefit';
  accumumatorIndicators?: Maybe<Scalars['String']['output']>;
  benefitLabels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  benefitSvcCode?: Maybe<Scalars['String']['output']>;
  buyUpMsg?: Maybe<Scalars['String']['output']>;
  carveoutVendorName?: Maybe<Scalars['String']['output']>;
  classCode?: Maybe<Scalars['String']['output']>;
  combDedAccum?: Maybe<Scalars['String']['output']>;
  combDedOopInd?: Maybe<Scalars['String']['output']>;
  coverageDetailSummary?: Maybe<CoverageDetailSummaryGQL>;
  drugListURL?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  eshdRetlFillLmt?: Maybe<Scalars['String']['output']>;
  fills30DayPermtdNum?: Maybe<Scalars['String']['output']>;
  footnote?: Maybe<Scalars['String']['output']>;
  fundingArrangementType?: Maybe<Scalars['String']['output']>;
  isErisaApplicable?: Maybe<Scalars['Boolean']['output']>;
  isInNetworkOnly?: Maybe<Scalars['Boolean']['output']>;
  isOutNetworkOnly?: Maybe<Scalars['Boolean']['output']>;
  isRetail90DayCoverage?: Maybe<Scalars['Boolean']['output']>;
  isRxDedInd?: Maybe<Scalars['Boolean']['output']>;
  isRxMaxInd?: Maybe<Scalars['Boolean']['output']>;
  mailOrderSpecialityDrugDays?: Maybe<Scalars['String']['output']>;
  mailOrderSupplyDays?: Maybe<Scalars['String']['output']>;
  mndtry90DayInd?: Maybe<Scalars['String']['output']>;
  noOfRefillsPermitted?: Maybe<Scalars['String']['output']>;
  nonSpltyProgTypeCodes?: Maybe<Scalars['String']['output']>;
  planAmountList?: Maybe<Array<Maybe<PlanAmountGQL>>>;
  planCombMedRxInd?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
  prescriptionDrugListCode?: Maybe<Scalars['String']['output']>;
  progTypeUMCodes?: Maybe<Scalars['String']['output']>;
  retail90DayProgramType?: Maybe<Scalars['String']['output']>;
  retailSpecialityDrugDays?: Maybe<Scalars['String']['output']>;
  retailSupplyDays?: Maybe<Scalars['String']['output']>;
  retl90DaySuplyNum?: Maybe<Scalars['String']['output']>;
  rxAnnAccumCode?: Maybe<Scalars['String']['output']>;
  rxApplMOInd?: Maybe<Scalars['String']['output']>;
  rxBuyUp?: Maybe<Scalars['String']['output']>;
  rxCarveOutVendorName?: Maybe<Scalars['String']['output']>;
  rxClinicalProgCodePA?: Maybe<Scalars['String']['output']>;
  rxClinicalProgCodeTE?: Maybe<Scalars['String']['output']>;
  rxClmCoinsPct?: Maybe<Scalars['String']['output']>;
  rxDedAccumCode?: Maybe<Scalars['String']['output']>;
  rxExclDedInd?: Maybe<Scalars['String']['output']>;
  rxMedAccessOptCode?: Maybe<Scalars['String']['output']>;
  rxOopAccumCode?: Maybe<Scalars['String']['output']>;
  rxPlanDesignType?: Maybe<Scalars['String']['output']>;
  rxPlanType?: Maybe<Scalars['String']['output']>;
  situsStateCode?: Maybe<Scalars['String']['output']>;
  spltyProgTypeCodes?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  subClassCode?: Maybe<Scalars['String']['output']>;
  tierBenefitsList?: Maybe<Array<Maybe<TierBenefitGQL>>>;
}

export interface PharmacyBenefitDetailsGQL {
  __typename?: 'PharmacyBenefitDetails';
  enableAccredoLink?: Maybe<Scalars['String']['output']>;
  pharmacyBenefits?: Maybe<Array<Maybe<PharmacyBenefitGQL>>>;
  sourceSystemCode?: Maybe<Scalars['String']['output']>;
}

export interface PhoneGQL {
  __typename?: 'Phone';
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface PlanAmountGQL {
  __typename?: 'PlanAmount';
  featureType?: Maybe<Scalars['String']['output']>;
  featureTypeDesc?: Maybe<Scalars['String']['output']>;
  inNetworkValue?: Maybe<Scalars['String']['output']>;
  outNetworkValue?: Maybe<Scalars['String']['output']>;
}

export interface PlanLevelAccumGQL {
  __typename?: 'PlanLevelAccum';
  benefits?: Maybe<PlanLevelAccumBenefitsGQL>;
  notes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface PlanLevelAccumBenefitsGQL {
  __typename?: 'PlanLevelAccumBenefits';
  coinsurance?: Maybe<PlanLevelAccumDetailGQL>;
  deductible?: Maybe<PlanLevelAccumDetailGQL>;
  lifeTimeMaximum?: Maybe<PlanLevelAccumDetailGQL>;
  moop?: Maybe<PlanLevelAccumDetailGQL>;
  outOfPocketMaximum?: Maybe<PlanLevelAccumDetailGQL>;
}

export interface PlanLevelAccumDetailGQL {
  __typename?: 'PlanLevelAccumDetail';
  accumulations?: Maybe<Array<Maybe<AccumulationGQL>>>;
}

export interface PlanLevelAccumInputGQL {
  healthplanDigitalBenefitId: Scalars['String']['input'];
}

export interface PrecertInputGQL {
  createdDt?: InputMaybe<Scalars['String']['input']>;
  expirationTime?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<PrecertPayloadInputGQL>;
  precertNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface PrecertMemberDetailsGQL {
  __typename?: 'PrecertMemberDetails';
  asOfDate?: Maybe<Scalars['String']['output']>;
  claimSystemCode?: Maybe<Scalars['String']['output']>;
  coverage?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  patientFirstName?: Maybe<Scalars['String']['output']>;
  patientLastName?: Maybe<Scalars['String']['output']>;
  patientPlan?: Maybe<Scalars['String']['output']>;
  patientSSN?: Maybe<Scalars['String']['output']>;
  sourceSystemCode?: Maybe<Scalars['String']['output']>;
}

export interface PrecertMemberDetailsInputGQL {
  asOfDate?: InputMaybe<Scalars['String']['input']>;
  claimSystemCode?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  patientFirstName?: InputMaybe<Scalars['String']['input']>;
  patientLastName?: InputMaybe<Scalars['String']['input']>;
  patientPlan?: InputMaybe<Scalars['String']['input']>;
  patientSSN?: InputMaybe<Scalars['String']['input']>;
  sourceSystemCode?: InputMaybe<Scalars['String']['input']>;
}

export interface PrecertPayloadGQL {
  __typename?: 'PrecertPayload';
  compositeKey?: Maybe<Scalars['String']['output']>;
  member?: Maybe<PrecertMemberDetailsGQL>;
  precertificaton?: Maybe<PrecertificationDetailsGQL>;
  providerDetails?: Maybe<PrecertProviderDetailsObjectGQL>;
}

export interface PrecertPayloadInputGQL {
  compositeKey?: InputMaybe<Scalars['String']['input']>;
  member?: InputMaybe<PrecertMemberDetailsInputGQL>;
  precertificaton?: InputMaybe<PrecertificationDetailsInputGQL>;
  providerDetails?: InputMaybe<PrecertProviderDetailsObjectInputGQL>;
}

export interface PrecertProviderDetailGQL {
  __typename?: 'PrecertProviderDetail';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tinNumber?: Maybe<Scalars['String']['output']>;
}

export interface PrecertProviderDetailInputGQL {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tinNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface PrecertProviderDetailsObjectGQL {
  __typename?: 'PrecertProviderDetailsObject';
  requestingProvider?: Maybe<PrecertProviderDetailGQL>;
  servicingProvider?: Maybe<PrecertProviderDetailGQL>;
}

export interface PrecertProviderDetailsObjectInputGQL {
  requestingProvider?: InputMaybe<PrecertProviderDetailInputGQL>;
  servicingProvider?: InputMaybe<PrecertProviderDetailInputGQL>;
}

export interface PrecertResponseGQL {
  __typename?: 'PrecertResponse';
  createdDt?: Maybe<Scalars['String']['output']>;
  expirationTime?: Maybe<Scalars['Int']['output']>;
  payload?: Maybe<PrecertPayloadGQL>;
  precertNumber?: Maybe<Scalars['String']['output']>;
}

export interface PrecertificationDetailsGQL {
  __typename?: 'PrecertificationDetails';
  endDate?: Maybe<Scalars['String']['output']>;
  precertNumber?: Maybe<Scalars['String']['output']>;
  precertSource?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
}

export interface PrecertificationDetailsInputGQL {
  endDate?: InputMaybe<Scalars['String']['input']>;
  precertNumber?: InputMaybe<Scalars['String']['input']>;
  precertSource?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export interface PrimaryCareServiceGQL {
  __typename?: 'PrimaryCareService';
  addresses?: Maybe<Array<Maybe<AddressGQL>>>;
  assignmentType?: Maybe<Scalars['String']['output']>;
  effectiveFrom?: Maybe<Scalars['String']['output']>;
  effectiveTill?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<PhoneGQL>>>;
}

export enum PriorAuthRequiredGQL {
  NGQL = 'N',
  UGQL = 'U',
  YGQL = 'Y',
}

export interface ProcedureListItemGQL {
  __typename?: 'ProcedureListItem';
  code?: Maybe<Scalars['String']['output']>;
  freqAgeLimitTxt?: Maybe<Scalars['String']['output']>;
  inNVal?: Maybe<Scalars['String']['output']>;
  indmnVal?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  ooNVal?: Maybe<Scalars['String']['output']>;
}

export interface ProviderGQL {
  __typename?: 'Provider';
  cancellationDate?: Maybe<Scalars['String']['output']>;
  contracted?: Maybe<Scalars['Boolean']['output']>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tinId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProviderIdentityGQL {
  __typename?: 'ProviderIdentity';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProviderOrganizationGQL {
  __typename?: 'ProviderOrganization';
  addresses?: Maybe<Array<Maybe<AddressGQL>>>;
  assignmentType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<PhoneGQL>>>;
  serviceTypeCode?: Maybe<Scalars['String']['output']>;
}

export interface QueryGQL {
  __typename?: 'Query';
  brnDetails?: Maybe<BenefitReferenceNumberDetailsGQL>;
  claimByCompositeKey?: Maybe<ClaimGQL>;
  claimByReferenceNumber?: Maybe<ClaimGQL>;
  claimSearch?: Maybe<ClaimSearchResponseGQL>;
  flaggedClaims?: Maybe<Array<Maybe<FlaggedClaimGQL>>>;
  flaggedPatients?: Maybe<Array<Maybe<FlaggedPatientGQL>>>;
  flaggedPrecertifications?: Maybe<Array<Maybe<PrecertResponseGQL>>>;
  patientByCompositeKey?: Maybe<PatientGQL>;
  patientByIdDob?: Maybe<PatientGQL>;
  patientByNameDob?: Maybe<PatientGQL>;
  ping?: Maybe<Scalars['String']['output']>;
  recentClaims?: Maybe<Array<Maybe<RecentClaimGQL>>>;
  recentPatients?: Maybe<Array<Maybe<RecentPatientGQL>>>;
  recentPrecertifications?: Maybe<Array<Maybe<PrecertResponseGQL>>>;
  user?: Maybe<UserProfileGQL>;
  wamDashboardUsers?: Maybe<WamQueryGQL>;
}

export interface QueryBrnDetailsArgsGQL {
  input: BrnDetailsInputGQL;
}

export interface QueryClaimByCompositeKeyArgsGQL {
  input?: InputMaybe<ClaimDetailInputGQL>;
}

export interface QueryClaimByReferenceNumberArgsGQL {
  input: Scalars['String']['input'];
}

export interface QueryClaimSearchArgsGQL {
  input: ClaimSearchInputGQL;
}

export interface QueryPatientByCompositeKeyArgsGQL {
  input: PatientCompositeKeyInputGQL;
}

export interface QueryPatientByIdDobArgsGQL {
  input: PatientIdDobInputGQL;
}

export interface QueryPatientByNameDobArgsGQL {
  input: PatientNameDobInputGQL;
}

export interface QueryWamDashboardUsersArgsGQL {
  input?: InputMaybe<WamUserSearchInputGQL>;
}

export interface RecentClaimGQL {
  __typename?: 'RecentClaim';
  claimSummary?: Maybe<RecentClaimSummaryGQL>;
  member?: Maybe<RecentClaimMemberGQL>;
  tinNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface RecentClaimInputGQL {
  claimSummary: RecentClaimSummaryInputGQL;
  member: RecentClaimMemberInputGQL;
  tinNumbers: Array<InputMaybe<Scalars['String']['input']>>;
}

export interface RecentClaimMemberGQL {
  __typename?: 'RecentClaimMember';
  memberFirstName?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  memberIdentifier?: Maybe<Scalars['String']['output']>;
  memberLastName?: Maybe<Scalars['String']['output']>;
  memberSearchKey?: Maybe<Scalars['String']['output']>;
  relationShipCode?: Maybe<Scalars['String']['output']>;
}

export interface RecentClaimMemberInputGQL {
  claimSystemCode?: InputMaybe<Scalars['String']['input']>;
  memberFirstName: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  memberIdentifier: Scalars['String']['input'];
  memberLastName: Scalars['String']['input'];
  memberSearchKey: Scalars['String']['input'];
  relationShipCode: Scalars['String']['input'];
}

export interface RecentClaimSummaryGQL {
  __typename?: 'RecentClaimSummary';
  chargeAmount?: Maybe<Scalars['String']['output']>;
  claimCompositeKey?: Maybe<Scalars['String']['output']>;
  claimReferenceNumber?: Maybe<Scalars['String']['output']>;
  claimStatus?: Maybe<Scalars['String']['output']>;
  claimType?: Maybe<Scalars['String']['output']>;
  compositeClaimId?: Maybe<Scalars['String']['output']>;
  dateOfService?: Maybe<Scalars['String']['output']>;
  dateProcessed?: Maybe<Scalars['String']['output']>;
  dateReceived?: Maybe<Scalars['String']['output']>;
  medicare?: Maybe<Scalars['Boolean']['output']>;
  providerGeneratedAccountNumber?: Maybe<Scalars['String']['output']>;
}

export interface RecentClaimSummaryInputGQL {
  chargeAmount: Scalars['String']['input'];
  claimCompositeKey: Scalars['String']['input'];
  claimReferenceNumber: Scalars['String']['input'];
  claimStatus: Scalars['String']['input'];
  claimType?: InputMaybe<Scalars['String']['input']>;
  compositeClaimId?: InputMaybe<Scalars['String']['input']>;
  dateOfService: Scalars['String']['input'];
  dateProcessed?: InputMaybe<Scalars['String']['input']>;
  dateReceived?: InputMaybe<Scalars['String']['input']>;
  medicare?: InputMaybe<Scalars['Boolean']['input']>;
  providerGeneratedAccountNumber: Scalars['String']['input'];
}

export interface RecentPatientGQL {
  __typename?: 'RecentPatient';
  ami?: Maybe<Scalars['String']['output']>;
  asOfDate?: Maybe<Scalars['String']['output']>;
  coverage?: Maybe<RecentPatientCoverageGQL>;
  dob?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
}

export interface RecentPatientCoverageGQL {
  __typename?: 'RecentPatientCoverage';
  account?: Maybe<Scalars['String']['output']>;
  claimSystem?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  compositeKey?: Maybe<Scalars['String']['output']>;
  coverageFrom?: Maybe<Scalars['String']['output']>;
  coverageStatus?: Maybe<Scalars['String']['output']>;
  coverageTo?: Maybe<Scalars['String']['output']>;
  coverageType?: Maybe<Scalars['String']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  isEffectivelyTermed?: Maybe<Scalars['Boolean']['output']>;
  isInactive?: Maybe<Scalars['Boolean']['output']>;
  showLink?: Maybe<Scalars['Boolean']['output']>;
}

export interface ReconsiderationGQL {
  __typename?: 'Reconsideration';
  choiceOne?: Maybe<Scalars['Int']['output']>;
  choiceThree?: Maybe<Scalars['Int']['output']>;
  choiceTwo?: Maybe<Scalars['Int']['output']>;
  extRefNum?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
}

export interface RemarkCodeGQL {
  __typename?: 'RemarkCode';
  desc?: Maybe<Scalars['String']['output']>;
  remarkCode?: Maybe<Scalars['String']['output']>;
}

export interface RemittanceDataGQL {
  __typename?: 'RemittanceData';
  controlStr?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['String']['output']>;
  creationDateStr?: Maybe<Scalars['String']['output']>;
  dataPowerLink?: Maybe<Scalars['String']['output']>;
  ddarMaxpages?: Maybe<Scalars['Int']['output']>;
  depositAmount?: Maybe<Scalars['String']['output']>;
  depositAmountStr?: Maybe<Scalars['String']['output']>;
  depositDate?: Maybe<Scalars['String']['output']>;
  depositDateStr?: Maybe<Scalars['String']['output']>;
  docId?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  documentTypeStr?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  generatedDate?: Maybe<Scalars['String']['output']>;
  isZelis?: Maybe<Scalars['Boolean']['output']>;
  links?: Maybe<Scalars['Int']['output']>;
  mbrDateofBirth?: Maybe<Scalars['String']['output']>;
  mbrFirstName?: Maybe<Scalars['String']['output']>;
  mbrLastName?: Maybe<Scalars['String']['output']>;
  npiList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  numberOfPages?: Maybe<Scalars['Int']['output']>;
  pagesList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  paymentDate?: Maybe<Scalars['String']['output']>;
  processedDate?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  readRemittance?: Maybe<Scalars['Boolean']['output']>;
  remittanceTrackingNumber?: Maybe<Scalars['String']['output']>;
  reportCategory?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
}

export interface RemittanceMarkAsReadInputGQL {
  createDt?: InputMaybe<Scalars['String']['input']>;
  readStatus: Scalars['Boolean']['input'];
  trackingNo: Scalars['String']['input'];
}

export interface RemittanceMarkAsReadResponseGQL {
  __typename?: 'RemittanceMarkAsReadResponse';
  trackingNo?: Maybe<Scalars['String']['output']>;
}

export interface RemittanceSummaryGQL {
  __typename?: 'RemittanceSummary';
  additionalDetails?: Maybe<Array<Maybe<CignaServiceAdditionalGQL>>>;
  isNPINumber?: Maybe<Scalars['Boolean']['output']>;
  isZelis?: Maybe<Scalars['Boolean']['output']>;
  paymentInfo?: Maybe<Array<Maybe<PayeeInfoGQL>>>;
  remittanceData?: Maybe<Array<Maybe<RemittanceDataGQL>>>;
  serviceError?: Maybe<CignaServiceErrorGQL>;
}

export interface RewardDetailsGQL {
  __typename?: 'RewardDetails';
  indicator?: Maybe<Scalars['Boolean']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
}

export interface ServiceGQL {
  __typename?: 'Service';
  serviceTypeCode?: Maybe<Scalars['String']['output']>;
  serviceTypeDesc?: Maybe<Scalars['String']['output']>;
  specialtyVariation?: Maybe<Scalars['String']['output']>;
  variations?: Maybe<Array<Maybe<VariationGQL>>>;
}

export interface ServiceLevelAccumulationGQL {
  __typename?: 'ServiceLevelAccumulation';
  services?: Maybe<Array<Maybe<ServiceGQL>>>;
}

export interface ServiceLineTotalGQL {
  __typename?: 'ServiceLineTotal';
  allowedAmountTotal?: Maybe<Scalars['String']['output']>;
  amountChargedTotal?: Maybe<Scalars['String']['output']>;
  amountNotCoveredTotal?: Maybe<Scalars['String']['output']>;
  coPayTotal?: Maybe<Scalars['String']['output']>;
  contractedAmountTotal?: Maybe<Scalars['String']['output']>;
  coordinationBenefitTotal?: Maybe<Scalars['String']['output']>;
  coveredBalTotal?: Maybe<Scalars['String']['output']>;
  deductibleTotal?: Maybe<Scalars['String']['output']>;
  memberCoInsuTotal?: Maybe<Scalars['String']['output']>;
  memberResponseTotal?: Maybe<Scalars['String']['output']>;
  paidFromFSATotal?: Maybe<Scalars['String']['output']>;
  paidFromHATotal?: Maybe<Scalars['String']['output']>;
  paidFromHRATotal?: Maybe<Scalars['String']['output']>;
  paidFromHSATotal?: Maybe<Scalars['String']['output']>;
  planCoinsurancePaidTotal?: Maybe<Scalars['String']['output']>;
}

export interface SpecificCoverageGQL {
  __typename?: 'SpecificCoverage';
  frequencyAgeLimitCoverageList?: Maybe<Array<Maybe<FrequencyCoverageItemGQL>>>;
  frequencyAgeLimitType?: Maybe<Scalars['String']['output']>;
}

export interface StateBenefitInfoGQL {
  __typename?: 'StateBenefitInfo';
  ctrl?: Maybe<Scalars['String']['output']>;
  docName?: Maybe<Scalars['String']['output']>;
  docTypCode?: Maybe<Scalars['String']['output']>;
  expireDt?: Maybe<Scalars['String']['output']>;
  fileSeqNum?: Maybe<Scalars['String']['output']>;
  fileTypeCode?: Maybe<Scalars['String']['output']>;
}

export interface SupportingInformationGQL {
  __typename?: 'SupportingInformation';
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  attachmentsData?: Maybe<Array<Maybe<AttachmentMetaDataGQL>>>;
  claimEngineClaimID?: Maybe<Scalars['String']['output']>;
  claimReferenceNumber?: Maybe<Scalars['String']['output']>;
  extRefNum?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  sourceSystemCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submittedBy?: Maybe<UserBaseGQL>;
  submittedDate?: Maybe<Scalars['String']['output']>;
}

export interface TierGQL {
  __typename?: 'Tier';
  tier1?: Maybe<Scalars['String']['output']>;
  tier2?: Maybe<Scalars['String']['output']>;
}

export interface TierBenefitGQL {
  __typename?: 'TierBenefit';
  tierType?: Maybe<Scalars['String']['output']>;
  tierTypeDesc?: Maybe<Scalars['String']['output']>;
  tiersList?: Maybe<Array<Maybe<TierListGQL>>>;
}

export interface TierListGQL {
  __typename?: 'TierList';
  tierCopayTimePeriod?: Maybe<Scalars['String']['output']>;
  tierLabel?: Maybe<Scalars['String']['output']>;
  tierNumber?: Maybe<Scalars['String']['output']>;
  tierValue?: Maybe<Scalars['String']['output']>;
}

export interface UserBaseGQL {
  __typename?: 'UserBase';
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
}

export interface UserProfileGQL {
  __typename?: 'UserProfile';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  allEntitlements?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  city?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isClaims360PopupSuppressed?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lob?: Maybe<Scalars['String']['output']>;
  lobPref?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  mobileVerified?: Maybe<Scalars['String']['output']>;
  permToggles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneExtn?: Maybe<Scalars['String']['output']>;
  secPwdLastChangedDate?: Maybe<Scalars['String']['output']>;
  ssoId?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  tins?: Maybe<Array<Maybe<UserProfileTinGQL>>>;
  zip?: Maybe<Scalars['String']['output']>;
}

export interface UserProfileTinGQL {
  __typename?: 'UserProfileTin';
  contracted?: Maybe<Scalars['Boolean']['output']>;
  entitlements?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  entitlementsJWTFormatted?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  generatedTinId?: Maybe<Scalars['String']['output']>;
  hasComrcl?: Maybe<Scalars['Boolean']['output']>;
  hasGovrnm?: Maybe<Scalars['Boolean']['output']>;
  sanctioned?: Maybe<Scalars['Boolean']['output']>;
  tinId?: Maybe<Scalars['String']['output']>;
  tinLob?: Maybe<Scalars['String']['output']>;
  tinName?: Maybe<Scalars['String']['output']>;
}

export interface VariationGQL {
  __typename?: 'Variation';
  benefitServiceId?: Maybe<Scalars['String']['output']>;
  benefits?: Maybe<Scalars['String']['output']>;
  diagnosisCode?: Maybe<Scalars['String']['output']>;
  diagnosisDesc?: Maybe<Scalars['String']['output']>;
  isPriorAuthRequired?: Maybe<Array<Maybe<AuthRequiredDataGQL>>>;
  notes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  posCode?: Maybe<Scalars['String']['output']>;
  posDesc?: Maybe<Scalars['String']['output']>;
  priorAuthorizationRequired?: Maybe<PriorAuthRequiredGQL>;
  procedureCode?: Maybe<Scalars['String']['output']>;
  procedureDesc?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  shortDesc?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface VisionServicesGQL {
  __typename?: 'VisionServices';
  hasCoreBenefits?: Maybe<Scalars['Boolean']['output']>;
  services?: Maybe<Array<Maybe<VisionServicesDetailsGQL>>>;
  standAloneVision?: Maybe<Scalars['Boolean']['output']>;
  vendorName?: Maybe<Scalars['String']['output']>;
}

export interface VisionServicesDetailsGQL {
  __typename?: 'VisionServicesDetails';
  coPayment?: Maybe<Scalars['String']['output']>;
  coinsurance?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  maximumAmount?: Maybe<Scalars['String']['output']>;
  maximumVisits?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface WamEntitlementsForUserGQL {
  __typename?: 'WamEntitlementsForUser';
  id?: Maybe<Scalars['String']['output']>;
  lob?: Maybe<Scalars['String']['output']>;
  lobPref?: Maybe<Scalars['String']['output']>;
  tins?: Maybe<Array<Maybe<WamUserTinGQL>>>;
}

export interface WamQueryGQL {
  __typename?: 'WamQuery';
  total?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<WamUserGQL>>>;
}

export interface WamUserGQL {
  __typename?: 'WamUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<Scalars['String']['output']>;
  jobRoleId?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lob?: Maybe<Scalars['String']['output']>;
  lobPref?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  requestedDate?: Maybe<Scalars['String']['output']>;
  temporaryPassword?: Maybe<Scalars['String']['output']>;
  temporaryUserName?: Maybe<Scalars['String']['output']>;
  tins?: Maybe<Array<Maybe<WamUserTinGQL>>>;
  tinsWithEntitlements?: Maybe<Array<Maybe<WamUserTinWithEntitlementGQL>>>;
  userId?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
}

export interface WamUserEntitlementGQL {
  __typename?: 'WamUserEntitlement';
  description?: Maybe<Scalars['String']['output']>;
  functions?: Maybe<Array<Maybe<WamUserFunctionGQL>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface WamUserFunctionGQL {
  __typename?: 'WamUserFunction';
  name?: Maybe<Scalars['String']['output']>;
}

export interface WamUserSearchInputGQL {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface WamUserTinGQL {
  __typename?: 'WamUserTin';
  lob?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
}

export interface WamUserTinWithEntitlementGQL {
  __typename?: 'WamUserTinWithEntitlement';
  entitlements?: Maybe<Array<Maybe<WamUserEntitlementGQL>>>;
  lob?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
}

export type DashboardClaimsQueryVariablesGQL = Exact<{ [key: string]: never }>;

export type DashboardClaimsQueryGQL = {
  __typename?: 'Query';
  recentClaims?: Array<{
    __typename?: 'RecentClaim';
    tinNumbers?: Array<string | null> | null;
    member?: {
      __typename?: 'RecentClaimMember';
      memberId?: string | null;
      memberFirstName?: string | null;
      memberLastName?: string | null;
    } | null;
    claimSummary?: {
      __typename?: 'RecentClaimSummary';
      chargeAmount?: string | null;
      claimCompositeKey?: string | null;
      claimReferenceNumber?: string | null;
      compositeClaimId?: string | null;
      claimStatus?: string | null;
      dateOfService?: string | null;
      medicare?: boolean | null;
      providerGeneratedAccountNumber?: string | null;
    } | null;
  } | null> | null;
  flaggedClaims?: Array<{
    __typename?: 'FlaggedClaim';
    id?: string | null;
    tinNumbers?: Array<string | null> | null;
    dateFlagged?: string | null;
    member?: {
      __typename?: 'FlaggedClaimMember';
      memberId?: string | null;
      memberFirstName?: string | null;
      memberLastName?: string | null;
    } | null;
    claimSummary?: {
      __typename?: 'FlaggedClaimSummary';
      chargeAmount?: string | null;
      claimCompositeKey?: string | null;
      claimReferenceNumber?: string | null;
      compositeClaimId?: string | null;
      claimStatus?: string | null;
      dateOfService?: string | null;
      medicare?: boolean | null;
      providerGeneratedAccountNumber?: string | null;
    } | null;
  } | null> | null;
};

export type ClaimDetailNewQueryVariablesGQL = Exact<{
  input: ClaimDetailInputGQL;
}>;

export type ClaimDetailNewQueryGQL = {
  __typename?: 'Query';
  claimByCompositeKey?: {
    __typename?: 'Claim';
    detail?: {
      __typename?: 'ClaimDetail';
      claimCompositeKey?: string | null;
      medicare?: boolean | null;
      isFlagged?: boolean | null;
      correspondenceHistory?: Array<{
        __typename?: 'Correspondence';
        claimId?: string | null;
        corresType?: string | null;
        documentLink?: string | null;
        generatedOn?: string | null;
        subject?: string | null;
        docId?: string | null;
      } | null> | null;
      remittanceSummaryData?: {
        __typename?: 'RemittanceSummary';
        isNPINumber?: boolean | null;
        isZelis?: boolean | null;
        additionalDetails?: Array<{
          __typename?: 'CignaServiceAdditional';
          code?: number | null;
          message?: string | null;
          severity?: string | null;
        } | null> | null;
        remittanceData?: Array<{
          __typename?: 'RemittanceData';
          documentType?: string | null;
          remittanceTrackingNumber?: string | null;
          creationDate?: string | null;
          creationDateStr?: string | null;
          paymentDate?: string | null;
          processedDate?: string | null;
          generatedDate?: string | null;
          reportCategory?: string | null;
          readRemittance?: boolean | null;
          taxId?: string | null;
          npiList?: Array<string | null> | null;
          depositDate?: string | null;
          depositDateStr?: string | null;
          depositAmount?: string | null;
          depositAmountStr?: string | null;
          mbrLastName?: string | null;
          mbrFirstName?: string | null;
          mbrDateofBirth?: string | null;
          productType?: string | null;
          expirationDate?: string | null;
          documentTypeStr?: string | null;
          numberOfPages?: number | null;
          links?: number | null;
          pagesList?: Array<string | null> | null;
          dataPowerLink?: string | null;
          docId?: string | null;
          controlStr?: string | null;
          ddarMaxpages?: number | null;
          isZelis?: boolean | null;
        } | null> | null;
        paymentInfo?: Array<{
          __typename?: 'PayeeInfo';
          eftExists?: boolean | null;
          chkExists?: boolean | null;
          payeeName?: string | null;
          payeeAddress?: string | null;
          checkAmount?: string | null;
          checkNumber?: string | null;
          checkStatus?: string | null;
          checkIssuedDate?: string | null;
          checkClearedDate?: string | null;
          paymentMethod?: string | null;
          sourceDataKey?: string | null;
          remittanceNumber?: string | null;
        } | null> | null;
      } | null;
      memberObject?: {
        __typename?: 'ClaimDetailMember';
        memberId?: string | null;
        memberDateOfBirth?: string | null;
        memberFirstName?: string | null;
        memberLastName?: string | null;
        relationShipCode?: string | null;
        memberIdentifier?: string | null;
        hasBehavioral?: boolean | null;
        patientId?: string | null;
      } | null;
      claimInfoObject?: {
        __typename?: 'ClaimInfo';
        serviceProvider?: string | null;
        renderingProviderId?: string | null;
        renderingProviderAddressState?: string | null;
        claimDateOfService?: string | null;
        claimTotChargeAmount?: string | null;
        claimReferenceNumber?: string | null;
        compositeClaimId?: string | null;
        groupNumber?: string | null;
        coverageCompositeKey?: string | null;
        uploadUrl?: string | null;
        isWebster?: boolean | null;
        isDDAR?: boolean | null;
        isDOSEstimate?: boolean | null;
        claimEngineClaimID?: string | null;
        networkPPOIndicator?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        dateOfBirth?: string | null;
        ssn?: string | null;
        ami?: string | null;
        idCardExtensionCode?: string | null;
        providerGeneratedAccountNumber?: string | null;
        isBehavioral?: boolean | null;
        claimReceivedDate?: string | null;
        claimProcessedDate?: string | null;
        claimDrgUsedCode?: string | null;
        patientResponsibility?: string | null;
        claimTotPaidAmount?: string | null;
        additionalRemarks?: string | null;
        footNoteMessages?: Array<string | null> | null;
        isZelis?: boolean | null;
        showPaymentInfo?: boolean | null;
        claimAmountDue?: string | null;
        recoupedAmount?: string | null;
        claimAmountPaid?: string | null;
        paymentProviderAmt?: string | null;
        hsaAmount?: string | null;
        hraAmount?: string | null;
        fsaAmount?: string | null;
        haaAmount?: string | null;
        hfAmount?: string | null;
        lpAmount?: string | null;
        claimStatusDesc?: string | null;
        sourceSystemCode?: string | null;
        claimDetailsInfoMessages?: Array<{
          __typename?: 'ClaimDetailsInfoMessages';
          claimInfoText?: string | null;
        } | null> | null;
        remarkCodes?: Array<{
          __typename?: 'RemarkCode';
          remarkCode?: string | null;
          desc?: string | null;
        } | null> | null;
        claimStatusCodes?: Array<{
          __typename?: 'ClaimsStatusCode';
          statusCodeValue?: string | null;
          statusCodeDesc?: string | null;
          reasonCodeValue?: string | null;
          reasonCodeDesc?: string | null;
        } | null> | null;
        overPaidClaimsInfo?: Array<{
          __typename?: 'OverpaidClaimsInfo';
          claimReferenceNumber?: string | null;
          patientAccountNumber?: string | null;
          claimPaidDate?: string | null;
          recoupmentAmount?: string | null;
          recoupmentBalance?: string | null;
          showLink?: boolean | null;
          tin?: string | null;
          claimCompositeKey?: string | null;
          compositeClaimId?: string | null;
        } | null> | null;
        payeeInfo?: Array<{
          __typename?: 'PayeeInfo';
          eftExists?: boolean | null;
          chkExists?: boolean | null;
          payeeName?: string | null;
          payeeAddress?: string | null;
          checkAmount?: string | null;
          checkNumber?: string | null;
          checkStatus?: string | null;
          checkIssuedDate?: string | null;
          checkClearedDate?: string | null;
          paymentMethod?: string | null;
          sourceDataKey?: string | null;
          remittanceNumber?: string | null;
        } | null> | null;
        payeePaymentInfoMessages?: Array<{
          __typename?: 'PayeePaymentInfoMessages';
          paymentInfoText?: string | null;
        } | null> | null;
        paymentInfoMessages?: Array<{
          __typename?: 'PaymentInfoMessages';
          label?: string | null;
          value?: string | null;
        } | null> | null;
        serviceLineInfoArray?: Array<{
          __typename?: 'ClaimServiceLineInfo';
          id?: string | null;
          dateOfService?: string | null;
          cdtCode?: string | null;
          procedureCode?: string | null;
          toothNumber?: string | null;
          amountCharged?: string | null;
          svcLineContractedAmount?: string | null;
          svcLinePaidAmount?: string | null;
          placeOfService?: string | null;
          allowedAmount?: string | null;
          serviceLineApcCode?: string | null;
          amountNotCovered?: string | null;
          deductible?: string | null;
          coveredBalance?: string | null;
          planCoInsurancePer?: string | null;
          memberCoInsurancePer?: string | null;
          memberCoInsu?: string | null;
          memberResponsibility?: string | null;
          patientCoinsuranceResponsibility?: string | null;
          copay?: string | null;
          remarkCode?: string | null;
          propRemarkDescription?: string | null;
          hraPayment?: string | null;
          hsaPayment?: string | null;
          fsaPayment?: string | null;
          haPayment?: string | null;
          amountNotAllowed?: string | null;
        } | null> | null;
        serviceLineTotalsObject?: {
          __typename?: 'ServiceLineTotal';
          amountChargedTotal?: string | null;
          allowedAmountTotal?: string | null;
          amountNotCoveredTotal?: string | null;
          deductibleTotal?: string | null;
          coveredBalTotal?: string | null;
          contractedAmountTotal?: string | null;
          coordinationBenefitTotal?: string | null;
          planCoinsurancePaidTotal?: string | null;
          coPayTotal?: string | null;
          memberCoInsuTotal?: string | null;
          memberResponseTotal?: string | null;
          paidFromHATotal?: string | null;
          paidFromHSATotal?: string | null;
          paidFromHRATotal?: string | null;
          paidFromFSATotal?: string | null;
        } | null;
        explanationOfRemarkCodes?: Array<{
          __typename?: 'ClaimExplanationOfRemarkCodes';
          remarkCode?: string | null;
          remarkCodeDesc?: string | null;
          additionalDesc?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type DashboardPatientsQueryVariablesGQL = Exact<{
  [key: string]: never;
}>;

export type DashboardPatientsQueryGQL = {
  __typename?: 'Query';
  recentPatients?: Array<{
    __typename?: 'RecentPatient';
    ami?: string | null;
    asOfDate?: string | null;
    dob?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    identifier?: string | null;
    patientId?: string | null;
    coverage?: {
      __typename?: 'RecentPatientCoverage';
      account?: string | null;
      clientName?: string | null;
      compositeKey?: string | null;
      coverageFrom?: string | null;
      coverageStatus?: string | null;
      coverageTo?: string | null;
      coverageType?: string | null;
      isEffectivelyTermed?: boolean | null;
      isInactive?: boolean | null;
      showLink?: boolean | null;
    } | null;
  } | null> | null;
  flaggedPatients?: Array<{
    __typename?: 'FlaggedPatient';
    ami?: string | null;
    asOfDate?: string | null;
    dateFlagged?: string | null;
    dob?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    patientId?: string | null;
    coverage?: {
      __typename?: 'FlaggedPatientCoverage';
      account?: string | null;
      clientName?: string | null;
      compositeKey?: string | null;
      coverageFrom?: string | null;
      coverageStatus?: string | null;
      coverageTo?: string | null;
      coverageType?: string | null;
      isEffectivelyTermed?: boolean | null;
      isInactive?: boolean | null;
      showLink?: boolean | null;
    } | null;
  } | null> | null;
};

export type DashboardPrecertificationsQueryVariablesGQL = Exact<{
  [key: string]: never;
}>;

export type DashboardPrecertificationsQueryGQL = {
  __typename?: 'Query';
  recentPrecertifications?: Array<{
    __typename?: 'PrecertResponse';
    precertNumber?: string | null;
    expirationTime?: number | null;
    createdDt?: string | null;
    payload?: {
      __typename?: 'PrecertPayload';
      compositeKey?: string | null;
      member?: {
        __typename?: 'PrecertMemberDetails';
        memberId?: string | null;
        patientSSN?: string | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        claimSystemCode?: string | null;
        sourceSystemCode?: string | null;
        coverage?: string | null;
        asOfDate?: string | null;
        patientPlan?: string | null;
      } | null;
      precertificaton?: {
        __typename?: 'PrecertificationDetails';
        precertNumber?: string | null;
        status?: string | null;
        startDate?: string | null;
        endDate?: string | null;
        precertSource?: string | null;
      } | null;
      providerDetails?: {
        __typename?: 'PrecertProviderDetailsObject';
        requestingProvider?: {
          __typename?: 'PrecertProviderDetail';
          tinNumber?: string | null;
          name?: string | null;
        } | null;
        servicingProvider?: {
          __typename?: 'PrecertProviderDetail';
          tinNumber?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  flaggedPrecertifications?: Array<{
    __typename?: 'PrecertResponse';
    precertNumber?: string | null;
    expirationTime?: number | null;
    createdDt?: string | null;
    payload?: {
      __typename?: 'PrecertPayload';
      compositeKey?: string | null;
      member?: {
        __typename?: 'PrecertMemberDetails';
        memberId?: string | null;
        patientSSN?: string | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        claimSystemCode?: string | null;
        sourceSystemCode?: string | null;
        coverage?: string | null;
        asOfDate?: string | null;
        patientPlan?: string | null;
      } | null;
      precertificaton?: {
        __typename?: 'PrecertificationDetails';
        precertNumber?: string | null;
        status?: string | null;
        startDate?: string | null;
        endDate?: string | null;
        precertSource?: string | null;
      } | null;
      providerDetails?: {
        __typename?: 'PrecertProviderDetailsObject';
        requestingProvider?: {
          __typename?: 'PrecertProviderDetail';
          tinNumber?: string | null;
          name?: string | null;
        } | null;
        servicingProvider?: {
          __typename?: 'PrecertProviderDetail';
          tinNumber?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type FlaggedPatientIdsQueryVariablesGQL = Exact<{
  [key: string]: never;
}>;

export type FlaggedPatientIdsQueryGQL = {
  __typename?: 'Query';
  flaggedPatients?: Array<{
    __typename?: 'FlaggedPatient';
    coverage?: {
      __typename?: 'FlaggedPatientCoverage';
      compositeKey?: string | null;
    } | null;
  } | null> | null;
};

export type FlaggedClaimIdsQueryVariablesGQL = Exact<{ [key: string]: never }>;

export type FlaggedClaimIdsQueryGQL = {
  __typename?: 'Query';
  flaggedClaims?: Array<{
    __typename?: 'FlaggedClaim';
    id?: string | null;
    claimSummary?: {
      __typename?: 'FlaggedClaimSummary';
      claimReferenceNumber?: string | null;
    } | null;
  } | null> | null;
};

export type DashboardWamUsersQueryVariablesGQL = Exact<{
  input?: InputMaybe<WamUserSearchInputGQL>;
}>;

export type DashboardWamUsersQueryGQL = {
  __typename?: 'Query';
  wamDashboardUsers?: {
    __typename?: 'WamQuery';
    total?: number | null;
    users?: Array<{
      __typename?: 'WamUser';
      firstName?: string | null;
      lastName?: string | null;
      id?: string | null;
      temporaryUserName?: string | null;
      temporaryPassword?: string | null;
      email?: string | null;
      phone?: string | null;
      jobRole?: string | null;
      tins?: Array<{
        __typename?: 'WamUserTin';
        tin?: string | null;
        name?: string | null;
        status?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DashboardWamUserDetailQueryVariablesGQL = Exact<{
  input: WamUserSearchInputGQL;
}>;

export type DashboardWamUserDetailQueryGQL = {
  __typename?: 'Query';
  wamDashboardUsers?: {
    __typename?: 'WamQuery';
    users?: Array<{
      __typename?: 'WamUser';
      firstName?: string | null;
      lastName?: string | null;
      id?: string | null;
      tinsWithEntitlements?: Array<{
        __typename?: 'WamUserTinWithEntitlement';
        tin?: string | null;
        name?: string | null;
        status?: string | null;
        lob?: string | null;
        entitlements?: Array<{
          __typename?: 'WamUserEntitlement';
          name?: string | null;
          description?: string | null;
          functions?: Array<{
            __typename?: 'WamUserFunction';
            name?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type PatientDetailsQueryVariablesGQL = Exact<{
  patientCompositeKeyInput: PatientCompositeKeyInputGQL;
}>;

export type PatientDetailsQueryGQL = {
  __typename?: 'Query';
  patientByCompositeKey?: {
    __typename?: 'Patient';
    asOfDate: string;
    detail?: {
      __typename?: 'PatientDetail';
      alliances?: {
        __typename?: 'PatientAlliance';
        hasMVP?: boolean | null;
        partners?: Array<{
          __typename?: 'Partner';
          roleOfCigna?: string | null;
          effectiveOn?: string | null;
          endsOn?: string | null;
          coordinatingParty?: string | null;
          supportingParty?: string | null;
          thirdPartyAdmin?: string | null;
        } | null> | null;
      } | null;
      cobDetails?: Array<{
        __typename?: 'CobDetail';
        policyNo?: string | null;
        verifiedOn?: string | null;
        effectiveFrom?: string | null;
        effectiveTill?: string | null;
        carrier?: string | null;
        coverageType?: string | null;
        primacy?: string | null;
      } | null> | null;
      subscriberDetails?: {
        __typename?: 'PatientSubscriberDetails';
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        dob?: string | null;
        gender?: string | null;
        internalProcessingId?: string | null;
      } | null;
      serviceContact?: Array<{
        __typename?: 'PatientServiceContact';
        type?: string | null;
        name?: string | null;
        id?: string | null;
        effectiveDate?: string | null;
        group?: string | null;
        web?: string | null;
        webName?: string | null;
        addresses?: Array<{
          __typename?: 'Address';
          city?: string | null;
          country?: string | null;
          lines?: Array<string | null> | null;
          state?: string | null;
          type?: string | null;
          zip?: string | null;
        } | null> | null;
        phones?: Array<{
          __typename?: 'Phone';
          number?: string | null;
          type?: string | null;
        } | null> | null;
      } | null> | null;
      coverageDetails?: {
        __typename?: 'PatientCoverageDetail';
        accountNumber?: string | null;
        accountName?: string | null;
        clientId?: string | null;
        effectiveFrom?: string | null;
        effectiveTill?: string | null;
        initialCoverageDate?: string | null;
        coverageEndDate?: string | null;
        status?: string | null;
        coverageStatus?: string | null;
        hasPRN?: boolean | null;
        hasPDP?: boolean | null;
        hasIFP?: boolean | null;
        hasV2V?: boolean | null;
        hasCSN?: boolean | null;
        hasMHSR?: boolean | null;
        hasBayCareGroup?: boolean | null;
        coverageType?: string | null;
        networkDescription?: string | null;
        otherInsurance?: string | null;
        otherInsuranceVerifiedOn?: string | null;
        pediatricInd?: string | null;
        utilizationDataNotAvlble?: boolean | null;
        isMultipleCoverage?: boolean | null;
        hasMultipleActive?: boolean | null;
        hasCoveredBenefits?: boolean | null;
        coverages?: Array<string | null> | null;
        isAccountRestricted?: boolean | null;
        medicalManagementPlan?: string | null;
        referralCode?: string | null;
        hasConflictingStatus?: boolean | null;
        erisaApplicable?: boolean | null;
        ohip?: string | null;
        umAuthSystem?: string | null;
        claimSystemCode?: string | null;
        visionSourceCd?: string | null;
        medicare?: {
          __typename?: 'MedicareDetails';
          contractNumber?: string | null;
          groupNumber?: string | null;
          planBenefitPackageNumber?: string | null;
          segmentNumber?: string | null;
          healthPlanName?: string | null;
          operationalMarket?: string | null;
          regulatoryMarket?: string | null;
          specialNeedPlanType?: string | null;
          egwpIndicator?: string | null;
          pcpRequired?: string | null;
          familyCode?: string | null;
          cobDetails?: Array<{
            __typename?: 'CobDetail';
            policyNo?: string | null;
            verifiedOn?: string | null;
            effectiveFrom?: string | null;
            effectiveTill?: string | null;
            carrier?: string | null;
            coverageType?: string | null;
            primacy?: string | null;
          } | null> | null;
        } | null;
        networkDetails?: {
          __typename?: 'NetworkDetails';
          inOutIndicator?: string | null;
          networkId?: string | null;
          networkName?: string | null;
          networkType?: string | null;
          effectiveFrom?: string | null;
          effectiveTill?: string | null;
          superNetworkId?: string | null;
          npoId?: string | null;
          npoName?: string | null;
          mpoId?: string | null;
          mpoName?: string | null;
          porgId?: string | null;
          seamlessIndicator?: string | null;
          baseNetworkId?: string | null;
          iCareNetworkId?: string | null;
          dnspEligible?: string | null;
          isAllegianceMember?: boolean | null;
          isHAPMember?: boolean | null;
          isNetworkTiered?: boolean | null;
          networkCategoryCode?: string | null;
          tier?: {
            __typename?: 'Tier';
            tier1?: string | null;
            tier2?: string | null;
          } | null;
          pcp?: {
            __typename?: 'PcpStatus';
            pcpStatus?: string | null;
            hasPCP?: boolean | null;
            assignmentType?: string | null;
            effectiveDate?: string | null;
            cancelDate?: string | null;
            providerIdentity?: {
              __typename?: 'ProviderIdentity';
              id?: string | null;
              type?: string | null;
            } | null;
          } | null;
          hmoDetails?: {
            __typename?: 'HmoDetails';
            orgName?: string | null;
            code?: string | null;
            networkLob?: string | null;
            lob?: string | null;
            networkId?: string | null;
            networkIdCode?: string | null;
            networkDesc?: string | null;
            contact?: {
              __typename?: 'Contact';
              name?: string | null;
              type?: string | null;
              addresses?: Array<{
                __typename?: 'Address';
                city?: string | null;
                country?: string | null;
                lines?: Array<string | null> | null;
                state?: string | null;
                type?: string | null;
                zip?: string | null;
              } | null> | null;
              phones?: Array<{
                __typename?: 'Phone';
                number?: string | null;
                type?: string | null;
              } | null> | null;
            } | null;
          } | null;
          ecnNetworkDetails?: {
            __typename?: 'EcnNetworkDetails';
            ecnId?: string | null;
            csnNetworkDetails?: {
              __typename?: 'CsnNetworkDetails';
              csnNetwork?: Array<{
                __typename?: 'CsnDetail';
                csnId?: string | null;
                displayOrderCd?: string | null;
                displayOrderDesc?: string | null;
                tierName?: string | null;
              } | null> | null;
            } | null;
          } | null;
        } | null;
        plan?: {
          __typename?: 'PatientPlan';
          effectiveFrom?: string | null;
          effectiveTill?: string | null;
          type?: string | null;
          fundingType?: string | null;
          isIndemnity?: boolean | null;
          progressiveRegressiveInd?: string | null;
          deductibleSequence?: string | null;
          benefitOption?: string | null;
          branch?: string | null;
          rxCarveOutVendor?: string | null;
          classNumber?: string | null;
          planVariationIdentifier?: string | null;
          pathwellIndicator?: string | null;
          situsStateIndicator?: string | null;
          planYears?: {
            __typename?: 'PatientPlanYears';
            effectiveFrom?: string | null;
            effectiveTill?: string | null;
            renewalType?: string | null;
          } | null;
        } | null;
      } | null;
      patientDetails?: {
        __typename?: 'PatientInformation';
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        dob?: string | null;
        gender?: string | null;
        internalProcessingId?: string | null;
        eligibleForCostEstimation?: boolean | null;
        id?: string | null;
        idCardExtensionCode?: string | null;
        identifier?: string | null;
        isInternationalMember?: boolean | null;
        isPolicyHolder?: boolean | null;
        relationship?: string | null;
        addresses?: Array<{
          __typename?: 'Address';
          city?: string | null;
          country?: string | null;
          lines?: Array<string | null> | null;
          state?: string | null;
          type?: string | null;
          zip?: string | null;
        } | null> | null;
      } | null;
      productDetails?: {
        __typename?: 'PatientProductDetail';
        typeCode?: string | null;
        familyCode?: string | null;
        hraFundBalance?: string | null;
        isV2V?: boolean | null;
        isIFP?: boolean | null;
        hasHRA?: boolean | null;
        hasHSA?: boolean | null;
        hasFSA?: boolean | null;
        name?: string | null;
        embeddedProducts?: Array<{
          __typename?: 'PatientProductEmbedded';
          productCode?: string | null;
          effectiveOn?: string | null;
          endsOn?: string | null;
        } | null> | null;
      } | null;
      rewardDetails?: Array<{
        __typename?: 'RewardDetails';
        indicator?: boolean | null;
        typeName?: string | null;
      } | null> | null;
      feeSchedule?: {
        __typename?: 'FeeSchedule';
        indicator?: string | null;
        typeName?: string | null;
      } | null;
    } | null;
  } | null;
};

export type PatientPcpQueryVariablesGQL = Exact<{
  patientCompositeKeyInput: PatientCompositeKeyInputGQL;
  pcpDetailsInput?: InputMaybe<PcpDetailsInputGQL>;
}>;

export type PatientPcpQueryGQL = {
  __typename?: 'Query';
  patientByCompositeKey?: {
    __typename?: 'Patient';
    primaryCareDetails?: {
      __typename?: 'PcpDetails';
      hasPCP?: boolean | null;
      pcpStatus?: string | null;
      primaryCarePhysician?: {
        __typename?: 'PrimaryCareService';
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        effectiveFrom?: string | null;
        effectiveTill?: string | null;
        assignmentType?: string | null;
        addresses?: Array<{
          __typename?: 'Address';
          type?: string | null;
          lines?: Array<string | null> | null;
          city?: string | null;
          country?: string | null;
          state?: string | null;
          zip?: string | null;
        } | null> | null;
        phones?: Array<{
          __typename?: 'Phone';
          number?: string | null;
          type?: string | null;
        } | null> | null;
      } | null;
      providerOrganization?: {
        __typename?: 'ProviderOrganization';
        name?: string | null;
        groupNumber?: string | null;
        serviceTypeCode?: string | null;
        addresses?: Array<{
          __typename?: 'Address';
          type?: string | null;
          lines?: Array<string | null> | null;
          city?: string | null;
          country?: string | null;
          state?: string | null;
          zip?: string | null;
        } | null> | null;
        phones?: Array<{
          __typename?: 'Phone';
          number?: string | null;
          type?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type PatientAccumsQueryVariablesGQL = Exact<{
  patientCompositeKeyInput: PatientCompositeKeyInputGQL;
  planLevelAccumInput?: InputMaybe<PlanLevelAccumInputGQL>;
}>;

export type PatientAccumsQueryGQL = {
  __typename?: 'Query';
  patientByCompositeKey?: {
    __typename?: 'Patient';
    planLevelAccumulation?: Array<{
      __typename?: 'PlanLevelAccum';
      type?: string | null;
      notes?: Array<string | null> | null;
      benefits?: {
        __typename?: 'PlanLevelAccumBenefits';
        deductible?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            tier?: string | null;
            desc?: string | null;
            networkType?: string | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            productType?: string | null;
            planPeriodCode?: string | null;
            networkTierLabelName?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkId?: string | null;
            networkName?: string | null;
          } | null> | null;
        } | null;
        moop?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            tier?: string | null;
            desc?: string | null;
            networkType?: string | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            productType?: string | null;
            planPeriodCode?: string | null;
            networkTierLabelName?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkId?: string | null;
            networkName?: string | null;
          } | null> | null;
        } | null;
        coinsurance?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            tier?: string | null;
            desc?: string | null;
            networkType?: string | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            productType?: string | null;
            planPeriodCode?: string | null;
            networkTierLabelName?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkId?: string | null;
            networkName?: string | null;
          } | null> | null;
        } | null;
        lifeTimeMaximum?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            tier?: string | null;
            desc?: string | null;
            networkType?: string | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            productType?: string | null;
            planPeriodCode?: string | null;
            networkTierLabelName?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkId?: string | null;
            networkName?: string | null;
          } | null> | null;
        } | null;
        outOfPocketMaximum?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            tier?: string | null;
            desc?: string | null;
            networkType?: string | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            productType?: string | null;
            planPeriodCode?: string | null;
            networkTierLabelName?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkId?: string | null;
            networkName?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PatientGeneralBenefitsMedicareQueryVariablesGQL = Exact<{
  patientCompositeKeyInput: PatientCompositeKeyInputGQL;
  medicareMedicalDetailInput?: InputMaybe<MedicareMedicalDetailInputGQL>;
}>;

export type PatientGeneralBenefitsMedicareQueryGQL = {
  __typename?: 'Query';
  patientByCompositeKey?: {
    __typename?: 'Patient';
    medicareMedicalDetail?: {
      __typename?: 'MedicareMedicalDetail';
      benefitOptionCode?: string | null;
      benefitPlanId?: string | null;
      contractNumber?: string | null;
      egwpAccountNumber?: string | null;
      id?: string | null;
      networkCategoryKeyCode?: string | null;
      pbpNumber?: string | null;
      planKey?: string | null;
      planName?: string | null;
      planType?: string | null;
      segmentNumber?: string | null;
      benefitDetails?: Array<{
        __typename?: 'MedicareBenefitDetail';
        productBenefitType?: string | null;
        categories?: Array<{
          __typename?: 'MedicareBenefitCategory';
          categoryName?: string | null;
          order?: string | null;
          pbpCategoryId?: string | null;
          benefits?: Array<{
            __typename?: 'MedicareBenefit';
            pbpCategoryId?: string | null;
            name?: string | null;
            order?: string | null;
            costShare?: Array<{
              __typename?: 'MedicareCostShare';
              code?: string | null;
              order?: string | null;
              showPartBDeductible?: string | null;
              value?: string | null;
              description?: Array<{
                __typename?: 'Description';
                type?: string | null;
                value?: Array<string | null> | null;
              } | null> | null;
            } | null> | null;
            description?: Array<{
              __typename?: 'Description';
              type?: string | null;
              value?: Array<string | null> | null;
            } | null> | null;
          } | null> | null;
          categoryDescription?: Array<{
            __typename?: 'Description';
            type?: string | null;
            value?: Array<string | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type FlagPatientUpdateMutationVariablesGQL = Exact<{
  patientFlags:
    | Array<InputMaybe<FlagPatientInputGQL>>
    | InputMaybe<FlagPatientInputGQL>;
  type: FlagTypeGQL;
}>;

export type FlagPatientUpdateMutationGQL = {
  __typename?: 'Mutation';
  flagPatientsUpdate?: Array<{
    __typename?: 'FlaggedPatient';
    ami?: string | null;
    asOfDate?: string | null;
    dateFlagged?: string | null;
    dob?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    patientId?: string | null;
    coverage?: {
      __typename?: 'FlaggedPatientCoverage';
      account?: string | null;
      clientName?: string | null;
      compositeKey?: string | null;
      coverageFrom?: string | null;
      coverageStatus?: string | null;
      coverageTo?: string | null;
      coverageType?: string | null;
      isEffectivelyTermed?: boolean | null;
      isInactive?: boolean | null;
      showLink?: boolean | null;
    } | null;
  } | null> | null;
};

export type FlagClaimUpdateMutationVariablesGQL = Exact<{
  claimsFlags:
    | Array<InputMaybe<FlagClaimInputGQL>>
    | InputMaybe<FlagClaimInputGQL>;
  type: FlagTypeGQL;
}>;

export type FlagClaimUpdateMutationGQL = {
  __typename?: 'Mutation';
  flagClaimsUpdate?: Array<{
    __typename?: 'FlaggedClaim';
    id?: string | null;
    tinNumbers?: Array<string | null> | null;
    dateFlagged?: string | null;
    member?: {
      __typename?: 'FlaggedClaimMember';
      memberId?: string | null;
      memberFirstName?: string | null;
      memberLastName?: string | null;
    } | null;
    claimSummary?: {
      __typename?: 'FlaggedClaimSummary';
      chargeAmount?: string | null;
      claimCompositeKey?: string | null;
      claimReferenceNumber?: string | null;
      compositeClaimId?: string | null;
      claimStatus?: string | null;
      dateOfService?: string | null;
      medicare?: boolean | null;
      providerGeneratedAccountNumber?: string | null;
    } | null;
  } | null> | null;
};

export type SaveRecentClaimMutationVariablesGQL = Exact<{
  claim: RecentClaimInputGQL;
}>;

export type SaveRecentClaimMutationGQL = {
  __typename?: 'Mutation';
  saveRecentClaim?: {
    __typename?: 'RecentClaim';
    tinNumbers?: Array<string | null> | null;
    member?: {
      __typename?: 'RecentClaimMember';
      memberId?: string | null;
      memberFirstName?: string | null;
      memberLastName?: string | null;
      memberSearchKey?: string | null;
    } | null;
    claimSummary?: {
      __typename?: 'RecentClaimSummary';
      chargeAmount?: string | null;
      claimCompositeKey?: string | null;
      claimReferenceNumber?: string | null;
      compositeClaimId?: string | null;
      claimStatus?: string | null;
      dateOfService?: string | null;
      medicare?: boolean | null;
      providerGeneratedAccountNumber?: string | null;
    } | null;
  } | null;
};

export type FlagPrecertificationUpdateMutationVariablesGQL = Exact<{
  precertInput: PrecertInputGQL;
  type: FlagTypeGQL;
}>;

export type FlagPrecertificationUpdateMutationGQL = {
  __typename?: 'Mutation';
  flagPrecertificationUpdate?: {
    __typename?: 'PrecertResponse';
    precertNumber?: string | null;
    expirationTime?: number | null;
    createdDt?: string | null;
    payload?: {
      __typename?: 'PrecertPayload';
      compositeKey?: string | null;
      member?: {
        __typename?: 'PrecertMemberDetails';
        memberId?: string | null;
        patientSSN?: string | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        claimSystemCode?: string | null;
        sourceSystemCode?: string | null;
        coverage?: string | null;
        asOfDate?: string | null;
        patientPlan?: string | null;
      } | null;
      precertificaton?: {
        __typename?: 'PrecertificationDetails';
        precertNumber?: string | null;
        status?: string | null;
        startDate?: string | null;
        endDate?: string | null;
        precertSource?: string | null;
      } | null;
      providerDetails?: {
        __typename?: 'PrecertProviderDetailsObject';
        requestingProvider?: {
          __typename?: 'PrecertProviderDetail';
          tinNumber?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
        servicingProvider?: {
          __typename?: 'PrecertProviderDetail';
          tinNumber?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SaveRecentPrecertificationMutationVariablesGQL = Exact<{
  precertInput: PrecertInputGQL;
}>;

export type SaveRecentPrecertificationMutationGQL = {
  __typename?: 'Mutation';
  saveRecentPrecertification?: {
    __typename?: 'PrecertResponse';
    precertNumber?: string | null;
    expirationTime?: number | null;
    createdDt?: string | null;
    payload?: {
      __typename?: 'PrecertPayload';
      compositeKey?: string | null;
      member?: {
        __typename?: 'PrecertMemberDetails';
        memberId?: string | null;
        patientSSN?: string | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        claimSystemCode?: string | null;
        sourceSystemCode?: string | null;
        coverage?: string | null;
        asOfDate?: string | null;
        patientPlan?: string | null;
      } | null;
      precertificaton?: {
        __typename?: 'PrecertificationDetails';
        precertNumber?: string | null;
        status?: string | null;
        startDate?: string | null;
        endDate?: string | null;
        precertSource?: string | null;
      } | null;
      providerDetails?: {
        __typename?: 'PrecertProviderDetailsObject';
        requestingProvider?: {
          __typename?: 'PrecertProviderDetail';
          tinNumber?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
        servicingProvider?: {
          __typename?: 'PrecertProviderDetail';
          tinNumber?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetBrnDetailsQueryVariablesGQL = Exact<{
  input: BrnDetailsInputGQL;
}>;

export type GetBrnDetailsQueryGQL = {
  __typename?: 'Query';
  brnDetails?: {
    __typename?: 'BenefitReferenceNumberDetails';
    medicalBenefits?: string | null;
    alliances?: {
      __typename?: 'PatientAlliance';
      hasMVP?: boolean | null;
      partners?: Array<{
        __typename?: 'Partner';
        roleOfCigna?: string | null;
        effectiveOn?: string | null;
        endsOn?: string | null;
        coordinatingParty?: string | null;
        supportingParty?: string | null;
        thirdPartyAdmin?: string | null;
      } | null> | null;
    } | null;
    ccfBenefit?: {
      __typename?: 'PatientCcfBenefit';
      footNotes?: Array<string | null> | null;
      ccfType?: string | null;
      ccfbenefitFundList?: Array<{
        __typename?: 'PatientCcfBenefitFundList';
        carryOverAmount?: string | null;
        carryOverPercentage?: string | null;
        currentAmount?: string | null;
        fundType?: string | null;
        goalAmount?: string | null;
        note?: string | null;
        note2?: string | null;
        fundTypeWithValues?: boolean | null;
        coverageList?: Array<{
          __typename?: 'PatientCcfCoverageList';
          eligibilityServiceType?: string | null;
          eligibilityServiceValue?: string | null;
          label?: string | null;
          type?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
    coverageDetail?: {
      __typename?: 'PatientCoverageDetail';
      accountNumber?: string | null;
      accountName?: string | null;
      clientId?: string | null;
      effectiveFrom?: string | null;
      effectiveTill?: string | null;
      initialCoverageDate?: string | null;
      status?: string | null;
      coverageStatus?: string | null;
      hasPRN?: boolean | null;
      hasPDP?: boolean | null;
      hasIFP?: boolean | null;
      hasCSN?: boolean | null;
      hasMHSR?: boolean | null;
      hasBayCareGroup?: boolean | null;
      coverageType?: string | null;
      networkDescription?: string | null;
      otherInsurance?: string | null;
      otherInsuranceVerifiedOn?: string | null;
      pediatricInd?: string | null;
      utilizationDataNotAvlble?: boolean | null;
      isMultipleCoverage?: boolean | null;
      hasMultipleActive?: boolean | null;
      hasCoveredBenefits?: boolean | null;
      coverages?: Array<string | null> | null;
      isAccountRestricted?: boolean | null;
      medicalManagementPlan?: string | null;
      referralCode?: string | null;
      hasConflictingStatus?: boolean | null;
      erisaApplicable?: boolean | null;
      ohip?: string | null;
      umAuthSystem?: string | null;
      claimSystemCode?: string | null;
      plan?: {
        __typename?: 'PatientPlan';
        effectiveFrom?: string | null;
        effectiveTill?: string | null;
        type?: string | null;
        fundingType?: string | null;
        isIndemnity?: boolean | null;
        progressiveRegressiveInd?: string | null;
        deductibleSequence?: string | null;
        benefitOption?: string | null;
        branch?: string | null;
        rxCarveOutVendor?: string | null;
        classNumber?: string | null;
        planVariationIdentifier?: string | null;
        pathwellIndicator?: string | null;
        planYears?: {
          __typename?: 'PatientPlanYears';
          effectiveFrom?: string | null;
          effectiveTill?: string | null;
          renewalType?: string | null;
        } | null;
      } | null;
      networkDetails?: {
        __typename?: 'NetworkDetails';
        networkId?: string | null;
        networkName?: string | null;
        networkType?: string | null;
        effectiveFrom?: string | null;
        effectiveTill?: string | null;
        npoId?: string | null;
        npoName?: string | null;
        mpoId?: string | null;
        mpoName?: string | null;
        porgId?: string | null;
        seamlessIndicator?: string | null;
        baseNetworkId?: string | null;
        iCareNetworkId?: string | null;
        isAllegianceMember?: boolean | null;
        isHAPMember?: boolean | null;
        inOutIndicator?: string | null;
        dnspEligible?: string | null;
        isNetworkTiered?: boolean | null;
        superNetworkId?: string | null;
        hmoDetails?: {
          __typename?: 'HmoDetails';
          orgName?: string | null;
          code?: string | null;
          networkLob?: string | null;
          lob?: string | null;
          networkId?: string | null;
          networkIdCode?: string | null;
          networkDesc?: string | null;
          contact?: {
            __typename?: 'Contact';
            type?: string | null;
            name?: string | null;
            addresses?: Array<{
              __typename?: 'Address';
              type?: string | null;
              lines?: Array<string | null> | null;
              city?: string | null;
              state?: string | null;
              zip?: string | null;
              country?: string | null;
            } | null> | null;
            phones?: Array<{
              __typename?: 'Phone';
              number?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        tier?: {
          __typename?: 'Tier';
          tier1?: string | null;
          tier2?: string | null;
        } | null;
        pcp?: {
          __typename?: 'PcpStatus';
          pcpStatus?: string | null;
          hasPCP?: boolean | null;
          assignmentType?: string | null;
          cancelDate?: string | null;
          effectiveDate?: string | null;
          providerIdentity?: {
            __typename?: 'ProviderIdentity';
            id?: string | null;
            type?: string | null;
          } | null;
        } | null;
        ecnNetworkDetails?: {
          __typename?: 'EcnNetworkDetails';
          ecnId?: string | null;
          csnNetworkDetails?: {
            __typename?: 'CsnNetworkDetails';
            csnNetwork?: Array<{
              __typename?: 'CsnDetail';
              csnId?: string | null;
              displayOrderCd?: string | null;
              displayOrderDesc?: string | null;
              tierName?: string | null;
            } | null> | null;
          } | null;
        } | null;
      } | null;
      medicare?: {
        __typename?: 'MedicareDetails';
        contractNumber?: string | null;
        planBenefitPackageNumber?: string | null;
        segmentNumber?: string | null;
        healthPlanName?: string | null;
        groupNumber?: string | null;
        operationalMarket?: string | null;
        regulatoryMarket?: string | null;
        specialNeedPlanType?: string | null;
        egwpIndicator?: string | null;
        pcpRequired?: string | null;
        familyCode?: string | null;
        cobDetails?: Array<{
          __typename?: 'CobDetail';
          policyNo?: string | null;
          verifiedOn?: string | null;
          effectiveFrom?: string | null;
          effectiveTill?: string | null;
          carrier?: string | null;
          coverageType?: string | null;
          primacy?: string | null;
        } | null> | null;
      } | null;
    } | null;
    idcardDetail?: {
      __typename?: 'PatientIdCardDetails';
      accountID?: string | null;
      backImageFlag?: boolean | null;
      backImageGUID?: string | null;
      backImageUrl?: string | null;
      benOptionCode?: string | null;
      cardType?: string | null;
      claimSystemCode?: string | null;
      contentSearchBeansList?: Array<string | null> | null;
      coverageFromDate?: string | null;
      frontImageFlag?: boolean | null;
      frontImageGUID?: string | null;
      frontImageUrl?: string | null;
      idCardExtensionCode?: string | null;
      idNumber?: string | null;
      isIFP?: string | null;
      issueDate?: string | null;
      medicalPlan?: string | null;
      memberCoverageDate?: string | null;
      patientID?: string | null;
      patientName?: string | null;
      pharmacyAvailable?: string | null;
      planName?: string | null;
      productType?: string | null;
      relationshipCode?: string | null;
      selectPharmacyAvailable?: string | null;
    } | null;
    subscriberDetail?: {
      __typename?: 'PatientSubscriberDetails';
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      dob?: string | null;
      gender?: string | null;
      internalProcessingId?: string | null;
    } | null;
    patientDetail?: {
      __typename?: 'BrnPatientDetail';
      asOfDate?: string | null;
      details?: {
        __typename?: 'PatientInformation';
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        dob?: string | null;
        gender?: string | null;
        internalProcessingId?: string | null;
        eligibleForCostEstimation?: boolean | null;
        id?: string | null;
        idCardExtensionCode?: string | null;
        identifier?: string | null;
        isInternationalMember?: boolean | null;
        isPolicyHolder?: boolean | null;
        relationship?: string | null;
        addresses?: Array<{
          __typename?: 'Address';
          type?: string | null;
          lines?: Array<string | null> | null;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
        } | null> | null;
      } | null;
    } | null;
    dentalOfficeDetails?: {
      __typename?: 'PatientDentalOfficeDetails';
      effectiveFrom?: string | null;
      effectiveTill?: string | null;
      id?: string | null;
      name?: string | null;
    } | null;
    serviceContact?: Array<{
      __typename?: 'PatientServiceContact';
      type?: string | null;
      name?: string | null;
      id?: string | null;
      effectiveDate?: string | null;
      group?: string | null;
      web?: string | null;
      webName?: string | null;
      addresses?: Array<{
        __typename?: 'Address';
        type?: string | null;
        lines?: Array<string | null> | null;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
      } | null> | null;
      phones?: Array<{
        __typename?: 'Phone';
        number?: string | null;
        type?: string | null;
      } | null> | null;
    } | null> | null;
    planLevelAccumulation?: Array<{
      __typename?: 'PlanLevelAccum';
      type?: string | null;
      notes?: Array<string | null> | null;
      benefits?: {
        __typename?: 'PlanLevelAccumBenefits';
        coinsurance?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            desc?: string | null;
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            networkType?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            tier?: string | null;
            productType?: string | null;
            networkName?: string | null;
            networkId?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierLabelName?: string | null;
            planPeriodCode?: string | null;
          } | null> | null;
        } | null;
        deductible?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            desc?: string | null;
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            networkType?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            tier?: string | null;
            productType?: string | null;
            networkName?: string | null;
            networkId?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierLabelName?: string | null;
            planPeriodCode?: string | null;
          } | null> | null;
        } | null;
        lifeTimeMaximum?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            desc?: string | null;
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            networkType?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            tier?: string | null;
            productType?: string | null;
            networkName?: string | null;
            networkId?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierLabelName?: string | null;
            planPeriodCode?: string | null;
          } | null> | null;
        } | null;
        outOfPocketMaximum?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            desc?: string | null;
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            networkType?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            tier?: string | null;
            productType?: string | null;
            networkName?: string | null;
            networkId?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierLabelName?: string | null;
            planPeriodCode?: string | null;
          } | null> | null;
        } | null;
        moop?: {
          __typename?: 'PlanLevelAccumDetail';
          accumulations?: Array<{
            __typename?: 'Accumulation';
            desc?: string | null;
            amount?: string | null;
            met?: string | null;
            remaining?: string | null;
            covers?: string | null;
            networkType?: string | null;
            notes?: Array<string | null> | null;
            cignaCareNetwork?: boolean | null;
            coveredServices?: Array<string | null> | null;
            tierIndex?: string | null;
            tier?: string | null;
            productType?: string | null;
            networkName?: string | null;
            networkId?: string | null;
            networkTierDisplayOrderCd?: string | null;
            networkTierDisplayOrderCdDescription?: string | null;
            networkTierLabelName?: string | null;
            planPeriodCode?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
    benefitReportDetails?: {
      __typename?: 'PatientBenefitReportDetails';
      showBenefitsInd?: boolean | null;
      patientChargeSheet?: {
        __typename?: 'PatientChargeSheet';
        fileSeqNum?: string | null;
        ctrl?: string | null;
        docName?: string | null;
        docTypCode?: string | null;
        expireDt?: string | null;
        fileTypeCode?: string | null;
      } | null;
      stateBenefitInfo?: {
        __typename?: 'StateBenefitInfo';
        fileSeqNum?: string | null;
        ctrl?: string | null;
        docName?: string | null;
        docTypCode?: string | null;
        expireDt?: string | null;
        fileTypeCode?: string | null;
      } | null;
    } | null;
    primaryCareDetails?: {
      __typename?: 'PcpDetails';
      pcpStatus?: string | null;
      hasPCP?: boolean | null;
      primaryCarePhysician?: {
        __typename?: 'PrimaryCareService';
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
        fullName?: string | null;
        effectiveFrom?: string | null;
        effectiveTill?: string | null;
        assignmentType?: string | null;
        addresses?: Array<{
          __typename?: 'Address';
          type?: string | null;
          lines?: Array<string | null> | null;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
        } | null> | null;
        phones?: Array<{
          __typename?: 'Phone';
          type?: string | null;
          number?: string | null;
        } | null> | null;
      } | null;
      providerOrganization?: {
        __typename?: 'ProviderOrganization';
        name?: string | null;
        groupNumber?: string | null;
        serviceTypeCode?: string | null;
        assignmentType?: Array<string | null> | null;
        addresses?: Array<{
          __typename?: 'Address';
          type?: string | null;
          lines?: Array<string | null> | null;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
        } | null> | null;
        phones?: Array<{
          __typename?: 'Phone';
          number?: string | null;
          type?: string | null;
        } | null> | null;
      } | null;
    } | null;
    serviceLevelAccumulation?: {
      __typename?: 'ServiceLevelAccumulation';
      services?: Array<{
        __typename?: 'Service';
        serviceTypeCode?: string | null;
        serviceTypeDesc?: string | null;
        specialtyVariation?: string | null;
        variations?: Array<{
          __typename?: 'Variation';
          diagnosisCode?: string | null;
          diagnosisDesc?: string | null;
          shortDesc?: string | null;
          benefitServiceId?: string | null;
          serviceName?: string | null;
          tags?: Array<string | null> | null;
          posCode?: string | null;
          posDesc?: string | null;
          procedureCode?: string | null;
          procedureDesc?: string | null;
          priorAuthorizationRequired?: PriorAuthRequiredGQL | null;
          notes?: Array<string | null> | null;
          specialty?: string | null;
          benefits?: string | null;
          isPriorAuthRequired?: Array<{
            __typename?: 'AuthRequiredData';
            networkType?: string | null;
            required?: string | null;
            authorizationProvider?: string | null;
            reasonProAuth?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    maternityBenefitDetails?: {
      __typename?: 'MaternityBenefitDetails';
      healthWellnessProgram?: string | null;
      newBornChargeType?: string | null;
      newBornCoverageDuration?: string | null;
      newBornCoverageProvision?: string | null;
      newBornCoverageType?: string | null;
      hasMaternity?: boolean | null;
    } | null;
    cobDetails?: Array<{
      __typename?: 'CobDetail';
      policyNo?: string | null;
      verifiedOn?: string | null;
      effectiveFrom?: string | null;
      effectiveTill?: string | null;
      carrier?: string | null;
      coverageType?: string | null;
      primacy?: string | null;
    } | null> | null;
    notificationDetail?: {
      __typename?: 'NotificationDetail';
      mmmInd?: string | null;
      displayOutPatientPreCertMngmtLevels?: boolean | null;
      indemnity?: boolean | null;
      prodInNetwork?: boolean | null;
      surefitEast?: boolean | null;
      surefitWest?: boolean | null;
      routerUrl?: string | null;
      routerUrlInput?: string | null;
      outPatientPreCertMngmtLevelList?: Array<{
        __typename?: 'NotificationData';
        inNetworkValue?: string | null;
        label?: string | null;
        outNetworkValue?: string | null;
      } | null> | null;
      carveOutInfo?: {
        __typename?: 'CarveOutInfo';
        id?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
        carveOutMessage?: string | null;
        entTypeQual?: string | null;
        idCodeQual?: string | null;
        serviceTypeCode?: string | null;
        suffix?: string | null;
        address?: string | null;
        contact?: string | null;
      } | null;
      notificationData?: Array<{
        __typename?: 'NotificationData';
        inNetworkValue?: string | null;
        label?: string | null;
        outNetworkValue?: string | null;
      } | null> | null;
    } | null;
    pharmacyBenefitDetails?: {
      __typename?: 'PharmacyBenefitDetails';
      sourceSystemCode?: string | null;
      enableAccredoLink?: string | null;
      pharmacyBenefits?: Array<{
        __typename?: 'PharmacyBenefit';
        benefitLabels?: Array<string | null> | null;
        accumumatorIndicators?: string | null;
        benefitSvcCode?: string | null;
        buyUpMsg?: string | null;
        carveoutVendorName?: string | null;
        classCode?: string | null;
        combDedAccum?: string | null;
        combDedOopInd?: string | null;
        errorCode?: string | null;
        eshdRetlFillLmt?: string | null;
        fills30DayPermtdNum?: string | null;
        footnote?: string | null;
        mailOrderSpecialityDrugDays?: string | null;
        mailOrderSupplyDays?: string | null;
        mndtry90DayInd?: string | null;
        noOfRefillsPermitted?: string | null;
        nonSpltyProgTypeCodes?: string | null;
        planCombMedRxInd?: string | null;
        planType?: string | null;
        prescriptionDrugListCode?: string | null;
        retail90DayProgramType?: string | null;
        retailSupplyDays?: string | null;
        retl90DaySuplyNum?: string | null;
        rxAnnAccumCode?: string | null;
        rxApplMOInd?: string | null;
        rxBuyUp?: string | null;
        rxCarveOutVendorName?: string | null;
        rxClinicalProgCodePA?: string | null;
        rxClinicalProgCodeTE?: string | null;
        rxClmCoinsPct?: string | null;
        rxDedAccumCode?: string | null;
        rxExclDedInd?: string | null;
        rxMedAccessOptCode?: string | null;
        rxOopAccumCode?: string | null;
        rxPlanDesignType?: string | null;
        rxPlanType?: string | null;
        spltyProgTypeCodes?: string | null;
        stateCode?: string | null;
        subClassCode?: string | null;
        fundingArrangementType?: string | null;
        situsStateCode?: string | null;
        progTypeUMCodes?: string | null;
        isInNetworkOnly?: boolean | null;
        isOutNetworkOnly?: boolean | null;
        isRetail90DayCoverage?: boolean | null;
        isRxDedInd?: boolean | null;
        isRxMaxInd?: boolean | null;
        isErisaApplicable?: boolean | null;
        drugListURL?: string | null;
        planAmountList?: Array<{
          __typename?: 'PlanAmount';
          featureType?: string | null;
          inNetworkValue?: string | null;
          outNetworkValue?: string | null;
          featureTypeDesc?: string | null;
        } | null> | null;
        tierBenefitsList?: Array<{
          __typename?: 'TierBenefit';
          tierType?: string | null;
          tierTypeDesc?: string | null;
          tiersList?: Array<{
            __typename?: 'TierList';
            tierNumber?: string | null;
            tierCopayTimePeriod?: string | null;
            tierValue?: string | null;
            tierLabel?: string | null;
          } | null> | null;
        } | null> | null;
        coverageDetailSummary?: {
          __typename?: 'CoverageDetailSummary';
          effectiveDate?: string | null;
          cancelDate?: string | null;
          coverageType?: string | null;
          identifier?: string | null;
          clientId?: string | null;
          clientName?: string | null;
          groupNumber?: string | null;
          groupName?: string | null;
          locationCode?: string | null;
          productType?: string | null;
          familyCode?: string | null;
          embeddedCode?: string | null;
          isRetired?: string | null;
          networkId?: string | null;
          networkType?: string | null;
          isIFP?: boolean | null;
          branchCode?: string | null;
          benOptCode?: string | null;
          coverageStatus?: string | null;
          fromIBORCoverage?: boolean | null;
          clinicalSystemLocations?: string | null;
          claimRoutings?: Array<{
            __typename?: 'ClaimRouting';
            claimSystemCode?: string | null;
            memKeyType?: string | null;
            memKeyValue?: string | null;
          } | null> | null;
          complianceTypes?: {
            __typename?: 'ComplianceTypes';
            complianceType?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    productDetails?: {
      __typename?: 'PatientProductDetail';
      typeCode?: string | null;
      familyCode?: string | null;
      hraFundBalance?: string | null;
      isV2V?: boolean | null;
      isIFP?: boolean | null;
      hasHRA?: boolean | null;
      hasHSA?: boolean | null;
      hasFSA?: boolean | null;
      name?: string | null;
      embeddedProducts?: Array<{
        __typename?: 'PatientProductEmbedded';
        productCode?: string | null;
        effectiveOn?: string | null;
        endsOn?: string | null;
      } | null> | null;
    } | null;
    frequencyAgePlanDetails?: {
      __typename?: 'FrequencyAgePlanDetails';
      showBenefitPDF?: boolean | null;
      benefitPDFName?: string | null;
      specificCoverage?: {
        __typename?: 'SpecificCoverage';
        frequencyAgeLimitType?: string | null;
        frequencyAgeLimitCoverageList?: Array<{
          __typename?: 'FrequencyCoverageItem';
          code?: string | null;
          label?: string | null;
          inNVal?: string | null;
          ooNVal?: string | null;
          freqAgeLimitTxt?: string | null;
        } | null> | null;
      } | null;
      procedureList?: Array<{
        __typename?: 'ProcedureListItem';
        code?: string | null;
        label?: string | null;
        inNVal?: string | null;
        ooNVal?: string | null;
        indmnVal?: string | null;
        freqAgeLimitTxt?: string | null;
      } | null> | null;
    } | null;
    visionServices?: {
      __typename?: 'VisionServices';
      hasCoreBenefits?: boolean | null;
      standAloneVision?: boolean | null;
      vendorName?: string | null;
      services?: Array<{
        __typename?: 'VisionServicesDetails';
        name?: string | null;
        coinsurance?: string | null;
        coPayment?: string | null;
        maximumVisits?: string | null;
        maximumAmount?: string | null;
        frequency?: string | null;
      } | null> | null;
    } | null;
    infertilityServices?: {
      __typename?: 'InfertilityServices';
      isUnlimitedExists?: boolean | null;
      services?: Array<{
        __typename?: 'InfertilityServicesDetails';
        category?: string | null;
        inNetworkValue?: string | null;
        outNetworkValue?: string | null;
      } | null> | null;
    } | null;
    reference?: {
      __typename?: 'BenefitReferenceNumber';
      brn?: string | null;
      sso?: string | null;
      created?: string | null;
      benefits?: Array<string | null> | null;
      notes?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      status?: string | null;
      asOfDate?: string | null;
      lastModified?: string | null;
      compositeKey?: string | null;
      coverage?: string | null;
    } | null;
    runtimeError?: {
      __typename?: 'BrnRuntimeError';
      errMsg?: string | null;
      status?: string | null;
    } | null;
  } | null;
};

export const DashboardClaimsDocumentGQL = gql`
  query DashboardClaims {
    recentClaims {
      tinNumbers
      member {
        memberId
        memberFirstName
        memberLastName
      }
      claimSummary {
        chargeAmount
        claimCompositeKey
        claimReferenceNumber
        compositeClaimId
        claimStatus
        dateOfService
        medicare
        providerGeneratedAccountNumber
      }
    }
    flaggedClaims {
      id
      tinNumbers
      dateFlagged
      member {
        memberId
        memberFirstName
        memberLastName
      }
      claimSummary {
        chargeAmount
        claimCompositeKey
        claimReferenceNumber
        compositeClaimId
        claimStatus
        dateOfService
        medicare
        providerGeneratedAccountNumber
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DashboardClaimsGQLService extends Apollo.Query<
  DashboardClaimsQueryGQL,
  DashboardClaimsQueryVariablesGQL
> {
  document = DashboardClaimsDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ClaimDetailNewDocumentGQL = gql`
  query ClaimDetailNew($input: ClaimDetailInput!) {
    claimByCompositeKey(input: $input) {
      detail {
        correspondenceHistory {
          claimId
          corresType
          documentLink
          generatedOn
          subject
          docId
        }
        remittanceSummaryData {
          isNPINumber
          isZelis
          additionalDetails {
            code
            message
            severity
          }
          remittanceData {
            documentType
            remittanceTrackingNumber
            creationDate
            creationDateStr
            paymentDate
            processedDate
            generatedDate
            reportCategory
            readRemittance
            taxId
            npiList
            depositDate
            depositDateStr
            depositAmount
            depositAmountStr
            mbrLastName
            mbrFirstName
            mbrDateofBirth
            productType
            expirationDate
            documentTypeStr
            numberOfPages
            links
            pagesList
            dataPowerLink
            docId
            controlStr
            ddarMaxpages
            isZelis
          }
          paymentInfo {
            eftExists
            chkExists
            payeeName
            payeeAddress
            checkAmount
            checkNumber
            checkStatus
            checkIssuedDate
            checkClearedDate
            paymentMethod
            sourceDataKey
            remittanceNumber
          }
        }
        memberObject {
          memberId
          memberDateOfBirth
          memberFirstName
          memberLastName
          relationShipCode
          memberIdentifier
          hasBehavioral
          patientId
        }
        claimInfoObject {
          serviceProvider
          renderingProviderId
          renderingProviderAddressState
          claimDateOfService
          claimTotChargeAmount
          claimReferenceNumber
          compositeClaimId
          claimDetailsInfoMessages {
            claimInfoText
          }
          groupNumber
          coverageCompositeKey
          uploadUrl
          isWebster
          isDDAR
          isDOSEstimate
          claimEngineClaimID
          networkPPOIndicator
          firstName
          lastName
          dateOfBirth
          ssn
          ami
          idCardExtensionCode
          providerGeneratedAccountNumber
          isBehavioral
          claimReceivedDate
          claimProcessedDate
          claimDrgUsedCode
          patientResponsibility
          claimTotPaidAmount
          additionalRemarks
          footNoteMessages
          isZelis
          showPaymentInfo
          remarkCodes {
            remarkCode
            desc
          }
          claimAmountDue
          recoupedAmount
          claimAmountPaid
          paymentProviderAmt
          hsaAmount
          hraAmount
          fsaAmount
          haaAmount
          hfAmount
          lpAmount
          claimStatusDesc
          claimStatusCodes {
            statusCodeValue
            statusCodeDesc
            reasonCodeValue
            reasonCodeDesc
          }
          sourceSystemCode
          overPaidClaimsInfo {
            claimReferenceNumber
            patientAccountNumber
            claimPaidDate
            recoupmentAmount
            recoupmentBalance
            showLink
            tin
            claimCompositeKey
            compositeClaimId
          }
          payeeInfo {
            eftExists
            chkExists
            payeeName
            payeeAddress
            checkAmount
            checkNumber
            checkStatus
            checkIssuedDate
            checkClearedDate
            paymentMethod
            sourceDataKey
            remittanceNumber
          }
          payeePaymentInfoMessages {
            paymentInfoText
          }
          paymentInfoMessages {
            label
            value
          }
          serviceLineInfoArray {
            id
            dateOfService
            cdtCode
            procedureCode
            toothNumber
            amountCharged
            svcLineContractedAmount
            svcLinePaidAmount
            placeOfService
            allowedAmount
            serviceLineApcCode
            amountNotCovered
            deductible
            coveredBalance
            planCoInsurancePer
            memberCoInsurancePer
            memberCoInsu
            memberResponsibility
            patientCoinsuranceResponsibility
            copay
            remarkCode
            propRemarkDescription
            hraPayment
            hsaPayment
            fsaPayment
            haPayment
            amountNotAllowed
          }
          serviceLineTotalsObject {
            amountChargedTotal
            allowedAmountTotal
            amountNotCoveredTotal
            deductibleTotal
            coveredBalTotal
            contractedAmountTotal
            coordinationBenefitTotal
            planCoinsurancePaidTotal
            coPayTotal
            memberCoInsuTotal
            memberResponseTotal
            paidFromHATotal
            paidFromHSATotal
            paidFromHRATotal
            paidFromFSATotal
          }
          explanationOfRemarkCodes {
            remarkCode
            remarkCodeDesc
            additionalDesc
          }
        }
        claimCompositeKey
        medicare
        isFlagged
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ClaimDetailNewGQLService extends Apollo.Query<
  ClaimDetailNewQueryGQL,
  ClaimDetailNewQueryVariablesGQL
> {
  document = ClaimDetailNewDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DashboardPatientsDocumentGQL = gql`
  query DashboardPatients {
    recentPatients {
      ami
      asOfDate
      coverage {
        account
        clientName
        compositeKey
        coverageFrom
        coverageStatus
        coverageTo
        coverageType
        isEffectivelyTermed
        isInactive
        showLink
      }
      dob
      firstName
      lastName
      identifier
      patientId
    }
    flaggedPatients {
      ami
      asOfDate
      coverage {
        account
        clientName
        compositeKey
        coverageFrom
        coverageStatus
        coverageTo
        coverageType
        isEffectivelyTermed
        isInactive
        showLink
      }
      dateFlagged
      dob
      firstName
      lastName
      patientId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DashboardPatientsGQLService extends Apollo.Query<
  DashboardPatientsQueryGQL,
  DashboardPatientsQueryVariablesGQL
> {
  document = DashboardPatientsDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DashboardPrecertificationsDocumentGQL = gql`
  query DashboardPrecertifications {
    recentPrecertifications {
      precertNumber
      payload {
        member {
          memberId
          patientSSN
          patientFirstName
          patientLastName
          claimSystemCode
          sourceSystemCode
          coverage
          asOfDate
          patientPlan
        }
        precertificaton {
          precertNumber
          status
          startDate
          endDate
          precertSource
        }
        providerDetails {
          requestingProvider {
            tinNumber
            name
          }
          servicingProvider {
            tinNumber
            name
          }
        }
        compositeKey
      }
      expirationTime
      createdDt
    }
    flaggedPrecertifications {
      precertNumber
      payload {
        member {
          memberId
          patientSSN
          patientFirstName
          patientLastName
          claimSystemCode
          sourceSystemCode
          coverage
          asOfDate
          patientPlan
        }
        precertificaton {
          precertNumber
          status
          startDate
          endDate
          precertSource
        }
        providerDetails {
          requestingProvider {
            tinNumber
            name
          }
          servicingProvider {
            tinNumber
            name
          }
        }
        compositeKey
      }
      expirationTime
      createdDt
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DashboardPrecertificationsGQLService extends Apollo.Query<
  DashboardPrecertificationsQueryGQL,
  DashboardPrecertificationsQueryVariablesGQL
> {
  document = DashboardPrecertificationsDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FlaggedPatientIdsDocumentGQL = gql`
  query FlaggedPatientIds {
    flaggedPatients {
      coverage {
        compositeKey
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FlaggedPatientIdsGQLService extends Apollo.Query<
  FlaggedPatientIdsQueryGQL,
  FlaggedPatientIdsQueryVariablesGQL
> {
  document = FlaggedPatientIdsDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FlaggedClaimIdsDocumentGQL = gql`
  query FlaggedClaimIds {
    flaggedClaims {
      id
      claimSummary {
        claimReferenceNumber
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FlaggedClaimIdsGQLService extends Apollo.Query<
  FlaggedClaimIdsQueryGQL,
  FlaggedClaimIdsQueryVariablesGQL
> {
  document = FlaggedClaimIdsDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DashboardWamUsersDocumentGQL = gql`
  query DashboardWamUsers($input: WamUserSearchInput) {
    wamDashboardUsers(input: $input) {
      total
      users {
        firstName
        lastName
        id
        temporaryUserName
        temporaryPassword
        email
        phone
        jobRole
        tins {
          tin
          name
          status
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DashboardWamUsersGQLService extends Apollo.Query<
  DashboardWamUsersQueryGQL,
  DashboardWamUsersQueryVariablesGQL
> {
  document = DashboardWamUsersDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DashboardWamUserDetailDocumentGQL = gql`
  query DashboardWamUserDetail($input: WamUserSearchInput!) {
    wamDashboardUsers(input: $input) {
      users {
        firstName
        lastName
        id
        tinsWithEntitlements {
          tin
          name
          status
          lob
          entitlements {
            name
            description
            functions {
              name
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DashboardWamUserDetailGQLService extends Apollo.Query<
  DashboardWamUserDetailQueryGQL,
  DashboardWamUserDetailQueryVariablesGQL
> {
  document = DashboardWamUserDetailDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PatientDetailsDocumentGQL = gql`
  query PatientDetails($patientCompositeKeyInput: PatientCompositeKeyInput!) {
    patientByCompositeKey(input: $patientCompositeKeyInput) {
      asOfDate
      detail {
        alliances {
          hasMVP
          partners {
            roleOfCigna
            effectiveOn
            endsOn
            coordinatingParty
            supportingParty
            thirdPartyAdmin
          }
        }
        cobDetails {
          policyNo
          verifiedOn
          effectiveFrom
          effectiveTill
          carrier
          coverageType
          primacy
        }
        subscriberDetails {
          firstName
          middleName
          lastName
          fullName
          dob
          gender
          internalProcessingId
        }
        serviceContact {
          type
          name
          addresses {
            city
            country
            lines
            state
            type
            zip
          }
          phones {
            number
            type
          }
          id
          effectiveDate
          group
          web
          webName
        }
        coverageDetails {
          accountNumber
          accountName
          clientId
          effectiveFrom
          effectiveTill
          initialCoverageDate
          coverageEndDate
          status
          coverageStatus
          hasPRN
          hasPDP
          hasIFP
          hasV2V
          hasCSN
          hasMHSR
          hasBayCareGroup
          coverageType
          networkDescription
          otherInsurance
          otherInsuranceVerifiedOn
          pediatricInd
          utilizationDataNotAvlble
          isMultipleCoverage
          hasMultipleActive
          hasCoveredBenefits
          coverages
          isAccountRestricted
          medicalManagementPlan
          medicare {
            contractNumber
            groupNumber
            planBenefitPackageNumber
            segmentNumber
            healthPlanName
            operationalMarket
            regulatoryMarket
            specialNeedPlanType
            egwpIndicator
            cobDetails {
              policyNo
              verifiedOn
              effectiveFrom
              effectiveTill
              carrier
              coverageType
              primacy
            }
            pcpRequired
            familyCode
          }
          networkDetails {
            inOutIndicator
            networkId
            networkName
            networkType
            effectiveFrom
            effectiveTill
            superNetworkId
            npoId
            npoName
            mpoId
            mpoName
            porgId
            seamlessIndicator
            baseNetworkId
            iCareNetworkId
            dnspEligible
            tier {
              tier1
              tier2
            }
            pcp {
              providerIdentity {
                id
                type
              }
              pcpStatus
              hasPCP
              assignmentType
              effectiveDate
              cancelDate
            }
            hmoDetails {
              orgName
              code
              networkLob
              lob
              networkId
              networkIdCode
              networkDesc
              contact {
                name
                addresses {
                  city
                  country
                  lines
                  state
                  type
                  zip
                }
                phones {
                  number
                  type
                }
                type
              }
            }
            isAllegianceMember
            isHAPMember
            isNetworkTiered
            networkCategoryCode
            ecnNetworkDetails {
              csnNetworkDetails {
                csnNetwork {
                  csnId
                  displayOrderCd
                  displayOrderDesc
                  tierName
                }
              }
              ecnId
            }
          }
          referralCode
          hasConflictingStatus
          erisaApplicable
          ohip
          umAuthSystem
          claimSystemCode
          visionSourceCd
          plan {
            effectiveFrom
            effectiveTill
            type
            fundingType
            isIndemnity
            progressiveRegressiveInd
            deductibleSequence
            benefitOption
            branch
            rxCarveOutVendor
            classNumber
            planVariationIdentifier
            pathwellIndicator
            situsStateIndicator
            planYears {
              effectiveFrom
              effectiveTill
              renewalType
            }
          }
        }
        patientDetails {
          firstName
          middleName
          lastName
          fullName
          dob
          gender
          internalProcessingId
          addresses {
            city
            country
            lines
            state
            type
            zip
          }
          eligibleForCostEstimation
          id
          idCardExtensionCode
          identifier
          isInternationalMember
          isPolicyHolder
          relationship
        }
        productDetails {
          typeCode
          familyCode
          hraFundBalance
          isV2V
          isIFP
          hasHRA
          hasHSA
          hasFSA
          embeddedProducts {
            productCode
            effectiveOn
            endsOn
          }
          name
        }
        rewardDetails {
          indicator
          typeName
        }
        feeSchedule {
          indicator
          typeName
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PatientDetailsGQLService extends Apollo.Query<
  PatientDetailsQueryGQL,
  PatientDetailsQueryVariablesGQL
> {
  document = PatientDetailsDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PatientPcpDocumentGQL = gql`
  query PatientPcp(
    $patientCompositeKeyInput: PatientCompositeKeyInput!
    $pcpDetailsInput: PcpDetailsInput
  ) {
    patientByCompositeKey(input: $patientCompositeKeyInput) {
      primaryCareDetails(input: $pcpDetailsInput) {
        hasPCP
        pcpStatus
        primaryCarePhysician {
          firstName
          middleName
          lastName
          fullName
          addresses {
            type
            lines
            city
            country
            state
            zip
          }
          phones {
            number
            type
          }
          effectiveFrom
          effectiveTill
          assignmentType
        }
        providerOrganization {
          name
          groupNumber
          serviceTypeCode
          addresses {
            type
            lines
            city
            country
            state
            zip
          }
          phones {
            number
            type
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PatientPcpGQLService extends Apollo.Query<
  PatientPcpQueryGQL,
  PatientPcpQueryVariablesGQL
> {
  document = PatientPcpDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PatientAccumsDocumentGQL = gql`
  query PatientAccums(
    $patientCompositeKeyInput: PatientCompositeKeyInput!
    $planLevelAccumInput: PlanLevelAccumInput
  ) {
    patientByCompositeKey(input: $patientCompositeKeyInput) {
      planLevelAccumulation(input: $planLevelAccumInput) {
        type
        notes
        benefits {
          deductible {
            accumulations {
              amount
              met
              remaining
              covers
              notes
              cignaCareNetwork
              tier
              desc
              networkType
              coveredServices
              tierIndex
              productType
              planPeriodCode
              networkTierLabelName
              networkTierDisplayOrderCdDescription
              networkTierDisplayOrderCd
              networkId
              networkName
            }
          }
          moop {
            accumulations {
              amount
              met
              remaining
              covers
              notes
              cignaCareNetwork
              tier
              desc
              networkType
              coveredServices
              tierIndex
              productType
              planPeriodCode
              networkTierLabelName
              networkTierDisplayOrderCdDescription
              networkTierDisplayOrderCd
              networkId
              networkName
            }
          }
          coinsurance {
            accumulations {
              amount
              met
              remaining
              covers
              notes
              cignaCareNetwork
              tier
              desc
              networkType
              coveredServices
              tierIndex
              productType
              planPeriodCode
              networkTierLabelName
              networkTierDisplayOrderCdDescription
              networkTierDisplayOrderCd
              networkId
              networkName
            }
          }
          lifeTimeMaximum {
            accumulations {
              amount
              met
              remaining
              covers
              notes
              cignaCareNetwork
              tier
              desc
              networkType
              coveredServices
              tierIndex
              productType
              planPeriodCode
              networkTierLabelName
              networkTierDisplayOrderCdDescription
              networkTierDisplayOrderCd
              networkId
              networkName
            }
          }
          outOfPocketMaximum {
            accumulations {
              amount
              met
              remaining
              covers
              notes
              cignaCareNetwork
              tier
              desc
              networkType
              coveredServices
              tierIndex
              productType
              planPeriodCode
              networkTierLabelName
              networkTierDisplayOrderCdDescription
              networkTierDisplayOrderCd
              networkId
              networkName
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PatientAccumsGQLService extends Apollo.Query<
  PatientAccumsQueryGQL,
  PatientAccumsQueryVariablesGQL
> {
  document = PatientAccumsDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PatientGeneralBenefitsMedicareDocumentGQL = gql`
  query PatientGeneralBenefitsMedicare(
    $patientCompositeKeyInput: PatientCompositeKeyInput!
    $medicareMedicalDetailInput: MedicareMedicalDetailInput
  ) {
    patientByCompositeKey(input: $patientCompositeKeyInput) {
      medicareMedicalDetail(input: $medicareMedicalDetailInput) {
        benefitOptionCode
        benefitPlanId
        contractNumber
        egwpAccountNumber
        id
        networkCategoryKeyCode
        pbpNumber
        planKey
        planName
        planType
        segmentNumber
        benefitDetails {
          categories {
            benefits {
              costShare {
                code
                description {
                  type
                  value
                }
                order
                showPartBDeductible
                value
              }
              description {
                type
                value
              }
              pbpCategoryId
              name
              order
            }
            categoryDescription {
              type
              value
            }
            categoryName
            order
            pbpCategoryId
          }
          productBenefitType
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PatientGeneralBenefitsMedicareGQLService extends Apollo.Query<
  PatientGeneralBenefitsMedicareQueryGQL,
  PatientGeneralBenefitsMedicareQueryVariablesGQL
> {
  document = PatientGeneralBenefitsMedicareDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FlagPatientUpdateDocumentGQL = gql`
  mutation FlagPatientUpdate(
    $patientFlags: [FlagPatientInput]!
    $type: FlagType!
  ) {
    flagPatientsUpdate(patients: $patientFlags, type: $type) {
      ami
      asOfDate
      coverage {
        account
        clientName
        compositeKey
        coverageFrom
        coverageStatus
        coverageTo
        coverageType
        isEffectivelyTermed
        isInactive
        showLink
      }
      dateFlagged
      dob
      firstName
      lastName
      patientId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FlagPatientUpdateGQLService extends Apollo.Mutation<
  FlagPatientUpdateMutationGQL,
  FlagPatientUpdateMutationVariablesGQL
> {
  document = FlagPatientUpdateDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FlagClaimUpdateDocumentGQL = gql`
  mutation FlagClaimUpdate($claimsFlags: [FlagClaimInput]!, $type: FlagType!) {
    flagClaimsUpdate(claims: $claimsFlags, type: $type) {
      id
      tinNumbers
      dateFlagged
      member {
        memberId
        memberFirstName
        memberLastName
      }
      claimSummary {
        chargeAmount
        claimCompositeKey
        claimReferenceNumber
        compositeClaimId
        claimStatus
        dateOfService
        medicare
        providerGeneratedAccountNumber
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FlagClaimUpdateGQLService extends Apollo.Mutation<
  FlagClaimUpdateMutationGQL,
  FlagClaimUpdateMutationVariablesGQL
> {
  document = FlagClaimUpdateDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SaveRecentClaimDocumentGQL = gql`
  mutation SaveRecentClaim($claim: RecentClaimInput!) {
    saveRecentClaim(claim: $claim) {
      tinNumbers
      member {
        memberId
        memberFirstName
        memberLastName
        memberSearchKey
      }
      claimSummary {
        chargeAmount
        claimCompositeKey
        claimReferenceNumber
        compositeClaimId
        claimStatus
        dateOfService
        medicare
        providerGeneratedAccountNumber
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SaveRecentClaimGQLService extends Apollo.Mutation<
  SaveRecentClaimMutationGQL,
  SaveRecentClaimMutationVariablesGQL
> {
  document = SaveRecentClaimDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FlagPrecertificationUpdateDocumentGQL = gql`
  mutation FlagPrecertificationUpdate(
    $precertInput: PrecertInput!
    $type: FlagType!
  ) {
    flagPrecertificationUpdate(precert: $precertInput, type: $type) {
      precertNumber
      payload {
        member {
          memberId
          patientSSN
          patientFirstName
          patientLastName
          claimSystemCode
          sourceSystemCode
          coverage
          asOfDate
          patientPlan
        }
        precertificaton {
          precertNumber
          status
          startDate
          endDate
          precertSource
        }
        providerDetails {
          requestingProvider {
            tinNumber
            id
            name
          }
          servicingProvider {
            tinNumber
            id
            name
          }
        }
        compositeKey
      }
      expirationTime
      createdDt
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FlagPrecertificationUpdateGQLService extends Apollo.Mutation<
  FlagPrecertificationUpdateMutationGQL,
  FlagPrecertificationUpdateMutationVariablesGQL
> {
  document = FlagPrecertificationUpdateDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SaveRecentPrecertificationDocumentGQL = gql`
  mutation SaveRecentPrecertification($precertInput: PrecertInput!) {
    saveRecentPrecertification(precert: $precertInput) {
      precertNumber
      payload {
        member {
          memberId
          patientSSN
          patientFirstName
          patientLastName
          claimSystemCode
          sourceSystemCode
          coverage
          asOfDate
          patientPlan
        }
        precertificaton {
          precertNumber
          status
          startDate
          endDate
          precertSource
        }
        providerDetails {
          requestingProvider {
            tinNumber
            id
            name
          }
          servicingProvider {
            tinNumber
            id
            name
          }
        }
        compositeKey
      }
      expirationTime
      createdDt
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SaveRecentPrecertificationGQLService extends Apollo.Mutation<
  SaveRecentPrecertificationMutationGQL,
  SaveRecentPrecertificationMutationVariablesGQL
> {
  document = SaveRecentPrecertificationDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetBrnDetailsDocumentGQL = gql`
  query GetBrnDetails($input: BrnDetailsInput!) {
    brnDetails(input: $input) {
      alliances {
        hasMVP
        partners {
          roleOfCigna
          effectiveOn
          endsOn
          coordinatingParty
          supportingParty
          thirdPartyAdmin
        }
      }
      ccfBenefit {
        footNotes
        ccfType
        ccfbenefitFundList {
          carryOverAmount
          carryOverPercentage
          currentAmount
          fundType
          goalAmount
          note
          note2
          fundTypeWithValues
          coverageList {
            eligibilityServiceType
            eligibilityServiceValue
            label
            type
          }
        }
      }
      coverageDetail {
        accountNumber
        accountName
        clientId
        effectiveFrom
        effectiveTill
        initialCoverageDate
        status
        coverageStatus
        hasPRN
        hasPDP
        hasIFP
        hasCSN
        hasMHSR
        hasBayCareGroup
        coverageType
        networkDescription
        otherInsurance
        otherInsuranceVerifiedOn
        pediatricInd
        utilizationDataNotAvlble
        isMultipleCoverage
        hasMultipleActive
        hasCoveredBenefits
        coverages
        isAccountRestricted
        medicalManagementPlan
        referralCode
        hasConflictingStatus
        erisaApplicable
        ohip
        umAuthSystem
        claimSystemCode
        plan {
          effectiveFrom
          effectiveTill
          type
          fundingType
          isIndemnity
          progressiveRegressiveInd
          deductibleSequence
          benefitOption
          branch
          rxCarveOutVendor
          classNumber
          planVariationIdentifier
          planYears {
            effectiveFrom
            effectiveTill
            renewalType
          }
          pathwellIndicator
        }
        networkDetails {
          networkId
          networkName
          networkType
          effectiveFrom
          effectiveTill
          npoId
          npoName
          mpoId
          mpoName
          porgId
          seamlessIndicator
          baseNetworkId
          iCareNetworkId
          hmoDetails {
            orgName
            code
            networkLob
            lob
            networkId
            networkIdCode
            networkDesc
            contact {
              type
              name
              addresses {
                type
                lines
                city
                state
                zip
                country
              }
              phones {
                number
                type
              }
            }
          }
          isAllegianceMember
          isHAPMember
          inOutIndicator
          dnspEligible
          isNetworkTiered
          superNetworkId
          tier {
            tier1
            tier2
          }
          pcp {
            providerIdentity {
              id
              type
            }
            pcpStatus
            hasPCP
            assignmentType
            cancelDate
            effectiveDate
          }
          ecnNetworkDetails {
            csnNetworkDetails {
              csnNetwork {
                csnId
                displayOrderCd
                displayOrderDesc
                tierName
              }
            }
            ecnId
          }
        }
        medicare {
          contractNumber
          planBenefitPackageNumber
          segmentNumber
          healthPlanName
          groupNumber
          operationalMarket
          regulatoryMarket
          specialNeedPlanType
          egwpIndicator
          cobDetails {
            policyNo
            verifiedOn
            effectiveFrom
            effectiveTill
            carrier
            coverageType
            primacy
          }
          pcpRequired
          familyCode
        }
      }
      idcardDetail {
        accountID
        backImageFlag
        backImageGUID
        backImageUrl
        benOptionCode
        cardType
        claimSystemCode
        contentSearchBeansList
        coverageFromDate
        frontImageFlag
        frontImageGUID
        frontImageUrl
        idCardExtensionCode
        idNumber
        isIFP
        issueDate
        medicalPlan
        memberCoverageDate
        patientID
        patientName
        pharmacyAvailable
        planName
        productType
        relationshipCode
        selectPharmacyAvailable
      }
      subscriberDetail {
        firstName
        middleName
        lastName
        fullName
        dob
        gender
        internalProcessingId
      }
      patientDetail {
        details {
          firstName
          middleName
          lastName
          fullName
          dob
          gender
          internalProcessingId
          addresses {
            type
            lines
            city
            state
            zip
            country
          }
          eligibleForCostEstimation
          id
          idCardExtensionCode
          identifier
          isInternationalMember
          isPolicyHolder
          relationship
        }
        asOfDate
      }
      dentalOfficeDetails {
        effectiveFrom
        effectiveTill
        id
        name
      }
      serviceContact {
        type
        name
        addresses {
          type
          lines
          city
          state
          zip
          country
        }
        phones {
          number
          type
        }
        id
        effectiveDate
        group
        web
        webName
      }
      planLevelAccumulation {
        type
        notes
        benefits {
          coinsurance {
            accumulations {
              desc
              amount
              met
              remaining
              covers
              networkType
              notes
              cignaCareNetwork
              coveredServices
              tierIndex
              tier
              productType
              networkName
              networkId
              networkTierDisplayOrderCd
              networkTierDisplayOrderCdDescription
              networkTierLabelName
              planPeriodCode
            }
          }
          deductible {
            accumulations {
              desc
              amount
              met
              remaining
              covers
              networkType
              notes
              cignaCareNetwork
              coveredServices
              tierIndex
              tier
              productType
              networkName
              networkId
              networkTierDisplayOrderCd
              networkTierDisplayOrderCdDescription
              networkTierLabelName
              planPeriodCode
            }
          }
          lifeTimeMaximum {
            accumulations {
              desc
              amount
              met
              remaining
              covers
              networkType
              notes
              cignaCareNetwork
              coveredServices
              tierIndex
              tier
              productType
              networkName
              networkId
              networkTierDisplayOrderCd
              networkTierDisplayOrderCdDescription
              networkTierLabelName
              planPeriodCode
            }
          }
          outOfPocketMaximum {
            accumulations {
              desc
              amount
              met
              remaining
              covers
              networkType
              notes
              cignaCareNetwork
              coveredServices
              tierIndex
              tier
              productType
              networkName
              networkId
              networkTierDisplayOrderCd
              networkTierDisplayOrderCdDescription
              networkTierLabelName
              planPeriodCode
            }
          }
          moop {
            accumulations {
              desc
              amount
              met
              remaining
              covers
              networkType
              notes
              cignaCareNetwork
              coveredServices
              tierIndex
              tier
              productType
              networkName
              networkId
              networkTierDisplayOrderCd
              networkTierDisplayOrderCdDescription
              networkTierLabelName
              planPeriodCode
            }
          }
        }
      }
      benefitReportDetails {
        patientChargeSheet {
          fileSeqNum
          ctrl
          docName
          docTypCode
          expireDt
          fileTypeCode
        }
        stateBenefitInfo {
          fileSeqNum
          ctrl
          docName
          docTypCode
          expireDt
          fileTypeCode
        }
        showBenefitsInd
      }
      primaryCareDetails {
        pcpStatus
        hasPCP
        primaryCarePhysician {
          firstName
          lastName
          middleName
          fullName
          addresses {
            type
            lines
            city
            state
            zip
            country
          }
          phones {
            type
            number
          }
          effectiveFrom
          effectiveTill
          assignmentType
        }
        providerOrganization {
          name
          groupNumber
          serviceTypeCode
          addresses {
            type
            lines
            city
            state
            zip
            country
          }
          phones {
            number
            type
          }
          assignmentType
        }
      }
      serviceLevelAccumulation {
        services {
          serviceTypeCode
          serviceTypeDesc
          variations {
            diagnosisCode
            diagnosisDesc
            shortDesc
            benefitServiceId
            serviceName
            tags
            posCode
            posDesc
            procedureCode
            procedureDesc
            priorAuthorizationRequired
            isPriorAuthRequired {
              networkType
              required
              authorizationProvider
              reasonProAuth
            }
            notes
            specialty
            benefits
          }
          specialtyVariation
        }
      }
      maternityBenefitDetails {
        healthWellnessProgram
        newBornChargeType
        newBornCoverageDuration
        newBornCoverageProvision
        newBornCoverageType
        hasMaternity
      }
      cobDetails {
        policyNo
        verifiedOn
        effectiveFrom
        effectiveTill
        carrier
        coverageType
        primacy
      }
      notificationDetail {
        mmmInd
        displayOutPatientPreCertMngmtLevels
        outPatientPreCertMngmtLevelList {
          inNetworkValue
          label
          outNetworkValue
        }
        indemnity
        prodInNetwork
        carveOutInfo {
          id
          firstName
          lastName
          middleName
          carveOutMessage
          entTypeQual
          idCodeQual
          serviceTypeCode
          suffix
          address
          contact
        }
        surefitEast
        surefitWest
        routerUrl
        routerUrlInput
        notificationData {
          inNetworkValue
          label
          outNetworkValue
        }
      }
      pharmacyBenefitDetails {
        sourceSystemCode
        enableAccredoLink
        pharmacyBenefits {
          planAmountList {
            featureType
            inNetworkValue
            outNetworkValue
            featureTypeDesc
          }
          tierBenefitsList {
            tierType
            tierTypeDesc
            tiersList {
              tierNumber
              tierCopayTimePeriod
              tierValue
              tierLabel
            }
          }
          benefitLabels
          accumumatorIndicators
          benefitSvcCode
          buyUpMsg
          carveoutVendorName
          classCode
          combDedAccum
          combDedOopInd
          errorCode
          eshdRetlFillLmt
          fills30DayPermtdNum
          footnote
          mailOrderSpecialityDrugDays
          mailOrderSupplyDays
          mndtry90DayInd
          noOfRefillsPermitted
          nonSpltyProgTypeCodes
          nonSpltyProgTypeCodes
          planCombMedRxInd
          planType
          prescriptionDrugListCode
          retail90DayProgramType
          retailSupplyDays
          retl90DaySuplyNum
          rxAnnAccumCode
          rxApplMOInd
          rxBuyUp
          rxCarveOutVendorName
          rxClinicalProgCodePA
          rxClinicalProgCodeTE
          rxClmCoinsPct
          rxDedAccumCode
          rxExclDedInd
          rxMedAccessOptCode
          rxOopAccumCode
          rxPlanDesignType
          rxPlanType
          spltyProgTypeCodes
          stateCode
          subClassCode
          fundingArrangementType
          situsStateCode
          progTypeUMCodes
          isInNetworkOnly
          isOutNetworkOnly
          isRetail90DayCoverage
          isRxDedInd
          isRxMaxInd
          isErisaApplicable
          drugListURL
          coverageDetailSummary {
            effectiveDate
            cancelDate
            coverageType
            identifier
            clientId
            clientName
            groupNumber
            groupName
            locationCode
            productType
            familyCode
            embeddedCode
            isRetired
            claimRoutings {
              claimSystemCode
              memKeyType
              memKeyValue
            }
            complianceTypes {
              complianceType
            }
            networkId
            networkType
            isIFP
            branchCode
            benOptCode
            coverageStatus
            fromIBORCoverage
            clinicalSystemLocations
          }
        }
      }
      productDetails {
        typeCode
        familyCode
        hraFundBalance
        isV2V
        isIFP
        hasHRA
        hasHSA
        hasFSA
        embeddedProducts {
          productCode
          effectiveOn
          endsOn
        }
        name
      }
      frequencyAgePlanDetails {
        showBenefitPDF
        benefitPDFName
        specificCoverage {
          frequencyAgeLimitType
          frequencyAgeLimitCoverageList {
            code
            label
            inNVal
            ooNVal
            freqAgeLimitTxt
          }
        }
        procedureList {
          code
          label
          inNVal
          ooNVal
          indmnVal
          freqAgeLimitTxt
        }
      }
      visionServices {
        services {
          name
          coinsurance
          coPayment
          maximumVisits
          maximumAmount
          frequency
        }
        hasCoreBenefits
        standAloneVision
        vendorName
      }
      infertilityServices {
        services {
          category
          inNetworkValue
          outNetworkValue
        }
        isUnlimitedExists
      }
      medicalBenefits
      reference {
        brn
        sso
        created
        benefits
        notes
        firstName
        lastName
        status
        asOfDate
        lastModified
        compositeKey
        coverage
      }
      runtimeError {
        errMsg
        status
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetBrnDetailsGQLService extends Apollo.Query<
  GetBrnDetailsQueryGQL,
  GetBrnDetailsQueryVariablesGQL
> {
  document = GetBrnDetailsDocumentGQL;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
