export const ROUTE_PUT_MFA_DETAILS_COMPLETE =
  'my-practice/two-step-auth-settings/complete';

// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum MFA_REFERRER {
  preferences = 'preferences',
  popup = 'popup',
  login = 'login',
}

// TODO: resolve CHCP disables by bot
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ISAM_RESPONSE_CODES {
  success = '200',
  stop = '409',
  retry_limit = '410',
}
export const ISAM_SUCCESS_MSG = 'mfa flow completed successfully';

export const MFA_ERROR_MESSAGES = {
  genericError:
    'We cannot process your request at this time. Try refreshing your browser or try again later or call 1(800) 853-2713.',
  codeSendFail:
    'We were unable to send your authentication code. Try again or call 1(800) 853-2713',
  stopStatusSingleError: '',
  stopStatusMultipleError: '',
  retryLimitError:
    'You have reached the limit of incorrect authentication codes allowed and have been temporarily locked out.  Please select “cancel” and then try again in 10 minutes.',
  verifyCodeError:
    'You have entered the incorrect authentication code. Try again or request a new code.',
};
