import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[cignaDndFiles]',
})
export class DndFilesDirective {
  @Output() dropped = new EventEmitter<FileList>();
  @Output() hovered = new EventEmitter<boolean>();

  handleEvent($event: DragEvent, isHovered: boolean): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.hovered.emit(isHovered);
  }

  @HostListener('dragover', ['$event']) public onDragOver(
    $event: DragEvent,
  ): void {
    this.handleEvent($event, true);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(
    $event: DragEvent,
  ): void {
    this.handleEvent($event, false);
  }

  @HostListener('drop', ['$event']) public onDrop($event: DragEvent): void {
    this.handleEvent($event, false);

    if ($event.dataTransfer?.files.length) {
      this.dropped.emit($event.dataTransfer.files);
    }
  }
}
