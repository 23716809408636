import { Directive, HostListener, Input, Injector } from '@angular/core';
import { TRACK_FN } from '../../utils';
import { TrackUtil } from '../../track-util';
import { MouseTrackEvent } from '../../track-handlers';

@Directive({
  selector: '[cignaTrackClicks]',
})
export class TrackClicksDirective {
  constructor(private _util: TrackUtil, private _injector: Injector) {}

  @Input('cignaTrackClicks') params: string | [string, ...unknown[]];

  @HostListener('click', ['$event'])
  handleClick(clickEvent: MouseEvent & { __cignaTrackClicks?: boolean }) {
    if (clickEvent.__cignaTrackClicks) {
      // don't react on a propagated event that has been handled by a child trackClicks directive
      return;
    }
    clickEvent.__cignaTrackClicks = true;

    this._util[TRACK_FN]<MouseTrackEvent>(
      { injector: this._injector, mouseEvent: clickEvent },
      this.params,
    );
  }
}
