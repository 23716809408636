/* eslint-disable max-classes-per-file */

declare module 'oidc-client' {
  export class MetadataService {
    constructor(settings: OidcClientSettings);
  }
  export class ResponseValidator {
    constructor(
      settings: OidcClientSettings,
      metadataServiceCtor?: MetadataServiceCtor,
      userInfoServiceCtor?: unknown,
      joseUtilCtor?: unknown,
      tokenClientCtor?: () => TokenClient,
    );
    _tokenClient: TokenClient;
  }
  export class OidcClientSettings {}
  export class TokenClient {
    constructor(
      settings: OidcClientSettings,
      jsonServiceCtor?: unknown,
      metadataServiceCtor?: MetadataServiceCtor,
    );
  }
}

import {
  MetadataService,
  MetadataServiceCtor,
  OidcClientSettings,
  ResponseValidator,
  ResponseValidatorCtor,
  TokenClient,
} from 'oidc-client';
import { Location } from '@angular/common';
import { ImplicitFlowConfig } from './implicit-flow-config';

class ModifiedMetadataService extends MetadataService {
  constructor(settings: OidcClientSettings, private nx: ImplicitFlowConfig) {
    super(settings);
  }

  async getMetadata() {
    return super.getMetadata().then((x) => ({
      ...x,
      token_endpoint: this.nx.tokenUri
        ? this.nx.tokenUri.startsWith('http')
          ? this.nx.tokenUri
          : Location.joinWithSlash(x.issuer, this.nx.tokenUri)
        : x.token_endpoint,
      authorization_endpoint: this.nx.authorizeUri,
    }));
  }
}

export const nxMetadataService: (
  nx: ImplicitFlowConfig,
) => MetadataServiceCtor = (nx) =>
  function (settings) {
    return new ModifiedMetadataService(settings, nx);
  };

export const nxResponseValidator: (
  nx: ImplicitFlowConfig,
) => ResponseValidatorCtor = (nx) =>
  function (settings) {
    const validator = (
      new OidcClientSettings() as OidcClientSettingsWithValidator
    ).validator;
    const validatorCtor = validator.constructor as typeof ResponseValidator;
    const tokenClientCtor = validator._tokenClient
      .constructor as typeof TokenClient;
    const metadataServiceCtor = nxMetadataService(nx);

    return new validatorCtor(
      settings,
      metadataServiceCtor,
      undefined,
      undefined,
      // eslint-disable-next-line prefer-arrow-callback
      function () {
        return new tokenClientCtor(settings, undefined, metadataServiceCtor);
      },
    );
  };

type OidcClientSettingsWithValidator = OidcClientSettings & {
  validator: ResponseValidator;
};
