<div
  class="cg-dialog"
  aria-labelledby="header"
  data-test="delete-confirmation-modal"
>
  <h3
    id="header"
    class="cg-dialog__header cg-dialog__header--modal dialog-header"
  >
    Select your line of business
  </h3>
  <p>
    Please select your preferred line of business. This will determine which
    website you are directed to upon your next login.
  </p>
  <p>
    Learn more about preferred line of business in the
    <a
      [href]="
        '/resourceLibrary/behavioralResources/faq'
          | resourceLink : { targetApp: 'evernorth' }
          | async
      "
      target="resourcesWindow"
      >FAQs</a
    >.
  </p>

  <p class="pref-title">Preferred Line Of Business</p>
  <select [(ngModel)]="lobPref" cignaSelect>
    <option [ngValue]="undefined" disabled hidden>
      Select your line of business
    </option>
    <option
      *ngFor="let lobPrefOption of lobPrefOptions"
      [ngValue]="lobPrefOption.value"
    >
      {{ lobPrefOption.name }}
    </option>
  </select>

  <div class="cg-button-group cg-margin-top-lg">
    <button
      [disabled]="!lobPref"
      cignaButton="primary"
      (click)="dialogRef.close({ lobPref })"
      data-test="delete-file"
    >
      Continue
    </button>
  </div>
</div>
