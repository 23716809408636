import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cigna-omni-messaging-system-event',
  templateUrl: './system-event.component.html',
  styleUrls: ['./system-event.component.scss'],
})
export class OmniMessagingSystemEventComponent implements OnInit {
  @Input() public message: string;
  @Input() public isTransferMessage: boolean;
  @Input() public shouldEnablePreChatWelcome: boolean;
  public isHTMLString: boolean;

  ngOnInit() {
    const regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    this.isHTMLString = regexForHTML.test(this.message);
  }
}
