import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getUrl } from '@cigna/shared/angular/core/router-state-data-access';
import {
  FANCYINVITATIONS_FEATURE_KEY,
  State,
} from './fancy-invitations.reducer';
import { conversationsQuery } from '@cigna/omni/conversations-state-data-access';
import { isChatClosed } from '@cigna/omni/chat-state-data-access';

// Lookup the 'FancyInvitations' feature state managed by NgRx
export const getFancyInvitationsState = createFeatureSelector<State>(
  FANCYINVITATIONS_FEATURE_KEY,
);

export const dismissedDMs = createSelector(
  getFancyInvitationsState,
  (state: State) => state.dismissedDms,
);

const inclusionList: string[] = ['home', 'contact-us'];
export const isOnInclusionList = createSelector(getUrl, (url) =>
  inclusionList.some((fragment) => url?.includes(fragment)),
);

export const unviewedFancyInvitationConv = createSelector(
  isChatClosed,
  isOnInclusionList,
  conversationsQuery.selectFancyInvitationConvs,
  dismissedDMs,
  (chatClosed, onInclusionList, initialDms, dismissed) => {
    if (onInclusionList && chatClosed) {
      return initialDms.find((dm) => !dismissed?.includes(dm._id));
    }
    return undefined;
  },
);
