import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TabComponent } from '../tab/tab.component';
import { TabSwitchService } from './tab-switch.service';

@Component({
  selector: 'cigna-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TabSwitchService],
})
export class TabGroupComponent implements AfterContentInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() border = true;
  @Input() tabStyle: 'buttons' | 'links' = 'buttons';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() hideSingleTab = false;
  @Input() isMultiLineTab = false;

  constructor(
    private _cd: ChangeDetectorRef,
    private _switcher: TabSwitchService,
  ) {}

  /** @internal */
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  /** @internal */
  activeTab$ = this._switcher.activeTab$;

  ngAfterContentInit() {
    this._switcher.updateLayout(this.tabs.toArray());

    this.tabs.changes
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((tabs: QueryList<TabComponent>) => {
        this._cd.markForCheck();
        this._switcher.updateLayout(tabs.toArray());
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
